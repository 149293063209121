import axios from "axios";
import { servicePath } from "../utility/data";


export const getAllFrameStyle = (accessToken, id) => {

    const formData = new FormData();

    formData.append("FrameStyleCollectionId", id);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/FrameStyle/GetAllFrameStyle`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get all panel collections for selected master panel based on ID
export const getFrameStyleColletionDetails = (accessToken, id) => {

    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/FrameStyle/GetFrameStyleColletion`, formData, config)

        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get all right menu item list based on page/type
export const getRightMenuList = (accessToken, id) => {

    const formData = new FormData();

    formData.append("FrameStyleCollectionId", id);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/FrameStyle/GetAllFrameStyle`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getItemDetails = async (accessToken, id) => {
    // console.log(id, "API id")

    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    try {
        const res = await axios
            .post(`${servicePath}/FrameStyle/GetFrameStyle`, formData, config);
        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)

    return axios
        .post(`${servicePath}/FrameStyle/DeleteFrameStyle`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, collectionId, direction) => {
    
    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": collectionId,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/FrameStyle/UpdateFrameStyleOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, data) => {

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();

    formData.append("Id", data.id);
    formData.append("Name", data.name);
    formData.append("MinimumWidth", data.minWidth || 0);
    formData.append("MinimumHeight", data.minHeight || 0);
    formData.append("MaximumWidth", data.maxWidth || 9999);
    formData.append("MaximumHeight", data.maxHeight || 9999);
    formData.append("Wide", data.wide || 1);
    formData.append("High", data.high || 1);
    formData.append("DefaultStyle", data.defaultStyle);
    formData.append("FrameStyleCollectionId", data.collectionId);
    formData.append('ProfilePath', data.filePath || "");
    formData.append('ProfilePathUrl', '');

    return axios
        .post(`${servicePath}/FrameStyle/AddUpdateFrameStyle`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data, collectionId) => {

    const formData = new FormData();

    formData.append("Id", '');
    formData.append("Name", data);
    formData.append("MinimumWidth", 100);
    formData.append("MinimumHeight", 100);
    formData.append("MaximumWidth", 9999);
    formData.append("MaximumHeight", 9999);
    formData.append("Wide", 1);
    formData.append("High", 1);
    formData.append("DefaultStyle", false);
    formData.append("FrameStyleCollectionId", collectionId);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/FrameStyle/AddUpdateFrameStyle`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// to get the listing for the tags
export const getSizeTagsService = (accessToken, systemId) => {

    const formData = new FormData();

    formData.append("FrameProductId", systemId);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/Product/GetTagsMappings`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const deleteTagsMapping = (accessToken, itemId) => {

    const formData = new FormData();

    formData.append("Id", itemId);
    // formData.append("FrameStyleId", frameStyleId);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/Product/DeleteTagsMapping`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};