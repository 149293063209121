import { without } from 'lodash';
import * as THREE from 'three';
export function createShapeFromWidthHeight(width, height, radius) {
    // const arcRatio = 0.001;
    const arcRatio = 0;


    const frameShape = new THREE.Shape();
    // radius = radius || 0.004;
    radius = 0.002;

    // Start from the top left, adjust the coordinates to include radius.
    frameShape.moveTo(-width / 2 + radius, 0);

    // Top edge (left to right)
    frameShape.lineTo(width / 2 - radius, 0);
    frameShape.absarc(width / 2 - radius, -radius, radius, Math.PI / 2, 0, true);

    // Right edge (top to bottom)
    frameShape.lineTo(width / 2, -height + radius);
    frameShape.absarc(width / 2 - radius, -height + radius, radius, 0, -Math.PI / 2, true);

    // Bottom edge (right to left)
    frameShape.lineTo(-width / 2 + radius, -height);
    frameShape.absarc(-width / 2 + radius, -height + radius, radius, -Math.PI / 2, -Math.PI, true);

    // Left edge (bottom to top)
    frameShape.lineTo(-width / 2, -radius);
    frameShape.absarc(-width / 2 + radius, -radius, radius, Math.PI, Math.PI / 2, true);

    return frameShape;
}

export function createSmoothTriangleShape_(width, height, angle, cornerRadius) {
    cornerRadius = cornerRadius || 0.004;
    // Define the three vertices of the triangle
    const x2 = width; // Second vertex is horizontally along the width
    const y2 = 0; // Same y-coordinate as the first vertex

    const x3 = height * Math.cos(angle); // Horizontal component of the height
    const y3 = height * Math.sin(angle); // Vertical component of the height

    // Ensure the corner radius is not too large
    const maxRadius = Math.min(cornerRadius, width / 2, height / 2);

    // Create a shape
    const triangleShape = new THREE.Shape();

    // Start at the first corner (0, 0)
    triangleShape.moveTo(maxRadius, 0);

    // Draw the first edge with a rounded corner at (x2, y2)
    triangleShape.lineTo(x2 - maxRadius, y2);
    triangleShape.absarc(x2 - maxRadius, y2 + maxRadius, maxRadius, -Math.PI / 2, 0, false);

    // Draw the second edge with a rounded corner at (x3, y3)
    triangleShape.lineTo(x3, y3 - maxRadius);
    triangleShape.absarc(x3 - maxRadius * Math.cos(angle), y3 - maxRadius * Math.sin(angle), maxRadius, 0, angle, false);

    // Draw the third edge with a rounded corner at (0, 0)
    triangleShape.lineTo(maxRadius * Math.cos(angle), maxRadius * Math.sin(angle));
    triangleShape.absarc(maxRadius * Math.cos(angle), maxRadius * Math.sin(angle), maxRadius, angle, Math.PI + angle, false);

    return triangleShape;
}
export function createSmoothTriangleShape__(width, height, angle, cornerRadius) {
    cornerRadius = cornerRadius || 0;
    // Define the three vertices of the triangle
    const x2 = width; // Second vertex is horizontally along the width
    const y2 = 0; // Same y-coordinate as the first vertex

    const x3 = height * Math.cos(angle); // Horizontal component of the height
    const y3 = height * Math.sin(angle); // Vertical component of the height

    // Ensure the corner radius is not too large
    const maxRadius = Math.min(cornerRadius, width / 2, height / 2);

    // Adjust vertices to ensure arcs fit properly
    const adjustedX2 = x2 - maxRadius; // Adjusted for the first corner arc
    const adjustedY3 = y3 - maxRadius; // Adjusted for the second corner arc
    const adjustedX3 = x3 - maxRadius * Math.cos(angle); // Adjusted for the third corner arc
    const adjustedY3Arc = y3 - maxRadius * Math.sin(angle);

    // Create a shape
    const triangleShape = new THREE.Shape();

    // Start at the first vertex with a corner arc
    triangleShape.moveTo(maxRadius, 0); // Start slightly offset for the arc

    // Draw the first edge with a rounded corner at (x2, y2)
    triangleShape.lineTo(adjustedX2, y2);
    triangleShape.absarc(adjustedX2, maxRadius, maxRadius, -Math.PI / 2, 0, false);

    // Draw the second edge with a rounded corner at (x3, y3)
    triangleShape.lineTo(adjustedX3, adjustedY3);
    triangleShape.absarc(adjustedX3, adjustedY3Arc, maxRadius, 0, angle, false);

    // Draw the third edge with a rounded corner back to the start
    triangleShape.lineTo(maxRadius * Math.cos(angle), maxRadius * Math.sin(angle));
    triangleShape.absarc(
        maxRadius * Math.cos(angle),
        maxRadius * Math.sin(angle),
        maxRadius,
        angle,
        Math.PI + angle,
        false
    );

    return triangleShape;
}
export function createSmoothTriangleShape____(width, height, angle) {
    angle = 120;
    // Define the three vertices of the triangle
    const x2 = width; // Second vertex is horizontally along the width
    const y2 = 0; // Same y-coordinate as the first vertex

    const x3 = height * Math.cos(angle); // Horizontal component of the height
    const y3 = height * Math.sin(angle); // Vertical component of the height

    // Create a shape
    const triangleShape = new THREE.Shape();

    // Start at the first vertex (0, 0)
    triangleShape.moveTo(0, 0);

    // Draw edges to the other two vertices
    triangleShape.lineTo(x2, y2); // Line to the second vertex
    triangleShape.lineTo(x3, y3); // Line to the third vertex

    // Close the shape back to the first vertex
    triangleShape.lineTo(0, 0);

    return triangleShape;
}
export function createSmoothTriangleShape(width, height, angle) {
    // Convert angle to radians
    const angleRad = (angle * Math.PI) / 180;

    // Bottom-left corner
    const point1 = [0, 0];

    // Bottom-right corner (width along the x-axis)
    const point2 = [width, 0]; // This point is at (width, 0)

    // Top vertex (calculated using side2 length and angle)
    const topX = width - height * Math.cos(angleRad); // Horizontal component of side2
    const topY = height * Math.sin(angleRad);        // Vertical component of side2
    const point3 = [topX, topY];

    // Create a new THREE.Shape
    const triangleShape = new THREE.Shape();

    // Move to the first point
    triangleShape.moveTo(point1[0], point1[1]);

    // Draw lines to the other points
    triangleShape.lineTo(point2[0], point2[1]); // Line to bottom-right corner
    triangleShape.lineTo(point3[0], point3[1]); // Line to top vertex
    triangleShape.lineTo(point1[0], point1[1]); // Close the shape by going back to the first point

    return triangleShape;
}


export function createExternalMesh(frameMesh, frameMaterial_Ext, frameGeometry1, group, name = ""){
    const extMesh = frameMesh.clone();
    extMesh.material = frameMaterial_Ext;
    extMesh.position.z -= 0.1 / 1000;
    const offsetVal = 2 / 1000;
    frameGeometry1.computeBoundingBox();
    const beforeBound = frameGeometry1.boundingBox;
    const beforeWidth = beforeBound.max.x - beforeBound.min.x;
    const beforeHeight = beforeBound.max.y - beforeBound.min.y;
    const xScaleVal = (beforeWidth + offsetVal) / beforeWidth;
    const yScaleVal = (beforeHeight + offsetVal) / beforeHeight;
    const xCenter = (beforeBound.max.x + beforeBound.min.x) / 2;
    const xOffset = ((xCenter * (xScaleVal)) - xCenter) / 1;
    const yCenter = (beforeBound.max.y + beforeBound.min.y) / 2;
    const yOffset = ((yCenter * (yScaleVal)) - yCenter) / 1;
    extMesh.scale.x = xScaleVal;
    extMesh.scale.y = yScaleVal;
    extMesh.position.x -= xOffset;
    extMesh.position.y -= yOffset;
    extMesh.name = name;
    group.add(extMesh);
}



// Helper function for linear interpolation
function lerp(a, b, t) {
    return a + (b - a) * t;
}


export function getWidthAndHeightFromMesh(mesh) {
    const boundingBox = new THREE.Box3().setFromObject(mesh);
    const width = boundingBox.max.x - boundingBox.min.x;
    const height = boundingBox.max.y - boundingBox.min.y;
    return {
        width: width,
        height: height
    };
}


export function removeObjectByName(scene, obj) {
    const groupToRemove = scene?.getObjectByName(obj);

    if (groupToRemove) {
        scene.remove(groupToRemove);
    } else {
        console.log(`Group with name ${obj} not found.`);
    }
}

export const createLockingPlate = (mesh, width, index, height) => {};


export async function getSplitValue_(group , parentGroup , isSash = false, sashGrroup = null) {    
    // console.clear();
    // console.log("sashGrroup :::: ", sashGrroup);
    
    let frameTop = parentGroup.getObjectByName(isSash ? "SashTop" : "FrameTop");
    const topBoundingBox = new THREE.Box3().setFromObject(frameTop);
    let frameBottom = parentGroup.getObjectByName(isSash ? "SashBottom" : "FrameBottom");
    const bottomBoundingBox = new THREE.Box3().setFromObject(frameBottom);



    let frameLeft = parentGroup.getObjectByName("FrameLeft");
    let frameRight = parentGroup.getObjectByName("FrameRight");
    let sashSplit = [];

    if(isSash){
        let minX = Infinity;
        let maxX = -Infinity;
        let transCount = 1;
        let sashCounts = null;
        parentGroup.traverse((object) => {
            if(object.transType && object.transType == "vertical"){
                transCount++;
            }
            if (object.name === "SashLeft") {

                let bound = new THREE.Box3().setFromObject(object)
                if (bound.max.x > minX) {
                    minX = bound.max.x;
                    // frameLeft = object;
                }
            }
            if (object.name === "SashRight") {
                if(Array.isArray(sashCounts)){
                    sashCounts.push(transCount);
                    transCount = 1;
                }
                if(!sashCounts){
                    sashCounts=[];
                }
                let bound = new THREE.Box3().setFromObject(object)
                sashSplit.push((bound.max.x + bound.min.x)/2);
                if (bound.min.x > maxX) {
                    maxX = bound.min.x;
                    // frameRight = object;
                }
            }
        });
        sashCounts.push(transCount);
        transCount = 1;
        if(parentGroup.type == "Scene"){
            parentGroup.sashSplits = sashCounts;
        }else{
            parentGroup.sashSplits = null;
        }
        // console.log("parentGroup :::: ", parentGroup);
        
    }



    const leftBoundingBox = new THREE.Box3().setFromObject(frameLeft);
    const rightBoundingBox = new THREE.Box3().setFromObject(frameRight);

    let yPositions = [], xPositions = [];
    group.traverse((child) => {
      if (child.isMesh && child.transType === "horizontal") {

        yPositions.push(child.position.y + child.transHeightCenter);
      }else if (child.isMesh && child.transType === "vertical") {
        
        let bound = new THREE.Box3().setFromObject(child)
        xPositions.push( bound.min.x + ((bound.max.x - bound.min.x) / 2))
        // xPositions.push(child.position.x - child.transHeightCenter);
    }
    });

    let heightSplitNew = [], widthSplitNew = [];

    let uniqueYPositions = [...new Set(yPositions)];
    let allYPositions = [...uniqueYPositions, topBoundingBox.min.y, bottomBoundingBox.max.y];
    await allYPositions.sort((a, b) => b - a);
    let innerHeight = topBoundingBox.min.y - bottomBoundingBox.max.y;
    for (let i = 0; i < allYPositions.length - 1; i++) {
      heightSplitNew.push( parseFloat(((allYPositions[i] - allYPositions[i+1]) / innerHeight).toFixed(3) ));
    }

    if(sashSplit.length > 0){
        sashSplit = sashSplit.slice(0, -1);
        xPositions = xPositions.concat(sashSplit)
    }
    let uniqueXPositions = [...new Set(xPositions)]; 
    let allXPositions = [...uniqueXPositions, rightBoundingBox.max.x, leftBoundingBox.min.x];
    allXPositions.sort((a, b) => a - b);
    let innerWidth = rightBoundingBox.max.x - leftBoundingBox.min.x;
    for (let i = 0; i < allXPositions.length - 1; i++) {
        widthSplitNew.push( (allXPositions[i + 1] - allXPositions[i]) / innerWidth );
        // widthSplitNew.push( parseFloat(((allXPositions[i + 1] - allXPositions[i]) / innerWidth).toFixed(3) ));
    }
    function distributeAsEqualSums(heightSplitNew) {
        const length = heightSplitNew.length;
        if (length === 0) {
            return [];
        }
        const equalValue = 1 / length;
        return Array(length).fill(equalValue);
    }
    // heightSplitNew = distributeAsEqualSums(heightSplitNew);
    // console.log("heightSplitNew :::: ", heightSplitNew);
    // console.log("widthSplitNew :::: ", widthSplitNew);
  
    return {
      heightSplit: heightSplitNew || [],
      widthSplit: widthSplitNew || []
    };
  }
  export async function getSplitValue(group, parentGroup, isSash = false, sashGrroup = null) {    
    let frameTop = parentGroup.getObjectByName(isSash ? "SashTop" : "FrameTop");
    const topBoundingBox = new THREE.Box3().setFromObject(frameTop);
    let frameBottom = parentGroup.getObjectByName(isSash ? "SashBottom" : "FrameBottom");
    const bottomBoundingBox = new THREE.Box3().setFromObject(frameBottom);

    let frameLeft = parentGroup.getObjectByName("FrameLeft");
    let frameRight = parentGroup.getObjectByName("FrameRight");
    let sashSplit = [];

    if (isSash) {
        let minX = Infinity;
        let maxX = -Infinity;
        let transCount = 1;
        let sashCounts = null;

        parentGroup.traverse((object) => {
            if (object.transType && object.transType === "vertical") {
                transCount++;
            }
            if (object.name === "SashLeft") {
                let bound = new THREE.Box3().setFromObject(object);
                if (bound.max.x > minX) {
                    minX = bound.max.x;
                }
            }
            if (object.name === "SashRight") {
                if (Array.isArray(sashCounts)) {
                    sashCounts.push(transCount);
                    transCount = 1;
                }
                if (!sashCounts) {
                    sashCounts = [];
                }
                let bound = new THREE.Box3().setFromObject(object);
                sashSplit.push((bound.max.x + bound.min.x) / 2);
                if (bound.min.x > maxX) {
                    maxX = bound.min.x;
                }
            }
        });
        sashCounts.push(transCount);
        transCount = 1;
        if (parentGroup.type === "Scene") {
            parentGroup.sashSplits = sashCounts;
        } else {
            parentGroup.sashSplits = null;
        }
    }

    const leftBoundingBox = new THREE.Box3().setFromObject(frameLeft);
    const rightBoundingBox = new THREE.Box3().setFromObject(frameRight);

    let yPositions = [], xPositions = [];
    group.traverse((child) => {
        if (child.isMesh && child.transType === "horizontal") {
            // Collect horizontal transom positions
            let bound = new THREE.Box3().setFromObject(child);
            yPositions.push(bound.min.y + ((bound.max.y - bound.min.y) / 2));
        } else if (child.isMesh && child.transType === "vertical") {
            // Collect vertical transom positions
            let bound = new THREE.Box3().setFromObject(child);
            xPositions.push(bound.min.x + ((bound.max.x - bound.min.x) / 2));
        }
    });

    let heightSplitNew = [], widthSplitNew = [];

    // Calculate unique and sorted Y positions
    let uniqueYPositions = [...new Set(yPositions)];
    let allYPositions = [...uniqueYPositions, topBoundingBox.max.y, bottomBoundingBox.min.y];
    allYPositions = [...new Set(allYPositions)];
    allYPositions.sort((a, b) => b - a);

    // Calculate height splits
    let innerHeight = topBoundingBox.max.y - bottomBoundingBox.min.y;
    // for (let i = 0; i < allYPositions.length - 1; i++) {
    //     const segmentHeight = allYPositions[i + 1] - allYPositions[i];
    //     heightSplitNew.push(parseFloat((segmentHeight / innerHeight).toFixed(3)));
    // }
    for (let i = 0; i < allYPositions.length - 1; i++) {
        heightSplitNew.push( parseFloat(((allYPositions[i] - allYPositions[i+1]) / innerHeight).toFixed(3) ));
      }

    // Normalize height splits if necessary
    if (heightSplitNew.length > 0 && heightSplitNew.reduce((sum, val) => sum + val, 0) !== 1) {
        // heightSplitNew = distributeAsEqualSums(heightSplitNew);
    }

    // Calculate unique and sorted X positions
    if (sashSplit.length > 0) {
        sashSplit = sashSplit.slice(0, -1);
        xPositions = xPositions.concat(sashSplit);
    }
    let uniqueXPositions = [...new Set(xPositions)];
    let allXPositions = [...uniqueXPositions, rightBoundingBox.max.x, leftBoundingBox.min.x];
    allXPositions.sort((a, b) => a - b);
    let innerWidth = rightBoundingBox.max.x - leftBoundingBox.min.x;
    for (let i = 0; i < allXPositions.length - 1; i++) {
        const segmentWidth = allXPositions[i + 1] - allXPositions[i];
        widthSplitNew.push(parseFloat((segmentWidth / innerWidth).toFixed(3)));
    }

    // Normalize width splits if necessary
    if (widthSplitNew.length > 0 && widthSplitNew.reduce((sum, val) => sum + val, 0) !== 1) {
        // widthSplitNew = distributeAsEqualSums(widthSplitNew);
    }

    function distributeAsEqualSums(splits) {
        const length = splits.length;
        if (length === 0) {
            return [];
        }
        const equalValue = 1 / length;
        return Array(length).fill(equalValue);
    }

    console.log("heightSplitNew :::: ", heightSplitNew);
    console.log("widthSplitNew :::: ", widthSplitNew);

    return {
        heightSplit: heightSplitNew || [],
        widthSplit: widthSplitNew || []
    };
}





export function addSpritesSqNo(scene, name, color, number = 1) {
    // Create a texture for the sprite with a rounded background and a number
    function createSpriteTexture(number) {
        const size = 128; // Size of the canvas
        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;

        const context = canvas.getContext('2d');
        if (!context) return null;

        // Draw the rounded background
        const radius = size / 2;
        context.fillStyle = color[0]; // Background color
        context.beginPath();
        context.arc(size / 2, size / 2, radius - 10, 0, 2 * Math.PI);
        context.closePath();
        context.fill();

        // Draw the number
        context.fillStyle = color[1]; // Text color
        context.font = `${size / 2}px Arial`;
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(number, size / 2, size / 2);

        // Create a texture
        const texture = new THREE.CanvasTexture(canvas);
        return texture;
    }

    // Create a sprite with the texture
    function createSprite(number) {
        const texture = createSpriteTexture(number);
        if (!texture) return null;

        const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
        const sprite = new THREE.Sprite(spriteMaterial);
        sprite.scale.set(0.1, 0.1, 0.1); // Adjust sprite size if needed
        sprite.position.z = 5;
        return sprite;
    }

    // let number = 1; // Starting number for the sprites

    // Traverse the scene to find meshes with "frames" in their name
    scene.traverse((object) => {
        if (object.isMesh && object.name.includes(name) && !object.name.includes("_ext")) {
            console.log("object visibility :::: ", object.visible);
            
            const sprite = createSprite(number);
            if (sprite) {
                // Add the sprite to the scene and position it at the center of the mesh
                const boundingBox = new THREE.Box3().setFromObject(object);
                const center = new THREE.Vector3();
                boundingBox.getCenter(center);
                // Position sprite in front of the mesh along the z-axis
                const offsetDistance = name == "sill" ? boundingBox.max.z + 0.01 : boundingBox.max.z + 0.04; // Adjust this value as needed
                const normal = new THREE.Vector3(0, 0, 1); // Direction to move the sprite (z-axis)
                object.localToWorld(normal); // Convert normal to world space
                normal.normalize().multiplyScalar(offsetDistance);

                sprite.position.copy(center.add(normal)); // Offset the sprite position

                // Add sprite to the appropriate parent
                if (object.parent) {
                    object.parent.worldToLocal(sprite.position);
                    object.parent.add(sprite);
                } else {
                    scene.add(sprite);
                }

                number++; // Increment the number for the next sprite
            }
        }
    });
    return number;
}
