import React, { useState, useEffect } from "react";
import { BiPlus, BiTrashAlt } from "react-icons/bi";
import {
  MdArrowDownward,
  MdPersonOutline,
  MdArrowUpward,
  MdContentCopy,
  MdFolderOpen,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { useProductPaletteContext } from "../../../../../../../context/productPaletteContext";
import { useMessage } from "../../../../../../../context/messageContext";
import {
  addCollectionColours,
  deleteCollection,
  updateCollectionOrder,
} from "../../../../../../../services/productServices";
import RotatingCube from "../../../../../../../components/3DComponents/RotatingCube";
import { copyItem } from "../../../../../../../services/commonServices";
import { useRightContext } from "../../../../../../../context/rightMenuContext";
import { handleError } from "../../../../../../../utility/commonHelper";

const CollectionsRight = ({
  setLoading,
  getAllCollectionServices,
  locationId,
}) => {
  const {
    newItemList,
    fetchSelected,
    activeIndex,
    handleActiveIndexDown,
    handleActiveIndexUp,
    setActiveIndex,
    timestamp,
  } = useProductPaletteContext();
  const { setMessage, setMessageType } = useMessage();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const accessToken = localStorage.getItem("access_token");

  const { selectedRightItem, setSelectedRightItem } = useRightContext();

  const navigate = useNavigate();

  useEffect(() => {
    // setActiveIndex(0)
  }, []);

  useEffect(() => {
    if (newItemList?.length > 0) {
      if (locationId) {
        let index = newItemList?.findIndex((item) => item?.id == locationId);
        setActiveIndex(index);
      } else {
        let selectedRight = newItemList?.find((p) => p.id === timestamp);

        setSelectedRightItem(selectedRight);
      }
    }
  }, [timestamp, newItemList]);

  const getSingleCollection = newItemList?.find(
    (item) => item.id === timestamp
  );

  const addCollectionServices = async (name) => {
    setLoading(true);
    const data = {
      name: name,
      type: 2,
    };
    const res = await addCollectionColours(data, accessToken);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(
          res?.data?.message ? res?.data?.message : "Added new colour"
        );
        getAllCollectionServices("added");
        setLoading(false);
      } else {
        setMessage(
          res?.data?.message ? res?.data?.message : "Something went wrong"
        );
        setMessageType("error");
        getAllCollectionServices();
        setLoading(false);
        handleError(res, false, navigate);
      }
    }
  };

  const deleteCollectionServices = async () => {
    setLoading(true);
    const res = await deleteCollection(accessToken, timestamp);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(
          res?.data?.message ? res.data.message : "Deleted Color Successfully"
        );
        getAllCollectionServices();
        setLoading(false);
        fetchSelected(0, newItemList[0]?.id);
        setShowDeleteModal(false);
      } else {
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
        getAllCollectionServices();
        setLoading(false);
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      getAllCollectionServices();
      handleError(res, false, navigate);
    }
  };

  const updateOrderServices = async (direction) => {
    setLoading(true);
    const res = await updateCollectionOrder(
      timestamp,
      2,
      direction,
      accessToken
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(
          res?.data?.message ? res?.data?.message : "Order updated successfully"
        );
        getAllCollectionServices();
        setLoading(false);
        if (direction === "UP") {
          handleActiveIndexUp();
        } else {
          handleActiveIndexDown();
        }
      } else {
        setMessage(
          res?.data?.message ? res?.data?.message : "Something went wrong"
        );
        setMessageType("error");
        getAllCollectionServices();
        setLoading(false);
        handleError(res, false, navigate);
      }
    }
  };

  const handleUp = () => {
    updateOrderServices("UP");
  };

  const handleDown = () => {
    updateOrderServices("DOWN");
  };

  const copyPaletteServices = async (id) => {
    setLoading(true);
    const res = await copyItem(accessToken, id, 15);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(res?.data?.message ? res.data.message : "Item copied");
        getAllCollectionServices();
        setLoading(false);
      } else {
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
        getAllCollectionServices();
        setLoading(false);
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      getAllCollectionServices();
      handleError(res, false, navigate);
    }
  };

  return (
    <>
      <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_btns">
          <div className="right_list">
            {newItemList &&
              newItemList?.length > 0 &&
              newItemList?.map((item, index) => (
                <div
                  key={index}
                  className="position-relative d-flex align-items-center right_item_wrap has_delete has_copy"
                >
                  <Link
                    to="#"
                    className={`right_item w-100 ${
                      activeIndex === index && "active"
                    }`}
                    onClick={() => {
                      fetchSelected(index, item?.id);
                    }}
                  >
                    <span className="me-2">
                      <MdFolderOpen size={22} />
                    </span>
                    {/* <span className='me-2'>
                  {console.log(item.filePath , "221")}
                  <RotatingCube elements={"https://testorbitapp.augursapps.com/Content/VisualElement/White9017.svg"} roughnessVal={0} colorVal={"0xFFFFFF"} metalNessVal={0.5} bumpiness={0} textureVal={"https://testorbitapp.augursapps.com/Content/VisualElement/White9017.svg"} capturedAccordion={""}  />
                  </span> */}
                    <span className="text-truncate">{item?.name}</span>
                  </Link>
                  <span className="position-absolute action_btns">
                    {item?.isSelf && (
                      <>
                        <Link
                          to="#"
                          onClick={() => {
                            fetchSelected(index, item?.id);
                            setShowDeleteModal(true);
                          }}
                        >
                          {" "}
                          <BiTrashAlt className="text-muted" size={20} />{" "}
                        </Link>
                        <Link
                          to="#"
                          onClick={() => copyPaletteServices(item?.id)}
                        >
                          {" "}
                          <MdContentCopy
                            className="text-muted"
                            size={18}
                          />{" "}
                        </Link>
                      </>
                    )}
                  </span>
                </div>
              ))}
          </div>
        </div>

        <Modal
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(false)}
        >
          <ModalHeader>Delete Ancillary</ModalHeader>
          <ModalBody>
            Are you sure you want delete{" "}
            <strong>{getSingleCollection?.name}</strong> ?
          </ModalBody>
          <ModalFooter>
            <Button
              color="outline-danger"
              size="md"
              onClick={() => deleteCollectionServices()}
            >
              Delete
            </Button>
            <Button
              onClick={() => setShowDeleteModal(false)}
              color="primary"
              size="md"
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={() =>
              addCollectionServices(`Collection ${newItemList.length + 1}`)
            }
          >
            <BiPlus size={20} className="me-1" />
            New
          </Button>

          {selectedRightItem?.isSelf && (
            <>
              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => handleUp()}
                disabled={newItemList?.length === 0 || activeIndex === 0}
              >
                <MdArrowUpward size={22} className="me-1" />
                Up
              </Button>
              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => handleDown()}
                disabled={
                  activeIndex === newItemList?.length - 1 ||
                  newItemList?.length === 0 ||
                  selectedRightItem?.isLast
                }
              >
                <MdArrowDownward size={22} className="me-1" />
                Down
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CollectionsRight;
