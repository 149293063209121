// createWindowFrame.js
import * as THREE from 'three';
import { createFramePath, createLinePath } from './lineShapes.js'; // Import the createFramePath function
import { createCornerPath } from './cornerShapes.js'; // Import the createCornerPath function
import { createCombinedOutlinePath } from './combinedOutline.js';
import { createSash, hangingRefs, removeHangingRefs, removeSashRefs } from './sashes.js';
import { getAngleFromLine, updateFrameJoint } from './profileJoints.js';
import { createTransomGlassPath, emptyTransomPartitions, emptyTransomRefs, storeTransomPartitionRefs } from './transoms.js';
import { calculateOffsets, createAddons } from './addOns.js'
import { addHardwareInSteps, loadHardware, positionHardwareOnFrame } from './hardwares.js';
import { getHeightFromAngle, createSVGElement, storeTransomPartialDimensions } from './utils/generalUtils'
import { createShapeFromWidthHeight, createSmoothTriangleShape, createExternalMesh, getWidthAndHeightFromMesh, addSpritesSqNo, getSplitValue } from './utils/threeUtils'
import { addSill } from './sills.js';
import { getSideFromFrameType } from '../../../utility/helper.js';
import { connectPaypostCobbler } from './addFrames.js';
import { assignTexture, createGlassMaterial, createPanelMatrial, createProfileMaterial } from './utils/materials.js';

const shapeCache = new Map();
let zOffsetMap = {}
let frameProfileRefs = []
let jointRefs = []
let addOnRefs = []
let frameMeshArr = [];

const allTrickleVents = [];

let allOffSet = {
    wWidthOffset: 0,
    wHeightOffset: 0,
    wLoffset: 0,
    wRoffset: 0,
    wTopOffset: 0,
    wBOffset: 0,
    sillHeight: 0,
}

export const getAllOffSet = () => {
    return allOffSet;
}

const storeFrameProfileRef = (obj, callBack) => {
    frameProfileRefs.push(obj)
    if (typeof callBack === 'function') {
        callBack()
    }
}

const storeFrameMesh = (obj) => {
    frameMeshArr.push(obj);
}

export const getFrameMesh = () => {
    return frameMeshArr;
}

const storeJointsRef = (obj) => {
    jointRefs.push(obj)
}

export const getJointsRef = () => {
    return jointRefs;
}

const storeAddOnRef = (obj) => {
    addOnRefs.push(obj)
}

export const getAddOnRef = () => {
    return removeDuplicatesByName(addOnRefs);
}

export const getFrameRefs = () => {
    return frameProfileRefs;
}

function removeDuplicatesByName(array) {
    const uniqueItems = new Map();

    array.forEach(item => {
        if (item.name && !uniqueItems.has(item.name)) {
            uniqueItems.set(item.name, item);
        }
    });

    return Array.from(uniqueItems.values());
}

export const updateFrameDimension = (width, height, jsonIndex = 0) => {
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };
        updateJson.dimensions.width = width;
        updateJson.dimensions.height = height;
        updatedModelArray[jsonIndex] = updateJson;
        return updatedModelArray;
    }
}

export const loadDefaultFrame = async (
    defaultFrameProfile,
    profileJoint,
    statcJson,
    storeFrameProfile,
    inititalSpec,
    storeDefaultSpecData
) => {


    const height = defaultFrameProfile?.height / 1000 || 0.1;
    const width = defaultFrameProfile?.width / 1000 || 0.1;
    const id = defaultFrameProfile?.id
    const frameJoint = profileJoint?.frameName;
    const hexValue = inititalSpec?.hexValue;

    // Clone the array to avoid mutating the original reference
    const updatedModelJsonArray = statcJson.map((frame) => {
        const updatedFrame = { ...frame };

        // Update glass color and joint
        updatedFrame.glass.color = hexValue;
        updatedFrame.joint = frameJoint;

        // Update dimensions for all sides
        Object.keys(updatedFrame.frame.sides).forEach((side, index) => {
            updatedFrame.frame.sides[side].dimensions.width = width;
            updatedFrame.frame.sides[side].dimensions.height = height;
            updatedFrame.frame.sides[side].id = id

            // Call storeData for each side
            setTimeout(() => {
                storeFrameProfile(side, defaultFrameProfile, index);
                if (hexValue) {
                    storeDefaultSpecData(inititalSpec, -1, true)
                }
            }, 200);
        });

        return updatedFrame;
    });

    return updatedModelJsonArray;
};

// };
export const updateFrameProfile = (frameProfile, orientation, storeData, jsonIndex = 0) => {
    let height = frameProfile?.height / 1000 || 0.1;
    let width = frameProfile?.width / 1000 || 0.1;
    let id = frameProfile?.id
    const finalOrientation = orientation == 'FrameTop' ? "top" : orientation == 'FrameRight' ? "right" : orientation == 'FrameBottom' ? "bottom" : "left";
    return prevModelJson => {
        const updatedModelArray = [...prevModelJson]
        const updatedModelJson = { ...updatedModelArray[jsonIndex] };
        Object.keys(updatedModelJson.frame.sides).forEach((side, index) => {
            if (finalOrientation === side) {
                updatedModelJson.frame.sides[side].dimensions.width = width;
                updatedModelJson.frame.sides[side].dimensions.height = height;
                updatedModelJson.frame.sides[side].id = id
                updatedModelJson.frame.sides[side].isVisible = true;
                storeData(side, frameProfile, index)
            }
        });

        updatedModelArray[jsonIndex] = updatedModelJson;
        return updatedModelArray;
    };
};

export const deleteFrameProfile = (orientation, jsonIndex = 0) => {
    const side = getSideFromFrameType(orientation);
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updatedModelJson = { ...updatedModelArray[jsonIndex] };
        updatedModelJson.frame.sides[side].isVisible = false;
        updatedModelArray[jsonIndex] = updatedModelJson;
        return updatedModelArray;
    }
}

const profileHeightWidth = (height, width) => {
    const frameShape = new THREE.Shape();
    const radius = 0.004;
    frameShape.moveTo(-width / 2 + radius, 0);

    // Top edge (left to right)
    frameShape.lineTo(width / 2 - radius, 0);
    frameShape.absarc(width / 2 - radius, -radius, radius, Math.PI / 2, 0, true);

    // Right edge (top to bottom)
    frameShape.lineTo(width / 2, -height + radius);
    frameShape.absarc(width / 2 - radius, -height + radius, radius, 0, -Math.PI / 2, true);

    // Bottom edge (right to left)
    frameShape.lineTo(-width / 2 + radius, -height);
    frameShape.absarc(-width / 2 + radius, -height + radius, radius, -Math.PI / 2, -Math.PI, true);

    frameShape.lineTo(-width / 2, -radius);
    frameShape.absarc(-width / 2 + radius, -radius, radius, Math.PI, Math.PI / 2, true);

    return frameShape;

}


const getShapeCache = (height, width) => {
    const key = `${height} - ${width}`
    if (!shapeCache.has(key)) {
        const shape = createShapeFromWidthHeight(width, height);
        shapeCache.set(key, shape)
    }
    return shapeCache.get(key)
}



const extrudeSettings = (path, steps) => ({
    // steps: 100,
    steps: steps,
    extrudePath: path, // Path to extrude along
    bevelEnabled: false,  // No bevel
    // bevelThickness: 1,
    // bevelSize: 1,
    // bevelOffset: 0,
    // bevelSegments: 100,
    // curveSegments: 120,
    morphTargetsRelative: true
});
export async function createWindowFrame(scene, windowDataFull, groupName, sashGroup, profileOrientation, profileType, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, modelName = null, partialDimensionRef, isTranomTriggered = false) {
    console.clear();
    framePathCollection.current = [];

    emptyTransomPartitions();
    emptyTransomRefs();
    frameMeshArr = [];
    let sillHeight = 0;
    if (sillsHeightRef && sillsHeightRef.current) {
        sillsHeightRef.current = [0.0];
        sillHeight = sillsHeightRef.current.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }
    // addWindowTempData(windowDataFull);
    if (windowDataFull && windowDataFull?.length) {

        try {
            windowDataFull.forEach(async (windowData, wdi) => {

                console.log("windowData :::: ", windowData);

                // ***** to fix the profile z access issue
                // const sides = windowData.frame.sides;
                // const widths = []; 
                // const heights = [];
                // Object.keys(sides).forEach(key => {
                //     const side = sides[key].dimensions;
                //     const width = side.width;
                //     const height = side.height;
                //     widths.push(width);
                //     heights.push(height);
                // });
                // // const widths = Object.keys(sides).map(key => sides[key].width);
                // // const heights = Object.keys(sides).map(key => sides[key].height);
                // const maxWidth = Math.max(...widths);
                // const maxHeight = Math.max(...heights);
                // frameProfileDefault.width = maxWidth;
                // frameProfileDefault.height = maxHeight;

                // const windowData = windowDataFull?.[wdi] || {};
                const addOn = windowData.addOn
                //     if(!addOnFrames){
                //     addOnFrames = {
                //         left: [[0.07, 0.14] , [0.07, 0.14]],
                //         right: [],
                //         bottom: [[0.06, 0.05]],
                //         top:[[0.07, 0.14]]
                //     }
                // }
                addOnFrames = addOn


                const { wWidthOffset, wHeightOffset, wLoffset, wRoffset, wTopOffset, wBOffset } = calculateOffsets(addOnFrames, windowData?.sill?.height > 0 ? (windowData?.sill?.height / 1000) : 0);

                allOffSet = {
                    wWidthOffset: wWidthOffset,
                    wHeightOffset: wHeightOffset,
                    wLoffset: wLoffset,
                    wRoffset: wRoffset,
                    wTopOffset: wTopOffset,
                    wBOffset: wBOffset,
                    sillHeight: windowData?.sill?.height > 0 ? (windowData?.sill?.height / 1000) : 0,
                }

                // console.log(allOffSet, "allOffSet")

                frameProfileRefs = [];
                removeHangingRefs();
                emptyTransomPartitions();
                removeSashRefs();
                jointRefs = [];
                groupName = "group" + (wdi + 1);
                await removeGroupByName(scene, groupName);
                const group = new THREE.Group();
                group.name = groupName;
                const { frame, sash, color, colorExt, joint, transom, dimensions, glass } = windowData // Extract frame data

                const materials = {};

                const sections = sash?.sections && sash?.sections?.length > 0 ? sash.sections : [];
                const sashSplits = sections?.length > 0 ? sections?.some(item => Array.isArray(item.splits) && item.splits.length > 0) : false;


                const { width: frameWidth, height: frameHeight } = dimensions || {};
                const glassThickness = 0.02; // Adjust as needed for glass thickness

                // Create a material for the frame
                const frameMaterial = createProfileMaterial(colorExt)
                const frameMaterial_Ext = createProfileMaterial(color)

                materials.frameMaterial = frameMaterial;
                materials.frameMaterial_Ext = frameMaterial_Ext;
                // Extrusion settings

                const pathsAndCorners = [
                    { pathType: '', cornerType: 'topRight' },
                    { pathType: '', cornerType: 'topLeft' },
                    { pathType: 'top', cornerType: 'bottomRight' },
                    { pathType: 'right', cornerType: 'bottomLeft' },
                    { pathType: 'bottom', cornerType: '' },
                    { pathType: 'left', cornerType: '' }

                ];
                const jointType = joint?.toLowerCase();
                let sashHangingValue = sash?.hangings;

                const frames = {};
                await pathsAndCorners.forEach(({ pathType, cornerType }) => {

                    const sideData = windowData?.frame?.sides[pathType || "top"]
                    let { width, height } = sideData?.dimensions;

                    const newPathName = pathType == 'top' ? "FrameTop" : pathType == 'right' ? "FrameRight" : pathType == 'bottom' ? "FrameBottom" : "FrameLeft";

                    let zOffset;
                    if (profileOrientation && profileType) {

                        const frameProfileHeight = profileType?.height / 1000;
                        const frameProfileWidth = profileType?.width / 1000;

                        const defaultWidth = frameProfileDefault?.width / 1000;

                        if (newPathName == profileOrientation) {
                            zOffset = (defaultWidth - frameProfileWidth) / 2;
                            width = frameProfileWidth;
                            height = frameProfileHeight;
                        } else {
                            zOffset = zOffsetMap[pathType] ?? 0
                        }
                    } else {
                        zOffset = 0
                    }


                    zOffsetMap[pathType] = zOffset;

                    const frameShape = getShapeCache(height, width)
                    let frameShapeExt = getShapeCache(height * 1.005, width * 1.005)
                    let frameMesh, extMesh;

                    let framePath = pathType != "" ? createFramePath(windowData, pathType, "Corner", wHeightOffset, wWidthOffset, wLoffset, wTopOffset, wBOffset) : null;
                    // framePathCollection.current.push(framePath);
                    let cornerPath = cornerType != "" ? createCornerPath(windowData, cornerType, frame.corners[cornerType].type, wHeightOffset, wWidthOffset, wLoffset, wTopOffset, wBOffset) : null;
                    // framePathCollection.current.push(cornerPath);
                    if (framePath) {

                        const frameGeometry1 = new THREE.ExtrudeGeometry(frameShape, extrudeSettings(framePath, 2));
                        // frames[pathType+"Path"] = framePath;
                        frames[pathType] = [framePath];
                        frames[pathType].push(height);
                        // updateCornerBevel(pathType, frameGeometry1, windowData.dimensions.height, windowData.dimensions.width);
                        let startOffset = true, endOffset = true;
                        let startAngle = 0, endAngle = 0;
                        if (pathType == "top") {
                            if (frame.corners["topLeft"].type == "Radius" || frame.corners["topLeft"].type == "Ellipse" || frame.sides.left.dimensions.height != frame.sides.top.dimensions.height) {
                                endOffset = false;
                            }
                            if (frame.corners["topRight"].type == "Radius" || frame.corners["topRight"].type == "Ellipse" || frame.sides.right.dimensions.height != frame.sides.top.dimensions.height) {
                                startOffset = false;
                            }
                            if (frame.corners["topRight"].type == "Chamfer") {
                                startAngle = getAngleFromLine(frames.topRight[0])
                            }
                            if (frame.corners["topLeft"].type == "Chamfer") {
                                endAngle = getAngleFromLine(frames.topLeft[0])
                            }
                        }

                        if (pathType == "bottom") {
                            if (frame.corners["bottomLeft"].type == "Radius" || frame.corners["bottomLeft"].type == "Ellipse" || frame.sides.left.dimensions.height != frame.sides.bottom.dimensions.height) {
                                endOffset = false;
                            }
                            if (frame.corners["bottomRight"].type == "Radius" || frame.corners["bottomRight"].type == "Ellipse" || frame.sides.right.dimensions.height != frame.sides.bottom.dimensions.height) {
                                startOffset = false;
                            }
                            if (frame.corners["bottomRight"].type == "Chamfer") {
                                startAngle = getAngleFromLine(frames.bottomRight[0])
                            }
                            if (frame.corners["bottomLeft"].type == "Chamfer") {
                                endAngle = getAngleFromLine(frames.bottomLeft[0])
                            }
                        }
                        if (pathType == "left") {
                            if (frame.corners["topLeft"].type == "Radius" || frame.corners["topLeft"].type == "Ellipse" || frame.sides.left.dimensions.height != frame.sides.top.dimensions.height) {
                                startOffset = false;
                            }
                            if (frame.corners["bottomLeft"].type == "Radius" || frame.corners["bottomLeft"].type == "Ellipse" || frame.sides.left.dimensions.height != frame.sides.bottom.dimensions.height) {
                                endOffset = false;
                            }
                            if (frame.corners["bottomLeft"].type == "Chamfer") {
                                startAngle = getAngleFromLine(frames.bottomLeft[0])
                            }
                            if (frame.corners["topLeft"].type == "Chamfer") {
                                endAngle = getAngleFromLine(frames.topLeft[0])
                            }
                        }
                        if (pathType == "right") {
                            if (frame.corners["topRight"].type == "Radius" || frame.corners["topRight"].type == "Ellipse" || frame.sides.right.dimensions.height != frame.sides.top.dimensions.height) {
                                startOffset = false;
                            }
                            if (frame.corners["bottomRight"].type == "Radius" || frame.corners["bottomRight"].type == "Ellipse" || frame.sides.right.dimensions.height != frame.sides.bottom.dimensions.height) {
                                endOffset = false;
                            }
                            if (frame.corners["bottomRight"].type == "Chamfer") {
                                startAngle = getAngleFromLine(frames.bottomRight[0])
                            }
                            if (frame.corners["topRight"].type == "Chamfer") {
                                endAngle = getAngleFromLine(frames.topRight[0])
                            }
                        }
                        updateFrameJoint(jointType, pathType, frameGeometry1, height, windowData?.dimensions?.height - wHeightOffset, windowData?.dimensions?.width - wWidthOffset, startOffset, endOffset, startAngle, endAngle, sillsHeightRef)
                        // jointType, pathPos, mesh, fheight, wheight, wwidth, startOffset, endOffset, startAngle, endAngle, framePath)
                        frameMesh = new THREE.Mesh(frameGeometry1, frameMaterial.clone());

                        frameMesh.castShadow = true;
                        frameMesh.receiveShadow = true;
                        frameMesh.visible = sideData?.isVisible ?? true;
                        // if (pathType == "right" || pathType == "left") frameMesh.material.shininess *= 1;

                        frameMesh.name = pathType == 'top' ? "FrameTop" : pathType == 'right' ? "FrameRight" : pathType == 'bottom' ? "FrameBottom" : "FrameLeft";
                        frameMesh.position.z -= zOffset;

                        if (pathType == "bottom") {
                            frameStyleBottom.current = frameMesh;
                        }

                        if (pathType == "right") {
                            frameStyleRight.current = frameMesh;
                        }

                        if (pathType == "top") {
                            frameStyleTop.current = frameMesh;
                        }

                        storeFrameMesh(frameMesh);
                        group.add(frameMesh);
                        // frames[pathType] = frameMesh;
                        frames[pathType].push(frameMesh);
                        const boundingBox = new THREE.Box3().setFromObject(frameMesh);
                        const boundHeight = boundingBox.max.y - boundingBox.min.y;


                        if (jointType != "mitre") {
                            const topObj = frames["top"][2];
                            const topObjBoundingBox = new THREE.Box3().setFromObject(topObj);
                            const topBotCorner = topObjBoundingBox.min.y;
                            if (pathType == 'left') {
                                const topLeftHeight = getWidthAndHeightFromLine(frames?.topLeft[0])?.height || frame.sides.top.dimensions.height;
                                const topOffset = frame.corners["topLeft"].type == "Corner" || frame.corners["topLeft"].size.height == 0 ? frame.sides.top.dimensions.height : 0;
                                const bottomOffset = frame.corners["bottomLeft"].type == "Corner" || frame.corners["bottomLeft"].size.height == 0 ? frame.sides.bottom.dimensions.height : 0;
                                const heightScale = (boundHeight - topOffset - bottomOffset) / boundHeight;
                                frameMesh.scale.y = heightScale;
                                const boundingBox2 = new THREE.Box3().setFromObject(frameMesh);
                                frameMesh.position.y += topBotCorner - boundingBox2.max.y - topLeftHeight + frame.sides.top.dimensions.height;
                                frameShapeExt.position = frameMesh.position;
                                frameShapeExt.scale = frameMesh.scale;
                            }
                            if (pathType == 'right') {
                                const topRightHeight = getWidthAndHeightFromLine(frames?.topRight[0])?.height || frame.sides.top.dimensions.height;
                                const topOffset = frame.corners["topRight"].type == "Corner" || frame.corners["topRight"].size.height == 0 ? frame.sides.top.dimensions.height : 0;
                                const bottomOffset = frame.corners["bottomRight"].type == "Corner" || frame.corners["bottomRight"].size.height == 0 ? frame.sides.bottom.dimensions.height : 0;
                                const heightScale = (boundHeight - topOffset - bottomOffset) / boundHeight;
                                frameMesh.scale.y = heightScale;
                                const boundingBox2 = new THREE.Box3().setFromObject(frameMesh);
                                frameMesh.position.y += topBotCorner - boundingBox2.max.y - topRightHeight + frame.sides.top.dimensions.height;
                            }

                        }

                        let externalColor = true; // this layer is intrnal according to color
                        if (externalColor) {
                            extMesh = frameMesh.clone();
                            extMesh.material = frameMaterial_Ext;
                            extMesh.name += "_ext";

                            // extMesh.position.set(frameMesh.position.x, frameMesh.position.y, frameMesh.position.z);
                            // extMesh.scale.set(frameMesh.scale.x, frameMesh.scale.y, frameMesh.scale.z);

                            extMesh.position.z -= 0.1 / 1000;
                            const offsetVal = 0.05 / 1000;

                            const beforeBound = new THREE.Box3().setFromObject(extMesh);
                            // frameGeometry1.computeBoundingBox();
                            // const beforeBound = frameGeometry1.boundingBox;
                            const beforeWidth = beforeBound.max.x - beforeBound.min.x;
                            const beforeHeight = beforeBound.max.y - beforeBound.min.y;
                            const xScaleVal = (beforeWidth + offsetVal) / beforeWidth;
                            const yScaleVal = (beforeHeight + offsetVal) / beforeHeight;
                            const xCenter = (beforeBound.max.x + beforeBound.min.x) / 2;
                            const xOffset = ((xCenter * (xScaleVal)) - xCenter) / 1;
                            const yCenter = (beforeBound.max.y + beforeBound.min.y) / 2;
                            const yOffset = ((yCenter * (yScaleVal)) - yCenter) / 1;
                            extMesh.scale.x *= xScaleVal;
                            extMesh.scale.y *= yScaleVal;
                            extMesh.position.x -= pathType == "left" ? (xOffset + offsetVal) : pathType == "right" ? (xOffset - offsetVal) : xOffset;
                            extMesh.position.y -= pathType == "bottom" ? (yOffset + offsetVal) : pathType == "top" ? (yOffset - offsetVal) : yOffset;

                            group.add(extMesh);
                        }

                        // calculate the position of each frameprofile

                        const center = new THREE.Vector3()
                        boundingBox.getCenter(center)

                        const width = boundingBox.max.x - boundingBox.min.x;

                        // Calculate the right and left ends
                        const rightEnd = new THREE.Vector3(center.x + width / 2, center.y, center.z);
                        const leftEnd = new THREE.Vector3(center.x - width / 2, center.y, center.z);
                        // create the copy of old mesh and then update it with calculated position

                        if (pathType === "top" || pathType === "bottom") {
                            const rightEndMesh = {
                                position: { x: rightEnd?.x, y: rightEnd?.y, z: rightEnd?.z },
                                name: `${pathType}Right`
                            }

                            const leftEndMesh = {
                                position: { x: leftEnd?.x, y: leftEnd?.y, z: leftEnd?.z },
                                name: `${pathType}Left`
                            }
                            storeJointsRef(rightEndMesh);
                            storeJointsRef(leftEndMesh);
                        }


                        const framePos = {
                            position: { x: center?.x, y: center?.y, z: center?.z },
                            name: frameMesh.name
                        }
                        storeFrameProfileRef(framePos, getFrameRefs)

                        const centerZ = (boundingBox.min.z + boundingBox.max.z) / 2;

                        // Use this center value for your frame position
                        const addOnPos = {
                            position: { x: center?.x, y: center?.y, z: centerZ }, // Use calculated centerZ
                            name: frameMesh.name
                        };
                        storeAddOnRef(addOnPos)
                    }

                    if (cornerType && (frame.corners[cornerType].type === "Corner" || frame.corners[cornerType].type === "corner")) {
                        cornerPath = "";
                    }


                    if (cornerPath) {
                        const steps = frame.corners[cornerType].type == "Chamfer" ? 2 : 100;
                        const pathAngle = frame.corners[cornerType].type == "Chamfer" ? getAngleFromLine(cornerPath) : "";
                        let shape = frameShape.clone();
                        frames[cornerType] = [cornerPath];
                        if (pathAngle > 0) {
                            let scaleFactor = 1.2;
                            // if (Math.round(pathAngle) != 45) scaleFactor = 1 + (90 - pathAngle) / 90;
                            if (Math.round(pathAngle) != 45) scaleFactor = 1 + (pathAngle / 45) * 0.2
                            // if (Math.round(pathAngle) != 45) scaleFactor *= (1 + ((22.5 - pathAngle) / 45));
                            // if (Math.round(pathAngle) != 45) scaleFactor = 3 - (((45 + pathAngle) / 45));
                            // if (Math.round(pathAngle) != 45) scaleFactor = 1 * (90 - pathAngle) / 90;
                            if (frame.corners[cornerType].type != "Chamfer") scaleFactor = 1;
                            // const scaleFactor = (1.35 * pathAngle) / 45;
                            shape = createShapeFromWidthHeight(height, width * scaleFactor);
                            frameShapeExt = createShapeFromWidthHeight(height * 1.005, width * scaleFactor * 1.005);
                            frames[cornerType].push(height);
                        }

                        const cornerGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings(cornerPath, steps));

                        cornerGeometry.morphTargetsRelative = true;
                        cornerGeometry.lookAt = new THREE.Vector3(-1, -1, 0)
                        const cornerMesh = new THREE.Mesh(cornerGeometry, frameMaterial.clone());
                        cornerMesh.castShadow = true;
                        // cornerMesh.scale.set(1.4, 1.4, 1)
                        cornerMesh.receiveShadow = true;
                        cornerMesh.material.shininess = frameMaterial.shininess * 0.9;
                        group.add(cornerMesh);
                        let externalColor = false;
                        if (externalColor) {
                            const cornerGeometryExt = new THREE.ExtrudeGeometry(frameShapeExt, extrudeSettings(cornerPath, steps));
                            const extMesh = new THREE.Mesh(cornerGeometryExt, frameMaterial.clone());
                            extMesh.position.set(frameMesh.position.x, frameMesh.position.y, frameMesh.position.z);
                            extMesh.scale.set(frameMesh.scale.x, frameMesh.scale.y, frameMesh.scale.z);
                            extMesh.material = frameMaterial_Ext;
                            extMesh.position.z -= 0.5 / 1000;
                            const offsetVal = 1 / 1000;
                            cornerGeometryExt.computeBoundingBox();
                            const beforeBound = cornerGeometryExt.boundingBox;
                            const beforeWidth = beforeBound.max.x - beforeBound.min.x;
                            const beforeHeight = beforeBound.max.y - beforeBound.min.y;
                            const xScaleVal = (beforeWidth + offsetVal) / beforeWidth;
                            const yScaleVal = (beforeHeight + offsetVal) / beforeHeight;
                            const xCenter = (beforeBound.max.x + beforeBound.min.x) / 2;
                            const xOffset = ((xCenter * (xScaleVal)) - xCenter) / 1;
                            const yCenter = (beforeBound.max.y + beforeBound.min.y) / 2;
                            const yOffset = ((yCenter * (yScaleVal)) - yCenter) / 1;
                            extMesh.scale.x = xScaleVal;
                            extMesh.scale.y = yScaleVal;
                            extMesh.position.x -= xOffset;
                            extMesh.position.y -= yOffset;
                            group.add(extMesh);
                        }
                        frames[cornerType].push(cornerMesh);
                    }
                });

                // setTimeout(() => {
                Object.keys(frames).forEach(async (key) => {
                    if (frame?.sides[key]) {
                        const profile = frames[key][2];
                        const trickleVent = frame?.sides[key] ? frame?.sides[key]?.tricklevent : [];
                        if (trickleVent && trickleVent?.length) {
                            // const triclkleVentCount = trickleVent?.length;
                            const trickleModel = await Promise.all(
                                trickleVent.map(async (item) => {
                                    const hardware = await loadHardware(item.model);
                                    const hardwareClone = hardware.clone()
                                    group.add(hardwareClone); // Add to group immediately
                                    return hardwareClone;
                                })
                            );

                            allTrickleVents.current = trickleModel
                            // Call addHardwareInSteps after all hardware models are loaded
                            await addHardwareInSteps(profile, allTrickleVents, trickleVent);
                            // const modelClone = transformedModel.clone();
                        }

                        const spyHole = frame?.sides[key] ? frame?.sides[key]?.spyhole : false;

                        if (spyHole) {
                            const hardware = await loadHardware(spyHole.model);
                            const positionSpyHole = await positionHardwareOnFrame(spyHole, profile, hardware, "inside")
                            const spyHoleClone = positionSpyHole.clone();
                            group.add(spyHoleClone);
                        }

                    }
                })
                // }, 0);

                function removeBaseGlass() {
                    scene?.traverse((child) => {
                        if ((child instanceof THREE.Mesh)) {
                            if (child.name.includes("GlassPanel")) {
                                scene.remove(child);
                            }
                        }
                    })
                }

                // Create the initial material
                let glassMaterial = createGlassMaterial(glass?.color)

                if(windowData && windowData.textures && windowData.textures.isTexture && windowData.textures.texturePath){
                    assignTexture(windowData.textures.texturePath, glassMaterial);
                }

                const panelMaterial = createPanelMatrial(glass?.color)

                // materials.glassTextureMaterial = glassTextureMaterial;
                materials.glassMaterial = glassMaterial;
                materials.panelMaterial = panelMaterial;
                let isMasterSlave = true, masterSlave;
                const partialWidthRatios = windowData?.sash?.partialWidthRatios;
                const partialHeightRatios = windowData?.sash?.partialHeightRatios;

                if (sashHangingValue > -1) {
                    removeBaseGlass();

                    // const partialWidthRatios = [0.3, 0.4000000000000001, 0.3];

                    // function convertToRatios(sashSizes) {
                    //     const totalSize = sashSizes.reduce((acc, size) => acc + size, 0); // Calculate total sum
                    //     if (totalSize === 0) return []; // Avoid division by zero
                    //     return sashSizes.map(size => (size / totalSize)); // Convert each size to ratio and round to 2 decimal places
                    // }

                    if (!masterSlave) {
                        // masterSlave = {
                        //     mullion: {
                        //         width: 0.07,
                        //         depth: 0.03
                        //     }
                        // }
                        // sashHangingValue = 2;
                    }

                    // createSash(sashes, group, frame.sides, windowData.dimensions.width - wWidthOffset, windowData.dimensions.height - wHeightOffset, sashHangingValue, sashGroup, partialWidth, allTrickleVents, masterSlave, windowData);
                    group.isContainTransoms = false;
                    // console.log("scene sashsplits :::: ", scene?.sashSplits); 
                    if (sashHangingValue) {
                        let splitArray = scene?.sashSplits ? scene?.sashSplits : Array(sashHangingValue).fill(1);
                        if (scene?.sashSplits?.length || 0 != sashHangingValue) splitArray = Array(sashHangingValue).fill(1);
                        await createSash(sash, group, frame?.sides, windowData?.dimensions?.width - wWidthOffset, windowData?.dimensions?.height - wHeightOffset, sashHangingValue, sashGroup, partialWidthRatios, allTrickleVents, masterSlave, windowData, wLoffset, wTopOffset, wBOffset, framePathCollection, modelName, splitArray);
                    }
                    // if(group.isContainTransoms){
                    // setTimeout(async () => {
                    //     console.clear();
                    //     const splitValues = await getSplitValue(group , group, true); 
                    //     storeTransomPartialDimensions(splitValues) 
                    //     // partialDimensionRef.current = true;                          
                    // }, 100);
                    // }else{
                    // let values = {
                    //     heightSplit: [],
                    //     widthSplit: []
                    // }
                    // storeTransomPartialDimensions(values)  
                    // partialDimensionRef.current = true;   
                    // }
                }




                // let isMasterSlave = true, masterSlave;
                // if(isMasterSlave){
                //     removeBaseGlass();
                //     const partialWidth = windowData.sash.sashSize;
                //     if(!masterSlave){
                //         masterSlave = {
                //             mullion: {
                //                 width: 0.7,
                //                 depth: 0.3
                //             }
                //         }
                //     }
                //     console.clear();

                //     createSash(sash, group, frame.sides, windowData.dimensions.width - wWidthOffset, windowData.dimensions.height - wHeightOffset, 2, sashGroup, partialWidth, allTrickleVents, masterSlave);
                // }


                if (parseInt(sashHangingValue) <= 0 && transom && !transom.splits) {
                    const glassExtrudeSettings = {
                        depth: glassThickness,
                        bevelEnabled: false,
                    };

                    const combinedOutlinePath = createCombinedOutlinePath(windowData, wHeightOffset, wWidthOffset, wLoffset, wTopOffset, wBOffset);
                    framePathCollection.current.push(combinedOutlinePath);
                    const glassGeometry = new THREE.ExtrudeGeometry(combinedOutlinePath, glassExtrudeSettings);
                    const glassMesh = new THREE.Mesh(glassGeometry, materials.glassMaterial);

                    const isPanelEnabled = windowData?.panels?.isPanel || false; //panels *****
                    // const isPanelEnabled = false; //panels *****
                    console.log(modelName, "832");
                    if (!isPanelEnabled) {

                        let lockingPlate = "";
                        lockingPlate = modelName === "Heritage Door" ? "LockingPlate" : "";

                        glassMesh.name = `${glassMesh.name}`;
                        glassMesh.scale.set(0.999, 0.999, 1);
                        glassMesh.position.z = -glassThickness * 0.5;
                        glassMesh.renderOrder = -10;
                        group.add(glassMesh);
                    } else {
                        const frameMaterial_Ext = createProfileMaterial(colorExt)

                        const panelMesh = new THREE.Mesh(glassGeometry.clone(), frameMaterial_Ext);

                        panelMesh.name = `GlassPanel_0`;
                        panelMesh.scale.set(0.999, 0.999, 1);

                        panelMesh.position.z = (windowData.frame.sides.top.dimensions.height * 0.5) - (glassThickness * 0.5) - 0.011;
                        group.add(panelMesh);

                        let externalColor = true;
                        if (externalColor) {
                            createExternalMesh(panelMesh, panelMaterial, glassGeometry, group)
                        }
                    }
                    hangingRefs.push(glassMesh)
                    storeTransomPartitionRefs(glassMesh)
                }

                console.log(isTranomTriggered, "907");

                if (transom?.splits && transom?.splits[0]?.sections?.length > 1) {
                    removeBaseGlass();
                    let frameWidths = {
                        top: frame.sides.top.dimensions.height,
                        bottom: frame.sides.bottom.dimensions.height,
                        left: frame.sides.left.dimensions.height,
                        right: frame.sides.right.dimensions.height,
                    }
                    const transomGroup = createTransomGlassPath(frameWidth - wWidthOffset, frameHeight - wHeightOffset, frameWidths, transom, materials, colorExt, group, isTranomTriggered, partialWidthRatios, partialHeightRatios, frameWidth, frameHeight, framePathCollection);
                    group.add(transomGroup);
                }

                if ((!transom?.splits || !transom?.splits[0]?.sections?.length > 1) && !sashSplits) {
                    emptyTransomRefs()
                }

                if (addOnFrames && (addOnFrames?.left?.length || addOnFrames?.bottom?.length || addOnFrames?.top?.length || addOnFrames?.right?.length)) {
                    // setTimeout(() => {
                    createAddons(addonRef, scene, addOnFrames, frames, frameStyleBottom, group, color);
                    // }, 0);
                }

                if (windowData?.sill && windowData?.sill?.height && windowData?.sill?.width) {
                    addSill(windowData, sillRef, scene, frameStyleBottom, setDefaultSillScale, sillsHeightRef, sillExtRef)
                }


                if (wdi != 0) {

                    group.name = "group" + (wdi + 1);
                    const groupBounding = new THREE.Box3().setFromObject(group);
                    const relWindowData = getRelWindow(scene, windowDataFull[wdi].relWindow);
                    const relWindow = scene.getObjectByName(relWindowData[1]);
                    let angle = relWindowData[0] == "left" ? 180 - windowDataFull[wdi].angle : windowDataFull[wdi].angle;
                    const hadPaypost = windowDataFull[wdi].payPost[relWindowData[0]];
                    const cobblerData = windowDataFull[wdi].cobblerData[relWindowData[0]];
                    if (angle == 180) angle = 0
                    if (hadPaypost) connectPaypostCobbler(scene, group, relWindowData, angle, relWindowData[0], hadPaypost, materials.frameMaterial, cobblerData);
                    group.rotation.y = THREE.MathUtils.degToRad(angle);
                    subWindPosChange(scene, group, relWindowData, groupBounding.max.z - groupBounding.min.z, angle, hadPaypost, cobblerData);
                    // connectPaypostCobbler();
                }
                group.name = "group" + (wdi + 1)
                scene?.add(group);

                // const svgElement =  createSVGElement(windowData);
                // seq number start Arasu
                // addSpritesSqNo(scene, "GlassPanel", ['#bfbfbf', '#000000']);
                // let number = await addSpritesSqNo(scene, "Frame", ['#b30c15', '#ffffff']);
                // number = addSpritesSqNo(scene, "Sash", ['#0a50a1', '#ffffff'], number);
                // addSpritesSqNo(scene, "sill", ['#06a125', '#ffffff'], number);
                // document.body.appendChild(svgElement);
                // const html = document.getElementsByClassName("window-svg")[0].outerHTML;
                // document.getElementsByClassName("window-svg")[0].innerHTML="";
                // setTimeout(() => {
                //     document.getElementsByClassName("window-svg")[0].innerHTML=html;
                // }, 1);
            })
        } catch (error) {
            console.error(`Error getting data ${error}`);

        }
    }
}




function subWindPosChange(scene, group, relWindowData, width, angle, paypost, cobblerData) {
    // console.clear();
    const relWindow = scene.getObjectByName(relWindowData[1]);
    const relWindowBounding = new THREE.Box3().setFromObject(relWindow);
    let groupBounding = new THREE.Box3().setFromObject(group);
    const side = relWindowData[0];
    let offset = getHeightFromAngle(angle, width);
    const isPerpendicular = angle == 90 ? true : false;
    if (isPerpendicular) {
        offset = 0;
        if (!paypost) offset = side == "left" ? width : -width;
    }
    if (angle > 0) {
        if (side == "left") {
            group.position.x += relWindowBounding.max.x - groupBounding.min.x;
            group.position.z -= relWindowBounding.max.z - groupBounding.min.z - offset;
        }
        if (side == "right") {
            group.position.x += relWindowBounding.min.x - groupBounding.max.x;
            group.position.z -= relWindowBounding.max.z - groupBounding.min.z + offset;
        }
        if (side == "bottom") {
            group.rotation.y = relWindow.rotation.y;
            groupBounding = new THREE.Box3().setFromObject(group);
            group.position.y += groupBounding.max.y - relWindowBounding.min.y;
            group.position.z = relWindowBounding.max.z - ((groupBounding.max.z - groupBounding.min.z) / 2)
            group.position.x = relWindowBounding.max.x - ((groupBounding.max.x - groupBounding.min.x) / 2)
        }
    } else {
        cobblerData = cobblerData && Array.isArray(cobblerData) && cobblerData.length > 0 ? cobblerData : [0.02, 0.01];
        if (side == "left") {
            group.position.x += relWindowBounding.max.x - groupBounding.min.x;
        }
        if (side == "right") {
            group.position.x += relWindowBounding.min.x - groupBounding.max.x;
        }
        if (side == "bottom") {
            group.rotation.y = relWindow.rotation.y;
            groupBounding = new THREE.Box3().setFromObject(group);
            group.position.y += groupBounding.max.y - relWindowBounding.min.y + cobblerData[0];
            group.position.z = relWindowBounding.max.z - ((groupBounding.max.z - groupBounding.min.z) / 2)
            // group.position.x = relWindowBounding.max.x - ((groupBounding.max.x - groupBounding.min.x)/2)
            group.position.x = relWindow.position.x
        }
    }

}


function getRelWindow(scene, relWindow) {
    const firstGroupInfo = Object.entries(relWindow).find(([key, groupName]) => {
        if (groupName) { // Ignore empty values
            const group = scene.getObjectByName(groupName);
            return !!group;
        }
        return false;
    });

    return firstGroupInfo;
}



function removeGroupByName(scene, groupName) {
    const groupToRemove = scene?.getObjectByName(groupName);

    if (groupToRemove) {
        scene.remove(groupToRemove);
        groupToRemove.traverse((child) => {
            if (child.geometry) child.geometry.dispose();
            if (child.material) {
                if (Array.isArray(child.material)) {
                    child.material.forEach((material) => material.dispose());
                } else {
                    child.material.dispose();
                }
            }
        });
    } else {
        console.log(`Group with name ${groupName} not found.`);
    }
}


// Example usage:
function getWidthAndHeightFromLine(lineCurve) {
    // Retrieve start and end points of the line
    const v1 = lineCurve.v1 || lineCurve.points[0];
    const v2 = lineCurve.v2 || lineCurve.points[lineCurve.points.length - 1];

    // Calculate the differences in coordinates
    const deltaX = Math.abs(v2.x - v1.x);
    const deltaY = Math.abs(v2.y - v1.y);

    // Return width and height
    return {
        width: deltaX,
        height: deltaY,
    };
}