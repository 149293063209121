import React, { useEffect, useState } from "react";
import { BiPlus, BiTrashAlt } from "react-icons/bi";
import { MdArrowDownward, MdArrowUpward, MdContentCopy } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import { useMessage } from "../../../../../../context/messageContext";
import {
  deleteRightItem,
  updateOrder,
} from "../../../../../../services/sysFrameProductsService";
import { Image } from "react-bootstrap";
import { copyItem } from "../../../../../../services/commonServices";
import { handleError } from "../../../../../../utility/commonHelper";

const DocumentsRight = ({
  accessToken,
  collectionId,
  activeTab,
  allRightListItems,
  setLoading,
  getAllSysFrameProductsData,
  addNewRightItemServices,
  selectItemDetails,
}) => {
  // const [previouseSelected, setPreviouseSelected] = useState({ prevId: 1, prevIndex: 1 })
  // const [currentSelected, setCurrentSelected] = useState({ currId: 1, currIndex: 1 })
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();

  const {
    timestamp,
    selectedRightItem,
    fetchSelected,
    toDelete,
    fetchDeleteData,
    newItemList,
    setNewItemList,
    handleActiveIndexUp,
    handleActiveIndexDown,
    activeIndex,
    setActiveIndex,
  } = useRightContext();

  const { setMessage, setMessageType } = useMessage();

  useEffect(() => {
    const assignItemValue = allRightListItems?.map((item) => {
      return {
        name: item.name,
        description: item.description,
        imageUrl: item?.profilePathUrl,
        id: item.id,
        isEnabled: item?.isEnabled,
        isSelf: item?.isSelf,
      };
    });

    setNewItemList(assignItemValue);
  }, [allRightListItems]);

  // useEffect(() => {
  // 	setActiveIndex(0)
  // }, [activeTab])

  const handleItemClick = (currentIndex, id) => {
    // setCurrentSelected({ currId: id, currIndex: currentIndex + 1 });
    // if (currentSelected) {
    // 	setPreviouseSelected({
    // 		prevId: currentSelected.currId,
    // 		prevIndex: currentSelected.currIndex,
    // 	});
    // }
    fetchSelected(currentIndex, id);
  };

  // api to delete right side items
  const deleteRightItemServices = async () => {
    setIsDeleting(true);
    setLoading(true);

    const res = await deleteRightItem(accessToken, toDelete?.id);

    // console.log(res, "res delete doc")

    if (res && res && res.status === 200) {
      setShowDeleteModal(false);

      fetchSelected(0, allRightListItems[0]?.id);

      if (res?.data?.statusCode === 200) {
        setMessage(
          res?.data?.message
            ? res.data.message
            : "Record removed successfully !"
        );
        setMessageType("success");

        getAllSysFrameProductsData(collectionId);
        setIsDeleting(false);
      } else {
        setIsDeleting(false);
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setIsDeleting(false);
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      setShowDeleteModal(false);
      getAllSysFrameProductsData(collectionId);

      handleError(res, false, navigate);
    }
  };

  // api to update right side item
  const updateOrderServices = async (direction) => {
    setLoading(true);

    const res = await updateOrder(
      accessToken,
      timestamp,
      direction,
      collectionId
    );

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        getAllSysFrameProductsData(collectionId);
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      handleError(res, false, navigate);
    }
  };

  const handleItemUp = () => {
    handleActiveIndexUp();
    updateOrderServices("UP");
  };

  const handleItemDown = () => {
    handleActiveIndexDown();
    updateOrderServices("DOWN");
  };

  const copyPaletteServices = async (id) => {
    setLoading(true);
    const res = await copyItem(accessToken, id, 21);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(res?.data?.message ? res.data.message : "Item copied");
        getAllSysFrameProductsData(collectionId);
        setLoading(false);
      } else {
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
        getAllSysFrameProductsData(collectionId);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      getAllSysFrameProductsData(collectionId);

      handleError(res, false, navigate);
    }
  };

  return (
    <>
      <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_btns">
          <div className="right_list">
            {newItemList?.map((item, index) => (
              // add has_delete and has_copy class in below div based on availability of icons in action_btns div
              <div
                key={index}
                className="position-relative d-flex align-items-center right_item_wrap has_delete has_copy"
              >
                <Link
                  to="#"
                  className={`right_item w-100 ${
                    activeIndex === index && "active"
                  }`}
                  onClick={() => handleItemClick(index, item.id)}
                >
                  <span className="me-2 image_icon">
                    {/* {item.imageUrl ? (
											<Image src={item.imageUrl} />
										) : (
											<span>
												<svg viewBox="0 0 100 50" width="55" height="55"><path className="stroke" stroke='#989898' fill='#fff' strokeWidth="2" d={`M 0,50 0,0 100,0 100,50 Z`}></path></svg>
											</span>
										)} */}

                    {item?.imageUrl ? (
                      <>
                        {item?.imageUrl?.includes("https") ? (
                          <Image src={item?.imageUrl} alt="icon" />
                        ) : (
                          <Image
                            src={`data:image/svg+xml;base64,${item?.imageUrl}`}
                            alt="icon"
                          />
                        )}
                      </>
                    ) : (
                      <span>
                        <svg viewBox="0 0 50 20" width="55" height="45">
                          <path
                            className="stroke"
                            stroke="#989898"
                            fill="#fff"
                            strokeWidth="2"
                            d={`M 0,20 0,0 50,0 50,20 Z`}
                          ></path>
                        </svg>
                      </span>
                    )}
                  </span>
                  <div className="right_title">
                    <span className="text-truncate">{item.name}</span>
                    <span className="text-truncate d-block text-small">
                      {item.description}
                    </span>
                  </div>
                </Link>
                <span className="position-absolute action_btns">
                  {item?.isSelf && (
                    <>
                      {newItemList?.length > 1 && (
                        <Link
                          to="#"
                          onClick={() => {
                            setShowDeleteModal(true);
                            fetchDeleteData(index, item.id);
                          }}
                        >
                          {" "}
                          <BiTrashAlt className="text-muted" size={20} />{" "}
                        </Link>
                      )}
                      <Link
                        to="#"
                        onClick={() => copyPaletteServices(item?.id)}
                      >
                        {" "}
                        <MdContentCopy className="text-muted" size={18} />{" "}
                      </Link>
                    </>
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Start: space for buttons at the bottom */}
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          {selectedRightItem?.isSelf && (
            <Button
              color="primary"
              size="md"
              className="px-4"
              onClick={() =>
                addNewRightItemServices(
                  `Product ${
                    newItemList && newItemList.length
                      ? newItemList.length + 1
                      : 1
                  }`,
                  1,
                  `${
                    newItemList && newItemList.length ? newItemList.length : 0
                  }`
                )
              }
            >
              <BiPlus size={20} className="me-1" />
              New
            </Button>
          )}

          {selectedRightItem?.isSelf && (
            <>
              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => handleItemUp()}
                disabled={timestamp === "" || activeIndex === 0}
              >
                <MdArrowUpward size={22} className="me-1" />
                Up
              </Button>
              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => handleItemDown()}
                disabled={
                  timestamp === "" ||
                  allRightListItems?.length - 1 === activeIndex
                }
              >
                <MdArrowDownward size={22} className="me-1" />
                Down
              </Button>
            </>
          )}
        </div>
        {/* End: space for buttons at the bottom */}
      </div>

      <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
        <ModalHeader>Delete Selected Item</ModalHeader>
        <ModalBody>
          Are you sure you want delete <strong>{toDelete?.name}</strong> ?
        </ModalBody>
        <ModalFooter>
          <Button
            color="outline-danger"
            size="md"
            disabled={isDeleting}
            onClick={() => deleteRightItemServices()}
          >
            Delete
          </Button>
          <Button
            onClick={() => setShowDeleteModal(false)}
            color="primary"
            size="md"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DocumentsRight;
