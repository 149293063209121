import React, { useEffect, useLayoutEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { getCountForStatus } from "../../utility/commonHelper";
import { useDesigner } from "../../context/designerContext";
import { Alert, Button } from "reactstrap";
import { MdInfoOutline } from "react-icons/md";
import { BiTrash } from "react-icons/bi";
import { getSashHangingData } from "../../services/3dModelServices";
import { capitalizeFirstLetter } from "../../utility/helper";
import { saveModelData, saveModelDataQuotation } from "../../utility/designerHelper";
import { useNavigate } from "react-router-dom";
import { useMessage } from "../../context/messageContext";

const HandleHanging = ({
    collectionDetails,
    accessToken,
    collectionId,
    sashProductId,
    frameStyleProdId,
    hideDelete,
    quotationId,
    fromPanelDesigner,
    receivedProductId
}) => {

    const {
        customModelData,
        setCustomModelData,
        setWindowHandleOrientation,
        setDeleteSashHanging,
        multiSelectRefPoints,
        setSashHangingNo,
        modelJson,
        setModelJson,
        jsonIndex
    } = useDesigner();

    const [sashHangingData, setSashHangingData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [showSashHangingAlert, setShowSashHangingAlert] = useState(false);
    const [isSave, setIsSave] = useState(false)

    const navigate = useNavigate();
    const { setMessage, setMessageType } = useMessage()

    useEffect(() => {
        getCountForStatus(sashHangingData, setShowSashHangingAlert);
    }, [sashHangingData]);



    useEffect(() => {
        if (multiSelectRefPoints[0] && sashProductId && frameStyleProdId) {
            getSashDataService(multiSelectRefPoints[0]?.index);
        }
    }, [multiSelectRefPoints[0]]);

    // This is for saving the data of the hanging type from the model json
    useEffect(() => {
        let tempWindowData = [];
        if (modelJson?.length > 0) {
            modelJson.forEach((model, index) => {
                if (index === jsonIndex) {
                    if (model?.sash?.sections?.length > 0) {
                        model?.sash?.sections?.forEach((ele, index) => {
                            let json = {
                                index: index,
                                isHangingAdded: true,
                                handleDirection: ele?.type,
                                hangingType: capitalizeFirstLetter(ele?.type),
                            };
                            tempWindowData.push(json);
                        });
                    }
                }
            });
        }
        if (tempWindowData?.length > 0) {
            setCustomModelData((prevModelData) => ({
                ...prevModelData,
                windowData: tempWindowData,
            }));
        } else {
            setCustomModelData((prevModelData) => ({
                ...prevModelData,
                windowData: [],
            }));
        }
        if (fromPanelDesigner) {
            setIsSave(true)
        }
        else {
            setIsSave(false)
        }
        setTimeout(() => {
            setIsSave(false)
        }, 200)
    }, [modelJson]);

    // This will enable the selected fix type
    useEffect(() => {
        if (
            multiSelectRefPoints?.length > 0 &&
            customModelData?.windowData &&
            customModelData?.windowData?.length > 0 &&
            sashHangingData &&
            sashHangingData?.length > 0
        ) {
            let searchedItem = null;
            multiSelectRefPoints?.forEach((item) => {
                searchedItem = customModelData?.windowData?.find(
                    (ele) => ele?.index === item?.index
                );
            });
            if (searchedItem) {
                let index = sashHangingData?.findIndex(
                    (item) => item?.hangingName == searchedItem?.hangingType
                );
                setActiveIndex(index);
            } else {
                setActiveIndex(-1);
            }
        }
        else {
            setActiveIndex(-1)
        }
    }, [multiSelectRefPoints, sashHangingData, customModelData?.windowData]);

    useEffect(() => {
        if (isSave && !quotationId && collectionId && frameStyleProdId) {
            saveModelData(
                accessToken,
                collectionId,
                frameStyleProdId?.id,
                customModelData,
                modelJson,
                navigate,
                setMessage,
                setMessageType
            );
        } else if (isSave && quotationId && collectionId && frameStyleProdId) {
            saveModelDataQuotation(
                accessToken,
                collectionId,
                frameStyleProdId?.id,
                customModelData,
                quotationId,
                receivedProductId,
                navigate,
                setMessage,
                setMessageType,
                modelJson
            );
        }
        setTimeout(() => {
            setIsSave(false);
        }, 200);
    }, [isSave]);

    const getSashDataService = async (referencePoint) => {
        let data = {
            collectionId: collectionId,
            sashProductId: sashProductId?.id,
            frameStyleId: frameStyleProdId?.id,
            referencePoint: referencePoint,
        };
        const res = await getSashHangingData(accessToken, data);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setSashHangingData(res?.data?.entity);
            }
        }
    };

    const handleSashAdd = (item, index) => {
        if (index === activeIndex) {
            setActiveIndex(null); // Reset the state first
            setTimeout(() => setActiveIndex(index), 0); // Set it back after a tiny delay
        } else {
            setActiveIndex(index);
        }

        // var handleJson = {
        //     isHangingAdded: true,
        //     hangingType: item.hangingName,
        //     hangingId: item.id,
        //     handleDirection: "fixed",
        //     productMasterId: item.sashProductMasterId,
        //     // index: checkSingleSide,
        //     index: multiSelectRefPoints[0]?.index,
        //     position: checkClickedProfile?.position,
        // };
        // // setCustomModelData((prevData = {}) => ({
        // //   ...prevData,
        // //   windowData: [
        // //     ...(prevData?.windowData ? prevData?.windowData : []),
        // //     handleJson
        // //   ],
        // // }));
        setWindowHandleOrientation(null);
        setTimeout(() => setWindowHandleOrientation(item.hangingName), 0);
    };

    const handleDeleteHanging = () => {
        setDeleteSashHanging(true);
        setSashHangingNo(0)

        if (collectionDetails && collectionDetails.typeId == 2) {
            var handleJson = {
                isHangingAdded: false,
                hangingType: "",
                hangingId: "",
                handleDirection: "",
            };

            setCustomModelData((prevData) => ({
                ...prevData,
                windowData: handleJson,
                numberOfSash: {
                    number: 0
                },
                partialWidthRatios: [1],
                partialHeightRatios:[1],
                sashSizeHeight:[]
            }));

            setModelJson((prevModelJson)=>{
                const updateModelArray = [...prevModelJson]
				const updateJson = { ...updateModelArray[jsonIndex] }
				updateJson.sash.partialHeightRatios = [1];
				updateModelArray[jsonIndex] = updateJson;
				return updateModelArray;
            })            

            setWindowHandleOrientation("");
            setIsSave(true)
        }
        setTimeout(() => {
            setDeleteSashHanging(false)
        }, 1000);
    };


    return (
        <>
            {multiSelectRefPoints[0] ? (
                <>
                    <div className="mt-1 right_list">
                        {sashHangingData &&
                            sashHangingData?.map((item, index) => (
                                <React.Fragment key={index}>
                                    {item?.status && !showSashHangingAlert && (
                                        <div
                                            className="position-relative d-flex align-items-center right_item_wrap"
                                        >
                                            <div
                                                // className={`right_item w-100 
                                                //     ${customModelData?.windowData?.hangingId
                                                //     ? customModelData?.windowData
                                                //         ?.hangingId === item.id &&
                                                //     "active"
                                                //     : ""
                                                //     }`
                                                // }
                                                className={`right_item w-100 ${index == activeIndex ? "active" : ""}`}
                                                onClick={() => handleSashAdd(item, index)}
                                            >
                                                <span className="me-2 image_icon">
                                                    {item.imagePath ? (
                                                        <Image src={item.imagePath} />
                                                    ) : (
                                                        <svg
                                                            viewBox="0 0 100 50"
                                                            width="100"
                                                            height="50"
                                                        >
                                                            <path
                                                                className="stroke"
                                                                stroke="#989898"
                                                                fill="#fff"
                                                                strokeWidth="2"
                                                                d={`M 0,50 0,0 100,0 100,50 Z`}
                                                            ></path>
                                                        </svg>
                                                    )}
                                                </span>

                                                <div className="right_title">
                                                    <span className="text-truncate">
                                                        {item.name}
                                                    </span>
                                                    <span className="text-truncate d-block text-small">
                                                        {item.description}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}

                        {showSashHangingAlert && (
                            <Alert
                                color="secondary"
                                className="d-flex gap-2 p-2 my-2"
                            >
                                <span>
                                    <MdInfoOutline size={18} />
                                </span>
                                <span>
                                    Sash Hanging is not enabled for this reference
                                    point.
                                </span>
                            </Alert>
                        )}
                    </div>

                    {!hideDelete && sashHangingData && sashHangingData.length > 0 && (
                        <Button
                            color="light"
                            size="md"
                            className="px-4 w-100 d-flex justify-content-between fw-medium mb-2 mt-4"
                            onClick={() => handleDeleteHanging()}
                        >
                            <BiTrash size={20} />
                            Delete
                            <span></span>
                        </Button>
                    )}
                </>
            ) : (
                <>
                    <Alert
                        color="secondary"
                        className="d-flex gap-2 p-2 my-2"
                    >
                        <span>
                            <MdInfoOutline size={18} />
                        </span>
                        <span>Select Ref point to add hanging</span>
                    </Alert>
                </>
            )}
        </>
    );
};

export default HandleHanging;
