import * as THREE from 'three';
import html2canvas from 'html2canvas';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

function calculateBoundingBox(object, boundingBox) {
    if (object.isMesh) {
        object.geometry.computeBoundingBox();
        const objectBoundingBox = object.geometry.boundingBox.clone();
        objectBoundingBox.applyMatrix4(object.matrixWorld);
        boundingBox.union(objectBoundingBox);
    } else if (object.isGroup) {
        object.children.forEach((child) => {
            calculateBoundingBox(child, boundingBox);
        });
    }
}


export function setFrameCameraView(
    xValue,
    zSign,
    scene,
    cameraRef,
    windowWidth,
    windowHeight,
    zoomValue,
    cameraUiMoved,
    cameraUiStopped
) {
    cameraUiMoved();

    // Calculate the total bounding box
    const totalBoundingBox = new THREE.Box3();
    scene?.traverse((object) => {
        calculateBoundingBox(object, totalBoundingBox);
    });

    // Calculate the center and size of the bounding box
    const boundingBoxCenter = totalBoundingBox.getCenter(new THREE.Vector3());

    const dimensionRatio = windowWidth / windowHeight;

    let cameraPositionAdjust = Math.sqrt(windowWidth ** 2 + windowHeight ** 2) / 1000;
    if (dimensionRatio < 1) {
        cameraPositionAdjust += windowHeight / windowWidth;
    }

    // Update camera properties only after all calculations are done
    if (cameraRef.current) {
        // Focus the camera on the bounding box center
        cameraRef.current.position.copy(boundingBoxCenter);
        // Adjust the camera's z-position for zoom and size
        cameraRef.current.position.z = (cameraPositionAdjust + 2 - zoomValue) * zSign;
        // Adjust x-position
        cameraRef.current.position.x = xValue;
        // Make the camera look at the center of the bounding box
        cameraRef.current.lookAt(boundingBoxCenter);
    }

    setTimeout(() => {
        cameraUiStopped();
    }, 200);
}


export async function setFrameCameraViewTT(
    xValue, zSign, scene, cameraRef, windowWidth, windowHeight, zoomValue, cameraUiMoved, cameraUiStopped
) {
    // Notify UI about the start of camera movement
    cameraUiMoved();

    const totalBoundingBox = new THREE.Box3();

    // Update the bounding box synchronously
    async function updateBoundingBox(scene, totalBoundingBox) {
        return new Promise((resolve) => {
            scene?.traverse((object) => {
                calculateBoundingBox(object, totalBoundingBox);
            });
            resolve();
        });
    }

    await updateBoundingBox(scene, totalBoundingBox);

    // Calculate the bounding box center and size
    const boundingBoxCenter = totalBoundingBox.getCenter(new THREE.Vector3());
    const boundingBoxSize = totalBoundingBox.getSize(new THREE.Vector3());
    const maxBoundingSize = Math.max(boundingBoxSize.x, boundingBoxSize.y, boundingBoxSize.z);

    // Calculate screen and dimension ratios
    const screenRatio = window?.innerWidth / window?.innerHeight;
    const dimensionRatio = windowWidth / windowHeight;

    let cameraPositionAdjust = Math.sqrt(windowWidth ** 2 + windowHeight ** 2) / 1000;
    if (dimensionRatio < 1) {
        cameraPositionAdjust += windowHeight / windowWidth;
    }

    // Safely update the camera position
    if (cameraRef.current) {
        cameraRef.current.position.copy(boundingBoxCenter);
        cameraRef.current.position.z = (cameraPositionAdjust + 1 - zoomValue) * zSign;
        cameraRef.current.position.x = xValue;
        cameraRef.current.lookAt(boundingBoxCenter);
    }

    // Notify UI about the end of camera movement after a short delay
    setTimeout(() => {
        cameraUiStopped();
    }, 100);
}


export async function updateBoundingBox(scene, totalBoundingBox) {
    return new Promise((resolve) => {
        setTimeout(() => {
            // Traverse the scene and calculate the bounding box
            scene?.traverse((object) => {
                calculateBoundingBox(object, totalBoundingBox);
            });

            resolve();
        }, 200);
    });
}

export function setFrameCameraViewNew(xValue, zSign, scene, cameraRef, windowWidth, windowHeight, zoomValue, cameraUiMoved, cameraUiStopped) {
    cameraUiMoved()

    const totalBoundingBox = new THREE.Box3();

    async function updateBoundingBox(scene, totalBoundingBox) {
        return new Promise((resolve) => {
            setTimeout(() => {
                // Traverse the scene and calculate the bounding box
                scene?.traverse((object) => {
                    calculateBoundingBox(object, totalBoundingBox);
                });

                resolve();
            }, 200);
        });
    }

    async function addBoundingBoxHelper(scene, totalBoundingBox) {
        await updateBoundingBox(scene, totalBoundingBox);
    }

    addBoundingBoxHelper(scene, totalBoundingBox);

    const boundingBoxCenter = totalBoundingBox.getCenter(new THREE.Vector3());
    const boundingBoxSize = totalBoundingBox.getSize(new THREE.Vector3());

    const maxBoundingSize = Math.max(boundingBoxSize.x, boundingBoxSize.y, boundingBoxSize.z);

    let screenRatio = window?.innerWidth / window?.innerHeight;
    let dimensionRatio = windowWidth / windowHeight;

    let cameraPositionAdjust = Math.sqrt(windowWidth ** 2 + windowHeight ** 2) / 1000;

    if (dimensionRatio < 1) {
        cameraPositionAdjust += windowHeight / windowWidth;
    }

    const targetPosition = {
        x: xValue,
        y: boundingBoxCenter.y,
        z: (cameraPositionAdjust + 1 - zoomValue) * zSign,
    };

    const cameraZ = cameraRef.current.position.z

    let flip = false
    if ((targetPosition.z < 0 && cameraZ > 0) || (targetPosition.z > 0 && cameraZ < 0)) {
        flip = true
    } else {
        flip = false
    }

    if (cameraRef.current) {
        const startPosition = cameraRef.current.position.clone();
        const duration = flip ? 1 : 0.25; // in seconds

        const clock = new THREE.Clock();

        function animate() {
            const elapsed = clock.getElapsedTime();
            const t = Math.min(elapsed / duration, 1); // Normalize t between 0 and 1

            cameraRef.current.position.lerpVectors(startPosition, new THREE.Vector3(targetPosition.x, targetPosition.y, targetPosition.z), t);
            cameraRef.current.lookAt(boundingBoxCenter);

            if (t < 1) {
                requestAnimationFrame(animate);
            }

            if (flip) {
                setTimeout(() => {
                    cameraUiStopped()
                }, 1200);
            } else {
                setTimeout(() => {
                    cameraUiStopped()
                }, 300);
            }
        }

        clock.start();
        animate();
    }
}

export const captureAndSaveImages = (setFrameCameraView, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom, getAllProfileRef, allProfileRefSeq, glassRefSeq, frameStyleRef, sashList, sillRef, sashGroup, uiLinesRefFrame, uiLinesRefBottom, removeFrameLines, removeLayoutLines, sceneRef, setModelImages, modelWrap, setSaveAllImages, setCaptureImages, setRefreshUiLines, setUiLinesLocation, setRefreshAfterSave) => {

    // setFrameCameraView(0, 1, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom);

    setTimeout(() => {
        captureAndDownload(setModelImages, modelWrap, 1);
    }, 400);

    // setTimeout(() => {
    //     uiLinesRefFrame.current = []
    //     uiLinesRefBottom.current = []
    //     removeFrameLines(sceneRef)
    //     removeLayoutLines(sceneRef)

    //     setFrameCameraView(0, -1, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom);
    // }, 1000);

    // setTimeout(() => {
    //     captureAndDownload(setModelImages, modelWrap, 2);
    // }, 1600);

    // setTimeout(() => {
    //     setFrameCameraView(0, 1, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom);

    //     getAllProfileRef(allProfileRefSeq, glassRefSeq, frameStyleRef, sashList, sillRef, sashGroup)
    // }, 1800);

    // // setTimeout(() => {
    // //     getAllProfileRef(allProfileRefSeq, glassRefSeq, frameStyleRef, sashList, sillRef, sashGroup)
    // // }, 2000);

    // setTimeout(() => {
    //     captureAndDownload(setModelImages, modelWrap, 3);
    // }, 3500);

    setTimeout(() => {
        setSaveAllImages(true)

        // allProfileRefSeq.current = []
    }, 4500);

    setTimeout(() => {
        setRefreshUiLines(false)
        setUiLinesLocation("")
        allProfileRefSeq.current = []

        setRefreshAfterSave(true)
        setCaptureImages(false)
    }, 2000);
}

export const captureAndDownload = (setModelImages, modelWrap, type) => {

    if (modelWrap && modelWrap.current) {
        html2canvas(modelWrap.current, { scale: 2 }).then((canvas) => {
            const base64String = canvas.toDataURL('image/png');

            if (type == 1) {
                setModelImages((prevData) => ({
                    ...prevData,
                    externalImage: base64String,
                }))
            } else if (type == 2) {
                setModelImages((prevData) => ({
                    ...prevData,
                    internalImage: base64String,
                }))
            } else if (type == 3) {
                setModelImages((prevData) => ({
                    ...prevData,
                    seqImage: base64String,
                }))
            }
        });
    }
};

export const captureAndDownloadExt = (setModelImages, modelWrap, type) => {
    if (modelWrap && modelWrap.current) {
        html2canvas(modelWrap.current, { scale: 2 }).then((canvas) => {
            const base64String = canvas.toDataURL('image/png');

            if (type == 1) {
                setModelImages((prevData) => ({
                    ...prevData,
                    externalImage: base64String,
                }))
            }
        });
    }
};

export const captureAndDownloadOld = (setPricingData, gltfModel, modelWrap) => {

    if (gltfModel && gltfModel.current) {
        const modelBoundingBox = new THREE.Box3().setFromObject(gltfModel.current);

        let widthM = (modelBoundingBox.max.x - modelBoundingBox.min.x)
        let heightM = ((modelBoundingBox.max.y - modelBoundingBox.min.y))
        var scaleValue = window?.innerWidth / ((widthM * 1000) - window?.innerWidth)

        let whiteSpaceX = (((window?.innerWidth / (window?.innerWidth - widthM)) * 10) / 2) % (widthM)
        let whiteSpaceY = ((window?.innerHeight / ((heightM * 1000) - window?.innerHeight) * 10) / 2) % heightM

        html2canvas(modelWrap.current, { scale: 2 }).then((canvas) => {
            // const link = document.createElement('a');
            // link.download = 'screenshot.png';
            // link.href = canvas.toDataURL('image/png');
            // link.click();

            const base64String = canvas.toDataURL('image/png');

            setPricingData((prevData) => ({
                ...prevData,
                modelImage: base64String,
                xMargin: (whiteSpaceX * 100), // for top bottom margin
                yMargin: (whiteSpaceY * 100), // for top bottom margin
                scaleValue: scaleValue < 1.5 ? scaleValue : 1.4,
            }))
        });
    }

};

export const getCanvasAsBase64 = (canvas) => {
    return canvas?.toDataURL("image/png");
};

export function handleMultiSelect(index, data, setMultiSelectRefPoints) {
    setMultiSelectRefPoints((prevData) => {
        const indexExists = prevData.some(item => item.index === index);
        if (indexExists) {
            const newData = prevData.filter(item => item.index !== index);
            return newData;
        } else {
            const newData = [
                ...prevData,
                {
                    data: data,
                    index: index
                }
            ];
            return newData;
        }
    });
}

export function addShadow(scene) {
    const directionalLight3 = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight3.position.set(.5, 5, .4);
    directionalLight3.castShadow = true;

    // Use a larger value for shadow radius to create a blurred effect
    directionalLight3.shadow.radius = 10;

    scene.add(directionalLight3);

    // Add a blurred shadow plane
    const shadowGeometry = new THREE.PlaneGeometry(20, 20);
    const shadowMaterial = new THREE.ShadowMaterial({ opacity: 0.1, transparent: true });
    const shadowPlane = new THREE.Mesh(shadowGeometry, shadowMaterial);
    shadowPlane.rotation.x = -Math.PI / 2;
    shadowPlane.position.y = -0.35; // Adjust the height of the shadow plane
    shadowPlane.receiveShadow = true;
    scene.add(shadowPlane);
}

// export const captureBalls = () => {
//     const canvases = document.querySelectorAll("#ballContainer");
//     const arr = [];
//     canvases?.forEach((canvas) => {
//         const base64String = getCanvasAsBase64(canvas);
//         arr.push(base64String);
//     });
//     setCustomModelData((prevModelData) => ({
//         ...prevModelData,
//         modelBalls: arr,
//     }));
// };

// export const setCameraPosition = (cameraRef) => {
//     return new Promise((resolve) => {
//         cameraRef?.current?.position?.set(0, 0, -3.9);
//         cameraRef?.current?.lookAt(0, 0, 0);
//         resolve();
//     });
// };

export const updateOverlayPosition = (gltfModel, camera, pointInScreen, testRef, newFrameRefPoints, uiLinesRef, uiLinesRefBottom, uiLinesRefFrame, allProfileRefSeq, glassRefSeq) => {
    if (gltfModel.current) {
        // const pointOnModel = new THREE.Vector3(-0.4, 0, 0);
        // for (let i = 0; i < testRef?.current?.length; i++) {
        //     let pointOnModel;
        //     var worldPosition = new THREE.Vector3();
        //     testRef.current[i]?.getWorldPosition(worldPosition);

        //     //	pointOnModel = testRef.current[i]?.position
        //     pointOnModel = worldPosition;
        //     pointInScreen = pointOnModel?.clone()?.project(camera);
        //     // Convert normalized device coordinates to screen coordinates
        //     const overlayX = (pointInScreen.x + 1) * window.innerWidth / 2;
        //     const overlayY = (-pointInScreen.y + 1) * window.innerHeight / 2;
        //     testRef.current[i].overlayX = `${overlayX - 120}px`;
        //     testRef.current[i].overlayY = `${overlayY - 45}px`;
        // }

        // if (newFrameRefPoints.current.length > 0) {
        //     for (let i = 0; i < newFrameRefPoints?.current?.length; i++) {
        //         let pointOnModel;
        //         var worldPosition = new THREE.Vector3();
        //         newFrameRefPoints.current[i]?.getWorldPosition(worldPosition);
        //         //	pointOnModel = testRef.current[i]?.position
        //         pointOnModel = worldPosition;
        //         pointInScreen = pointOnModel?.clone()?.project(camera);
        //         // Convert normalized device coordinates to screen coordinates
        //         const overlayX = (pointInScreen.x + 1) * window.innerWidth / 2;
        //         const overlayY = (-pointInScreen.y + 1) * window.innerHeight / 2;
        //         newFrameRefPoints.current[i].overlayX = `${overlayX - 120}px`;
        //         newFrameRefPoints.current[i].overlayY = `${overlayY - 45}px`;
        //     }
        // }

        if (uiLinesRef.current.length > 0) {
            for (let i = 0; i < uiLinesRef?.current?.length; i++) {
                let pointOnModel;
                var worldPosition = new THREE.Vector3();
                uiLinesRef.current[i]?.getWorldPosition(worldPosition);
                //	pointOnModel = testRef.current[i]?.position
                pointOnModel = worldPosition;
                pointInScreen = pointOnModel?.clone()?.project(camera);
                // Convert normalized device coordinates to screen coordinates
                const overlayX = (pointInScreen.x + 1) * window.innerWidth / 2;
                const overlayY = (-pointInScreen.y + 1) * window.innerHeight / 2;
                uiLinesRef.current[i].overlayX = `${overlayX - 120}px`;
                uiLinesRef.current[i].overlayY = `${overlayY - 45}px`;
            }
        }

        if (uiLinesRefBottom.current.length > 0) {

            for (let i = 0; i < uiLinesRefBottom?.current?.length; i++) {
                let pointOnModel;
                var worldPosition = new THREE.Vector3();
                uiLinesRefBottom.current[i]?.getWorldPosition(worldPosition);
                //	pointOnModel = testRef.current[i]?.position
                pointOnModel = worldPosition;
                pointInScreen = pointOnModel?.clone()?.project(camera);
                // Convert normalized device coordinates to screen coordinates
                const overlayX = (pointInScreen.x + 1) * window.innerWidth / 2;
                const overlayY = (-pointInScreen.y + 1) * window.innerHeight / 2;
                uiLinesRefBottom.current[i].overlayX = `${overlayX - 120}px`;
                uiLinesRefBottom.current[i].overlayY = `${overlayY - 45}px`;
            }
        }

        if (uiLinesRefFrame.current.length > 0) {
            for (let i = 0; i < uiLinesRefFrame?.current?.length; i++) {
                let pointOnModel;
                var worldPosition = new THREE.Vector3();
                uiLinesRefFrame.current[i]?.getWorldPosition(worldPosition);
                //	pointOnModel = testRef.current[i]?.position
                pointOnModel = worldPosition;
                pointInScreen = pointOnModel?.clone()?.project(camera);
                // Convert normalized device coordinates to screen coordinates
                const overlayX = (pointInScreen.x + 1) * window.innerWidth / 2;
                const overlayY = (-pointInScreen.y + 1) * window.innerHeight / 2;
                uiLinesRefFrame.current[i].overlayX = `${overlayX - 120}px`;
                uiLinesRefFrame.current[i].overlayY = `${overlayY - 45}px`;
            }
        }

        if (allProfileRefSeq.current.length > 0) {
            for (let i = 0; i < allProfileRefSeq?.current?.length; i++) {
                let pointOnModel;
                var worldPosition = new THREE.Vector3();
                allProfileRefSeq?.current[i]?.getWorldPosition(worldPosition);
                //	pointOnModel = testRef.current[i]?.position
                pointOnModel = worldPosition;
                pointInScreen = pointOnModel?.clone()?.project(camera);
                // Convert normalized device coordinates to screen coordinates
                const overlayX = (pointInScreen.x + 1) * window.innerWidth / 2;
                const overlayY = (-pointInScreen.y + 1) * window.innerHeight / 2;

                allProfileRefSeq.current[i].overlayX = `${overlayX - 120}px`;
                allProfileRefSeq.current[i].overlayY = `${overlayY - 45}px`;
            }
        }

        if (glassRefSeq.current.length > 0) {
            for (let i = 0; i < glassRefSeq?.current?.length; i++) {
                let pointOnModel;
                var worldPosition = new THREE.Vector3();
                glassRefSeq?.current[i]?.getWorldPosition(worldPosition);
                //	pointOnModel = testRef.current[i]?.position
                pointOnModel = worldPosition;
                pointInScreen = pointOnModel?.clone()?.project(camera);
                // Convert normalized device coordinates to screen coordinates
                const overlayX = (pointInScreen.x + 1) * window.innerWidth / 2;
                const overlayY = (-pointInScreen.y + 1) * window.innerHeight / 2;

                glassRefSeq.current[i].overlayX = `${overlayX - 120}px`;
                glassRefSeq.current[i].overlayY = `${overlayY - 45}px`;
            }
        }
    }
}


export const updateReferencePoints = (testRef, camera) => {
    testRef?.forEach((object) => {
        const { x, y, z } = object?.position
        let position = new THREE.Vector3(x, y, z);
        let pointInScreen = position?.project(camera);

        const overlayX = (pointInScreen.x + 1) * (window.innerWidth / 2);
        const overlayY = (-pointInScreen.y + 1) * (window.innerHeight / 2);

        object.overlayX = `${overlayX - 120}px`;
        object.overlayY = `${overlayY - 44}px`;
    });
}

// export const updateReferencePoints = (testRef, camera, renderer) => {
//     if (!testRef || !camera || !renderer) return;

//     testRef?.forEach((object) => {
//         const vector = new THREE.Vector3();
//         object.getWorldPosition(vector); // Get the world position of the 3D object
//         vector.project(camera); // Project the 3D position to 2D space

//         // Convert normalized device coordinates (NDC) to screen space
//         const overlayX = (vector.x  0.5 + 0.5)  renderer.domElement.clientWidth;
//         const overlayY = -(vector.y  0.5 - 0.5)  renderer.domElement.clientHeight;

//         object.overlayX = `${overlayX - 120}px`;
//         object.overlayY = `${overlayY - 45}px`;
//     });
// }


export const getModelRenderedSize = (gltfModel, cameraRef, setRenderedSize) => {

    // Ensure the scene graph is updated
    gltfModel.current.updateMatrixWorld();
    cameraRef.current.updateMatrixWorld();

    // Calculate the bounding box of the model
    const box = new THREE.Box3().setFromObject(gltfModel.current);
    const size = box.getSize(new THREE.Vector3());

    if (size.x > 0 && size.y > 0 && size.z > 0) {
        const vector = new THREE.Vector3();
        const width = window.innerWidth;
        const height = window.innerHeight;

        const corners = [
            new THREE.Vector3(box.min.x, box.min.y, box.min.z),
            new THREE.Vector3(box.min.x, box.min.y, box.max.z),
            new THREE.Vector3(box.min.x, box.max.y, box.min.z),
            new THREE.Vector3(box.min.x, box.max.y, box.max.z),
            new THREE.Vector3(box.max.x, box.min.y, box.min.z),
            new THREE.Vector3(box.max.x, box.min.y, box.max.z),
            new THREE.Vector3(box.max.x, box.max.y, box.min.z),
            new THREE.Vector3(box.max.x, box.max.y, box.max.z),
        ];

        // Project each corner of the bounding box
        const projectedCorners = corners.map(corner => {
            corner.project(cameraRef.current);
            return {
                x: (corner.x * 0.5 + 0.5) * width,
                y: (corner.y * -0.5 + 0.5) * height,
            };
        });

        // Find the min and max screen-space coordinates
        const xMin = Math.min(...projectedCorners.map(c => c.x));
        const xMax = Math.max(...projectedCorners.map(c => c.x));
        const yMin = Math.min(...projectedCorners.map(c => c.y));
        const yMax = Math.max(...projectedCorners.map(c => c.y));

        // Calculate the width and height in pixels
        const widthInPixels = xMax - xMin;
        const heightInPixels = yMax - yMin;

        setRenderedSize({
            width: widthInPixels,
            height: heightInPixels,
        })
    } else {
        console.warn('Bounding box is degenerate or very small.');
    }
}

export function convertSvgElementToBase64(svgElement) {
    // Serialize the SVG element to a string
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgElement);

    // Create a Blob from the SVG string
    const svgBlob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });

    // Create a FileReader to read the Blob and generate a Base64 string
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const dataUrl = reader.result; // The full data URL
            const base64URL = dataUrl.split(",")[1]; // Extract the Base64 part
            resolve(base64URL);
        };
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(svgBlob);
    });
}
