import React, { useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { GoCircleSlash } from "react-icons/go";
import { Button } from "reactstrap";
import { useDesigner } from "../../context/designerContext";
import {
  saveModelData,
  saveModelDataQuotation,
} from "../../utility/designerHelper";
import { useMessage } from "../../context/messageContext";
import { useNavigate } from "react-router-dom";
import { getImage } from "../../utility/helper";

const AddOnComp = ({
  addOnList,
  setFrameData,
  setIsAddOnOpen,
  addOnData,
  setOpen,
  setHardwareType,
  modelId,
  frameStyleProdId,
  quotationId,
  setLoading,
  receivedProductId,
}) => {
  const accessToken = localStorage.getItem("access_token");
  const [activeSide, setActiveSide] = useState("");
  const [mappingList, setMappingList] = useState([]);
  const [referencePoint, setReferencePoint] = useState(null);
  const [activeIndexes, setActiveIndexes] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();

  const { setMessage, setMessageType } = useMessage();

  const {
    multiSelectRefPoints,
    setMultiSelectRefPoints,
    customModelData,
    modelJson,
    delete3DItems,
  } = useDesigner();

  //   For checking one reference point at once for better view and understanding
  useEffect(() => {
    if (multiSelectRefPoints.length === 1) {
      setReferencePoint(multiSelectRefPoints[0]);
    } else if (multiSelectRefPoints?.length > 1) {
      let lastElement = multiSelectRefPoints.pop();
      setMultiSelectRefPoints([lastElement]);
      setReferencePoint(lastElement);
    } else if (multiSelectRefPoints?.length === 0) {
      setReferencePoint(null);
      setActiveIndexes([]);
    }
  }, [multiSelectRefPoints]);

  //   for getting data from the model json on the basis of the slected side
  useEffect(() => {
    if (referencePoint) {
      setActiveSide(
        referencePoint?.data?.name.split("Frame")[1]?.toLocaleLowerCase()
      );
    }
  }, [referencePoint]);

  useEffect(() => {
    if (activeSide && addOnList[activeSide]) {
      setMappingList(addOnList[activeSide]);
    }
  }, [activeSide, addOnList]);

  //   For showing the selected add-ons
  useEffect(() => {
    if (mappingList?.length > 0 && addOnData?.length > 0) {
      const indexArray = [];

      addOnData.forEach(({ id }, index) => {
        if (mappingList.some((ele) => ele?.id === id)) {
          indexArray.push(index);
        }
      });

      setActiveIndexes(indexArray);
    }
  }, [mappingList, addOnData]);

  useEffect(() => {
    if (isSaving) {
      setTimeout(() => {
        setIsSaving(false);
        setLoading(false);
      }, 100);
    }
  }, [isSaving]);

  useEffect(() => {
    if (isSaving && customModelData && modelId && frameStyleProdId?.id) {
      if (quotationId && receivedProductId) {
        saveModelDataQuotation(
          accessToken,
          modelId,
          frameStyleProdId?.id,
          customModelData,
          quotationId,
          receivedProductId,
          navigate,
          setMessage,
          setMessageType,
          modelJson
        );
        handleCancel();
      } else {
        saveModelData(
          accessToken,
          modelId,
          frameStyleProdId?.id,
          customModelData,
          modelJson,
          navigate,
          setMessage,
          setMessageType
        );
        handleCancel();
      }
    }
  }, [isSaving]);

  const handleSaveData = () => {
    setLoading(true);
    setIsSaving(true);
  };

  const handleCancel = () => {
    setIsAddOnOpen(false);
    setFrameData();
    setMultiSelectRefPoints([]);
    setOpen(1);
    setHardwareType("");
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-2 left_header">
        <h5 className="mb-0 text-wrap text-break me-4 main_title">Add On</h5>{" "}
        <div></div>
      </div>
      <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        {referencePoint ? (
          <div className="sill_list_scroll">
            <div className="panel_object_list mb-3">
              {addOnData &&
                addOnData?.length > 0 &&
                addOnData?.map((item, index) => {
                  return (
                    <div
                      className="position-relative d-flex align-items-center right_item_wrap has_delete"
                      key={index}
                      //   onClick={() => handleFrameObject(item, index)}
                    >
                      <div
                        className={`right_item w-100 ${
                          activeIndexes.includes(index) ? "active" : ""
                        }`}
                      >
                        <span className="me-2 image_icon">
                          {item.width > 0 && item.height > 0 ? (
                            <img src={getImage(item?.imagePath)} />
                          ) : (
                            <GoCircleSlash size={40} color="#989898" />
                          )}
                        </span>
                        <div className="right_title">
                          <span className="text-truncate">{item?.name}</span>
                          <span className="text-truncate d-block text-small">
                            {item.description}
                          </span>
                        </div>
                      </div>
                      <span className="position-absolute action_btns"></span>
                    </div>
                  );
                })}
            </div>

            {referencePoint && mappingList?.length > 0 && (
              <Button
                color="light"
                size="md"
                className="px-4 w-100 d-flex justify-content-between fw-medium mb-3"
                onClick={() => delete3DItems(true, "addon")}
              >
                <BiTrash size={20} />
                Delete
                <span></span>
              </Button>
            )}
          </div>
        ) : (
          <div className="mt-4 mb-4">
            <span className="text-truncate text-dark">
              Select a Reference Point
            </span>
          </div>
        )}
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={() => handleSaveData()}
          >
            Save
          </Button>
          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddOnComp;
