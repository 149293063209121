import React from 'react';

function ShowDimensionDivisions({ uiLinesRef, ratios, type, addSillCombine }) {
  const divisionRatios = ratios || [];

  return (
    <>
      {uiLinesRef?.current?.map((item, index) => {
        return (
          <div id={`overlayLines${index}`} key={index} className={`ref_btn_wrap ui_ref ${addSillCombine && (index === (divisionRatios?.length - 1)) ? "d-none" : ''}`}>
            <div
              className={`btn_parent ${type === "top" ? "is_top" : "is_left"} only_text`}
              style={{
                position: 'absolute',
                left: item.overlayX,
                top: item.overlayY,
              }}
            >
              <div className="ref_btn position-relative type_3">
                {(addSillCombine && index === (divisionRatios?.length - 2) )? (
                  <>
                    {parseInt(divisionRatios[index] * 1000) + parseInt(divisionRatios[index + 1] * 1000) || 0}
                  </>
                ) : (
                  <>
                    {parseInt(divisionRatios[index] * 1000) || 0}
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default ShowDimensionDivisions;