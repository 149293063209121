import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  NavLink,
} from "reactstrap";
import {
  setUpdateFormulaService,
  setUpdateSashHangingFormulaService,
  updateFormulaAdditionalgProfile,
  updateFormulaAdditionalHardware,
} from "../services/ManufacturingServices";
import { useMessage } from "../context/messageContext";
import { IoMdLink } from "react-icons/io";
import { handleQuantity } from "../utility/helper";
import { useDesigner } from "../context/designerContext";
import { saveModelData } from "../utility/designerHelper";
import { useNavigate } from "react-router-dom";

const ManufacturingEditComp = ({
  setIsEdit,
  frameStyleProdId,
  referencePoint,
  currentItem,
  accessToken,
  setLoading,
  getData,
  getSashHangingData,
  activeTab,
  isAdditionProfile,
  refetchData,
  allTagData,
  profileType,
  collectionId,
}) => {
  const [heightFormula, setHeightFormula] = useState("");
  const [widthFormula, setWidthFormula] = useState("");
  const [toggleTagSizeWidth, setToggleTagSizeWidth] = useState(false);
  const [toggleTagSizHeight, setToggleTagSizHeight] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedIdsHeight, setSelectedIdsHeight] = useState([]);
  const [formulaWithIds, setFormulaWithIds] = useState([]);
  const [formulaWithIdsHeight, setFormulaWithIdsHeight] = useState([]);
  const [quantity, setQuantity] = useState(currentItem?.quantity);
  const [additionalHardwareQuantity, setAdditionalHardwareQuantity] =
    useState(0);
  const [bothDimension, setBothDimension] = useState(false);
  const [activeRefrenceIndex, setReferenceIndex] = useState(
    referencePoint?.index
  );
  const [isFormulaSaved, setIFormulaSaved] = useState(false);

  const { setMessage, setMessageType } = useMessage();
  const navigate = useNavigate();

  const {
    currentOpenProfile,
    partialIndex,
    setPartialIndex,
    customModelData,
    setCustomModelData,
    modelJson,
    setMultiSelectRefPoints,
  } = useDesigner();

  // This code will ensure whenever the reference point is changed the user must be sent to the listing menu so that the value for the height and width formula does not colide with each other's value
  useEffect(() => {
    if (referencePoint?.index != activeRefrenceIndex) {
      setIsEdit(false);
    }
  }, [referencePoint]);

  useEffect(() => {
    if (isFormulaSaved && frameStyleProdId?.id) {
      saveModelData(
        accessToken,
        collectionId,
        frameStyleProdId?.id,
        customModelData,
        modelJson,
        navigate,
        setMessage,
        setMessageType
      );
    }
    setTimeout(() => {
      setIFormulaSaved(false);
    }, 200);
  }, [isFormulaSaved]);

  const updateAdditonalProfileFormulaService = async () => {
    let typeId =
      activeTab === "Frame" ||
      (activeTab === "Cutting" && profileType === "Frame")
        ? 1
        : 2;
    let data = {
      typeId,
      profileId: currentItem?.id,
      frameStyleId: frameStyleProdId?.id,
      referencePoint: referencePoint.index,
      heightFormula,
      widthFormula,
      tagIds: selectedIds?.length ? selectedIds : [0],
      status: currentItem?.status,
    };

    const res = await updateFormulaAdditionalgProfile(accessToken, data);
    if (res.data.statusCode === 200) {
      refetchData(typeId, referencePoint?.index, frameStyleProdId?.id);
      setIsEdit(true);
    }
  };

  const updateAdditonalHardwareFormulaService = async (type) => {
    let data = {
      hardwareId: currentItem?.id,
      frameStyleId: frameStyleProdId?.id,
      referencePoint: referencePoint.index,
      heightFormula,
      widthFormula,
      tagIds: selectedIds?.length ? selectedIds : [0],
      status: currentItem?.status,
      quantity:
        type == "cuttingHardware" ? additionalHardwareQuantity : quantity,
    };

    const res = await updateFormulaAdditionalHardware(accessToken, data);
    if (res.data.statusCode === 200) {
      refetchData(referencePoint?.index);
      setIsEdit(true);
    }
  };

  const saveData = () => {
    if (activeTab === "Hardware") {
      updateAdditonalHardwareFormulaService("");
    } else if (activeTab === "Sash" || activeTab === "Frame") {
      updateAdditonalProfileFormulaService();
    } else if (activeTab === "Cutting") {
      if (currentOpenProfile === "profile") {
        updateAdditonalProfileFormulaService();
      } else {
        updateAdditonalHardwareFormulaService("cuttingHardware");
      }
    }
  };

  useEffect(() => {
    if (currentItem !== null) {
      // setWidthFormula(
      //   currentItem?.widthFormula !== null
      //     ? currentItem?.widthFormula
      //     : `P${referencePoint?.index + 1}`
      // );

      // This condition is added for the sash hanging condition
      if (
        currentItem?.hasOwnProperty("defaultHanging") ||
        (referencePoint?.data?.name?.includes("Glass") &&
          !referencePoint?.data?.name?.includes("section_"))
      ) {
        setBothDimension(true);
      } else {
        setBothDimension(false);
      }

      setSelectedIds(
        currentItem?.tagsIds?.length > 0 ? currentItem?.tagsIds : []
      );

      setSelectedIdsHeight(
        currentItem?.tagsIdsHeight?.length > 0 ? currentItem?.tagsIdsHeight : []
      );
    }
  }, [currentItem]);

  useEffect(() => {
    if (currentItem && currentItem?.priceType == 2) {
      setAdditionalHardwareQuantity(currentItem?.quantity);
    }
  }, [currentItem]);

  // This will create the array which will generate the persisting formula on the basis of ids and this if for default when the data is not saved
  // useEffect(() => {
  //   if (
  //     allTagData &&
  //     selectedIds &&
  //     selectedIds?.length > 0 &&
  //     allTagData?.length > 0
  //   ) {
  //     let temp = [];
  //     selectedIds?.forEach((item) => {
  //       let element = allTagData?.find((ele) => ele?.id === item);
  //       if (element) {
  //         let obj = {
  //           id: element?.id,
  //           length: element?.length,
  //         };
  //         temp.push(obj);
  //       }
  //     });
  //     setFormulaWithIds(temp);
  //   } else if (partialIndex !== -1) {
  //     setWidthFormula(`P${partialIndex + 1}`);
  //   } else {
  //     if (!referencePoint?.data?.name?.includes("Frame")) {
  //       const index = referencePoint?.data?.name
  //         ? Math.floor(referencePoint.index / 4)
  //         : referencePoint?.index;

  //       const formula = referencePoint?.data?.name?.includes("verticalBar")
  //         ? "H"
  //         : `P${index + 1}`;

  //       setWidthFormula(formula);
  //     } else {
  //       if (
  //         referencePoint?.data?.name?.includes("Top") ||
  //         referencePoint?.data?.name?.includes("Bottom")
  //       ) {
  //         setWidthFormula(`W`);
  //       } else {
  //         setWidthFormula("H");
  //       }
  //     }
  //   }
  // }, [selectedIds, allTagData, partialIndex]);

  useEffect(() => {
    if (allTagData?.length > 0 && selectedIds?.length > 0) {
      const temp = selectedIds
        .map((item) => {
          const element = allTagData.find((ele) => ele?.id === item);
          return element ? { id: element.id, length: element.length } : null;
        })
        .filter(Boolean); // Remove null values
      setFormulaWithIds(temp);
    } else {
      const name = referencePoint?.data?.name;

      if (name?.includes("Sash") || name?.includes("Frame")) {
        let formula;

        if (name.includes("Top") || name.includes("Bottom")) {
          formula = partialIndex !== "" ? partialIndex : "W";
        } else if (name.includes("Left") || name.includes("Right")) {
          formula = partialIndex !== "" ? partialIndex : "H";
        } else {
          formula = "W";
        }
        // Ensure partialIndex affects only formulas starting with "W"
        setWidthFormula((prevFormula) =>
          prevFormula?.startsWith("W") ? formula : prevFormula
        );
      }
    }
  }, [selectedIds, allTagData, partialIndex, referencePoint]);

  // console.log("PArtial Index-->",partialIndex);

  // This will ensure if the reference point is changed then we should convert the formula
  useEffect(() => {
    if (partialIndex !== "") {
      setPartialIndex("");
    }
  }, [referencePoint]);

  // This will create the array which will generate the persisting formula on the basis of ids and this if for default when the data is not saved
  useEffect(() => {
    if (bothDimension) {
      if (
        allTagData &&
        selectedIdsHeight &&
        selectedIdsHeight?.length > 0 &&
        allTagData?.length > 0
      ) {
        let temp = [];
        selectedIdsHeight?.forEach((item) => {
          let element = allTagData?.find((ele) => ele?.id === item);
          if (element) {
            let obj = {
              id: element?.id,
              length: element?.length,
            };
            temp.push(obj);
          }
        });
        setFormulaWithIdsHeight(temp);
      } else {
        setHeightFormula("H");
      }
    }
  }, [selectedIdsHeight, allTagData]);

  // This is old
  // useEffect(() => {
  //   if (!referencePoint) return;

  //   let tempFormula = "";
  //   const isFrame = referencePoint?.data?.name?.includes("Frame");
  //   const isTopOrBottom =
  //     referencePoint?.data?.name?.includes("Top") ||
  //     referencePoint?.data?.name?.includes("Bottom");
  //   const isVerticalBar = referencePoint?.data?.name?.includes("verticalBar");

  //   const index = referencePoint?.data?.name
  //     ? Math.floor(referencePoint.index / 4)
  //     : referencePoint.index;

  //   if (!isFrame) {
  //     tempFormula = isVerticalBar ? "H" : `P${index + 1}`;
  //   } else {
  //     tempFormula = isTopOrBottom ? "W" : "H";
  //   }

  //   if (formulaWithIds?.length > 0) {
  //     formulaWithIds.forEach((item) => {
  //       tempFormula += `-${item?.length}`;
  //     });
  //     setWidthFormula(tempFormula);
  //   } else if (formulaWithIds?.length === 0) {
  //     setWidthFormula(tempFormula);
  //   }
  // }, [formulaWithIds, referencePoint]);

  // This will generate the formula everytime on the basis of selected ids when the data is saved
  // This is getting used now
  useEffect(() => {
    if (!referencePoint) return;

    let tempFormula = "";

    // Use currentItem's widthFormula if it starts with "P"
    if (currentItem?.widthFormula?.startsWith("P")) {
      let splitForumula = currentItem?.widthFormula.split("");
      let tagSign = "";
      if (splitForumula[1] !== "+" && splitForumula[1] !== "-") {
        tagSign = splitForumula[2];
      } else {
        tagSign = splitForumula[1];
      }
      tempFormula = currentItem.widthFormula.split(tagSign)[0];
      if (partialIndex !== "") {
        tempFormula = partialIndex;
      }
    } else if (currentItem?.widthFormula?.startsWith("H")) {
      let splitForumula = currentItem?.widthFormula.split("");
      let tagSign = "";
      if (splitForumula[1] !== "+" && splitForumula[1] !== "-") {
        tagSign = splitForumula[2];
      } else {
        tagSign = splitForumula[1];
      }
      tempFormula = currentItem.widthFormula.split(tagSign)[0];
      if (partialIndex !== "") {
        tempFormula = partialIndex;
      }
    } else if (currentItem?.widthFormula?.startsWith("W")) {
      let splitForumula = currentItem?.widthFormula?.split("");
      let tagSign = splitForumula[1];
      tempFormula = currentItem.widthFormula.split(tagSign)[0];
      if (partialIndex !== "") {
        tempFormula = partialIndex;
      }
    } else {
      const { name } = referencePoint?.data || {};
      const index = name
        ? Math.floor(referencePoint.index / 4)
        : referencePoint.index;

      const isTopOrBottom = name?.includes("Top") || name?.includes("Bottom");
      const isLeftOrRight = name?.includes("Left") || name?.includes("Right");
      const isSash = name?.includes("Sash");
      const isFrame = name?.includes("Frame");
      const isTransom = name?.includes("section_");
      const isVerticalBar = name?.includes("verticalBar");

      // Determine the initial formula
      if (isLeftOrRight) {
        tempFormula = partialIndex !== "" ? partialIndex : "H"; // Always "H" for left or right
      } else if (isTopOrBottom) {
        tempFormula = partialIndex !== "" ? partialIndex : "W";
      } else if (isSash || isFrame) {
        tempFormula = "W"; // Default for other sash or frame cases
      } else if (isTransom) {
        let tempName = name.split("_").pop();
        tempFormula =
          partialIndex !== "" ? partialIndex : `P${Number(tempName) + 1}`;
      } else if (isVerticalBar) {
        tempFormula = "H"; // Special case for vertical bars
      } else {
        tempFormula = `P${index + 1}`; // Default for other cases
      }
    }

    // Append lengths from formulaWithIds
    if (formulaWithIds?.length > 0) {
      formulaWithIds.forEach((item) => {
        console.log("Iem-->", item);

        let tempLength = String(item?.length)?.startsWith("-")
          ? `${item.length}`
          : `+${item?.length}`;
        tempFormula += tempLength;
      });
    }

    // Set the final formula
    setWidthFormula(tempFormula);
  }, [formulaWithIds, referencePoint, partialIndex, currentItem]);

  useEffect(() => {
    if (bothDimension) {
      if (formulaWithIdsHeight && formulaWithIdsHeight?.length > 0) {
        let tempFormula = "H";

        formulaWithIdsHeight?.forEach((item) => {
          tempFormula += `-${item?.length}`;
        });

        setHeightFormula(tempFormula);
      } else if (formulaWithIdsHeight?.length === 0) {
        setHeightFormula("H");
      }
    }
  }, [formulaWithIdsHeight]);

  const saveFormula = async () => {
    setLoading(true);

    // Prepare data with fallbacks
    const data = {
      id: 0,
      frameStyleId: frameStyleProdId?.id || null,
      referencePoint: referencePoint?.index >= 0 ? referencePoint?.index : null,
      heightFormula: heightFormula || "",
      widthFormula: widthFormula || "",
      status: currentItem?.status || false,
      tagIds: selectedIds || [],
      tagIdsHeight: selectedIdsHeight || [],
      orientation: extractOrientation(referencePoint?.data?.name || ""),
      index: referencePoint?.index,
    };

    // Determine service based on isHanging
    const isHanging = currentItem?.hasOwnProperty("hangingName");
    const service = isHanging
      ? setUpdateSashHangingFormulaService
      : setUpdateFormulaService;

    // Assign appropriate key based on condition
    data[isHanging ? "sashHangingId" : "frameProfileId"] =
      currentItem?.id || null;

    try {
      console.log("Service being called:", service);
      console.log("Data being sent:", data);

      if (typeof service !== "function") {
        throw new Error("Service is not a valid function");
      }

      const res = await service(accessToken, data);

      if (res?.data?.statusCode === 200) {
        setMessage(res?.data?.message);
        setMessageType("success");
        if (!isHanging) {
          setCuttingFormula(data, profileType, false);
        }
      } else {
        setCuttingFormula(data, profileType, true);
        setMessage("An unexpected response occurred");
        setMessageType("error");
      }
    } catch (error) {
      console.error("Error in saveFormula:", error);
      setMessage("An error occurred");
      setMessageType("error");
    } finally {
      setLoading(false);

      const finalFunction = isHanging ? getSashHangingData : getData;
      if (typeof finalFunction === "function") {
        finalFunction(referencePoint?.index);
      } else {
        console.error("Final function is not callable:", finalFunction);
      }
    }
  };

  const setCuttingFormula = (originalData, profileType, isError = false) => {
    if (!isError) {
      if (profileType === "Sash") {
        let index = originalData?.index;
        let widthFormula = originalData?.widthFormula;
        let heightFormula = originalData?.heightFormula;
        let data = customModelData?.sashData;
        data[index].heightFormula = heightFormula;
        data[index].widthFormula = widthFormula;
        setCustomModelData((prevData) => ({
          ...prevData,
          sashData: data,
        }));

        setIFormulaSaved(true);
      }
      if (profileType === "Frame") {
        let widthFormula = originalData?.widthFormula;
        let heightFormula = originalData?.heightFormula;
        let data = customModelData?.frameProfileData;

        data.forEach((item, index) => {
          if (
            item?.orientation === originalData?.orientation.toLocaleLowerCase()
          ) {
            data[index].heightFormula = heightFormula;
            data[index].widthFormula = widthFormula;
          }
        });

        setCustomModelData((prevData) => ({
          ...prevData,
          frameProfileData: data,
        }));
        setIFormulaSaved(true);

      }
    } else {
      if (profileType === "Frame") {
        let data = customModelData?.frameProfileData;
        let foundMatch = false;

        data.forEach((item, index) => {
          if (
            item?.orientation === originalData?.orientation.toLocaleLowerCase()
          ) {
            foundMatch = true;
            data[index].heightFormula = "H";
            data[index].widthFormula = "W";
          }
        });
        setCustomModelData((prevData) => ({
          ...prevData,
          frameProfileData: data,
        }));
        setIFormulaSaved(true);
      }
    }
  };

  const handleCuttingFormula = (item) => {
    if (!selectedIds?.includes(item?.id)) {
      setSelectedIds((prevIds) => {
        return [...prevIds, item?.id];
      });
      let obj = {
        id: item?.id,
        length: item?.length,
      };
      setFormulaWithIds((prevIds) => {
        return [...prevIds, obj];
      });
    } else {
      const filteredItems = selectedIds?.filter((ele) => ele !== item?.id);
      const filterItemsWithLenth = formulaWithIds?.filter(
        (ele) => ele?.id !== item?.id
      );
      setSelectedIds(filteredItems);
      setFormulaWithIds(filterItemsWithLenth);
    }
  };

  const handleCuttingFormulaHeight = (item) => {
    if (!selectedIdsHeight?.includes(item?.id)) {
      setSelectedIdsHeight((prevIds) => {
        return [...prevIds, item?.id];
      });
      let obj = {
        id: item?.id,
        length: item?.length,
      };
      setFormulaWithIdsHeight((prevIds) => {
        return [...prevIds, obj];
      });
    } else {
      const filteredItems = selectedIdsHeight?.filter(
        (ele) => ele !== item?.id
      );
      const filterItemsWithLenth = formulaWithIds?.filter(
        (ele) => ele?.id !== item?.id
      );
      setSelectedIdsHeight(filteredItems);
      setFormulaWithIdsHeight(filterItemsWithLenth);
    }
  };

  const extractOrientation = (inputString) => {
    // Define an array of possible orientations
    const orientations = ["Left", "Right", "Top", "Bottom"];

    // Iterate over the orientations array and check if the input string ends with one of them
    for (let orientation of orientations) {
      if (inputString.endsWith(orientation)) {
        return orientation;
      }
    }

    // If no orientation is found, return null or some default value
    return null;
  };

  return (
    <div className="d-flex flex-column text-dark pe-3 manufacturing_scroll">
      <>
        <NavLink onClick={() => setIsEdit(false)} className="text-link mb-3">
          <BiArrowBack size={20} />
          <span className="ms-1">Back</span>
        </NavLink>

        {/* <Accordion
            open={open}
            toggle={toggleAccordion}
            onClick={(e) => handleAccordionClick(e)}
            tag="div"
          > */}
        {/* <AccordionItem tag="div"> */}
        {/* <AccordionHeader targetId="1">
                Cutting Formula
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropDown}
                  className="ms-auto"
                >
                  <DropdownToggle
                    color="none"
                    className="border-0 p-0 py-1 d-block text-muted"
                    tag="span"
                  ></DropdownToggle>
                </Dropdown>
              </AccordionHeader> */}

        {/* <AccordionBody accordionId="1"> */}

        <div className="text-dark">
          <div className="cutting_section d-flex align-items-center gap-3">
            {(referencePoint?.data?.name?.includes("horizontal") ||
              referencePoint?.data?.name?.includes("vertical") ||
              referencePoint?.data?.name === "" ||
              referencePoint?.data?.name?.includes("Frame") ||
              referencePoint?.data?.name?.includes("Sash") ||
              referencePoint?.data?.name?.includes("Glass") ||
              referencePoint?.data?.name?.includes("transom")) && (
              <>
                {activeTab === "Hardware" && currentItem?.priceType === 2 ? (
                  <>
                    <FormGroup className="mb-3 w-100">
                      <Label for="quantity">Quantity</Label>
                      <Input
                        type="number"
                        name="quantity"
                        min="1"
                        max="999"
                        step="1"
                        value={quantity}
                        onInput={(e) => handleQuantity(e)}
                        onChange={(e) => {
                          setQuantity(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </>
                ) : (
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex align-items-center gap-3">
                      {currentItem?.priceType !== 2 ? (
                        <FormGroup className="mb-0 d-flex align-items-center gap-1 w-75">
                          <Label for="width" className="mb-0 mini_text">
                            {bothDimension ? "Width" : "Length"}
                          </Label>
                          <Input
                            name="width"
                            disabled={true}
                            // onChange={handleWidthFormula}
                            value={widthFormula}
                            id="width"
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup className="mb-0 d-flex align-items-center gap-1 w-75">
                          <Label
                            for="additionalHardwareQuantity"
                            className="mb-0 mini_text"
                          >
                            Quantity
                          </Label>
                          <Input
                            name="additionalHardwareQuantity"
                            // disabled={true}
                            onChange={(e) =>
                              setAdditionalHardwareQuantity(e?.target?.value)
                            }
                            value={additionalHardwareQuantity}
                            id="additionalHardwareQuantity"
                          />
                        </FormGroup>
                      )}

                      {currentItem && currentItem?.priceType !== 2 && (
                        <FormGroup className="mb-0 d-flex align-items-center">
                          <Dropdown
                            isOpen={toggleTagSizeWidth}
                            toggle={() => {
                              setToggleTagSizeWidth(!toggleTagSizeWidth);
                            }}
                          >
                            <DropdownToggle
                              color="primary"
                              className="btn-icon px-2"
                              outline
                            >
                              <IoMdLink size={22} />
                            </DropdownToggle>

                            <DropdownMenu
                              className={`${
                                window?.innerHeight < 900 ? "has_scroll" : ""
                              } body_dropdown hardware_list`}
                              container="body"
                            >
                              {allTagData &&
                                allTagData?.length > 0 &&
                                allTagData?.map((item, index) => {
                                  return (
                                    <DropdownItem
                                      onClick={() => {
                                        handleCuttingFormula(item);
                                      }}
                                      key={index}
                                    >
                                      {/* {item?.name} */}
                                      <div className="d-flex align-items-center gap-5">
                                        <span className="w-100" name="checkTag">
                                          {item?.name} {`(${item.length})`}
                                        </span>
                                        <div className="form-check mb-0 ms-1">
                                          <Input
                                            className="check_box"
                                            type="checkbox"
                                            id="checkTag"
                                            checked={selectedIds?.includes(
                                              item?.id
                                            )}
                                            readOnly={true}
                                          />
                                        </div>
                                      </div>
                                    </DropdownItem>
                                  );
                                })}
                            </DropdownMenu>
                          </Dropdown>
                        </FormGroup>
                      )}
                    </div>

                    {bothDimension && (
                      <div className="d-flex align-items-center gap-3">
                        <FormGroup className="mb-0 d-flex align-items-center gap-1 w-75">
                          <Label for="width" className="mb-0 mini_text">
                            Height
                          </Label>
                          <Input
                            name="width"
                            disabled={true}
                            value={heightFormula}
                            id="width"
                          />
                        </FormGroup>

                        <FormGroup className="mb-0 d-flex align-items-center">
                          <Dropdown
                            isOpen={toggleTagSizHeight}
                            toggle={() => {
                              setToggleTagSizHeight(!toggleTagSizHeight);
                            }}
                          >
                            <DropdownToggle
                              color="primary"
                              className="btn-icon px-2"
                              outline
                            >
                              <IoMdLink size={22} />
                            </DropdownToggle>

                            <DropdownMenu
                              className={`${
                                window?.innerHeight < 900 ? "has_scroll" : ""
                              } body_dropdown hardware_list`}
                              container="body"
                            >
                              {allTagData &&
                                allTagData?.length > 0 &&
                                allTagData?.map((item, index) => {
                                  return (
                                    <DropdownItem
                                      onClick={() => {
                                        handleCuttingFormulaHeight(item);
                                      }}
                                      key={index}
                                    >
                                      {/* {item?.name} */}
                                      <div className="d-flex align-items-center gap-5">
                                        <span className="w-100" name="checkTag">
                                          {item?.name} {`(${item.length})`}
                                        </span>
                                        <div className="form-check mb-0 ms-1">
                                          <Input
                                            className="check_box"
                                            type="checkbox"
                                            id="checkTag"
                                            checked={selectedIdsHeight?.includes(
                                              item?.id
                                            )}
                                            readOnly={true}
                                          />
                                        </div>
                                      </div>
                                    </DropdownItem>
                                  );
                                })}
                            </DropdownMenu>
                          </Dropdown>
                        </FormGroup>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {/* {open === "1" && ( */}

        <div className="d-flex gap-2 gap-xl-3 main_buttons w-100 mt-3 mb-3">
          <Button
            color="primary"
            size="md"
            // disabled={open === "1" && (!heightFormula || !widthFormula)}
            className="px-4"
            onClick={() => {
              isAdditionProfile ? saveData() : saveFormula();
            }}
          >
            Save
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            onClick={() => {
              setIsEdit(false);
              setMultiSelectRefPoints([]);
            }}
          >
            Cancel
          </Button>
        </div>
        {/* )} */}
        {/* </AccordionBody>
            </AccordionItem>
          </Accordion> */}
      </>
    </div>
  );
};

export default ManufacturingEditComp;
