import * as THREE from 'three';
import { createShapeFromWidthHeight } from './utils/threeUtils'


export const addAddOns = (side, data, setMethod, jsonIndex = 0,index) => {

    const { width, height } = data;
    const newWidth = width / 1000;
    const newHeight = height / 1000;
    return (prevModelJson) => {

        const updatedModelArray = [...prevModelJson]
        const updatedModelJson = { ...updatedModelArray[jsonIndex] };

        updatedModelJson.addOn[side]?.push([newWidth, newHeight, {id:data?.id}]);

        // storing data in the json
        setMethod(data, side,index)
        updatedModelArray[jsonIndex] = updatedModelJson;
        return updatedModelArray;

    };
};


export const deleteAddOns = (side, setMethod, jsonIndex = 0) => {

    //this will update the state for deletion 
    setMethod(side)

    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };
        const addOn = updateJson?.addOn;

        if (addOn && addOn[side]) {
            addOn[side].shift()
        } else {
            console.error("Invalid side");
        }

        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray;
    }
}


export function createAddons(addonRef, sceneRef, addOnFrames, frames, frameStyleBottom, group, colorValue) {

    if (!addonRef || typeof addonRef.current === "undefined") {
        console.error("addonRef is not properly initialized. Creating a new object.");
        addonRef.current = [];
    }

    if (addonRef?.current) {
        addonRef.current.visible = false;
        sceneRef.remove(addonRef.current);
    }

    if (sceneRef) {
        var addonLocal
        sceneRef?.traverse(function (object) {
            if (object.name.includes("addon")) {
                object.visible = false;
                addonLocal = object
            }
        });
        sceneRef.remove(addonLocal);
    }


    // const addonDepth = 0.2;
    const addonHeight = 0.04;

    const extrudeSettings = (path, steps) => ({
        // steps: 100,
        steps: steps,
        extrudePath: path, // Path to extrude along
        bevelEnabled: false,  // No bevel
        morphTargetsRelative: true
    });

    const addons = {};
    Object.keys(addOnFrames)?.forEach((side) => {

        const frameData = addOnFrames[side];

        // Skip if the array for the side is empty
        if (!frameData || frameData?.length === 0) return;

        frameData?.forEach((value, index) => {
            console.log(value, "93");

            const addonHeight = value[1];
            let boundingBox;
            let addonPathStart, addonPathEnd;

            const material = frames[side][2].material;
            const frameMaterial_Ext = new THREE.MeshPhongMaterial({
                // color: 0xff0ff0,
                color: colorValue,
                specular: 0xffffff,
                shininess: 10,
                flatShading: false,
                emissive: 0x000000,
                specular: 0x888888,
                bumpScale: 2,
            });
            boundingBox = new THREE.Box3().setFromObject(addons[side] || frames[side][2]);
            const topBoundingBox = new THREE.Box3().setFromObject(frames.top[2]);
            const botBoundingBox = new THREE.Box3().setFromObject(frames.bottom[2]);
            // Determine bounding box based on the side
            if (side === 'bottom') {
                let leftX = boundingBox.min.x;
                let rightX = boundingBox.max.x;
                if (addons.left) leftX = new THREE.Box3().setFromObject(addons.left).min.x;
                if (addons.right) rightX = new THREE.Box3().setFromObject(addons.right).max.x;
                addonPathStart = new THREE.Vector3(leftX, boundingBox.min.y - 0, 0);
                addonPathEnd = new THREE.Vector3(rightX, boundingBox.min.y - 0, 0);
            } else if (side === 'top') {
                let leftX = boundingBox.min.x;
                let rightX = boundingBox.max.x;
                if (addons.left) leftX = new THREE.Box3().setFromObject(addons.left).min.x;
                if (addons.right) rightX = new THREE.Box3().setFromObject(addons.right).max.x;
                addonPathEnd = new THREE.Vector3(leftX, boundingBox.max.y, 0);
                addonPathStart = new THREE.Vector3(rightX, boundingBox.max.y, 0);
            } else if (side === 'left') {
                addonPathEnd = new THREE.Vector3(boundingBox.min.x, botBoundingBox.min.y, 0);
                addonPathStart = new THREE.Vector3(boundingBox.min.x, topBoundingBox.max.y, 0);
            } else if (side === 'right') {
                addonPathStart = new THREE.Vector3(boundingBox.max.x, botBoundingBox.min.y, 0);
                addonPathEnd = new THREE.Vector3(boundingBox.max.x, topBoundingBox.max.y, 0);
            }
            const addonDepth = value[0];

            if (addonDepth && addonHeight) {

                const sideBoundingBox = new THREE.Box3().setFromObject(frames[side][2]);
                const depth = sideBoundingBox.max.z - sideBoundingBox.min.z;

                const addonPath = new THREE.LineCurve3(addonPathStart, addonPathEnd);

                // Update extrude settings and shape
                const addonShape = createShapeFromWidthHeight(addonDepth, addonHeight);

                // Create the geometry and mesh
                const addonGeometry = new THREE.ExtrudeGeometry(addonShape, extrudeSettings(addonPath, 2));
                const exmesh = new THREE.Mesh(addonGeometry, material);
                exmesh.castShadow = true;
                exmesh.receiveShadow = true;
                if (!index % 2) exmesh.material.shininess += 30;
                addons[side] = exmesh;

                // // Adjust position based on the side
                exmesh.position.z = -(depth - addonDepth) / 2;

                // Set a unique name and add to the scene
                exmesh.name = 'addon ' + side;
                if (side == "bottom") {
                    frameStyleBottom.current = exmesh;
                }

                if (exmesh) {
                    addonRef.current = exmesh;
                }
                group.add(exmesh);
                let externalColor = true;
                if(externalColor){
                    const extMesh = exmesh.clone();
                    extMesh.material = frameMaterial_Ext;
                    extMesh.position.z -= 0.1 / 1000;
                    const offsetVal = 1 / 1000;
                    addonGeometry.computeBoundingBox();
                    const beforeBound = addonGeometry.boundingBox;
                    const beforeWidth = beforeBound.max.x - beforeBound.min.x;
                    const beforeHeight = beforeBound.max.y - beforeBound.min.y;
                    const xScaleVal = ((beforeWidth + offsetVal) / beforeWidth);
                    const yScaleVal = (beforeHeight + offsetVal) / beforeHeight;
                    const xCenter = (beforeBound.max.x + beforeBound.min.x) / 2;
                    const xOffset = ((xCenter * (xScaleVal)) - xCenter) / 1;
                    const yCenter = (beforeBound.max.y + beforeBound.min.y) / 2;
                    const yOffset = ((yCenter * (yScaleVal)) - yCenter) / 1;
                    extMesh.scale.x = xScaleVal;
                    extMesh.scale.y = yScaleVal;
                    extMesh.position.x -= side == "left" ? (xOffset + offsetVal) : side == "right" ? (xOffset - offsetVal) : xOffset;
                    extMesh.position.y -= side == "bottom" ? (yOffset + offsetVal) : side == "top" ? (yOffset - offsetVal) : yOffset;
                    group.add(extMesh);
                }
            }
        });
    });

}


export const calculateOffsets = (addOnFrames, sillHeight) => {
    let wHeightOffset = 0;
    let wWidthOffset = 0;
    let wLoffset = 0;
    let wRoffset = 0;
    let wTopOffset = 0;
    let wBOffset = 0;

    const calculateWidth = (frames) => {
        return frames?.reduce((accumulator, currentArray) => {
            return accumulator + (currentArray[1] || 0); // Sum the first element of each inner array
        }, 0);
    };


    if (addOnFrames) {
        const aoRightWidth = calculateWidth(addOnFrames?.right) || 0;
        const aoLeftWidth = calculateWidth(addOnFrames?.left) || 0;
        const aoTopWidth = calculateWidth(addOnFrames?.top) || 0;
        const aoBottomWidth = calculateWidth(addOnFrames?.bottom) || 0;

        const aoWidth = aoRightWidth + aoLeftWidth;
        const aoHeight = aoTopWidth + aoBottomWidth;

        wWidthOffset = aoWidth;
        wHeightOffset = aoHeight + sillHeight;

        wLoffset = aoLeftWidth; // Left offset
        wRoffset = aoRightWidth; // Right offset
        wTopOffset = aoTopWidth;
        wBOffset = aoBottomWidth;
    }

    return { wWidthOffset, wHeightOffset, wLoffset, wRoffset, wTopOffset, wBOffset };
}
