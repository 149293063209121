export const applyPanels = (panelData, id, index, jsonIndex = 0, transomId) => {
  return (prevModelJson) => {
    const updatedModelArray = [...prevModelJson];
    const updatedJson = { ...updatedModelArray[jsonIndex] };
    const sash = updatedJson?.sash || {};
    const transom = updatedJson?.transom || {};
    let updateTransomId=transomId.split(" ")[0]
    
    let updatedIndex = updateTransomId.split("_").pop();

    // Ensure `panels` exists in the main object
    if (!updatedJson.panels) {
      updatedJson.panels = {};
    }
    updatedJson.panels.isPanel = false; // Add the isPanel key

    if (sash && !sash.sections?.length && transom && !transom?.splits?.length) {
      // updatedJson.panels.isPanel = true; // Set isPanel in the main panels
      updatedJson.panels = {
        ...panelData,
        isPanel: true,
      };
    } else if (transom && transom?.splits && transom?.splits?.length > 0) {
      updatedJson.transom = addPanelToObjectById(transom, updateTransomId, panelData);
    } else if (sash && sash.sections && sash.sections?.length > 0) {
      if (sash?.sections?.length > 0) {
        sash?.sections?.forEach((item) => {
          if (item?.splits && item?.splits?.length > 0) {
            item.splits = updateTransomStatus(
              item?.splits,
              updateTransomId,
              panelData
            );
          } else {
            if (updatedIndex > -1 && updatedIndex !== undefined) {              
              // Ensure `panels` exists in the specific section
              // if (sash?.sections[updatedIndex]?.panels==undefined || !sash?.sections[updatedIndex]?.panels) {
              //   sash.sections[updatedIndex].panels = {};
              // }
              sash.sections[Number(updatedIndex)].panels = {
                ...panelData,
                isPanel: true,
              };
              // sash.sections[updatedIndex].panels.isPanel = true; // Add isPanel key to the section panels
            } else {
              sash.sections.forEach((section) => {
                // Ensure `panels` exists in each section
                // if (!section.panels) {
                //   section.panels = {};
                // }
                section.panels.isPanel = true; // Add isPanel key to each section panels
              });
            }
          }
        });
      }
    }

    updatedModelArray[jsonIndex] = updatedJson;
    return updatedModelArray;
  };
};

export const deletePanels = (
  id,
  index,
  jsonIndex = 0,
  glassIndex,
  updateData,
  transomId
) => {
  return (prevModelJson) => {
    const updatedModelArray = [...prevModelJson];
    const updatedJson = { ...updatedModelArray[jsonIndex] };
    const sash = updatedJson?.sash || {};
    const transom = updatedJson?.transom || {};
    let updatedTransomId=transomId.split(" ")[0]
    let updatedIndex = updatedTransomId.split("_").pop();

    // Ensure `panels` exists in the main object
    if (!updatedJson.panels) {
      updatedJson.panels = {};
    }
    updatedJson.panels.isPanel = false; // Add the isPanel key

    if (sash && !sash.sections?.length && transom && !transom?.splits?.length) {
      // updatedJson.panels.isPanel = false; // Set isPanel in the main panels
      updatedJson.panels = {
        isPanel: false,
      };
    } else if (transom && transom?.splits && transom?.splits?.length > 0) {
      updatedJson.transom = removePanelStatus(transom, updatedTransomId);
    } else if (sash && sash.sections?.length) {
      if (sash?.sections?.length > 0) {
        sash.sections?.forEach((item) => {
          if (item?.splits && item?.splits?.length > 0) {
            item.splits = deleteTransomStatus(item?.splits, updatedTransomId);
          } else {
            if (updatedIndex > -1 && updatedIndex !== undefined) {
              // Ensure `panels` exists in the specific section
              if (!sash.sections[Number(updatedIndex)].panels) {
                sash.sections[Number(updatedIndex)].panels = {};
              }
              // sash.sections[Number(updatedIndex)].panels.isPanel = false; // Add isPanel key to the section panels
              sash.sections[Number(updatedIndex)].panels = {
                isPanel: false,
              };
            } else {
              sash.sections.forEach((section) => {
                // Ensure `panels` exists in each section
                if (!section.panels) {
                  section.panels = {};
                }
                section.panels.isPanel = false; // Add isPanel key to each section panels
              });
            }
          }
        });
      }
    }

    updateData(Number(updatedIndex) ? Number(updatedIndex) : 0, transomId);

    updatedModelArray[jsonIndex] = updatedJson;
    return updatedModelArray;
  };
};

function addPanelToObjectById(obj, targetId, data) {
  // Check if the current object matches the target ID
  if (obj.id === targetId) {
    return {
      ...obj,
      panels: { ...data, isPanel: true }, // Add the panels key immutably
    };
  }

  // If the object has nested `sections`, process them immutably
  if (Array.isArray(obj.sections)) {
    return {
      ...obj,
      sections: obj.sections.map((section) =>
        addPanelToObjectById(section, targetId, data)
      ),
    };
  }

  // If the object has nested `splits`, process them immutably
  if (Array.isArray(obj.splits)) {
    return {
      ...obj,
      splits: obj.splits.map((split) =>
        addPanelToObjectById(split, targetId, data)
      ),
    };
  }

  // Return the object as-is if no match is found
  return obj;
}

function removePanelStatus(obj, targetId) {
  // Check if the current object matches the target ID
  if (obj.id === targetId) {
    return {
      ...obj,
      panels: { isPanel: false }, // Add the panels key immutably
    };
  }

  // If the object has nested `sections`, process them immutably
  if (Array.isArray(obj.sections)) {
    return {
      ...obj,
      sections: obj.sections.map((section) =>
        removePanelStatus(section, targetId)
      ),
    };
  }

  // If the object has nested `splits`, process them immutably
  if (Array.isArray(obj.splits)) {
    return {
      ...obj,
      splits: obj.splits.map((split) => removePanelStatus(split, targetId)),
    };
  }

  // Return the object as-is if no match is found
  return obj;
}

function updateTransomStatus(arr, targetId, data) {
  // Map over the array and process each object
  return arr.map((obj) => addPanelObject(obj, targetId, data));
}

function addPanelObject(obj, targetId, data) {
  // Check if the current object matches the target ID
  if (obj.id === targetId) {
    return {
      ...obj,
      panels: { ...data, isPanel: true }, // Add the panels key immutably
    };
  }

  // If the object has nested `sections`, process them immutably
  if (Array.isArray(obj.sections)) {
    return {
      ...obj,
      sections: obj.sections.map((section) =>
        addPanelObject(section, targetId, data)
      ),
    };
  }

  // If the object has nested `splits`, process them immutably
  if (Array.isArray(obj.splits)) {
    return {
      ...obj,
      splits: obj.splits.map((split) => addPanelObject(split, targetId, data)),
    };
  }

  // Return the object as-is if no match is found
  return obj;
}

function deleteTransomStatus(arr, targetId) {
  // Map over the array and process each object
  return arr.map((obj) => removePanelObj(obj, targetId));
}

function removePanelObj(obj, targetId) {
  // Check if the current object matches the target ID
  if (obj.id === targetId) {
    return {
      ...obj,
      panels: { isPanel: false }, // Add the panels key immutably
    };
  }

  // If the object has nested `sections`, process them immutably
  if (Array.isArray(obj.sections)) {
    return {
      ...obj,
      sections: obj.sections.map((section) =>
        removePanelObj(section, targetId)
      ),
    };
  }

  // If the object has nested `splits`, process them immutably
  if (Array.isArray(obj.splits)) {
    return {
      ...obj,
      splits: obj.splits.map((split) => removePanelObj(split, targetId)),
    };
  }

  // Return the object as-is if no match is found
  return obj;
}
