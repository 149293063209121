import {
  getTrasomsFrameService,
  setModelData,
  setQuotationModelData,
  setQuotationPriceModelProperty,
} from "../services/3dModelServices";
import { handleError } from "./commonHelper";

export function storeExtColor(
  externalColor,
  externalRAL,
  internalColor,
  internalRAL,
  rgbStringToHex,
  setCustomModelData,
  setSaveColors
) {
  let externalColour = {
    name: externalColor?.name,
    hex:
      externalRAL !== undefined
        ? rgbStringToHex(externalRAL?.rgbValue)
        : externalColor?.hex,
    customRALName: externalRAL !== undefined ? externalRAL?.name : "",
    customRALCode: externalRAL !== undefined ? externalRAL?.number : "",
    rgbValue: externalRAL !== undefined ? externalRAL?.rgbValue : "",
    type: "external",
    id: 1,
    itemId: externalColor?.id,
    primaryId: externalColor?.primaryId,
    custom_Id: externalRAL !== undefined ? externalRAL?.id : "",
    obj: "External",
    model:
      "https://testorbitapp.augursapps.com/Content/VisualElement/White9017.svg",
    bumpiness: 0,
    roughness: 0,
    metalness: 0,
    price: externalColor?.price || 0,
    priceInpercentage: externalColor?.priceInpercentage || 0,
    barLengthPrice: externalColor?.barLengthPrice || 0,
    stockColor: externalColor?.stockColour,
    mmSquarePrice: externalColor?.mmSquarePrice,
    chartColourType:externalColor?.chartColourType
  };

  let internalColour = {
    name: internalColor?.name,
    hex:
      internalRAL !== undefined
        ? rgbStringToHex(internalRAL?.rgbValue)
        : internalColor?.hex,
    customRALName: internalRAL !== undefined ? internalRAL?.name : "",
    customRALCode: internalRAL !== undefined ? internalRAL?.number : "",
    rgbValue: internalRAL !== undefined ? internalRAL?.rgbValue : "",
    type: "internal",
    id: 1,
    itemId: internalColor?.id,
    custom_Id: internalRAL !== undefined ? internalRAL?.id : "",
    obj: "Internal",
    model:
      "https://testorbitapp.augursapps.com/Content/VisualElement/White9017.svg",
    bumpiness: 0,
    roughness: 0,
    metalness: 0,
    price: internalColor?.price || 0,
    priceInpercentage: internalColor?.priceInpercentage || 0,
    barLengthPrice: internalColor?.barLengthPrice || 0,
    stockColor: internalColor?.stockColour,
    mmSquarePrice: externalColor?.mmSquarePrice,
    chartColourType:internalColor?.chartColourType
  };

  setCustomModelData((prevModelData) => ({
    ...prevModelData,
    frame: {
      ...prevModelData.frame,
      externalColor: externalColour,
      internalColor: internalColour?.itemId
        ? internalColour
        : prevModelData?.frame?.internalColor,
    },
  }));

  // setTimeout(() => {
  //   setSaveColors(true);
  // }, 100);
}

export async function saveModelData(
  accessToken,
  modelId,
  styleId,
  customModelData,
  modelJson,
  navigate,
  setMessage,
  setMessageType
) {
  const res = await setModelData(
    accessToken,
    modelId,
    styleId,
    customModelData,
    modelJson
  );
  if (res?.status == 200) {
    if (res?.data?.statusCode === 200) {
      // setMessage("Frame data saved")
      // setMessageType("success")
      console.log(res?.data, "model data saved")
    }
  } else {
    handleError(res, false, navigate)
  }
}

export async function saveModelDataInit(
  accessToken,
  modelId,
  styleId,
  customModelData,
  modelJson,
  setSaveData
) {
  const res = await setModelData(
    accessToken,
    modelId,
    styleId,
    customModelData,
    modelJson
  );

  if (res && res.status === 200) {
    setSaveData(false);
  } else {
    setSaveData(false);
  }
}

export async function saveModelDataQuotation(
  accessToken,
  modelId,
  styleId,
  customModelData,
  quotationId,
  receivedProductId,
  navigate,
  setMessage,
  setMessageType,
  modelJson
) {
  const res = await setQuotationModelData(
    accessToken,
    modelId,
    styleId,
    customModelData,
    quotationId,
    receivedProductId,
    modelJson
  );
  
  if (res?.status == 200) {
    if (res?.data?.statusCode === 200) {
      // setMessage("Quote data saved")
      // setMessageType("success")
      console.log("quote data saved")
    }
  } else {
    handleError(res, false, navigate)
  }
}

export async function saveQuotationPriceData(
  accessToken,
  receivedProductId,
  priceJson,
  navigate,
  setMessage,
  setMessageType
) {
  const res = await setQuotationPriceModelProperty(
    accessToken,
    receivedProductId,
    priceJson
  );
  if (res?.status == 200) {
    if (res?.data?.statusCode === 200) {
      console.log("pricing data saved")
    }
  } else {
    handleError(res, false, navigate);
  }
}

export async function getFramesProfilesDefault(
  accessToken,
  modelId,
  setFrameProfileDefault
) {
  const res = await getTrasomsFrameService(accessToken, modelId, 6);
  if (res && res.status === 200) {
    if (res?.data?.statusCode === 200) {
      if (res?.data?.entity && res?.data?.entity?.frame?.length > 0) {
        const frameDefault = res?.data?.entity?.frame?.find(
          (p) => p.defaultProfile === true
        );

        if (frameDefault) {
          setFrameProfileDefault(frameDefault);
        } else {
          setFrameProfileDefault(res?.data?.entity?.frame[0]);
        }
      }
    }
  }
}

const getCanvasAsBase64 = (canvas) => {
  return canvas?.toDataURL("image/png");
};

// This is for adding colorPrice key in the hardware object
export const addHardwareColorPricing = (color, type, name, setMethod) => {
  setMethod((prevData) => {
    // Create a copy of the hardware array inside customModelData
    const updatedHardware = prevData?.hardware?.[type]?.map((item) => {
      if (item?.name === name) {
        // Return a new object with the updated colorPrice
        return {
          ...item,
          colorPrice: color.price,
        };
      }
      return item;
    });

    // Return a new object with the updated hardware array and other properties spread
    return {
      ...prevData,
      hardware: {
        ...prevData.hardware,
        [type]: updatedHardware,
      },
    };
  });
};

export const updateShapedCornerData = (setModelData, modelData, savingJson) => {
  if (!modelData?.shapedCornerData) {
    setModelData({
      ...modelData,
      shapedCornerData: [savingJson],
    });
  } else {
    const updatedArray = modelData?.shapedCornerData.map((item) =>
      item.cornerName === savingJson.cornerName ? savingJson : item
    );

    const isReplaced = updatedArray.some(
      (item) => item.cornerName === savingJson.cornerName
    );

    if (!isReplaced) {
      updatedArray.push(savingJson);
    }

    setModelData({
      ...modelData,
      shapedCornerData: updatedArray,
    });
  }
};

export function getHexValue(obj) {
  // Helper function to convert RGB to Hex
  const rgbToHex = (rgb) => {
      const [r, g, b] = rgb.match(/\d+/g).map(Number); // Extract RGB values as numbers
      return (
          "#" +
          ((1 << 24) + (r << 16) + (g << 8) + b)
              .toString(16)
              .slice(1)
              .toUpperCase()
      );
  };

  if (obj.rgbValue) {
      return rgbToHex(obj.rgbValue); // Convert the dynamic RGB value to Hex
  } else if (obj.hex) {
      return obj.hex; // Return the existing Hex value
  }

  return null; // Return null if neither key exists
}