import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import mountains from "../../assets/img/mountain.png";

function RotatingCube({ elements, roughnessVal, colorVal, metalNessVal, bumpiness, textureVal }) {
  const canvasRef = useRef();
  const renderer = useRef(null);
  const camera = useRef(null);
  const scene = useRef(null);
  const material = useRef(null);
  const sphere = useRef(null);
  const geometry = useRef(null);

  const baseMap = useRef(null);
  const bumpMap = useRef(null);

  useEffect(() => {
    setUpSphere();

    return () => cleanupResources(); // Cleanup when the component unmounts
  }, []);

  useEffect(() => {
    setMaterialValues(metalNessVal, roughnessVal, colorVal, bumpiness);
  }, [metalNessVal, roughnessVal, colorVal, bumpiness]);

  useEffect(() => {
    const textureLoader = new THREE.TextureLoader();

    if (!material.current) {
      material.current = new THREE.MeshStandardMaterial();
    }

    textureLoader.load(
      elements,
      (bumpMapTexture) => {
        if (material.current) {
          material.current.bumpMap = bumpMapTexture;
          bumpMap.current = bumpMapTexture;

          if (baseMap.current) {
            material.current.map = baseMap.current;
          }

          if (sphere.current) {
            sphere.current.material = material.current;
          }
          setMaterialValues(metalNessVal, roughnessVal, colorVal, bumpiness);
          renderer.current.render(scene.current, camera.current);
        }
      },
      undefined,
      (error) => {
        // console.error("Error loading bump map texture:", error);
      }
    );
  }, [elements]);

  useEffect(() => {
    const textureLoader = new THREE.TextureLoader();

    if (!material.current) {
      material.current = new THREE.MeshStandardMaterial();
    }

    textureLoader.load(
      textureVal,
      (mapTexture) => {
        if (material.current) {
          material.current.map = mapTexture;
          baseMap.current = mapTexture;

          if (bumpMap.current) {
            material.current.bumpMap = bumpMap.current;
          }

          if (sphere.current) {
            sphere.current.material = material.current;
          }
          setMaterialValues(metalNessVal, roughnessVal, colorVal, bumpiness);
          renderer.current.render(scene.current, camera.current);
        }
      },
      undefined,
      (error) => {
        // console.error("Error loading base map texture:", error);
      }
    );
  }, [textureVal]);

  const setUpSphere = () => {
    if (!canvasRef.current) {
      // console.error("Canvas element is not available!");
      return;
    }

    if (renderer.current) {
      // console.warn("WebGL renderer already exists. Skipping creation.");
      return;
    }

    // Initialize scene, camera, and renderer
    scene.current = new THREE.Scene();
    camera.current = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    camera.current.position.z = 1.7;

    renderer.current = new THREE.WebGLRenderer({
      alpha: true,
      canvas: canvasRef.current,
      antialias: true,
      preserveDrawingBuffer: true,
    });
    renderer.current.setSize(70, 70);

    // Add lighting
    const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
    directionalLight.position.set(1, 1, 1);
    scene.current.add(directionalLight);

    // Create sphere geometry and add it to the scene
    geometry.current = new THREE.SphereGeometry(1, 70, 70);
    material.current = new THREE.MeshStandardMaterial();
    sphere.current = new THREE.Mesh(geometry.current, material.current);
    scene.current.add(sphere.current);

    // Initial render
    renderer.current.render(scene.current, camera.current);
  };

  const setMaterialValues = (metalNessVal, roughnessVal, colorVal, bumpiness) => {
    if (!renderer.current) {
      // console.error("Renderer is not initialized!");
      return;
    }

    const textureLoader = new THREE.TextureLoader();

    textureLoader.load(
      mountains,
      (texture) => {
        try {
          const pmremGenerator = new THREE.PMREMGenerator(renderer.current);
          pmremGenerator.compileEquirectangularShader();

          const envMap = pmremGenerator.fromEquirectangular(texture).texture;

          material.current.envMap = envMap;
          material.current.metalness = metalNessVal;
          material.current.roughness = roughnessVal;
          material.current.color = new THREE.Color(colorVal);

          if (bumpiness < 1) {
            material.current.bumpScale = bumpiness;
          }

          sphere.current.material = material.current;

          renderer.current.render(scene.current, camera.current);

          pmremGenerator.dispose();
        } catch (error) {
          // console.error("Error during PMREM generation:", error);
        }
      },
      undefined,
      (error) => {
        // console.error("Error loading environment map texture:", error);
      }
    );
  };

  const cleanupResources = () => {
    if (renderer.current) {
      renderer.current.dispose();
      renderer.current.forceContextLoss();
      renderer.current = null;
    }

    if (material.current) {
      material.current.dispose();
      material.current = null;
    }

    if (geometry.current) {
      geometry.current.dispose();
      geometry.current = null;
    }

    if (scene.current) {
      while (scene.current.children.length > 0) {
        const child = scene.current.children.pop();
        if (child.geometry) child.geometry.dispose();
        if (child.material) child.material.dispose();
      }
      scene.current = null;
    }
  };

  return <canvas ref={canvasRef} id="ballContainer" />;
}

export default RotatingCube;