import { servicePath } from "../../../../utility/data";
import { getFrameMesh } from "../createWindowFrame";
import { calculateVerticalPosition } from "../hardwares";
import { getSashMesh } from "../sashes";
import * as THREE from 'three';



let lastActivityTime = Date.now();
let idleThreshold = 5000; // 5 seconds
let isIdle = false;
let partialDimensionObj = {};


export function resetIdleTimer() {
    lastActivityTime = Date.now();
    if (isIdle) {
        console.log("User is active again");
        isIdle = false;
    }
}


export const storeTransomPartialDimensions = (obj) => {
    partialDimensionObj = { ...obj };
}

export const getTransomPartialDimensions = () => {
    return partialDimensionObj;
}


export function idle() {
    const currentTime = Date.now();
    const elapsedTime = currentTime - lastActivityTime;

    if (elapsedTime > idleThreshold && !isIdle) {
        console.log("User is idle");
        isIdle = true;
    }
    return isIdle;
}



export const getHeightFromAngle = (startAngle, fheight) => {
    const angleRadians = (startAngle * Math.PI) / 180;
    const yOffset = fheight * Math.cos(angleRadians);
    return yOffset;
}

export const calculateHardwareRefs = (hardware, modelJson) => {
    if (!hardware || !hardware?.hardwareStyles || !hardware?.hardwareStyles[0]) {
        console.error("Invalid hardware input or missing hardware styles.");
        return [];
    }

    if (!modelJson || !modelJson?.dimensions) {
        console.error("Invalid modelJson input or missing dimensions.");
        return [];
    }

    const modifiedRefs = [];
    const hardwareStyles = hardware.hardwareStyles[0];
    const defaultPos = hardwareStyles.defaultPosition;
    const center = hardwareStyles.isProfileCenter;


    const profileMesh = [...getSashMesh(), ...getFrameMesh()];

    const modelHeight = modelJson?.dimensions?.height;

    profileMesh.forEach((mesh) => {

        try {
            const boundingBox = new THREE.Box3().setFromObject(mesh);
            const profileCenter = new THREE.Vector3();
            boundingBox.getCenter(profileCenter);

            const profileBottom = boundingBox.min.y;
            const profileHeight = boundingBox.max.y - boundingBox.min.y;

            if (mesh.name === "SashLeft" || mesh.name === "SashRight") {
                const vPos = calculateVerticalPosition(
                    center,
                    defaultPos,
                    modelHeight,
                    profileHeight,
                    profileBottom,
                    profileCenter
                );

                const calculatedPos = {
                    position: { x: profileCenter.x, y: vPos, z: profileCenter.z },
                    name: mesh.name,
                };
                modifiedRefs.push(calculatedPos);
            } else {
                const profilePos = {
                    position: { x: profileCenter.x, y: profileCenter.y, z: profileCenter.z },
                    name: mesh.name,
                };
                modifiedRefs.push(profilePos);
            }
        } catch (error) {
            console.error(`Error processing mesh "${mesh.name}":`, error);
        }
    });

    return modifiedRefs;
};


export const centerCoordinates = (mesh) => {

    try {

        if (!mesh || !(mesh instanceof THREE.Object3D)) {
            console.error("Invalid mesh: Expected a THREE.Object3D instance.");
            return;
        }

        const boundingBox = new THREE.Box3().setFromObject(mesh);
        if (!boundingBox.isEmpty()) {
            const center = new THREE.Vector3()
            boundingBox.getCenter(center)
            return {
                position: {
                    x: center.x,
                    y: center.y,
                    z: center.z,
                },
                name: mesh.name
            };
        } else {
            console.error("Bounding box is empty: Ensure the mesh has geometry or children.");
            return;
        }

    } catch (error) {
        console.log(error);
        return {
            x: 0,
            y: 0,
            z: 0,
            name: ""
        }
    }
}


export function findTransomIdBySplitId(data, splitId) {
    // Iterate over each item in the input array
    for (let item of data) {
      // If the current item has splits, check each split
      if (item.splits) {
        for (let split of item.splits) {
          // If the splitId matches, return the transomId
          if (split.id === splitId) {
            return split.transomId;
          }
          
          // Recursively search in nested splits and sections
          const transomId = findTransomIdBySplitId(split.splits || [], splitId);
          if (transomId) {
            return transomId;
          }
          
          // Search in sections if they have splits as well
          if (split.sections) {
            for (let section of split.sections) {
              const transomIdInSection = findTransomIdBySplitId(section.splits || [], splitId);
              if (transomIdInSection) {
                return transomIdInSection;
              }
            }
          }
        }
      }
    }
    // Return null if no matching splitId is found
    return null;
  }


// function createSVGPathFromCurves(curves) {
//     let pathData = '';
    
//     curves.forEach((curve, index) => {
//         if (!curve || !curve.v1 || !curve.v2) {
//             console.warn(`Invalid curve at index ${index}, skipping.`);
//             return;
//         }
//         const start = curve.v1;
//         const end = curve.v2;

//         if (index === 0) {
//             pathData += `M ${start.x} ${-start.y} `;
//         }
//         pathData += `L ${end.x} ${-end.y} `;
//     });

//     return pathData.trim();
// }

// // Create SVG element
// export function createSVGElement(curves) {
//     const svgNamespace = "http://www.w3.org/2000/svg";

//     const svg = document.createElementNS(svgNamespace, "svg");
//     svg.setAttribute("width", "500");
//     svg.setAttribute("height", "500");
//     svg.setAttribute("viewBox", "-5 -5 10 10");
//     svg.style.border = "1px solid black";

//     const path = document.createElementNS(svgNamespace, "path");
//     path.setAttribute("d", createSVGPathFromCurves(curves));
//     path.setAttribute("stroke", "blue");
//     path.setAttribute("fill", "none");
//     path.setAttribute("stroke-width", "0.1");

//     svg.appendChild(path);
//     return svg;
// }



function createSVGPathsFromCurves(curves) {
    const paths = [];
    
    curves.forEach((curve, index) => {
        // Skip null or undefined curves
        if (!curve || !curve.v1 || !curve.v2) {
            console.warn(`Invalid curve at index ${index}, skipping.`);
            return;
        }

        const start = curve.v1; // Start point
        const end = curve.v2;   // End point

        // Create an SVG path for this curve
        const pathData = `M ${start.x} ${-start.y} L ${end.x} ${-end.y}`;
        paths.push(pathData);
    });

    return paths;
}

// Create SVG element
export function createSVGElement_(curves) {
    const svgNamespace = "http://www.w3.org/2000/svg";

    // Create the SVG root
    const svg = document.createElementNS(svgNamespace, "svg");
    svg.setAttribute("width", "500");
    svg.setAttribute("height", "500");
    svg.setAttribute("viewBox", "-5 -5 10 10");
    svg.style.border = "1px solid black";

    // Generate individual paths
    const pathDataArray = createSVGPathsFromCurves(curves);

    pathDataArray.forEach((pathData, index) => {
        const path = document.createElementNS(svgNamespace, "path");
        path.setAttribute("d", pathData);
        path.setAttribute("stroke", "blue");
        path.setAttribute("fill", "none");
        path.setAttribute("stroke-width", "0.1");
        svg.appendChild(path);
    });

    return svg;
}

export function createSVGElement(windowData, className = "window-svg") {
    const svgNamespace = "http://www.w3.org/2000/svg";

    // Check if an existing SVG with the same class name exists, and remove it
    const existingSvg = document.querySelector(`.${className}`);
    if (existingSvg) {
        existingSvg.remove();
    }

    // Check if an existing SVG with the same class name exists, and remove it
    const existingWrapper = document.querySelector(`.${className}-wrapper`);
    if (existingWrapper) {
        existingWrapper.remove();
    }

    // Create the wrapper div
    const wrapperDiv = document.createElement("div");
    wrapperDiv.setAttribute("class", `${className}-wrapper`);
    wrapperDiv.style.position = "relative";
    wrapperDiv.style.display = "inline-block";

    // Extract dimensions
    const { dimensions, sash } = windowData;
    const { width, height } = dimensions;
    const sashSize = sash?.sashSize || [];

    // Determine scaling factor to fit within 100x100
    const maxSize = 100;
    const scaleFactor = Math.min(maxSize / width, maxSize / height);

    const scaledWidth = width * scaleFactor;
    const scaledHeight = height * scaleFactor;

    // Create SVG element
    const svg = document.createElementNS(svgNamespace, "svg");
    svg.setAttribute("class", className); // Add the class name
    svg.setAttribute("width", maxSize);
    svg.setAttribute("height", maxSize);
    svg.setAttribute("viewBox", `0 0 ${scaledWidth} ${scaledHeight}`);
    svg.style.border = "1px solid black";

    // Create the window outline
    const rect = document.createElementNS(svgNamespace, "rect");
    rect.setAttribute("x", 0);
    rect.setAttribute("y", 0);
    rect.setAttribute("width", scaledWidth);
    rect.setAttribute("height", scaledHeight);
    rect.setAttribute("stroke", "blue");
    rect.setAttribute("fill", "none");
    rect.setAttribute("stroke-width", "2");
    svg.appendChild(rect);

    // Function to handle transoms recursively
    // function drawTransoms(splits, startX, endX, startY, endY, ind) {
    //     if (!splits) return;

    //     splits.forEach((split) => {
    //         if (split.type === "transom") {
    //             const splitRatios = split.ratios;

    //             if (split.orientation === "horizontal") {
    //                 let currentY = startY;
    //                 splitRatios.forEach((ratio, index) => {
    //                     const nextY = currentY + ratio * (endY - startY);
    //                     if(index == 0){
    //                     const transomLine = document.createElementNS(svgNamespace, "line");
    //                     transomLine.setAttribute("x1", startX);
    //                     transomLine.setAttribute("y1", nextY);
    //                     transomLine.setAttribute("x2", endX);
    //                     transomLine.setAttribute("y2", nextY);
    //                     transomLine.setAttribute("stroke", "red");
    //                     transomLine.setAttribute("stroke-width", "1");
    //                     svg.appendChild(transomLine);}

    //                     currentY = nextY; // Update currentY for the next transom
    //                 });
    //             } else if (split.orientation === "vertical") {
    //                 let currentX = startX;
    //                 splitRatios.forEach((ratio, index) => {
    //                     const nextX = currentX + ratio * (endX - startX);
    //                     if(index == 0){
    //                     const transomLine = document.createElementNS(svgNamespace, "line");
    //                     transomLine.setAttribute("x1", nextX);
    //                     transomLine.setAttribute("y1", startY);
    //                     transomLine.setAttribute("x2", nextX);
    //                     transomLine.setAttribute("y2", endY);
    //                     transomLine.setAttribute("stroke", "red");
    //                     transomLine.setAttribute("stroke-width", "1");
    //                     svg.appendChild(transomLine);}

    //                     currentX = nextX; // Update currentX for the next transom
    //                 });
    //             }

    //             // Recursively handle nested transoms
    //             if (split.sections && split.sections) {
    //                 const sectionWidth = (endX - startX) / splitRatios.length;
    //                 const sectionHeight = (endY - startY) / splitRatios.length;

    //                 split.sections.forEach((section, index) => {
    //                     const sectionStartX = startX + index * sectionWidth;
    //                     const sectionEndX = sectionStartX + sectionWidth;
    //                     const sectionStartY = startY + index * sectionHeight;
    //                     const sectionEndY = sectionStartY + sectionHeight;
    //                     if (section.splits) {
    //                         drawTransoms(section.splits, sectionStartX, sectionEndX, sectionStartY, sectionEndY, index);
    //                         drawTransoms2(split.sections[index].splits[0], startPosX, startPosY, parentWidth, parentHeight, transomFrameHeight, transomFrameDepth, materials, colorExt);
    //                     }
    //                 });
    //             }
    //         }
    //     });
    // }

    // Add sash partitions
    let currentX = 0; // Track the cumulative X position for each sash
    sashSize.forEach((sashWidth, sashIndex) => {
        const sashScaledWidth = (sashWidth / 1000) * scaleFactor;

        // Create a vertical sash line
        if(sashSize.length != sashIndex+1){
            const line = document.createElementNS(svgNamespace, "line");
            line.setAttribute("x1", currentX + sashScaledWidth);
            line.setAttribute("y1", 0);
            line.setAttribute("x2", currentX + sashScaledWidth);
            line.setAttribute("y2", scaledHeight);
            line.setAttribute("stroke", "green");
            line.setAttribute("stroke-width", "2");
            if(sashIndex == 0){
                svg.appendChild(line);
                const existingSvg = document.querySelector(`.${className}`);
                if (existingSvg) {
                    existingSvg.remove();
                }
                wrapperDiv.appendChild(svg)
                document.body.appendChild(wrapperDiv);
            }else{
                document.getElementsByClassName("window-svg")[0].appendChild(line);
                // const html = document.getElementsByClassName("window-svg")[0].outerHTML;
                // document.getElementsByClassName("window-svg")[0].innerHTML="";
                // document.getElementsByClassName("window-svg")[0].innerHTML=html;
            }
        }
        
        // Add transoms within the current sash
        const sashSection = sash.sections[sashIndex];
        if (sashSection && sashSection.splits) {
            // drawTransoms(sashSection.splits, currentX, currentX + sashScaledWidth, 0, scaledHeight, 0);
            drawTransoms2(sashSection.splits[0], currentX, 0, sashScaledWidth, scaledHeight, 0, 0, "materials", "colorExt", svgNamespace, svg);
        }

        currentX += sashScaledWidth; // Move to the next sash
    });
// setTimeout(() => {
//     if (document.getElementsByClassName("window-svg").length > 0) {
//         const html = document.getElementsByClassName("window-svg")[0].outerHTML;
//         document.getElementsByClassName("window-svg")[0].innerHTML="";
//         document.getElementsByClassName("window-svg")[0].innerHTML=html;
//     }
// }, 1500);
    // return svg;
}






// // Example usage
// const curves = [
//     new THREE.LineCurve3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(1, 1, 0)),
//     new THREE.LineCurve3(new THREE.Vector3(1, 1, 0), new THREE.Vector3(2, 0, 0)),
//     new THREE.LineCurve3(new THREE.Vector3(2, 0, 0), new THREE.Vector3(3, 1, 0)),
// ];

// // Generate SVG preview
// const svgElement = createSVGElementForSeparatePaths(curves);
// document.body.appendChild(svgElement);

export const drawTransoms2 = (split, startPosX, startPosY, parentWidth, parentHeight, transomFrameHeight, transomFrameDepth, materials, svgNamespace, svg) => {

    const orientation = split?.orientation;
    const splitRatios = split?.ratios;
  
    if (splitRatios && splitRatios.length < 2) {
      console.error("Error: Invalid split ratios. Expected an array with at least two values.");
      return;
    }
  
  
    const startPosX2 = startPosX + (orientation === "vertical" ? splitRatios[0] * parentWidth : 0);
    const startPosY2 = startPosY - (orientation === "horizontal" ? splitRatios[0] * parentHeight : 0);
    const section = split?.sections[0];
    const section2 = split?.sections[1];
  
  
    if (!section || !section.id || !section2 || !section2.id) {
      console.error("Error: Invalid sections in split. Sections must have an id.");
      return;
    }
  
  
    if (orientation === "horizontal") {
        const transomLine = document.createElementNS(svgNamespace, "line");
        transomLine.setAttribute("x1", startPosX);
        transomLine.setAttribute("y1", (startPosY2*(-1)));
        transomLine.setAttribute("x2", (startPosX + parentWidth));
        transomLine.setAttribute("y2", (startPosY2*(-1)));
        transomLine.setAttribute("stroke", "red");
        transomLine.setAttribute("stroke-width", "1");
        document.getElementsByClassName("window-svg")[0].appendChild(transomLine);
      
    //   transomMesh.position.y = startPosY2 - transomFrameHeight / 2;
    //   transomMesh.position.x = startPosX2 + parentWidth / 2;
    } else {
        const transomLine = document.createElementNS(svgNamespace, "line");
        transomLine.setAttribute("x1", startPosX2 + transomFrameHeight);
        transomLine.setAttribute("y1", (startPosY * (-1)));
        transomLine.setAttribute("x2", startPosX2 + transomFrameHeight);
        transomLine.setAttribute("y2", ((startPosY2 - parentHeight) * (-1)));
        transomLine.setAttribute("stroke", "red");
        transomLine.setAttribute("stroke-width", "1");
        document.getElementsByClassName("window-svg")[0].appendChild(transomLine);

        
    //   transomMesh.position.y = startPosY2 - parentHeight / 2;
    //   transomMesh.position.x = startPosX2 + transomFrameHeight / 2;
    }
  
    const splitHeight = split?.orientation === 'horizontal'
      ? splitRatios[0] * parentHeight // Constrain height to parent's height
      : parentHeight;
    const splitWidth = split?.orientation === 'horizontal'
      ? parentWidth
      : splitRatios[0] * parentWidth;
    const splitHeight2 = split?.orientation === 'horizontal'
      ? splitRatios[1] * parentHeight // Constrain height to parent's height
      : parentHeight;
    const splitWidth2 = split?.orientation === 'horizontal'
      ? parentWidth
      : splitRatios[1] * parentWidth;
  
    if (section.splits) {
      drawTransoms2(
        section.splits[0],
        startPosX,
        startPosY,
        splitWidth,
        splitHeight, transomFrameHeight, transomFrameDepth, materials, svgNamespace, svg
      );
    } else {
      const glassGeometry = new THREE.BoxGeometry(splitWidth, splitHeight, 1);
      const glassMesh = new THREE.Mesh(glassGeometry, materials.glassMaterial);
  
      glassMesh.position.set(
        startPosX + splitWidth / 2,
        startPosY - splitHeight / 2,
        -1 / 2
      );
  
      const glassBounding = new THREE.Box3().setFromObject(glassMesh);
      const center = new THREE.Vector3()
      glassBounding.getCenter(center)
      const glassPos = {
        position: { x: center?.x, y: center?.y, z: center?.z },
        name: glassMesh.name
      }
  
    }
    if (section2.splits) {
      drawTransoms2(
        section2.splits[0],
        startPosX2,
        startPosY2,
        splitWidth2,
        splitHeight2, transomFrameHeight, transomFrameDepth, materials, svgNamespace, svg
      );
    } else {
      const glassGeometry2 = new THREE.BoxGeometry(splitWidth2, splitHeight2, 1);
      const glassMesh2 = new THREE.Mesh(glassGeometry2, materials.glassMaterial);
      glassMesh2.position.set(
        startPosX2 + splitWidth2 / 2,
        startPosY2 - splitHeight2 / 2,
        -1 / 2
      );
      glassMesh2.name = `${section2?.id}`;
  
      const glassBounding2 = new THREE.Box3().setFromObject(glassMesh2);
      const center = new THREE.Vector3()
      glassBounding2.getCenter(center)
      const glassPos2 = {
        position: { x: center?.x, y: center?.y, z: center?.z },
        name: glassMesh2.name
      }
  
    }
  }

  export function countHorizontalTransoms(object, orientation) {
    const sashCounts = [];

    function traverseSections(sections, count = 1) {
      if (sections.type === "transom" && sections.orientation === orientation) {
          count++;
      }
      if (sections.sections) {
          count = traverseSections(sections.sections[0], count);
          if(sections.sections.length == 2)count = traverseSections(sections.sections[1], count);
      }
      if (sections.splits) {
          count = traverseSections(sections.splits[0], count);
      }
        return count;
    }

    if (object.sections) {
        object.sections.forEach(sash => {
            const count = sash.splits ? traverseSections(sash.splits[0]) : 1;
            sashCounts.push(count);
            // if(orientation == 'vertical')sashCounts.push(count);
        });
    }
    if(orientation = 'horizontal' && sashCounts.length == 0)sashCounts.push(1)
    return sashCounts;
}
// export function countHorizontalTransoms_(object) {
//     const sashCounts = [];

//     function traverseSections(sections, isHorizontal = true) {
//         let count = 0;

//         if (Array.isArray(sections)) {
//             sections.forEach(section => {
//                 count += traverseSections(section, isHorizontal);
//             });
//             return count;
//         }

//         if (sections.type === "transom") {
//             if (sections.orientation === "horizontal" && isHorizontal) {
//                 count++;
//             } else if (sections.orientation === "vertical" && !isHorizontal) {
//                 count = 1; // Reset to 1 as only one vertical count is needed per horizontal
//             }
//         }

//         if (sections.sections) {
//             const verticalCounts = sections.sections.map(subsection => traverseSections(subsection, false));
//             count += Math.max(...verticalCounts); // Add the maximum vertical count from child sections
//         }

//         if (sections.splits) {
//             sections.splits.forEach(split => {
//                 count += traverseSections(split, sections.orientation === "horizontal");
//             });
//         }

//         return count;
//     }

//     if (object.sections) {
//         object.sections.forEach(sash => {
//             // const count = sash.splits ? traverseSections(sash.splits, true) : 0;
//             const count = sash.splits ? traverseSections(sash.splits, sash.splits[0].orientation == "horizontal" ? true : false) : 0;
//             sashCounts.push(count+(count >0 ? 1 : 0));
//         });
//     }

//     return sashCounts;
// }
export function updateTransomCount(transomData, widthSplits, heightSplits, orientation){
    // console.clear();
    console.log("transomData :::: ", transomData);
    let splits = orientation == "vertical" ? widthSplits : heightSplits;
    let verticalArray, horizontalArray;
    // console.log("splits :::: ", splits);
    // console.log("orientation :::: ", orientation);
    transomData[orientation+"Count"] = [];
    transomData.transomArray = [[], []];
    // getSplitCount(transomData.sections[0].splits[0], orientation)
    let firstCount = transomData.sections[0].splits ? getSplitCount(transomData.sections[0].splits[0], orientation) : 1;
    transomData[orientation+"Count"][0] = firstCount;
    transomData.transomArray[0] = splits.slice(0, firstCount); // First `firstCount` numbers
    transomData.transomArray[1] = splits.slice(firstCount);
    // getSplitCount(transomData.sections[1].splits[0], orientation)
    // transomData[orientation+"Count"] = getSplitCount(transomData.sections[0].splits[0], orientation);
    transomData[orientation+"Count"][1] = transomData?.sections[1]?.splits ? getSplitCount(transomData.sections[1].splits[0], orientation) : 1;
    // if(transomData.sections[1].splits && transomData.sections[1].splits[0].sections)
    // addSplitsCount(transomData);
    // return 
    // if(transomData.sections[0].splits){
    // if()
    if(orientation == "vertical"){
        verticalArray = transomData.transomArray;
        horizontalArray = [heightSplits, heightSplits];
    }else{
        horizontalArray = transomData.transomArray;
        verticalArray = [widthSplits, widthSplits];
    }
    if (transomData?.sections[0]?.splits){
        if(orientation == transomData.sections[0].splits[0].orientation){
            updateTransomCount(transomData.sections[0].splits[0], verticalArray[0], horizontalArray[0], transomData.sections[0].splits[0].orientation)
        }else{
            updateTransomCount(transomData.sections[0].splits[0], verticalArray[0], horizontalArray[0], transomData.sections[0].splits[0].orientation)
        }
    } 
    if (transomData?.sections[1]?.splits){
        if(orientation == transomData.sections[1].splits[0].orientation){
            updateTransomCount(transomData.sections[1].splits[0], verticalArray[1], horizontalArray[1], transomData.sections[1].splits[0].orientation)
        }else{
            updateTransomCount(transomData.sections[1].splits[0], verticalArray[1], horizontalArray[1], transomData.sections[1].splits[0].orientation)
        }
    }
    // }

}
function addSplitsCount(transomData){
    if(transomData.sections[0].splits){

    }
}
function getSplitCount(data, orientation){
    // console.log("data :::: ", data);
    // console.log("orientation :::: ", orientation);
    // console.log(":::: ------------");
    let counts = 0;
    function traverseSections(sections, count = 1) {
        if (sections.type === "transom" && sections.orientation === orientation) {
            count++;
        }
        if (sections.sections) {
            count = traverseSections(sections.sections[0], count);
            if(sections.sections.length == 2)count = traverseSections(sections.sections[1], count);
            if(sections.sections[0].splits){
                console.log("here came");
                // && sections.sections[0].splits[0].sections
            }
            if(sections?.sections[1]?.splits){
                console.log("here came 2");
                // && sections.sections[0].splits[0].sections
            }
        }
        console.log("sections :::: ", sections);
        // if()
        
        if (sections.sections) {
            sections.sections.forEach(sash => {
                if(sash.splits){
                    count = traverseSections(sash.splits[0]);
                }
                // else{
                //     count++;
                // }
                
            })
        }
        return count;
    }
    if (data.sections && orientation == data.orientation) {
        data.sections.forEach(sash => {
            const count = sash.splits ? traverseSections(sash.splits[0]) : 1;
            counts += count;
            // sashCounts.push(count);
            // if(orientation == 'vertical')sashCounts.push(count);
        });
    }else{
        counts = 1;
    }
    return counts;
}

export const getModelFileUrl = (model) => {
    try {
      if (!model) {
        return "";
      }
  
      if (!model) {
        console.error("Model file path is missing in defaultHandle.");
        return "";
      }
  
      return `${servicePath}/ThreeJSModel/Glb/${model}`;
    } catch (error) {
      console.error("Error generating model file URL:", error.message);
      return "";
    }
  };