import * as THREE from 'three';

export function createGlassMaterial(color) {
    const material = new THREE.MeshPhysicalMaterial({
        color: color,
        roughness: 0.05,
        metalness: 0.5,
        reflectivity: 0.9,
        clearcoat: 1,
        clearcoatRoughness: 0.05,
        opacity: 0.5,
        transparent: true,
        envMapIntensity: 1,
        blending: THREE.NormalBlending,
        depthWrite: true,
    });

    return material;
}

function preloadTexture(texturePath) {
    return new Promise((resolve, reject) => {
        if (!texturePath) {
            resolve(null);
            return;
        }

        const fullUrl = `${process.env.REACT_APP_API_ENDPOINT}/Content/VisualElement/${texturePath}`;

        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(
            fullUrl,
            (texture) => {
                texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
                texture.repeat.set(20, 40);
                resolve(texture);
            },
            undefined,
            (error) => {
                console.warn("Failed to load texture:", fullUrl, error);
                resolve(null);
            }
        );
    });
}

export async function assignTexture(texturePath, glassMaterial) {
    const texture = await preloadTexture(texturePath);
    if (texture) {
        glassMaterial.map = texture;
        glassMaterial.needsUpdate = true;
    } else {
        console.warn("No texture loaded. Proceeding without texture.");
    }
}

export function createPanelMatrial(color) {
    return new THREE.MeshPhongMaterial({
        color: color,
        specular: 0xffffff,
        shininess: 40,
        flatShading: false,
        emissive: 0x000000,
        specular: 0x888888,
        bumpScale: 2
    })
}

export function createProfileMaterial(color) {

    // const material = new THREE.MeshStandardMaterial({
    //     color: color,
    //     metalness: 0.9,
    //     roughness: 0.2,
    //     emissive: 0x000000,
    //     bumpScale: 0.1,
    // });

    const material = new THREE.MeshPhongMaterial({
        color: color,
        specular: 0xffffff,
        shininess: 10,
        flatShading: false,
        emissive: 0x000000,
        specular: 0x888888,
        bumpScale: 1,
        // wireframe : true
    });
    return material
}