import React, { useEffect, useState } from "react";
import { Table, Form } from "react-bootstrap";
import { FormGroup, Label } from "reactstrap";
import { formatValue } from "../utility/helper";
import {
  addNewFrameAncillaryService,
  deleteFrameAncillaries,
  getQuotationPriceList,
  getProjectAncillariesPricelist,
} from "../services/pricingServices";
import { useDesigner } from "../context/designerContext";
import { useMessage } from "../context/messageContext";
import { useNavigate } from "react-router-dom";
import PromotionComponent from "./PromotionComponent";
import { useRightContext } from "../context/rightMenuContext";
import ItemQuantity from "./Pricing/ItemQuantity";
import AncillaryComponent from "./AncillaryComponent";
import { handleError } from "../utility/commonHelper";

export const QuotationPricingJob = ({
  quotationId,
  leftPanelOpen,
  accessToken,
  printId,
  isDeleted,
  quoteDetails,
  isCopied,
  setIsCopied,
  setLoading,
  quoteUserHostUrl,
}) => {
  const [frameData, setFrameData] = useState([]);
  const [installData, setInstallData] = useState([]);
  const [ancillaryData, setAncillaryData] = useState([]);
  const [installation, setInstallation] = useState(false);
  const [addedAncillaries, setAddedAncillaries] = useState([]);
  const [isUnlinked, setIsUnLinked] = useState(false);
  const [ancillaryModal, setAncillaryModal] = useState(false);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [wastagePrice, setWastagePrice] = useState(0);
  const [totalPriceObj, setTotalPriceObj] = useState(null);
  const [discountSwitch, setDiscountSwitch] = useState(false);
  const [hidePrintSwitch, setHidePrintSwitch] = useState(false);
  const [addedPromotions, setAddedPromotions] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [discountSubTotal, setDiscountSubtotal] = useState(0);
  const [priceReCalc, setPriceReCalc] = useState(true);
  const [getPricingAPI, setGetPricingAPI] = useState(true);
  const [currency, setCurrency] = useState("£");

  const [modalData, setModalData] = useState({
    id: 0,
    lumpSum: 0,
    quantity: 1,
    percentage: 0,
    description: "",
    preset: -1,
  });

  const { setMessage, setMessageType } = useMessage();
  const navigate = useNavigate();

  const { userDataContext, projectDefault, generalTax } = useRightContext();
  const { setQuotationFramePrice } = useDesigner();

  useEffect(() => {
    if (quotationId && printId !== null && quoteUserHostUrl) {
      setLoading(false);
      getQuotationPriceListService(printId);
    }
  }, [quotationId, printId]);

  useEffect(() => {
    if (quotationId && printId !== null) {
      setGetPricingAPI(true);
    }
  }, [isDeleted, installation, isCopied]);

  useEffect(() => {
    if (projectDefault && projectDefault?.currency) {
      setCurrency(projectDefault?.currency);
    } else {
      setCurrency("£");
    }
  }, [projectDefault]);

  useEffect(() => {
    if (getPricingAPI && printId && quotationId && quoteUserHostUrl) {
      setLoading(true);
      getQuotationPriceListService(printId);
    }
  }, [getPricingAPI]);

  useEffect(() => {
    if (quoteDetails && quoteDetails.project_type) {
      if (quoteDetails.project_type === "Supply and installation") {
        setInstallation(true);
      } else {
        setInstallation(false);
      }
    }
  }, [quoteDetails]);

  useEffect(() => {
    let subTotalInit = 0;

    // frame price with quantity
    subTotalInit = parseFloat(totalPriceObj?.framesPrice?.price);

    // frame installation price with quantity
    if (installation && installData && installData?.length > 0) {
      installData.forEach((item) => {
        subTotalInit += parseFloat(item?.price);
      });
    }

    const totalFramePrice = parseFloat(totalPriceObj?.framesPrice?.price);

    // addedAncillaries price
    if (addedAncillaries && addedAncillaries?.length > 0) {
      addedAncillaries.forEach((item) => {
        if (item.percentage > 0) {
          subTotalInit +=
            (parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) +
              totalFramePrice * (item.percentage / 100)) *
            (item?.quantity > 0 ? item.quantity : 0);
        } else {
          subTotalInit +=
            parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) *
            (item?.quantity > 0 ? item.quantity : 0);
        }
      });
    }

    setSubTotalPrice(subTotalInit);

    setQuotationFramePrice(
      subTotalInit + subTotalInit * (generalTax?.percentage / 100)
    );

    if (totalDiscount > 0 && addedPromotions?.length > 0) {
      let discountPrice = formatValue(
        subTotalPrice -
        parseFloat(
          (totalDiscount / 100) *
          getFameAndInstallPrice(frameData, installData) +
          parseFloat(getTotalAncillaryPriceDisc(addedAncillaries))
        )
      );

      setDiscountSubtotal(discountPrice);

      setQuotationFramePrice(
        formatValue(
          parseFloat(discountPrice) +
          parseFloat(discountPrice * (generalTax?.percentage / 100))
        )
      );
    }

    setPriceReCalc(false);
  }, [
    installation,
    installData,
    frameData,
    addedPromotions,
    totalDiscount,
    priceReCalc,
  ]);

  const handlePresetModalValue = (e) => {
    const value = e.target.value;

    if (value === "-1") {
      setModalData((prevData) => ({
        ...prevData,
        preset: value,
        description: "",
        lumpSum: 0,
        quantity: 1,
        percentage: 0,
      }));
    } else {
      let item = ancillaryData.find((item) => item?.id === value);

      setModalData((prevData) => ({
        ...prevData,
        preset: value,
        description: item?.description,
        lumpSum: item?.totalPlusVat,
        quantity: 1,
        percentage: item?.percentage || 0,
      }));
    }

    setIsUnLinked(false);
  };

  const handleAncillaryModal = () => {
    setIsUnLinked(false);

    setModalData((prevData) => ({
      ...prevData,
      id: 0,
      preset: -1,
      description: "",
      lumpSum: 0,
      quantity: 1,
      percentage: 0,
    }));

    setAncillaryModal(true);
  };

  const getAncillariesData = async () => {
    const res = await getProjectAncillariesPricelist(accessToken);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setAncillaryData(res?.data?.entity);

        setGetPricingAPI(true);
      } else if (res?.data?.statusCode === 404) {
        navigate("/error/404");
      } else {
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");

        setGetPricingAPI(true);
      }
    } else {
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  const handleAncillaryEdit = (item) => {
    setIsUnLinked(true);

    setModalData((prevModalData) => ({
      ...prevModalData,
      id: item?.id || 0,
      preset: item?.ancillariesId,
      description: item?.ancillariesName,
      percentage: item?.percentage || 0,
      lumpSum: item?.ancillariesPrice,
      quantity: item?.quantity,
    }));

    setDiscountSwitch(item?.discountable ? item?.discountable : false);

    setHidePrintSwitch(
      item?.hideWithInFrameWhenPrinting
        ? item?.hideWithInFrameWhenPrinting
        : false
    );

    setIsEdit(true);
    setAncillaryModal(true);
  };

  const handleAncillary = (item) => {
    let data = {
      id: 0,
      ancillariesId: item?.id,
      ancillariesName: item.description,
      ancillariesPrice: item.totalPlusVat,
      quotationId: quotationId,
      percentage: item?.percentage || 0,
      quantity: 1,
      discountable: false,
      hideWithInFrameWhenPrinting: hidePrintSwitch,
    };

    addNewAncillary(data);
    setIsUnLinked(true);
  };

  const deleteAncillary = (idToRemove, indexToRemove) => {
    setAddedAncillaries((prevData) => {
      const newData = prevData.filter(
        (item, index) => index !== indexToRemove || item.id !== idToRemove
      );
      return newData;
    });
    deleteFrameAncillary(idToRemove);
  };

  const deleteFrameAncillary = async (id) => {
    const res = await deleteFrameAncillaries(accessToken, id);
    if (res?.data?.statusCode === 200) {
      getAncillariesData();
      console.log(res?.data?.message);
    } else {
      console.log(res?.data?.message);
    }
  };

  const addNewFrameAncillary = (modalData) => {
    let data = {
      id: modalData?.id || 0,
      ancillariesId: modalData?.preset,
      ancillariesName: modalData?.description,
      ancillariesPrice: modalData?.lumpSum,
      quotationId: quotationId,
      percentage: modalData?.percentage || 0,
      quantity: modalData?.quantity,
      discountable: discountSwitch,
      hideWithInFrameWhenPrinting: hidePrintSwitch,
    };

    addNewAncillary(data);

    setIsEdit(false);
    setAncillaryModal(false);
  };

  const getPricing = (arr) => {
    let total = 0;
    arr.forEach((item) => {
      total += parseFloat(item?.price);
    });
    return total;
  };

  const getAncillaryPrice = (item) => {
    let price = 0;

    const totalFramePrice = parseFloat(totalPriceObj?.framesPrice?.price);

    if (item.percentage > 0) {
      price +=
        (parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) +
          totalFramePrice * (item.percentage / 100)) *
        (item?.quantity > 0 ? item.quantity : 0);
    } else {
      price +=
        parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) *
        (item?.quantity > 0 ? item.quantity : 0);
    }

    return price;
  };

  const getTotalAncillaryPrice = (arr) => {
    let total = 0;

    const totalFramePrice = parseFloat(totalPriceObj?.framesPrice?.price);

    arr.forEach((item) => {
      if (item.percentage > 0) {
        total +=
          (parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) +
            totalFramePrice * (item.percentage / 100)) *
          (item?.quantity > 0 ? item.quantity : 0);
      } else {
        total +=
          parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) *
          (item?.quantity > 0 ? item.quantity : 0);
      }
    });

    return total;
  };

  const getTotalAncillaryPriceDisc = (arr) => {
    let total = 0;

    arr.forEach((item) => {
      if (item.discountable) {
        total +=
          parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) *
          (item?.quantity > 0 ? item.quantity : 0) *
          (parseFloat(totalDiscount) / 100);
      }
    });
    return total;
  };

  const getQuotationPriceListService = async (fetchId) => {
    const res = await getQuotationPriceList(
      accessToken,
      fetchId,
      quotationId,
      quoteUserHostUrl
    );

    if (res?.data?.statusCode === 200) {
      const entity = res?.data?.entity || {};

      setFrameData(entity?.framePrice?.length > 0 ? entity?.framePrice : []);
      setInstallData(
        installation && entity?.installationPrice?.length > 0
          ? entity?.installationPrice
          : []
      );
      setAddedAncillaries(
        entity?.ancillariesPrice?.length > 0 ? entity?.ancillariesPrice : []
      );
      setAddedPromotions(
        entity?.promotionsPrice?.length > 0 ? entity?.promotionsPrice : []
      );
      setWastagePrice(entity?.wastageModel?.price || 0);
      setTotalPriceObj(entity?.total || null);
      setIsCopied(false);

      setPriceReCalc(true);
      setGetPricingAPI(false);
      setLoading(false);
    } else {
      setPriceReCalc(true);
      setFrameData([]);
      setInstallData([]);
      setAddedAncillaries([]);
      setAddedPromotions([]);
      setWastagePrice(0);
      setTotalPriceObj(null);
      setIsCopied(false);
      setLoading(false);
    }
  };

  const addNewAncillary = async (data) => {
    const res = await addNewFrameAncillaryService(accessToken, data);
    if (res?.data?.statusCode === 200) {
      setGetPricingAPI(true);
    } else {
      setGetPricingAPI(true);
    }
  };

  const getFameAndInstallPrice = (frameData, installData) => {
    let total = 0;

    // total for frame
    total = parseFloat(totalPriceObj?.framesPrice?.price);

    // total for frame installation
    if (installation) {
      total += getPricing(installData);
    }

    return formatValue(parseFloat(total));
  };

  return (
    <div className="fixed_m_scroll full_h ms-2">
      <h5 className="mt-3 mb-2">Frame</h5>

      <Table className="pricing_table no_td_bg">
        <tbody>
          {frameData &&
            frameData.map((item, index) => (
              <tr key={index} className="border-bottom">
                <td className="text-start text-wrap">
                  <ItemQuantity name={item?.name} quantity={item.quantity} />
                </td>
                <td className="text-end">
                  {currency}
                  {parseFloat(item?.price)}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {wastagePrice > 0 && (
        <>
          <h5 className="mt-3 mb-2">Wastage</h5>

          <Table className="pricing_table no_td_bg">
            <tbody>
              <tr className="border-bottom">
                <td className="text-start text-wrap">Total amount</td>
                <td className="text-end">
                  {currency}
                  {parseFloat(wastagePrice)}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}

      <FormGroup className="form-group">
        <Label for="installation">Installation</Label>
        <Form.Check
          type="switch"
          id="installation"
          inline
          className="form-switch-lg ms-1 disabled"
          checked={installation}
          label={installation ? "Included" : "Excluded"}
          onChange={(e) => e.preventDefault()}
        />
      </FormGroup>

      {installation && installData && installData?.length > 0 && (
        <>
          <h5 className="mt-5 mb-2">Installation</h5>
          <Table className="pricing_table no_td_bg">
            <tbody>
              {installData &&
                installData.map((item, index) => (
                  <tr key={index} className="border-bottom">
                    <td className="text-start text-wrap">
                      <ItemQuantity
                        name={item?.name}
                        quantity={item.quantity}
                      />
                    </td>
                    <td className="text-end">
                      {currency}
                      {parseFloat(item?.price)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}

      {userDataContext && userDataContext.role !== "" && (
        <>
          <AncillaryComponent
            modalData={modalData}
            setModalData={setModalData}
            leftPanelOpen={leftPanelOpen}
            accessToken={accessToken}
            addedAncillaries={addedAncillaries}
            handleAncillaryModal={handleAncillaryModal}
            handleAncillary={handleAncillary}
            deleteAncillary={deleteAncillary}
            setIsUnLinked={setIsUnLinked}
            ancillaryModal={ancillaryModal}
            setAncillaryModal={setAncillaryModal}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            handlePresetModalValue={handlePresetModalValue}
            ancillaryData={ancillaryData}
            setAncillaryData={setAncillaryData}
            addNewFrameAncillary={addNewFrameAncillary}
            handleAncillaryEdit={handleAncillaryEdit}
            discountSwitch={discountSwitch}
            setDiscountSwitch={setDiscountSwitch}
            hidePrintSwitch={hidePrintSwitch}
            isUnlinked={isUnlinked}
            setHidePrintSwitch={setHidePrintSwitch}
            getAncillariesData={getAncillariesData}
            getAncillaryPrice={getAncillaryPrice}
            projectDefault={projectDefault}
            removeDiscountToggle={false}
            currency={currency}
          />

          <PromotionComponent
            accessToken={accessToken}
            addedPromotions={addedPromotions}
            setAddedPromotions={setAddedPromotions}
            setTotalDiscount={setTotalDiscount}
            totalDiscount={totalDiscount}
            quotationId={quotationId}
            setGetPricingAPI={setGetPricingAPI}
          />
        </>
      )}

      <h5 className="mt-3">Total</h5>
      <Table className="td_p_2 no_td_bg">
        <tbody>
          <tr className="border-bottom">
            <td className="text-wrap">Frame</td>
            <td className="text-wrap text-end">
              {currency}
              {formatValue(parseFloat(totalPriceObj?.framesPrice?.price))}
            </td>
          </tr>
          <>
            {installation && installData && installData?.length > 0 && (
              <tr className="border-bottom">
                <td className="text-wrap">Installation</td>
                <td className="text-wrap text-end">
                  {currency}
                  {formatValue(
                    parseFloat(totalPriceObj?.installationPrice?.price)
                  )}
                </td>
              </tr>
            )}
          </>

          {addedAncillaries && addedAncillaries?.length > 0 ? (
            <tr className="border-bottom">
              <td className="text-wrap">Ancillaries</td>
              <td className="text-wrap text-end">
                {currency}
                {formatValue(getTotalAncillaryPrice(addedAncillaries))}
              </td>
            </tr>
          ) : null}

          <tr className="border-bottom">
            <td className="text-wrap fw-bold">Subtotal</td>
            <td className="text-wrap text-end fw-bold">
              {currency}
              {formatValue(parseFloat(subTotalPrice))}
            </td>
          </tr>

          {totalDiscount > 0 && addedPromotions?.length > 0 && (
            <tr className="border-bottom">
              <td className="text-wrap ">Discount</td>
              <td className="text-wrap text-end ">
                -{currency}
                {/* calculate discount */}
                {formatValue(
                  parseFloat(
                    (totalDiscount / 100) *
                    getFameAndInstallPrice(frameData, installData) +
                    parseFloat(getTotalAncillaryPriceDisc(addedAncillaries))
                  )
                )}
              </td>
            </tr>
          )}

          {totalDiscount > 0 && addedPromotions?.length > 0 && (
            <tr className="border-bottom">
              <td className="text-wrap fw-bold">Subtotal</td>
              <td className="text-wrap text-end fw-bold">
                {currency}

                {discountSubTotal}

                {/* {`-->`}
                {formatValue(
                  subTotalPrice -
                  (parseFloat((totalDiscount / 100) * getFameAndInstallPrice(frameData, installData) +
                    parseFloat(getTotalAncillaryPriceDisc(addedAncillaries))))
                )} */}
              </td>
            </tr>
          )}
          <tr>
            <td className="text-wrap">VAT @20 %</td>
            <td className="text-wrap text-end">
              {/* {currency}{formatValue(parseFloat(totalPriceObj?.vat?.price))} */}
              {currency}

              {totalDiscount > 0 && addedPromotions?.length > 0
                ? formatValue(discountSubTotal * (generalTax?.percentage / 100))
                : formatValue(
                  parseFloat(subTotalPrice) * (generalTax?.percentage / 100)
                )}
            </td>
          </tr>
          <tr className="border-bottom">
            <td className="text-wrap fw-bold">Total</td>
            <td className="text-wrap text-end fw-bold">
              {currency}

              {totalDiscount > 0 && addedPromotions?.length > 0
                ? formatValue(
                  parseFloat(discountSubTotal) +
                  parseFloat(
                    discountSubTotal * (generalTax?.percentage / 100)
                  )
                )
                : formatValue(
                  parseFloat(subTotalPrice) +
                  parseFloat(subTotalPrice * (generalTax?.percentage / 100))
                )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
