import { createLinePath } from "./lineShapes";
import { createShapeFromWidthHeight, createSmoothTriangleShape } from "./utils/threeUtils";
import * as THREE from 'three';


const extrudeSettings = (path, steps) => ({
    // steps: 100,
    steps: steps,
    extrudePath: path, // Path to extrude along
    bevelEnabled: false,  // No bevel
    // bevelThickness: 1,
    // bevelSize: 1,
    // bevelOffset: 0,
    // bevelSegments: 100,
    // curveSegments: 120,
    morphTargetsRelative: true
});

export function addWindowTempData(wData) {
    // wData.push(wData[0].clone());
    // wData.push([...wData[0]]);
    if (wData.length == 1) {
        wData.push(JSON.parse(JSON.stringify(wData[0])));

        wData[1].windowType = "sub";
        wData[1].relWindow = {
            "left": "group1",
            "right": "",
            "top": "",
            "bottom": "",
        }
        wData[1].payPost = {
            "left": true,
            "right": true,
            "top": "",
            "bottom": true,
        }
        wData[1].cobblerData = {
            "left": [0.05, 0.01],
            "right": [0.05, 0.01],
            "top": [],
            "bottom": [],
        }
        wData[1].angle = 0;

        wData.push(JSON.parse(JSON.stringify(wData[1])));
        wData[2].windowType = "sub";
        wData[2].relWindow = {
            "left": "",
            "right": "group1",
            "top": "",
            "bottom": "",
        }
        wData[1].angle = 120;

        wData.push(JSON.parse(JSON.stringify(wData[1])));
        wData[3].windowType = "sub";
        wData[3].relWindow = {
            "left": "",
            "right": "",
            "top": "",
            "bottom": "group1",
        }
        wData[3].angle = 0;
        wData[3].dimensions.height = 0.7;

        wData.push(JSON.parse(JSON.stringify(wData[1])));
        wData[4].windowType = "sub";
        wData[4].relWindow = {
            "left": "",
            "right": "",
            "top": "",
            "bottom": "group2",
        }
        wData[4].angle = 0;
        wData[4].dimensions.height = 0.7;

        wData.push(JSON.parse(JSON.stringify(wData[1])));
        wData[5].windowType = "sub";
        wData[5].relWindow = {
            "left": "",
            "right": "",
            "top": "",
            "bottom": "group3",
        }
        wData[5].angle = 0;
        wData[5].dimensions.height = 0.7;
    }
}


export function connectPaypostCobbler(scene, group, relWindowData, angle, side, hadPaypost, material, cobblerData) {
    const relWindow = scene.getObjectByName(relWindowData[1]);
    const relWindowBounding = new THREE.Box3().setFromObject(relWindow);
    const curWindowBounding = new THREE.Box3().setFromObject(group);
    if ((side == "left" || side == "right")) {
        if (angle == 90) {
            if (hadPaypost) {
                const height = curWindowBounding.max.z - curWindowBounding.min.z;
                const width = relWindowBounding.max.z - relWindowBounding.min.z;
                const shape = createShapeFromWidthHeight(height, width)
                let pathX = relWindowBounding.min.x;
                // if(side == "right"){
                //     pathX = relWindowBounding.min.x;
                // }
                const start = new THREE.Vector3(pathX, relWindowBounding.max.y, 0);
                const end = new THREE.Vector3(pathX, relWindowBounding.min.y, 0);
                const path = createLinePath(start, end);
                const paypostGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings(path, 2));
                const paypostMesh = new THREE.Mesh(paypostGeometry, material.clone());
                paypostMesh.name = group.name + "_paypost";
                group.add(paypostMesh);
            }
        } else if (angle == 180 || angle == 0) {
            cobblerData = cobblerData && Array.isArray(cobblerData) && cobblerData.length > 0 ? cobblerData : [0.02, 0.01];
            const shape = createShapeFromWidthHeight(cobblerData[1], cobblerData[0])
            let pathX = relWindowBounding.max.x + cobblerData[0];
            if (side == "left") pathX = relWindowBounding.min.x;
            const start = new THREE.Vector3(pathX, relWindowBounding.max.y, 0);
            const end = new THREE.Vector3(pathX, relWindowBounding.min.y, 0);
            const path = createLinePath(start, end);
            const paypostGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings(path, 2));
            const paypostMesh = new THREE.Mesh(paypostGeometry, material.clone());
            paypostMesh.name = group.name + "_paypost";
            group.add(paypostMesh);
            const paypostMeshClone = paypostMesh.clone();
            const paypostBounding = new THREE.Box3().setFromObject(paypostMesh);
            paypostMesh.position.z += relWindowBounding.max.z - paypostBounding.max.z;
            const frontOffset = relWindowBounding.max.z - paypostBounding.max.z;
            paypostMeshClone.name = group.name + "_paypost";
            group.add(paypostMeshClone);
            paypostMeshClone.position.z -= frontOffset;
        } else {
            const height = curWindowBounding.max.z - curWindowBounding.min.z;
            const width = relWindowBounding.max.z - relWindowBounding.min.z;
            const shape = createSmoothTriangleShape(height, width, -angle)
            // const shape = createSmoothTriangleShape(height, width, angle+45)
            let pathX = relWindowBounding.min.x;
            // shape.translate(pathX, relWindowBounding.max.y, 0);

            // if(side == "right"){
            //     pathX = relWindowBounding.min.x;
            // }
            const start = new THREE.Vector3(pathX, relWindowBounding.max.y, 0);
            const end = new THREE.Vector3(pathX, relWindowBounding.min.y, 0);
            const path = createLinePath(start, end);
            const paypostGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings(path, 2));
            const paypostMesh = new THREE.Mesh(paypostGeometry, material.clone());

            setTimeout((group, paypostMesh, width) => {
                paypostMesh.position.z += width / 2;
                paypostMesh.name = group.name + "_paypost";
                group.add(paypostMesh);
            }, 0, group, paypostMesh, width);
        }
    } else {
        if (angle == 90) {
            if (hadPaypost) {
                const height = curWindowBounding.max.z - curWindowBounding.min.z;
                const width = relWindowBounding.max.z - relWindowBounding.min.z;
                const shape = createShapeFromWidthHeight(height, width)
                let pathX = relWindowBounding.min.x;
                // if(side == "right"){
                //     pathX = relWindowBounding.min.x;
                // }
                const start = new THREE.Vector3(pathX, relWindowBounding.max.y, 0);
                const end = new THREE.Vector3(pathX, relWindowBounding.min.y, 0);
                const path = createLinePath(start, end);
                const paypostGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings(path, 2));
                const paypostMesh = new THREE.Mesh(paypostGeometry, material.clone());
                paypostMesh.name = group.name + "_paypost";
                group.add(paypostMesh);
            }
        } else if (angle == 180 || angle == 0) {
            cobblerData = cobblerData && Array.isArray(cobblerData) && cobblerData.length > 0 ? cobblerData : [0.02, 0.01];
            const shape = createShapeFromWidthHeight(cobblerData[1], cobblerData[0])
            let pathY = curWindowBounding.min.y - cobblerData[0];
            // let pathY = 0;
            const start = new THREE.Vector3(curWindowBounding.max.x, pathY, 0);
            const end = new THREE.Vector3(curWindowBounding.min.x, pathY, 0);
            const path = createLinePath(start, end);
            const paypostGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings(path, 2));
            const paypostMesh = new THREE.Mesh(paypostGeometry, material.clone());
            paypostMesh.name = group.name + "_paypost";
            group.add(paypostMesh);
            const paypostMeshClone = paypostMesh.clone();
            const paypostBounding = new THREE.Box3().setFromObject(paypostMesh);
            paypostMesh.position.z += relWindowBounding.max.z - paypostBounding.max.z;
            const frontOffset = relWindowBounding.max.z - paypostBounding.max.z;
            paypostMeshClone.name = group.name + "_paypost";
            group.add(paypostMeshClone);
            paypostMeshClone.position.z -= frontOffset;
            setTimeout((relWindow, relWindowData, group, relWindowBounding) => {
                relWindow.traverse((child) => {
                    // if (child.isGroup) {
                    if (child.name == (relWindowData[1] + "_paypost")) {
                        const paypostClone = child.clone();
                        const paypostBounding = new THREE.Box3().setFromObject(group);
                        group.add(paypostClone);
                        paypostClone.position.y -= cobblerData[0]
                        const ppHeight = paypostBounding.max.y - paypostBounding.min.y;
                        const rwHeight = relWindowBounding.max.y - relWindowBounding.min.y;
                        paypostClone.scale.y = ppHeight / rwHeight;
                    }
                    // }
                })
            }, 600, relWindow, relWindowData, group, relWindowBounding);

        } else {
            const height = curWindowBounding.max.z - curWindowBounding.min.z;
            const width = relWindowBounding.max.z - relWindowBounding.min.z;
            const shape = createSmoothTriangleShape(height, width, -angle)
            // const shape = createSmoothTriangleShape(height, width, angle+45)
            let pathX = relWindowBounding.min.x;
            // shape.translate(pathX, relWindowBounding.max.y, 0);

            // if(side == "right"){
            //     pathX = relWindowBounding.min.x;
            // }
            const start = new THREE.Vector3(pathX, relWindowBounding.max.y, 0);
            const end = new THREE.Vector3(pathX, relWindowBounding.min.y, 0);
            const path = createLinePath(start, end);
            const paypostGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings(path, 2));
            const paypostMesh = new THREE.Mesh(paypostGeometry, material.clone());

            setTimeout((group, paypostMesh, width) => {
                paypostMesh.position.z += width / 2;
                paypostMesh.name = group.name + "_paypost";
                group.add(paypostMesh);
            }, 0, group, paypostMesh, width);
        }
    }
}