import React from 'react'
import PanelDesigner from '../../../../../components/DesignerComp/PanelDesigner';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllFrameCollection, getFrameStyles, getGlazingProductsData, getModelData, getModelHardware, getProductDataService, getProfileDataService, getQuotationModelProperty, getSelectedFrameProduct, getTrasomsFrameService, verifyModelService } from '../../../../../services/3dModelServices';
import { useMessage } from '../../../../../context/messageContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getFrameStyleColletionDetails } from '../../../../../services/frameStylesService';
import { useDesigner } from '../../../../../context/designerContext';
import { modelData } from '../../../../../utility/data';
import AddNewFrame from '../../../../../components/AddNewFrame';
import PanelDesignerBlock from '../../../../../components/DesignerComp/PanelDesignerBlock';
import { handleError } from '../../../../../utility/commonHelper';
import { negate } from 'lodash';

const Left = ({ loading, setLoading, setModelVisible, modelVisible, receivedDataLocal, captureImages, setCaptureImages, is_new_fame, isNewFrame, setIsNewFrame }) => {

    const { modelId, styleId } = useParams()

    const navigate = useNavigate()

    const accessToken = localStorage.getItem('access_token')

    const { setMessage, setMessageType } = useMessage()

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [allFrameCollection, setAllFrameCollection] = useState([])
    const [frameStyles, setFrameStyles] = useState([])
    const [collectionDetails, setCollectionDetails] = useState('')
    const [initialLoad, setInitialLoad] = useState(false)
    const [gotData, setGotData] = useState(false)
    const [receivedData, setReceivedData] = useState('')
    const [quotationId, setQuotationId] = useState('')
    const [receivedProductId, setReceivedProductId] = useState(0)
    const [productId, setProductId] = useState()
    const [showSillOptions, setShowSillOptions] = useState(false)
    const [verifyModels, setVerifyModels] = useState(null)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [selectedAnimation, setSelectedAnimation] = useState()
    const [frameProductDetails, setFrameProductDetails] = useState('')
    const [allHardware, setAllHardware] = useState([])
    const [isModelChange, setIsModelChange] = useState(false);

    const { setBayPostList, setInitialProfile, customModelData, setCouplerData, setCustomModelData, setModelJson, setSashHangingNo, newModelId, newStyleId, setNewModelId, setNewStyleId, setPanelObject, setExternalColor, setInternalColor, setExternalRAL, setInternalRAL, setDefaultSpec, threshHolData, setThreshHolData, setBeadList, setHardwareObject } = useDesigner()

    useEffect(() => {
        if (modelId) {
            setLoading(true)
            setGotData(false)
            setInitialLoad(false)
            getAllFrameService()
            setReceivedData('')
            setQuotationId('')
            setReceivedProductId(0)
            setCustomModelData(modelData)
            setNewModelId('')
            setNewStyleId('')
            setPanelObject([])
            setHardwareObject()


            getAllGlazingData(modelId)
            getProductsData(modelId)
            profileListService(modelId)
            frameStyleService()
            getFrameCollectionDetails(modelId)
            getFrameProductDetails(modelId)
            getModelHardwareOptions(modelId)
        }
    }, [modelId])

    useEffect(() => {
        if (modelId && styleId) {
            verifyModel(modelId, styleId);
        }
    }, [modelId, styleId]);

    useEffect(() => {
        if (newModelId !== "" && newStyleId !== "" && modelId && styleId) {
            setCustomModelData(modelData)
            setPanelObject([])
            setExternalColor()
            setInternalColor()
            setExternalRAL()
            setInternalRAL()

            setModelVisible(false)
            setLoading(true)
            setGotData(false)
            setInitialLoad(false)

            setIsModelChange(true)
        }
    }, [newModelId, newStyleId])

    useEffect(() => {
        if (modelId && productId) {
            getProfileData()
        }
    }, [productId])

    useEffect(() => {
        if (receivedDataLocal && receivedDataLocal.id) {
            setReceivedData(receivedDataLocal)
            setQuotationId(parseInt(receivedDataLocal.quotationId))
            setReceivedProductId(parseInt(receivedDataLocal.id))
        } else {
            setReceivedData('')
        }
    }, [receivedDataLocal])

    useEffect(() => {
        if (modelId) {
            sessionStorage.setItem("collectionId", modelId ? modelId : '')
        }
        if (quotationId) {
            sessionStorage.setItem("quotationId", quotationId ? quotationId : '')
        }
        if (receivedProductId) {
            sessionStorage.setItem("productId", receivedProductId ? receivedProductId : '')
        }
    }, [modelId, quotationId, receivedProductId])

    const profileListService = async (fetchId) => {
        const res = await getTrasomsFrameService(accessToken, fetchId);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setThreshHolData(res?.data?.entity?.threshold)
                setCouplerData(res?.data?.entity?.coupler)
                setBeadList(res?.data?.entity?.bead)
                setBayPostList(res?.data?.entity?.baypost)
                res?.data?.entity?.sill?.length > 0 ? setShowSillOptions(true) : setShowSillOptions(false)
            }
        }
    };

    useEffect(() => {
        if (frameStyles && frameStyles.length > 0) {
            const styleItem = frameStyles?.find((p) => p.id == styleId)

            if (styleItem && styleItem.id) {
                setSelectedAnimation(styleItem)

                if (customModelData?.layoutFrame?.width && styleItem.id) {
                    if (isNewFrame && receivedData && receivedData.id) {

                        if (customModelData?.numberOfSash?.number && customModelData?.numberOfSash?.number > 0) {
                            setCustomModelData((prevData) => ({
                                ...prevData,
                                frameStyle: styleItem,
                                collectionId: modelId,
                            }))
                        }

                        setIsNewFrame(false)
                    } else {
                        setCustomModelData((prevData) => ({
                            ...prevData,
                            frameStyle: styleItem,
                            collectionId: modelId,
                        }))
                    }
                }


                if (!quotationId) {
                    setInitialLoad(false)
                    setGotData(false)
                    setModelVisible(false)
                    setLoading(true)

                    setIsModelChange(true)
                }
            }
        }
    }, [frameStyles])

    useEffect(() => {
        if (modelId && styleId && receivedDataLocal != undefined) {
            setInitialLoad(false)
            setGotData(false)
            setModelVisible(false)
            setLoading(true)

            // if (!is_new_fame) {
            //     getQuotationModelDataService(receivedDataLocal.id)
            // }
        }
    }, [receivedDataLocal])

    const getAllFrameService = async () => {
        const res = await getAllFrameCollection(accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setAllFrameCollection(res?.data?.entity)
            }
        } else {
            handleError(res, false, navigate)
        }
    }


    const getFrameCollectionDetails = async (fetchId) => {
        const res = await getFrameStyleColletionDetails(accessToken, fetchId)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setCollectionDetails(res?.data?.entity)
            }
        } else {
            handleError(res, false, navigate)
        }
    }

    const frameStyleService = async () => {
        const res = await getFrameStyles(accessToken, modelId)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setFrameStyles(res?.data?.entity)
            }
        } else {
            handleError(res, false, navigate)
        }
    }

    const getAllGlazingData = async (fetchId) => {
        const res = await getGlazingProductsData(accessToken, fetchId);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setDefaultSpec(res?.data?.entity)
            }
        }
    };

    const getProductsData = async (fetchId) => {
        const res = await getProductDataService(accessToken, fetchId);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (customModelData?.sashProd) {
                    setProductId(customModelData?.sashProd)
                } else {
                    setProductId(res?.data?.entity[0])
                }
            }
        }
    };

    const getProfileData = async () => {
        const res = await getProfileDataService(accessToken, modelId, productId);

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setInitialProfile(res?.data?.entity);
            }
        }
    };

    const handleAddFramePanel = () => {
        setLoading(true)
        setSidebarOpen(!sidebarOpen);
    };

    const verifyModel = async (modelId, styleId) => {
        const res = await verifyModelService(accessToken, modelId, styleId);
        if (res?.data?.statusCode == 200) {
            setVerifyModels(res?.data?.entity);
        }
    };

    const getFrameProductDetails = async (fetchId) => {
        const res = await getSelectedFrameProduct(accessToken, fetchId);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setFrameProductDetails(res?.data?.entity)
            }
        } else {
            setFrameProductDetails('')
        }
    };

    const getModelHardwareOptions = async (fetchId) => {
        const res = await getModelHardware(accessToken, fetchId, 1);
        if (res?.data?.statusCode === 200) {
            setAllHardware(res?.data?.entity);
        } else {
            setAllHardware([]);
        }
    };

    const currentModel = allFrameCollection.find((item) => item?.id == modelId)

    return (
        <>
            <PanelDesignerBlock
                initialLoad={initialLoad}
                collectionDetails={collectionDetails}
                allFrameCollection={allFrameCollection}
                frameStyles={frameStyles}
                modelId={modelId}
                styleId={styleId}
                receivedData={receivedData}
                quotationId={quotationId}
                receivedProductId={receivedProductId}
                accessToken={accessToken}
                currentModel={currentModel}
                loading={loading}
                setLoading={setLoading}
                frameStyleService={frameStyleService}
                gotData={gotData}
                setGotData={setGotData}
                setInitialLoad={setInitialLoad}
                handleAddFramePanel={handleAddFramePanel}
                setModelVisible={setModelVisible}
                modelVisible={modelVisible}
                showSillOptions={showSillOptions}
                captureImages={captureImages}
                setCaptureImages={setCaptureImages}
                isNewFrame={isNewFrame}
                setIsNewFrame={setIsNewFrame}
                dataLoaded={dataLoaded}
                setDataLoaded={setDataLoaded}
                verifyModels={verifyModels}
                selectedAnimation={selectedAnimation}
                setSelectedAnimation={setSelectedAnimation}
                frameProductDetails={frameProductDetails}
                setFrameProductDetails={setFrameProductDetails}
                allHardware={allHardware}
                setAllHardware={setAllHardware}
                isModelChange={isModelChange}
                setIsModelChange={setIsModelChange}
                setMessage={setMessage}
                setMessageType={setMessageType}
            />

            {receivedDataLocal && receivedDataLocal.id && (
                <AddNewFrame accessToken={accessToken} setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} setMessage={setMessage}
                    setMessageType={setMessageType} setLoading={setLoading} quoteId={quotationId} frameCount={0} />
            )}
        </>
    )
}

export default Left