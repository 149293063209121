import React, { useEffect, useState } from "react";
import PricingHeader from "../../../../../components/Pricing/PricingHeader";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Form, Table } from "react-bootstrap";
import { MenuProps, servicePath } from "../../../../../utility/data";
import axios from "axios";
import CustomizedButton from "../../../../../components/CustomizedButton";
import {
  handleEmptyValues,
  handleFocus,
  newHandleInput,
} from "../../../../../utility/helper";
import { useRightContext } from "../../../../../context/rightMenuContext";
import PricingTableComp from "../../../../../components/PricingTableComp";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { getAllGlazingOversizeCollection } from "../../../../../services/glazingOversizeService";

const Left = ({
  accessToken,
  setMessage,
  setMessageType,
  loading,
  setLoading,
  pageName,
  palleteId,
}) => {

  const { userDataContext, setUserDataContext } = useRightContext("");
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("Lump sum");
  const [selectedPricing, setSelectedPricing] = useState();
  const [selectedSupplyOnly, setSelectedSupplyOnly] = useState("");
  const [costPrice, setCostPrice] = useState([]);
  const [lumpSumData, setLumpSumData] = useState([]);
  const [squareMetreData, setSquareMetreData] = useState([]);
  const [oversizeCollectionOptions, setOversizeCollectionOptions] = useState([]);
  const [oversizeCollection, setOversizeCollection] = useState('');

  // useEffect(() => {
  //   setLoading(true);
  //   getGlazingData();
  // }, []);

  useEffect(() => {
    setLoading(true);
    if ((palleteId, selectedSupplyOnly && selectedPricing)) {
      getGlazingData();
    }
  }, [palleteId, selectedPricing, selectedSupplyOnly]);

  useEffect(() => {
    console.log(activeTab, "activeTab")

    if(activeTab === "Oversize") {
      getAllGlazingOversizeCollectionService()
    }
  }, [activeTab]);

  const navlinks = [
    {
      id: 1,
      name: "Lump sum",
    },
    {
      id: 2,
      name: "Square metre",
    },
    {
      id: 3,
      name: "Minimum area",
    },
    {
      id: 4,
      name: "Oversize",
    },
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const getGlazingData = () => {
    const FormData = require("form-data");

    let data = new FormData();

    data.append("PriceMasterId", selectedPricing);
    data.append("Markupmasterid", selectedSupplyOnly);
    data.append("PaletteType", palleteId);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/GetGlazingPricing`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);

          setLumpSumData(res?.data?.entity["lumpSum"]);

          setSquareMetreData(res?.data?.entity["squareMetre"]);

          console.log(lumpSumData, squareMetreData, "glazingData");
        } else if (res?.data?.statusCode === 404) {
          setLoading(false);
          navigate("/error/404");
        } else {
          setLoading(false);
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleInput = (setMethod, id, key, value) => {
    if (validate(value)) {
      setMethod((prevData) =>
        prevData.map((item, index) =>
          index === id ? { ...item, [key]: parseFloat(value) } : item
        )
      );
    }
  };

  const validate = (value) => {
    if (value < 0) return false;
    return true;
  };

  const handleUpdate = () => {
    setLoading(true);
    updateGlazingData();
  };

  // get all right menu list
  const getAllGlazingOversizeCollectionService = async () => {

    const res = await getAllGlazingOversizeCollection(accessToken);

    if (res && res && res.status === 200) {

      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {

          if (res?.data?.entity?.length > 0) {
            setOversizeCollectionOptions(res?.data?.entity)
          } else {
            setOversizeCollectionOptions([])
          }
        }
        setTimeout(() => {
          setLoading(false)
        }, 1200);
      } else {
        setLoading(false)
        setMessage(res?.data.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }

    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')

      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  const updateGlazingData = () => {
    let config = {

      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/AddUpdateGlazingPricing`,

      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },

      data: handleEmptyValues([...lumpSumData, ...squareMetreData]),
    };

    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setMessage(res.data.message);
          setMessageType("success");
          getGlazingData();
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleCollection = (e) => {
    // console.log(e, "aadi test")
    // setOversizeCollection(e.target.value)
  };

  const handleFormReset = () => {
    setLoading(true);
    getGlazingData();
  };

  var urlName = pageName.toLowerCase();

  // console.log(urlName)

  const data1 = { url: `/product/glazing/${urlName}` };

  return (
    <>
      <PricingHeader
        setSelectedSupplyOnly={setSelectedSupplyOnly}
        setSelectedPricing={setSelectedPricing}
        selectedPricing={selectedPricing}
        selectedSupplyOnly={selectedSupplyOnly}
        costPrice={costPrice}
      />
      <div className="d-flex align-items-center mb-4 flex-wrap gap-3 left_header">
        <h5 className="mb-0 text-wrap text-break me-4 main_title">
          {pageName}
        </h5>

        <CustomizedButton data={data1} />
      </div>

      <Nav tabs className="details_header border-0 nav-tabs-custom">
        {navlinks.map((item) => (
          <NavItem key={item.id}>
            <NavLink
              className={`${activeTab === item.name ? "active" : "text-muted"}`}
              onClick={() => handleTabChange(item.name)}
            >
              {item.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_tab has_btns">
          <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
            <TabPane tabId="Lump sum" className="flex-grow-1 w-100">
              <PricingTableComp
                data={lumpSumData}
                handleInput={handleInput}
                newHandleInput={newHandleInput}
                setData={setLumpSumData}
                userDataContext={userDataContext}
              ></PricingTableComp>
            </TabPane>

            <TabPane tabId="Square metre" className="flex-grow-1 w-100">
              <PricingTableComp
                data={squareMetreData}
                handleInput={handleInput}
                newHandleInput={newHandleInput}
                setData={setSquareMetreData}
                userDataContext={userDataContext}
              ></PricingTableComp>
            </TabPane>

            <TabPane tabId="Minimum area" className="flex-grow-1 w-100">
              <Table responsive className="w-100 discount_table">
                <thead className="border-bottom">
                  <tr className="text-end">
                    <th style={{ width: "90%", minWidth: "90%" }}></th>
                    <th>
                      m<sup>2</sup>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {lumpSumData && lumpSumData.length > 0 ? (
                    <>
                      {lumpSumData &&
                        lumpSumData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>
                              <Form.Control
                                type="number"
                                value={item.mSquar}
                                onFocus={handleFocus}
                                onChange={(e) =>
                                  handleInput(
                                    setLumpSumData,
                                    index,
                                    "mSquar",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={2}>
                        <h5 className="my-4 text-center text-muted w-100">
                          No data found
                        </h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </TabPane>

            <TabPane tabId="Oversize" className="flex-grow-1 w-100">
              <Table responsive className="w-100 discount_table">
                <thead className="border-bottom">
                  <tr className="text-end">
                    <th></th>
                    <th>
                      Oversize Collection
                    </th>
                    {/* <th style={{ width: "10%", min/Width: "10%" }}>Total</th> */}
                  </tr>
                </thead>
                <tbody>
                  {lumpSumData && lumpSumData.length > 0 ? (
                    <>
                      {lumpSumData &&
                        lumpSumData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>
                              <Select
                                labelId="oversizeCollection"
                                id="oversizeCollection"
                                value={item.oversizeCollectionId}
                                label="oversizeCollection"
                                // onChange={(e) => { handleCollection(e) }}
                                onChange={(e) =>
                                  handleInput(
                                    setLumpSumData,
                                    index,
                                    "oversizeCollectionId",
                                    e.target.value
                                  )
                                }
                                input={<OutlinedInput className='w-100' name='oversizeCollection' />}

                                MenuProps={MenuProps}

                                IconComponent={() => (
                                  <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                )}
                              >
                                {oversizeCollectionOptions && oversizeCollectionOptions.map((o, index) => (
                                  <MenuItem key={index} value={o?.id}>
                                    {o?.name}
                                  </MenuItem>
                                ))}
                              </Select>

                              {/* <Form.Control
                                type="number"
                                onFocus={handleFocus}
                                value={item.oversizePrice}
                                onChange={(e) =>
                                  handleInput(
                                    setLumpSumData,
                                    index,
                                    "oversizePrice",
                                    e.target.value
                                  )
                                }
                              /> */}
                            </td>
                            {/* <td>
                              <Form.Control
                                type="number"
                                onFocus={handleFocus}
                                value={item.oversizeTotal}
                                onChange={(e) =>
                                  handleInput(
                                    setLumpSumData,
                                    index,
                                    "oversizeTotal",
                                    e.target.value
                                  )
                                }
                              />
                            </td> */}
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={3}>
                        <h5 className="my-4 text-center text-muted w-100">
                          No data found
                        </h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </TabPane>
          </TabContent>
        </div>
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={handleUpdate}
          >
            Save Changes
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            onClick={handleFormReset}
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Left;
