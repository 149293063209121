import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import {
  deleteTagsMapping,
  getSizeTagsService,
} from "../services/frameStylesService";
import { Link, useNavigate } from "react-router-dom";
import { useMessage } from "../context/messageContext";
import axios from "axios";
import { servicePath } from "../utility/data";
import NumberInput from "./NumberInput";
import { Form, Table } from "react-bootstrap";
import {
  handleEmptySizeTagsValues,
  handlePositiveNumber,
  isOnlySpaces,
} from "../utility/helper";
import { handleError } from "../utility/commonHelper";
import { BiTrashAlt } from "react-icons/bi";

const SizeTagsComp = ({
  setLoading,
  frameProductId,
  activeTab,
  selectedRightItem,
  accessToken,
}) => {
  const inputRefs = useRef([]);
  const { setMessage, setMessageType } = useMessage();
  const navigate = useNavigate();

  const [tagData, setTagData] = useState([]);
  const [tagName, setTagName] = useState("");
  const [length, setLength] = useState(0);

  useEffect(() => {
    if (frameProductId && activeTab === "sizeTags") {
      getTagNames(frameProductId);
    }
  }, [frameProductId, activeTab]);

  const handleRowClick = (index) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index].focus();
      inputRefs.current[index].select();
    }
  };

  const handleDimensionChange = (val, index) => {
    setTagData((prevData) => {
      const updatedData = [...prevData];

      // Check if the input is empty, "-", or only spaces
      if (val === "" || val === "-" || /^\s*$/.test(val)) {
        updatedData[index] = {
          ...updatedData[index],
          length: val, // Keep the raw input
        };
      } else {
        updatedData[index] = {
          ...updatedData[index],
          length: parseFloat(val), // Parse only valid numbers
        };
      }

      return updatedData;
    });
  };

  // const getTagsMasters = async () => {
  //   setLoading(true);

  //   const res = await GetTagsMasters(
  //     accessToken
  //   );

  //   if (res?.data?.statusCode === 200) {
  //     setLoading(false);
  //     if (res?.data?.entity?.length > 0) {
  //       setTagMasterData(res?.data?.entity);
  //     } else {
  //       setTagMasterData([]);
  //     }
  //   } else {
  //     setLoading(false);

  //     setMessageType("error");
  //     setMessage(
  //       res?.message ? res?.message : "Something went wrong !"
  //     );

  //     handleError(res, false, navigate)
  //   }
  // };

  const getTagNames = async (systemId) => {
    setLoading(true);

    const res = await getSizeTagsService(accessToken, systemId);

    if (res?.data?.statusCode === 200) {
      setLoading(false);
      if (res?.data?.entity?.length > 0) {
        setTagData(res?.data?.entity);
      } else {
        setTagData([]);
      }

      setTagName("");
      setLength(0);
    } else {
      setLoading(false);
      setTagName("");
      setLength(0);

      setMessageType("error");
      setMessage(res?.message ? res?.message : "Something went wrong !");

      handleError(res, false, navigate);
    }
  };

  const deleteTagsMappingService = async (item) => {
    setLoading(true);

    const res = await deleteTagsMapping(accessToken, item.id);

    if (res && res && res.status === 200) {
      if (res && res?.data?.statusCode === 200) {
        getTagNames(frameProductId);
        setLoading(false);
      }
    } else {
      setLoading(false);

      setMessageType("error");
      setMessage(
        res?.data?.message ? res?.data?.message : "Something went wrong !"
      );

      handleError(res, false, navigate);
    }
  };

  const updateTagData = (type) => {
    setLoading(true);

    let addItem = [
      {
        id: 0,
        name: tagName,
        frameProductId: frameProductId,
        length: parseFloat(length),
      },
    ];

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/Product/AddUpdateTagsMapping`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data:
        type === "new" ? [...addItem] : handleEmptySizeTagsValues([...tagData]),
    };

    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          getTagNames(frameProductId);

          setMessage(res.data.message);
          setMessageType("success");

          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setMessage(error.message);
        setMessageType("error");
        handleError(error, false, navigate);
      });
  };

  const handleTagNameChange = (e) => {
    const value = e.target.value;
    setTagName(value);
  };

  const handlePositiveNumberFun = (e) => {
    const value = e.target.value;
    if (!/^(-|\d)*$/.test(value)) {
      e.preventDefault();
    }
  };

  return (
    <div
      className={`h-100 d-flex flex-column flex-grow-1 justify-content-between ${
        selectedRightItem?.isSelf ? "" : "not_allowed"
      }`}
    >
      <div className="inside_scroll has_btns has_head">
        <Form className="mb-4">
          <Row>
            <Col lg="6">
              <FormGroup className="form-group">
                <Label for="tagType">Tag Name</Label>

                <Input
                  name="tagName"
                  value={tagName}
                  onChange={(e) => {
                    handleTagNameChange(e);
                  }}
                />
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup className="form-group">
                <Label for="length">Length</Label>

                <Input
                  type="number"
                  name="length"
                  value={length}
                  onChange={(e) => setLength(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

          <div className="d-flex gap-2 gap-xl-3 main_buttons mb-2">
            <Button
              color="primary"
              size="md"
              className="px-4"
              onClick={() => updateTagData("new")}
              disabled={!length || !tagName || isOnlySpaces(tagName)}
            >
              Add
            </Button>
          </div>
        </Form>

        <Table responsive className="w-100 discount_table">
          <thead className="border-bottom">
            <tr className="text-end">
              <th style={{ width: "90%", minWidth: "90%" }}>Tags</th>
              <th className="min-w-120 text-end">Length</th>
              <th className="mini_text text-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tagData && tagData.length > 0 ? (
              <>
                {tagData &&
                  tagData?.map((item, index) => (
                    <tr key={index} onClick={() => handleRowClick(index)}>
                      <td>{item.name}</td>
                      <td>
                        <Form.Control
                          ref={(el) => (inputRefs.current[index] = el)}
                          type="text"
                          value={item?.length}
                          onChange={(e) =>
                            handleDimensionChange(e.target.value, index)
                          }
                          // onInput={(e) => handlePositiveNumberFun(e)}
                        />
                      </td>
                      <td className="text-end">
                        <Link
                          to={{}}
                          onClick={() => deleteTagsMappingService(item)}
                          className="btn btn-icon btn-outline-danger border-0"
                        >
                          <BiTrashAlt size={20} />
                        </Link>
                      </td>
                    </tr>
                  ))}
              </>
            ) : (
              <tr className="bg_trans">
                <td colSpan={3}>
                  <h5 className="my-4 text-center text-muted w-100">
                    No data found
                  </h5>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {selectedRightItem?.isSelf && (
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={() => updateTagData()}
          >
            Save Changes
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            onClick={() => {
              getTagNames(frameProductId);
            }}
          >
            Reset
          </Button>
        </div>
      )}
    </div>
  );
};

export default SizeTagsComp;
