import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import { useDesigner } from "../../context/designerContext";
import NumberInput from "../NumberInput";
import { BiSolidCheckSquare, BiX } from "react-icons/bi";
import { FaGripLines } from "react-icons/fa";
import {
  setModelData,
  setQuotationModelData,
} from "../../services/3dModelServices";
import { useMessage } from "../../context/messageContext";
import { useNavigate } from "react-router-dom";
import { updateSashSize } from "../../utility/threeJsHelpers/uiLinesHelper";
import { handleError } from "../../utility/commonHelper";
import { checkValidationForPositiveNumber } from "../../utility/helper";

function EditFrameSizeModel({
  uiLinesRef,
  accessToken,
  setLayoutSizing,
  collectionId,
  setLoading,
  quotationId,
  receivedProductId,
  frameStyleProdId,
  frameProfileDefault,
  manufacturingToggle,
}) {
  // this is minimum values of frame based on frame profile
  let frameDValue = frameProfileDefault.height * 2;

  const {
    saveHeightWidth,
    setSaveHeightWidth,
    customModelData,
    setCustomModelData,
    setModelWidth,
    setModelHeight,
    lockRefIndex,
    modelJson,
    setResizeFrame,
    setPartialIndex,
  } = useDesigner();

  const [popoverIndex, setPopoverIndex] = useState(null);
  const [isSave, setIsSave] = useState(false);
  const [frameType, setFrameType] = useState("H");
  const [isDisabled, setIsDisabled] = useState(false);
  const [dimentionRecheck, setDimentionRecheck] = useState(false);

  const [modelWidthStatic, setModelWidthStatic] = useState();
  const [modelHeightStatic, setModelHeightStatic] = useState();

  const dimensionRef = useRef(null);

  const { setMessage, setMessageType } = useMessage();

  const navigate = useNavigate();

  useEffect(() => {
    if (popoverIndex != null) {
      if (dimensionRef?.current) {
        dimensionRef?.current?.focus()
        dimensionRef?.current?.select()
      }
    } else {
      if (dimensionRef.current) {
        dimensionRef.current = null
      }
    }
  }, [popoverIndex])

  const handleApply = (e) => {
    // e.preventDefault();
    setResizeFrame(true);
    setPopoverIndex(!popoverIndex);

    if (modelWidthStatic < frameDValue) {
      setModelWidth(frameDValue);
    } else {
      setModelWidth(modelWidthStatic);
      console.log(modelWidthStatic, "modelWidthStatic");
    }

    if (modelHeightStatic < frameDValue) {
      setModelHeight(frameDValue);
    } else {
      setModelHeight(modelHeightStatic);
    }

    // setTimeout(() => {
    //   setDimentionRecheck(true);
    // }, 10);
  };

  useEffect(() => {
    if (
      frameDValue > 0 &&
      modelWidthStatic > 0 &&
      modelHeightStatic > 0 &&
      dimentionRecheck
    ) {
      setSaveHeightWidth(true);
      storeFrameData();
    }
  }, [dimentionRecheck]);

  useEffect(() => {
    if (saveHeightWidth) {
      setDimentionRecheck(false);
      setTimeout(() => {
        setSaveHeightWidth(false);
      }, 100);
    }
  }, [saveHeightWidth]);

  useEffect(() => {
    if (
      modelWidthStatic < frameStyleProdId?.minimumWidth ||
      modelWidthStatic > frameStyleProdId?.maximumWidth ||
      modelHeightStatic < frameStyleProdId?.minimumHeight ||
      modelHeightStatic > frameStyleProdId?.maximumHeight ||
      modelWidthStatic === "" ||
      modelHeightStatic === ""
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [modelHeightStatic, modelWidthStatic]);

  useEffect(() => {
    if (modelWidthStatic < 0) {
      setModelWidthStatic(0);
    }
    if (modelHeightStatic < 0) {
      setModelHeightStatic(0);
    }
  }, [modelHeightStatic, modelWidthStatic]);

  const storeFrameData = () => {
    setCustomModelData((prevModelData) => ({
      ...prevModelData,
      layoutFrame: {
        ...prevModelData.layoutFrame,
        width: parseFloat(modelWidthStatic),
        height: parseFloat(modelHeightStatic),
      },
    }));

    setIsSave(true);
  };

  const handleRefClick = (item) => {
    setModelWidthStatic(customModelData?.layoutFrame?.width);
    setModelHeightStatic(customModelData?.layoutFrame?.height);

    if (item.name.includes("frameWidth")) {
      setFrameType("H");
    } else {
      setFrameType("V");
    }
  };

  const saveQuotationGlazingService = async (data) => {
    setLoading(true);
    const res = await setQuotationModelData(
      accessToken,
      collectionId,
      frameStyleProdId?.id,
      data,
      quotationId,
      receivedProductId,
      modelJson
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false);
        setMessage(res?.data?.message);
        setMessageType("success");
        setLayoutSizing(false);
      }
    } else {
      setLoading(false);
      setMessageType("error");
      setMessage(
        res?.data?.message ? res?.data?.message : "Something went wrong !"
      );
      handleError(res, false, navigate);
    }
  };

  const saveGlazingService = async (data) => {
    setLoading(true);
    const res = await setModelData(
      accessToken,
      collectionId,
      frameStyleProdId?.id,
      data,
      modelJson
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false);
        setMessage(res?.data?.message);
        setMessageType("success");
        setLayoutSizing(false);
        setIsSave(false);
      } else {
        setLayoutSizing(false);
        setLoading(false);
        setMessage(res?.data?.message);
        setMessageType("error");
        setIsSave(false);
      }
    } else {
      setLayoutSizing(false);
      setLoading(false);
      setMessageType("error");
      setMessage(
        res?.data?.message ? res?.data?.message : "Something went wrong !"
      );
      handleError(res, false, navigate);
    }
  };

  useEffect(() => {
    let saveDesignerTimer;
    let saveQuotationTimer;

    if (!quotationId && isSave) {
      saveDesignerTimer = setTimeout(() => {
        saveGlazingService(customModelData);
      }, 100);
    } else if (quotationId && isSave) {
      saveQuotationTimer = setTimeout(() => {
        saveQuotationGlazingService(customModelData);
      }, 100);
    }
    return () => {
      if (saveDesignerTimer) {
        clearTimeout(saveDesignerTimer);
      }
      if (saveQuotationTimer) {
        clearTimeout(saveQuotationTimer);
      }
    };
  }, [customModelData, isSave]);

  const handleApplyOnEnter = (e) => {
    if (e?.key === "Enter" && !isDisabled) {
      handleApply(e);
    }
  };

  const handleFrameWidth = (e, isBlur) => {
    console.log(e?.key, "e?.key");
    if (e?.key === "Enter" && !isDisabled) {
      if (
        e?.target?.value > 0 &&
        e?.target?.value >= frameStyleProdId?.minimumWidth &&
        e?.target?.value <= frameStyleProdId?.maximumWidth
      ) {
        setResizeFrame(true);
        setModelWidth(e?.target?.value);
        setPopoverIndex(false);
      }
    }
  };

  const handleFrameHeight = (e, isBlur) => {
    if (e?.key === "Enter" && !isDisabled) {
      if (
        e?.target?.value > 0 &&
        e?.target?.value >= frameStyleProdId?.minimumHeight &&
        e?.target?.value <= frameStyleProdId?.maximumHeight
      ) {
        setResizeFrame(true);
        setModelHeight(e?.target?.value);
        setPopoverIndex(false);
      }
    }
  };

  return (
    <>
      {uiLinesRef?.current &&
        uiLinesRef?.current?.length > 0 &&
        uiLinesRef?.current?.map((item, index) => {
          return (
            <div
              id={`overlayLines${index}`}
              key={index}
              className="ref_btn_wrap ui_ref"
            >
              <div
                className={`btn_parent ref_sq ${item.name.includes("frameHeight") && "ref_sq_right"
                  } ${item.name.includes("frameWidth") && "is_top"}`}
                style={{
                  position: "absolute",
                  left: item.overlayX,
                  top: item.overlayY,
                }}
              >
                <div className="potition-relative">
                  <Button
                    type="button"
                    color="light"
                    className={`rounded ref_btn position-relative type_2`}
                    id={`frameLinesBtn${index}`}
                    onClick={() => {
                      if (!manufacturingToggle) {
                        handleRefClick(item);
                      }

                      if (manufacturingToggle) {
                        if (item.name.includes("frameWidth")) {
                          setPartialIndex("W");
                        } else {
                          setPartialIndex("H");
                        }
                      }
                    }}
                  >
                    <span>
                      {item.name.includes("frameWidth")
                        ? parseInt(customModelData?.layoutFrame?.width)
                        : parseInt(customModelData?.layoutFrame?.height)}
                    </span>
                  </Button>

                  <UncontrolledPopover
                    placement="bottom"
                    target={`frameLinesBtn${index}`}
                    trigger="legacy"
                    isOpen={popoverIndex === index}
                    toggle={() => {
                      !manufacturingToggle ?
                        setPopoverIndex(popoverIndex === index ? null : index) : setPopoverIndex(null)
                    }}
                    className="custom_shadow"
                  >
                    <PopoverBody className="p-0 position-relative">
                      <div className="p-2">
                        <Button
                          color="primary"
                          className="circle_cross box_right_top rounded"
                          onClick={() => {
                            setPopoverIndex(!popoverIndex);
                          }}
                        >
                          <BiX size={20} />
                        </Button>

                        <React.Fragment>
                          <FormGroup className="mb-0">
                            {item.name.includes("frameWidth") ? (
                              <Label
                                for="width"
                                className="text-dark fs-6 fw-bold"
                              >
                                Width
                              </Label>
                            ) : (
                              <Label
                                for="height"
                                className="text-dark fs-6 fw-bold"
                              >
                                Height
                              </Label>
                            )}

                            <div className="d-flex gap-2">
                              <div className="w-50">
                                {item.name.includes("frameWidth") ? (
                                  <>
                                    <Input
                                      id="partialFrameWidth"
                                      innerRef={dimensionRef}
                                      type="number"
                                      name="width"
                                      value={modelWidthStatic}
                                      onChange={(e) =>
                                        setModelWidthStatic(e.target.value)
                                      }
                                      onInput={(e) => checkValidationForPositiveNumber(e)}
                                      onKeyDown={(e) => handleFrameWidth(e, false)}
                                      className="border-primary"
                                    ></Input>
                                    {parseFloat(modelWidthStatic) <
                                      frameStyleProdId?.minimumWidth ||
                                      modelWidthStatic === "" ? (
                                      <span className="text-danger text-small">{`Min value ${frameStyleProdId?.minimumWidth}`}</span>
                                    ) : null}

                                    {parseFloat(modelWidthStatic) >
                                      frameStyleProdId?.maximumWidth ? (
                                      <span className="text-danger text-small">{`Max value ${frameStyleProdId?.maximumWidth}`}</span>
                                    ) : null}
                                  </>
                                ) : (
                                  <>
                                    {/* <NumberInput
                                    name="height"
                                    value={modelHeightStatic}
                                    setValue={setModelHeightStatic}
                                    className="border-primary"
                                  /> */}
                                    <Input
                                      id="partialFrameHeight"
                                      innerRef={dimensionRef}
                                      type="number"
                                      name="height"
                                      value={modelHeightStatic}
                                      onChange={(e) =>
                                        setModelHeightStatic(e.target.value)
                                      }
                                      onInput={(e) => checkValidationForPositiveNumber(e)}
                                      onKeyDown={(e) => handleFrameHeight(e)}
                                      className="border-primary"
                                    ></Input>
                                    {parseFloat(modelHeightStatic) <
                                      frameStyleProdId?.minimumHeight ||
                                      modelHeightStatic === "" ? (
                                      <span className="text-danger text-small">{`Min value ${frameStyleProdId?.minimumHeight}`}</span>
                                    ) : null}
                                    {parseFloat(modelHeightStatic) >
                                      frameStyleProdId?.maximumHeight ? (
                                      <span className="text-danger text-small">{`Max value ${frameStyleProdId?.maximumHeight}`}</span>
                                    ) : null}
                                  </>
                                )}
                              </div>

                              <Button
                                outline
                                color="primary"
                                size="md"
                                className="px-3 mb-1 w-50"
                                disabled={isDisabled}
                                onClick={(e) => {
                                  handleApply(e);
                                }}
                              >
                                <BiSolidCheckSquare
                                  size={18}
                                  className="me-2"
                                />
                                Apply
                              </Button>
                            </div>
                          </FormGroup>

                          <div className="d-none gap-2">
                            <Button
                              color="primary"
                              size="md"
                              className="px-3 w-50"
                            >
                              <FaGripLines size={18} className="me-2" />
                              Frame
                            </Button>

                            <Button
                              color="primary"
                              size="md"
                              className="px-3 w-50"
                            >
                              <FaGripLines size={18} className="me-2" />
                              Glass
                            </Button>
                          </div>
                        </React.Fragment>
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
export default EditFrameSizeModel;
