import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import ComingSoon from '../../../errors/coming-soon'
import { useLocation, useNavigate } from 'react-router-dom'
import FrameStylesLeft from './left'
import FrameStylesRight from './right'
import { useMessage } from '../../../../../../context/messageContext'
import { useRightContext } from '../../../../../../context/rightMenuContext'
import { addNewRightItem, getRightMenuList, getItemDetails, getFrameStyleColletionDetails, updateItemDetails } from '../../../../../../services/frameStylesService'
import { Oval } from 'react-loader-spinner'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { MdKeyboardArrowDown } from 'react-icons/md'
import NoDataCard from '../../../../../../components/NoDataCard'
import { getAllFrameStyleColletion } from '../../../../../../services/frameCollectionsService'
import { handleError } from '../../../../../../utility/commonHelper'

const FrameStyles = () => {

    const navigate = useNavigate();
    const accessToken = localStorage.getItem('access_token')

    const location = useLocation();

    const collectionId = location.state ? location.state.data : null
    const backStyleId = location.state ? location.state.styleId : null

    const [headerDropdown, setHeaderDropdown] = useState(false);
    const [mainDropdownList, setMainDropdownList] = useState([])
    const [frameCollection, setFrameCollection] = useState('')

    const [wip, setWIP] = useState(false)
    const [loading, setLoading] = useState(true)
    const [pageName, setPageName] = useState("Styles")

    const [selectItemDetails, setSelectItemDetails] = useState([])
    const [allRightListItems, setAllRightListItems] = useState([])
    const [allRightListItemsOrder, setAllRightListItemsOrder] = useState(true)
    // const [frameProductId, setFrameProductId] = useState("")
    const [backStyleIdState, setBackStyleIdState] = useState(null)

    const toggleTabDropdown = () => setHeaderDropdown((prevState) => !prevState);

    const { setMessage, setMessageType } = useMessage()
    const { timestamp, setSelectedRightItem, fetchSelected, setTimestamp, activeIndex, setActiveIndex, setMainMenuActive, setSubMenuActive, setSubMenu2Active } = useRightContext()


    var getFirstElId = allRightListItems[0]?.id || ''

    var itemAddedTrue = ''

    // let { collectionId } = useParams();

    useEffect(() => {
        if (backStyleId && !backStyleIdState) {
            setBackStyleIdState(backStyleId)
        }
    }, [backStyleId])

    useEffect(() => {
        getAllFrameService()

        setMainMenuActive("Products")
        setSubMenuActive("Systems")
        setSubMenu2Active("")
    }, []);

    useEffect(() => {
        if (mainDropdownList && mainDropdownList.length > 0) {
            if (collectionId) {
                const collectionItem = mainDropdownList?.find((p) => p.id == collectionId)

                if (collectionItem && collectionItem.id) {
                    setFrameCollection(collectionItem)
                } else {
                    setFrameCollection(mainDropdownList[0])
                }
            } else {
                setFrameCollection(mainDropdownList[0])
            }
        } else {
            setLoading(false)
        }
    }, [mainDropdownList]);

    useEffect(() => {
        setLoading(true)
        setTimestamp('')
        setActiveIndex(0)

        if (mainDropdownList && mainDropdownList.length > 0) {
            setTimeout(() => {
                if (frameCollection && frameCollection.id) {
                    getRightMenuListData(frameCollection?.id)

                    // getFrameStyleColletionDetailsService(frameCollection?.id)
                }
            }, 400);
        }
    }, [frameCollection?.id])

    useEffect(() => {
        if (allRightListItems?.length > 0 && timestamp) {
            getRightItemDetails(timestamp)
        }
    }, [timestamp])

    useEffect(() => {
        if (backStyleIdState) {
            if (backStyleIdState && allRightListItems?.length > 0) {
                setActiveIndex(allRightListItems.findIndex(e => e?.id == backStyleIdState))
                getRightItemDetails(backStyleIdState)
            }
        } else {

            if (allRightListItemsOrder) {

                getFirstElId = allRightListItems[0]?.id

                setActiveIndex(0)

                if (getFirstElId && allRightListItems?.length) {
                    getRightItemDetails(getFirstElId)
                }
            } else {

                if (getFirstElId && allRightListItems?.length) {
                    if (timestamp && allRightListItems?.length > 0 && activeIndex !== 0) {

                        getRightItemDetails(timestamp)
                    } else {
                        getRightItemDetails(getFirstElId)
                    }
                }
            }
        }

    }, [allRightListItems])

    const getAllFrameService = async () => {
        setTimestamp('')
        setLoading(true)

        const res = await getAllFrameStyleColletion(accessToken)

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMainDropdownList(res?.data?.entity)
            } else {
                setLoading(false)
                setMessage(res?.data?.message);
                setMessageType('error')
            }
            setLoading(false)
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    // const getFrameStyleColletionDetailsService = async (fetchId) => {
    //     const res = await getFrameStyleColletionDetails(accessToken, fetchId);

    //     if (res && res && res.status === 200) {
    //         if (res?.data?.statusCode === 200) {
    //             if (res?.data?.entity !== null) {
    //                 const getPageName = res.data.entity
    //                 setFrameProductId(getPageName?.editAs)
    //             }
    //         }
    //     }
    // }

    // get all right menu item list based on page/type (type = 1 for forewords)
    const getRightMenuListData = async (fetchId) => {

        // setLoading(true);

        const res = await getRightMenuList(accessToken, fetchId);


        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    if (res?.data?.entity?.length > 0) {

                        setAllRightListItems(res?.data?.entity)

                        if (itemAddedTrue == 'added') {

                            
                            const addedItemIndex = res.data.entity.length - 1
                            setBackStyleIdState(null)
                            
                            if (addedItemIndex) {
                                fetchSelected(addedItemIndex, res.data.entity[addedItemIndex]?.id)
                                itemAddedTrue = ''
                            }
                        }
                    } else {
                        setAllRightListItems([])
                        setTimestamp('')

                        fetchSelected(0, '');

                        setSelectItemDetails([])
                    }
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    // get details of selected item in right menu by passing item id
    const getRightItemDetails = async (id) => {

        setLoading(true)

        const res = await getItemDetails(accessToken, id);

        setAllRightListItemsOrder(false)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    const selectedRight = allRightListItems?.find((p) => p.id == id)
                    setSelectedRightItem(selectedRight)

                    // set state to get details of current selected item which will display data in left side component
                    setSelectItemDetails(res.data.entity)

                    // set selected item id which will update context
                    setTimestamp(id)
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    // api call to update all selected item details
    const updateItemDetailsServices = async (data) => {
        setLoading(true)

        const res = await updateItemDetails(accessToken, data)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Updated details');
                setMessageType('success')

                getRightMenuListData(frameCollection?.id)

                setLoading(false)
                fetchSelected(allRightListItems?.findIndex((item => item?.id === data?.id)), data?.id)
                setBackStyleIdState(data?.id)

            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    // api call to added new right item
    const addNewRightItemServices = async (title) => {
        setLoading(true)
        itemAddedTrue = "added"

        const res = await addNewRightItem(accessToken, title, frameCollection?.id)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Record created successfully!');
                setMessageType('success')

                getRightMenuListData(frameCollection?.id)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    const handleFormReset = () => {
        if (timestamp) {

            getRightMenuListData(frameCollection?.id)

            getRightItemDetails(timestamp)
        }
    }

    const handleItemSelect = (value) => {
        setFrameCollection(value)
        setBackStyleIdState(null)
    }

    const navigateToCollection = () => {
        navigate("/product/systems/styles/collections");
    };

    return (
        <>
            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}

            <InnerLayout pageTitle='' layoutType=""
                leftContent={
                    <>
                        {mainDropdownList && mainDropdownList.length > 0 ? (
                            <>
                            {allRightListItems && allRightListItems.length > 0 ? (
                                <FrameStylesLeft collectionId={frameCollection?.id} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} handleFormReset={handleFormReset} />
                            ) : (
                                <div className='mt-5'>
                                    <NoDataCard text="items" loading={loading} isText={true} />
                                </div>
                            )}
                        </>
                        ) : (
                            <NoDataCard text="style collections" loading={loading} isText={true} />
                        )}
                    </>
                }
                rightContent={<FrameStylesRight frameCollection={frameCollection} collectionId={frameCollection?.id} accessToken={accessToken} selectItemDetails={selectItemDetails} allRightListItems={allRightListItems} setLoading={setLoading} getRightMenuListData={getRightMenuListData} addNewRightItemServices={addNewRightItemServices} />}
                rightMaster={true}
                filterButton={
                    <div className='d-flex gap-2 aligm-items-center'>
                        {mainDropdownList && mainDropdownList.length > 0 && (
                            <Dropdown
                                isOpen={headerDropdown}
                                toggle={toggleTabDropdown}
                            >
                                <DropdownToggle color="none" className="border-0 p-0">
                                    <div className="d-flex align-items-center">
                                        <span className="fw-semibold fs-5">
                                            {frameCollection && frameCollection?.name}
                                            <MdKeyboardArrowDown className="ms-1" size={20} />
                                        </span>
                                    </div>
                                </DropdownToggle>

                                <DropdownMenu className='full_height'>
                                    {mainDropdownList && mainDropdownList?.map((item, index) => {
                                        return (
                                            <DropdownItem
                                                onClick={() => handleItemSelect(item)}
                                                key={index}
                                                disabled={item?.id == frameCollection?.id}
                                                className={`${item?.id == frameCollection?.id ? 'text-primary fw-medium' : ''}`}
                                            >
                                                {item?.name}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        )}

                        <div
                            onClick={navigateToCollection}
                            className="text-primary fs-5 clickable"
                        >
                            Edit collections
                        </div>
                    </div>
                }
            />

        </>
    )
}

export default FrameStyles