import React, { useEffect, useMemo, useState } from "react";
import { Alert, FormGroup, Input, Label, NavLink } from "reactstrap";
import { capitalizeFirstLetter } from "../utility/helper";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { Image } from "react-bootstrap";
import { MenuProps } from "../utility/data";
import {
  MdInfoOutline,
  MdOutlineKeyboardArrowDown,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from "react-icons/md";
import { BsTools } from "react-icons/bs";
import { useDesigner } from "../context/designerContext";
import {
  setUpdateManufacturingHardwareStatus,
  updateOffsetService,
} from "../services/ManufacturingServices";
import { useMessage } from "../context/messageContext";
import { getHardwareDataService } from "../services/3dModelServices";

const ManufacturingHardware = ({
  activeTab,
  hardwareListing,
  setAllHardware,
  setHardwareType,
  referencePoint,
  manufacturingToggle,
  collectionId,
  frameStyleProdId,
  accessToken,
  additionalAcc,
}) => {
  const [showListing, setShowListing] = useState(false);
  const [selectedHardware, setSelectedHardware] = useState("");
  const [defaultPosition, setDefaultPosition] = useState(0);
  const [profileCenter, setProfileCenter] = useState(false);
  const [isVerticalProfile, setIsVerticalProfile] = useState(false)

  const { setMessage, setMessageType } = useMessage();
  const { multiSelectRefPoints, setMultiSelectRefPoints } = useDesigner();

  useEffect(() => {
    if (activeTab === "Hardware" && additionalAcc === "2") {
      setHardwareType("additionalHardware");
    } else if (activeTab === "Hardware" && selectedHardware) {
      setHardwareType(selectedHardware);
    } else {
      setHardwareType("");
    }
  }, [additionalAcc, selectedHardware, activeTab]);

  useEffect(() => {
    const checkVerticalProfile = () => {
      return multiSelectRefPoints[0]?.data?.name?.includes("Right") || multiSelectRefPoints[0]?.data?.name?.includes("Left");
    };

    setIsVerticalProfile(checkVerticalProfile());
  }, [multiSelectRefPoints]);

  const memoizedIsVerticalProfile = useMemo(() => isVerticalProfile, [isVerticalProfile])

  useEffect(() => {
    if (multiSelectRefPoints.length > 0) {
      setShowListing(true);
    } else {
      setShowListing(false);
    }
  }, [multiSelectRefPoints]);

  useEffect(() => {
    if (!manufacturingToggle && activeTab == "Hardware") {
      setSelectedHardware("");
    }
  }, [manufacturingToggle, activeTab]);

  useEffect(() => {
    if (referencePoint !== null && activeTab === "Hardware") {
      getHardwareListingData(referencePoint?.index);
    }
  }, [referencePoint, activeTab]);

  console.log(multiSelectRefPoints, "72");



  // Ensure selectedHardware is set properly if hardwareListing changes
  // useEffect(() => {
  //   if (
  //     hardwareListing?.length > 0 &&
  //     selectedHardware === "" &&
  //     manufacturingToggle && activeTab === "Hardware"
  //   ) {
  //     setSelectedHardware(hardwareListing[2]);
  //     setHardwareType(hardwareListing[2]);
  //   }
  // }, [hardwareListing, activeTab]);

  const getHardwareListingData = async (referencePoint) => {
    const data = {
      frameCollectionId: collectionId,
      type: 1,
      referencePoint: referencePoint,
      frameStyleId: frameStyleProdId?.id,
    };

    const res = await getHardwareDataService(accessToken, data);

    if (res?.data?.statusCode === 200) {
      const item = res?.data?.entity?.find(
        (ele) => ele?.id === selectedHardware?.id
      );
      setAllHardware(res?.data?.entity);
      setSelectedHardware(item);
      // This is for selecting the default position and profile center
      if (item?.hardwareStyles?.length > 0) {
        if (item?.hardwareStyles[0]?.defaultPosition !== undefined) {
          setDefaultPosition(item?.hardwareStyles[0]?.defaultPosition);
        }
        if (item?.hardwareStyles[0]?.isProfileCenter !== undefined) {
          setProfileCenter(item?.hardwareStyles[0]?.isProfileCenter);
        }
      }
    } else {
      setSelectedHardware(selectedHardware);
      setAllHardware([]);
    }
  };

  const makeHardwareEnableDisable = async (item) => {
    const status = !item?.status;
    let data = {
      id: 0,
      hardwareStyleId: item?.id,
      referencePoint: referencePoint?.index,
      frameStyleId: frameStyleProdId?.id,
      status: status,
    };

    const res = await setUpdateManufacturingHardwareStatus(accessToken, data);

    if (res?.data?.statusCode == 200) {
      setMessageType("success");
      setMessage(res?.data?.message);
      getHardwareListingData(referencePoint?.index);
      // setMultiSelectRefPoints([]);
    } else {
      setMessageType("error");
      setMessage(res?.data?.message);
      getHardwareListingData(referencePoint?.index);
      // setMultiSelectRefPoints([]);
    }
  };
  const updateOffset = async (isChecked = false) => {
    const data = {
      id: 0,
      hardwareMasterId: selectedHardware?.id,
      frameStyleId: frameStyleProdId?.id,
      referencePoint: referencePoint?.index,
      isProfileCenter: isChecked ? !profileCenter : profileCenter,
      defaultPosition: defaultPosition,
    };
    const res = await updateOffsetService(accessToken, data);
    if (res?.status == 200) {
      getHardwareListingData(referencePoint?.index);
    }
  };

  const handleOffsetUpdate = (event) => {
    if (event.key === "Enter") {
      updateOffset();
    }
  };

  return (
    <div className="ms-2">
      <FormGroup>
        <Label for="hardware" className="text-dark">
          Hardware
        </Label>
        <Select
          fullWidth
          className="w-100"
          labelId="hardware"
          id="hardware"
          value={selectedHardware}
          label="hardware"
          onChange={(e) => {
            setSelectedHardware(e?.target?.value);
            setMultiSelectRefPoints([]);
          }}
          input={<OutlinedInput className="w-100" name="hardware" />}
          MenuProps={MenuProps}
          IconComponent={() => (
            <MdOutlineKeyboardArrowDown
              size={20}
              className="me-1 MuiSvgIcon-root MuiSelect-icon"
            />
          )}
        >
          {hardwareListing &&
            hardwareListing.map((item, index) => (
              <MenuItem key={index} value={item}>
                {capitalizeFirstLetter(item?.name)}
              </MenuItem>
            ))}
        </Select>
      </FormGroup>

      <div className="admin_hardware_list">
        {(showListing && isVerticalProfile) && (
          <div className="d-flex gap-3 align-items-end">
            <FormGroup className="max-w-150">
              <Label for="defaultPosition" className="text-dark">
                Default Position
              </Label>
              <Input
                type="number"
                name="defaultPosition"
                value={defaultPosition}
                onChange={(e) => setDefaultPosition(e.target?.value)}
                // onBlur={updateOffset}
                onKeyDown={handleOffsetUpdate}
              />
            </FormGroup>

            <FormGroup
              className="form-group position-relative d-flex align-items-center right_item_wrap"
              check
            >
              <Input
                type="checkbox"
                name="profileCenter"
                id="profileCenter"
                onChange={(e) => {
                  setProfileCenter(!profileCenter);
                  updateOffset(true);
                }}
                checked={profileCenter}
              />
              <Label check for="profileCenter" className="text-dark ms-2 text-nowrap">
                Profile Center
              </Label>
            </FormGroup>
          </div>
        )}

        <div className="mt-1 right_list">
          {selectedHardware !== null &&
            selectedHardware?.hardwareStyles &&
            selectedHardware?.hardwareStyles.length > 0 ? (
            selectedHardware?.hardwareStyles?.map((item, index) => (
              <>
                {showListing && (
                  <div
                    key={index}
                    className="position-relative d-flex align-items-center right_item_wrap has_delete has_copy"
                  >
                    <div className={`right_item w-100`}>
                      <span
                        className={`me-2 image_icon ${item?.status ? "" : "opacity-50"
                          }`}
                      >
                        {item.imagePath ? (
                          <Image src={item.imagePath} width={25} height={25} />
                        ) : (
                          <BsTools size={25} color="#989898" />
                        )}
                      </span>
                      <div className="right_title">
                        <span
                          className={`text-truncate ${item?.status ? "text-dark" : "text-muted"
                            }`}
                        >
                          {item.name}
                        </span>
                      </div>
                      <span className="position-absolute action_btns">
                        <NavLink onClick={() => makeHardwareEnableDisable(item)}>
                          {item?.status ? (
                            <MdOutlineVisibility
                              className="text-muted"
                              size={18}
                            />
                          ) : (
                            <MdOutlineVisibilityOff
                              className="text-muted"
                              size={18}
                            />
                          )}
                        </NavLink>
                      </span>
                    </div>
                  </div>
                )}
              </>
            ))
          ) : (
            <>
              {selectedHardware !== null &&
                selectedHardware?.hardwareStyles?.length === 0 && (
                  <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
                    <span>
                      <MdInfoOutline size={18} />
                    </span>
                    <span>No hardware to show</span>
                  </Alert>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManufacturingHardware;
