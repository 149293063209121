import React from "react";
import Left from "./left";
import InternalLayout from "../../../../../layouts/internalLayout";
import { useState } from 'react'
import { useMessage } from '../../../../../context/messageContext'
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { productProfileMenu } from "../../../../../utility/data";
import { Oval } from "react-loader-spinner";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import CustomizedButton from "../../../../../components/CustomizedButton";
import { useRightContext } from "../../../../../context/rightMenuContext";
import NoDataCard from "../../../../../components/NoDataCard";
const PricingProfileAddon = () => {
  var access_token = localStorage.getItem("access_token")

  const [loading, setLoading] = useState(true)

  const { setMessage, setMessageType } = useMessage()
  const location = useLocation();
  const profileId = location.state ? location.state.data : null

  const [headerDropdown, setHeaderDropdown] = useState(false);
  const [mainDropdownList, setMainDropdownList] = useState([])
  const [frameCollection, setFrameCollection] = useState('')

  const { setMainMenuActive, setSubMenuActive, setSubMenu2Active } = useRightContext()

  useEffect(() => {
    setMainDropdownList(productProfileMenu)

    setMainMenuActive("Pricing")
    setSubMenuActive("Profile")
    setSubMenu2Active("")
  }, []);

  useEffect(() => {
    if (mainDropdownList && mainDropdownList.length > 0) {
      if (profileId) {
        const collectionItem = mainDropdownList?.find((p) => p.id == profileId)

        if (collectionItem && collectionItem.id) {
          setFrameCollection(collectionItem)
        }
      } else {
        setFrameCollection(mainDropdownList[0])
      }
    }
  }, [mainDropdownList]);

  const toggleTabDropdown = () => setHeaderDropdown((prevState) => !prevState);

  const handleItemSelect = (value) => {
    setFrameCollection(value)
  }

  const data1 = { url: `/product/profiles/list`, stateValue: -1 }

  return (
    <>

      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      {mainDropdownList && mainDropdownList.length > 0 ? (
        <InternalLayout
          pageTitle=""
          layoutType="full"
          leftContent={
            <Left token={access_token} profileId={frameCollection?.id} setMessage={setMessage} setMessageType={setMessageType} pageName={frameCollection?.title} loading={loading} setLoading={setLoading} />
          }
          rightContent={""}
          filterButton={
            <>
              <Dropdown
                isOpen={headerDropdown}
                toggle={toggleTabDropdown}
              >
                <DropdownToggle color="none" className="border-0 p-0">
                  <div className="d-flex align-items-center">
                    <span className="fw-semibold fs-5">
                      {frameCollection && frameCollection?.title}
                      <MdKeyboardArrowDown className="ms-1" size={20} />
                    </span>
                  </div>
                </DropdownToggle>

                <DropdownMenu className='full_height'>
                  {mainDropdownList?.map((item, index) => {
                    return (
                      <DropdownItem
                        onClick={() => handleItemSelect(item)}
                        key={index}
                        disabled={item?.id == frameCollection?.id}
                        className={`${item?.id == frameCollection?.id ? 'text-primary fw-medium' : ''}`}
                      >
                        {item?.title}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
              <CustomizedButton data={data1} hasState={true} />
            </>
          }
        />
      ) : (
        <NoDataCard text="profiles" loading={loading} />
      )}
    </>
  );
};

export default PricingProfileAddon;
