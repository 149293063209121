import React, { useEffect, useState } from "react";
import { BiPlus, BiTrashAlt } from "react-icons/bi";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdOutlineSearch,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import { useMessage } from "../../../../../../context/messageContext";
import {
  deleteRightItem,
  updateOrder,
} from "../../../../../../services/hardwareStylesService";
import { Image } from "react-bootstrap";
import { handleError } from "../../../../../../utility/commonHelper";
import { PiProhibitBold } from "react-icons/pi";

const ProfileRight = ({
  accessToken,
  pageName,
  styleMasterId,
  allRightListItems,
  setLoading,
  getRightMenuListData,
  addNewRightItemServices,
  selectItemDetails,
  usedProducts,
  selectedSystemCollection,
  searchHardware,
  setSearchHardware,
  searchedIndex,
  setSearchedIndex,
}) => {
  const [previouseSelected, setPreviouseSelected] = useState({
    prevId: 1,
    prevIndex: 1,
  });
  const [currentSelected, setCurrentSelected] = useState({
    currId: 1,
    currIndex: 1,
  });
  const [orderUpdate, setOrderUpdate] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [unchangableList, setUnchangableList] = useState([]);
  const [tempActiveIndex, setTempActiveIndex] = useState(-1);

  const navigate = useNavigate();

  const {
    timestamp,
    fetchSelected,
    newItemList,
    setNewItemList,
    handleActiveIndexUp,
    handleActiveIndexDown,
    activeIndex,
    setActiveIndex,
    selectedRightItem,
  } = useRightContext();

  const { setMessage, setMessageType } = useMessage();

  useEffect(() => {
    const assignItemValue = allRightListItems?.map((item) => {
      return {
        name: item.name,
        description: item.description,
        imageUrl: item.imagePath,
        id: item.id,
        isEnabled: item?.isEnabled,
        isSelf: item?.isSelf,
        productCode: item?.productCode,
      };
    });

    setNewItemList(assignItemValue);
    setUnchangableList(assignItemValue);
    // console.log(allRightListItems, "allRightListItems")
  }, [allRightListItems]);

  useEffect(() => {
    if (activeIndex !== -1) {
      setTempActiveIndex(activeIndex);
    }
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndex(0);
  }, []);

  const handleItemClick = (currentIndex, id) => {
    if (currentIndex !== activeIndex) {
      setLoading(true);
    }
    // if (styleMasterId === -1 && selectedSystemCollection?.id === -1) {
    //   getRightMenuListData("", "");
    // } else if (styleMasterId !== -1 && selectedSystemCollection?.id === -1) {
    //   getRightMenuListData(styleMasterId, "");
    // } else if (styleMasterId === -1 && selectedSystemCollection?.id !== -1) {
    //   getRightMenuListData("", selectedSystemCollection?.id);
    // } else {
    //   getRightMenuListData(styleMasterId, selectedSystemCollection?.id);
    // }

    if (searchHardware?.length !== 0) {
      setSearchedIndex(unchangableList?.findIndex((item) => item?.id == id));
    } else {
      setSearchedIndex(-1);
    }

    setCurrentSelected({ currId: id, currIndex: currentIndex + 1 });

    if (currentSelected) {
      setPreviouseSelected({
        prevId: currentSelected.currId,
        prevIndex: currentSelected.currIndex,
      });
    }
    fetchSelected(currentIndex, id);
  };

  // api to delete right side items
  const deleteRightItemServices = async () => {
    setIsDeleting(true);
    setLoading(true);

    const res = await deleteRightItem(accessToken, selectItemDetails?.id);

    if (res && res && res.status === 200) {
      setShowDeleteModal(false);

      fetchSelected(0, allRightListItems[0]?.id);

      if (res?.data?.statusCode === 200) {
        setSearchHardware("");
        setMessage(
          res?.data?.message
            ? res.data.message
            : "Record removed successfully !"
        );
        setMessageType("success");

        if (styleMasterId === -1 && selectedSystemCollection?.id === -1) {
          getRightMenuListData("", "");
        } else if (
          styleMasterId !== -1 &&
          selectedSystemCollection?.id === -1
        ) {
          getRightMenuListData(styleMasterId, "");
        } else if (
          styleMasterId === -1 &&
          selectedSystemCollection?.id !== -1
        ) {
          getRightMenuListData("", selectedSystemCollection?.id);
        } else {
          getRightMenuListData(styleMasterId, selectedSystemCollection?.id);
        }
        setIsDeleting(false);
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
        setIsDeleting(false);
      }
    } else {
      setIsDeleting(false);
      setLoading(false);

      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      setShowDeleteModal(false);
      handleError(res, false, navigate);
    }
  };

  // api to update right side item
  const updateOrderServices = async (styleMasterId, direction) => {
    setLoading(true);

    const res = await updateOrder(
      accessToken,
      timestamp,
      styleMasterId,
      direction
    );

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (styleMasterId === -1 && selectedSystemCollection?.id === -1) {
          getRightMenuListData("", "");
        } else if (
          styleMasterId !== -1 &&
          selectedSystemCollection?.id === -1
        ) {
          getRightMenuListData(styleMasterId, "");
        } else if (
          styleMasterId === -1 &&
          selectedSystemCollection?.id !== -1
        ) {
          getRightMenuListData("", selectedSystemCollection?.id);
        } else {
          getRightMenuListData(styleMasterId, selectedSystemCollection?.id);
        }
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  const handleItemUp = () => {
    setSearchedIndex(-1);
    handleActiveIndexUp();
    updateOrderServices(styleMasterId, "UP");
  };

  const handleItemDown = () => {
    setSearchedIndex(-1);
    handleActiveIndexDown();
    updateOrderServices(styleMasterId, "DOWN");
  };

  const searchHardwareFun = (setList, list, profile, setProfile) => {
    setProfile(profile); // Update the profile state
    const searchText = profile.toLowerCase();
    if (searchText.length > 0) {
      setActiveIndex(-1);
      const new_list = unchangableList.filter((obj) => {
        const nameMatch = obj.name.toLowerCase().includes(searchText);
        const codeMatch =
          typeof obj.productCode === "string" &&
          String(obj.productCode).includes(searchText);
        return nameMatch || codeMatch;
      });
      setList(new_list); // Update the filtered list
    } else {
      fetchSelected(0, unchangableList[0]?.id);
      setActiveIndex(0);
      setList(unchangableList); // Reset to the full list when input is empty
    }
  };

  return (
    <>
      <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="pb-3 px-3">
          <InputGroup className="border rounded">
            <Input
              type="text"
              placeholder="Search Hardware"
              className="search-input border-0"
              value={searchHardware}
              onChange={(e) =>
                searchHardwareFun(
                  setNewItemList,
                  newItemList,
                  e.target.value,
                  setSearchHardware
                )
              }
            />
            <div className="input-group-append">
              <span className="input-group-text bg-transparent border-0 px-2">
                <MdOutlineSearch size={16} />
              </span>
            </div>
          </InputGroup>
        </div>
        <div className="inside_scroll has_btns has_search">
          <div className="right_list">
            {newItemList && newItemList?.length > 0 ? (
              <>
                {newItemList?.map((item, index) => (
                  <div
                    key={index}
                    className="position-relative d-flex align-items-center right_item_wrap has_delete"
                  >
                    <Link
                      to="#"
                      className={`right_item w-100 ${
                        activeIndex === index && "active"
                      }`}
                      onClick={() => handleItemClick(index, item.id)}
                    >
                      <span className="me-2 image_icon">
                        {item.imageUrl ? (
                          <Image
                            src={item.imageUrl}
                            style={{ width: "55px", height: "55px" }}
                          />
                        ) : (
                          <PiProhibitBold size={55} />

                          // <span>
                          //     <svg viewBox="0 0 100 50" width="55" height="55"><path className="stroke" stroke='#989898' fill='#fff' strokeWidth="2" d={`M 0,50 0,0 100,0 100,50 Z`}></path></svg>
                          // </span>
                        )}
                      </span>

                      <div className="right_title">
                        <span className="text-truncate">{item.name}</span>
                        <span className="text-truncate d-block text-small">
                          {item.description}
                        </span>
                      </div>
                    </Link>
                    <span className="position-absolute action_btns">
                      {item?.isSelf && (
                        <>
                          {newItemList?.length > 1 && (
                            <Link
                              to="#"
                              onClick={() => {
                                setShowDeleteModal(true);
                                fetchSelected(index, item.id);
                              }}
                            >
                              {" "}
                              <BiTrashAlt
                                className="text-muted"
                                size={20}
                              />{" "}
                            </Link>
                          )}
                        </>
                      )}
                      {/* <Link to="#"> <MdContentCopy className='text-muted' size={18} /> </Link> */}
                    </span>
                  </div>
                ))}
              </>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center p-5"
                style={{ height: "100%" }}
              >
                <span>
                  No data found for selected filter
                  {/* <br />
                                Please Change the filter */}
                </span>
              </div>
            )}
          </div>
        </div>

        {/* Start: space for buttons at the bottom */}
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            disabled={
              selectedSystemCollection?.id !== -1
              // unchangableList?.length > 0 &&
              // ||
              // (styleMasterId === -1 && selectedSystemCollection?.id === -1) ||
              // (styleMasterId !== -1 && selectedSystemCollection?.id !== -1) ||
              // (styleMasterId === -1 && selectedSystemCollection?.id !== -1)
            }
            onClick={() =>
              addNewRightItemServices(
                `Hardware ${
                  unchangableList && unchangableList.length
                    ? unchangableList.length + 1
                    : 1
                }`,
                styleMasterId
              )
            }
          >
            <BiPlus size={20} className="me-1" />
            New
          </Button>

          {selectedRightItem?.isSelf && (
            <>
              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => handleItemUp()}
                disabled={
                  timestamp === "" ||
                  activeIndex === 0 ||
                  searchHardware.length != 0 ||
                  selectedSystemCollection?.id !== -1
                  // (styleMasterId === -1 &&
                  //   selectedSystemCollection?.id === -1) ||
                  // (styleMasterId !== -1 && selectedSystemCollection?.id !== -1)
                }
              >
                <MdArrowUpward size={22} className="me-1" />
                Up
              </Button>
              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => handleItemDown()}
                disabled={
                  timestamp === "" ||
                  unchangableList?.length - 1 === activeIndex ||
                  // selectedRightItem?.isLast ||
                  searchHardware.length != 0 ||
                  selectedSystemCollection?.id !== -1
                  // (styleMasterId === -1 &&
                  //   selectedSystemCollection?.id === -1) ||
                  // (styleMasterId !== -1 && selectedSystemCollection?.id !== -1)
                }
              >
                <MdArrowDownward size={22} className="me-1" />
                Down
              </Button>
            </>
          )}
        </div>
        {/* End: space for buttons at the bottom */}
      </div>

      <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
        {usedProducts?.length > 0 ? (
          <h2 className="px-3 pt-3">Oops!</h2>
        ) : (
          <ModalHeader>Delete Selected Item</ModalHeader>
        )}
        {usedProducts?.length > 0 ? (
          <ModalBody>
            <p className="bt-0">
              This hardware is being used by the following colletions:
            </p>
            {usedProducts.map((item, index) => (
              <span key={index}>
                {index == 0 ? null : <br></br>}
                {item.productType}
                {item.usedIn}
              </span>
            ))}

            <p className="mt-4">
              Remove the hardware from these colletions first.
            </p>
          </ModalBody>
        ) : (
          <ModalBody>
            Are you sure you want delete{" "}
            <strong>{selectItemDetails?.name}</strong> ?
          </ModalBody>
        )}
        <ModalFooter>
          {usedProducts?.length == 0 ? (
            <Button
              color="outline-danger"
              size="md"
              disabled={isDeleting}
              onClick={() => deleteRightItemServices()}
            >
              Delete
            </Button>
          ) : null}
          <Button
            onClick={() => setShowDeleteModal(false)}
            color="primary"
            size="md"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ProfileRight;
