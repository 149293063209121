import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import { createSashPath } from './lineShapes';
import { createShapeFromWidthHeight, createExternalMesh, removeObjectByName, getSplitValue } from './utils/threeUtils'
import { countHorizontalTransoms, updateTransomCount } from './utils/generalUtils'
import { addHardwareInSteps, applyHinges, dualHandles, getHandlePosition, loadHardware, positionHardwareOnFrame, positionHardwareOnSash, transonsFormedHandle } from './hardwares';
import { emptyTransomPartitions, emptyTransomRefs, getTransomPartitionRefs, storeTransomPartitionRefs } from './transoms';
import { processSplit, updateTransomData } from './transoms'
import { getSashType } from '../../../utility/helper';
import { CSG } from 'three-csg-ts';
import { assignTexture, createGlassMaterial, createPanelMatrial, createProfileMaterial } from './utils/materials';


export let hangingRefs = []
let sashMeshArr = [];
let sashRefs = []

export const storeHangingRefs = (obj, callBack) => {
    hangingRefs.push(obj)
    if (typeof callBack === 'function') {
        callBack()
    }
}

const storeSashMesh = (obj) => {
    sashMeshArr.push(obj);
}

export const getSashMesh = () => {
    return sashMeshArr;
}

export const getSashHangingRefs = () => {
    return hangingRefs;
}

export const storeSashRefs = (obj) => {
    sashRefs.push(obj);
}

export const getSasProfilehRefs = () => {
    return sashRefs;
}

export const removeSashRefs = () => {
    sashRefs = []
}

export const removeHangingRefs = () => {
    hangingRefs = []
}

export const removeHangings = (index = 0, updateData) => {
    // setting the custom model data
    updateData();
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson];
        const updatedModelJson = { ...updatedModelArray[index] };
        updatedModelJson.sash.hangings = 0;
        updatedModelJson.sash.sections = [];
        updatedModelJson.sash.partialWidthRatios = [1];
        updatedModelJson.sash.partialHeightRatios = [1]
        updatedModelJson.sash.sashSize = [updatedModelJson.dimensions.width * 1000];
        updatedModelArray[index] = updatedModelJson;
        return updatedModelArray;
    };
};


export const updateSashInTransom = (ref, updatedValues, jsonIndex = 0) => {
    return (prevModelJson) => {
        // Parse the `ref` to get the sashProfileKey and sectionId
        const sectionData = ref ? ref.split(" ") : [];
        const sashProfileKey = sectionData.length ? sectionData[0] : "";
        const sectionId = sectionData.length ? sectionData[1] : "";

        if (!sectionId || !sashProfileKey || !updatedValues) {
            console.error("Invalid arguments provided to updateSashInTransom");
            return prevModelJson; // Return the original model if arguments are invalid
        }

        if (!Array.isArray(prevModelJson)) {
            console.error("Invalid model JSON: Expected an array");
            return prevModelJson;
        }

        // Deep copy the previous model to avoid mutation
        const updatedModelArray = [...prevModelJson];
        const updatedModelJson = { ...updatedModelArray[jsonIndex] };

        if (!updatedModelJson.transom || !Array.isArray(updatedModelJson.transom.splits)) {
            console.error("Invalid transom structure: Missing or malformed 'splits'");
            return prevModelJson;
        }

        // Recursive function to traverse and update the model
        const updateSection = (node) => {
            if (!node || typeof node !== "object") return;

            // Check if the current node matches the sectionId
            if (node.id === sectionId) {
                if (
                    node.sashProfile &&
                    typeof node.sashProfile === "object" &&
                    node.sashProfile[sashProfileKey]
                ) {
                    // Merge the updated values into the specific sashProfile key
                    node.sashProfile[sashProfileKey] = {
                        ...node.sashProfile[sashProfileKey],
                        width: updatedValues?.width / 1000,
                        height: updatedValues?.height / 1000,
                        id: updatedValues?.id
                    };
                    console.log(`Updated sashProfile for ${sashProfileKey}:`, node.sashProfile[sashProfileKey]);
                } else {
                    console.warn(
                        `SashProfile or key "${sashProfileKey}" not found for sectionId "${sectionId}"`
                    );
                }
                return; // Exit once the section is updated
            }

            // Traverse splits recursively
            if (Array.isArray(node.splits)) {
                for (const split of node.splits) {
                    updateSection(split);
                }
            }

            // Traverse sections recursively
            if (Array.isArray(node.sections)) {
                for (const section of node.sections) {
                    updateSection(section);
                }
            }
        };

        // Traverse the transom splits
        updatedModelJson.transom.splits.forEach((node) => updateSection(node));

        // Replace the updated object in the array
        updatedModelArray[jsonIndex] = updatedModelJson;

        return updatedModelArray;
    };
};




export const loadDefaultSashProfile = (numberOfIteration, defaultSash, setMethod, partialWidthRatios, initialSpec, index = 0, setMessage, setMessageType) => {

    return (prevModelJson) => {

        try {

            if (!defaultSash || !defaultSash.id) {
                setMessage("No default sash configured");
                setMessageType("error");
                return;
            }

            const { height = 0, width = 0 } = defaultSash || {};
            const id = defaultSash?.id;
            const { hexValue } = initialSpec || {};

            const updatedModelArray = [...prevModelJson];
            const updatedModelJson = { ...updatedModelArray[index] };
            updatedModelJson.sash.hangings = numberOfIteration;
            updatedModelJson.sash.partialWidthRatios = partialWidthRatios
            updatedModelJson.sash.partialHeightRatios = [1]

            if (numberOfIteration >= 1) {
                updatedModelJson.sash.sections = Array.from(
                    { length: numberOfIteration },
                    (_, index) => {
                        const profileWidth = width / 1000;
                        const profileHeight = height / 1000;
                        let sashProfileObj = {
                            SashTop: { width: profileWidth, height: profileHeight, id: id },
                            SashRight: { width: profileWidth, height: profileHeight, id: id },
                            SashBottom: { width: profileWidth, height: profileHeight, id: id },
                            SashLeft: { width: profileWidth, height: profileHeight, id: id },
                        };
                        // Calculate dynamic dimensions for sashProfile

                        const maxComputedIndex = numberOfIteration * 4 - 1;
                        Object.keys(sashProfileObj)?.forEach((k, keyIndex) => {
                            const computedIndex = index * 4 + keyIndex;
                            if (computedIndex > maxComputedIndex) {
                                return;
                            }
                            setMethod(computedIndex, defaultSash, k, maxComputedIndex);
                        });

                        return {
                            id: index,
                            material: "glass",
                            opacity: 0.6,
                            reflectivity: 0.5,
                            color: hexValue,
                            transomCount: 0,
                            panels: {
                                isPanel: false
                            },
                            textures: {
                                isTexture: false,
                            },
                            type: "fixed",
                            model: null,
                            sashProfile: {
                                SashTop: { width: profileWidth, height: profileHeight, id: id, isVisible: true },
                                SashRight: { width: profileWidth, height: profileHeight, id: id, isVisible: true },
                                SashBottom: { width: profileWidth, height: profileHeight, id: id, isVisible: true },
                                SashLeft: { width: profileWidth, height: profileHeight, id: id, isVisible: true },
                            },
                        };

                    });
            } else {
                updatedModelJson.sash.sections = [];
                setMethod((prevData) => ({
                    ...prevData,
                    sashData: [],
                }));
            }
            updatedModelArray[index] = updatedModelJson;
            return updatedModelArray;

        } catch (error) {
            setMessage?.(error.message || "An error occurred while loading the sash profile.");
            setMessageType?.("error");
            return prevModelJson;

        }


    }




}


const handleTrickleVents = async (allTrickleVents, boundingBox, trickleVentData, group) => {
    if (trickleVentData && trickleVentData?.length) {
        // const boundingBox = frames[key][2];
        // const triclkleVentCount = trickleVent?.length;
        const trickleModel = await Promise.all(
            trickleVentData.map(async (item) => {
                const hardware = await loadHardware(item.model);
                const hardwareClone = hardware.clone()
                group.add(hardwareClone); // Add to group immediately
                return hardwareClone;
            })
        );

        allTrickleVents.current = trickleModel
        // Call addHardwareInSteps after all hardware models are loaded
        await addHardwareInSteps(boundingBox, allTrickleVents, trickleVentData);
        // const modelClone = transformedModel.clone();
    }
}

export const deleteSashProfile = (index, jsonIndex = 0, orientation) => {
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson];
        const updatedModelJson = { ...updatedModelArray[jsonIndex] };
        const section = updatedModelJson.sash.sections[index];
        section.sashProfile[orientation].isVisible = false;
        updatedModelArray[jsonIndex] = updatedModelJson;
        return updatedModelArray;
    }
}


export const updateSashProfile = (index, orientation, updatedSash, method, profileIndex, updateSashData, jsonIndex = 0) => {
    const { height, width, id } = updatedSash;
    const newHeight = height / 1000;
    const newWidth = width / 1000;

    let searchedItem = method?.find((item) => item?.index === profileIndex);

    updateSashData(updatedSash, searchedItem, profileIndex)

    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson];
        const updatedModelJson = { ...updatedModelArray[jsonIndex] };
        const section = updatedModelJson.sash.sections[index];
        section.sashProfile[orientation].width = newWidth;
        section.sashProfile[orientation].height = newHeight;
        section.sashProfile[orientation].id = id;
        section.sashProfile[orientation].isVisible = true;


        updatedModelArray[jsonIndex] = updatedModelJson;
        return updatedModelArray;
    };
};

export const editHangings = (
    index,
    type,
    handleModel,
    setMethod,
    data,
    hardwareType,
    cylinderData = null,
    jsonIndex = 0,
    hingeModel,
    defaultMullion,
    hingeData,
    deleteHingeData
) => {
    // Save handleData

    try {

        setMethod(data, null, index, hardwareType, cylinderData, null);

        const typeMapping = {
            SashLeft: "SashRight",
            SashRight: "SashLeft",
            SashTop: "SashBottom",
            SashBottom: "SashTop",
        };

        return (prevModelJson) => {
            // Clone the entire array to avoid mutating the original
            const updatedModelArray = [...prevModelJson];

            // Clone the specific object at the given jsonIndex
            const updatedModelJson = { ...updatedModelArray[jsonIndex] };

            const doorHandle = {
                model: handleModel,
                color: "#00000",
                offsetX: 0,
                offsetY: 0,
                center: data?.isProfileCenter,
                defaultPosition: data?.defaultPosition,
                modelHeight: updatedModelJson?.dimensions?.height,
                side: "Both",
                direction: "default"
            };

            const hinge = {
                model: hingeModel,
                color: "#00000",
                offsetX: 0,
                offsetY: 0,
                center: hingeData?.isProfileCenter,
                defaultPosition: hingeData?.defaultPosition,
                modelHeight: updatedModelJson?.dimensions?.height,
                side: "Both",
            }

            let hanging = updatedModelJson?.sash;

            if (type === "master/slave" || type === "slave/master") {
                // hanging.partialWidthRatios = [0.5, 0.5]
                hanging.isMasterSlave = true;
                hanging.mullion = {
                    width: defaultMullion?.width / 1000 || 0.1,
                    depth: defaultMullion?.height / 1000 || 0.1,
                };
            } else {
                hanging.isMasterSlave = false;
                hanging.mullion = {
                    width: 0,
                    depth: 0,
                };
            }

            const side = getSashType(type);
            const oppositeSide = typeMapping[side];


            // Update the specified section

            let section = updatedModelJson.sash.sections[index];
            section.type = type;
            section.id = data?.id;

            if (section.type && section.type !== "fixed" && (!hanging?.isMasterSlave)) {

                section.sashProfile[oppositeSide].doorhandle = doorHandle;
                deleteHingeData(null, null, null, true)

            } else if (section.type && hanging?.isMasterSlave && section.type !== "fixed") {
                let section1 = updatedModelJson.sash.sections[0];
                let section2 = updatedModelJson.sash.sections[1];
                // section.forEach((sec) => {
                //     sec.id = data.id;
                // })
                section1.type = "left";
                section2.type = "right";
                section1.sashProfile["SashRight"].doorhandle = doorHandle;
                section2.sashProfile["SashLeft"].doorhandle = doorHandle;
                section1.sashProfile["SashLeft"].hinge = hingeModel ? [hinge, hinge, hinge] : [];
                section2.sashProfile["SashRight"].hinge = hingeModel ? [hinge, hinge, hinge] : [];;
                setMethod(data, null, index, hardwareType, cylinderData, hingeData, 2);
            } else {

                Object.keys(section.sashProfile).forEach((side) => {
                    const sash = section.sashProfile[side];

                    // Check if the current sash profile has a doorhandle and remove it
                    if (sash.doorhandle) {
                        delete sash.doorhandle; // Remove the doorhandle property
                    }
                });
            }

            // Replace the updated object back into the array
            updatedModelArray[jsonIndex] = updatedModelJson;
            // Return the updated array
            return updatedModelArray;
        };

    } catch (error) {
        console.error("Error in editHangings :::: ", error);

    }

};


const extrudeSettings = (path, steps) => ({
    steps: steps,
    extrudePath: path,
    bevelEnabled: true,
    bevelThickness: 0.01,
    bevelSize: 0.01,
    bevelOffset: 0,
    bevelSegments: 5,
    curveSegments: 12
});


const subtractHoleFromSash = (sashGeometry, holeWidth, holeHeight, holePosition, center, sashBounding) => {
    // Ensure the input is BufferGeometry
    if (!sashGeometry.isBufferGeometry) {
        sashGeometry = new THREE.BufferGeometry().fromGeometry(sashGeometry);
    }

    // Create the hole geometry
    const holeShape = new THREE.Shape();
    holeShape.moveTo(-holeWidth / 2, -holeHeight / 2);
    holeShape.lineTo(holeWidth / 2, -holeHeight / 2);
    holeShape.lineTo(holeWidth / 2, holeHeight / 2);
    holeShape.lineTo(-holeWidth / 2, holeHeight / 2);
    holeShape.closePath();

    const extrudeSettings = {
        depth: sashBounding?.max.z || 10, // Match sash depth
        bevelEnabled: false,
    };

    const holeGeometry = new THREE.ExtrudeGeometry(holeShape, extrudeSettings);

    // Position the hole
    holeGeometry.translate(center.x, center.y, center.z);

    // Perform CSG operations
    const sashCSG = CSG.fromGeometry(sashGeometry);
    const holeCSG = CSG.fromGeometry(holeGeometry);

    // Subtract the hole from the sash
    const finalCSG = sashCSG.subtract(holeCSG);

    // Convert back to geometry
    const finalGeometry = CSG.toGeometry(finalCSG);

    if (!finalGeometry) {
        throw new Error('CSG operation failed: finalGeometry is undefined');
    }

    return finalGeometry;
};




export async function createSash(sashes, group, frameSides, frameWidth, frameHeight, sashHangingValue, sashGroup, partialWidth, allTrickleVents, masterSlave, windowData, wLoffset, wTopOffset, wBOffset, framePathCollection, modelName) {
    emptyTransomPartitions()
    emptyTransomRefs()
    sashMeshArr = [];
    // partialWidth = [0.24, 0.26, 0.1, 0.4000000000000001]//*********** */
    let outerPartialWidthRatio = partialWidth;
    let newPartialHeight = sashes.partialHeightRatios;
    let partialHeight = newPartialHeight;


    const { color, colorExt } = windowData

    const wWidth = windowData.dimensions.width
    let mullionWidth = 0;
    if (sashHangingValue == 2 && sashes?.isMasterSlave) {
        masterSlave = {
            mullion: {
                width: sashes?.mullion?.width || 0.1,
                depth: sashes?.mullion?.height || 0.1
            }
        }
    }

    if (masterSlave && typeof masterSlave === 'object' && Object.keys(masterSlave).length > 0) {
        mullionWidth = masterSlave.mullion.width;
    }
    const offsetL = frameSides.left.dimensions.height,
        offsetT = frameSides.top.dimensions.height,
        // offsetT = 0,
        offsetR = frameSides.right.dimensions.height,
        offsetB = frameSides.bottom.dimensions.height;
    const setSashHangingNo = sashHangingValue;

    const sashMaterial = createProfileMaterial(colorExt)
    const frameMaterial_Ext = createProfileMaterial(color)

    sashGroup.current = [];

    const sashPaths = ["SashTop", "SashRight", "SashBottom", "SashLeft"];
    let sashWidthFull = (frameWidth - (offsetL + offsetR + mullionWidth));

    let offset = -(wWidth / 2) + wLoffset + offsetL;
    // updateTransomCount(sashes, "vertical");
    let getarray = countHorizontalTransoms(sashes, "vertical");
    let getHeightarray = countHorizontalTransoms(sashes, "horizontal");

    function calculateParentWidth(partialWidthFull, splitArray) {
        let parentWidth = [];
        let startIndex = 0;

        // Loop through each value in splitArray
        for (let count of splitArray) {
            // Sum up `count` elements from partialWidthFull
            let segmentSum = partialWidthFull
                .slice(startIndex, startIndex + count) // Take the segment
                .reduce((acc, val) => acc + val, 0); // Sum the segment
            parentWidth.push(segmentSum);
            startIndex += count; // Move to the next segment
        }

        return parentWidth;
    }


    let widthCount = getarray.reduce((sum, value) => sum + value, 0);
    let heightCount = getHeightarray.reduce((sum, value) => sum + value, 0);
    let oldWidthCount = windowData.sash?.oldSashArray ? windowData.sash?.oldSashArray.reduce((sum, value) => sum + value, 0) : sashHangingValue;
    let oldHeightCount = windowData.sash?.oldSashHeightArray ? windowData.sash?.oldSashHeightArray.reduce((sum, value) => sum + value, 0) : sashHangingValue;

    function getNewRatio(partialWidth, frameWidth, offsetL, offsetR) {
        let newWidth = frameWidth - offsetL - offsetR;
        const partialWidthArray = partialWidth.map(ratio => ratio * frameWidth);
        partialWidthArray[0] -= offsetL;
        partialWidthArray[partialWidthArray.length - 1] -= offsetR;
        const newPartialWidths = partialWidthArray.map(ratio => ratio / newWidth);
        return newPartialWidths;
    }

    function getNewRationReverse(partialWidth, frameWidth, offsetL, offsetR) {
        let newWidth = frameWidth - offsetL - offsetR;
        const partialWidthArray = partialWidth.map(ratio => ratio * newWidth);
        partialWidthArray[0] += offsetL;
        partialWidthArray[partialWidthArray.length - 1] += offsetR;
        const newPartialWidths = partialWidthArray.map(ratio => ratio / frameWidth);
        return newPartialWidths;
    }




    const newRatios = getNewRatio(partialWidth, frameWidth, offsetL, offsetR);



    // partialWidth = newRatios;

    const partitions = {}
    // const newPartitons = {};
    // partialWidth = [0.3, 0.7];
    // let preWidthSplit = calculatePartialWidth(getarray, outerPartialWidthRatio);
    let outerPartialWidths = outerPartialWidthRatio.map((width) => width * frameWidth);

    outerPartialWidths[0] -= frameSides.left.dimensions.height;
    outerPartialWidths[outerPartialWidthRatio.length - 1] -= frameSides.right.dimensions.height;
    let sashPartialWidthRatioFull = outerPartialWidths.map((width) => width / (frameWidth - frameSides.right.dimensions.height - frameSides.left.dimensions.height));


    let preWidthSplit = calculatePartialWidth(getarray, sashPartialWidthRatioFull);
    let preHeightSplit = [1]


    let isNewWidthAdding = true, isNewHeightAdding = true, sashSplitRatio;
    if (widthCount == partialWidth.length) {
        isNewWidthAdding = false;
        sashSplitRatio = calculateParentWidth(sashPartialWidthRatioFull, getarray);

    } else if (oldWidthCount == partialWidth.length) {
        isNewWidthAdding = true;
        sashSplitRatio = calculateParentWidth(sashPartialWidthRatioFull, windowData.sash.oldSashArray);

    } else {
        // setTimeout(() => {
        //     createSash(sashes, group, frameSides, frameWidth, frameHeight, sashHangingValue, sashGroup, windowData?.sash?.partialWidthRatios || partialWidth, allTrickleVents, masterSlave, windowData, wLoffset, wTopOffset, wBOffset, framePathCollection , modelName)
        // }, 200);
        // return;
    }
    windowData.sash.oldSashArray = getarray;
    windowData.sash.oldSashHeightArray = getHeightarray;
    if (heightCount == partialHeight.length) {
        isNewHeightAdding = false;
    } else if (oldHeightCount == partialWidth.length) {

    }

    // let preHeightSplit = calculatePartialWidth(getHeightarray, newPartialHeight);

    for (let index = 0; index < setSashHangingNo; index++) {
        let sashWidth;
        // let partialWidthEnabled = frameWidth < partialWidth[index] ? false : true;
        let sumOfWidths = preWidthSplit[index]?.reduce((acc, value) => acc + value, 0);
        let widthSplit = preWidthSplit[index]?.map((width) => width / sumOfWidths);
        let sumOfHeights = preHeightSplit.reduce((acc, value) => acc + value, 0);
        let heightSplit = preHeightSplit.map((width) => width / sumOfHeights);
        let paths = [];

        const sashHeight = frameHeight - (offsetT + offsetB);
        if (sashSplitRatio && sashSplitRatio[index]) {
            sashWidth = (sashWidthFull) * sashSplitRatio[index]
            // sashWidth = (frameWidth - (offsetL + offsetR + mullionWidth)) * (sashSplitRatio[index] / (frameWidth));
        }

        if (index == 0) {
            // offset += (sashWidth * 0.8) / 2;
            offset += sashWidth / 2;
            // offset = -(frameWidth / 2) + (sashWidth / 2) + offsetL;
            // offset = wTopOffset;
            if (mullionWidth > 0) createMullion(masterSlave, group, sashWidth, sashHeight, sashMaterial, frameMaterial_Ext, framePathCollection, offset);
        } else if (index == 1) {
            offset += (sashWidth / 2) + mullionWidth;
        } else {
            offset += sashWidth / 2;
        }
        // }


        let nextOffset = 0;
        let currentSashTop;
        let sashLeftMesh;
        sashPaths.forEach(async (sashPosition) => {
            const sashPath = createSashPath(sashWidth, sashHeight, sashPosition, offset, 0, wTopOffset, wBOffset);
            framePathCollection.current.push(sashPath);
            paths.push(sashPath);
            framePathCollection.current.push(sashPath);

            const sash = sashes.sections[index].sashProfile[sashPosition]

            const shapeHt = sash?.height
            const shapeW = sash?.width

            const sashShape = createShapeFromWidthHeight(shapeW, shapeHt);
            // const sashGeometry = new THREE.ExtrudeGeometry(sashShape, extrudeSettings(sashPath));
            const sashGeometry1 = new THREE.ExtrudeGeometry(sashShape, extrudeSettings(sashPath, 2));
            const sashGeometry = BufferGeometryUtils.mergeVertices(sashGeometry1);
            sashGeometry.computeVertexNormals();
            const sashMesh = new THREE.Mesh(sashGeometry, sashMaterial);
            sashMesh.position.y -= (offsetT - offsetB) / 2;



            // sashMesh.position.y += 0.01;
            sashMesh.castShadow = true;
            sashMesh.receiveShadow = true;
            sashMesh.name = sashPosition;

            if (sashPosition == "SashTop") currentSashTop = sashMesh;
            sashMesh.visible = sash.isVisible;
            storeSashMesh(sashMesh);
            group.add(sashMesh);
            if (sashPosition == "SashLeft") sashLeftMesh = sashMesh;
            let externalColor = true;
            if (externalColor) {
                const extMesh = sashMesh.clone();
                extMesh.material = frameMaterial_Ext;
                extMesh.name += "_ext";
                extMesh.position.z -= 0.1 / 1000;
                const offsetVal = 2 / 1000;
                sashGeometry.computeBoundingBox();
                const beforeBound = sashGeometry.boundingBox;
                const beforeWidth = beforeBound.max.x - beforeBound.min.x;
                const beforeHeight = beforeBound.max.y - beforeBound.min.y;
                const xScaleVal = (beforeWidth + offsetVal) / beforeWidth;
                const yScaleVal = (beforeHeight + offsetVal) / beforeHeight;
                const xCenter = (beforeBound.max.x + beforeBound.min.x) / 2;
                const xOffset = ((xCenter * (xScaleVal)) - xCenter) / 1;
                const yCenter = (beforeBound.max.y + beforeBound.min.y) / 2;
                const yOffset = ((yCenter * (yScaleVal)) - yCenter) / 1;
                extMesh.scale.x = xScaleVal;
                extMesh.scale.y = yScaleVal;
                extMesh.position.x -= xOffset;
                extMesh.position.y -= yOffset;
                group.add(extMesh);
            }


            sashGroup.current.push(sashMesh);
            const sashBounding = new THREE.Box3().setFromObject(sashMesh);
            if (sashPosition == "SashRight") {
                nextOffset = sashBounding.max.x;
            }
            const sashCenter = new THREE.Vector3()
            sashBounding.getCenter(sashCenter)


            if (!partitions[index]) {
                partitions[index] = {}; // Create a new object for the current index
            }

            partitions[index][sashPosition] = sashCenter;

            const section = sashes?.sections[index]
            const profile = sashes?.sections[index]?.sashProfile[sashPosition]

            setTimeout(async () => {
                if (section?.type && section?.type !== 'fixed' && !sashes?.isMasterSlave) {
                    if (profile?.doorhandle) {
                        const handle = await transonsFormedHandle(sashes, partitions, index, profile?.doorhandle, sashBounding)
                        group.add(handle.clone())
                    }
                } else if (section?.type && section?.type !== 'fixed' && sashes?.isMasterSlave) {

                    if (profile?.doorhandle) {
                        const handle1 = await dualHandles(sashes, partitions, index, profile?.doorhandle, sashBounding)
                        group.add(handle1.clone())
                    }

                }

                if (profile?.hinge && profile?.hinge?.length) {
                    await applyHinges((profile?.hinge), sashBounding, group, sashPosition)
                }


                if ((!section?.type || section?.type === 'fixed')) {

                    if (profile?.doorhandle || profile?.windowhandle) {
                        const model = profile?.doorhandle?.model || profile?.windowhandle?.model;
                        let sashHandle = await loadHardware(model)
                        let cloneSashHandle = sashHandle.clone();
                        const data = profile?.doorhandle || profile?.windowhandle
                        cloneSashHandle = await positionHardwareOnSash(data, sashMesh, cloneSashHandle, sashBounding, "");
                        group.add(cloneSashHandle);
                    }
                }

                if (profile?.tricklevent && profile?.tricklevent?.length) {
                    await handleTrickleVents(allTrickleVents, sashMesh, profile?.tricklevent, group)
                }

                if (profile?.spyhole) {
                    // if(spyHole){
                    const spyHole = profile?.spyhole
                    const hardware = await loadHardware(spyHole.model);
                    const spyHoleClone = hardware.clone();
                    setTimeout(async () => {
                        const positionSpyHole = await positionHardwareOnSash(spyHole, sashMesh, spyHoleClone, sashBounding, "inside")
                        group.add(positionSpyHole);
                    }, 1500);
                    // }
                }
            }, 100);

            const sashPos = {
                position: { x: sashCenter?.x, y: sashCenter?.y, z: sashCenter?.z },
                name: sashMesh?.name
            }
            storeSashRefs(sashPos)
        })

        const partition = sashes?.sections[index];

        const glassThickness = 0.02;

        let glassMaterial = createGlassMaterial(partition?.color)
        console.log(partition?.color, "partition?.color");

        if (partition && partition.textures && partition.textures.isTexture && partition.textures.texturePath) {
            assignTexture(partition.textures.texturePath, glassMaterial);
        }

        const panelMaterial = createPanelMatrial(color)

        const glassShape = getGlassShape(paths);
        const glassExtrudeSettings = {
            depth: glassThickness,
            bevelEnabled: false,
        };

        const glassGeometry = new THREE.ExtrudeGeometry(glassShape, glassExtrudeSettings);
        const glassMesh = new THREE.Mesh(glassGeometry, glassMaterial);

        glassMesh.position.y -= (offsetT - offsetB) / 2;
        let lockingPlate = modelName === "Heritage Door" ? "LockingPlate" : ""

        glassMesh.name = `GlassPanel_${index}`;
        glassMesh.scale.set(0.999, 0.999, 1);
        glassMesh.position.z = -glassThickness * 0.5;
        glassMesh.renderOrder = -10;

        const boundingBox = new THREE.Box3().setFromObject(glassMesh);
        const center = new THREE.Vector3()
        boundingBox.getCenter(center)

        const glassPos = {
            position: { x: center?.x, y: center?.y, z: center?.z },
            name: glassMesh?.name
        }
        // glassMaterial = glassData.material.clone()

        const sashLeftBounding = new THREE.Box3().setFromObject(sashLeftMesh);
        if (!sashes.sections[index].splits) {

            let isPanelEnabled = partition?.panels?.isPanel || false; //panels *****

            if (!isPanelEnabled) {
                group.add(glassMesh);
            } else {

                const frameMaterial_Ext = createProfileMaterial(colorExt)

                const panelMesh = new THREE.Mesh(glassGeometry.clone(), frameMaterial_Ext);
                panelMesh.name = `GlassPanel_${index}`;
                panelMesh.scale.set(0.999, 0.999, 1);
                panelMesh.position.z = (windowData.frame.sides.top.dimensions.height * 0.5) - (glassThickness * 0.5) - 0.011;
                group.add(panelMesh);

                let externalColor = true;
                if (externalColor) {
                    createExternalMesh(panelMesh, panelMaterial, glassGeometry, group)
                }
            }
            // group.add(glassMesh);
            storeTransomPartitionRefs(glassPos, getTransomPartitionRefs)
            // emptyTransomRefs()
        } else {
            const currentSashTopBounding = new THREE.Box3().setFromObject(currentSashTop);

            const sashProfileHeight = sashes.sections[index].sashProfile.SashRight.height || 0.04;
            let parentGroup, startPosX = sashLeftBounding.min.x, startPosY = currentSashTopBounding.min.y,
                parentWidth = sashWidth,
                parentHeight = sashHeight, transomFrameHeight = 0.04,
                transomFrameDepth = sashes.sections[index].sashProfile.SashRight.width,
                materials = { "frameMaterial": sashMaterial, "glassMaterial": glassMaterial, "panelMaterial": panelMaterial, "frameMaterial_Ext": frameMaterial_Ext };
            updateTransomCount(sashes.sections[index].splits[0], widthSplit, sashes.sections[index].splits[0].orientation);

            // if(!isNewWidthAdding && !isNewHeightAdding){
            if (!isNewWidthAdding) {
                // updateTransomSplit();
                // updateTransomCount(sashes.sections[index].splits[0], widthSplit, "vertical");
                updateTransomData(sashes.sections[index], heightSplit, widthSplit)


            }
            // else{
            //     updateTransomCount(sashes.sections[index].splits[0], widthSplit, "vertical");
            //     updateTransomCount(sashes.sections[index].splits[0], widthSplit, "horizontal");
            // }
            if (index == 0) {
                // console.log("first sash calling :::: -----");
            }
            if (index == (setSashHangingNo - 1)) {
                // console.log("last sash calling :::: -----");
            }
            await processSplit(sashes.sections[index]?.splits[0], group, startPosX, startPosY, parentWidth, parentHeight, transomFrameHeight, transomFrameDepth, materials, colorExt, modelName, {}, 0, true);
            group.isContainTransoms = true;
        }

        // offset += sashWidth;
        offset = nextOffset;
        storeHangingRefs(glassPos, getSashHangingRefs)
    };
}

export async function createSashFromTransom(sashes, group, frameSides, frameWidth, frameHeight,
    sashHangingValue, sashGroup, partialWidth, allTrickleVents, masterSlave,
    windowData, wLoffset, wTopOffset, wBOffset, framePathCollection,
    modelName) {

    emptyTransomPartitions()
    emptyTransomRefs()
    sashMeshArr = [];
    partialWidth = sashes?.ratios;
    // partialWidth = [0.24, 0.26, 0.1, 0.4000000000000001]//*********** */
    let outerPartialWidthRatio = partialWidth;
    let newPartialHeight = sashes.partialHeightRatios;
    let partialHeight = newPartialHeight;


    const { color, colorExt, materials } = windowData

    // sashHangingValue = sashes?.hangings
    sashHangingValue = Math.min(sashes.ratios.length, sashes?.hangings)

    const wWidth = windowData.dimensions.width

    let mullionWidth = 0;
    if (sashHangingValue == 2 && sashes?.masterSlave?.mullion) {
        masterSlave = {
            mullion: {
                width: sashes?.masterSlave?.mullion?.width || 0.1,
                depth: sashes?.masterSlave?.mullion?.depth || 0.1
            }
        }
    }

    // console.log(sashes , );



    if (sashes?.masterSlave?.mullion) {
        mullionWidth = masterSlave.mullion.width;
    }
    const offsetL = frameSides?.left?.dimensions?.height || 0,
        offsetT = frameSides?.top.dimensions.height || 0,
        // offsetT = 0,
        offsetR = frameSides?.right.dimensions.height || 0,
        offsetB = frameSides?.bottom.dimensions.height || 0;
    const setSashHangingNo = sashHangingValue;

    const sashMaterial = materials.frameMaterial
    const frameMaterial_Ext = materials.frameMaterial_Ext

    sashGroup.current = [];

    const sashPaths = ["SashTop", "SashRight", "SashBottom", "SashLeft"];
    let sashWidthFull = (frameWidth - (offsetL + offsetR + mullionWidth));

    let offset = windowData.startPosX || -(wWidth / 2) + wLoffset + offsetL;
    let offsetY = windowData.startPosY || 0;

    // updateTransomCount(sashes, "vertical");
    let getarray = sashes?.masterSlave?.mullion ? [1, 1] : countHorizontalTransoms(sashes, "vertical");
    let getHeightarray = countHorizontalTransoms(sashes, "horizontal");

    function calculateParentWidth(partialWidthFull, splitArray) {
        let parentWidth = [];
        let startIndex = 0;

        // Loop through each value in splitArray
        for (let count of splitArray) {
            // Sum up `count` elements from partialWidthFull
            let segmentSum = partialWidthFull
                .slice(startIndex, startIndex + count) // Take the segment
                .reduce((acc, val) => acc + val, 0); // Sum the segment
            parentWidth.push(segmentSum);
            startIndex += count; // Move to the next segment
        }

        return parentWidth;
    }


    let widthCount = getarray.reduce((sum, value) => sum + value, 0);
    let heightCount = getHeightarray.reduce((sum, value) => sum + value, 0);
    let oldWidthCount = sashes?.oldSashArray ? sashes?.oldSashArray.reduce((sum, value) => sum + value, 0) : sashHangingValue;
    let oldHeightCount = sashes?.oldSashHeightArray ? sashes?.oldSashHeightArray.reduce((sum, value) => sum + value, 0) : sashHangingValue;

    function getNewRatio(partialWidth, frameWidth, offsetL, offsetR) {
        let newWidth = frameWidth - offsetL - offsetR;
        const partialWidthArray = partialWidth.map(ratio => ratio * frameWidth);
        partialWidthArray[0] -= offsetL;
        partialWidthArray[partialWidthArray.length - 1] -= offsetR;
        const newPartialWidths = partialWidthArray.map(ratio => ratio / newWidth);
        return newPartialWidths;
    }

    function getNewRationReverse(partialWidth, frameWidth, offsetL, offsetR) {
        let newWidth = frameWidth - offsetL - offsetR;
        const partialWidthArray = partialWidth.map(ratio => ratio * newWidth);
        partialWidthArray[0] += offsetL;
        partialWidthArray[partialWidthArray.length - 1] += offsetR;
        const newPartialWidths = partialWidthArray.map(ratio => ratio / frameWidth);
        return newPartialWidths;
    }




    const newRatios = getNewRatio(partialWidth, frameWidth, offsetL, offsetR);


    const partitions = {}

    let outerPartialWidths = outerPartialWidthRatio.map((width) => width * frameWidth);

    outerPartialWidths[0] -= frameSides?.left.dimensions.height || 0;
    outerPartialWidths[outerPartialWidthRatio.length - 1] -= frameSides?.right.dimensions.height || 0;
    let sashPartialWidthRatioFull = outerPartialWidths.map((width) => width / (frameWidth));

    let preWidthSplit = calculatePartialWidth(getarray, sashPartialWidthRatioFull);
    let preHeightSplit = [1]


    let isNewWidthAdding = true, isNewHeightAdding = true, sashSplitRatio;
    if (widthCount == partialWidth.length) {
        isNewWidthAdding = false;
        sashSplitRatio = calculateParentWidth(sashPartialWidthRatioFull, getarray);

    } else {
        isNewWidthAdding = true;
        sashSplitRatio = calculateParentWidth(sashPartialWidthRatioFull, sashes.oldSashArray);
    }
    if (setSashHangingNo == 1) sashSplitRatio[0] = 1

    // else if (oldWidthCount == partialWidth.length) {
    //     isNewWidthAdding = true;
    //     sashSplitRatio = calculateParentWidth(sashPartialWidthRatioFull, sashes.oldSashArray);

    // } else {
    //     // setTimeout(() => {
    //     //     createSash(sashes, group, frameSides, frameWidth, frameHeight, sashHangingValue, sashGroup, windowData?.sash?.partialWidthRatios || partialWidth, allTrickleVents, masterSlave, windowData, wLoffset, wTopOffset, wBOffset, framePathCollection , modelName)
    //     // }, 200);
    //     // return;
    // }
    sashes.oldSashArray = getarray;
    sashes.oldSashHeightArray = getHeightarray;
    if (heightCount == partialHeight.length) {
        isNewHeightAdding = false;
    } else if (oldHeightCount == partialWidth.length) {

    }


    for (let index = 0; index < setSashHangingNo; index++) {
        let sashWidth;
        // let partialWidthEnabled = frameWidth < partialWidth[index] ? false : true;
        let sumOfWidths = preWidthSplit[index]?.reduce((acc, value) => acc + value, 0);
        let widthSplit = preWidthSplit[index]?.map((width) => width / sumOfWidths);
        // let sumOfWidths = preWidthSplit[index] ? preWidthSplit[index].reduce((acc, value) => acc + value, 0) : preWidthSplit[0].reduce((acc, value) => acc + value, 0);
        // let widthSplit = preWidthSplit[index] ? preWidthSplit[index].map((width) => width / sumOfWidths) : preWidthSplit[0].map((width) => width / sumOfWidths);
        let sumOfHeights = preHeightSplit.reduce((acc, value) => acc + value, 0);
        let heightSplit = preHeightSplit.map((width) => width / sumOfHeights);
        let paths = [];

        const sashHeight = frameHeight - (offsetT + offsetB);
        if (sashSplitRatio && sashSplitRatio[index]) {
            sashWidth = (sashWidthFull) * sashSplitRatio[index]
            // sashWidth = (frameWidth - (offsetL + offsetR + mullionWidth)) * (sashSplitRatio[index] / (frameWidth));
        }

        if (index == 0) {
            // offset += (sashWidth * 0.8) / 2;
            offset += sashWidth / 2;
            // offset = -(frameWidth / 2) + (sashWidth / 2) + offsetL;
            // offset = wTopOffset;
            if (mullionWidth > 0) createMullion(masterSlave, group, sashWidth, sashHeight, sashMaterial, materials.frameMaterial_Ext, framePathCollection, offset, offsetY - sashHeight / 2);
        } else if (index == 1) {
            offset += (sashWidth / 2) + mullionWidth;
        } else {
            offset += sashWidth / 2;
        }
        // }


        let nextOffset = 0;
        let currentSashTop;
        let sashLeftMesh;
        sashPaths.forEach(async (sashPosition) => {
            const sashPath = createSashPath(sashWidth, sashHeight, sashPosition, offset, offsetY - sashHeight / 2, wTopOffset, wBOffset);
            framePathCollection?.current.push(sashPath);
            paths.push(sashPath);
            // framePathCollection.current.push(sashPath);

            // const sash = sashes.sections[index].sashProfile[sashPosition]
            const sash = sashes.sections[index].sashProfile[sashPosition]//index should be dynamic, *****

            const shapeHt = sash?.height
            const shapeW = sash?.width

            const sashShape = createShapeFromWidthHeight(shapeW, shapeHt);
            // const sashGeometry = new THREE.ExtrudeGeometry(sashShape, extrudeSettings(sashPath));
            const sashGeometry1 = new THREE.ExtrudeGeometry(sashShape, extrudeSettings(sashPath, 2));
            const sashGeometry = BufferGeometryUtils.mergeVertices(sashGeometry1);
            sashGeometry.computeVertexNormals();
            const sashMesh = new THREE.Mesh(sashGeometry, sashMaterial);
            sashMesh.position.y -= (offsetT - offsetB) / 2;



            // sashMesh.position.y += 0.01;
            sashMesh.castShadow = true;
            sashMesh.receiveShadow = true;
            sashMesh.name = sashPosition;

            if (sashPosition == "SashTop") currentSashTop = sashMesh;
            sashMesh.visible = sash.isVisible;
            storeSashMesh(sashMesh);
            group.add(sashMesh);
            if (sashPosition == "SashLeft") sashLeftMesh = sashMesh;
            let externalColor = true;
            if (externalColor) {
                const extMesh = sashMesh.clone();
                extMesh.material = frameMaterial_Ext;
                extMesh.name += "_ext";
                extMesh.position.z -= 0.1 / 1000;
                const offsetVal = 2 / 1000;
                sashGeometry.computeBoundingBox();
                const beforeBound = sashGeometry.boundingBox;
                const beforeWidth = beforeBound.max.x - beforeBound.min.x;
                const beforeHeight = beforeBound.max.y - beforeBound.min.y;
                const xScaleVal = (beforeWidth + offsetVal) / beforeWidth;
                const yScaleVal = (beforeHeight + offsetVal) / beforeHeight;
                const xCenter = (beforeBound.max.x + beforeBound.min.x) / 2;
                const xOffset = ((xCenter * (xScaleVal)) - xCenter) / 1;
                const yCenter = (beforeBound.max.y + beforeBound.min.y) / 2;
                const yOffset = ((yCenter * (yScaleVal)) - yCenter) / 1;
                extMesh.scale.x = xScaleVal;
                extMesh.scale.y = yScaleVal;
                extMesh.position.x -= xOffset;
                extMesh.position.y -= yOffset;
                group.add(extMesh);
            }

            const section = sashes?.sections[index];
            const profile = sashes?.sections[index]?.sashProfile[sashPosition];

            sashMesh.name += ` ${section?.id}`

            sashGroup.current.push(sashMesh);
            const sashBounding = new THREE.Box3().setFromObject(sashMesh);
            if (sashPosition == "SashRight") {
                nextOffset = sashBounding.max.x;
            }
            const sashCenter = new THREE.Vector3()
            sashBounding.getCenter(sashCenter)


            if (!partitions[index]) {
                partitions[index] = {}; // Create a new object for the current index
            }

            partitions[index][sashPosition] = sashCenter;



            setTimeout(async () => {
                if (section?.type && section?.type !== 'fixed' && !sashes?.isMasterSlave) {
                    if (profile?.doorhandle) {
                        const handle = await transonsFormedHandle(sashes, partitions, index, profile?.doorhandle, sashBounding)
                        group.add(handle.clone())
                    }
                } else if (section?.type && section?.type !== 'fixed' && sashes?.isMasterSlave) {

                    if (profile?.doorhandle) {
                        const handle1 = await dualHandles(sashes, partitions, index, profile?.doorhandle, sashBounding)
                        group.add(handle1.clone())
                    }

                }

                if (profile?.hinge && profile?.hinge?.length) {
                    await applyHinges((profile?.hinge), sashBounding, group, sashPosition)
                }


                if ((!section?.type || section?.type === 'fixed')) {

                    if (profile?.doorhandle || profile?.windowhandle) {
                        const model = profile?.doorhandle?.model || profile?.windowhandle?.model;
                        let sashHandle = await loadHardware(model)
                        let cloneSashHandle = sashHandle.clone();
                        const data = profile?.doorhandle || profile?.windowhandle
                        cloneSashHandle = await positionHardwareOnSash(data, sashMesh, cloneSashHandle, sashBounding, "");
                        group.add(cloneSashHandle);
                    }
                }

                if (profile?.tricklevent && profile?.tricklevent?.length) {
                    await handleTrickleVents(allTrickleVents, sashMesh, profile?.tricklevent, group)
                }

                if (profile?.spyhole) {
                    // if(spyHole){
                    const spyHole = profile?.spyhole
                    const hardware = await loadHardware(spyHole.model);
                    const spyHoleClone = hardware.clone();
                    setTimeout(async () => {
                        const positionSpyHole = await positionHardwareOnSash(spyHole, sashMesh, spyHoleClone, sashBounding, "inside")
                        group.add(positionSpyHole);
                    }, 1500);
                    // }
                }
            }, 100);

            const sashPos = {
                position: { x: sashCenter?.x, y: sashCenter?.y, z: sashCenter?.z },
                name: sashMesh?.name
            }
            storeSashRefs(sashPos)
        })

        // const partition = sashes?.sections[index];
        const partition = sashes?.sections[0];//index should be dynamic, *****

        const glassThickness = 0.02;

        let glassMaterial = createGlassMaterial(partition?.color)
        if (partition && partition.textures && partition.textures.isTexture && partition.textures.texturePath) {
            assignTexture(partition.textures.texturePath, glassMaterial);
        }

        const panelMaterial = createPanelMatrial(color)

        const glassShape = getGlassShape(paths);
        const glassExtrudeSettings = {
            depth: glassThickness,
            bevelEnabled: false,
        };

        const glassGeometry = new THREE.ExtrudeGeometry(glassShape, glassExtrudeSettings);
        const glassName = sashes

        const glassMesh = new THREE.Mesh(glassGeometry, glassMaterial);

        glassMesh.position.y -= (offsetT - offsetB) / 2;
        let lockingPlate = modelName === "Heritage Door" ? "LockingPlate" : ""

        glassMesh.name = glassName?.id ? glassName?.id : `GlassPanel_${index}`;

        glassMesh.scale.set(0.999, 0.999, 1);
        glassMesh.position.z = -glassThickness * 0.5;
        glassMesh.renderOrder = -10;


        const boundingBox = new THREE.Box3().setFromObject(glassMesh);
        const center = new THREE.Vector3()
        boundingBox.getCenter(center)

        const glassPos = {
            position: { x: center?.x, y: center?.y, z: center?.z },
            name: glassMesh?.name
        }
        // glassMaterial = glassData.material.clone()

        const sashLeftBounding = new THREE.Box3().setFromObject(sashLeftMesh);
        if (!sashes.sections[0].splits) {//index should be dynamic, *****
            // if (!sashes.sections[index].splits) {

            let isPanelEnabled = partition?.panels?.isPanel || false; //panels *****

            if (!isPanelEnabled) {
                group.add(glassMesh);
            } else {

                const frameMaterial_Ext = createProfileMaterial(colorExt)

                const panelMesh = new THREE.Mesh(glassGeometry.clone(), frameMaterial_Ext);
                panelMesh.name = `GlassPanel_${index}`;
                panelMesh.scale.set(0.999, 0.999, 1);
                panelMesh.position.z = (windowData.frame.sides.top.dimensions.height * 0.5) - (glassThickness * 0.5) - 0.011;
                group.add(panelMesh);

                let externalColor = true;
                if (externalColor) {
                    createExternalMesh(panelMesh, panelMaterial, glassGeometry, group)
                }
            }
            // group.add(glassMesh);
            // storeTransomPartitionRefs(glassPos, getTransomPartitionRefs)
            // emptyTransomRefs()
        } else {
            const currentSashTopBounding = new THREE.Box3().setFromObject(currentSashTop);

            const sashProfileHeight = sashes.sections[index].sashProfile.SashRight.height || 0.04;
            let parentGroup, startPosX = sashLeftBounding.min.x, startPosY = currentSashTopBounding.min.y,
                parentWidth = sashWidth,
                parentHeight = sashHeight, transomFrameHeight = 0.04,
                transomFrameDepth = sashes.sections[index].sashProfile.SashRight.width,
                materials = { "frameMaterial": sashMaterial, "glassMaterial": glassMaterial, "panelMaterial": panelMaterial, "frameMaterial_Ext": frameMaterial_Ext };
            updateTransomCount(sashes.sections[index].splits[0], widthSplit, sashes.sections[index].splits[0].orientation);

            // if(!isNewWidthAdding && !isNewHeightAdding){
            if (!isNewWidthAdding) {
                // updateTransomSplit();
                // updateTransomCount(sashes.sections[index].splits[0], widthSplit, "vertical");
                updateTransomData(sashes.sections[index], heightSplit, widthSplit)


            }
            // else{
            //     updateTransomCount(sashes.sections[index].splits[0], widthSplit, "vertical");
            //     updateTransomCount(sashes.sections[index].splits[0], widthSplit, "horizontal");
            // }
            if (index == 0) {
                // console.log("first sash calling :::: -----");
            }
            if (index == (setSashHangingNo - 1)) {
                // console.log("last sash calling :::: -----");
            }
            await processSplit(sashes.sections[index]?.splits[0], group, startPosX, startPosY, parentWidth, parentHeight, transomFrameHeight, transomFrameDepth, materials, colorExt, modelName, {}, 0, true);
            group.isContainTransoms = true;
        }

        // offset += sashWidth;
        offset = nextOffset;
        console.log(glassPos, "1303");

        storeHangingRefs(glassPos, getSashHangingRefs)
    };
}

function getGlassShape(paths) {
    const shape = new THREE.Shape();
    if (paths[0]) shape.moveTo(paths[0].v1.x, paths[0].v1.y);
    paths.forEach(path => {
        if (path) shape.lineTo(path.v2.x, path.v2.y);
    });
    shape.lineTo(paths[0]?.v1?.x, paths[0]?.v1?.y);
    return shape;
}

export const handlePartialWidthChange = (partialWidthRatios, partialHeightRatios, jsonIndex = 0) => {
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updatedModelJson = { ...updatedModelArray[jsonIndex] };
        updatedModelJson.sash.partialWidthRatios = partialWidthRatios
        updatedModelJson.sash.partialHeightRatios = partialHeightRatios
        return updatedModelArray;
    }
}

function createMullion(masterSlave, group, sashWidth, sashHeight, material, frameMaterial_Ext, framePathCollection, offset, offsetY = 0) {
    // -(sashWidth / 2) + (width / 2)
    const { width, depth } = masterSlave.mullion;
    const mullionShape = createShapeFromWidthHeight(depth, width, 0.008);
    // const mullionPath = createSashPath(sashWidth, sashHeight, "SashRight", 0);
    const mullionPath = createSashPath(sashWidth, sashHeight, "SashRight", offset + width, offsetY, 0, 0);
    framePathCollection?.current.push(mullionPath);
    const mullionGeometry = new THREE.ExtrudeGeometry(mullionShape, extrudeSettings(mullionPath, 2));
    const mullionMesh = new THREE.Mesh(mullionGeometry, material);
    //     mullionMesh.position.y -= (offsetT - offsetB) / 2;

    // mullionMesh.position.x = 0;
    // mullionMesh.position.z = depth/2;
    // //         mullionMesh.castShadow = true;
    // //         mullionMesh.receiveShadow = true;
    // //         mullionMesh.name = mullionPosition;
    group.add(mullionMesh);
    let externalColor = true;
    if (externalColor) {
        createExternalMesh(mullionMesh, frameMaterial_Ext, mullionGeometry, group)
    }
}
function calculatePartialWidth(getarray, newPartialWidth) {
    let partialWidthAlt = [];
    let currentIndex = 0;

    // Iterate over each value in getarray
    for (let i = 0; i < getarray.length; i++) {
        let count = getarray[i]; // Number of elements to take from newPartialWidth
        let subArray = newPartialWidth.slice(currentIndex, currentIndex + count); // Extract the subarray
        partialWidthAlt.push(subArray); // Add the subarray to partialWidthAlt
        currentIndex += count; // Move the currentIndex forward
    }

    return partialWidthAlt;
}

export const deleteHangingFromTransoms = (deleteConfig, jsonIndex = 0, updateData) => {
    let sectionId = deleteConfig?.data?.name;
    sectionId = sectionId.split(" ")
    sectionId = sectionId[0]
    let index = deleteConfig?.index;
    let deletePanelData = false;
    if (index == 0) {
        deletePanelData = true;
    }

    return (prevModelJson) => {

        const updateModelArray = [...prevModelJson]
        const updatedModelJson = { ...updateModelArray[jsonIndex] };

        let parts = sectionId?.split("_");
        const hangingIndex = parts && parts?.length ? parts[parts?.length - 1] : null;


        const deleteSplitArray = (node, sectionId) => {
            if (!node.splits || node.splits.length === 0) return false;

            const lastSplit = node.splits[node.splits.length - 1];

            // Check if the last split contains the matching sectionId
            if (lastSplit.id === sectionId) {
                delete node.splits; // Remove the entire splits array
                return true;
            }

            // Recursively check nested splits
            for (const section of lastSplit.sections) {
                if (section.splits && deleteSplitArray(section, sectionId)) {
                    return true;
                }
            }

            return false;
        };


        if (updatedModelJson?.sash?.sections?.length) {
            let selectedSection = updatedModelJson?.sash?.sections[hangingIndex];
            selectedSection.transomCount -= 1;
            if (selectedSection && selectedSection?.splits?.length) {
                deleteSplitArray(selectedSection, sectionId);
            }
        }

        if (updatedModelJson?.transom && updatedModelJson?.transom?.splits?.length > 0) {
            const stack = [{ node: updatedModelJson }];
            while (stack.length > 0) {
                const { node } = stack.pop();
                if (node.transom) {
                    deleteSplitArray(node.transom, sectionId);
                }
            }
        }

        updateModelArray[jsonIndex] = updatedModelJson;

        updateData(sectionId, deletePanelData)

        return updateModelArray;
    };
};
