import {
  FormControl,
  FormControlLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
} from "material-ui-core";
import React, { useCallback, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import {
  MdOutlineColorLens,
  MdOutlineInsertChart,
  MdOutlineKeyboardArrowDown,
  MdOutlineSearch,
  MdOutlineTexture,
} from "react-icons/md";
import Slider from "react-rangeslider";
import { Row, Col, Button, Label, FormGroup, Input } from "reactstrap";
import { MenuProps } from "../../../../../../utility/data";
import {
  addUpdateColoursName,
  bumpList,
  getUniquePalette,
  chartTypeList,
  colorList,
  textureList,
} from "../../../../../../services/productServices";
import { useProductPaletteContext } from "../../../../../../context/productPaletteContext";
import { useEffect } from "react";
import { useMessage } from "../../../../../../context/messageContext";
import { useNavigate } from "react-router";
import { PhotoshopPicker } from "react-color";
import {
  convertCanvasToImageFile,
  denormalizeValue,
  normalizeValue,
  rgbStringToHex,
} from "../../../../../../utility/helper";
// import { denormalize } from "three/src/math/MathUtils";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import { handleError } from "../../../../../../utility/commonHelper";
// import { PhotoshopPicker, SketchPicker } from 'react-color';

const PaletteLeft = ({ setLoading, getAllColoursServices, allColours }) => {
  const {
    timestamp,
    newItemList,
    handleEdit,
    setTimestamp,
    activeIndex,
    handleDescEdit,
    handleModificationChange,
  } = useProductPaletteContext();

  const [radioType, setRadioType] = useState("chart");
  const [bumpDrop, setBumpDrop] = useState(1);
  const [textureDrop, setTextureDrop] = useState(0);
  const [chartType, setChartType] = useState(1);
  const [dualColor, setDualColor] = useState(1);
  const [roughness, setRoughness] = useState(0);
  const [bumpiness, setBumpiness] = useState(0);
  const [metalness, setMetalness] = useState(0);
  const [customerCheck, setCustomerCheck] = useState("fixed");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [defaultColor, setDefaultColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    hex: "F17013",
  });

  const [chartColorDrop, setChartColorDrop] = useState(0);
  const [bumpListDrop, setBumpListDrop] = useState([]);
  const [chartListDrop, setChartListDrop] = useState([]);
  const [colorListDrop, setColorListDrop] = useState([]);
  const [textureListDrop, setTextureListDrop] = useState([]);
  const [id, setId] = useState();
  const accessToken = localStorage.getItem("access_token");
  const [error, setError] = useState({ name: "", description: "" });
  const [isStockColour, setIsStockColour] = useState(false);
  const { setSelectedRightItem, selectedRightItem } = useRightContext();
  const { setMessage, setMessageType } = useMessage();
  let firstElId = allColours[0]?.colourPaletteId;
  const [unchangableList, setUnchangableList] = useState([]);
  const [searchedColor, setSearchedColor] = useState("");

  const navigate = useNavigate();

  let minSliderVal = 0;
  let maxSliderVal = 100;

  const chartDefaultColor = colorListDrop?.find(
    (item) => item?.id === chartColorDrop
  );

  const inputRef = useRef(null);

  useEffect(() => {
    if (firstElId) {
      if (allColours?.length && activeIndex !== 0) {
        setTimestamp(timestamp);
      } else {
        setTimestamp(firstElId);
      }
    }
  }, [allColours, timestamp]);

  useEffect(() => {
    if (searchedColor?.length > 0) {
      inputRef.current.focus();
    }
  }, [searchedColor]);

  useEffect(() => {
    if (timestamp) {
      getUniquePaletteServices(timestamp);
    }
  }, [timestamp]);

  useEffect(() => {
    if (radioType === "chart" && chartDefaultColor) {
      handleModificationChange(
        "hex",
        rgbStringToHex(chartDefaultColor?.rgbValue)
      );
    } else if (radioType === "customcolor" && defaultColor) {
      handleModificationChange("hex", defaultColor?.hex);
    }
  }, [radioType]);

  const reset = () => {
    getAllColoursServices();
    getUniquePaletteServices(timestamp);
  };

  // useEffect(() => {

  // }, [])

  // useEffect(() => {
  //     if (type && timestamp) {
  //         if (type !== existingType) {
  //             getUniquePaletteServices(timestamp)
  //         }
  //     }
  // }, [type])

  useEffect(() => {
    if (newItemList?.length === 0) {
      setName("");
      setDesc("");
      setTimestamp("");
    }
  }, [newItemList]);

  useEffect(() => {
    if (!bumpDrop) {
      handleBumpiness(0);
    }
  }, [bumpDrop]);

  useEffect(() => {
    bumpListServices();
    chartTypeServices();
    colorListServices();
    textureListServices();
  }, []);

  // useEffect(() => {
  //     if (firstElId) {
  //         if (allColours?.length && activeIndex !== 0) {
  //             getUniquePaletteServices(timestamp)
  //         } else {
  //             getUniquePaletteServices(firstElId)
  //         }
  //     }
  // }, [allColours])

  const handleRoughnessChange = (value) => {
    setRoughness(value);
    handleModificationChange(
      "roughness",
      normalizeValue(value, minSliderVal, maxSliderVal)
    );
  };

  const handleBumpiness = (value) => {
    setBumpiness(value);
    handleModificationChange(
      "bumpiness",
      normalizeValue(value, minSliderVal, maxSliderVal)
    );
  };

  const handelMetalness = (value) => {
    setMetalness(value);
    handleModificationChange(
      "metalness",
      normalizeValue(value, minSliderVal, maxSliderVal)
    );
  };

  const handleChartType = (e) => {
    const value = e.target.value;
    setChartType(typeof value === "string" ? value.split(",") : value);
  };

  const handleDualColor = (e) => {
    const value = e.target.value;
    setDualColor(typeof value === "string" ? value.split(",") : value);
  };

  const handleBump = (e) => {
    const value = e?.target?.value;
    const selectedBump = bumpListDrop?.find((item) => item?.id === value);
    setBumpDrop(typeof value === "string" ? value.split(",") : value);
    handleModificationChange("filePath", selectedBump?.imagePath);
  };

  const handleChartColor = (e) => {
    const value = e.target.value;

    if (value) {
      setChartColorDrop(typeof value === "string" ? value.split(",") : value);
      const selectedColor = colorListDrop.find((item) => item?.id === value);
      handleModificationChange("hex", rgbStringToHex(selectedColor?.rgbValue));
    }
  };

  const handleTexture = (e) => {
    const value = e.target.value;
    const selectedTexture = textureListDrop?.find((item) => item?.id === value);
    setTextureDrop(typeof value === "string" ? value.split(",") : value);
    handleModificationChange("texture", selectedTexture?.imagePath);
  };

  const handleColorPicker = (value) => {
    let rgb = value?.rgb;
    setDefaultColor({ r: rgb?.r, g: rgb?.g, b: rgb?.b, hex: value?.hex });
    handleModificationChange("hex", value?.hex);
  };

  // console.log(rgbStringToHex(chartDefaultColor?.rgbValue), "/", defaultColor?.hex);

  const addUpdateColorsServices = async () => {
    const capturedFile = convertCanvasToImageFile();

    const data = {
      id: timestamp,
      name: name,
      description: desc,
      type: radioType,
      chartId: radioType === "chart" ? chartType : "",
      chartColorType:
        customerCheck === "fixed" || radioType !== "chart" ? true : false,
      chartColorId: radioType === "chart" ? chartColorDrop : "",
      textureId: textureDrop,
      red: defaultColor?.r,
      green: defaultColor?.g,
      blue: defaultColor?.b,
      hex:
        radioType === "chart"
          ? rgbStringToHex(chartDefaultColor?.rgbValue)
          : defaultColor?.hex,
      roughness: normalizeValue(roughness, minSliderVal, maxSliderVal),
      metalness: normalizeValue(metalness, minSliderVal, maxSliderVal),
      bumpId: radioType === "chart" ? bumpDrop : 0,
      bumpiness:
        radioType === "chart"
          ? normalizeValue(bumpiness, minSliderVal, maxSliderVal)
          : 0,
      paletteType: 1,
      CapturedFile: capturedFile,
      StockColour: isStockColour,
    };

    setLoading(true);
    const res = await addUpdateColoursName(data, accessToken);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessage(res?.data?.message ? res.data.message : "Updated Palette");
        setMessageType("success");
        setLoading(false);
        // getAllColoursServices()
        getUniquePaletteServices(timestamp);
        getAllColoursServices("");
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
        // getAllColoursServices()
        getUniquePaletteServices(timestamp);
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      getAllColoursServices();
      handleError(res, false, navigate);
    }
  };

  const getUniquePaletteServices = async (timestamp) => {
    setLoading(true);
    const res = await getUniquePalette(accessToken, timestamp);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        const selectedRight = allColours?.find(
          (p) => p.colourPaletteId == timestamp
        );
        setSelectedRightItem(selectedRight);
        setName(res?.data.entity?.name);
        setDesc(
          res?.data.entity?.description ? res?.data.entity?.description : ""
        );

        setRadioType(res?.data.entity?.type);
        setChartType(res?.data.entity?.chartId ? res?.data.entity?.chartId : 1);

        setRoughness(
          denormalizeValue(
            res?.data.entity?.roughness,
            minSliderVal,
            maxSliderVal
          )
        );
        setBumpiness(
          denormalizeValue(
            res?.data.entity?.bumpiness,
            minSliderVal,
            maxSliderVal
          )
        );
        setMetalness(
          denormalizeValue(
            res?.data.entity?.metalness,
            minSliderVal,
            maxSliderVal
          )
        );
        setIsStockColour(res?.data?.entity?.stockColour?.toString() || false);
        // handelMetalness(res?.data.entity?.metalness)
        // handleBumpiness(res?.data.entity?.bumpiness)
        // handleRoughnessChange(res?.data.entity?.roughness)
        setBumpDrop(res?.data.entity?.bumpId);
        setTextureDrop(res?.data.entity?.textureId);
        setChartColorDrop(res?.data?.entity?.chartColourId || 202);

        setCustomerCheck(res?.data.entity?.chartColourType ? "fixed" : "any");
        setDefaultColor({
          r: res?.data?.entity?.red,
          g: res?.data?.entity?.green,
          b: res?.data?.entity?.blue,
          hex: res?.data.entity?.hex,
        });
        setLoading(false);
        setId(res?.data?.entity?.id);
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (e.target.value.length <= 51) {
      setName(value);
      handleEdit(value);
    }
  };

  const bumpListServices = async () => {
    const res = await bumpList(accessToken);
    setBumpListDrop(res?.data?.entity);
  };

  const chartTypeServices = async () => {
    const res = await chartTypeList(accessToken);
    setChartListDrop(res?.data?.entity);
  };

  const colorListServices = async () => {
    const res = await colorList(accessToken);
    setColorListDrop(res?.data?.entity);
    setUnchangableList(res?.data?.entity);
  };

  const textureListServices = async () => {
    const res = await textureList(accessToken);
    setTextureListDrop(res?.data?.entity);
  };

  const handleDescChange = (e) => {
    const value = e.target.value;
    if (e.target.value.length <= 51) {
      handleDescEdit(value);
      setDesc(value);
    }
  };

  const searchColor = useCallback(
    (setList, list, color, setColor) => {
      // setColor(color)

      if (color?.length > 0) {
        // setColor(color);

        let searchText = color.toLowerCase();

        const new_list = unchangableList.filter((obj) => {
          const nameMatch = obj.name.toLowerCase().includes(searchText);
          const rgbMatch = obj.rgbValue.includes(searchText);
          const numMatch = obj.number.includes(searchText);
          return nameMatch || rgbMatch || numMatch;
        });

        setList(new_list);
      }

      if (color.length === 0) {
        setList(unchangableList);
      }
    },
    [searchedColor]
  );

  return (
    <div className="d-flex flex-column h-100">
      {/* <h5 className='main_title'>Lorem ipsum dolor sit amet</h5> */}
      {/* <Nav tabs className="details_header border-0 nav-tabs-custom">
                <NavItem>
                    <NavLink
                        className={`${activeTab === "General" && 'active'}`}
                        onClick={() => noRefCheck("General")}
                    >
                        General
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "Discount" && 'active'}`}
                        onClick={() => noRefCheck("Discount")}
                    >
                        Discount
                    </NavLink>
                </NavItem>
            </Nav> */}
      {newItemList && newItemList?.length > 0 ? (
        <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
          <div className="inside_scroll has_btns has_head">
            <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
              {name && (
                <h5 className="mb-0 text-wrap text-break main_title">
                  {name?.slice(0, 50)}
                  {name?.length >= 50 && "..."}
                </h5>
              )}
            </div>
            {/* <div className='d-flex align-items-center mb-3'>
                        <span className='fs-3'>{name?.slice(0, 50)}{name?.length >= 50 && "..."}</span> <div></div>
                    </div> */}
            <Row>
              <Col lg="6">
                <FormGroup className="form-group">
                  <Label for="name">Name</Label>
                  <Input
                    disabled={!selectedRightItem?.isSelf}
                    className="mb-1"
                    name="name"
                    value={name}
                    onChange={(e) => handleNameChange(e)}
                  />
                  <div className="text-danger">{error.name}</div>
                  {name && name.length > 50 && (
                    <div className="mt-1 text-muted text-small">
                      Max 50 characters are allowed
                    </div>
                  )}

                  {!name && id && (
                    <div className="mt-1 text-danger text-small">
                      Name is required
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-group">
                  <Label for="description">Description</Label>
                  <Input
                    disabled={!selectedRightItem?.isSelf}
                    className="mb-1"
                    name="description"
                    value={desc}
                    onChange={(e) => handleDescChange(e)}
                  />
                  <div className="text-danger">{error.description}</div>
                  {desc && desc.length > 50 && (
                    <div className="mt-1 text-muted text-small">
                      Max 50 characters are allowed
                    </div>
                  )}
                </FormGroup>
              </Col>

              {/* <Col
                lg="6"
                className={`${!selectedRightItem?.isSelf ? "not_allowed" : ""}`}
              >
                <FormControl className="form-group">
                  <Label for="pageSize">Is Dual Colour ?</Label>

                  <RadioGroup
                    className="flex-row gap-4"
                    aria-labelledby="pageSize-label"
                    name="pageSize"
                    value={isStockColour}
                    onChange={(e) => setIsStockColour(e.target.value)}
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormControl>
              </Col> */}

              {/* <Col lg="6">
                <FormGroup className="form-group">
                  <Label for="chart">Select Dual Colour</Label>
                  <Select
                    labelId="printdoc-label"
                    id="chart"
                    disabled={!selectedRightItem?.isSelf}
                    value={dualColor}
                    label="chart"
                    onChange={handleDualColor}
                    input={
                      <OutlinedInput className="w-100" name="chart" />
                    }
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    <MenuItem value="Jet Black 9005 Matt">
                      Jet Black 9005 Matt
                    </MenuItem>

                    <MenuItem value="Anthracite Grey 7016">
                      Anthracite Grey 7016
                    </MenuItem>

                    <MenuItem value="Gray">
                      Gray
                    </MenuItem>
                  </Select>
                </FormGroup>
              </Col> */}

              <Col
                lg="6"
                className={`${!selectedRightItem?.isSelf ? "not_allowed" : ""}`}
              >
                <FormControl className="form-group">
                  <Label for="pageSize">Is stock colour ?</Label>

                  <RadioGroup
                    className="flex-row gap-4"
                    aria-labelledby="pageSize-label"
                    name="pageSize"
                    value={isStockColour}
                    onChange={(e) => setIsStockColour(e.target.value)}
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>

              <Col lg="12">
                <FormControl className="form-group">
                  <Label for="pageType">Type</Label>

                  <RadioGroup
                    aria-labelledby="pageType-label"
                    name="pageType"
                    className="flex-row gap-2 mt-3"
                    value={radioType}
                    onChange={(e) => setRadioType(e.target.value)}
                  >
                    <FormControlLabel
                      className="page_type_radio_2"
                      value="chart"
                      control={<Radio />}
                      label={
                        <div>
                          <span>
                            <MdOutlineInsertChart />
                          </span>
                          <span className="text-center">Chart</span>
                        </div>
                      }
                    />

                    {/* <FormControlLabel
                      className="page_type_radio_2"
                      value="imagetexture"
                      control={<Radio />}
                      label={
                        <div className="landscape">
                          <span>
                            <MdOutlineTexture />
                          </span>
                          <span className="text-center">Image Texture</span>
                        </div>
                      }
                    /> */}

                    <FormControlLabel
                      className="page_type_radio_2"
                      value="customcolor"
                      control={<Radio />}
                      label={
                        <div className="landscape">
                          <span>
                            <MdOutlineColorLens />
                          </span>
                          <span className="text-center">Custom Color</span>
                        </div>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
              {radioType === "chart" && (
                <>
                  <Col lg="12">
                    <Row className="align-items-center">
                      <Col lg="6">
                        <FormGroup className="form-group">
                          <Label for="chart">Chart</Label>
                          <Select
                            labelId="printdoc-label"
                            id="chart"
                            disabled={!selectedRightItem?.isSelf}
                            value={chartType}
                            label="chart"
                            onChange={handleChartType}
                            input={
                              <OutlinedInput className="w-100" name="chart" />
                            }
                            MenuProps={MenuProps}
                            IconComponent={() => (
                              <MdOutlineKeyboardArrowDown
                                size={20}
                                className="me-1 MuiSvgIcon-root MuiSelect-icon"
                              />
                            )}
                          >
                            {chartListDrop.map((item, index) => {
                              return (
                                <MenuItem value={item.id} key={index}>
                                  {item?.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormGroup>
                      </Col>
                      {radioType === "chart" && (
                        <Col
                          lg="6"
                          className={`${!selectedRightItem?.isSelf ? "not_allowed" : ""
                            }`}
                        >
                          {/* <div className="mb-2">
                                                    <Input
                                                        type="radio"
                                                        name="selection-radio"
                                                        id="fixed"
                                                        value="fixed"
                                                        checked={customerCheck === 'fixed'}
                                                        onChange={(e) => setCustomerCheck(e.target.value)}
                                                    />
                                                    <span className={`ms-2`}>Fixed</span>
                                                </div>
                                                <FormGroup>
                                                    <Input
                                                        type="radio"
                                                        name="selection-radio"
                                                        id="any"
                                                        value="any"
                                                        checked={customerCheck === 'any'}
                                                        onChange={(e) => setCustomerCheck(e.target.value)}
                                                    />
                                                    <span className={`ms-2`}>Any (User Chooses)</span>
                                                </FormGroup> */}

                          <FormControl className="mb-2 w-100">
                            <RadioGroup
                              aria-labelledby="customerInfoSelection-label"
                              name="customerInfoSelection"
                              className=""
                              value={customerCheck}
                              onChange={(e) => setCustomerCheck(e.target.value)}
                            >
                              <FormControlLabel
                                value="fixed"
                                control={<Radio />}
                                label="Fixed"
                              />
                              <FormControlLabel
                                className=""
                                value="any"
                                control={<Radio />}
                                label="Any (User Chooses)"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  {customerCheck === "fixed" && (
                    <Col lg="6">
                      <FormGroup className="form-group modal_select">
                        <Label for="colour">Colour</Label>

                        <Select
                          labelId="printdoc-label"
                          id="colour"
                          disableCloseOnSelect
                          disabled={!selectedRightItem?.isSelf}
                          value={chartColorDrop}
                          label="colour"
                          onChange={(e) => handleChartColor(e)}
                          input={
                            <OutlinedInput className="w-100" name="colour" />
                          }
                          onClose={() => {
                            setSearchedColor("");
                            setColorListDrop(unchangableList);
                          }}
                          MenuProps={MenuProps}
                          IconComponent={() => (
                            <MdOutlineKeyboardArrowDown
                              size={20}
                              className="me-1 MuiSvgIcon-root MuiSelect-icon"
                            />
                          )}
                        >
                          <ListSubheader>
                            <InputGroup className="border rounded w-100 text-black">
                              <Input
                                // type="text"
                                ref={inputRef}
                                placeholder="Search colour"
                                className="search-input border-0"
                                value={searchedColor || ""}
                                onChange={(e) => {
                                  inputRef.current.focus();
                                  setSearchedColor(e.target.value);
                                  searchColor(
                                    setColorListDrop,
                                    colorListDrop,
                                    e.target.value,
                                    setSearchedColor
                                  );
                                }}
                                onClick={(e) => e.stopPropagation()} // Prevent default click behavior
                              />
                              <div className="input-group-append">
                                <span className="input-group-text bg-transparent border-0 px-2">
                                  <MdOutlineSearch size={16} />
                                </span>
                              </div>
                            </InputGroup>
                          </ListSubheader>

                          {colorListDrop.map((item, index) => {
                            return (
                              <MenuItem value={item?.id} key={index}>
                                <div className="d-flex align-items-center dropdown_with_icon">
                                  <span
                                    className="me-2 modal_select_hide color_circle dot_lg"
                                    style={{
                                      backgroundColor: `${item.rgbValue}`,
                                      borderRadius: "50%",
                                      width: "fit-content",
                                    }}
                                  ></span>

                                  <div className="d-flex flex-column w-100">
                                    <span className="lh-1 d-flex gap-1 align-items-center">
                                      {item?.number}

                                      <span className="modal_select_show">
                                        <span className="mx-1">-</span>{" "}
                                        {item?.name}
                                      </span>
                                    </span>
                                    <span className="text-small lh-1 modal_select_hide">
                                      {item?.name}
                                    </span>
                                  </div>
                                </div>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormGroup>
                    </Col>
                  )}
                </>
              )}
              {/* boundary chart */}
              {radioType === "imagetexture" && (
                <Col lg="6">
                  <FormGroup className="form-group">
                    <Label for="texture">Texture</Label>
                    <Select
                      labelId="printdoc-label"
                      id="texture"
                      value={textureDrop}
                      label="texture"
                      disabled={!selectedRightItem?.isSelf}
                      onChange={handleTexture}
                      input={<OutlinedInput className="w-100" name="texture" />}
                      MenuProps={MenuProps}
                      IconComponent={() => (
                        <MdOutlineKeyboardArrowDown
                          size={20}
                          className="me-1 MuiSvgIcon-root MuiSelect-icon"
                        />
                      )}
                    >
                      {textureListDrop?.map((item, index) => {
                        return (
                          <MenuItem value={item?.id} key={index}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormGroup>
                </Col>
              )}

              {radioType === "imagetexture" || radioType === "chart" ? (
                <Col lg="6">
                  <FormGroup className="form-group">
                    <Label for="bump">Bump</Label>
                    <Select
                      labelId="printdoc-label"
                      id="bump"
                      disabled={!selectedRightItem?.isSelf}
                      value={bumpDrop}
                      label="bump"
                      onChange={handleBump}
                      input={<OutlinedInput className="w-100" name="bump" />}
                      MenuProps={MenuProps}
                      IconComponent={() => (
                        <MdOutlineKeyboardArrowDown
                          size={20}
                          className="me-1 MuiSvgIcon-root MuiSelect-icon"
                        />
                      )}
                    >
                      <MenuItem value={0}>None</MenuItem>
                      {bumpListDrop?.map((item, index) => {
                        return (
                          <MenuItem value={item?.id} key={index}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormGroup>
                </Col>
              ) : (
                <></>
              )}

              {radioType === "customcolor" && (
                <Col lg="12">
                  <FormGroup
                    className={`${!selectedRightItem?.isSelf ? "not_allowed" : ""
                      }`}
                  >
                    <div className="palette-color-picker">
                      <PhotoshopPicker
                        onChange={handleColorPicker}
                        disableAlpha={true}
                        color={defaultColor}
                      />
                    </div>
                  </FormGroup>
                </Col>
              )}

              <Col lg="6">
                <FormGroup
                  className={`${!selectedRightItem?.isSelf && "opacity-75 pointer_none"
                    }`}
                >
                  <Label>Roughness</Label>
                  <div className="d-flex align-items-center">
                    <Slider
                      min={0}
                      max={100}
                      value={roughness}
                      className="w-75"
                      onChange={handleRoughnessChange}
                    />
                    <span className="ms-3">{roughness}</span>
                  </div>
                </FormGroup>
              </Col>

              {radioType !== "customcolor" && (
                <Col lg="6">
                  <FormGroup
                    className={`${(bumpDrop === 0 || !selectedRightItem?.isSelf) &&
                      "opacity-75 pointer_none"
                      }`}
                  >
                    <Label>Bumpiness</Label>
                    <div className="d-flex align-items-center">
                      <Slider
                        min={0}
                        max={100}
                        value={bumpiness}
                        className="w-75"
                        onChange={handleBumpiness}
                      />
                      <span className="ms-3">{bumpiness}</span>
                    </div>
                  </FormGroup>
                </Col>
              )}
              <Col lg="6">
                <FormGroup
                  className={`${!selectedRightItem?.isSelf && "opacity-75 pointer_none"
                    }`}
                >
                  <Label>Metalness</Label>
                  <div className="d-flex align-items-center">
                    <Slider
                      min={0}
                      max={100}
                      value={metalness}
                      className="w-75"
                      onChange={handelMetalness}
                    />
                    <span className="ms-3">{metalness}</span>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </div>
          {selectedRightItem?.isSelf && (
            <div className="d-flex gap-2 gap-xl-3 main_buttons">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={() => addUpdateColorsServices()}
                disabled={
                  newItemList?.length === 0 ||
                  desc?.length > 50 ||
                  name?.length > 50 ||
                  !name
                }
              >
                Save Changes
              </Button>

              <Button
                color="primary"
                size="md"
                outline
                className="px-4"
                onClick={() => reset()}
                disabled={
                  newItemList?.length === 0 ||
                  desc?.length > 50 ||
                  name?.length > 49 ||
                  !name
                }
              >
                Reset
              </Button>
            </div>
          )}
        </Form>
      ) : (
        <div className="align-items-center m-auto">
          <h4>No Data to Show</h4>
        </div>
      )}
    </div>
  );
};

export default PaletteLeft;
