import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
} from "material-ui-core";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MenuProps } from "../../../../../../utility/data";
import { getAllColoursV2 } from "../../../../../../services/productServices";
import { handleError } from "../../../../../../utility/commonHelper";
import { useNavigate } from "react-router-dom";

const DualColourLeft = ({
  setLoading,
  selectItemDetails,
  updateItemDetailsServices,
  handleFormReset,
  accessToken,
  allRightListItems,
}) => {
  const { timestamp, selectedRightItem, selected, setSelected, handleEdit } =
    useRightContext();

  const [name, setName] = useState("");
  const [isStockColour, setIsStockColour] = useState(false);
  const [colourOne, setColourOne] = useState("");
  const [colourTwo, setColourTwo] = useState("");
  const [colourListing, setColourListing] = useState([]);

  const [nameError, setNameError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getColourListing();
  }, []);

  useEffect(() => {
    setLoading(false);
    setSelected(selectItemDetails?.name || "");
    setName(selectItemDetails?.name || "");
    setIsStockColour(selectItemDetails?.stockColour === true ? "Yes" : "No");
    if (colourListing?.length > 0) {
      let colourOne = colourListing?.find(
        (item) => item?.elementId === selectItemDetails?.firstColour
      )?.elementId;
      let colourTwo = colourListing?.find(
        (item) => item?.elementId === selectItemDetails?.secondColour
      )?.elementId;

      setColourOne(colourOne || "");
      setColourTwo(colourTwo || "");
    }

    setNameError(false);
  }, [selectItemDetails]);

  const getColourListing = async () => {
    const res = await getAllColoursV2(accessToken);
    if (res.statusCode === 200) {
      setColourListing(res?.entity);
    } else {
      handleError(res, false, navigate);
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;

    if (e.target.value?.length <= 50) {
      setName(value);
      handleEdit(value);
      setSelected(value);
    }

    if (value.length > 50) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  const handleFormSubmit = () => {
    const formDataValues = {
      id: selectItemDetails?.id,
      isStockColour: isStockColour == "Yes" ? true : false,
      colourOne: colourOne,
      colourTwo: colourTwo,
      name: name,
    };

    updateItemDetailsServices(formDataValues);
  };

  const handleStockColour = (e) => {
    const value = e.target.value;
    setIsStockColour(value);
  };

  const getFilteredOptions = (excludeColour) => {
    return colourListing.filter(
      (colour) => colour?.elementId !== excludeColour
    );
  };

  return (
    <div className="d-flex flex-column h-100">
      {allRightListItems?.length > 0 ? (
        <>
          <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
            {selected && (
              <h5 className="mb-0 text-wrap text-break me-4 main_title">
                {selected}
              </h5>
            )}
          </div>

          <Form
            className={`h-100 d-flex flex-column flex-grow-1 justify-content-between ${
              selectedRightItem?.isSelf ? "" : "not_allowed"
            }`}
          >
            <div className="inside_scroll has_btns has_head">
              <Row>
                <Col lg="6" md="8">
                  <FormGroup className="form-group">
                    <Label for="name">Name</Label>
                    <Input
                      className="mb-1"
                      name="name"
                      value={name}
                      onChange={(e) => handleNameChange(e)}
                    />

                    {nameError && timestamp && (
                      <div className="mt-1 text-muted text-small">
                        Max 50 characters are allowed
                      </div>
                    )}

                    {!name && timestamp && (
                      <div className="mt-1 text-danger text-small">
                        Name is required
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6" md="8">
                  <FormGroup className="form-group">
                    <Label for="isStockColour">Is Stock Colour ?</Label>

                    <FormControl className="mb-0 w-100">
                      <RadioGroup
                        aria-labelledby="feature-label"
                        name="isStockColour"
                        className="flex-row flex-wrap custom_x_gap"
                        value={isStockColour}
                        onChange={(e) => handleStockColour(e)}
                      >
                        <FormControlLabel
                          value={"Yes"}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={"No"}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="8">
                  <FormGroup className="form-group">
                    <Label for="postion">Colour One</Label>
                    <Select
                      labelId="printdoc-label"
                      id="colour1"
                      value={colourOne || ""}
                      label="colour1"
                      onChange={(e) => {
                        setColourOne(e.target.value);
                      }}
                      input={<OutlinedInput className="w-100" name="colour1" />}
                      MenuProps={MenuProps}
                      IconComponent={() => (
                        <MdOutlineKeyboardArrowDown
                          size={20}
                          className="me-1 MuiSvgIcon-root MuiSelect-icon"
                        />
                      )}
                    >
                      {colourListing &&
                        getFilteredOptions(colourTwo)?.map((item, index) => (
                          <MenuItem key={index} value={item.elementId}>
                            {item.paletteName}
                          </MenuItem>
                        ))}
                    </Select>
                    {!colourOne && (
                      <div className="mt-1 text-danger text-small">
                        Colour One is required
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6" md="8">
                  <FormGroup className="form-group">
                    <Label for="postion">Colour Two</Label>
                    <Select
                      labelId="printdoc-label"
                      id="colour2"
                      value={colourTwo || ""}
                      label="colour2"
                      onChange={(e) => {
                        setColourTwo(e.target.value);
                      }}
                      input={<OutlinedInput className="w-100" name="colour2" />}
                      MenuProps={MenuProps}
                      IconComponent={() => (
                        <MdOutlineKeyboardArrowDown
                          size={20}
                          className="me-1 MuiSvgIcon-root MuiSelect-icon"
                        />
                      )}
                    >
                      {colourListing &&
                        getFilteredOptions(colourOne)?.map((item, index) => (
                          <MenuItem key={index} value={item.elementId}>
                            {item.paletteName}
                          </MenuItem>
                        ))}
                    </Select>
                    {!colourTwo && (
                      <div className="mt-1 text-danger text-small">
                        Colour Two is required
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>

            {/* Start: space for buttons at the bottom */}
            {selectedRightItem?.isSelf && (
              <div className="d-flex gap-2 gap-xl-3 main_buttons">
                <Button
                  color="primary"
                  size="md"
                  className="px-4"
                  onClick={() => handleFormSubmit()}
                  disabled={
                    !timestamp || !name.trim() || !colourOne || !colourTwo
                  }
                >
                  Save Changes
                </Button>

                <Button
                  color="primary"
                  size="md"
                  outline
                  className="px-4"
                  disabled={!timestamp}
                  onClick={() => handleFormReset()}
                >
                  Reset
                </Button>
              </div>
            )}
            {/* End: space for buttons at the bottom */}
          </Form>
        </>
      ) : (
        <h5 className="my-4 text-center text-muted w-100">No data found</h5>
      )}
    </div>
  );
};

export default DualColourLeft;
