import * as THREE from 'three';
import { createShapeFromWidthHeight } from './utils/threeUtils'

export const storeSill = (sillData, jsonIndex = 0) => {
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson];
        const updatedModel = { ...updatedModelArray[jsonIndex] };

        const height = sillData?.height;
        const width = sillData?.width;
        const id = sillData?.id

        if (updatedModel.sill) {
            updatedModel.sill.width = width;
            updatedModel.sill.height = height;
            updatedModel.sill.id = id
        }
        updatedModelArray[jsonIndex] = updatedModel;
        return updatedModelArray;
    }
}

export const updateHornLengthData = (length, jsonIndex = 0) => {
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updatedModel = { ...updatedModelArray[jsonIndex] };
        updatedModel.sill.hornLength = length;
        updatedModelArray[jsonIndex] = updatedModel;
        return updatedModelArray;
    }
}

export const removeSillData = (
  setCustomModelData,
  jsonIndex = 0,
  setFrameObject
) => {
  setFrameObject();
  setCustomModelData((prevData) => ({
    ...prevData,
    frame: {
      ...prevData?.frame,
      sill: {
        ...prevData?.frame?.sill,
        sillData: {},
        hornLength: 0,
      },
    },
  }));

  return (prevModelJson) => {
    const updatedModelArray = [...prevModelJson];
    const updatedModel = { ...updatedModelArray[jsonIndex] };
    updatedModel.sill = {
      width: 0,
      height: 0,
      hornLength: 0,
    };
    updatedModelArray[jsonIndex] = updatedModel;
    return updatedModelArray;
  };

  //   setData((prevModelJson) => ({
  //     ...prevModelJson,
  //     sill: {
  //       width: 0,
  //       height: 0,
  //       hornLength: 0,
  //     },
  //   }));
};


export function addSill(data, sillRef, sceneRef, frameStyleBottom, setDefaultSillScale, sillsHeightRef, sillExtRef) {

    const { color, colorExt } = data

    const { height, hornLength } = data?.sill


    let sillDepth = data?.sill?.width / 1000;
    let sillHeight = height / 1000;
    const sillHorn = hornLength ? hornLength * 0.01 : 0
    let widthOffset = 0;
    sillsHeightRef.current.push(sillHeight)


    const boundingBox = new THREE.Box3().setFromObject(frameStyleBottom.current);
    // const width = boundingBox.max.x - boundingBox.min.x;
    const width = data.dimensions.width;
    const botDepth = boundingBox.max.z - boundingBox.min.z;
    const sillPathStart = new THREE.Vector3(-width / 2, boundingBox.min.y, 0);
    const sillPathEnd = new THREE.Vector3(width / 2, boundingBox.min.y, 0);

    const sillPath = new THREE.LineCurve3(sillPathStart, sillPathEnd);
    const sillShape = createShapeFromWidthHeight(sillDepth, sillHeight);
    const extrudeSettings = {
        steps: 2,
        bevelEnabled: true,
        extrudePath: sillPath,
        bevelSegments: 3,
        bevelSize: 0.001,
        bevelThickness: 0.1
    };
    const material = new THREE.MeshPhongMaterial({
        color: colorExt,
        specular: 0xffffff,
        shininess: 10,
        // shininess: 27.5,
        flatShading: false,
        emissive: 0x000000,
        specular: 0x555555,
        bumpScale: 1,
    });
    const frameMaterial_Ext = new THREE.MeshPhongMaterial({
        color: color,
        // color: 0xff0ff0,
        specular: 0xffffff,
        shininess: 10,
        flatShading: false,
        emissive: 0x000000,
        specular: 0x888888,
        bumpScale: 2,
    });

    if (sillRef?.current) {
        sceneRef?.remove(sillRef.current);
    }

    if (sillExtRef?.current) {
        sceneRef?.remove(sillExtRef.current);
    }


    const sillGeometry = new THREE.ExtrudeGeometry(sillShape, extrudeSettings);
    const exmesh = new THREE.Mesh(sillGeometry, material);
    
    exmesh.name = "sill";
    sillRef.current = exmesh;
    if (sillHorn) {
        exmesh.scale.x += sillHorn
    }
    setDefaultSillScale(exmesh.scale.x)
    sceneRef?.add(exmesh);
    const boundingBox2 = new THREE.Box3().setFromObject(exmesh);
    exmesh.position.z -= boundingBox2.min.z - boundingBox.min.z;
    let externalColor = true;
    if (externalColor) {
        const extMesh = exmesh.clone();
        extMesh.material = frameMaterial_Ext;
        extMesh.position.z -= 0.1 / 1000;
        const offsetVal = 1 / 1000;
        sillGeometry.computeBoundingBox();
        const beforeBound = sillGeometry.boundingBox;
        const beforeWidth = beforeBound.max.x - beforeBound.min.x;
        const beforeHeight = beforeBound.max.y - beforeBound.min.y;
        const xScaleVal = (beforeWidth + offsetVal) / beforeWidth;
        const yScaleVal = (beforeHeight + offsetVal) / beforeHeight;
        const xCenter = (beforeBound.max.x + beforeBound.min.x) / 2;
        const xOffset = ((xCenter * (xScaleVal)) - xCenter) / 1;
        const yCenter = (beforeBound.max.y + beforeBound.min.y) / 2;
        const yOffset = ((yCenter * (yScaleVal)) - yCenter) / 1;
        extMesh.scale.x += offsetVal;
        extMesh.position.x -= xOffset;
        extMesh.position.y -= offsetVal;
        extMesh.name = "sillExt";
        sillExtRef.current = extMesh;
        sceneRef?.add(extMesh);
    }

}

export const removeSill = (sillRef, sceneRef, sillExtRef) => {
    if (sillRef.current) {
        sceneRef.current.remove(sillRef.current);
    }
    if (sillExtRef.current) {
        sceneRef.current.remove(sillExtRef.current);
    }	
}

export const sillHornLength = (sillRef, sillExtRef, data, defaultScale) => {
    const { hornLength } = data?.sill;

    if (!sillRef.current) {
        return
    }
    const scalingFactor = hornLength / 1000;
    sillRef.current.scale.x = defaultScale + scalingFactor;
    if(sillExtRef.current)sillExtRef.current.scale.x = defaultScale + scalingFactor + 0.001;
}
