import React from "react";
import { MdPersonOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Button, Label, FormGroup } from "reactstrap";
import NumberInput from "../NumberInput";
import { useState } from "react";
import { useDesigner } from "../../context/designerContext";
import { useMessage } from "../../context/messageContext";
import { getTrasomsFrameService } from "../../services/3dModelServices";
import { useEffect } from "react";
import { BiTrash } from "react-icons/bi";
import { GoCircleSlash } from "react-icons/go";
import {
  saveModelData,
  saveModelDataQuotation,
} from "../../utility/designerHelper";
import { handleError } from "../../utility/commonHelper";

const FrameObject = ({
  frameData,
  setFrameData,
  modelId,
  setLoading,
  frameStyleProdId,
  quotationId,
  receivedProductId,
  setHardwareType,
}) => {
  const accessToken = localStorage.getItem("access_token");

  const {
    handleFrameObject,
    activeIndex,
    setActiveIndex,
    setHornLength,
    hornLength,
    setFrameObjectType,
    setFrameObject,
    frameObject,
    setCustomModelData,
    customModelData,
    modelJson,
    setFrameObjectSaving,
    deleteFrameObject,
    setDeleteFrameObject,
  } = useDesigner();

  const navigate = useNavigate();

  const { setMessage, setMessageType } = useMessage();
  const [sillList, setSillList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (
      frameData?.name === "Sill" ||
      frameData?.obj === "Sill" ||
      frameData.id >= 2
    ) {
      if (frameData?.name === "Sill") {
        setHardwareType("");
        setFrameObjectType(frameData?.name);
      } else {
        setFrameObjectType(frameData?.obj);
      }
      sillListServices(modelId);
    }
  }, [frameData]);

  // This will get and show the saved sill and horn length
  useEffect(() => {
    if (customModelData?.frame?.sill && sillList?.length > 0) {
      if (customModelData?.frame?.sill?.sillData) {
        let index = sillList?.findIndex(
          (item) => item?.id == customModelData?.frame?.sill?.sillData?.id
        );
        if (index) {
          setActiveIndex(index);
        } else {
          setActiveIndex(-1);
        }
      }
      if (customModelData?.frame?.sill?.hornLength !== 0) {
        setHornLength(customModelData?.frame?.sill?.hornLength);
      } else if (hornLength === 0) {
        setHornLength(0);
      }
    }
  }, [customModelData, sillList]);

  useEffect(() => {
    if (isSaving) {
      setTimeout(() => {
        setIsSaving(false);
        setFrameObjectSaving(false);
      }, 100);
    }
  }, [isSaving]);

  useEffect(() => {
    if (deleteFrameObject) {
      setTimeout(() => {
        setDeleteFrameObject(false);
      }, 200);
    }
  }, [deleteFrameObject]);

  useEffect(() => {
    if (isSaving && customModelData && modelId && frameStyleProdId?.id) {
      if (quotationId && receivedProductId) {
        saveModelDataQuotation(
          accessToken,
          modelId,
          frameStyleProdId?.id,
          customModelData,
          quotationId,
          receivedProductId,
          navigate,
          setMessage,
          setMessageType,
          modelJson
        );
      } else {
        saveModelData(
          accessToken,
          modelId,
          frameStyleProdId?.id,
          customModelData,
          modelJson,
          navigate,
          setMessage,
          setMessageType
        );
      }
    }
  }, [isSaving]);

  // This is for getting sill data with price
  const sillListServices = async (fetchId) => {
    const res = await getTrasomsFrameService(accessToken, fetchId, 9);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setSillList(res?.data?.entity?.sill);
      } else {
        setMessage(res?.data?.message);
        setMessageType("error");
      }
    } else {
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      setSillList([]);
      handleError(res, false, navigate);
    }
  };

  const saveSill = () => {
    if (frameObject) {
      setCustomModelData((prevModelData) => ({
        ...prevModelData,
        frame: {
          ...prevModelData.frame,
          sill: {
            ...prevModelData.frame.sill,
            sillData: {
              ...frameObject,
              orientation: "Bottom",
              frameType: "Sill",
            },
            hornLength: hornLength,
          },
        },
      }));
    } else {
      setCustomModelData((prevModelData) => ({
        ...prevModelData,
        frame: {
          ...prevModelData.frame,
          sill: {
            ...prevModelData.frame.sill,
            sillData: {},
            hornLength: "0",
          },
        },
      }));
    }
    // For closing the sill listing
    setTimeout(() => {
      setFrameData();
    }, 200);
  };

  const handleSaveData = () => {
    saveSill();
    setIsSaving(true);
    setFrameObjectSaving(true);
  };

  const getImage = (image) => {
    if (image && image?.includes("svg")) {
      const svgDataUri = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
        image
      )}`;
      return svgDataUri;
    } else {
      return image;
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-2 left_header">
        <h5 className="mb-0 text-wrap text-break me-4 main_title">Sill</h5>{" "}
        <div></div>
      </div>
      <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="sill_list_scroll">
          <div className="panel_object_list mb-3">
            {sillList &&
              sillList?.length > 0 &&
              sillList.map((item, index) => {
                return (
                  <div
                    className="position-relative d-flex align-items-center right_item_wrap has_delete"
                    key={index}
                    onClick={() => {
                      handleFrameObject(item, index);
                      // handleSaveData();
                    }}
                  >
                    <div
                      className={`right_item w-100 ${
                        activeIndex === index && "active"
                      } `}
                    >
                      <span className="me-2 image_icon">
                        {item.width > 0 && item.height > 0 ? (
                          <img src={getImage(item?.imagePath)} />
                        ) : (
                          <GoCircleSlash size={40} color="#989898" />
                        )}
                      </span>
                      <div className="right_title">
                        <span className="text-truncate">{item?.name}</span>
                        {/* <span className="text-truncate d-block text-small">{item.description}</span> */}
                      </div>
                    </div>
                    <span className="position-absolute action_btns"></span>
                  </div>
                );
              })}
          </div>

          <FormGroup className="form-group mb-1">
            <Label for="hornLength" className="text-dark">
              Horn Length
            </Label>

            <NumberInput
              name="hornLength"
              value={hornLength}
              setValue={setHornLength}
            />
          </FormGroup>

          <Button
            color="light"
            size="md"
            className="px-4 w-100 d-flex justify-content-between fw-medium mb-3"
            onClick={() => setDeleteFrameObject(true)}
          >
            <BiTrash size={20} />
            Delete
            <span></span>
          </Button>
        </div>
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={() => handleSaveData()}
          >
            Save
          </Button>
          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            onClick={() => {
              setFrameData();
              if (
                customModelData?.frame?.sill?.sillData &&
                Object.entries(customModelData?.frame?.sill?.sillData)
                  .length !== 0
              ) {
                setFrameObject(customModelData?.frame?.sill?.sillData);
              } else {
                setDeleteFrameObject(true);
                setFrameObject();
              }
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FrameObject;
