// combinedOutline.js
import * as THREE from 'three';
import { createFramePath } from './lineShapes.js'; // Import the createFramePath function
import { createCornerPath } from './cornerShapes.js'; // Import the createCornerPath function

// Function to create a combined outline path for the entire frame and corners
export function createCombinedOutlinePath(windowData, wHeightOffset, wWidthOffset, wLoffset, wTopOffset, wBOffset) {
    const { frame } = windowData; // Extract frame data
    const outlinePath = new THREE.Shape(); // Create a new THREE.Path to hold the combined outline

    // List of path and corner types in sequence
    const pathsAndCorners = [
        { pathType: 'top', cornerType: 'topRight' },
        { pathType: 'right', cornerType: 'bottomRight' },
        { pathType: 'bottom', cornerType: 'bottomLeft' },
        { pathType: 'left', cornerType: 'topLeft' }
    ];

    // Helper variable to track the starting point of each segment
    let startPoint = null;

    pathsAndCorners.forEach(({ pathType, cornerType }) => {

        const framePath = createFramePath(windowData, pathType, frame.corners[cornerType].type, wHeightOffset, wWidthOffset, wLoffset, wTopOffset, wBOffset);
        
        const cornerPath = createCornerPath(windowData, cornerType, frame.corners[cornerType].type, wHeightOffset, wWidthOffset, wLoffset, wTopOffset, wBOffset);

        if (framePath) {
            // Get points from the frame path
            const framePoints = framePath.getPoints(30); // Get 10 points for a smoother line
            if (framePoints.length > 0) {
                if (!startPoint) {
                    // Move to the starting point of the first segment
                    outlinePath.moveTo(framePoints[0].x, framePoints[0].y);
                    startPoint = framePoints[0];
                }
                // Add each point in the frame path to the outline
                framePoints.forEach((point) => outlinePath.lineTo(point.x, point.y));
            }
        }

        if (cornerPath) {
            // Get points from the corner path
            const cornerPoints = cornerPath.getPoints(30); // Get 10 points for a smoother curve
            // Add each point in the corner path to the outline
            cornerPoints.forEach((point) => outlinePath.lineTo(point.x, point.y));
        }
    });

    // Close the path to form a complete loop
    outlinePath.closePath();

    return outlinePath;
}