// lineShapes.js
import * as THREE from 'three';

// Function to create a line as a path between two points
export function createLinePath(start, end) {
    const curve = new THREE.LineCurve3(start, end);
    return curve;
}

// Function to create a frame line as a path based on the line position (top, right, bottom, left)
export function createFramePath(frameData, linePosition, cornerStyle, wHeightOffset, wWidthOffset, wLoffset, wTopOffset, wBOffset) {
    // let sillHeight = 0;
    // if(sillsHeightRef && sillsHeightRef.current){
    //     console.log("sillsHeightRef :::: ", sillsHeightRef.current);
    //     sillsHeightRef.current = [0.04];
    //     sillHeight = sillsHeightRef.current.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    // }

    const { dimensions, frame } = frameData;
    const corners = frame.corners;
    let { width, height } = dimensions;
    // console.log("wLoffset :::: ", wLoffset);
    

    width -= typeof wWidthOffset == 'object' ? wWidthOffset.current.reduce((accumulator, currentValue) => accumulator + currentValue, 0) : wWidthOffset;
    height -= typeof wHeightOffset == 'object' ? wHeightOffset.current.reduce((accumulator, currentValue) => accumulator + currentValue, 0) : wHeightOffset;

    // const cornerMulti = cornerStyle == "Corner" ? 0 : cornerStyle == "corner" ? 0 : 1;
    const cornerMulti = 1;

    let start, end;
    wLoffset += -wWidthOffset/2
    wTopOffset *= -1/2;
    wTopOffset += wBOffset/2;
    // wTopOffset -= wHeightOffset/2
    // wTopOffset -= 0
    // Adjust start and end points to be based on the top base
    switch (linePosition) {
        case 'top':
            // Top path should consider the top-left and top-right corner sizes
            start = new THREE.Vector3(-(width / 2) + wLoffset + (corners.topLeft.size.width * cornerMulti), (height / 2) + wTopOffset, 0);
            end = new THREE.Vector3((width/2)  + wLoffset - (corners.topRight.size.width * cornerMulti), (height / 2) + wTopOffset, 0);
            break;
        case 'right':
            // Right path should consider top-right and bottom-right corner sizes
            start = new THREE.Vector3((width/2)  + wLoffset, (height / 2) + wTopOffset - (corners.topRight.size.height * cornerMulti), 0);
            end = new THREE.Vector3((width/2)  + wLoffset, -(height / 2) + wTopOffset + (corners.bottomRight.size.height * cornerMulti), 0);
            break;
        case 'bottom':
            // Bottom path should consider bottom-right and bottom-left corner sizes
            start = new THREE.Vector3((width/2)  + wLoffset - (corners.bottomRight.size.width * cornerMulti), -(height / 2) + wTopOffset, 0);
            end = new THREE.Vector3(-(width/2)  + wLoffset + (corners.bottomLeft.size.width * cornerMulti), -(height / 2) + wTopOffset, 0);
            break;
        case 'left':
            // Left path should consider top-left and bottom-left corner sizes
            start = new THREE.Vector3(-(width/2)  + wLoffset, -(height / 2) + wTopOffset + (corners.bottomLeft.size.height * cornerMulti), 0);
            end = new THREE.Vector3(-(width/2)  + wLoffset, (height / 2) + wTopOffset - (corners.topLeft.size.height * cornerMulti), 0);
            break;
        default:
            console.error(`Invalid line position: ${linePosition}`);
            return null;
    }

    // Debugging: Check if the start or end points have NaN values
    if (isNaN(start.x) || isNaN(start.y) || isNaN(end.x) || isNaN(end.y)) {
        console.error(`Invalid path points for line position "${linePosition}": Start - ${start.toArray()}, End - ${end.toArray()}`);
        return null;
    }

    return createLinePath(start, end);
}



export function createSashPath(width, height, linePosition, offset, offsetY = 0, wTopOffset, wBOffset) {
    let start, end;
    wTopOffset *= -1/2;
    wTopOffset += wBOffset/2;
    wTopOffset += offsetY;
    switch (linePosition) {
        case 'SashTop':
            // Top path should consider the top-left and top-right corner sizes
            start = new THREE.Vector3(-width / 2 + offset, (height / 2) + wTopOffset, 0);
            end = new THREE.Vector3(width / 2 + offset, (height / 2) + wTopOffset, 0);
            break;
        case 'SashRight':
            // Right path should consider top-right and bottom-right corner sizes
            start = new THREE.Vector3(width / 2 + offset, (height / 2) + wTopOffset, 0);
            end = new THREE.Vector3(width / 2 + offset, -(height / 2) + wTopOffset, 0);
            break;
        case 'SashBottom':
            // Bottom path should consider bottom-right and bottom-left corner sizes
            start = new THREE.Vector3(width / 2 + offset, -(height / 2) + wTopOffset, 0);
            end = new THREE.Vector3(-width / 2 + offset, -(height / 2) + wTopOffset, 0);
            break;
        case 'SashLeft':
            // Left path should consider top-left and bottom-left corner sizes
            start = new THREE.Vector3(-width / 2 + offset, -(height / 2) + wTopOffset, 0);
            end = new THREE.Vector3(-width / 2 + offset, (height / 2) + wTopOffset, 0);
            break;
        default:
            console.error(`Invalid line position: ${linePosition}`);
            return null;
    }

    // Debugging: Check if the start or end points have NaN values
    if (isNaN(start.x) || isNaN(start.y) || isNaN(end.x) || isNaN(end.y)) {
        console.error(`Invalid path points for line position "${linePosition}": Start - ${start.toArray()}, End - ${end.toArray()}`);
        return null;
    }

    return createLinePath(start, end);
}
