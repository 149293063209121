import React, { useEffect, useState } from "react";
import { Row, Col, Button, FormGroup, Label, Input, Form } from "reactstrap";

import logo from "../../../../../../assets/img/logo/logo.png";
import {
  MdMailOutline,
  MdOutlinePinDrop,
  MdOutlinePhone,
  MdLanguage,
  MdOutlineFolder,
  MdClose,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { MenuProps } from "../../../../../../utility/data";
import {
  getPageSetupInfo,
  logoRemove,
  updatePageSetup,
} from "../../../../../../services/pageSetupService";
import { useNavigate } from "react-router-dom";
import isURL from "validator/lib/isURL";
import { getFontSizeMaster } from "../../../../../../services/commonServices";
import { Image } from "react-bootstrap";
import { handleError } from "../../../../../../utility/commonHelper";

const PageSetupLeft = ({
  accessToken,
  setMessage,
  setMessageType,
  setLoading,
}) => {
  const initialValues = {
    website: "",
  };

  const navigate = useNavigate();

  const [fontOptions, setFontOptions] = useState([]);
  const [dynamicLogo, setDynamicLogo] = useState("");
  const [logoFile, setLogoFile] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [phone, setPhone] = useState("");
  const [errorColor, setErrorColor] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");

  const [website, setWebsite] = useState("");
  const [validWebsiteURL, setValidWebsiteURL] = useState(false);

  const [vat, setVat] = useState("");
  const [fontValue, setFontValue] = useState("");
  const [logoType, setLogoType] = useState("");

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const [isValid, setIsValid] = useState(true);

  const [fontId, setFontId] = useState(1);

  const validate = () => {
    let formErrors = {};
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!website || !isURL(website)) {
      formErrors.website = "Please enter valid Website URL";
      isValid = false;
    }

    if (!phone) {
      formErrors.phone = "Phone is required!";
      isValid = false;
    }
    if (!vat) {
      formErrors.vat = "Vat is required !";
      isValid = false;
    }

    if (!address1) {
      formErrors.address1 = "Address 1 is required !";
    } else if (address1?.length === 100) {
      formErrors.address1 = "Address 1 cannot be more that 100 characters";
    }

    if (!email) {
      formErrors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      formErrors.email = "Email is Invalid";
    }

    setFormErrors(formErrors);
    setErrorColor("text-danger");

    return isValid;
  };

  // const validateField = (name, value) => {
  //     let errors = { ...formErrors };
  //     let valid = true;
  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     const urlOptions = {
  //         protocols: ['http', 'https'],
  //         require_protocol: true,
  //     };

  //     switch (name) {
  //         case "website":
  //             if (!value) {
  //                 errors.website = "Please enter Website URL";
  //                 valid = false;
  //                 setErrorColor('text-danger')
  //             } else if (value && !isURL(value , urlOptions)) {
  //                 errors.website = "Please enter a valid Website URL";
  //                 valid = false;
  //                 setErrorColor('text-danger')
  //             } else {
  //                 setErrorColor('')
  //                 delete errors.website;
  //             }
  //             break;
  //         case "phone":
  //             if (!value.length) {
  //                 errors.phone = "Phone is required!";
  //                 valid = false;
  //                 setErrorColor('text-danger')
  //             }else if(value.length > 10){
  //                 errors.phone = "Max 10 digits is allowed!";
  //                 valid = false
  //                 setErrorColor('text-muted')
  //             } else {
  //                 delete errors.phone;
  //             }
  //             break;
  //         case "vat":
  //             if (!value?.length) {
  //                 errors.vat = "Vat is required!";
  //                 valid = false;
  //                 setErrorColor('text-danger')
  //             } else if (value?.length >= 20) {
  //                 errors.vat = "Max 20 digits are allowed"
  //                 valid = false
  //                 setErrorColor('text-muted')
  //             } else {
  //                 delete errors.vat;
  //             }
  //             break;
  //         case "address1":
  //             if (!value) {
  //                 errors.address1 = "Address 1 is required!";
  //                 valid = false;
  //                 setErrorColor('text-danger')
  //             } else if (value.length > 100) {
  //                 errors.address1 = "Max 100 characters are allowed";
  //                 valid = false;
  //                 setErrorColor('text-muted')
  //             } else {
  //                 delete errors.address1;
  //             }
  //             break;
  //         case "email":
  //             if (!value) {
  //                 errors.email = "Email is required";
  //                 valid = false;
  //                 setErrorColor('text-danger')
  //             } else if (!emailRegex.test(value)) {
  //                 errors.email = "Email is invalid";
  //                 valid = false;
  //                 setErrorColor('text-muted')
  //             } else {
  //                 delete errors.email;
  //             }
  //             break;
  //         default:
  //             break;
  //     }
  //     // valid = true
  //     setFormErrors(errors);
  //     setIsValid(valid);
  // };

  const validateField = (name, value) => {
    let errors = { ...formErrors };
    let valid = true;

    if (value && value.length > 100) {
      errors[name] = `${
        name.charAt(0).toUpperCase() + name.slice(1)
      } cannot exceed 100 characters`;
      valid = false;
      setErrorColor("text-danger");
    } else {
      delete errors[name];
      setErrorColor("text-danger");
    }

    setFormErrors(errors);
    setIsValid(valid);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  useEffect(() => {
    setLoading(true);

    getFontSizeOptions(1);
    getPageSetupData();
  }, []);

  useEffect(() => {
    if (fontOptions) {
      const fontDynamic = fontOptions?.find((p) => p.id == fontId);

      if (fontDynamic) {
        setFontValue(fontDynamic.value);
      } else {
        setFontValue("14");
      }
    }
  }, [fontOptions, fontId]);

  // get font size dropdown from master
  const getFontSizeOptions = async (typeID) => {
    const res = await getFontSizeMaster(accessToken, typeID);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            setFontOptions(res?.data?.entity);
          }
        }
      }
    }
  };

  const handleValidationChange = (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const handleLogoChange = (e) => {
    const imageFile = e.target.files[0];

    setLogoFile(imageFile);

    if (imageFile) {
      setDynamicLogo(URL.createObjectURL(imageFile));
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);

    handleValidationChange(e);
  };

  const handleEmail = (e) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (e.target.value && regEmail.test(e.target.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    if (e.target.value === "") {
      setEmailError(false);
    }
  };

  const handleWebsiteChange = (e) => {
    setWebsite(e.target.value);

    // if (isURL(e.target.value)) {
    //     setValidWebsiteURL(true)
    // } else {
    //     setValidWebsiteURL(false)
    // }

    handleValidationChange(e);
  };

  const handleChangePhone = (e) => {
    if (e.target.value?.length <= 10) {
      setPhone(e.target.value);
    }

    handleValidationChange(e);
  };

  const handleChangeVat = (e) => {
    if (e.target.value?.length <= 20) {
      setVat(e.target.value);
    }

    handleValidationChange(e);
  };

  const getPageSetupData = async () => {
    const res = await getPageSetupInfo(accessToken);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          setAddress1(res.data.entity.addressLine1 || "");
          setAddress2(res.data.entity.addressLine2 || "");
          setEmail(res.data.entity.email || "");
          // setFontValue(res.data.entity.fontSize || fontOptions[2]?.value)
          setFontId(res.data.entity.fontSize || fontOptions[2]?.id);
          setPhone(res.data.entity.phone || "");
          setVat(res.data.entity.vat || "");
          setWebsite(res.data.entity.website || "");

          setDynamicLogo(res.data.entity.logoImagePath || "");
        }

        setLoading(false);
      } else {
        setLoading(false);
        setMessage(
          res?.data.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  const handleFormSubmit = async () => {
    setLoading(true);

    var data = {
      logo: logoFile,
      address1: address1,
      address2: address2,
      phone: phone,
      email: email,
      website: website,
      vat: vat,
      fontValue: fontId,
      logoType: logoType,
    };

    const res = await updatePageSetup(data, accessToken);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false);
        setMessage(res?.message ? res.message : "Page setup updated");
        setMessageType("success");

        getPageSetupData();

        setLoading(false);
      } else {
        setLoading(false);
        setMessage(
          res?.data.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  const handleDeleteLogo = async () => {
    const res = await logoRemove(accessToken);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        getPageSetupData();

        setLogoFile("");
        setDynamicLogo("");

        document.getElementById("logoUpload").value = "";

        setLoading(false);
      } else {
        setLoading(false);
        setMessage(
          res?.data.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  const handleFontOptions = (e) => {
    setFontId(e.target.value);
  };

  return (
    <div className="d-flex flex-column h-100">
      <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        {/* has_tab has_btns has_head -> add respective class of included compoenent in div of class inside_scroll for proper scroll */}
        <div className="inside_scroll has_btns">
          <Row>
            <Col xxl="12">
              <Row className="mb-3">
                <Col lg="12" className="mb-3">
                  <div className="border px-3 py-3 br-4 mb-1">
                    {/* <div className={`mt-0 page_setup_logo mb-3 ${logoType ? logoType : ''}`}>
                                            <Image src={dynamicLogo ? dynamicLogo : logo} alt="logo" />
                                        </div> */}

                    {dynamicLogo && (
                      <div className="mt-0 page_setup_logo mb-3">
                        <Image src={dynamicLogo} alt="logo" />
                      </div>
                    )}

                    <div className="item_list">
                      {address1 && (
                        <span
                          className="d-flex mb-2 head_item wrap_address"
                          style={{
                            fontSize: `${fontValue ? fontValue : "14"}px`,
                          }}
                        >
                          <span>
                            <MdOutlinePinDrop
                              size={`${
                                fontValue ? parseInt(fontValue) + 4 : "18"
                              }`}
                              className="me-2"
                            />
                          </span>
                          <span>
                            <span className="d-block lh-sm text-break">
                              {address1}
                            </span>
                            <span className="d-block lh-sm text-break">
                              {address2}
                            </span>
                          </span>
                        </span>
                      )}

                      {phone && (
                        <span
                          className="d-flex align-items-center mb-2 head_item"
                          style={{
                            fontSize: `${fontValue ? fontValue : "14"}px`,
                          }}
                        >
                          <span>
                            <MdOutlinePhone
                              size={`${
                                fontValue ? parseInt(fontValue) + 4 : "18"
                              }`}
                              className="me-2"
                            />
                          </span>
                          <span className="lh-sm">{phone}</span>
                        </span>
                      )}

                      {email && (
                        <span
                          className="d-flex align-items-center mb-2 head_item"
                          style={{
                            fontSize: `${fontValue ? fontValue : "14"}px`,
                          }}
                        >
                          <span>
                            <MdMailOutline
                              size={`${
                                fontValue ? parseInt(fontValue) + 4 : "18"
                              }`}
                              className="me-2"
                            />
                          </span>
                          <span className="lh-sm">{email}</span>
                        </span>
                      )}

                      {website && (
                        <span
                          className="d-flex align-items-center mb-2 head_item"
                          style={{
                            fontSize: `${fontValue ? fontValue : "14"}px`,
                          }}
                        >
                          <span>
                            <MdLanguage
                              size={`${
                                fontValue ? parseInt(fontValue) + 4 : "18"
                              }`}
                              className="me-2"
                            />
                          </span>
                          <span className="lh-sm">{website}</span>
                        </span>
                      )}

                      {vat && (
                        <span
                          className="d-flex align-items-center mb-2 head_item"
                          style={{
                            fontSize: `${fontValue ? fontValue : "14"}px`,
                          }}
                        >
                          <span className="me-1">VAT NO:</span>
                          <span className="lh-sm">{vat}</span>
                        </span>
                      )}
                    </div>
                  </div>

                  <span className="text-muted text-small">
                    Previewing your page setup
                  </span>
                </Col>

                {fontOptions && fontOptions.length > 0 && (
                  <Col md="6" lg="6" xl="4">
                    <FormGroup className="form-group">
                      <Label for="fontsize">Font size</Label>

                      <Select
                        labelId="fontsize"
                        id="fontsize"
                        value={fontId}
                        label="fontsize"
                        onChange={(e) => {
                          handleFontOptions(e);
                        }}
                        input={
                          <OutlinedInput className="w-100" name="fontsize" />
                        }
                        MenuProps={MenuProps}
                        IconComponent={() => (
                          <MdOutlineKeyboardArrowDown
                            size={20}
                            className="me-1 MuiSvgIcon-root MuiSelect-icon"
                          />
                        )}
                      >
                        {fontOptions &&
                          fontOptions.map((o, index) => (
                            <MenuItem
                              key={index}
                              value={o?.id}
                              style={{ fontSize: `${o.value}px` }}
                            >
                              {o.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormGroup>
                  </Col>
                )}

                {/* <Col sm="6" md="4" lg="3">
                                    <FormGroup className="form-group">
                                        <Label for="fontsize">
                                            Logo type
                                        </Label>

                                        <Select
                                            labelId="logoType"
                                            id="logoType"
                                            value={logoType}
                                            label="logoType"
                                            onChange={(e) => { setLogoType(e.target.value) }}
                                            input={<OutlinedInput className='w-100' name='logoType' />}

                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            <MenuItem value="rect">
                                                Rectangular
                                            </MenuItem>
                                            <MenuItem value="square">
                                                Square/Circular
                                            </MenuItem>
                                        </Select>
                                    </FormGroup>
                                </Col> */}
              </Row>

              <Row>
                <Col lg="12">
                  <h4 className="main_title sub_title">Header Information</h4>

                  <FormGroup className="form-group">
                    <Label>Logo</Label>

                    <div className="d-flex gap-3 file_choose_group mb-1">
                      <div className="d-flex max-w-200">
                        <Label
                          for="logoUpload"
                          className="file_choose btn btn-primary text-white d-flex justify-content-center align-items-center w-100 mb-0"
                        >
                          <Input
                            id="logoUpload"
                            className="mb-1"
                            name="logoUpload"
                            type="file"
                            onChange={(e) => {
                              handleLogoChange(e);
                            }}
                          />
                          <MdOutlineFolder size={20} className="me-2 lh-1" />
                          <span className="lh-sm">Choose file</span>
                        </Label>
                      </div>

                      <Button
                        color="primary"
                        size="md"
                        outline
                        className="px-2 px-sm-4 max-w-180"
                        // disabled={!dynamicLogo}
                        onClick={(e) => handleDeleteLogo(e)}
                      >
                        <MdClose size={20} className="me-1 lh-1" />
                        <span className="lh-sm">Remove</span>
                      </Button>
                    </div>

                    <span className="text-muted text-small">
                      Your logo will be scaled by 50% to look better on mobile
                      devices.
                    </span>
                  </FormGroup>
                </Col>

                <Col md="6" lg="6" xl="4">
                  <FormGroup className="form-group">
                    <Label for="address1">Address Line 1</Label>
                    <Input
                      className="mb-1"
                      name="address1"
                      value={address1}
                      onChange={(e) => {
                        {
                          e.target.value?.length < 101 &&
                            setAddress1(e.target.value);
                        }
                        handleInputChange(e);
                      }}
                      onInput={(e) => handleInputChange(e)}
                    />
                    {formErrors?.address1 && (
                      <div className={`mt-1 ${errorColor} text-small`}>
                        {formErrors?.address1}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col md="6" lg="6" xl="4">
                  <FormGroup className="form-group">
                    <Label for="address2">Address Line 2</Label>
                    <Input
                      className="mb-1"
                      name="address2"
                      value={address2}
                      onChange={(e) => {
                        {
                          e.target.value?.length < 101 &&
                            setAddress2(e.target.value);
                        }
                      }}
                    />

                    {address2 && address2?.length > 100 && (
                      <div className="mt-1 text-danger text-small">
                        Max 100 characters are allowed
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col md="6" lg="6" xl="4">
                  <FormGroup className="form-group">
                    <Label for="email">Email</Label>
                    <Input
                      className="mb-1"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        handleEmailChange(e);
                        handleInputChange(e);
                      }}
                      onBlur={handleEmail}
                      onInput={(e) => handleInputChange(e)}
                    />

                    {/* {emailError && (
                                            <div className='mt-1 text-danger'>Please enter valid email</div>
                                        )} */}

                    {formErrors && (
                      <div className="mt-1 text-danger">
                        {formErrors?.email}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col md="6" lg="6" xl="4">
                  <FormGroup className="form-group">
                    <Label for="website">Website</Label>
                    <Input
                      className="mb-1"
                      name="website"
                      value={website}
                      onChange={(e) => {
                        handleWebsiteChange(e);
                        handleInputChange(e);
                      }}
                      onInput={(e) => handleInputChange(e)}
                    />

                    {formErrors?.website && (
                      <div className="mt-1 text-danger">
                        {formErrors?.website}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="6" lg="6" xl="4">
                  <FormGroup className="form-group">
                    <Label for="phone">Phone</Label>

                    <Input
                      className="mb-1"
                      name="phone"
                      value={phone}
                      type="number"
                      onChange={(e) => {
                        handleChangePhone(e);
                        handleInputChange(e);
                      }}
                      onInput={(e) => handleInputChange(e)}
                    />

                    {/* {(phone && phone.length === 10) && (
                                            <div className='mt-1 text-muted text-small'>Max 10 digits are allowed</div>
                                        )} */}

                    {formErrors && (
                      <div className={`mt-1 ${errorColor} text-small`}>
                        {formErrors.phone}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="6" lg="6" xl="4">
                  <FormGroup className="form-group">
                    <Label for="vat">VAT number</Label>
                    <Input
                      className="mb-1"
                      name="vat"
                      value={vat}
                      type="number"
                      onChange={(e) => {
                        handleChangeVat(e);
                        handleInputChange(e);
                      }}
                      onInput={(e) => handleInputChange(e)}
                    />
                    {formErrors?.vat && (
                      <div className={`mt-1 ${errorColor} text-small`}>
                        {formErrors.vat}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/* Start: space for buttons at the bottom */}
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            disabled={!isValid}
            onClick={() => (isValid ? handleFormSubmit() : <></>)}
          >
            Save Changes
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            onClick={() => getPageSetupData()}
          >
            Reset
          </Button>
        </div>
        {/* End: space for buttons at the bottom */}
      </Form>
    </div>
  );
};

export default PageSetupLeft;
