import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, FormGroup, Input, Label, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { useDesigner } from '../../context/designerContext';
import NumberInput from '../NumberInput';
import { BiLockOpenAlt, BiSolidCheckSquare, BiX } from 'react-icons/bi';
import { FaGripLines } from 'react-icons/fa';
import { handleMultiSelect } from '../../utility/threeJsHelpers/general';
import { MdInfoOutline } from 'react-icons/md';
import { checkValidationForPositiveNumber, handlePositiveNumber } from '../../utility/helper';
import { adjustWidthsForRounding } from '../../utility/threeJsHelpers/uiLinesHelper';
import { getAllOffSet } from './BlockWindow/createWindowFrame';

function EditPartialHeights({ uiLinesRef, savedData, manufacturingToggle }) {

  const { lockRefPointsV, setLockRefPointsV, lockRefIndexV, setLockRefIndexV, setApplyPartialObj, applyPartialTrigger, setApplyPartialTrigger, setPartialIndex, setCustomModelData } = useDesigner()

  const [popoverIndex, setPopoverIndex] = useState(null)
  const [partialDimension, setPartialDimension] = useState(0)
  const [validLength, setValidLength] = useState(0)
  const [partialHeights, setPartialHeights] = useState([]);
  const [minPartialDimention, setMinPartialDimention] = useState(1);
  const dimensionRef = useRef(null)

  const partialHeightRatios = savedData?.sash?.partialHeightRatios || [1];
  const allOffSetData = getAllOffSet();
  const frameHeight = (savedData?.dimensions?.height || 0) * 1000;

  useEffect(() => {
    let adjustedFrameHeight = frameHeight;
    if (allOffSetData) {
      adjustedFrameHeight -= allOffSetData?.wHeightOffset * 1000;
    }

    const newPartialWidths = adjustWidthsForRounding(adjustedFrameHeight, partialHeightRatios);
    setPartialHeights(newPartialWidths);
  }, [allOffSetData, frameHeight, partialHeightRatios]);

  useEffect(() => {
    if (partialHeights) {
      setCustomModelData((prevModelData) => ({
        ...prevModelData,
        sashSizeHeight: partialHeights,
      }));
    }
  }, [partialHeights])

  useEffect(() => {
    if (savedData && popoverIndex != null) {
      let minPartialValue = 0
      if (popoverIndex == 0) {
        minPartialValue = (savedData?.frame?.sides?.top?.dimensions?.height) * 1000
      } else if (popoverIndex == (partialHeights.length - 1)) {
        minPartialValue = (savedData?.frame?.sides?.bottom?.dimensions?.height) * 1000
      } else {
        minPartialValue = 1
      }

      setMinPartialDimention(minPartialValue)
    }
  }, [savedData, popoverIndex])

  useEffect(() => {
    if (popoverIndex != null) {
      if (dimensionRef?.current) {
        dimensionRef?.current?.focus()
        dimensionRef?.current?.select()
      }
    } else {
      if (dimensionRef.current) {
        dimensionRef.current = null
      }
    }
  }, [popoverIndex])

  const handleApply = (e) => {
    // e.preventDefault();    
    if (partialDimension > 0) {
      setPopoverIndex(!popoverIndex);
      setApplyPartialTrigger(true)
    }
  }

  const handleInputW = (value) => {
    setPartialDimension(value)
    if (value < minPartialDimention) return;
    // e.preventDefault();

    var data = {
      'index': popoverIndex,
      'value': value,
      'type': 'H'
    }

    setApplyPartialObj(data)
  }

  const handleLockIndex = (index) => {
    setLockRefIndexV((prev) => {
      if (prev.includes(index)) return prev.filter((item) => item !== index);
      return [...prev, index];
    });
  };

  const handleRefClick = (item, index) => {

    if (partialHeightRatios && partialHeightRatios.length > 0) {
      let lockedLength = lockRefIndexV && lockRefIndexV.length > 0 ? lockRefIndexV?.reduce((sum, index) => sum + (partialHeights[index]), 0) : 0;

      let validValue = ((frameHeight) - lockedLength - ((partialHeightRatios.length - lockRefIndexV.length - 1) * 50))
      setValidLength(validValue)

      setPartialDimension(parseInt(partialHeights[index]))
    }
  }

  const handleApplyOnEnter = (e) => {
    if ((e?.key === "Enter" && partialDimension >= 1 && partialDimension <= validLength)) {
      handleApply(e);
    }
  };

  return (
    <>
      {uiLinesRef?.current && uiLinesRef?.current?.length > 0 && uiLinesRef?.current?.map((item, index) => {
        const isLocked = lockRefPointsV.some((obj) => obj.index === index);

        return (
          <div id={`overlayLines${index}`} key={index} className="ref_btn_wrap ui_ref">
            <div
              // className={`btn_parent ${(item.name.includes('FrameLeft') || item.name.includes('GlassPanel')) ? 'is_left' : ''}`}
              className={`btn_parent is_right`}
              style={{
                position: 'absolute',
                left: item.overlayX,
                top: item.overlayY,
              }}
            >
              <div className='potition-relative'>
                {uiLinesRef?.current && uiLinesRef?.current?.length > 2 && (!item.name.includes('FrameLeft')) && (
                  <Button
                    type="button"
                    color={isLocked ? 'outline-danger' : 'light'}
                    className={`rounded position-absolute ref_btn lock_btn`}
                    onClick={() => { handleMultiSelect(index, item, setLockRefPointsV); handleLockIndex(index) }}
                  >
                    <BiLockOpenAlt size={12} />
                  </Button>
                )}

                <Button
                  type="button"
                  color='light'
                  className={`rounded ref_btn position-relative type_2`}
                  id={`linesButtons_v${index}`}
                  onClick={() => {
                    if (!manufacturingToggle) {
                      setApplyPartialTrigger(false);
                      handleRefClick(item, index);
                    }
                    if (manufacturingToggle) {
                      setPartialIndex(`H${index + 1}`)
                    }
                  }}
                >
                  <span>
                    {parseInt(partialHeights[index])}
                    {/* {partialHeightRatios && partialHeightRatios.length > 0 ? (
                      parseInt(partialHeights[index])
                    ) : (
                      parseInt((parseFloat(frameHeight) / (parseInt(savedData?.sash?.hangings) ? parseInt(savedData?.sash?.hangings) : 1)))
                    )} */}
                  </span>
                </Button>

                <UncontrolledPopover
                  placement="bottom"
                  target={`linesButtons_v${index}`}
                  trigger="legacy"
                  isOpen={popoverIndex === index}
                  toggle={() => {
                    !manufacturingToggle ?
                      setPopoverIndex(popoverIndex === index ? null : index) : setPopoverIndex(null)
                  }}
                  className='custom_shadow'
                >
                  <PopoverBody className='p-0 position-relative'>

                    {(!lockRefIndexV.includes(index) && (lockRefIndexV?.length !== (uiLinesRef?.current?.length - 1))) ? (
                      <div className='p-2'>
                        <Button
                          color="primary"
                          className='circle_cross box_right_top rounded'
                          onClick={() => {
                            setPopoverIndex(!popoverIndex);
                          }}
                        >
                          <BiX size={20} />
                        </Button>

                        <React.Fragment >
                          <FormGroup className='mb-0'>
                            <Label for="partialDimension" className="text-dark fs-6 fw-bold">
                              Height
                            </Label>

                            <div className='d-flex align-items-center gap-2'>
                              <div className="w-50">
                                <Input
                                  id='partialDimension'
                                  innerRef={dimensionRef}
                                  type='number'
                                  name='partialDimension'
                                  value={partialDimension}
                                  onChange={(e) => handleInputW(e.target.value)}
                                  onKeyDown={(e) => {
                                    handleApplyOnEnter(e)
                                  }}
                                  onInput={(e) => checkValidationForPositiveNumber(e)}
                                  className={partialDimension ? 'border-primary' : "border-danger"}>
                                </Input>
                              </div>

                              <Button
                                outline
                                color="primary"
                                size="md"
                                className='px-3 mb-1 w-50'
                                onClick={(e) => { handleApply(e) }}
                                // disabled={(partialDimension > validLength) && validLength > 0 || isNaN(validLength) || partialDimension < 1}
                                disabled={(partialDimension > validLength) || (partialDimension < minPartialDimention)}
                              >
                                <BiSolidCheckSquare size={18} className="me-2" />
                                Apply
                              </Button>
                            </div>

                            {(partialDimension > validLength) && validLength > 0 && (
                              <p className='text-danger mb-0'>Max value should be {validLength}</p>
                            )}

                            {(partialDimension < minPartialDimention) && (
                              <p className="text-danger mb-0">Min value should be {minPartialDimention}</p>
                            )}
                          </FormGroup>

                          <div className='d-none gap-2'>
                            <Button
                              color="primary"
                              size="md"
                              className='px-3 w-50'
                            >
                              <FaGripLines size={18} className="me-2" />
                              Frame
                            </Button>

                            <Button
                              color="primary"
                              size="md"
                              className='px-3 w-50'
                            >
                              <FaGripLines size={18} className="me-2" />
                              Glass
                            </Button>
                          </div>
                        </React.Fragment>
                      </div>
                    ) : (
                      <div className='p-2'>
                        <h4 className='text-bark mb-2'>Oops!</h4>

                        <Alert
                          color={`${lockRefIndexV.includes(index) ? 'warning' : 'warning'}`}
                          className="p-2 m-0"
                        >
                          <div className='d-flex gap-2'>
                            <span>
                              <MdInfoOutline size={18} />
                            </span>

                            {lockRefIndexV.includes(index) ? (
                              <span>
                                This glazing is locked, you can't change dimension of locked items
                              </span>
                            ) : (
                              <span>
                                You'll need at least 2 unlocked item to change dimensions
                              </span>
                            )}
                          </div>
                        </Alert>
                      </div>
                    )}
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            </div>
          </div>
        )
      })}
    </>
  );
};
export default EditPartialHeights;