import React, { useState } from "react";
import { BiPlus, BiTrashAlt } from "react-icons/bi";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdClose,
  MdContentCopy,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavLink,
} from "reactstrap";
import { useProductPaletteContext } from "../../../../../../context/productPaletteContext";
import {
  addUpdateColoursName,
  deletePalette,
  updateColorPaletterOrder,
} from "../../../../../../services/productServices";
import { useEffect } from "react";
import { useMessage } from "../../../../../../context/messageContext";
import {
  copyItem,
  itemVisibility,
} from "../../../../../../services/commonServices";
import RotatingCube from "../../../../../../components/3DComponents/RotatingCube";
import { Image } from "react-bootstrap";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import { handleError } from "../../../../../../utility/commonHelper";
import multiColorBall from "../../../../../../assets/img/custom_ral_any.png";
import dummyWhiteBall from "../../../../../../assets/img/dummyColorBall.jpg"

const PaletteRight = ({ setLoading, getAllColoursServices }) => {
  const {
    newItemList,
    fetchSelected,
    activeIndex,
    handleActiveIndexDown,
    handleActiveIndexUp,
    setActiveIndex,
    timestamp,
  } = useProductPaletteContext();
  const { selectedRightItem, userDataContext, setSelectedRightItem } =
    useRightContext();
  const { setMessage, setMessageType } = useMessage();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(true);

  const accessToken = localStorage.getItem("access_token");

  const navigate = useNavigate();

  useEffect(() => {
    setActiveIndex(0);
  }, []);

  useEffect(() => {
    if (newItemList?.length > 0) {
      let selectedRight = newItemList?.find((p) => p.id === timestamp);

      setSelectedRightItem(selectedRight);
    }
  }, [timestamp, newItemList]);

  const getSingleColor = newItemList?.find((item) => item.id === timestamp);


  const addNameServices = async (name) => {

    const response = await fetch(dummyWhiteBall);
    const blob = await response.blob();

    // Step 2: Convert Blob to File
    const file = new File([blob], 'local-image.jpg', { type: blob.type });

    setLoading(true);
    const data = {
      id: "",
      name: name,
      description: "",
      type: "chart",
      chartId: 1,
      chartColorType: true,
      chartColorId: 202,
      textureId: 0,
      red: 0,
      green: 0,
      blue: 0,
      hex: 0,
      roughness: 0,
      metalness: 0,
      bumpId: 0,
      bumpiness: 0,
      paletteType: 1,
      CapturedFile:file
    };
    
    const res = await addUpdateColoursName(data, accessToken);

    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(res?.data?.message ? res.data.message : "Added new colour");
        getAllColoursServices("added");
        setLoading(false);
      } else {
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
        getAllColoursServices();
        setLoading(false);
      }
    }
  };

  const deletePaletteServices = async () => {
    setLoading(true);
    setIsDeleted(false);
    const res = await deletePalette(accessToken, timestamp);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(
          res?.data?.message ? res.data.message : "Deleted Color Successfully"
        );
        getAllColoursServices();
        setLoading(false);
        fetchSelected(0, newItemList[0]?.id);
        setShowDeleteModal(false);
        setIsDeleted(true);
      } else {
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
        getAllColoursServices();
        setLoading(false);
        setIsDeleted(true);
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      getAllColoursServices();
      setIsDeleted(true);
      handleError(res, false, navigate);
    }
  };

  const updateOrderServices = async (type, direction) => {
    setLoading(true);
    const res = await updateColorPaletterOrder(
      accessToken,
      timestamp,
      type,
      direction
    );
    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        getAllColoursServices();
        if (direction === "UP") {
          handleActiveIndexUp();
        } else if (direction === "DOWN") {
          handleActiveIndexDown();
          getAllColoursServices();
        }
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  const handleItemUp = () => {
    updateOrderServices(1, "UP");
  };

  const handleItemDown = () => {
    updateOrderServices(1, "DOWN");
  };

  const copyServices = async (id) => {
    setLoading(true);
    const res = await copyItem(accessToken, id, 4);
    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false);
        setMessage(
          res?.data?.message
            ? res.data.message
            : "Record removed successfully !"
        );
        setMessageType("success");
        getAllColoursServices();
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  const handleVisibility = async (e, item) => {
    setLoading(true);
    const res = await itemVisibility(
      accessToken,
      item?.id,
      4,
      !item?.isEnabled
    );

    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setLoading(false);
        setMessage(res?.data?.message ? res.data.message : "Action Successful");
        getAllColoursServices();
      } else {
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
        getAllColoursServices();
        setLoading(false);
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      getAllColoursServices();
      handleError(res, false, navigate);
    }
  };
  return (
    <>
      <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_btns">
          <div className="right_list">
            {newItemList?.map((item, index) => (
              <div
                key={index}
                className={`position-relative d-flex align-items-center right_item_wrap has_delete has_copy threejs_ball ${
                  userDataContext?.role === "Supplier" && "count_3"
                }`}
              >
                <Link
                  to="#"
                  className={`right_item w-100 ${
                    activeIndex === index && "active"
                  }`}
                  onClick={() => {
                    fetchSelected(index, item?.id);
                  }}
                >
                  {activeIndex === index ? (
                    <span className="me-2 ball_shadow">
                      {item?.chartColourType ? (
                        <RotatingCube
                          elements={item?.filePath}
                          roughnessVal={item?.roughness}
                          colorVal={item?.hex}
                          metalNessVal={item?.metalness}
                          bumpiness={item?.bumpiness}
                          textureVal={item?.texture}
                          capturedAccordion={""}
                        />
                      ) : (
                        <Image
                          src={multiColorBall}
                          alt="icon1"
                          className="rounded-circle"
                        />
                      )}
                    </span>
                  ) : (
                    <span className="me-2 image_icon ball_shape ball_shadow">
                      <Image
                        src={
                          item?.chartColourType
                            ? item?.capturedImage
                            : multiColorBall
                        }
                        alt="icon1"
                        className="rounded-circle"
                      />
                    </span>
                  )}

                  <div className="right_title">
                    <span className="text-truncate">{item.name}</span>
                    <span className="text-truncate d-block text-small">
                      {item.description}
                    </span>
                  </div>
                </Link>
                <span className="position-absolute action_btns">
                  {userDataContext && userDataContext?.role === "Supplier" && (
                    <Link
                      to="#"
                      onClick={(e) => {
                        fetchSelected(index, item.id);
                        handleVisibility(e, item);
                      }}
                    >
                      {!item?.isEnabled ? (
                        <MdOutlineVisibilityOff
                          className="text-muted visibility_off"
                          size={20}
                        />
                      ) : (
                        <MdOutlineVisibility
                          className="text-muted visibility_on"
                          size={20}
                        />
                      )}
                    </Link>
                  )}
                  {item?.isSelf && (
                    <>
                      <Link
                        to="#"
                        onClick={() => {
                          fetchSelected(index, item?.id);
                          setShowDeleteModal(true);
                        }}
                      >
                        {" "}
                        <BiTrashAlt className="text-muted" size={20} />{" "}
                      </Link>
                      <Link to="#" onClick={() => copyServices(item?.id)}>
                        {" "}
                        <MdContentCopy className="text-muted" size={18} />{" "}
                      </Link>
                    </>
                  )}
                </span>
              </div>
            ))}
            {/* <ThreeDList elements = {elements}/> */}
          </div>
        </div>

        <Modal
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(false)}
        >
          <ModalHeader>Delete Ancillary</ModalHeader>
          <ModalBody>
            Are you sure you want delete <strong>{getSingleColor?.name}</strong>{" "}
            ?
          </ModalBody>
          <ModalFooter>
            <Button
              color="outline-danger"
              size="md"
              onClick={() => deletePaletteServices()}
              disabled={!isDeleted}
            >
              Delete
            </Button>
            <Button
              onClick={() => setShowDeleteModal(false)}
              color="primary"
              size="md"
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={() => addNameServices(`Color ${newItemList.length + 1}`)}
          >
            <BiPlus size={20} className="me-1" />
            New
          </Button>

          {selectedRightItem?.isSelf && (
            <>
              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => handleItemUp()}
                disabled={activeIndex === 0}
              >
                <MdArrowUpward size={22} className="me-1" />
                Up
              </Button>
              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => handleItemDown()}
                // disabled={(newItemList?.length - 1 === activeIndex) || (selectedRightItem?.isLast)}
                disabled={
                  activeIndex === newItemList.length - 1 ||
                  newItemList.length === 0 ||
                  selectedRightItem?.isLast
                }
              >
                <MdArrowDownward size={22} className="me-1" />
                Down
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PaletteRight;
