import React, { useEffect } from "react";
import { useState } from "react";
import {
  MdInfoOutline,
  MdOutlineKeyboardArrowDown,
  MdPersonOutline,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Button,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import { BsTools } from "react-icons/bs";
import { MenuProps, handleSideData, hardwareColors } from "../../utility/data";
import { BiArrowBack, BiTrash } from "react-icons/bi";
import { useDesigner } from "../../context/designerContext";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import {
  getHardwareColorServices,
  setModelData,
  setQuotationModelData,
} from "../../services/3dModelServices";
import { useMessage } from "../../context/messageContext";
import RotatingCube from "../3DComponents/RotatingCube";
import DynamicDropdown from "./DynamicDropdown";
import NumberInputNegative from "../NumberInputNegative";
import { Image } from "react-bootstrap";
import { handleError } from "../../utility/commonHelper";
import { colorList } from "../../services/productServices";
import multicolorball from "../../assets/img/custom_ral_any.png";

const HardwareConfig = ({
  selectedHardware,
  setHardwareData,
  setLoading,
  collectionId,
  frameStyleProdId,
  quotationId,
  receivedProductId,
}) => {
  const {
    numHardware,
    setNumHardware,
    hardwareElPos,
    setHardwareElPos,
    setHardwareColor,
    sethandleSides,
    handleSides,
    verticalPos,
    setVerticalPos,
    horizontalPos,
    setHorizontalPos,
    isHardwareSave,
    setIsHardwareSave,
    deleteHardware,
    setDeleteHardware,
    setCustomModelData,
    customModelData,
    modelJson,
    hardwareColor,
    setIsSettingPos,
    setEditHardware,
    multiSelectRefPoints,
    setMultiSelectRefPoints,
  } = useDesigner();

  const accessToken = localStorage.getItem("access_token");

  const [activeTab, setActiveTab] = useState("style");
  const [activeStyleIndex, setActiveStyleIndex] = useState();
  const [data, setData] = useState("");
  const [activeColorIndex, setActiveColorIndex] = useState(0);
  const [activeExternalColorIndex, setActiveExternalColorIndex] = useState(0);
  const [activeInternalColorIndex, setActiveInternalColorIndex] = useState(0);
  const [trickleData, setTrickleData] = useState();
  const [styleOptions, setStyleOptions] = useState([]);
  const [selectedStyle, setSelectStyle] = useState("");
  const [hardwareColours, setHardwareColours] = useState([]);
  const [isSave, setISave] = useState(false);
  const [isColorSelected, setIsColorSelected] = useState(false);
  const [colorListDrop, setColorListDrop] = useState([]);
  const [isCustomRal, setIsCustomRal] = useState(false);
  const [matchColor, setMatchColor] = useState(false);
  const [showColorError, setShowColorError] = useState(false);

  const navigate = useNavigate();
  const { setMessage, setMessageType } = useMessage();

  useEffect(() => {
    if (activeTab === "position") {
      setIsSettingPos(true);
    } else {
      setIsSettingPos(false);
    }
  }, [activeTab]);

  // useEffect(() => {
  //   if (isHardwareSave) {
  //     setTimeout(() => {
  //       setIsHardwareSave(false);
  //     }, 1000);
  //   }
  // }, [isHardwareSave]);

  useEffect(() => {
    if (deleteHardware) {
      setTimeout(() => {
        setDeleteHardware(false);
      }, 200);
    }
  }, [deleteHardware]);

  useEffect(() => {
    if (!quotationId && isSave) {
      saveGlazingService(customModelData);
      setISave(false);
    } else if (quotationId && isSave) {
      glazingQuotationService(customModelData);
      setISave(false);
    }
  }, [isSave, customModelData]);

  useEffect(() => {
    if (!quotationId && deleteHardware) {
      saveGlazingService(customModelData);
      setDeleteHardware(false);
    } else if (quotationId && deleteHardware) {
      glazingQuotationService(customModelData);
      setDeleteHardware(false);
    }
  }, [deleteHardware, customModelData]);

  // useEffect(() => {
  //   if (selectedHardware?.type !== "trickle") {
  //     if (selectedHardware?.color && hardwareColours.length > 0) {
  //       setActiveColorIndex(
  //         hardwareColours.findIndex(
  //           (item) => item.name === selectedHardware.color
  //         )
  //       );
  //     }
  //   } else {
  //     if (selectedHardware?.internalColor) {
  //       setActiveInternalColorIndex(
  //         hardwareColors.find(
  //           (item) => item.name === selectedHardware.internalColor
  //         )?.id - 1
  //       );
  //     }
  //     if (selectedHardware?.externalColor) {
  //       setActiveExternalColorIndex(
  //         hardwareColors.find(
  //           (item) => item.name === selectedHardware.externalColor
  //         )?.id - 1
  //       );
  //     }
  //   }
  // }, [selectedHardware, hardwareColours]);

  useEffect(() => {
    if (
      selectedHardware?.type === "cylinder" &&
      customModelData?.hardware?.cylinder[0]?.position?.vertical &&
      customModelData?.hardware?.cylinder[0]?.position?.horizontal
    ) {
      setVerticalPos(
        customModelData?.hardware?.cylinder[0]?.position?.vertical
      );
      setHorizontalPos(
        customModelData?.hardware?.cylinder[0]?.position?.horizontal
      );
    }
    // else if (selectedHardware?.type === "handle" && customModelData?.hardware?.handle[0]?.position?.vertical && customModelData?.hardware?.handle[0]?.position?.horizontal) {
    //   setVerticalPos(customModelData?.hardware?.handle[0]?.position?.vertical);
    //   setHorizontalPos(
    //     customModelData?.hardware?.handle[0]?.position?.horizontal
    //   );
    //   const side = handleSideData.find(
    //     (item) =>
    //       item.id === customModelData?.hardware?.handle[0]?.position?.side
    //   );
    //   sethandleSides(side?.id);
    // }
    else if (selectedHardware?.type === "numeral") {
      // commented don't uncomment it for now
      // setVerticalPos(customModelData?.hardware?.numeral.position?.vertical);
      // setHorizontalPos(
      //   customModelData?.hardware?.numeral.position?.horizontal
      // );
    } else if (
      selectedHardware?.type === "trickle vent" &&
      customModelData?.hardware?.trickleVent[0]?.position?.vertical &&
      customModelData?.hardware?.trickleVent[0]?.position?.horizontal
    ) {
      setVerticalPos(
        customModelData?.hardware?.trickleVent[0]?.position?.vertical
      );
      setHorizontalPos(
        customModelData?.hardware?.trickleVent[0]?.position?.horizontal
      );
    }
  }, [selectedHardware, customModelData]);

  useEffect(() => {
    if (customModelData && selectedHardware) {
      if (selectedHardware?.type === "cylinder")
        setStyleOptions(customModelData?.hardware?.cylinder);
      else if (selectedHardware?.type === "handle") {
        setStyleOptions(customModelData?.hardware?.handle);
      } else if (selectedHardware?.type === "trickleVent") {
        setStyleOptions(customModelData?.hardware?.[`trickleVent`]);
      } else if (selectedHardware?.type === "escutcheon") {
        setStyleOptions(customModelData?.hardware?.[`escutcheon`]);
      } else if (selectedHardware?.type === "knocker") {
        setStyleOptions(customModelData?.hardware?.[`knocker`]);
      } else if (selectedHardware?.type === "spyhole") {
        setStyleOptions(customModelData?.hardware?.[`spyhole`]);
      } else if (selectedHardware?.type === "hinge") {
        setStyleOptions(customModelData?.hardware?.[`hingeData`]);
      } else if (selectedHardware?.type === "bar handles offset") {
        setStyleOptions(customModelData?.hardware?.[`barHandlesOffset`]);
      } else {
        setStyleOptions(customModelData?.hardware?.[`numeral`]);
      }
    }
  }, [selectedHardware, customModelData]);

  useEffect(() => {
    if (styleOptions?.length > 0 && selectedStyle === "") {
      setSelectStyle(styleOptions[0]?.id);
    }
  }, [styleOptions, selectedStyle]);

  useEffect(() => {
    if (styleOptions?.length > 0 && selectedHardware) {
      setActiveStyleIndex(
        styleOptions?.findIndex((item) => item.name === selectedHardware?.name)
      );
    }
  }, [selectedHardware, styleOptions]);

  useEffect(() => {
    if (selectedStyle && activeTab === "colour") {
      getHardwareColorsData();
      colorListServices();
    }
  }, [selectedStyle, activeTab]);

  const saveData = () => {
    // setIsHardwareSave(true);
    // if (selectedHardware?.type === "handle") {
    //   setCustomModelData((prevAdded) => ({
    //     ...prevAdded,
    //     hardware: updatePos(
    //       customModelData?.hardware,
    //       hardwareElPos,
    //       horizontalPos,
    //       verticalPos,
    //       "handle"
    //     ),
    //   }));
    // } else if (selectedHardware?.type === "hinge") {
    //   setCustomModelData((prevAdded) => ({
    //     ...prevAdded,
    //     hardware: updatePos(
    //       customModelData.hardware,
    //       hardwareElPos,
    //       horizontalPos,
    //       verticalPos,
    //       "hinge"
    //     ),
    //   }));
    // } else {
    //   let modifiedData;
    //   switch (selectedHardware?.type) {
    //     case "cylinder":
    //       modifiedData = getModifiedData("cylinder", data);
    //       break;
    //     case "numeral":
    //       modifiedData = getModifiedData("numeral", data);
    //       break;
    //     case "letterplate":
    //       modifiedData = getModifiedData("letterplate", data);
    //       break;
    //     case "spyhole":
    //       modifiedData = getModifiedData("spyhole", data);
    //       break;
    //     case "trickle":
    //       modifiedData = getModifiedData("trickle", trickleData);
    //       break;
    //     case "knocker":
    //       modifiedData = getModifiedData("knocker", data);
    //       break;
    //     case "escutcheon":
    //       modifiedData = getModifiedData("escutcheon", data);
    //       break;
    //     // case "handle":
    //     //   modifiedData = getModifiedData("handle", data)
    //     //   break;
    //     default:
    //       console.log("wrong case");
    //       break;
    //   }

    //   // let arrayData=modifiedData[0]?.horizontalPos || modifiedData?.verticalPos
    //   // ? modifiedData.slice(0, -1)
    //   // : modifiedData.slice(1);

    //   if (selectedHardware?.type !== "trickle") {
    //     setCustomModelData((prevModelData) => ({
    //       ...prevModelData,
    //       hardware: {
    //         ...prevModelData.hardware,
    //         [selectedHardware?.type]: isColorSelected
    //           ? [modifiedData]
    //           : modifiedData,
    //       },
    //     }));
    //   } else {
    //     setCustomModelData((prevModelData) => ({
    //       ...prevModelData,
    //       hardware: {
    //         ...prevModelData.hardware,
    //         trickleVent: isColorSelected ? [modifiedData] : modifiedData,
    //       },
    //     }));
    //   }
    // }
    // setIsColorSelected(false);
    setISave(true);
  };

  function updatePos(
    jsonData,
    handleIndex,
    newHorizontalPos,
    newVerticalPos,
    type
  ) {
    // Check if the handleIndex is valid
    if (type === "handle") {
      if (handleIndex < 0 || handleIndex >= jsonData.handle.length) {
        return;
      }

      // Update the values
      jsonData.handle[handleIndex].horizontalPos = newHorizontalPos;
      jsonData.handle[handleIndex].verticalPos = newVerticalPos;
    } else if (type === "hinge") {
      if (handleIndex < 0 || handleIndex >= jsonData.hingeData.length) {
        return;
      }

      jsonData?.hingeData?.forEach((item, index) => {
        if (handleIndex <= 2 && index <= 2) {
          jsonData.hingeData[index].horizontalPos = newHorizontalPos;
          jsonData.hingeData[index].verticalPos = newVerticalPos;
        } else if (handleIndex > 2 && index > 2) {
          jsonData.hingeData[index].horizontalPos = newHorizontalPos;
          jsonData.hingeData[index].verticalPos = newVerticalPos;
        }
      });
      // Update the values
    }
    // Optional: Return the updated JSON data
    return jsonData;
  }

  // useEffect(() => {
  //   let horizontalPos;
  //   let verticalPos;

  //   if (customModelData?.hardware) {
  //     if (selectedHardware?.type === "trickle") {
  //       horizontalPos = customModelData?.hardware?.trickleVent[0]?.horizontalPos
  //         ? customModelData?.hardware?.trickleVent[0]?.horizontalPos
  //         : 0;
  //       verticalPos = customModelData?.hardware?.trickleVent[0]?.verticalPos
  //         ? customModelData?.hardware?.trickleVent[0]?.verticalPos
  //         : 0;
  //     } else if (selectedHardware?.type === "handle") {
  //       Object.values(customModelData?.hardware).forEach((item) => {
  //         if (item?.type === selectedHardware?.type) {
  //           horizontalPos = item?.horizontalPos ? item?.horizontalPos : 0;
  //           verticalPos = item?.verticalPos ? item?.verticalPos : 0;
  //         }
  //       });
  //     } else if (selectedHardware?.type === "hinge") {
  //       customModelData?.hardware?.hingeData.forEach((item, index) => {
  //         if (hardwareElPos <= 2 && index <= 2) {
  //           horizontalPos = item?.horizontalPos ? item?.horizontalPos : 0;
  //           verticalPos = item?.verticalPos ? item?.verticalPos : 0;
  //         } else if (hardwareElPos > 2 && index > 2) {
  //           horizontalPos = item?.horizontalPos ? item?.horizontalPos : 0;
  //           verticalPos = item?.verticalPos ? item?.verticalPos : 0;
  //         }
  //       });
  //     } else if (selectedHardware.type === "bar handles offset") {
  //       customModelData?.hardware?.barHandlesOffset.forEach((item, index) => {
  //         horizontalPos = item?.horizontalPos ? item?.horizontalPos : 0;
  //         verticalPos = item?.verticalPos ? item?.verticalPos : 0;
  //       });
  //     }
  //     else {
  //       Object.values(customModelData?.hardware).forEach((item) => {
  //         if (item[0]?.type === selectedHardware?.type) {
  //           horizontalPos = item[0]?.horizontalPos ? item[0]?.horizontalPos : 0;
  //           verticalPos = item[0]?.verticalPos ? item[0]?.verticalPos : 0;
  //         }
  //       });
  //     }
  //   }

  //   // setHorizontalPos(horizontalPos);
  //   // setVerticalPos(verticalPos);
  // }, [customModelData, selectedHardware]);

  // This is for showing the position and saved color
  useEffect(() => {
    if (
      selectedHardware &&
      selectedHardware?.type &&
      multiSelectRefPoints &&
      multiSelectRefPoints?.length === 1
    ) {
      let item = customModelData?.hardware[selectedHardware?.type]?.find(
        (ele) => ele?.index === multiSelectRefPoints[0]?.index
      );
      if (item) {
        setHorizontalPos(parseFloat(item?.horizontalPos) || 0);
        setVerticalPos(parseFloat(item?.verticalPos) || 0);
        sethandleSides(item?.side || 3)
      }
      if (
        hardwareColours?.length > 0 &&
        item?.colorId &&
        multiSelectRefPoints[0]?.index === item?.index &&
        !isCustomRal
      ) {
        let foundColorItemIndex = hardwareColours?.findIndex(
          (ele) => ele?.id == item?.colorId
        );
        if (foundColorItemIndex) {
          setActiveColorIndex(foundColorItemIndex);
        }
      } else if (
        colorListDrop?.length > 0 &&
        item?.colorId &&
        multiSelectRefPoints[0]?.index === item?.index &&
        isCustomRal
      ) {
        let foundColorItemIndex = colorListDrop?.findIndex(
          (ele) => ele?.id == item?.colorId
        );
        if (foundColorItemIndex) {
          setActiveColorIndex(foundColorItemIndex);
        }
      }
    }
  }, [
    selectedHardware,
    multiSelectRefPoints,
    hardwareColours,
    colorListDrop,
    isCustomRal,
  ]);

  useEffect(() => {
    if (
      matchColor &&
      hardwareColours?.length > 0 &&
      colorListDrop?.length > 0 &&
      customModelData?.frame?.externalColor
    ) {
      const externalColorId = customModelData?.frame?.externalColor?.custom_Id;
      const hexColor =
        externalColorId === ""
          ? customModelData?.frame?.externalColor?.hex
          : "";

      let foundColor = null;
      let foundIndex = null;

      const findInList = (list) => {
        if (!list?.length) return null;

        if (externalColorId !== "") {
          const color = list?.find((item) => item?.id === externalColorId);
          const index = list?.findIndex((item) => item?.id === externalColorId);
          return { color, index };
        } else {
          const color = list?.find((item) => item?.hex === hexColor);
          const index = list?.findIndex((item) => item?.hex === hexColor);
          return { color, index };
        }
      };

      // Check hardwareColours first
      let result = findInList(hardwareColours);
      if (!result?.color) {
        const ralPrice = hardwareColours?.find(
          (item) => item?.chartColourType == false
        );
        // console.log("RAL PRICE=>",ralPrice);
        
        // Check colorListDrop if not found in hardwareColours
        result = findInList(colorListDrop);
        // console.log("Resule517",result);
        if(result?.color && result?.color!==undefined){
          result.color.price = ralPrice?.price || 0;
        }
      }

      foundColor = result?.color || null;
      foundIndex = result?.index ?? -1;

      if (foundColor) {
        setHardwareColor(foundColor);
        setShowColorError(false);
      }
      if (foundIndex !== -1) setActiveColorIndex(foundIndex);
      if (!foundColor) {
        setShowColorError(true);
      }
    } else {
      setShowColorError(false);
    }
  }, [
    matchColor,
    hardwareColours,
    colorListDrop,
    customModelData,
    isCustomRal,
  ]);

  const noRefCheck = (tab) => {
    setActiveTab(tab);
  };

  const handleCancel = () => {
    setHardwareData();
    setEditHardware(false);
  };

  const handlePos = (param) => {
    if (param === "increase") {
      if (hardwareElPos < numHardware) {
        setHardwareElPos(hardwareElPos + 1);
      }
    } else if (param === "decrease") {
      if (hardwareElPos !== 0) {
        setHardwareElPos(hardwareElPos - 1);
      }
    }
  };

  const captureSides = (e) => {
    const value = e?.target?.value;
    sethandleSides(typeof value === "string" ? value.split(",") : value);
  };

  // const saveData = () => {
  //   setIsHardwareSave(true);
  //   if (selectedHardware?.type !== "trickle") {
  //     if (selectedHardware?.type === "cylinder") {
  //       setCustomModelData((prevModelData) => ({
  //         ...prevModelData,
  //         hardware: {
  //           ...prevModelData.hardware,
  //           cylinder: [getModifiedData("cylinder", data)],
  //         },
  //       }));
  //     }
  //     if (selectedHardware?.type === "numeral") {
  //       setCustomModelData((prevModelData) => ({
  //         ...prevModelData,
  //         hardware: {
  //           ...prevModelData.hardware,
  //           numeral: getModifiedData("numeral", data),
  //         },
  //       }));
  //     }
  //     if (selectedHardware?.type === 'letterplate') {
  //       setCustomModelData((prevModelData) => ({
  //         ...prevModelData,
  //         hardware: {
  //           ...prevModelData.hardware,
  //           letterplate: [getModifiedData("letterplate", data)],
  //         },
  //       }));
  //     }
  //     if (selectedHardware?.type === 'spyhole') {
  //       setCustomModelData((prevModelData) => ({
  //         ...prevModelData,
  //         hardware: {
  //           ...prevModelData.hardware,
  //           spyHole: [getModifiedData("spyhole", data)],
  //         },
  //       }));
  //     }
  //     else {
  //       setCustomModelData((prevModelData) => ({
  //         ...prevModelData,
  //         hardware: {
  //           ...prevModelData.hardware,
  //           handle: [getModifiedData("handle", data)],
  //         },
  //       }));
  //     }
  //   } else {
  //     setCustomModelData((prevModelData) => ({
  //       ...prevModelData,
  //       hardware: {
  //         ...prevModelData.hardware,
  //         trickleVent: [getModifiedData("trickle", trickleData)],
  //       },
  //     }));
  //   }
  //   setISave(true)
  // }

  // const getModifiedData = (type, data) => {
  //   let obj;
  //   if (data) {
  //     if (type === "handle") {
  //       obj = {
  //         ...data,
  //         position: {
  //           horizontal: horizontalPos,
  //           vertical: verticalPos,
  //           side: handleSides,
  //         },
  //       };
  //     } else {
  //       obj = {
  //         ...data,
  //         position: {
  //           horizontal: horizontalPos,
  //           vertical: verticalPos,
  //         },
  //       };
  //     }
  //   } else {
  //     if (type === "cylinder") {
  //       obj = {
  //         ...customModelData?.hardware?.cylinder[0],
  //         position: {
  //           horizontal: horizontalPos,
  //           vertical: verticalPos,
  //         },
  //       };
  //     } else if (type === "handle") {
  //       obj = {
  //         ...customModelData?.hardware?.handle[0],
  //         position: {
  //           horizontal: horizontalPos,
  //           vertical: verticalPos,
  //           side: handleSides,
  //         },
  //       };
  //     }
  //     else if (type === "numeral") {
  //       obj = {
  //         ...customModelData?.hardware?.numeral,
  //         position: {
  //           horizontal: horizontalPos,
  //           vertical: verticalPos,
  //         },
  //       };
  //     }
  //     else if (type === 'spyhole') {
  //       console.log("spyhole");
  //     } else if (type === 'letterplate') {
  //       console.log("letterplate");
  //     }
  //     else {
  //       obj = {
  //         ...customModelData?.hardware?.trickleVent[0],
  //         position: {
  //           horizontal: horizontalPos,
  //           vertical: verticalPos,
  //         },
  //       };
  //     }
  //   }

  //   return obj;
  // };

  const getModifiedData = (type, data) => {
    let obj = {
      position: {
        horizontal: horizontalPos,
        vertical: verticalPos,
      },
    };

    if (data) {
      obj = { ...data };
    } else {
      switch (type) {
        case "cylinder":
          obj = {
            ...customModelData?.hardware?.cylinder[0],
            horizontalPos: horizontalPos,
            verticalPos: verticalPos,
          };
          break;
        case "numeral":
          obj = [
            {
              ...customModelData?.hardware?.numeral[0],
              horizontalPos: horizontalPos,
              verticalPos: verticalPos,
            },
          ];
          break;
        case "spyhole":
          obj = [
            {
              ...customModelData?.hardware?.spyhole[0],
              horizontalPos: horizontalPos,
              verticalPos: verticalPos,
            },
          ];
          break;
        case "letterplate":
          console.log("letterplate");
          break;
        case "knocker":
          obj = [
            {
              ...customModelData?.hardware?.knocker[0],
              horizontalPos: horizontalPos,
              verticalPos: verticalPos,
            },
          ];
          break;
        case "escutcheon":
          obj = [
            {
              ...customModelData?.hardware?.escutcheon[0],
              horizontalPos: horizontalPos,
              verticalPos: verticalPos,
            },
          ];
          break;
        case "trickle":
          obj = [
            // ...customModelData?.hardware?.trickleVent,
            {
              ...customModelData?.hardware?.trickleVent[0],
              horizontalPos: horizontalPos,
              verticalPos: verticalPos,
            },
          ];
          break;
        default:
          console.log("Wrong case");
          break;
      }
    }

    return obj;
  };

  const handleColor = (item, index) => {
    if (
      // item?.name !== "Custom RAL"
      item?.chartColourType
    ) {
      setHardwareColor(item);
      if (!data.name) {
        let obj = {
          ...selectedHardware,
          name: selectedHardware?.name,
          color: item.name,
          price: selectedHardware?.price,
          type: selectedHardware?.type,
          colorPrice: item?.price,
        };
        // setData(prevData => [...prevData, obj]);
        setData(obj);
        // setData({
        //   ...data,
        //   name: selectedHardware?.name,
        //   color: item.name,
        //   type: selectedHardware?.type,
        //   price:selectedHardware?.price,
        //   colorPrice:item?.price
        // });
      } else {
        const ralPrice = hardwareColours?.find(
          (item) => item?.chartColourType == false
        );
        let obj = {
          ...selectedHardware,
          color: item.name,
          type: selectedHardware?.type,
          price: selectedHardware?.price,
          colorPrice: ralPrice,
        };
        setHardwareColor(item);
        setData(obj);
        // setData({
        //   ...data,
        //   color: item.name,
        //   type: selectedHardware?.type,
        //   colorPrice:item?.price,
        // });
      }

      setActiveColorIndex(index);
      setIsColorSelected(true);
      setIsCustomRal(false);
    } else {
      setIsCustomRal(true);
      setActiveColorIndex(index);
    }
  };

  const handleExternalColor = (item, index) => {
    setHardwareColor(item);
    setTrickleData({
      name: selectedHardware?.name,
      // internalColor: selectedHardware?.internalColor,
      internalColor: customModelData?.hardware?.trickleVent[0].internalColor,
      externalColor: item.name,
      type: "trickle",
    });
    setActiveExternalColorIndex(index);
  };

  const handleInternalColor = (item, index) => {
    setHardwareColor(item);
    setTrickleData({
      name: selectedHardware?.name,
      // externalColor: selectedHardware?.externalColor,
      externalColor: customModelData?.hardware?.trickleVent[0].externalColor,
      internalColor: item.name,
      type: "trickle",
    });
    setActiveInternalColorIndex(index);
  };

  const saveGlazingService = async (data) => {
    // setLoading(true)
    const res = await setModelData(
      accessToken,
      collectionId,
      frameStyleProdId?.id,
      data,
      modelJson
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false);
        setMessage(res?.data?.message);
        setMessageType("success");
        setIsCustomRal(false);
        setMultiSelectRefPoints([]);
      } else {
        setLoading(false);
        setMessage(res?.data?.message);
        setMessageType("error");
        setIsCustomRal(false);
        setMultiSelectRefPoints([]);
      }
    } else {
      setLoading(false);
      setMessageType("error");
      setMessage(
        res?.data?.message ? res?.data?.message : "Something went wrong !"
      );
      handleError(res, false, navigate);
    }

    handleCancel()
  };

  const glazingQuotationService = async (data) => {
    setLoading(true);
    const res = await setQuotationModelData(
      accessToken,
      collectionId,
      frameStyleProdId?.id,
      data,
      quotationId,
      receivedProductId,
      modelJson
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false);
        setMessage(res?.data?.message);
        setMessageType("success");
        setIsCustomRal(false);
        setMultiSelectRefPoints([]);
      }
    } else {
      setLoading(false);
      setMessageType("error");
      setMessage(
        res?.data?.message ? res?.data?.message : "Something went wrong !"
      );
      setIsCustomRal(false);
      setMultiSelectRefPoints([]);
      handleError(res, false, navigate);
    }

    handleCancel()
  };

  const getHardwareColorsData = async () => {
    const res = await getHardwareColorServices(accessToken, selectedStyle);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setHardwareColours(res?.data?.entity);
      }
    } else {
      setHardwareColours([]);
    }
  };

  const colorListServices = async () => {
    const res = await colorList(accessToken);
    setColorListDrop(res?.data?.entity);
  };

  const handleStyle = (item, index) => {
    if (item?.type === "handle") {
      setHardwareElPos(item?.index);
    } else if (item?.type === "hinge") {
      setHardwareElPos(index);
    } else if (item?.type === "bar handles offset") {
      setHardwareElPos(item?.index);
    }
    setSelectStyle(item?.id);
    setActiveStyleIndex(index);
    if (selectedHardware?.type !== "trickle") {
      let newObj = {
        name: item?.name,
        color: hardwareColor?.name,
        type: selectedHardware?.type,
      };
      // Use the spread operator to create a new array with the previous data and the new object
      setData((prevData) => [...prevData, newObj]);
    } else {
      let newTrickleData = {
        name: item?.name,
        externalColor: customModelData?.hardware?.trickleVent[0].externalColor,
        internalColor: customModelData?.hardware?.trickleVent[0].internalColor,
        type: selectedHardware?.type,
      };
      // Use the spread operator to create a new array with the previous trickle data and the new object
      setTrickleData((prevTrickleData) => [...prevTrickleData, newTrickleData]);
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <Nav tabs className="details_header border-0 nav-tabs-custom bg-none">
        <NavItem>
          <NavLink
            className={`${activeTab === "style" && "active"}`}
            onClick={() => noRefCheck("style")}
          >
            Style
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={`${activeTab === "colour" && "active"}`}
            onClick={() => noRefCheck("colour")}
          >
            Colour
          </NavLink>
        </NavItem>

        {/* {selectedHardware?.type !== "trickle" ? (
          <NavItem>
            <NavLink
              className={`${activeTab === "colour" && "active"}`}
              onClick={() => noRefCheck("colour")}
            >
              Colour
            </NavLink>
          </NavItem>
        ) : (
          <>
            <NavItem>
              <NavLink
                className={`${activeTab === "external" && "active"}`}
                onClick={() => noRefCheck("external")}
              >
                External
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "internal" && "active"}`}
                onClick={() => noRefCheck("internal")}
              >
                Internal
              </NavLink>
            </NavItem>
          </>
        )} */}

        <NavItem>
          <NavLink
            className={`${activeTab === "position" && "active"}`}
            onClick={() => noRefCheck("position")}
          >
            Position
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
        <TabPane tabId="style" className="flex-grow-1 w-100">
          <div className="panel_object_list">
            {selectedHardware?.type === "numeral" && (
              <DynamicDropdown
                arr={customModelData?.hardware?.numeral[0]?.numbers}
                setMethod={setCustomModelData}
              />
            )}
            {styleOptions &&
              styleOptions.length > 0 &&
              styleOptions.map((item, index) => (
                <div
                  className="position-relative d-flex align-items-center cursor-pointer"
                  key={index}
                >
                  <div
                    className={`right_item w-100} ${
                      activeStyleIndex === index && "active"
                    }`}
                    // onClick={() => setData(selectedHardware)}
                    onClick={() => handleStyle(item, index)}
                  >
                    <span className="me-2">
                      {item?.imagePath ? (
                        <Image src={item?.imagePath} width={50} height={50} />
                      ) : (
                        <BsTools color="#989898" size={20} />
                      )}
                    </span>
                    <div className="right_title">
                      <span className="text-truncate">{item?.name}</span>
                    </div>
                  </div>
                </div>
              ))}
            <div className="d-flex align-items-center">
              <Button
                color="light"
                size="md"
                className="px-4 w-100 d-flex justify-content-between fw-medium mb-2 mt-4"
                onClick={() => setDeleteHardware(true)}
              >
                <BiTrash size={20} />
                Delete
                <span></span>
              </Button>
              <div className="d-flex mt-3 ms-3">
                <Button
                  color="light"
                  size="md"
                  className="btn-icon"
                  onClick={() => handlePos("decrease")}
                >
                  <FaChevronLeft size={16} />
                </Button>{" "}
                <Button
                  color="light"
                  size="md"
                  className="btn-icon ms-2"
                  onClick={() => handlePos("increase")}
                >
                  <FaChevronRight
                    className="text-dark cursor-pointer"
                    size={16}
                  />
                </Button>
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="colour" className="w-100">
          {multiSelectRefPoints?.length > 0 ? (
            <>
              {isCustomRal && (
                <div className="text-dark">
                  <NavLink
                    onClick={() => setIsCustomRal(false)}
                    className="text-link mb-3"
                  >
                    <BiArrowBack size={20} className="" />
                    <span className="ms-1">Back</span>
                  </NavLink>
                </div>
              )}
              <div className="panel_object_list">
                {isCustomRal ? (
                  <>
                    {colorListDrop &&
                      colorListDrop?.length > 0 &&
                      colorListDrop?.map((item, index) => {
                        return (
                          <div
                            className="position-relative d-flex align-items-center right_item_wrap"
                            key={index}
                            onClick={() => {
                              // handleColor(item, index);
                              const ralPrice = hardwareColours?.find(
                                (item) => item?.chartColourType == false
                              );
                              let obj = {
                                ...item,
                                colorPrice: ralPrice?.price,
                              };
                              setHardwareColor(obj);
                              setData(obj);
                            }}
                          >
                            <NavLink
                              to={{}}
                              className={`right_item w-100 ${
                                activeColorIndex === index && "active"
                              }`}
                            >
                              <span className="me-2">
                                <span
                                  className="me-2 small_svg_set modal_select_hide image_icon p-2 color_circle dot_lg"
                                  style={{
                                    backgroundColor: `${item.rgbValue}`,
                                    borderRadius: "50%",
                                    width: "fit-content",
                                  }}
                                ></span>
                              </span>
                              <div className="right_title">
                                <span className="text-truncate">
                                  {item.name + " " + item.number}
                                </span>
                              </div>
                            </NavLink>
                          </div>
                        );
                      })}
                  </>
                ) : (
                  <>
                    <FormGroup
                      className="form-group mt-1 position-relative d-flex align-items-center right_item_wrap"
                      check
                    >
                      <Input
                        type="checkbox"
                        name="matchColor"
                        id="matchColor"
                        onChange={(e) => setMatchColor(!matchColor)}
                        checked={matchColor}
                      />
                      <Label check for="matchColor" className="text-dark ms-2">
                        Apply as existing frames
                      </Label>
                    </FormGroup>
                    {showColorError && (
                      <div className="position-relative d-flex align-items-center right_item_wrap mb-2">
                        <span className="active right_item w-100">
                          There is no matching color with frame.
                        </span>
                      </div>
                    )}
                    {hardwareColours &&
                      hardwareColours.map((item, index) => {
                        return (
                          <div
                            className={`position-relative d-flex align-items-center right_item_wrap cursor-pointer`}
                            key={index}
                            onClick={() => {
                              if (item?.chartColourType) {
                                handleColor(item, index);
                              } else {
                                setIsCustomRal(true);
                              }
                              setMatchColor(false)
                            }}
                          >
                            <div
                              className={`right_item w-100 ball_shadow} ${
                                activeColorIndex === index && "active"
                              }`}
                            >
                              <span className="me-2 image_icon ball_shape ball_shadow">
                                <Image
                                  src={
                                    item?.chartColourType ||
                                    item?.chartColourType == undefined
                                      ? item?.capturedImage
                                      : multicolorball
                                  }
                                  alt="icon1"
                                  className="rounded-circle"
                                />
                              </span>

                              <div className="right_title ms-2">
                                <span className="text-truncate">
                                  {item.name}
                                </span>
                              </div>
                            </div>
                            <span className="position-absolute action_btns"></span>
                          </div>
                        );
                      })}
                  </>
                )}
                <div className="d-flex align-items-center">
                  <Button
                    color="light"
                    size="md"
                    className="px-4 w-100 d-flex justify-content-between fw-medium mb-2 mt-4"
                    onClick={() => setDeleteHardware(true)}
                  >
                    <BiTrash size={20} />
                    Delete
                    <span></span>
                  </Button>
                  <div className="d-flex mt-3 ms-3">
                    <Button
                      color="light"
                      size="md"
                      className="btn-icon"
                      onClick={() => handlePos("decrease")}
                    >
                      <FaChevronLeft size={16} />
                    </Button>{" "}
                    <Button
                      color="light"
                      size="md"
                      className="btn-icon ms-2"
                      onClick={() => handlePos("increase")}
                    >
                      <FaChevronRight
                        className="text-dark cursor-pointer"
                        size={16}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
              <span>
                <MdInfoOutline size={18} />
              </span>
              <span>Select a reference point to see the colour list.</span>
            </Alert>
          )}
        </TabPane>

        <TabPane tabId="external" className="w-100">
          <div className="panel_object_list">
            {hardwareColours &&
              hardwareColours.map((item, index) => {
                return (
                  <div
                    className={`position-relative d-flex align-items-center right_item_wrap cursor-pointer`}
                    key={index}
                    onClick={() => handleExternalColor(item, index)}
                  >
                    <div
                      className={`right_item w-100 ball_shadow} ${
                        activeExternalColorIndex === index && "active"
                      }`}
                    >
                      {/* <span className="me-2">
                      <MdPersonOutline size={20} />
                    </span> */}
                      {item?.chartColourType ? (
                        <RotatingCube
                          elements={item?.filePath}
                          roughnessVal={item?.roughness}
                          colorVal={item?.hex}
                          metalNessVal={item?.metalness}
                          bumpiness={item?.bumpiness}
                          textureVal={item?.filePath}
                          // capturedAccordion={capturedAccordion}
                          // onImageCapture={handleCaptureComplete}
                        />
                      ) : (
                        <Image
                          src={multicolorball}
                          alt="icon1"
                          className="rounded-circle"
                        />
                      )}
                      <div className="right_title ms-2">
                        <span className="text-truncate">{item.name}</span>
                      </div>
                    </div>
                    <span className="position-absolute action_btns"></span>
                  </div>
                );
              })}
            <div className="d-flex align-items-center">
              <Button
                color="light"
                size="md"
                className="px-4 w-100 d-flex justify-content-between fw-medium mb-2 mt-4"
                onClick={() => setDeleteHardware(true)}
              >
                <BiTrash size={20} />
                Delete
                <span></span>
              </Button>
              <div className="d-flex mt-3 ms-3">
                <FaChevronLeft
                  className="text-dark me-2"
                  size={25}
                  onClick={() => handlePos("decrease")}
                />{" "}
                <FaChevronRight
                  className="text-dark"
                  size={25}
                  onClick={() => handlePos("increase")}
                />
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="internal" className="w-100">
          <div className="panel_object_list">
            {hardwareColours &&
              hardwareColours.map((item, index) => {
                return (
                  <div
                    className={`position-relative d-flex align-items-center right_item_wrap cursor-pointer`}
                    key={index}
                    onClick={() => handleInternalColor(item, index)}
                  >
                    <div
                      className={`right_item w-100 ball_shadow} ${
                        activeInternalColorIndex === index && "active"
                      }`}
                    >
                      {/* <span className="me-2">
                      <MdPersonOutline size={20} />
                    </span> */}
                      <RotatingCube
                        elements={item?.filePath}
                        roughnessVal={item?.roughness}
                        colorVal={item?.hex}
                        metalNessVal={item?.metalness}
                        bumpiness={item?.bumpiness}
                        textureVal={item?.filePath}
                        // capturedAccordion={capturedAccordion}
                        // onImageCapture={handleCaptureComplete}
                      />
                      <div className="right_title ms-2">
                        <span className="text-truncate">{item.name}</span>
                      </div>
                    </div>
                    <span className="position-absolute action_btns"></span>
                  </div>
                );
              })}
            <div className="d-flex align-items-center">
              <Button
                color="light"
                size="md"
                className="px-4 w-100 d-flex justify-content-between fw-medium mb-2 mt-4"
                onClick={() => setDeleteHardware(true)}
              >
                <BiTrash size={20} />
                Delete
                <span></span>
              </Button>
              <div className="d-flex mt-3 ms-3">
                <FaChevronLeft
                  className="text-dark me-2"
                  size={25}
                  onClick={() => handlePos("decrease")}
                />{" "}
                <FaChevronRight
                  className="text-dark"
                  size={25}
                  onClick={() => handlePos("increase")}
                />
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="position" className="w-100">
          {multiSelectRefPoints?.length > 0 ? (
            <>
              {selectedHardware.type !== "cylinder" && (
                <>
                  <div>
                    <FormGroup className="form-group">
                      <Label for="thickness" className="text-dark">
                        Horizontal
                      </Label>

                      <NumberInputNegative
                        name="thickness"
                        value={horizontalPos}
                        setValue={setHorizontalPos}
                      />
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup className="form-group">
                      <Label for="thickness" className="text-dark">
                        Vertical
                      </Label>
                      <NumberInputNegative
                        name="thickness"
                        value={verticalPos}
                        setValue={setVerticalPos}
                      />
                    </FormGroup>
                  </div>
                </>
              )}
              {(selectedHardware?.type === "bar handles offset" ||
                selectedHardware?.type === "handle" ||
                selectedHardware?.type === "escutcheon") && (
                <div>
                  <FormGroup className="form-group">
                    <Label for="side" className="text-dark">
                      Side
                    </Label>
                    <Select
                      labelId="printdoc-label"
                      id="side"
                      value={handleSides}
                      label="side"
                      onChange={captureSides}
                      input={
                        <OutlinedInput className="w-100 bg-white" name="bump" />
                      }
                      MenuProps={MenuProps}
                      IconComponent={() => (
                        <MdOutlineKeyboardArrowDown
                          size={20}
                          className="me-1 MuiSvgIcon-root MuiSelect-icon"
                        />
                      )}
                    >
                      {handleSideData?.map((item, index) => {
                        return (
                          <MenuItem value={item?.id} key={index}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormGroup>
                </div>
              )}
              <div className="d-flex align-items-center">
                <Button
                  color="light"
                  size="md"
                  className="px-4 w-100 d-flex justify-content-between fw-medium mb-2 mt-4"
                  onClick={() => setDeleteHardware(true)}
                >
                  <BiTrash size={20} />
                  Delete
                  <span></span>
                </Button>
                <div className="d-flex mt-3 ms-3">
                  <Button
                    color="light"
                    size="md"
                    className="btn-icon"
                    onClick={() => handlePos("decrease")}
                  >
                    <FaChevronLeft size={16} />
                  </Button>{" "}
                  <Button
                    color="light"
                    size="md"
                    className="btn-icon ms-2"
                    onClick={() => handlePos("increase")}
                  >
                    <FaChevronRight
                      className="text-dark cursor-pointer"
                      size={16}
                    />
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
              <span>
                <MdInfoOutline size={18} />
              </span>
              <span>Select a reference point to change the position.</span>
            </Alert>
          )}
        </TabPane>
      </TabContent>
      <div className="d-flex gap-2 gap-xl-3 main_buttons mt-4">
        <Button
          color="primary"
          size="md"
          className="px-4"
          onClick={() => saveData()}
        >
          Save
        </Button>

        <Button
          color="primary"
          size="md"
          outline
          className="px-4"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default HardwareConfig;
