import React, { useEffect } from 'react'
import Left from './left'
import { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDesigner } from '../../../../../context/designerContext'
import { useRightContext } from '../../../../../context/rightMenuContext'
import { useMessage } from '../../../../../context/messageContext'
import { ToastContainer, toast } from 'react-toastify';

const Designer = () => {
    const navigate = useNavigate()
    const location = useLocation();

    const [loading, setLoading] = useState(false)
    const [modelVisible, setModelVisible] = useState(false);
    const [captureImages, setCaptureImages] = useState(false)
    const [isNewFrame, setIsNewFrame] = useState(false)

    const { designerAccess, setDesignerAccess } = useDesigner()
    const { userDataContext } = useRightContext()

    const receivedDataLocal = location.state ? location.state.data : undefined
    const is_new_fame = location.state ? location.state.is_new : false

    useEffect(() => {
        setIsNewFrame(false)
        setCaptureImages(false)

        window.onload = (event) => {
            const e = event || window.event;
            // Cancel the event
            e.preventDefault();

            var quotationId = sessionStorage.getItem("quotationId")
            var collectionId = sessionStorage.getItem("collectionId")

            if (quotationId) {
                navigate(`/quotation/${quotationId}`)
            } else if (collectionId) {
                navigate(
                    `/product/systems/styles/list`,
                    { state: { data: collectionId } }
                );
                // navigate(`/product/systems/style-collections/collection/${collectionId}`)
            } else {
                navigate('/')
            }
        };

        return () => {
            sessionStorage.removeItem("collectionId")
            sessionStorage.removeItem("quotationId")
            sessionStorage.removeItem("productId")
        };
    }, []);

    useEffect(() => {
        if (is_new_fame) {
            setIsNewFrame(true)
        } else {
            setIsNewFrame(false)
        }
    }, [is_new_fame, receivedDataLocal])

    useEffect(() => {
        if(receivedDataLocal === undefined && (userDataContext && (userDataContext.role === "Admin" || userDataContext.role === "Super Admin" || userDataContext.role === "Supplier"))) {
            setDesignerAccess(true)
        } else {
            setDesignerAccess(false)
        }
    }, [receivedDataLocal, userDataContext, location]);

    const { message, messageType, setMessage } = useMessage();

    useEffect(() => {
        if (message) {
            toast.dismiss();

            toast(message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                type: messageType === 'success' ? 'success' : 'error',
            });
        }
        setMessage(''); // Clear message after displaying
    }, [message, messageType, setMessage]);

    return (
        <>
        {/* <ToastContainer /> */}

            {(loading || !modelVisible) && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}

            {(captureImages) && (
                <div className='loader_main full_loader save_images'>
                    <div className='d-flex align-items-center gap-3'>
                        <Oval
                            height={50}
                            width={50}
                            color="#44C8F5"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#44C8F5"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />

                        <span className='fs-5 text-muted fw-500'>Saving data</span>
                    </div>
                </div>
            )}

            <Left
                loading={loading}
                setLoading={setLoading}
                setModelVisible={setModelVisible}
                modelVisible={modelVisible}
                receivedDataLocal={receivedDataLocal}
                captureImages={captureImages}
                setCaptureImages={setCaptureImages}
                is_new_fame={is_new_fame}
                isNewFrame={isNewFrame}
                setIsNewFrame={setIsNewFrame}
            />
        </>
    )
}

export default Designer