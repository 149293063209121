import React, { memo, useEffect, useReducer, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { useDesigner } from '../../../context/designerContext';
import { deepEqual, getObjectData, getSideFromFrameType, mapNumberToRange, rgbStringToHex, toCamelCase } from '../../../utility/helper';
import { handleSideData, modelData, servicePath } from '../../../utility/data';
import { getFrameStyles, getInternalColorsWithPrice, getModelData, getQuotationModelProperty } from '../../../services/3dModelServices';
import ConfigurationPoints from '../../../components/3DComponents/configurationPoints';
import { updateFrameStyleAppenture } from '../../../services/commonServices';
import { getFramesProfilesDefault, saveModelData, saveModelDataInit, saveModelDataQuotation, updateShapedCornerData } from '../../../utility/designerHelper';
import { captureAndSaveImages, getModelRenderedSize, setFrameCameraView, updateReferencePoints } from '../../../utility/threeJsHelpers/general';
import { resizeAdditionalFrame } from '../../../components/3DComponents/ResizeAdditionalFrame';
import { addPartialHeights, addPartialWidths, addUiAddOnSillH, addUiAddOnSillV, addUiLinesFrameH, addUiLinesFrameV, calculateFrameSillOffsets, equalPartialWidths, getAllProfileRef, recalculateHeightRatios, recalculateWidthRatios, removeFrameLines, removeFrameSillAddLines, removeLayoutLines } from '../../../utility/threeJsHelpers/uiLinesHelper';
import { useRightContext } from '../../../context/rightMenuContext';
import EditPartialWidths from '../EditPartialWidths.js';
import useFrameModifier from '../../../components/3DComponents/CreateUpdateAddedFrames';
import EditFrameSizeModel from '../../../components/3DComponents/EditFrameSizeModel';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useMessage } from '../../../context/messageContext';
import { createWindowFrame, getAddOnRef, getFrameRefs, getJointsRef, updateFrameDimension, updateFrameProfile, deleteFrameProfile, getAllOffSet } from './createWindowFrame.js';

import useDebounce from '../../customHooks/customHook.js';
import { updateColor, updateColorExt } from './common.js';
import { deleteHangingFromTransoms,updateSashInTransom, deleteSashProfile, editHangings, getSashHangingRefs, getSasProfilehRefs, handlePartialWidthChange, loadDefaultSashProfile, removeHangings, updateSashProfile } from './sashes.js';
import { removeSill, removeSillData, sillHornLength, storeSill, updateHornLengthData } from './sills.js';
import useDataStorage from '../../../utility/customHooks/useDataStorage.js';
import { deleteTransomSplit, getTransomPartitionRefs, getTransomRefs, manipulateTransoms } from './transoms.js';
import { addAddOns, deleteAddOns } from './addOns.js';
import { addHardwareOnSash, addHinges, deleteHardwareFromSash, deleteHardwareOnFrame, deleteMultipleHardwareFromProfile, flipHandleDirection, storeHardwareOnFrame, storeMultipleHardwaresOnProfile, updateHardwareOnFrame, updateHardwareOnSash, updateMultiplHardwareOnProfile } from './hardwares.js';
import { applyGlazingSpec, applyTexture, removeTexture } from './glazing.js';
import { centerCoordinates, getModelFileUrl } from './utils/generalUtils.js';
import { applyPanels, deletePanels } from './panels.js';
import EditPartialHeights from '../EditPartialHeights.js';
import { getSplitValue } from './utils/threeUtils.js';
import ShowDimensionDivisions from '../ShowDimensionDivisions.js';

const BlockPanelDesign = memo(({ sashHardwares, manufacturingToggle, initialLoad, setInitialLoad, receivedProductId, modelVisible, setModelVisible, collectionDetails, cameraRef, currentModel, setLoading, layoutSizing, hardwareType, setHardwareType, allFrameCollection, setLayoutSizing, selectedAnimation, quotationId, modelId, headerSelectedItem, allHardware, numeralsText, numeralsFont, newAllHardware, maxZoom, setSaveAllImages, captureImages, setCaptureImages, addOnList,
	setDataLoaded, setGotData, verifyModels, isModelChange, setIsModelChange, setImageSrc }) => {

	const environmentHDR = `${process.env.PUBLIC_URL}/cloudy_1.hdr`;

	const addOnFrames = "";
	const modelWrap = useRef(null);
	const containerRef = useRef(null);
	const frameStyleRef = useRef([]);
	const partialDimensionRef = useRef(false);
	const navigate = useNavigate()

	const { setMessage, setMessageType } = useMessage()

	const { userDataContext } = useRightContext()

	const { updateAddedFrame, changeAddedFrameAngle, saveAdditionalFrameData } = useFrameModifier()

	const {
		editHardware,
		changeHandleDirection,
		colorActiveTab,
		setColorActiveTab,
		internalColor,
		externalColor,
		frameAngle,
		applyAngle,
		internalRAL,
		glazingSpec,
		externalRAL,
		frameObject,
		modelHeight,
		modelWidth,
		setModelWidth,
		setModelHeight,
		shapedHeight,
		shapedWidth,
		saveHeightWidth,
		defaultSpec,
		setNumHardware,
		hardwareColor,
		horizontalPos,
		verticalPos,
		handleSides,
		productsData,
		hornLength,
		deleteFrameObject,
		deleteHardware,
		transomType,
		setToggleHelper,
		toggleHelper,
		cancelChanges,
		setCancelChanges,
		initialProfile,
		bayPostList,
		deletePressed,
		setDeletePressed,
		overrideWidth,
		overrideHeight,
		panelData,
		setPanelData,
		multiSelectRefPoints, setMultiSelectRefPoints,
		checkSingleSide, setCheckSingleSide, checkClickedProfile, setCheckClickedProfile,
		frameProfiles, sashProfileType, saveFrameCustomization, sashHangingNo, deleteItem, sashRadioActive,
		windowHandleOrientation, deleteSashHanging, setSashHangingNo,
		deletePanel, setDeletePanel, setInternalColor, setInternalRAL, setExternalColor, setExternalRAL,
		shapeFrame, sandblastPricing, hardwareReferencePoint, uiLinesLocation, setUiLinesLocation,
		setApplyPartialTrigger, applyPartialTrigger, lockRefIndex, lockRefIndexV, applyPartialObj,
		setLockRefIndex, setLockRefIndexV, setLockRefPoints, setLockRefPointsV, setModelImages, modelJson,
		setModelJson, wide, high, newModelId, newStyleId, setNewModelId, setNewStyleId, jsonIndex,
		setJsonIndex, resizeFrame, setResizeFrame, setWide, setHigh, texture, setTexture
	} = useDesigner();

	const [modelInitialied, setModelInitialied] = useState(false)
	const [tickleVent, setTickleVent] = useState([])
	const [elementData, setElementData] = useState([])
	const [doorHandleData, setDoorHandleData] = useState([])
	const [uniqueSideRefernce, setUniqueSideRefernce] = useState([])
	const [newFrameData, setNewFrameData] = useState([])
	const [frameDrop, setFrameDrop] = useState(false)
	const [styleDrop, setStyleDrop] = useState(false)
	const [frameType, setFrameType] = useState()
	const [styleType, setStyleType] = useState()
	const [popoverIndex, setPopoverIndex] = useState(null)
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const [isUniqueSide, setIsUniqueSide] = useState(false)
	const [tansomsReference, setTansomsReference] = useState([])
	const [panelRefPoints, setPanelRefPoints] = useState([])
	const [checkDeleteSide, setCheckDeleteSide] = useState()
	const [spyHoleRefPoints, setSpyHoleRefPoints] = useState([])
	const [defaultSillScale, setDefaultSillScale] = useState()
	const [handleDeleted, setHandleDeleted] = useState(false)
	const [elementDeleted, setElementDeleted] = useState(false)
	const [masterSlave, setMasterSlave] = useState(false)
	const [frameProfileDefault, setFrameProfileDefault] = useState('')
	const [frameData, setFrameData] = useState(null);
	const [isHangingLoaded, setIsHangingLoaded] = useState(false)
	const [saveColors, setSaveColors] = useState(false)
	const [hingeRefPoints, setHingeRefPoints] = useState([])
	const [panelInitAdded, setPanelInitAdded] = useState(false)
	const [bayPostDeg, setBayPostDeg] = useState(bayPostList[0]?.id)
	const [bayPostAngleManual, setBayPostAngleManual] = useState(120)
	const [allStyleCollection, setAllStyleCollection] = useState([])
	const [refreshUiLines, setRefreshUiLines] = useState(false)
	const [refreshAfterSave, setRefreshAfterSave] = useState(false)
	const [saveJsonData, setSaveJsonData] = useState(false)
	const [resizeSashStyles, setResizeSashStyles] = useState(false)
	const [addSillRatioV, setAddSillRatioV] = useState([])
	const [addSillRatioH, setAddSillRatioH] = useState([])

	const [renderedSize, setRenderedSize] = useState({
		width: 2500,
		height: 2100,
	})

	const { customModelData, setCustomModelData, addedFrameData, setAddedFrameData, setAddedFrameList, addedFrameList, hangingPosition, setFrameObject } = useDesigner()

	const [isHardwareAdded, setIsHardwareAdded] = useState({
		knocker: false,
		escutcheon: false,
		spyhole: false,
		trickleVent: false,
		numeral: false,
		handle: false,
	})

	let cameraControls;
	let mixer;
	let gltfModel = useRef(null)
	// let glass1, glass2; 
	let loader;
	let tickleVentReference = [];
	const tickleVentRef = useRef([]);
	const handleRef = useRef([]);
	const shapeRef1 = useRef();
	const shapeRef2 = useRef();
	let handleReference = [];
	let heightRef = [];
	let widthRef = [];
	let sashRef = [];
	const addFrameRef = useRef([]);
	const transomRelativePosition = useRef([]);
	const testRef = useRef([]);
	const uiLinesRef = useRef([]);
	const uiLinesRefBottom = useRef([]);
	const uiLinesRefFrame = useRef([]);
	const addSillUiRefV = useRef([]);
	let addSillCombine = useRef([]);
	const addSillUiRefH = useRef([]);
	let allProfileRefSeq = useRef([]);
	let glassRefSeq = useRef([]);
	// const hardwareTypeRef = useRef(null);
	const internalFrameRef = useRef([]);
	const externalFrameRef = useRef([]);
	const defaultGlassMaterial = useRef(null);
	const defaultFrameMaterial = useRef(null);
	const windowRef = useRef([]);
	const handleInternal = useRef([]);
	const handleExternal = useRef([]);
	const tickleVentModels = useRef([]);
	const handlePosition = useRef([]);
	const removeReplacedSash = useRef([]);
	// const trickleVent
	const allTrickleVents = useRef([]);
	// const handlePositionInternal = useRef([]);
	const tickleVentPosition = useRef([]);
	const cylinderPosition = useRef([]);
	const handleCylinder = useRef([]);
	const sillRef = useRef(null);
	const sillExtRef = useRef(null);
	const addonRef = useRef([]);
	const sillsHeightRef = useRef([]);
	const glass1 = useRef(null);
	const glass2 = useRef(null);
	const glassGroup = useRef([]);
	const frameStyle = useRef([]);
	const addedFrame = useRef([]);
	const transoms = useRef([]);
	const bead = useRef([]);
	const hingeModels = useRef([]);
	const addedFramePosition = useRef([]);
	const frameStyleTop = useRef(null);
	const frameStyleBottom = useRef(null);
	const frameStyleLeft = useRef(null);
	const newFrameStyleSlant = useRef(null);
	const frameStyleRight = useRef(null);
	const windowHeight = useRef(null);
	const windowWidth = useRef(null);
	const frameSash = useRef([]);
	const Frame = useRef([]);
	const SpaceBar = useRef([]);
	const sashGroup = useRef([]);
	const transomFrame = useRef([]);
	const transomFrameSortedX = useRef([]);
	const transomFrameSortedY = useRef([]);
	const replacedSashProfile = useRef([]);
	const handleObjects = useRef([]);
	const tickleObjects = useRef([]);
	const addedSash = useRef([]);
	const sceneRef = useRef(null);
	const widthLine = useRef(null);
	const heightLine = useRef(null);
	const shadowPlaneRef = useRef(null);
	const orbitControlRef = useRef(null);
	const elementRef = useRef(null);
	const elementUiRef = useRef(null);
	const objectGroupCol = useRef(null);
	const objectGroupRow = useRef(null);
	const rowStorage = useRef([])
	const colStorage = useRef([]);
	const beadData = useRef(null);
	const handlePath = useRef(null);
	const ticklePath = useRef(null);
	const rendererInitialised = useRef(null);
	const sashHangingAmount = useRef(null);
	const spyHoleModel = useRef([]);
	const spyHolePosition = useRef([]);
	const letterPlateModel = useRef([]);
	const letterPlatePos = useRef([]);
	const knockerModel = useRef([]);
	const knockerPos = useRef([]);
	const sashList = useRef([]);
	const textMeshRef = useRef(null);
	const textMeshPos = useRef(null);
	const previousTransomFrameRef = useRef(null);
	const orientationClicked = useRef(null);
	const radioButtonClicked = useRef(null);
	const transomData = useRef(null);
	const mullionData = useRef(null)
	const escutcheonExternalModel = useRef([]);
	const escutcheonInternalModel = useRef([]);
	const transomPosition = useRef([]);
	const escutcheonExternalPos = useRef([])
	const addedHandles = useRef([]);
	const addedBarHandles = useRef([])
	const windowSash = useRef([]);
	const addedBarHandlesPos = useRef([]);
	const barHandlesOffsetExternal = useRef([])
	const barHandlesOffsetInternal = useRef([])
	const barHandlesOffsetInternalPos = useRef([])
	const barHandlesOffsetExternalPos = useRef([])
	const newFrameRefPoints = useRef([])
	const allLockingPlates = useRef([])
	const storeGrooveCylinder = useRef([])
	const storeGroveCube = useRef([])
	const storeSashes = useRef([])
	const clippedPlanes = useRef([])
	const getUpdatedFrameRefs = useRef([])
	const extraStorage = useRef([])
	const storeBayPost = useRef(null)
	const topLeftCorner = useRef(null);
	const topRightCorner = useRef(null)
	const cornersLeftFrame = useRef({
		currentTopR: null,
		currentBottomR: null,
		currentBottom: null,
		glassScale: null,
		frameLeftBoundingBox: null,
		frameTopBoundingBox: null,
		frameRightBoundBox: null
	})

	const cornersRightFrame = useRef({
		currentTopL: null,
		currentBottomL: null,
		currentBottom: null,
		glassScale: null,
		frameLeftBoundingBox: null,
		frameTopBoundingBox: null,
		frameRightBoundBox: null
	})

	const perpFrameTopBoundingBox = useRef({
		leftProfile: null,
		rightProfile: null
	})

	const allOffSetData = getAllOffSet()


	let sashHandles = useRef([])
	let masterSlaveElements = useRef([])
	const isHeritageDoor = useRef([])
	const glazeModelRef = useRef();

	const [clipGlaze, setClipGlaze] = useState([])

	const windowHandleRefPoint = useRef([]);
	const windowHangingSash = useRef([]);
	// const windowHandleOrientation = useRef('right');
	const glazingRef = useRef([]);
	let beadDefaultScale = null;
	let hingePosition = useRef([])
	// const panelGlazingRef = useRef([])

	const glassText = useRef([]);
	const framePathCollection = useRef([]);

	const debouncedValue = useDebounce(sashHangingNo, 300)
	const debounceShapedHeight = useDebounce(shapedHeight, 500)
	const debouncShapedWidth = useDebounce(shapedWidth, 500)
	const debouncedFrameWidth = useDebounce(modelWidth, 600)
	const debouncedFrameHeight = useDebounce(modelHeight, 600)
	const debounceHardwareHPosition = useDebounce(horizontalPos, 200);
	const debouceHardwareVPostion = useDebounce(verticalPos, 200);

	const { storeFrameProfile, storeSashProfile, updateSashData, storeAddOnsData, saveHardwareData, updateDeletedAddOnData, deleteHardwareData, updateHardwarePositionColor, storeTransomData, storeSpecData, deleteHangings, deletePanelData, deletePanelDataForTransoms, saveTextureData, deleteTextureData, deleteTextureDataForTransoms } = useDataStorage()


	let font;
	// const outerContainerRef = useRef(null);
	let pointInScreen;
	const accessToken = localStorage.getItem('access_token')
	let renderer;
	var camera;
	let scene;
	var newFrameStyles

	if (rendererInitialised.current !== 4) {
		// Dispose of any existing renderer if necessary
		if (renderer) {
			renderer.dispose();
			renderer.forceContextLoss(); // Explicitly lose the WebGL context
			renderer = null;
		}

		// Create a new renderer
		renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });

		// Set renderer properties
		renderer.localClippingEnabled = true;

		// Create a new camera and scene
		camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.25, 30);
		scene = new THREE.Scene();

		// Update the initialization flag
		rendererInitialised.current = 4;
	}


	// useEffect(() => {
	// 	setRefreshUiLines(false)
	// }, [manufacturingToggle])

	useEffect(() => {
		if (!manufacturingToggle) {
			setMultiSelectRefPoints([]);
			testRef.current = [];
			newFrameRefPoints.current = [];
			forceUpdate();
			setPopoverIndex(null);
		}
	}, [manufacturingToggle])

	useEffect(() => {
		resetPartialLocks()
		setColorActiveTab('')
	}, [selectedAnimation?.id])

	useEffect(() => {
		if (allFrameCollection && allFrameCollection?.length > 0 && modelId) {
			frameStyleService(allFrameCollection[0]?.id)
		}
	}, [allFrameCollection, modelId])

	useEffect(() => {
		if (hardwareType === "customize frame style" && multiSelectRefPoints[0]) {
			handleFrameProfiles()
		}
	}, [frameProfiles])

	useEffect(() => {
		if (deleteSashHanging && hardwareType === 'sashHanging'
			// && modelJson[jsonIndex]?.transom 
			// && modelJson[jsonIndex]?.transom?.splits?.length
		) {
			console.log("Am i here ?");

			handleDeleteHangings()
		}
	}, [deleteSashHanging])


	useEffect(() => {
		if ((hardwareType === 'transomVertical' || hardwareType === "transomHorizontal") && checkClickedProfile) {
			handleTransom(checkClickedProfile?.name, 2, "transoms", null, null, null)
		}
	}, [checkClickedProfile, transomType])

	useEffect(() => {
		if ((hardwareType === 'transomVertical' || hardwareType === "transomHorizontal") && multiSelectRefPoints[0]) {
			handleUpdateTransom(multiSelectRefPoints[0])
		}
	}, [transomType])


	useEffect(() => {
		if (deleteItem === "transoms" && multiSelectRefPoints[0]) {
			handleDeleteTransom(multiSelectRefPoints[0])
		}
	}, [deletePressed])

	// const updateTransom = (selectedTransom) => {

	// 	const transomConfig = {
	// 		data : {
	// 			newHeight : transomType?.height,
	// 			newDepth : transomType?.width
	// 		},
	// 		sectionId : selectedTransom?.data?.name
	// 	}


	// 	const updatedJson = updateTransomSplit(transomConfig, jsonIndex)
	// 	setModelJson((prevModelJson) => {
	// 		const newJson = updatedJson(prevModelJson)
	// 		createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef, true);
	// 		return newJson
	// 	})
	// }


	const handleUpdateTransom = (selectedRef) => {

		const ratioHeight = (modelHeight / modelHeight);
		const ratioWidth = (modelWidth / modelWidth);
		const orientation = hardwareType === "transomVertical" ? "vertical" : "horizontal";
		const halves = orientation === 'vertical' ? ratioWidth / 2 : ratioHeight / 2;
		const initialRatios = [halves, halves]
		const sectionId = checkClickedProfile?.name;
		const selectedTransom = selectedRef?.data?.name;

		let transomsConfig = {
			orientation,
			initialRatios,
			transomWidth: transomType?.width,
			transomHeight: transomType?.height,
			sectionId,
			selectedTransom,
		}

		const updatedJson = manipulateTransoms(transomsConfig, transomType, storeTransomData, jsonIndex, deletePanelDataForTransoms)
		setModelJson((prevModelJson) => {
			const newJson = updatedJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef, true);
			return newJson
		})

		// setTimeout(async () => {
		// 	const hadSash = sceneRef.current.getObjectByName("SashRight") ? true : false;
		// 	const splitValues = await getSplitValue(sceneRef.current, sceneRef.current, hadSash);
		// 	console.log(splitValues, "456 :::: ");

		// 	setModelJson((prevModelJson) => {
		// 		const updateModelArray = [...prevModelJson]
		// 		const updateJson = { ...updateModelArray[jsonIndex] }
		// 		updateJson.sash.partialWidthRatios = splitValues.widthSplit;
		// 		updateJson.sash.partialHeightRatios = splitValues.heightSplit;

		// 		updateModelArray[jsonIndex] = updateJson;
		// 		return updateModelArray;
		// 	})

		// 	setCustomModelData((prevModelData) => {
		// 		const updateJson = { ...prevModelData };
		// 		updateJson.partialHeightRatios = splitValues.heightSplit;
		// 		updateJson.partialWidthRatios = splitValues.widthSplit;
		// 		return updateJson;
		// 	})
		// }, 100);

		setTimeout(() => {
			testRef.current = getTransomPartitionRefs();
			newFrameRefPoints.current = getTransomRefs();
			setCheckClickedProfile()
			setCheckSingleSide()
			// setMultiSelectRefPoints([])
			// setTransomType()
		}, 0);
	}

	const handleTransom = (sectionId, sections, type, side, handle, mullion) => {
		const ratioHeight = (modelHeight / modelHeight);
		const ratioWidth = (modelWidth / modelWidth);
		const orientation = hardwareType === "transomVertical" ? "vertical" : "horizontal";
		// const halves = orientation === 'vertical' ? ratioWidth / 2 : ratioHeight / 2;
		let initialRatios = []

		if (orientation === "vertical") {
			const division = ratioWidth / sections;
			initialRatios = Array.from({ length: sections }, () => division)
		} else {
			const division = ratioWidth / sections;
			initialRatios = Array.from({ length: sections }, () => division)
		}

		const defaultSash = initialProfile?.sash.find((item) => item.defaultProfile === true);


		let transomsConfig = {
			orientation,
			initialRatios,
			transomWidth: transomType?.width,
			transomHeight: transomType?.height,
			sectionId,
			selectedTransom: null,
			sashHeight: defaultSash?.height,
			sashWidth: defaultSash?.width,
			sashId: defaultSash?.id,
			sashMullion: mullion
		}


		const updatedJson = manipulateTransoms(transomsConfig, transomType, storeTransomData, jsonIndex, deletePanelDataForTransoms, type, sections, side, handle)
		setModelJson((prevModelJson) => {
			const newJson = updatedJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef, true);
			return newJson
		})

		setTimeout(async () => {
			const hadSash = sceneRef.current.getObjectByName("SashRight") ? true : false;
			const splitValues = await getSplitValue(sceneRef.current, sceneRef.current, hadSash);

			setModelJson((prevModelJson) => {
				const updateModelArray = [...prevModelJson]
				const updateJson = { ...updateModelArray[jsonIndex] }
				if (orientation === "horizontal") {
					updateJson.sash.partialHeightRatios = splitValues.heightSplit;
				} else if (orientation === "vertical") {
					updateJson.sash.partialWidthRatios = splitValues.widthSplit;
				}
				updateModelArray[jsonIndex] = updateJson;
				return updateModelArray;
			})

			setCustomModelData((prevModelData) => {
				const updateJson = { ...prevModelData };
				updateJson.partialHeightRatios = splitValues.heightSplit;
				updateJson.partialWidthRatios = splitValues.widthSplit;
				return updateJson;
			})
		}, 100);

		setTimeout(() => {
			if(hardwareType !== "sashHanging"){
				testRef.current = getTransomPartitionRefs();
				newFrameRefPoints.current = getTransomRefs();
			}
			setCheckClickedProfile()
			setCheckSingleSide()
		}, 0);


					// if(updateJson?.sash?.sections && updateJson?.sash?.sections?.length){
				// 	let getarray = countHorizontalTransoms(updateJson?.sash); 
				// 	console.log(getarray , "486");

				// 	getarray.forEach((item , index) => {
				// 		if ((item > 1) && (updateJson?.sash?.sections[index]?.splits[0].orientation === "vertical")) {
				// 			const tempPartialWidth = [...updateJson.sash.partialWidthRatios]
				// 			let widthArr = tempPartialWidth.slice(index, index + getarray[index]);
				// 			let sumOfWidths = widthArr.reduce((acc, value) => acc + value, 0);
				// 			widthArr = widthArr.map((width) => width / sumOfWidths)
				// 			updateJson.sash.sections[index].splits[0].ratios = widthArr;     
				// 		}
				// 		else if ((item > 1) && (updateJson?.sash?.sections[index]?.splits[0].orientation === "horizontal")) {
				// 			const tempPartialHeight = [...updateJson.sash.partialHeightRatios]
				// 			let heightArr = tempPartialHeight.slice(index, index + getarray[index]);
				// 			let sumOfHeights = heightArr.reduce((acc, value) => acc + value, 0);
				// 			heightArr = heightArr.map((width) => width / sumOfHeights)
				// 			updateJson.sash.sections[index].splits[0].ratios = heightArr;     
				// 		}
				// 	})
				// }
	}


	
	const handleDeleteTransom = (deleteItem) => {
		const updateJson = deleteTransomSplit(deleteItem, jsonIndex, deletePanelDataForTransoms)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef, true);
			return newJson;
		})

		setTimeout(async () => {
			const hadSash = sceneRef.current.getObjectByName("SashRight") ? true : false;
			const splitValues = await getSplitValue(sceneRef.current, sceneRef.current, hadSash);
			// console.log(splitValues , "481");

			setModelJson((prevModelJson) => {
				const updateModelArray = [...prevModelJson]
				const updateJson = { ...updateModelArray[jsonIndex] }
				if (deleteItem?.data?.type === "horizontal") {
					updateJson.sash.partialHeightRatios = splitValues.heightSplit;
				} else if (deleteItem?.data?.type === "vertical") {
					updateJson.sash.partialWidthRatios = splitValues.widthSplit;
				}
				updateModelArray[jsonIndex] = updateJson;
				return updateModelArray;
			})

			setCustomModelData((prevModelData) => {
				const updateJson = { ...prevModelData };
				updateJson.partialHeightRatios = splitValues.heightSplit;
				updateJson.partialWidthRatios = splitValues.widthSplit;
				return updateJson;
			})
		}, 100);

		setTimeout(() => {
			testRef.current = getTransomPartitionRefs();
			newFrameRefPoints.current = getTransomRefs();
			setMultiSelectRefPoints([])
		}, 0);
	}

	useEffect(() => {
		if ((hardwareType === "spec" || hardwareType === "glazing") && glazingSpec) {
			handleApplySpec()
		}
	}, [glazingSpec])

	useEffect(() => {
		if (hardwareType === "glazingPanel" && panelData) {
			handleApplyPanel()
		}
	}, [panelData])

	useEffect(() => {
		if (hardwareType === "texture" && texture && texture !== "None") {
			handleApplyTexture()
		}
		else if (hardwareType === "texture" && texture && texture === "None") {
			handleRemoveTexture()
		}
	}, [texture])

	useEffect(() => {
		if (deletePanel) {
			handleDeletePanel()
		}
	}, [deletePanel])


	const handleApplySpec = () => {
		const id = multiSelectRefPoints[0]?.data?.name;
		const index = multiSelectRefPoints[0] ? multiSelectRefPoints[0]?.index : -1;
		const updateJson = applyGlazingSpec(glazingSpec, id, index, jsonIndex, storeSpecData)

		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
	}

	const handleApplyPanel = () => {
		const id = multiSelectRefPoints[0]?.data?.name;
		const index = multiSelectRefPoints[0] ? multiSelectRefPoints[0]?.index : -1;
		const updateJson = applyPanels(panelData, id, checkSingleSide, jsonIndex, checkClickedProfile?.name)

		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setCheckSingleSide()
		setPanelData()
	}

	const handleDeletePanel = () => {
		const id = multiSelectRefPoints[0]?.data?.name;
		const index = multiSelectRefPoints[0] ? multiSelectRefPoints[0]?.index : -1;
		const updateJson = deletePanels(id, index, jsonIndex, checkSingleSide, deletePanelData, checkClickedProfile?.name)

		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})

		setDeletePanel(false)
		setPanelData()
		setCheckSingleSide()
	}

	const handleApplyTexture = () => {
		const id = multiSelectRefPoints[0]?.data?.name;
		const index = multiSelectRefPoints[0] ? multiSelectRefPoints[0]?.index : -1;
		const updateJson = applyTexture(texture, id, checkSingleSide, jsonIndex, id !== undefined ? id : "", saveTextureData)

		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setCheckSingleSide()
		setTexture()
	}

	// console.log("Mit734", multiSelectRefPoints);


	const handleRemoveTexture = () => {
		const id = multiSelectRefPoints[0]?.data?.name;
		const index = multiSelectRefPoints[0] ? multiSelectRefPoints[0]?.index : -1;
		const updateJson = removeTexture(id, index, jsonIndex, checkSingleSide, deleteTextureData, id !== undefined ? id : "")

		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setCheckSingleSide()
		setTexture()
	}

	const handleFrameProfiles = () => {

		const profileName = multiSelectRefPoints[0]?.data?.name

		const updatedJson = updateFrameProfile(frameProfiles, profileName, storeFrameProfile, jsonIndex);
		setModelJson((prevModelJson) => {
			const newJson = updatedJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		});
		setToggleHelper(false)
		setMultiSelectRefPoints([])
	}

	const handleDeleteHangings = () => {
		let updatedJson = null
		if (modelJson[jsonIndex]?.transom
			&& !modelJson[jsonIndex]?.transom?.splits?.length) {
			updatedJson = removeHangings(jsonIndex, deleteHangings)
			setModelJson((prevModelJson) => {
				const newJson = updatedJson(prevModelJson);
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
				return newJson;
			});
		}
		else {
			const updateJson = deleteHangingFromTransoms(multiSelectRefPoints[0], jsonIndex, deletePanelDataForTransoms)
			setModelJson((prevModelJson) => {
				const newJson = updateJson(prevModelJson)
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef, true);
				return newJson;
			})
		}

		// setModelJson((prevModelJson) => {
		// 	const newJson = updatedJson(prevModelJson);
		// 	createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
		// 	return newJson;
		// });

		setTimeout(() => {
			getSashRef()
			setRefreshUiLines(true)
			setMultiSelectRefPoints([])
		}, 200);
	}

	useEffect(() => {
		if (externalColor && hardwareType === "color") {
			let updatedJson = updateColorExt(externalColor?.hex);

			if (!externalColor?.chartColourType && externalRAL !== undefined) {
				updatedJson = updateColorExt(rgbStringToHex(externalRAL?.rgbValue));
			}

			setModelJson((prevModelJson) => {
				const newJson = updatedJson(prevModelJson);
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
				return newJson;
			});
		}
	}, [externalColor, externalRAL])

	useEffect(() => {
		if (deleteItem === "addon" && deletePressed) {
			handleAddOnDelete()
		}
	}, [deleteItem])


	const handleAddOnDelete = () => {
		const name = multiSelectRefPoints[0]?.data.name;

		const side = getSideFromFrameType(name);

		const updateJson = deleteAddOns(side, updateDeletedAddOnData, jsonIndex);

		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
	}

	useEffect(() => {
		if (multiSelectRefPoints.length > 0 && applyAngle) {
			let isTop;
			let isLeft;
			multiSelectRefPoints.forEach((item) => {
				if (item.data.name.includes("topPerpendicular")) {
					isTop = "top"
				} else if (item.data.name.includes("bottomPerpendicular")) {
					isTop = "bottom"
				} else if (item.data.name.includes("rightPerpendicular")) {
					isLeft = true
				} else if (item.data.name.includes("leftPerpendicular")) {
					isLeft = false
				}

				changeAddedFrameAngle(storeBayPost.current, item.data, frameAngle, isTop, isLeft, gltfModel.current, frameStyleTop)
			})
		}
	}, [applyAngle])


	useEffect(() => {
		if ((customModelData && (customModelData?.frame?.framesAndCoupler && customModelData?.frame?.framesAndCoupler?.length > 0) || (customModelData?.frame?.bayPost && customModelData?.frame?.bayPost?.length > 0)) || (customModelData && customModelData?.addedFrames?.length)) {
			let isPerpendicular = false
			let isTop = false
			let isBottom = false
			if (multiSelectRefPoints && multiSelectRefPoints.length) {
				multiSelectRefPoints.forEach((item) => {
					if (item.data.name.includes("rightPerpendicular") || item.data.name.includes("leftPerpendicular")) {
						isPerpendicular = true
						isTop = false
						isBottom = false
					} else if (item.data.name.includes("topPerpendicular")) {
						isPerpendicular = true
						isTop = true
						isBottom = false
					} else if (item.data.name.includes("bottomPerpendicular")) {
						isPerpendicular = true
						isTop = false
						isBottom = true
					}

					if (overrideHeight || overrideWidth) {
						resizeAdditionalFrame(item.data, isPerpendicular, isTop, isBottom, overrideHeight, overrideWidth, headerSelectedItem, topLeftCorner, sceneRef, topRightCorner)

						// const foundItem = customModelData?.addFrames?.find((ele) =>
						// 	deepEqual(ele?.position, item?.data?.position)
						// );

						setCustomModelData((prevData) => {
							const updatedFrames = prevData.addedFrames.map((frame) => {
								if (deepEqual(frame?.position, item?.data?.position)) {
									return {
										...frame,
										height: overrideHeight !== frame?.height ? overrideHeight : frame.height,
										width: overrideWidth !== frame?.width ? overrideWidth : frame.width,
									};
								}
								return frame;
							});

							return {
								...prevData,
								addedFrames: updatedFrames,
							};
						});
					}

				})
			}

			// temporarily using extra storage to refeed the ref as it was getting emptied on save
			if (hardwareType && hardwareType?.name === "Frame") {
				newFrameRefPoints.current = extraStorage.current
			}
		}

	}, [saveHeightWidth])

	// useEffect(() => {
	// 	if (deleteItem === "sash") {
	// 		multiSelectRefPoints.forEach((item) => {
	// 			removeSash(item.index, item.data)
	// 		})
	// 	}
	// }, [deletePressed])

	// useEffect(() => {
	// 	if (modelInitialied && spacerBar) {
	// 		modifySpacerBar(spacerBar, true)
	// 	}
	// }, [spacerBar])


	useEffect(() => {
		if (customModelData?.numberOfSash?.number === "0") {
			setCustomModelData((prevData) => ({
				...prevData,
				sashData: []
			}))
			replacedSashProfile.current = []
		}
	}, [customModelData?.numberOfSash?.number])

	useEffect(() => {
		if (modelId) {
			getFramesProfilesDefault(accessToken, modelId, setFrameProfileDefault)
		}
	}, [modelId])

	useEffect(() => {
		if (initialLoad) {
			init()

			handleResize();

			window.addEventListener('resize', handleResize);

			return () => {
				if (renderer) {
					renderer.dispose();
				}
			};
		}
	}, [initialLoad]);


	// Handle window resize
	const handleResize = () => {
		const newWidth = containerRef.current?.clientWidth;
		const newHeight = containerRef.current?.clientHeight;

		// Update renderer size
		renderer?.setSize(newWidth, newHeight);

		if (camera) {
			// Update camera aspect ratio
			camera.aspect = newWidth / newHeight;
			camera.updateProjectionMatrix();
		}
	};

	useEffect(() => {
		if (tickleVent && tickleVent.length) {
			setNumHardware(tickleVent.length)
		}
	}, [tickleVent])

	useEffect(() => {
		if (hangingPosition && multiSelectRefPoints[0]) {
			const zPos = hangingPosition == "External" ? 0.02 : hangingPosition == "Internal" ? -0.02 : 0;
			const selectedIndex = multiSelectRefPoints[0]?.index
			sashGroup.current.forEach((child, index) => {

				const start = selectedIndex * 4;
				const end = start + 3;

				if (index >= start && index <= end) {
					child.position.z = zPos;
				}
			})
		}
	}, [hangingPosition])


	// add sash function for sashanging number
	useEffect(() => {
		if (hardwareType === 'sashHanging' && modelJson[jsonIndex]?.transom && !modelJson[jsonIndex]?.transom?.splits?.length) {
			if (debouncedValue) {
				(async () => {
					await handleSashHangingChange(debouncedValue);
					setTimeout(() => {
						getSashRef();
					}, 500);
				})();
			}
		}
	}, [debouncedValue]);


	// add sash function for sashanging number
	useEffect(() => {
		if (saveFrameCustomization && hardwareType === 'sashHanging') {
			resetPartialLocks()

			if (collectionDetails && collectionDetails.typeId == 1) {

				let partialWidthRatios = equalPartialWidths(customModelData?.layoutFrame?.width / 1000, sashHangingNo)

				if (!receivedProductId) {
					setCustomModelData((prevModelData) => ({
						...prevModelData,
						// sashData: [],
						numberOfSash: {
							number: parseInt(sashHangingNo),
							// wide: wide,
							// high: high
						},
						partialWidthRatios: partialWidthRatios
					}))
				} else {
					setCustomModelData((prevModelData) => ({
						...prevModelData,
						// sashData: [],
						numberOfSash: {
							number: parseInt(sashHangingNo),
							// wide: wide,
							// high: high
						},
						partialWidthRatios: partialWidthRatios
					}))
				}
			}

			if (!receivedProductId) {
				if (wide && high) {
					updateFrameStyleAppenture(accessToken, selectedAnimation?.id, wide, high)
				}
			}
		}
	}, [saveFrameCustomization])

	useEffect(() => {
		if (multiSelectRefPoints[0] && sashProfileType && hardwareType === "sashprofiles") {
			handleUpdateSashProfile()
		}
	}, [sashProfileType])

	useEffect(() => {
		if (multiSelectRefPoints[0] && deleteItem === "sash" && modelJson[jsonIndex]?.transom && !modelJson[jsonIndex]?.transom?.splits?.length) {
			handleDeleteSashProfile()
		}
	}, [deletePressed])


	useEffect(() => {
		if (multiSelectRefPoints[0] && deleteItem === "frameprofile") {
			handleDeleteFrameProfile()
		}
	}, [deletePressed])

	useEffect(() => {
		if (multiSelectRefPoints[0] && hardwareType === "sashHanging" && windowHandleOrientation) {
			handleHangingType()
		}
	}, [windowHandleOrientation])

	const handleHangingType = async () => {
		let handle;
		
		if (collectionDetails.typeId === 1) {
			handle = sashHardwares?.find((item) =>
				["door handle"].includes(item?.name?.toLowerCase())
			);
		} else {
			handle = sashHardwares?.find((item) =>
				["window handle"].includes(item?.name?.toLowerCase())
			);
		}

		const hinge = sashHardwares?.find((item) =>
			["hinge"].includes(item?.name?.toLowerCase())
		);

		const handleStyle = handle ? handle?.hardwareStyles : [];
		const hingeStyle = hinge ? hinge?.hardwareStyles : [];

		const defaultHandle = handleStyle?.length ? handleStyle.find((item) => item?.default === true) : null;
		const defaultHinge = hingeStyle?.length ? hingeStyle.find((item) => item?.default === true) : null;

		const orientation = windowHandleOrientation?.toLowerCase();
		const index = multiSelectRefPoints[0]?.index;
		let handleModel = getModelFileUrl(defaultHandle?.modelFilePath);
		let hingeModel = getModelFileUrl(defaultHinge?.modelFilePath);

		let cylinder = allHardware?.find((item) => item?.name?.toLowerCase() === "cylinder");

		if (!cylinder) {
			console.error("Cylinder not found. Proceeding without cylinder.");
			cylinder = { hardwareStyles: [{}] }; // Provide a default or empty object
		}

		if (!handleModel) {
			setMessage("Handle not configured")
			setMessageType("error");
			return;
		}


		const defaultMullion = initialProfile?.floatingMullion?.find((item) => item.defaultProfile === true);

		let sashHangingNo = 1;

		if (modelJson[jsonIndex]?.transom && !modelJson[jsonIndex]?.transom?.splits?.length) {
			if (modelJson[jsonIndex]?.sash && !modelJson[jsonIndex]?.sash?.sections?.length) {
				sashHangingNo = orientation === "master/slave" || orientation === "slave/master" ? 2 : 1;
				await handleSashHangingChange(sashHangingNo);
			} else if (orientation === "master/slave" || orientation === "slave/master") {
				sashHangingNo = 2;
				await handleSashHangingChange(sashHangingNo);
			}

			const updateModelJson = () => {
				const updatedJson = editHangings(index, orientation, handleModel, saveHardwareData, handle?.hardwareStyles[0], "handle", cylinder?.hardwareStyles[0], jsonIndex, hingeModel, defaultMullion, hinge?.hardwareStyles[0], deleteHardwareData);

				setModelJson((prevModelJson) => {
					const newJson = updatedJson(prevModelJson);
					createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
					return newJson;
				});
			};

			updateModelJson();
		} else {
			sashHangingNo = orientation === "master/slave" || orientation === "slave/master" ? 2 : 1;
			handleTransom(multiSelectRefPoints[0]?.data?.name, sashHangingNo, "sash", orientation, defaultHandle, defaultMullion);
			getSashRef();
			setMultiSelectRefPoints([])
			newFrameRefPoints.current = [];

		}

	};



	const handleSashHangingChange = async (debouncedValue) => {
		return new Promise((resolve) => {
			resetPartialLocks()
			setUiLinesLocation("");
			// setRefreshUiLines(false)

			const initialSpec = defaultSpec?.specification.find((item) => item.default === true);
			const defaultSash = initialProfile?.sash.find((item) => item.defaultProfile === true);

			let partialWidthRatios = equalPartialWidths(modelJson[jsonIndex]?.dimensions?.width, debouncedValue)

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				numberOfSash: {
					number: parseInt(sashHangingNo),
				},
				partialWidthRatios: partialWidthRatios,
				partialHeightRatios: [1],
				sashSizeHeight: [modelJson[jsonIndex]?.dimensions?.width * 1000],
			}));

			const updatedJson = loadDefaultSashProfile(debouncedValue, defaultSash, storeSashProfile, partialWidthRatios, initialSpec, jsonIndex, setMessage, setMessageType);

			setModelJson((prevModelJson) => {
				const newJson = updatedJson(prevModelJson);
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
				return newJson;
			});

			setTimeout(() => {
				getSashRef();
				setMultiSelectRefPoints([]);
				resolve(); // Resolve the promise after updates are complete
			}, 200);

			setTimeout(() => {
				setUiLinesLocation("All");
			}, 300);
		});
	};


	const handleDeleteSashProfile = () => {
		const data = multiSelectRefPoints[0]
		const index = mapNumberToRange(data?.index)
		const orientation = data?.data?.name;
		const updatedJson = deleteSashProfile(index, jsonIndex, orientation);
		setModelJson((prevModelJson) => {
			const newJson = updatedJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson
		})
		setMultiSelectRefPoints([])
	}


	const handleDeleteFrameProfile = () => {
		const data = multiSelectRefPoints[0]
		const orientation = data?.data?.name;
		const updatedJson = deleteFrameProfile(orientation, jsonIndex);
		setModelJson((prevModelJson) => {
			const newJson = updatedJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson
		})
		setMultiSelectRefPoints([])
	}


	const handleUpdateSashProfile = () => {
		const data = multiSelectRefPoints[0]
		const { height, width } = sashProfileType
		const orientation = data?.data?.name
		let profileIndex = data?.index
		const index = mapNumberToRange(data?.index)

		if(modelJson[jsonIndex]?.transom && !modelJson[jsonIndex]?.transom?.splits?.length){
			const updatedJson = updateSashProfile(index, orientation, sashProfileType, customModelData?.sashData, profileIndex, updateSashData, jsonIndex);
			setModelJson((prevModelJson) => {
				const newJson = updatedJson(prevModelJson)
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
				return newJson
			})
		}else{
			const updatedJson = updateSashInTransom(data.data.name , sashProfileType , jsonIndex)
			setModelJson((prevModelData) => {
				const newJson = updatedJson(prevModelData)
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
				return newJson;
			})
		}
		setMultiSelectRefPoints([])
	}

	const handleDeleteObject = (index) => {
		setCheckDeleteSide(index)
	}

	useEffect(() => {
		if (!quotationId && modelId && (handleDeleted || elementDeleted)) {
			saveModelData(accessToken, modelId, selectedAnimation?.id, customModelData, modelJson, navigate, setMessage, setMessageType)
		}

		if (quotationId && modelId && receivedProductId && (handleDeleted || elementDeleted)) {
			saveModelDataQuotation(accessToken, modelId, selectedAnimation?.id, customModelData, quotationId, receivedProductId, navigate, setMessage, setMessageType, modelJson)
		}
	}, [handleDeleted, elementDeleted]);

	// Debounce function to delay calling addTextOnGlass
	let debounceTimer;

	const debounceAddTextOnGlass = (font, text) => {
		clearTimeout(debounceTimer);

		debounceTimer = setTimeout(() => {
			if (multiSelectRefPoints.length > 0) {
				multiSelectRefPoints.forEach((item) => {
					if (numeralsText != '') {
						addTextOnGlass(font, text, item.index);
					} else {
						if (numeralsText == '') {
							removeTextOnGlass(item.index)
						}
					}
				})
			} else {
				if (numeralsText != '') {
					addTextOnGlass(font, text, null);
				} else {
					if (numeralsText == '') {
						removeTextOnGlass(null)
					}
				}
			}
		}, 1000); // Adjust the delay time as needed (e.g., 1000 milliseconds)
	};

	useEffect(() => {
		debounceAddTextOnGlass(numeralsFont, numeralsText);
	}, [numeralsText, numeralsFont])

	useEffect(() => {
		if (!layoutSizing) {
			// forceUpdate()
			testRef.current = []
			// newFrameRefPoints.current = []
		}
	}, [layoutSizing])




	useEffect(() => {

		if (hardwareType) {
			handleHardwares()
			forceUpdate()
		} else {
			setMultiSelectRefPoints([]);
			newFrameRefPoints.current = [];
			testRef.current = [];
			setPopoverIndex(null);
		}



		if (hardwareType && newAllHardware?.length > 0 && hardwareReferencePoint !== null && hardwareType !== "addOn") {
			const selectedHardware = newAllHardware.filter((item) => item?.name === hardwareType?.name)
			setElementData(selectedHardware[0]?.hardwareStyles)
		}

	}, [hardwareType, modelInitialied, hardwareReferencePoint, newAllHardware])


	useEffect(() => {
		if (headerSelectedItem.name === "French Door" && hardwareType === "sashHanging") {
			setElementData(productsData)
		}
	}, [headerSelectedItem, hardwareType])

	useEffect(() => {
		if (hardwareType && hardwareType?.name === "Frame" && modelInitialied) {
			handleHardwares()
		}
	}, [newFrameData, modelInitialied])

	useEffect(() => {
		if (hardwareType !== "sashHanging") {
			if (modelInitialied && customModelData?.hardware && customModelData?.hardware?.handle?.length > 0) {
				customModelData?.hardware?.handle?.forEach((obj, index) => {
					addHandle(obj.file, sashGroup.current[obj.index], true, "", "", obj.id)
				})
			}
		}
	}, [modelInitialied, sashGroup.current])

	const getHardwareApplyDetails = (hardwareModel, orientation, data, profileIndex, type) => {
		const trimmedFilePath = hardwareModel ? hardwareModel?.replace(/\s+/g, '') : "";
		type = type.replace(/\s+/g, '');
		if (type === "doorhandle" || type === "barhandlesoffset" || type === "windowhandle") {
			type = "doorhandle"
		}
		// else if (type==="tricklevent"){
		// 	type="trickle vent"
		// }
		let model = `${servicePath}/ThreeJSModel/Glb/${trimmedFilePath}`;
		const index = mapNumberToRange(profileIndex);

		return { model, index, data, orientation, type }
	}


	useEffect(() => {
		if (editHardware === "edithandle" && multiSelectRefPoints[0]) {
			handleDeleteHardwareSash()
		} else if (editHardware === "edittrickleVent" && multiSelectRefPoints[0]) {
			const { index, orientation, type } = getHardwareApplyDetails("", multiSelectRefPoints[0]?.data?.name, "", multiSelectRefPoints[0]?.index, "tricklevent");
			handleDeleteMultipleHardware("trickleVent", index, orientation, type, false)
		} else if (editHardware === "editspyhole" && multiSelectRefPoints[0]) {
			handleDeleteHardwareFrame()
		} else if (editHardware === "edithinge" && multiSelectRefPoints[0]) {
			const { index, orientation, type } = getHardwareApplyDetails("", multiSelectRefPoints[0]?.data?.name, "", multiSelectRefPoints[0]?.index, "hinge");
			handleDeleteMultipleHardware("hinge", index, orientation, type, true)
		}
	}, [deleteHardware])


	const handleDeleteMultipleHardware = async (hardware, index, orientation, type, clearAll) => {

		let side
		if (orientation.includes("Frame")) {
			side = orientation === "FrameTop" ? "top" : orientation === "FrameRight" ? "right" : orientation === "FrameLeft" ? "left" : "bottom";
		} else {
			side = orientation;
		}
		const updateJson = await deleteMultipleHardwareFromProfile(index, side, type, hardware, deleteHardwareData, jsonIndex, clearAll)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setMultiSelectRefPoints([]);
	}

	const handleDeleteHardwareSash = async () => {
		const { index, orientation, type } = getHardwareApplyDetails("", multiSelectRefPoints[0]?.data?.name, "", multiSelectRefPoints[0]?.index, "doorhandle")
		const updateJson = await deleteHardwareFromSash(multiSelectRefPoints[0]?.index, index, orientation, type, deleteHardwareData, "handle");
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setMultiSelectRefPoints([]);
	}

	const handleDeleteHardwareFrame = () => {
		const { index, orientation, type } = getHardwareApplyDetails("", multiSelectRefPoints[0]?.data?.name, "", multiSelectRefPoints[0]?.index, "spyhole")
		let side
		if (orientation.includes("Frame")) {
			side = orientation === "FrameTop" ? "top" : orientation === "FrameRight" ? "right" : orientation === "FrameLeft" ? "left" : "bottom";
		} else {
			side = orientation;
		}
		let hardwaretype = "";
		if (type === "door handle") {
			hardwaretype = "handle"
		}
		else {
			hardwaretype = toCamelCase(type)
		}

		const updateJson = deleteHardwareOnFrame(multiSelectRefPoints[0]?.index, side, hardwaretype, deleteHardwareData, jsonIndex);
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setMultiSelectRefPoints([]);
	}

	const hardwareRefs = () => {
		testRef.current = [];
		testRef.current = [...getSasProfilehRefs(), ...getFrameRefs()]
	}

	useEffect(() => {
		if (editHardware) {
			forceUpdate()
			hardwareRefs()
		} else {
			testRef.current = []
		}
	}, [editHardware])

	useEffect(() => {
		if (multiSelectRefPoints[0]) {

			const index = mapNumberToRange(multiSelectRefPoints[0]?.index);
			const name = multiSelectRefPoints[0]?.data?.name

			if (editHardware === 'edithandle' && (horizontalPos || verticalPos || hardwareColor || handleSides)) {
				const side = handleSides ? handleSideData.find((item) => item.id === handleSides) : 0
				handleHardwareUpdate(index, name, debounceHardwareHPosition || horizontalPos, debouceHardwareVPostion || verticalPos, hardwareColor, "doorhandle", "handle", multiSelectRefPoints[0]?.index, side)
			} else if (editHardware === 'edittrickleVent' && (horizontalPos || verticalPos || hardwareColor)) {
				handleMultipleHardwareUpdate(index, name, hardwareColor, "tricklevent", "trickleVent", multiSelectRefPoints[0]?.index)
			} else if (editHardware === "editspyhole" && (horizontalPos || verticalPos || hardwareColor)) {
				handleHardwareUpdateOnFrame(index, name, debounceHardwareHPosition || horizontalPos, debouceHardwareVPostion || verticalPos, hardwareColor, "spyhole", multiSelectRefPoints[0]?.index)
			} else if (editHardware === "edithinge" && (horizontalPos || verticalPos || hardwareColor)) {
				handleMultipleHardwareUpdate(index, name, hardwareColor, "hinge", "hinge", multiSelectRefPoints[0]?.index, horizontalPos, verticalPos)
			}
		}
	}, [debouceHardwareVPostion, debounceHardwareHPosition, hardwareColor, handleSides])

	useEffect(() => {
		if (multiSelectRefPoints[0]) {
			const index = multiSelectRefPoints[0]?.index;
			handleHardwareFlip(index)
		}
	}, [changeHandleDirection])

	const handleHardwareUpdate = (index, name, offsetX, offsetY, color, type, hardwareType, hardwareIndex, side) => {
		const updateJson = updateHardwareOnSash(index, name, offsetX, offsetY, color, type, updateHardwarePositionColor, hardwareType, hardwareIndex, jsonIndex, side);
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setMultiSelectRefPoints([]);
	}

	const handleHardwareFlip = (index) => {
		const updateJson = flipHandleDirection(jsonIndex, changeHandleDirection, index);
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setMultiSelectRefPoints([]);
	}

	const handleHardwareUpdateOnFrame = (index, name, offsetX, offsetY, color, type, hardwareIndex) => {
		let side
		if (name.includes("Frame")) {
			side = name === "FrameTop" ? "top" : name === "FrameRight" ? "right" : name === "FrameLeft" ? "left" : "bottom";
		} else {
			side = name;
		}
		const updateJson = updateHardwareOnFrame(index, side, offsetX, offsetY, color, type, hardwareIndex, updateHardwarePositionColor, jsonIndex)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
	}

	const handleMultipleHardwareUpdate = (index, name, color, type, hardwareType, hardwareIndex, horizontalPos, verticalPos) => {

		let side
		if (name.includes("Frame")) {
			side = name === "FrameTop" ? "top" : name === "FrameRight" ? "right" : name === "FrameLeft" ? "left" : "bottom";
		} else {
			side = name;
		}
		const updateJson = updateMultiplHardwareOnProfile(index, side, color, type, hardwareType, hardwareIndex, updateHardwarePositionColor, jsonIndex, horizontalPos, verticalPos)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setPopoverIndex(null)
	}

	useEffect(() => {
		if (hardwareType === "resizing" || hardwareType === "Shaped Frame" || resizeFrame) {
			resizeWindow(debouncedFrameWidth, debouncedFrameHeight, sashRadioActive, false);
		}
	}, [debouncedFrameHeight, debouncedFrameWidth])

	// function to add internal and ral color
	useEffect(() => {
		if (internalColor !== undefined && hardwareType === "color") {

			let updatedJson = updateColor(internalColor?.hex);

			if (
				!internalColor?.chartColourType && internalRAL !== undefined) {
				updatedJson = updateColor(rgbStringToHex(internalRAL?.rgbValue));
			}

			setModelJson((prevModelJson) => {
				const newJson = updatedJson(prevModelJson);
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
				return newJson;
			});
		}
	}, [internalColor, internalRAL])

	useEffect(() => {
		if (saveColors !== '' && saveColors && customModelData && modelId && selectedAnimation?.id) {
			saveModelDataInit(accessToken, modelId, selectedAnimation?.id, customModelData, modelJson, setSaveColors)
		}
	}, [saveColors]);

	useEffect(() => {
		if (frameObject && Object?.keys(frameObject)?.length > 0 && frameStyleBottom.current && Object.getPrototypeOf(frameObject) === Object.prototype) {
			const updateJson = storeSill(frameObject, jsonIndex)
			setModelJson((prevData) => {
				const newJson = updateJson(prevData)
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
				return newJson
			})
		}
	}, [frameObject])

	useEffect(() => {
		if (deleteFrameObject) {
			removeSill(sillRef, sceneRef, sillExtRef)
			const updateJson = removeSillData(setCustomModelData, jsonIndex, setFrameObject)
			setModelJson((prevModelJson) => {
				const newJson = updateJson(prevModelJson)
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
				return newJson;
			})
		}
	}, [deleteFrameObject])

	useEffect(() => {
		if (hornLength >= 0 && sillRef.current) {
			const updateJson = updateHornLengthData(hornLength, jsonIndex)
			setModelJson((prevModelData) => {
				const newJson = updateJson(prevModelData)
				sillHornLength(sillRef, sillExtRef, newJson[jsonIndex], defaultSillScale)
				return newJson;
			})
		}
	}, [hornLength])

	const toggleFrameDrop = () => {
		setFrameDrop(!frameDrop)
	}

	const toggleStyleDrop = () => {
		setStyleDrop(!styleDrop)
	}

	const handleFrameDrop = (data) => {
		setFrameType(data)
	}

	const handleStyleDrop = (data) => {
		setStyleType(data)
	}

	// This will get the data for the frame to be added on the basis of the selected frame collection and style

	useEffect(() => {
		if (modelId && selectedAnimation?.id && newModelId == "" && newStyleId == "" && verifyModels && !quotationId && (isModelChange || cancelChanges)) {
			setIsModelChange(false)
			setUiLinesLocation("All")
			getModelDataServices(modelId, selectedAnimation?.id)
		}

		if (modelId && selectedAnimation?.id && newModelId !== "" && newStyleId !== "" && isModelChange) {
			setIsModelChange(false)
			setUiLinesLocation("All")
			getModelDataServices(newModelId, newStyleId)
		}

		if (cancelChanges && !quotationId) {
			setModelVisible(false)
			setLoading(true)
		}
		//if modelId changed, selectedAnimation?.id will be auto changed
	}, [isModelChange, cancelChanges])

	useEffect(() => {
		if (modelId && newModelId == "" && newStyleId == "" && receivedProductId) {
			setUiLinesLocation("All")
			getQuoteModelDataServices(receivedProductId)
		}

		if (cancelChanges && quotationId) {
			setModelVisible(false)
			setLoading(true)
		}
	}, [receivedProductId, cancelChanges])

	useEffect(() => {
		if (frameType) {
			frameStyleService(frameType?.id)
		}
	}, [frameType])

	useEffect(() => {
		if (addedFrameData && addedFrameData.hasOwnProperty('position')) {
			setAddedFrameList(prevAddedFrameList => {
				if (!prevAddedFrameList.includes(addedFrameData)) {
					return [...prevAddedFrameList, addedFrameData];
				}
				return prevAddedFrameList;
			});
		}
	}, [addedFrameData]);

	const getModelDataServices = async (frameId, styleId) => {
		const res = await getModelData(accessToken, frameId, styleId)

		// setFrameData(frame1); // This will trigger a re-render
		if (res?.status === 200) {
			if (res?.data?.statusCode === 200) {
				setLoading(false)

				if (res?.data?.entity?.jsonblob !== null) {
					setAddedFrameData(JSON.parse(res?.data?.entity?.jsonblob))
				} else {
					setAddedFrameData(modelData)
				}

				const blocksJson = JSON?.parse(res.data.entity.blocksJson);
				const jsonblob = res.data.entity.jsonblob && res.data.entity.jsonblob !== "undefined" ? JSON?.parse(res.data.entity.jsonblob) : null

				if (blocksJson) {
					setModelJson(blocksJson)
					setModelWidth(blocksJson[jsonIndex]?.dimensions?.width * 1000)
					setModelHeight(blocksJson[jsonIndex]?.dimensions?.height * 1000)
				} else {
					setModelJson([])
					setModelWidth(2500)
					setModelHeight(2100)
				}

				if (jsonblob) {
					setCustomModelData(jsonblob)

					var getSavedExtColor = jsonblob?.frame?.externalColor
					var getSavedIntColor = jsonblob?.frame?.internalColor

					var extColor = {
						id: getSavedExtColor?.itemId,
						name: getSavedExtColor?.name,
						hex: getSavedExtColor?.hex,
						primaryId: getSavedExtColor?.primaryId,
						price: getSavedExtColor?.price,
						priceInpercentage: getSavedExtColor?.priceInpercentage,
						barLengthPrice: getSavedExtColor?.barLengthPrice,
						chartColourType: getSavedExtColor?.chartColourType,
					}

					var intColor = {
						id: getSavedIntColor?.itemId,
						name: getSavedIntColor?.name,
						hex: getSavedIntColor?.hex,
						primaryId: getSavedIntColor?.primaryId,
						price: getSavedIntColor?.price,
						priceInpercentage: getSavedIntColor?.priceInpercentage,
						barLengthPrice: getSavedIntColor?.barLengthPrice,
						chartColourType: getSavedIntColor?.chartColourType,
					}

					if (
						!jsonblob?.frame?.externalColor?.chartColourType
					) {
						var json = {
							id: getSavedExtColor?.custom_Id,
							name: getSavedExtColor?.customRALName,
							rgbValue: getSavedExtColor?.rgbValue,
							number: getSavedExtColor?.customRALCode,
						}

						setExternalColor(extColor)
						setExternalRAL(json)
					} else {
						setExternalColor(extColor)
						setExternalRAL()
					}

					if (!jsonblob?.frame?.internalColor?.chartColourType) {
						var json = {
							id: getSavedIntColor?.custom_Id,
							name: getSavedIntColor?.customRALName,
							rgbValue: getSavedIntColor?.rgbValue,
							number: getSavedIntColor?.customRALCode,
						}

						setInternalColor(intColor)
						setInternalRAL(json)
					} else {
						setInternalColor(intColor)
						setInternalRAL()
					}
				} else {
					setCustomModelData(modelData)
				}

				if ((cancelChanges) && blocksJson) {
					setCancelChanges(false)

					createWindowFrame(sceneRef.current, blocksJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef, true);

					setTimeout(() => {
						const initWidth = (blocksJson[jsonIndex]?.dimensions?.width * 1000)
						const initHeight = (blocksJson[jsonIndex]?.dimensions?.height * 1000)

						if (initWidth > 0 && initHeight > 0 && cameraRef.current) {
							setFrameCameraView(0, 1, sceneRef.current, cameraRef, initWidth, initHeight, 0, cameraUiMoved, cameraUiStopped);
						}
					}, 200);

					setTimeout(() => {
						setModelVisible(true)
						setLoading(false)
					}, 500);
				}

				if (!initialLoad) {
					setTimeout(() => {
						setInitialLoad(true)
						setGotData(true)
					}, 300);
				}

				if (newModelId !== '') {
					setNewModelId('')
					setNewStyleId('')
				}
			}
		}
	}

	const getQuoteModelDataServices = async (fetchId) => {
		const res = await getQuotationModelProperty(accessToken, fetchId)

		if (res?.status === 200) {
			if (res?.data?.statusCode === 200) {

				if (res?.data?.entity?.jsonblob !== null) {
					setAddedFrameData(JSON.parse(res?.data?.entity?.jsonblob))
				} else {
					setAddedFrameData(modelData)
				}

				const blocksJson = JSON?.parse(res.data.entity.blocksJson);
				const jsonblob = res.data.entity.jsonblob && res.data.entity.jsonblob !== "undefined" ? JSON?.parse(res.data.entity.jsonblob) : null

				if (jsonblob) {
					setCustomModelData(jsonblob)

					var getSavedExtColor = jsonblob?.frame?.externalColor
					var getSavedIntColor = jsonblob?.frame?.internalColor

					var extColor = {
						id: getSavedExtColor?.itemId,
						name: getSavedExtColor?.name,
						hex: getSavedExtColor?.hex,
						primaryId: getSavedExtColor?.primaryId,
						price: getSavedExtColor?.price,
						priceInpercentage: getSavedExtColor?.priceInpercentage,
						barLengthPrice: getSavedExtColor?.barLengthPrice,
						chartColourType: getSavedExtColor?.chartColourType,
					}

					var intColor = {
						id: getSavedIntColor?.itemId,
						name: getSavedIntColor?.name,
						hex: getSavedIntColor?.hex,
						primaryId: getSavedIntColor?.primaryId,
						price: getSavedIntColor?.price,
						priceInpercentage: getSavedIntColor?.priceInpercentage,
						barLengthPrice: getSavedIntColor?.barLengthPrice,
						chartColourType: getSavedIntColor?.chartColourType,
					}

					if (
						!jsonblob?.frame?.externalColor?.chartColourType
					) {
						var json = {
							id: getSavedExtColor?.custom_Id,
							name: getSavedExtColor?.customRALName,
							rgbValue: getSavedExtColor?.rgbValue,
							number: getSavedExtColor?.customRALCode,
						}

						setExternalColor(extColor)
						setExternalRAL(json)
					} else {
						setExternalColor(extColor)
						setExternalRAL()
					}

					if (!jsonblob?.frame?.internalColor?.chartColourType) {
						var json = {
							id: getSavedIntColor?.custom_Id,
							name: getSavedIntColor?.customRALName,
							rgbValue: getSavedIntColor?.rgbValue,
							number: getSavedIntColor?.customRALCode,
						}

						setInternalColor(intColor)
						setInternalRAL(json)
					} else {
						setInternalColor(intColor)
						setInternalRAL()
					}
				} else {
					setCustomModelData(modelData)
				}

				if (blocksJson) {
					setModelJson(blocksJson)
					setModelWidth(blocksJson[jsonIndex]?.dimensions?.width * 1000)
					setModelHeight(blocksJson[jsonIndex]?.dimensions?.height * 1000)
				} else {
					setModelJson([])
					setModelWidth(2500)
					setModelHeight(2100)
				}

				if (cancelChanges && blocksJson) {
					setCancelChanges(false)
					createWindowFrame(sceneRef.current, blocksJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);

					setTimeout(() => {
						const initWidth = (blocksJson[jsonIndex]?.dimensions?.width * 1000)
						const initHeight = (blocksJson[jsonIndex]?.dimensions?.height * 1000)

						if (initWidth > 0 && initHeight > 0 && cameraRef.current) {
							setFrameCameraView(0, 1, sceneRef.current, cameraRef, initWidth, initHeight, 0, cameraUiMoved, cameraUiStopped);
						}
					}, 200);

					setTimeout(() => {
						setModelVisible(true)
						setLoading(false)
					}, 500);
				}

				if (!initialLoad) {
					setTimeout(() => {
						setInitialLoad(true)
						setGotData(true)
					}, 300);
				}

				if (newModelId !== '') {
					setNewModelId('')
					setNewStyleId('')
				}
			}
		}
	}

	const frameStyleService = async (modelId) => {
		const res = await getFrameStyles(accessToken, modelId)
		if (res && res.status === 200) {
			if (res?.data?.statusCode === 200) {
				setAllStyleCollection(res?.data?.entity)
				setStyleType(res?.data?.entity[0])
			}
		} else {
			setAllStyleCollection([])
		}
	}

	useEffect(() => {
		if (multiSelectRefPoints && multiSelectRefPoints?.length && sashProfileType && sashProfileType.name === "Locking Plate") {
			multiSelectRefPoints.forEach((item) => addLockingPlate(item, sashProfileType))
			setToggleHelper(false)
		}
	}, [sashProfileType, toggleHelper, sashList.current])

	useEffect(() => {
		if (widthLine.current) {
			widthLine.current.visible = false;
			heightLine.current.visible = false;
		}
	}, [layoutSizing])


	const addLockingPlate = (refs, data) => {

		const gltfLoader = new GLTFLoader()
		//add lock plate
		gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${data?.customePath}`, function (gltf) {
			gltf.scene.position.y = refs.data.position.y;

			const boundingBoxPlate = new THREE.Box3().setFromObject(gltf.scene);
			const plateHeight = boundingBoxPlate.max.y - boundingBoxPlate.min.y;
			const plateWidth = boundingBoxPlate.max.x - boundingBoxPlate.min.x

			const boundingBoxGlass = new THREE.Box3().setFromObject(refs.data);

			if (Math.abs(boundingBoxGlass.min.x) < Math.abs(boundingBoxGlass.max.x)) {
				gltf.scene.position.x = boundingBoxGlass.min.x + (plateWidth / 2)
			} else {
				gltf.scene.position.x = boundingBoxGlass.max.x - (plateWidth / 2)
			}

			const glassHeight = boundingBoxGlass.max.y - boundingBoxGlass.min.y;

			gltf.scene.scale.y = glassHeight / plateHeight;
			allLockingPlates.current.push(gltf.scene)
			setDoorHandleData((prevData) => [...prevData, gltf.scene])

			// applying sash colors for internal and external layers
			gltf.scene.traverse((child) => {
				if (child.isMesh) {
					if (child.name.includes("External")) {
						externalFrameRef.current.push(child);
					} else {
						internalFrameRef.current.push(child);
					}
				}
			})

			sceneRef.current.add(gltf.scene);
		})
	}

	const sashHardwareMapping = [
		"window handle",
		"bar handles offset",
		"door handle",
		"spyhole",
	]

	const frameProfileHardwareMapping = [
		"trickle vent",
		"spyhole"
	]

	const handleAddHardware = async (element, pos) => {

		if (hardwareType === 'addOn') {
			const side = getSideFromFrameType(pos?.name)
			const updatedJson = addAddOns(side, element, storeAddOnsData, jsonIndex, popoverIndex);
			setModelJson((prevModelJson) => {
				const newJson = updatedJson(prevModelJson);
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
				return newJson;
			})
			setPopoverIndex(null)
		} else if (sashHardwareMapping.includes(hardwareType?.name) && pos.name.includes("Sash")) {
			handleApplyDoorHandle(element, pos)

		} else if (frameProfileHardwareMapping.includes(hardwareType?.name) && hardwareType.name !== "spyhole") {
			handleApplyMultipleHardware(element, pos)
		} else if (frameProfileHardwareMapping.includes(hardwareType?.name) && pos.name.includes("Frame")) {
			handleApplyHardwareOnFrame(element, pos)
		} else if (hardwareType?.name === "hinge") {
			handleApplyHinge(element, pos)
		}

	}

	const handleApplyHinge = (element, pos) => {
		const { index, model, data, orientation, type } = getHardwareApplyDetails(element?.modelFilePath, pos?.name, element, hardwareReferencePoint, hardwareType?.name)
		const updateJson = addHinges(model, type, index, orientation, data, jsonIndex, saveHardwareData)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setPopoverIndex(null)
	}

	const handleApplyDoorHandle = async (element, pos) => {

		const { index, model, data, orientation, type } = getHardwareApplyDetails(element?.modelFilePath, pos?.name, element, hardwareReferencePoint, hardwareType?.name)
		let cylinder = allHardware?.find((item) => item?.name?.toLowerCase() === "cylinder")
		let hardwaretype = "";

		if (!cylinder) {
			console.error("Cylinder not found. Proceeding without cylinder.");
			cylinder = { hardwareStyles: [{}] }; // Provide a default or empty object
		}

		if (!cylinder) {
			// console.error("Cylinder not found. Proceeding without cylinder.");
			cylinder = { hardwareStyles: [{}] }; // Provide a default or empty object
		}

		if (type === "door handle") {
			hardwaretype = "handle"
		} else {
			hardwaretype = toCamelCase(type)
		}
		const updateJson = await addHardwareOnSash(model, data, hardwareReferencePoint, index, orientation, hardwaretype, saveHardwareData, cylinder?.hardwareStyles[0], jsonIndex)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setPopoverIndex(null)
	}

	const handleApplyMultipleHardware = async (element, pos) => {
		const { index, model, data, orientation, type } = getHardwareApplyDetails(element?.modelFilePath, pos?.name, element, hardwareReferencePoint, hardwareType?.name)
		let side
		side = orientation === "FrameTop" ? "top" : orientation === "FrameRight" ? "right" : orientation === "FrameLeft" ? "left" : "bottom";
		let updateJson
		let hardwaretype = ""
		if (type === "door handle") {
			hardwaretype = "handle"
		}
		else {
			hardwaretype = toCamelCase(type)
		}
		if (orientation.includes("Frame")) {
			updateJson = await storeMultipleHardwaresOnProfile(0, side, model, data, hardwaretype, saveHardwareData, jsonIndex)
		} else {
			updateJson = await storeMultipleHardwaresOnProfile(index, orientation, model, data, hardwaretype, saveHardwareData, jsonIndex)
		}

		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setPopoverIndex(null)
	}


	const handleApplyHardwareOnFrame = async (element, pos) => {

		const { index, model, data, orientation, type } = getHardwareApplyDetails(element?.modelFilePath, pos?.name, element, hardwareReferencePoint, hardwareType?.name);

		const side = orientation === "FrameTop" ? "top" : orientation === "FrameRight" ? "right" : orientation === "FrameLeft" ? "left" : "bottom";
		let hardwaretype = ""
		if (type === "door handle") {
			hardwaretype = "handle"
		}
		else {
			hardwaretype = toCamelCase(type)
		}
		const updateJson = await storeHardwareOnFrame(side, model, hardwaretype, index, data, saveHardwareData, jsonIndex)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		setPopoverIndex(null)
	}


	async function initializeFrame() {
		createWindowFrame(sceneRef.current, modelJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef, true);
		animate();
		setLoading(false)
		setDataLoaded(false)
	}


	// function to initialize model
	async function init() {
		setModelInitialied(false)

		if (scene == null) {
			renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
			renderer.localClippingEnabled = true;
			camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.25, 20);

			scene = new THREE.Scene();
			renderer.localClippingEnabled = true;
		}

		windowWidth.current = 2500;
		windowHeight.current = 2100;
		rendererInitialised.current = 4;
		cameraRef.current = camera;
		const container = containerRef.current;
		sceneRef.current = scene;

		if (container) {
			initRenderer(container);
		}

		setLoading(true);
		loader = new GLTFLoader();
		glassGroup.current = [];
		glazingRef.current = [];
		frameStyle.current = [];
		frameSash.current = [];
		windowRef.current = [];
		frameStyleRef.current = [];
		bead.current = [];
		Frame.current = [];
		transoms.current = [];
		sashGroup.current = [];
		SpaceBar.current = [];
		handleExternal.current = [];
		handleInternal.current = [];
		handleCylinder.current = [];
		handlePosition.current = [];
		handleObjects.current = [];
		tickleObjects.current = [];
		tickleVentPosition.current = [];
		tickleVentModels.current = [];
		cylinderPosition.current = [];
		addedFrame.current = [];
		addedFramePosition.current = [];
		transomFrame.current = [];
		addedSash.current = [];
		glassText.current = [];
		orientationClicked.current = false;
		radioButtonClicked.current = false;

		setUniqueSideRefernce([]);

		await initializeFrame()

		initControls();

		setSashHangingNo(modelJson[jsonIndex]?.sash?.hangings)

		setTimeout(() => {
			const initWidth = (modelJson[jsonIndex]?.dimensions?.width * 1000)
			const initHeight = (modelJson[jsonIndex]?.dimensions?.height * 1000)

			if (initWidth > 0 && initHeight > 0 && cameraRef.current) {
				setFrameCameraView(0, 1, sceneRef.current, cameraRef, initWidth, initHeight, 0, cameraUiMoved, cameraUiStopped);
			}
		}, 200);

		setTimeout(() => {
			setModelVisible(true)
		}, 300);
	}


	function addTextOnGlass(fontSize, textToAdd, index) {
		// Add text on glass
		if (sceneRef.current) {
			removeTextOnGlass(index)
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			context.font = `${fontSize}px sans-serif`;
			context.fillStyle = '#1a1a1a';

			// Measure the text width
			const textWidth = context.measureText(textToAdd).width;

			// Calculate the center coordinates of the canvas
			const centerX = canvas.width / 2;
			const centerY = canvas.height / 2;

			// Calculate the starting position for the text to be centered
			const textX = centerX - (textWidth / 2);
			const textY = centerY + (fontSize / 2) - 10;

			context.fillText(textToAdd, textX, textY); // Text and position

			// Create texture from canvas
			const textureNew = new THREE.CanvasTexture(canvas);

			// Create plane geometry to apply the texture
			const geometry = new THREE.PlaneGeometry(10, 0.5); // Adjust the size as needed
			const materialText = new THREE.MeshBasicMaterial({ map: textureNew, transparent: true, metalness: 1, side: THREE.DoubleSide });


			if (index !== null) {
				textOnSelectedGlass(glazingRef.current[index], materialText, geometry, index, textToAdd)
			} else {
				glazingRef.current.forEach((item, i) => {
					textOnSelectedGlass(item, materialText, geometry, i, textToAdd)
				})
			}
		}
	}

	function textOnSelectedGlass(glazing, materialText, geometry, index, text) {
		const textMesh = new THREE.Mesh(geometry, materialText);

		// Scale the text mesh to fit the glass
		const boundingBox = new THREE.Box3().setFromObject(glazing);
		const center = new THREE.Vector3();
		boundingBox.getCenter(center);

		textMesh.position.set(center.x, center.y, center.z + 0.01);

		const boundingBox2 = new THREE.Box3().setFromObject(textMesh);
		const height = boundingBox.max.y - boundingBox.min.y;
		const width = boundingBox.max.x - boundingBox.min.x;
		const height2 = boundingBox2.max.y - boundingBox2.min.y;
		const width2 = boundingBox2.max.x - boundingBox2.min.x;
		textMesh.scale.set(width / width2, height / height2, 1);

		// Add text mesh to the scene and store a reference
		sceneRef.current.add(textMesh);
		glassText.current.push({ index, text3D: textMesh, text, price: sandblastPricing, name: "Sandblasted numerals" });
		setCustomModelData((prevModelData) => ({
			...prevModelData,
			glazing: {
				...prevModelData.glazing,
				sandblasted: glassText.current
			}
		}))
	}

	function removeTextOnGlass(index) {
		// Add text on glass
		if (sceneRef.current) {
			// Check if the text model already exists and remove it
			if (glassText.current.length > 0) {
				if (index === null) {
					glassText.current.forEach((item) => {
						sceneRef.current.remove(item.text3D);
					})
					glassText.current = []; // Clear the array
				} else {
					glassText.current = glassText.current.filter(textObj => {
						if (textObj.index === index) {
							sceneRef.current.remove(textObj.text3D);
							return false;
						}
						return true;
					});
				}

			}
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				glazing: {
					...prevModelData.glazing,
					sandblasted: glassText.current
				}
			}))
		}
	}


	const removeSash = (selectedIndex, data) => {
		addedSash.current.forEach(obj => {
			if (obj.index === selectedIndex) {
				obj.scene.visible = false
				obj.scene.scale.set(0, 0, 0)
				setDeletePressed(false)
			}
		});

		setCustomModelData((prevModelData) => ({
			...prevModelData,
			sashData: prevModelData.sashData.map((item) => {
				if (selectedIndex === item?.index) {
					return {
						...item,
						visible: false
					}
				}
				return item;
			})
		}))
	}

	async function changeFrameWidthData(width, height) {
		const newWidth = width / 1000;
		const newHeight = height / 1000;

		const updateJson = updateFrameDimension(newWidth, newHeight, jsonIndex)

		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			return newJson;
		})
		// blockWindowReload();
	}

	async function resizeWindow(width, height, modelType, initLoad) {
		setLoading(true)
		setModelVisible(false)
		setResizeFrame(false)

		resetPartialLocks()

		// setUiLinesLocation("")
		// setRefreshUiLines(false)
		// setResizeSashStyles(false)

		setCustomModelData((prevModelData) => ({
			...prevModelData,
			layoutFrame: {
				...prevModelData.layoutFrame,
				width: parseFloat(width),
				height: parseFloat(height),
			},
		}));

		await changeFrameWidthData(width, height);

		setFrameCameraView(0, 1, sceneRef.current, cameraRef, width, height, 0, cameraUiMoved, cameraUiStopped);

		setTimeout(() => {
			setModelVisible(true)
			setLoading(false)
		}, 400);

		// setTimeout(() => {
		// 	setUiLinesLocation("All")
		// 	// setRefreshUiLines(true)
		// 	// setResizeSashStyles(true)
		// }, 100);
	}

	useEffect(() => {
		if (applyPartialTrigger) {
			setRefreshUiLines(false)

			const indexToUpdate = applyPartialObj?.index; // Index of the section to update
			const updatedValueInMm = applyPartialObj?.value; // New value in mm for a specific section

			if (applyPartialObj?.type === "W") {

				// for widths
				let frameWidth = modelJson[jsonIndex]?.dimensions?.width; // Total frame width in mm

				if (allOffSetData) {
					frameWidth -= allOffSetData?.wWidthOffset;
				}

				const widthRatios = modelJson[jsonIndex]?.sash?.partialWidthRatios; // Existing width ratios

				recalculateWidthRatios(
					frameWidth,
					widthRatios,
					updatedValueInMm,
					indexToUpdate,
					lockRefIndex,
					setCustomModelData,
					setResizeSashStyles,
					setApplyPartialTrigger,
				);
			} else if (applyPartialObj?.type === "H") {
				// for heights
				let frameHeight = modelJson[jsonIndex]?.dimensions?.height; // Total frame height in mm

				if (allOffSetData) {
					frameHeight -= allOffSetData?.wHeightOffset;
				}

				const heightRatios = modelJson[jsonIndex]?.sash?.partialHeightRatios.length > 1 ? modelJson[jsonIndex]?.sash?.partialHeightRatios : [.25, .25, .5]; // Existing height ratios

				recalculateHeightRatios(
					frameHeight,
					heightRatios,
					updatedValueInMm,
					indexToUpdate,
					lockRefIndexV,
					setCustomModelData,
					setResizeSashStyles,
					setApplyPartialTrigger,
				);
			}
		}
	}, [applyPartialTrigger]);

	useEffect(() => {
		if (resizeSashStyles) {
			setResizeSashStyles(false)

			const updatedJson = handlePartialWidthChange(customModelData.partialWidthRatios, customModelData.partialHeightRatios, jsonIndex)

			setModelJson((prevModelJson) => {
				const newJson = updatedJson(prevModelJson);
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
				return newJson;
			});

			setTimeout(() => {
				getSashRef()
				setSaveJsonData(true)
				setMultiSelectRefPoints([])
			}, 200);
		}
	}, [resizeSashStyles])

	useEffect(() => {
		if (saveJsonData && customModelData) {
			setSaveJsonData(false)

			if (quotationId && receivedProductId) {
				saveModelDataQuotation(accessToken, modelId, selectedAnimation?.id, customModelData, quotationId, receivedProductId, navigate, setMessage, setMessageType, modelJson)
			} else {
				// let tempModelId = newModelId ? newModelId : modelId;
				// let tempStyleId = newStyleId ? newStyleId : selectedAnimation?.id

				saveModelData(
					accessToken,
					modelId,
					selectedAnimation?.id,
					customModelData,
					modelJson,
					navigate,
					setMessage,
					setMessageType
				);
			}

			setTimeout(() => {
				setLoading(false)
			}, 200);
		}
	}, [saveJsonData]);

	const getSashRef = () => {
		testRef.current = getSashHangingRefs()
		forceUpdate()
	}

	function getSashFrameRef() {
		testRef.current = getSasProfilehRefs()
	}

	function setPositionForElement(elementModels, elementPositions, horizontal, units) {
		units *= 0.01;
		elementModels.current.forEach((element, index) => {
			if (element) {
				if (horizontal) {
					if (elementPositions.current)
						element.position.x = elementPositions.current[index].x + units;
				} else {
					if (elementPositions.current)
						element.position.y = elementPositions.current[index].y + units;
				}
			}
		});
	}

	const getSashHangingAndProfileRef = () => {
		newFrameRefPoints.current = getSashHangingRefs()
		testRef.current = getSasProfilehRefs()
		forceUpdate()
	}

	const handleHardwares = () => {

		testRef.current = [];
		newFrameRefPoints.current = [];

		const hardwareRefMap = {};
		// newFrameRefPoints.current = [];
		const staticValues = {
			"Trickle vent": [...getSasProfilehRefs(), ...getFrameRefs()],
			"Escutcheon": tickleVent, // Assuming both "Trickle vent" and "Escutcheon" share the same reference
			"Door Handle": [...getSasProfilehRefs(), ...getFrameRefs()],
			"Bar Handles Offset": [...getSasProfilehRefs(), ...getFrameRefs()],
			"Frame": newFrameData,
			// "glazing": uniqueSideRefernce,
			"sashprofiles": hardwareType === "sashprofiles" && getSashFrameRef(), // Assuming getSashFrameRef returns a reference
			"sashHanging": hardwareType === "sashHanging" && getSashRef(), // Assuming updateSash is a function
			// "Panel": panelRefPoints,
			"Spyhole": [...getSasProfilehRefs(), ...getFrameRefs()],
			"Letterplate": spyHoleRefPoints,
			"Knocker": spyHoleRefPoints,
			"Numeral": spyHoleRefPoints,
			// "handleDirection": [...getSashHangingRefs()],
			// "glazingPanel": [...getSashHangingRefs()],
			"hinge": [...getSasProfilehRefs(), ...getFrameRefs()]
		};


		if (hardwareType == "sashHangingProfile") {
			getSashHangingAndProfileRef()
		}


		if ((hardwareType === "sashHanging" && collectionDetails.typeId == 1)) {
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				numberOfSash: {
					number: parseInt(sashHangingNo),
					// wide: wide,
					// high: high
				}
			}))
		}

		const lowercaseHardwareNames = allHardware.map(name => name?.name?.toLowerCase());

		// Populate hardwareRefMap with case-insensitive comparison
		Object.keys(staticValues).forEach(name => {
			if (lowercaseHardwareNames.includes(name.toLowerCase())) {
				hardwareRefMap[name?.toLocaleLowerCase()] = staticValues[name];
			}
		});

		if (hardwareType && hardwareRefMap[hardwareType?.name]) {
			testRef.current = hardwareRefMap[hardwareType?.name];
		}

		//setting transom refs points
		if (hardwareType && (hardwareType === "transomVertical" || hardwareType === 'transomHorizontal')) {
			testRef.current = getTransomPartitionRefs();
			newFrameRefPoints.current = getTransomRefs()
		}


		if (hardwareType === "handleDirection") {
			testRef.current = [...getSashHangingRefs()]
		}

		if (hardwareType && hardwareType.name === "Frame") {
			testRef.current = addFrameRef.current
		}

		if (hardwareType === "Shaped Frame") {
			testRef.current = getJointsRef()
		}

		if (hardwareType.name === "window handle") {
			testRef.current = [...getSasProfilehRefs(), ...getFrameRefs()]
		}

		//setting frame refs points
		if (hardwareType && hardwareType === "customize frame style") {
			testRef.current = getFrameRefs();
		}

		if (hardwareType === "customize frame style" && manufacturingToggle) {
			testRef.current = getFrameRefs();
			newFrameRefPoints.current = getJointsRef();
		}

		//handle Ref point
		// if (hardwareType && hardwareType === "handleDirection") {
		// 	testRef.current = windowHandleRefPoint.current
		// }

		if (manufacturingToggle && hardwareType === "sill") {
			testRef.current = [centerCoordinates(frameStyleBottom.current)]
		}


		if (hardwareType === "addOn" || hardwareType === "editAddOn") {
			updateTestRef();
			setElementData(addOnList);
		}

		if (manufacturingToggle && hardwareType === "transom") {
			testRef.current = getTransomRefs()
		}

		if (hardwareType === "addOn" || hardwareType === "editAddOn") {
			updateTestRef();
			setElementData(addOnList);
		}

		//handle Ref point
		if (hardwareType && hardwareType === "glazingPanel" || hardwareType === 'glazing' || hardwareType === 'texture') {
			testRef.current = [...getTransomPartitionRefs()];
		}
	}

	// Start: adding UI lines for sash
	useEffect(() => {
		if (refreshUiLines) {
			// cameraUiMoved()

			if (modelJson[jsonIndex]?.sill?.height == 0) {
				allOffSetData.sillHeight = 0
			}

			const partialWidths = modelJson[jsonIndex]?.sash?.partialWidthRatios?.length > 1 ? modelJson[jsonIndex]?.sash?.partialWidthRatios : [1];
			const partialHeights = modelJson[jsonIndex]?.sash?.partialHeightRatios?.length > 1 ? modelJson[jsonIndex]?.sash?.partialHeightRatios : [1];

			setRefreshUiLines(false)

			if (uiLinesLocation === '' || (partialWidths && partialWidths?.length < 2)) {
				uiLinesRefBottom.current = []
				removeLayoutLines(sceneRef, 1)
			} else {
				if (customModelData && !customModelData?.hideDimentions && (uiLinesLocation !== "") && (partialWidths && partialWidths?.length > 1) && frameStyleBottom.current) {
					uiLinesRefBottom.current = []
					addPartialWidths(modelJson[jsonIndex].dimensions?.width, partialWidths, uiLinesRefBottom, sceneRef, userDataContext?.company_color, frameStyleBottom.current, allOffSetData)
				}
			}

			if (uiLinesLocation === '' || (partialHeights && partialHeights?.length < 2)) {
				uiLinesRef.current = []
				removeLayoutLines(sceneRef, 2)
			} else {
				if (customModelData && !customModelData?.hideDimentions && (uiLinesLocation !== "") && (partialHeights && partialHeights?.length > 1) && frameStyleRight.current && frameStyleBottom.current) {
					uiLinesRef.current = []
					addPartialHeights(modelJson[jsonIndex].dimensions?.height, partialHeights, uiLinesRef, sceneRef, userDataContext?.company_color, frameStyleRight.current, frameStyleBottom.current, allOffSetData)
				}
			}

			if ((uiLinesLocation !== "") && frameStyleBottom.current && customModelData && !customModelData?.hideDimentions) {
				uiLinesRefFrame.current = []
				removeFrameLines(sceneRef, 3)

				addUiLinesFrameH(modelJson[jsonIndex].dimensions?.width, partialWidths, uiLinesRefFrame, sceneRef, userDataContext?.company_color, frameStyleTop.current, frameStyleBottom.current, allOffSetData)
				addUiLinesFrameV(modelJson[jsonIndex].dimensions?.height, partialHeights, uiLinesRefFrame, sceneRef, userDataContext?.company_color, frameStyleBottom.current, allOffSetData)
			}

			if ((uiLinesLocation !== "") && frameStyleBottom.current && customModelData && !customModelData?.hideDimentions && allOffSetData) {
				let frameAddSillV = []
				let frameAddSillH = []

				let addSillArray = calculateFrameSillOffsets(allOffSetData, modelJson, jsonIndex, addSillCombine)

				if (addSillArray) {
					frameAddSillV = addSillArray.frameAddSillV
					frameAddSillH = addSillArray.frameAddSillH
				}

				setAddSillRatioV(frameAddSillV)
				setAddSillRatioH(frameAddSillH)

				if (frameAddSillV && frameAddSillV.length > 0) {
					addSillUiRefV.current = [];
					removeFrameSillAddLines(sceneRef, 2);
					addUiAddOnSillV(frameAddSillV, modelJson[jsonIndex].dimensions?.height, addSillUiRefV, sceneRef, userDataContext?.company_color, frameStyleRight.current, frameStyleBottom.current, allOffSetData)
				} else {
					addSillUiRefV.current = []
				}

				if (frameAddSillH && frameAddSillH.length > 0) {
					addSillUiRefH.current = [];
					removeFrameSillAddLines(sceneRef, 1);
					addUiAddOnSillH(frameAddSillH, modelJson[jsonIndex].dimensions?.width, addSillUiRefH, sceneRef, userDataContext?.company_color, frameStyleTop.current, frameStyleBottom.current, allOffSetData)
				} else {
					addSillUiRefH.current = []
				}
			}

			if (customModelData && (customModelData?.hideDimentions || uiLinesLocation === '')) {
				removeAllUiLines()
			}

			// Wide and High automation
			if (partialWidths || partialHeights) {
				setWide(partialWidths?.length)
				setHigh(partialHeights?.length)

				if (!receivedProductId && partialWidths?.length > 0 && partialHeights?.length > 0 && selectedAnimation?.id) {
					updateFrameStyleAppenture(accessToken, selectedAnimation?.id, partialWidths?.length, partialHeights?.length)
				}
			}

			setTimeout(() => {
				cameraUiStopped()
			}, 100);
		}
	}, [refreshUiLines]);

	useEffect(() => {
		if (modelVisible && uiLinesLocation === '') {
			setUiLinesLocation("All")
		}
	}, [modelVisible])

	useEffect(() => {
		// if (modelVisible && uiLinesLocation === '') {
		// 	setUiLinesLocation("All")
		// 	console.log("uiLinestest 2")
		// }

		if (modelVisible && uiLinesLocation !== "" && !refreshUiLines) {
			setRefreshUiLines(true)
		}
	}, [sashGroup.current, modelVisible, customModelData?.hideDimentions, modelJson[jsonIndex]?.addOn, modelJson[jsonIndex]?.sill?.height, modelJson[jsonIndex]?.sash?.partialWidthRatios, modelJson[jsonIndex]?.sash?.partialHeightRatios])

	useEffect(() => {
		if (!refreshUiLines) {
			setRefreshUiLines(true)
		}
	}, [uiLinesLocation, customModelData?.hideDimentions, allOffSetData])

	// capture and save all images
	useEffect(() => {
		if (captureImages && cameraRef.current) {
			captureAndSaveImages(setFrameCameraView, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom, getAllProfileRef, allProfileRefSeq, glassRefSeq, frameStyleRef, sashList, sillRef, sashGroup, uiLinesRefFrame, uiLinesRefBottom, removeFrameLines, removeLayoutLines, sceneRef, setModelImages, modelWrap, setSaveAllImages, setCaptureImages, setRefreshUiLines, setUiLinesLocation, setRefreshAfterSave)
		}
	}, [captureImages]);

	useEffect(() => {
		if (refreshAfterSave) {
			setRefreshAfterSave(false)

			if (!customModelData?.hideDimentions) {
				setUiLinesLocation("All")
				setRefreshUiLines(true)
			}
		}
	}, [refreshAfterSave])

	//test with replaced sash in window model
	useEffect(() => {
		if (shapeFrame && checkClickedProfile && hardwareType === "Shaped Frame") {

			const cornerName = checkClickedProfile?.name
			const targetCorner = modelJson[jsonIndex]?.frame.corners[cornerName];
			targetCorner.type = shapeFrame;
			targetCorner.size.width = shapeFrame == "Corner" ? 0 : debouncShapedWidth / 1000;
			targetCorner.size.height = shapeFrame == "Corner" ? 0 : debounceShapedHeight / 1000;

			const savingJson = {
				name: shapeFrame,
				height: debounceShapedHeight,
				width: debouncShapedWidth,
				cornerName: cornerName
			}

			createWindowFrame(sceneRef.current, modelJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, frameStyleTop, sillsHeightRef, addOnFrames, addonRef, allTrickleVents, sillRef, setDefaultSillScale, sillExtRef, framePathCollection, frameStyleRight, headerSelectedItem?.name, partialDimensionRef);
			updateShapedCornerData(setCustomModelData, customModelData, savingJson)
		}

	}, [shapeFrame, frameStyleRef, debouncShapedWidth, debounceShapedHeight])

	function initRenderer(container) {
		if (sceneRef.current) {
			sceneRef.current.traverse(object => {
				if (object.material) {
					object.material.dispose();
				}
				if (object.geometry) {
					object.geometry.dispose();
				}
				if (object.texture) {
					object.texture.dispose();
				}
			});
		}

		// Clear the old sceneRef.current
		if (sceneRef.current) {
			sceneRef.current.children.length = 0;
		}

		new RGBELoader().load(environmentHDR, function (hdrEquirect) {
			hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
			sceneRef.current.environment = hdrEquirect;
		});

		cameraRef.current.position.set(0, -1, 4)

		const pointLight = new THREE.PointLight(0xffffff, 2, -100); // Color, intensity, and distance
		pointLight.position.set(0, 0, 6);
		pointLight.shadow.mapSize.width = 512; // Higher resolution shadow map for sharper shadows
		pointLight.shadow.mapSize.height = 512;
		pointLight.shadow.radius = 200; // Increase radius for softer shadows
		// sceneRef.current.add(pointLight);

		// const pointLight2 = new THREE.PointLight(0xffffff, 2.1, 10); // Color, intensity, and distance
		// pointLight2.position.set(0, 0, -2);
		// sceneRef.current.add(pointLight2);

		// Add Ambient Light
		const ambientLight = new THREE.AmbientLight(0xffffff, 2);
		sceneRef.current.add(ambientLight);

		// Add DirectionalLight with Shadows
		// const directionalLight = new THREE.DirectionalLight(0xffffff, 0.7);
		// directionalLight.position.set(0, 10, 0); // Position the light
		// directionalLight.castShadow = true; // Enable shadows

		// Configure shadow camera for better shadow quality
		// directionalLight.shadow.mapSize.width = 400; // Higher resolution for shadows
		// directionalLight.shadow.mapSize.height = 400;
		// directionalLight.shadow.camera.near = 0.5;
		// directionalLight.shadow.camera.far = 50;
		// directionalLight.shadow.camera.left = -10;
		// directionalLight.shadow.camera.right = 10;
		// directionalLight.shadow.camera.top = 10;
		// directionalLight.shadow.camera.bottom = -10;
		// directionalLight.shadow.radius = 8; // Soft shadows
		// directionalLight.shadow.blurSamples = 0.5;
		// sceneRef.current.add(directionalLight);

		// // light from front top
		// const directionalLightFT = new THREE.DirectionalLight(0xffffff, 1);
		// directionalLightFT.position.set(4, 40, 100);

		// if (sceneRef.current) {
		// 	sceneRef.current.add(directionalLightFT);
		// }

		// // light from front bottom
		// const directionalLightFB = new THREE.DirectionalLight(0xffffff, 1);
		// directionalLightFB.position.set(4, -40, 100);

		// if (sceneRef.current) {
		// 	sceneRef.current.add(directionalLightFB);
		// }

		// // light from front top
		// const directionalLightFTR = new THREE.DirectionalLight(0xffffff, 1);
		// directionalLightFT.position.set(-4, 40, 100);

		// if (sceneRef.current) {
		// 	sceneRef.current.add(directionalLightFT);
		// }

		// // light from front bottom
		// const directionalLightFBR = new THREE.DirectionalLight(0xffffff, 1);
		// directionalLightFB.position.set(-4, -40, 100);

		// if (sceneRef.current) {
		// 	sceneRef.current.add(directionalLightFB);
		// }

		// light from front left
		const directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.5);
		directionalLight1.position.set(-6, 0, 4);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight1);
		}

		// light from front right
		const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.5);
		directionalLight2.position.set(6, 0, 4);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight2);
		}

		// light from back left
		const directionalLight5 = new THREE.DirectionalLight(0xffffff, 0.5);
		directionalLight5.position.set(-6, 0, -4);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight5);
		}

		// light from back right
		const directionalLight6 = new THREE.DirectionalLight(0xffffff, 0.5);
		directionalLight6.position.set(6, 0, -4);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight6);
		}

		// Add a ground plane to receive shadows
		const planeGeometry = new THREE.PlaneGeometry(100, 100);
		const planeMaterial = new THREE.ShadowMaterial({ opacity: 0.6, side: THREE.DoubleSide, });

		// const planeMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
		const shadowPlane = new THREE.Mesh(planeGeometry, planeMaterial);
		shadowPlane.rotation.x = -Math.PI / 2; // Rotate to make it flat

		shadowPlane.position.y = sceneRef?.current?.position?.y - 1.2;
		shadowPlane.receiveShadow = true; // Ensure plane receives shadows
		// sceneRef.current.add(shadowPlane);

		// Create a new renderer
		renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
		renderer.localClippingEnabled = true;
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.setClearColor(0xffffff);
		renderer.shadowMap.enabled = true;
		renderer.shadowMap.type = THREE.PCFSoftShadowMap;

		// Remove the old canvas from the container
		if (container) {
			while (container.firstChild) {
				container.removeChild(container.firstChild);
			}
			// Append the new renderer canvas to the container
			container.appendChild(renderer.domElement);
		}
	}

	function initControls() {
		if (cameraRef.current) {
			const controls = new OrbitControls(cameraRef.current, renderer.domElement);

			if (controls) {
				cameraControls = controls
			}
			controls.addEventListener('change', render);
			// controls.addEventListener('change', () => checkZoomDirection(camera, controls));
			// controls.addEventListener('change', () => updateRef());
			controls.minDistance = 1;
			controls.maxDistance = 15;
			controls.target.set(0, 0, 0);
			controls.update();
			orbitControlRef.current = controls;
			window.addEventListener('resize', onWindowResize);

			controls.addEventListener('start', cameraMoved);
			controls.addEventListener('end', cameraStopped);

			controls.addEventListener('start', cameraUiMoved);
			controls.addEventListener('end', cameraUiStopped);
		}
	}

	function cameraStopped() {
		if (!elementRef?.current) {
			return;
		}

		forceUpdate()
		elementRef.current.classList?.remove("hide")
	}

	function cameraMoved() {
		if (!elementRef?.current) {
			return;
		}

		elementRef.current.hidden = false;
		elementRef.current.classList?.add("hide");
	}

	function cameraUiMoved() {
		if (!elementUiRef?.current) {
			return;
		}

		// if (cameraRef.current) {
		// 	const toDegrees = (radians) => radians * (180 / Math.PI);

		// 	// Extract the rotation values
		// 	const yAngle = toDegrees(cameraRef?.current?.rotation.x); // _x
		// 	const xAngle = toDegrees(cameraRef?.current?.rotation.y);   // _y
		// 	const zAngle = toDegrees(cameraRef?.current?.rotation.z);  // _z
		// 	console.log(yAngle, xAngle, zAngle, "aadi test 1")
		// 	if ((xAngle > 2 || xAngle < -2) || (yAngle > 2 || yAngle < -2) || (zAngle > 2 || zAngle < -2)) {
		// 		if (sceneRef && sceneRef.current) {
		// 			sceneRef.current.traverse((child) => {
		// 				if (child?.name?.includes("fullLines") || child?.name?.includes("uiLines")) {
		// 					child.visible = false
		// 				}
		// 			})
		// 		}
		// 	}
		// }

		elementUiRef.current.hidden = false;
		elementUiRef.current.classList?.add("hide");
	}

	function cameraUiStopped() {
		if (!elementUiRef?.current) {
			return;
		}

		if (customModelData && customModelData?.hideDimentions) {
			removeAllUiLines()
		} else {
			if (cameraRef.current && !customModelData?.hideDimentions) {
				const toDegrees = (radians) => radians * (180 / Math.PI);

				// Extract the rotation values
				const yAngle = toDegrees(cameraRef?.current?.rotation.x); // _x
				const xAngle = toDegrees(cameraRef?.current?.rotation.y);   // _y
				const zAngle = toDegrees(cameraRef?.current?.rotation.z);  // _z

				if ((xAngle > 20 || xAngle < -20) || (yAngle > 20 || yAngle < -20) || (zAngle > 20 || zAngle < -20)) {
					// if (sceneRef && sceneRef.current) {
					// 	sceneRef.current.traverse((child) => {
					// 		if (child?.name?.includes("fullLines") || child?.name?.includes("uiLines")) {
					// 			child.visible = false
					// 		}
					// 	})
					// }
				} else {
					// if (sceneRef && sceneRef.current) {
					// 	sceneRef.current.traverse((child) => {
					// 		if (child?.name?.includes("fullLines") || child?.name?.includes("uiLines")) {
					// 			child.visible = true
					// 		}
					// 	})
					// }

					forceUpdate()
					elementUiRef.current.classList?.remove("hide")
				}
			} else {
				forceUpdate()
				elementUiRef.current.classList?.remove("hide")
			}
		}

		// forceUpdate()
		// elementUiRef.current.classList?.remove("hide")
	}

	function removeAllUiLines() {
		uiLinesRef.current = []
		uiLinesRefBottom.current = []
		removeLayoutLines(sceneRef, 3)

		uiLinesRefFrame.current = []
		removeFrameLines(sceneRef, 3)

		addSillUiRefV.current = []
		addSillUiRefH.current = []
		removeFrameSillAddLines(sceneRef, 3)
	}

	function resetPartialLocks() {
		setLockRefPoints([]);
		setLockRefPointsV([])
		setLockRefIndex([]);
		setLockRefIndexV([]);
	}


	function onWindowResize() {
		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();
		renderer.setSize(window.innerWidth, window.innerHeight);
		render();
	}

	window.addEventListener('resize', () => {
		cameraUiMoved()
		setTimeout(() => {
			cameraUiStopped()
		}, 100);
	});

	function addHandle(element, objectPosition, isAlreadyAdded, price, image, id) {

		handlePath.current = element;
		const handleLoader = new GLTFLoader()
		handleObjects.current.push(objectPosition);
		var finalIndex = 0;
		sashGroup?.current?.forEach((child, index) => {
			if (child == objectPosition) {
				finalIndex = index;
			}
		});

		if (element) {
			handleLoader?.load(element, function (gltf) {
				const scale = new THREE.Vector3(1, 1, 1);
				gltf.scene.scale.copy(scale);
				const boundingBox = new THREE.Box3().setFromObject(objectPosition);
				let position = new THREE.Vector3(boundingBox.min.x + 0.03, objectPosition.position.y, objectPosition.position.z);
				// const position = new THREE.Vector3(boundingBox.max.x - 0.03, objectPosition.position.y, objectPosition.position.z);

				if (headerSelectedItem.name === "Heritage Door") {
					position = new THREE.Vector3(boundingBox.min.x + 0.05, objectPosition.position.y, objectPosition.position.z + 0.05);
				}

				gltf.scene.position.copy(position);
				sceneRef.current.add(gltf.scene);
				gltfModel.current.add(gltf.scene);

				gltf.scene.traverse((child) => {
					if (child.name.includes("HandleExt")) {
						handleExternal.current.push(child);
						handlePosition.current.push(new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z));
					}

					if (child.name.includes("HandleInt")) {
						handleInternal.current.push(child);
						// handlePositionInternal.current.push(new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z))
					}

					if (child.name.includes("Cylinder") || child.name.includes("cylinder")) {
						handleCylinder.current.push(child);

					}

				});
				setHardwareType('')
				//  windowRef.current.add(gltf.scene);
				render();
			});
		}


		else {
			setIsHardwareAdded((prevAdded) => ({
				...prevAdded,
				handle: true
			}))
		}
	}

	const updateTestRef = () => {
		testRef.current = getAddOnRef()
	}

	//   helper function to store data in the get object function
	function updateCustomModelData(type, element, objectPosition, data) {
		setCustomModelData(prevModelData => ({
			...prevModelData,
			hardware: {
				...prevModelData?.hardware,
				[type]: [
					...(Array.isArray(prevModelData?.hardware?.[type]) ? prevModelData?.hardware?.[type] : []),
					getObjectData(type?.toLowerCase(), element, objectPosition, data)
				]
			}
		}));
	}

	const handleFrameSingleSide = (data, index, checked) => {
		if (checked) {
			setCheckSingleSide(index)
			setCheckClickedProfile(data)
			setIsUniqueSide(checked)
		} else {
			setCheckSingleSide()
			setCheckClickedProfile()
			setIsUniqueSide(checked)
		}
	}

	function render() {
		renderer.render(scene, camera);
	}

	// window.addEventListener("mousemove", resetIdleTimer);
	// window.addEventListener("keydown", resetIdleTimer);
	// window.addEventListener("click", resetIdleTimer);

	function animate() {
		requestAnimationFrame(animate);

		// updateOverlayPosition(gltfModel, camera, pointInScreen, testRef, newFrameRefPoints, uiLinesRef, uiLinesRefBottom, uiLinesRefFrame, allProfileRefSeq, glassRefSeq)
		if (testRef.current.length && !newFrameRefPoints.current.length) {
			updateReferencePoints(testRef.current, camera)
		} else if (!testRef.current.length && newFrameRefPoints.current.length) {
			updateReferencePoints(newFrameRefPoints.current, camera)
		} else if (testRef.current.length && newFrameRefPoints.current.length) {
			const refs = [...newFrameRefPoints.current, ...testRef.current]
			updateReferencePoints(refs, camera)
		}

		if (uiLinesRefBottom.current.length > 0) {
			updateReferencePoints(uiLinesRefBottom.current, camera)
		}

		if (addSillUiRefV.current.length > 0) {
			updateReferencePoints(addSillUiRefV.current, camera)
		}

		if (addSillUiRefH.current.length > 0) {
			updateReferencePoints(addSillUiRefH.current, camera)
		}

		if (uiLinesRef.current.length > 0) {
			updateReferencePoints(uiLinesRef.current, camera)
		}

		if (uiLinesRefFrame.current.length > 0) {
			updateReferencePoints(uiLinesRefFrame.current, camera)
		}

		if (mixer) {
			mixer.update(0.01);
		}

		// if (idle()) {
		// 	navigate('/product/systems/styles/list')
		// 	forceUpdate()
		// }

		// console.log(cameraControls, "test camera")
		render();
	}

	useEffect(() => {
		if (modelVisible && hardwareType === "color" && cameraRef.current && sceneRef.current && modelWidth && modelHeight) {
			if (colorActiveTab === 'internal') {
				// setUiLinesLocation('')
				// setRefreshUiLines(true)
				setFrameCameraView(-1, -1, sceneRef.current, cameraRef, modelWidth, modelHeight, 0, cameraUiMoved, cameraUiStopped);
			} else if (colorActiveTab === 'external') {
				// setUiLinesLocation('')
				// setRefreshUiLines(true)
				setFrameCameraView(-1, 1, sceneRef.current, cameraRef, modelWidth, modelHeight, 0, cameraUiMoved, cameraUiStopped);
			} else {
				// setUiLinesLocation('All')
				// setRefreshUiLines(true)
				setFrameCameraView(0, 1, sceneRef.current, cameraRef, modelWidth, modelHeight, 0, cameraUiMoved, cameraUiStopped);
			}
		}
	}, [colorActiveTab])

	useEffect(() => {
		if (modelVisible && gltfModel.current && cameraRef.current) {
			getModelRenderedSize(gltfModel, cameraRef, setRenderedSize)
		}
	}, [modelVisible, gltfModel, cameraRef]);

	return (
		<div className='position-relative model_wrap'>
			<div ref={modelWrap}
				style={{
					width: `${renderedSize?.width + 100}px`,
					height: `${renderedSize?.height + 100}px`,
					marginLeft: '-100px',
					marginTop: '-80px'
				}}
			>
				<div id='container' ref={containerRef}>
				</div>

				<div ref={elementRef} className='ref_elements'>
					{(testRef?.current?.length > 0 || newFrameRefPoints?.current?.length > 0) &&
						<ConfigurationPoints newFrameRefPoints={newFrameRefPoints} bayPostAngleManual={bayPostAngleManual} setBayPostAngleManual={setBayPostAngleManual} setBayPostDeg={setBayPostDeg} bayPostDeg={bayPostDeg} headerSelectedItem={headerSelectedItem} multiSelectRefPoints={multiSelectRefPoints} handleDeleteObject={handleDeleteObject} hardwareType={hardwareType} testRef={testRef} setPopoverIndex={setPopoverIndex} popoverIndex={popoverIndex} elementData={elementData} handleAddHardware={handleAddHardware} frameDrop={frameDrop} toggleFrameDrop={toggleFrameDrop} checkSingleSide={checkSingleSide} handleFrameSingleSide={handleFrameSingleSide} frameType={frameType} currentModel={currentModel} handleFrameDrop={handleFrameDrop} manufacturingToggle={manufacturingToggle} allFrameCollection={allFrameCollection} allStyleCollection={allStyleCollection} toggleStyleDrop={toggleStyleDrop} styleDrop={styleDrop} handleStyleDrop={handleStyleDrop} styleType={styleType} />
					}

					{/* {(allProfileRefSeq?.current?.length > 0) &&
						<SeqNumberRefs profileRef={allProfileRefSeq} glassRef={glassRefSeq} />
					} */}
				</div>

				<div ref={elementUiRef} className='ref_elements'>
					{(uiLinesRef?.current?.length > 0) &&
						<EditPartialHeights uiLinesRef={uiLinesRef} savedData={modelJson[jsonIndex]} type="V" manufacturingToggle={manufacturingToggle} />
					}

					{(uiLinesRefBottom?.current?.length > 0) &&
						<EditPartialWidths uiLinesRef={uiLinesRefBottom} savedData={modelJson[jsonIndex]} manufacturingToggle={manufacturingToggle} />
					}

					{(addSillUiRefV?.current?.length > 0) &&
						<ShowDimensionDivisions uiLinesRef={addSillUiRefV} ratios={addSillRatioV} type="left" addSillCombine={addSillCombine.current} />
					}

					{(addSillUiRefH?.current?.length > 0) &&
						<ShowDimensionDivisions uiLinesRef={addSillUiRefH} ratios={addSillRatioH} type="top" addSillCombine={false} />
					}

					{(uiLinesRefFrame?.current?.length > 0) &&
						<EditFrameSizeModel
							uiLinesRef={uiLinesRefFrame}
							accessToken={accessToken}
							setLayoutSizing={setLayoutSizing}
							collectionId={modelId}
							setLoading={setLoading}
							quotationId={quotationId}
							receivedProductId={receivedProductId}
							frameStyleProdId={selectedAnimation}
							frameProfileDefault={frameProfileDefault}
							manufacturingToggle={manufacturingToggle}
						/>
					}
				</div>
			</div>

			<div className='design_footer'>
				<div>
					{/* <input type="range" id="slider" ref={sliderRef} onChange={handleSliderChange} /> */}
				</div>

				{quotationId && (
					<Button
						type='button'
						color="primary"
						size="md"
						className="px-4"
						// onClick={() => setCaptureImages(true)}
						onClick={() => {
							setSaveJsonData(true);
							setLoading(true)
						}}
					>
						Save
					</Button>
				)}
			</div>
		</div>
	);
})

export default BlockPanelDesign;