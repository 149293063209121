import React, { useEffect, useState } from "react";
import { FaPlus, FaRegFile } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import SidePanelBar from "../../../../components/SidePanelBar"
import { MdClose, MdContentCopy, MdContentPaste, MdInsertLink, MdKeyboardArrowDown, MdOutlineChecklist } from "react-icons/md";
import { BiTrashAlt } from "react-icons/bi";
import { servicePath } from "../../../../utility/data";
import { Row, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, FormGroup, NavLink } from "reactstrap";
import AddLinksModal from "../../../../components/AddLinksModal";
import axios from "axios";
import { Image } from "react-bootstrap";
import AddNewFrame from "../../../../components/AddNewFrame";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "material-ui-core";
import { useRightContext } from "../../../../context/rightMenuContext";
import { handleError } from "../../../../utility/commonHelper";

const Left = ({ accessToken, setMessage, setMessageType, loading, setLoading, quoteId, printingLinks, framesList, setFramesList, isDeleted, setIsDeleted, setIsCopied, quoteDetails, updateQuotationInvoiceJsonDataService, userDataContext }) => {

    const { quoteFrameData, setQuoteFrameData } = useRightContext()

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [leftPanelOpen, setLeftPanelOpen] = useState(false);

    const [selectedItems, setSelectedItems] = useState([]);
    const [activeSelect, setActiveSelect] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedFrameName, setSelectedFrameName] = useState("");
    const [deletedFrameId, setDeletedFrameId] = useState("")

    // State to manage which items are shown directly and which are in the dropdown
    const [isOpen, setIsOpen] = useState(false);
    const [itemsToShowDirectly, setItemsToShowDirectly] = useState(0)
    const [showCopyModal, setShowCopyModal] = useState(false)
    const [copyId, setCopyId] = useState("")
    const [whereToCopy, setWhereToCopy] = useState("Copy to this project")
    const [newFrameName, setNewFrameName] = useState("")

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        setActiveSelect(false)
        if (quoteId && accessToken) {
            getAllFrameList(quoteId)
            sessionStorage.setItem("QuotationId", quoteId)
        }

        // console.log(quoteFrameData, "quoteFrameData 2")
    }, [quoteId, accessToken])

    useEffect(() => {
        // console.log(quoteFrameData, "quoteFrameData")
    }, [quoteFrameData]);


    useEffect(() => {
        if (printingLinks && printingLinks?.length > 0) {
            setItemsToShowDirectly(printingLinks.length <= 2 ? printingLinks.length : 2)
        }
    }, [printingLinks])

    // useEffect(() => {
    //     if (framesList && framesList.length > 0) {
    //         getTemplatePrinntingData();
    //     }
    // }, [framesList]);

    const getAllFrameList = (fetchId) => {
        const FormData = require("form-data");

        let data = new FormData();

        data.append("QuotationId", fetchId);

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${servicePath}/Quotation/GetAllQuotationFrame`,
            headers: {
                accept: "text/plain",
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "multipart/form-data",
            },
            data: data,
        };

        axios
            .request(config)
            .then((res) => {
                if (res?.data?.statusCode === 200) {
                    // console.log("res", res);
                    setFramesList(res?.data?.entity);

                    setTimeout(() => {
                        setLoading(false);
                    }, 400);

                } else if (res?.data?.statusCode === 404) {
                    setLoading(false);
                    navigate("/error/404");
                } else {
                    setLoading(false);
                    setMessage(
                        res?.data?.message ? res.data.message : "Something went wrong"
                    );
                    setMessageType("error");
                }
            })
            .catch((error) => {
                console.log(error);

                setLoading(false);
                setMessage(error.message ? error.message : "Something went wrong");
                setMessageType("error");
                handleError(error,false,navigate)
            });
    };


    const handleAddFramePanel = () => {
        setLoading(true)

        setSidebarOpen(!sidebarOpen);
    };

    const handleLinksLeftPanel = () => {
        setLeftPanelOpen(!leftPanelOpen);
    };

    // const handlePricingPanel = () => {
    //     setPricingLeftOpen(!pricingLeftOpen)
    // };

    const handleDeSelectAll = () => {
        setSelectedItems([]);
        setActiveSelect(false)
        return false;
    };

    const handleChangeSelectAll = (isToggle) => {
        if (selectedItems.length >= framesList.length) {
            if (isToggle) {
                setSelectedItems([]);
            }
        } else {
            setSelectedItems(framesList.map((x) => x.id));
        }

        return false;
    };

    // useEffect(() => {
    //     console.log(selectedItems, "selectedItems")
    // }, [selectedItems]);

    const onCheckItem = (e, ids) => {
        let selectedList = [...selectedItems];

        if (selectedList.includes(ids)) {
            selectedList = selectedList.filter((x) => x !== ids);
        } else {
            selectedList.push(ids);
        }

        setSelectedItems(selectedList);
        return false;
    };

    const handleDeleteModal = (id, name) => {
        setShowDeleteModal(true);

        if (id) {
            // console.log(id, name, "handleDeleteModal")
            setDeletedFrameId(id);
            setSelectedFrameName(name);
        } else {
            if (selectedItems?.length === 1) {
                const item = framesList?.find((ele => ele?.id === selectedItems[0]))
                setSelectedFrameName(item?.name)
                setDeletedFrameId(item?.id)
            }

            if (selectedItems?.length > 1) {
                setSelectedFrameName('')
            }
        }
    };

    const handleCopyModal = (id, name) => {
        setWhereToCopy('Copy to this project')
        setShowCopyModal(true)

        if (id) {
            setCopyId(id);
            setNewFrameName(name);
        } else {
            if (selectedItems?.length === 1) {
                const item = framesList?.find((ele => ele?.id === selectedItems[0]))
                setNewFrameName(item?.name)
                setCopyId(item?.id)
            }

            if (selectedItems?.length > 1) {
                setNewFrameName('')
            }
        }
    }

    const handleDelete = () => {
        setLoading(true);
        setIsDeleted(true);
        // setQuoteFrameData({
        //     frameIds: [],
        //     quotationId: '',
        // })

        const data = new FormData();
        if (selectedItems?.length > 0) {
            selectedItems?.forEach((item) => {
                data.append("Id", item);
            });
        } else {
            data.append("Id", deletedFrameId);
        }

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${servicePath}/Quotation/deleteHardwareStyle`,
            headers: {
                accept: "text/plain",
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "multipart/form-data",
            },
            data: data,
        };
        axios
            .request(config)
            .then((res) => {
                if (res && res.status === 200) {
                    if (res?.data?.statusCode === 200) {
                        setMessageType("success");
                        setMessage(
                            res?.data?.message
                                ? res.data.message
                                : "Deleted frame Successfully"
                        );

                        getAllFrameList(quoteId);
                        setSelectedFrameName("");
                        setShowDeleteModal(false);
                        setSelectedItems([]);
                        setIsDeleted(false);
                    } else {
                        setMessage(
                            res?.data?.message ? res.data.message : "Something went wrong"
                        );

                        getAllFrameList(quoteId);
                        setSelectedItems([]);
                        setMessageType("error");
                        setSelectedFrameName("");
                        setIsDeleted(false);
                    }
                }
            })
            .catch((error) => {
                setIsDeleted(false);
                setSelectedFrameName("");
                setLoading(false);
                setMessage(error.message ? error.message : "Something went wrong");
                setMessageType("error");
                handleError(error,false,navigate)
            });
    };

    const handleCopyService = (frameIds) => {
        setLoading(true);

        const data = new FormData();
        data.append("QuotationId", quoteId.toString());

        if (frameIds?.length > 0) {
            frameIds?.forEach((item) => {
                data.append("QuotationFramesId", item);
            });
        } else {
            data.append("QuotationFramesId", copyId);
        }

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${servicePath}/Master/CopyToQuotationFrames`,
            headers: {
                accept: "text/plain",
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "multipart/form-data",
            },
            data: data,
        };
        axios
            .request(config)
            .then((res) => {
                if (res && res.status === 200) {
                    if (res?.data?.statusCode === 200) {
                        setMessageType("success");
                        setMessage(
                            res?.data?.message
                                ? res.data.message
                                : "Frame copied Successfully"
                        );
                        setIsCopied(true)
                        setSelectedItems([]);
                        setShowCopyModal(false);
                        setCopyId("");
                        getAllFrameList(quoteId);
                        setQuoteFrameData({
                            frameIds: [],
                            quotationId: '',
                        })
                    } else {
                        setMessage(
                            res?.data?.message ? res.data.message : "Something went wrong"
                        );
                        setIsCopied(false)
                        setSelectedItems([]);
                        getAllFrameList(quoteId);
                        setMessageType("error");
                        setShowCopyModal(false);
                        setCopyId("");
                        setQuoteFrameData({
                            frameIds: [],
                            quotationId: '',
                        })
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                setSelectedItems([]);
                setShowCopyModal(false);
                setIsCopied(false)
                setCopyId("");
                setMessage(error.message ? error.message : "Something went wrong");
                setMessageType("error");
                handleError(error,false,navigate)
            });

        // setQuoteFrameData({
        //     frameIds: [],
        //     quotationId: '',
        // })
    }

    const handleCopy = () => {
        if (whereToCopy === "Copy to this project") {
            handleCopyService(selectedItems)

            setQuoteFrameData({
                frameIds: [],
                quotationId: '',
            })
        } else {
            // const data = {
            //     selectedItems: selectedItems?.length > 0 ? selectedItems : copyId,
            //     action: "Paste",
            //     quotationId: quoteId
            // };

            // sessionStorage.setItem("copiedData", JSON.stringify(data));

            if (selectedItems && selectedItems.length > 0) {
                setQuoteFrameData({
                    frameIds: selectedItems,
                    quotationId: quoteId,
                })
            } else {
                setQuoteFrameData({
                    frameIds: [copyId],
                    quotationId: quoteId,
                })
            }

            setShowCopyModal(false)

            setTimeout(() => {
                setSelectedItems([])
                setCopyId("")
            }, 100);
        }
    };

    const openFrame = (item) => {
        {selectedItems && selectedItems.length > 0 ? (
            console.log("uncheck all items")
        ) : (
            // navigate(
            //     `/product/frame/designer/${item?.frameStyleCollectionId}/${item?.frameStyleId}`,
            //     { state: { data: item, is_new: false } }
            // )
            navigate(
                `/product/frame/block-designer/${item?.frameStyleCollectionId}/${item?.frameStyleId}`,
                { state: { data: item, is_new: false } }
            )
        )}
    };

    const toggleDropDown = () => {
        setIsOpen((prev) => !prev)
    }

    const handlePasteFrame = () => {
        if (quoteFrameData && quoteFrameData.quotationId) {
            handleCopyService(quoteFrameData.frameIds)
        }
    }

    return (
        <>
            <div className="">
                <div className="border-bottom gap-3 d-flex flex-column">
                    {/* {framesList && framesList.length > 0 && (
                        <div className="d-flex flex-wrap align-items-center gap-2">
                            {printingLinks && printingLinks.map((item, index) => (
                                <Link to={`/quotation/${quoteId}/print-view/${item?.id}`} key={index} className="text-nowrap me-2">
                                    <FaRegFile className="text-primary me-1 mb-1" size={18} />
                                    <span>{item?.name}</span>
                                </Link>
                            ))}
                            <Button color="primary" onClick={handlePricingPanel}>Quotation Pricing</Button>
                        </div>
                    )} */}
                    {printingLinks && printingLinks.length > 0 && (
                        <div className="d-flex flex-wrap align-items-center gap-2">
                            {printingLinks.slice(0, itemsToShowDirectly).map((item, index) => (
                                <Link to={`/quotation/${quoteId}/print-view/${item?.id}`} key={index} className="text-nowrap me-2">
                                    <FaRegFile className="text-primary me-1 mb-1" size={18} />
                                    <span>{item?.name}</span>
                                </Link>
                            ))}

                            {printingLinks.length > itemsToShowDirectly && <Dropdown
                                isOpen={isOpen}
                                toggle={() => { toggleDropDown() }}
                            >
                                <DropdownToggle color="none" className="border-0 p-0 lh-1 text-primary">
                                    <div className="d-flex align-items-center">
                                        <span className="fw-semibold">
                                            <MdKeyboardArrowDown size={24} />
                                        </span>
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className='full_height'>
                                    {printingLinks.slice(itemsToShowDirectly)?.map((item, index) => {
                                        return (
                                            <DropdownItem
                                                onClick={() => navigate(`/quotation/${quoteId}/print-view/${item?.id}`)}
                                                key={index}
                                            // className={`${item?.id == selectedAnimation?.id ? 'text-primary fw-medium' : ''}`}
                                            >
                                                {item?.name}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>}
                            {/* <Button color="primary" onClick={handlePricingPanel}>Quotation Pricing</Button> */}
                        </div>
                    )}

                    <div className="d-flex justify-content-between align-items-center gap-3 mb-3">

                        <div className="d-flex gap-3 align-items-center">
                            <div className="d-flex align-items-center gap-3">
                                <Button
                                    color="primary"
                                    size="md"
                                    onClick={handleAddFramePanel}
                                    className="d-flex align-items-centre gap-1 px-lg-4">
                                    <FaPlus size={16} />
                                    <span className="fs-15 lh-sm">New Frame</span>
                                </Button>

                                {/* <Link to={{  }} onClick={handlePricingPanel}>
                                    <div>
                                        <MdOutlineSell className="text-primary me-1" size={18} />
                                        <span className="text-primary lh-1">
                                            $2345.97
                                        </span>
                                    </div>
                                </Link> */}
                            </div>

                            {selectedItems && selectedItems.length > 0 ? (
                                <span className="d-flex gap-3 justify-content-center align-items-center">
                                    <NavLink className="d-flex gap-1 btn-link" onClick={() => handleCopyModal()}>
                                        <MdContentCopy size={15} />
                                        <span className="lh-1">
                                            Copy
                                        </span>
                                    </NavLink>

                                    <NavLink className="d-flex align-items-center gap-1 btn-link danger" onClick={() => { handleDeleteModal() }}>
                                        <BiTrashAlt size={17} />
                                        <span className="lh-1">
                                            Delete
                                        </span>
                                    </NavLink>

                                    <NavLink onClick={() => {
                                        navigate("/cutting-list", { state: { items: selectedItems, quoteId: quoteId } })
                                        sessionStorage.setItem("quotationId", quoteId)
                                    }}>
                                        <Button color="outline-primary">Cutting list</Button>
                                    </NavLink>


                                    {/* <NavLink onClick={() => navigate("/assembly-list", { state: { state: { items: selectedItems, quoteId: quoteId } } })}>
                                        <Button color="outline-primary">Assembly list</Button>
                                    </NavLink> */}
                                </span>
                            ) : (
                                <>
                                    {quoteFrameData && quoteFrameData.quotationId &&
                                        <Button
                                            outline
                                            color="light"
                                            onClick={() => handlePasteFrame()}
                                            className="d-flex gap-1 align-items-center text-dark"
                                        >
                                            <MdContentPaste size={15} />
                                            <span className="lh-1">
                                                Paste
                                            </span>
                                        </Button>
                                    }
                                </>
                            )}
                        </div>


                        <div className="d-flex gap-3 align-items-center">

                            {activeSelect ? (
                                <NavLink className="btn-link d-flex align-items-center" onClick={() => handleDeSelectAll(true)}>
                                    <MdClose className="me-1" size={18} />

                                    <span className="text-muted lh-1">
                                        {selectedItems.length} selected
                                    </span>
                                </NavLink>
                            ) : (
                                <NavLink className="btn-link d-flex align-items-center" onClick={() => setActiveSelect(true)}>
                                    <MdOutlineChecklist className="me-1" size={18} />

                                    <span className="text-muted lh-1">
                                        Select
                                    </span>
                                </NavLink>
                            )}


                            {/* <div className="form-check mb-0">
                                <Input
                                    className="check_box"
                                    type="checkbox"
                                    id="checkAll"
                                    checked={
                                        framesList.length &&
                                        selectedItems.length >= framesList.length
                                    }
                                    onChange={() => handleChangeSelectAll(true)}
                                />
                            </div> */}

                            {framesList && framesList.length > 0 && (
                                <NavLink className="btn-link d-flex align-items-center" onClick={handleLinksLeftPanel}>
                                    <MdInsertLink className="me-1" size={18} />
                                    <span className="lh-1">
                                        Links
                                    </span>
                                </NavLink>
                            )}
                        </div>
                    </div>
                </div>

                <Row className="item_box_wrap dynamic_card py-3 custom_row_8">
                    {framesList && framesList.length > 0 ?
                        <>
                            {framesList && framesList.map((item, index) => (
                                <Col sm="6" md="4" lg="3" xxl="2" key={index} className="item_box">
                                    <div className={`item_box_inner mt-2 ${selectedItems.includes(item?.id) ? "selected_item" : ''}`}>
                                        <div className={`item_header w-100 ${(selectedItems && selectedItems.length > 0 || activeSelect) ? "has_checked_items" : ''}`}>
                                            <div className="form-check mb-0">
                                                <Input
                                                    type="checkbox"
                                                    id={`checkbox_${item?.id}`}
                                                    checked={selectedItems.includes(item?.id)}
                                                    onChange={(e) => onCheckItem(e, item?.id)}
                                                    label=""
                                                    className="size_sm"
                                                />
                                            </div>

                                            {selectedItems && selectedItems.length == 0 && !activeSelect && (
                                                <span className="d-flex gap-1">
                                                    <NavLink onClick={() => handleCopyModal(item?.id, item?.name)}> <MdContentCopy size={16} /> </NavLink>
                                                    <NavLink onClick={() => handleDeleteModal(item?.id, item?.name)}> <BiTrashAlt size={18} /> </NavLink>
                                                </span>
                                            )}
                                        </div>

                                        <div className="d-flex flex-column h-100 clickable" onClick={() => openFrame(item)}>
                                            {/* <Link to={} className="d-flex flex-column h-100"> */}
                                            <div className="item_box_img">
                                                {item?.profilePathUrl?.includes("https") ? (
                                                    <Image src={item?.profilePathUrl} className="img-fluid" alt={item?.name} />
                                                ) : (
                                                    <Image src={`data:image/svg+xml;base64,${item?.profilePathUrl}`} className="img-fluid" alt={item?.name} />
                                                )}
                                            </div>

                                            <div className="item_box_content">
                                                <h5 className="mb-0">
                                                    {item?.name}
                                                </h5>
                                                {item?.name && (
                                                    <p className="mb-0">
                                                        {item.frameProductName}
                                                    </p>
                                                )}
                                            </div>
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </>
                        :
                        <Col lg="12">
                            <h3 className="text-center mt-5">No frames added</h3>
                        </Col>}
                </Row>

                <AddNewFrame accessToken={accessToken} setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} setMessage={setMessage}
                    setMessageType={setMessageType} loading={loading} setLoading={setLoading} quoteId={quoteId} frameCount={framesList && framesList?.length || 0} />

                <SidePanelBar
                    type=""
                    title="Customer links"
                    setLeftPanelOpen={setLeftPanelOpen}
                    leftPanelOpen={leftPanelOpen}
                    content={
                        <AddLinksModal
                            leftPanelOpen={leftPanelOpen}
                            setMessageType={setMessageType}
                            setLoading={setLoading}
                            navigate={navigate}
                            setMessage={setMessage}
                            accessToken={accessToken}
                            printingLinks={printingLinks}
                            quoteId={quoteId}
                            updateQuotationInvoiceJsonDataService={updateQuotationInvoiceJsonDataService}
                            userDataContext={userDataContext}
                        />} />

                <Modal
                    isOpen={showDeleteModal}
                    toggle={() => setShowDeleteModal(false)}
                    className="max-w-400"
                    centered
                >
                    <ModalBody>
                        <h4 className="fw-bold">
                            Delete
                        </h4>
                        <p>
                            {/* Are you sure that you want to permanently delete {selectedItems?.length > 1 ? `these` + <strong>`${selectedItems?.length} frames`</strong> : <strong>`${selectedFrameName}`</strong>}`} */}
                            Are you sure that you want to permanently delete {selectedItems?.length > 1 ? <>these <strong>{selectedItems?.length} frames</strong></> : <strong>{selectedFrameName}</strong>}
                        </p>
                        <div className="d-flex justify-content-end gap-2">
                            <Button className="px-4" color="outline-danger" size="md" onClick={() => handleDelete()} disabled={isDeleted}>Delete</Button>
                            <Button className="px-4" outline onClick={() => setShowDeleteModal(false)} color="primary" size="sm">Cancel</Button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={showCopyModal}
                    toggle={() => setShowCopyModal(false)}
                    className="max-w-400"
                    centered
                >
                    <ModalBody>
                        <h4 className="fw-bold">
                            Copy
                        </h4>

                        <FormGroup className="">
                            <FormControl className="mb-0 w-100">
                                <RadioGroup
                                    aria-labelledby="feature-label"
                                    name="whereToCopy"
                                    value={whereToCopy}
                                    onChange={(e) => setWhereToCopy(e?.target?.value)}
                                >
                                    <FormControlLabel
                                        value="Copy to this project"
                                        control={<Radio />}
                                        label="Copy to this project"
                                    />

                                    <FormControlLabel
                                        value="Paste in another project"
                                        control={<Radio />}
                                        label="Paste in another project"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </FormGroup>

                        {/* This will be shown if ther is only one item is selected */}
                        {(copyId || selectedItems?.length === 1) && whereToCopy === "Copy to this project" && (
                            <FormGroup className="mb-3">
                                <Label for="newFrameName">Name</Label>
                                <Input id="newFrameName" name="newFrameName" value={newFrameName || ''} onChange={(e) => setNewFrameName(e.target.value)} />
                            </FormGroup>
                        )}

                        <div className="d-flex justify-content-end gap-2">
                            <Button className="px-4" color="primary" size="md" onClick={() => handleCopy(quoteId)} >Copy</Button>
                            <Button
                                className="px-4"
                                onClick={() => {
                                    setShowCopyModal(false)
                                    setSelectedItems([])
                                    setCopyId("")
                                }}
                                outline
                                color="primary" size="md"
                            >
                                Cancel
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};

export default Left;
