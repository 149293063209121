import * as THREE from 'three';
// Import necessary THREE.js modules for text rendering
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import fontFile from '../../assets/fonts/Poppins/Poppins-Black.ttf';

export const addPartialWidths = (frameValue, partialWidths, uiLinesRef, sceneRef, themeColor, framePosition, allOffSetData) => {
    const framePositionRef = new THREE.Box3().setFromObject(framePosition);
    const framePositionCenter = new THREE.Vector3();
    framePositionRef.getCenter(framePositionCenter);

    let sillHeigth = allOffSetData?.sillHeight;

    let wLoffset = allOffSetData?.wLoffset;
    let wRoffset = allOffSetData?.wRoffset;
    let wBOffset = allOffSetData?.wBOffset;

    let bottomTotalWidth = frameValue - (wLoffset + wRoffset)

    const itemBoundingBox = new THREE.Box3().setFromObject(framePosition);

    const endTopP = 0.1
    let lineDistance = 0.08 + endTopP;

    let mainStartPoint = (framePositionCenter.x - (bottomTotalWidth / 2) - wLoffset) + wLoffset
    let mainEndPoint = (framePositionCenter.x + (bottomTotalWidth / 2) + wRoffset) - wRoffset
    const yPos = itemBoundingBox.min.y - lineDistance
    const zPos = itemBoundingBox.max.z

    if (wBOffset > 0) {
        mainStartPoint = itemBoundingBox.min.x + wLoffset
        mainEndPoint = itemBoundingBox.max.x - wRoffset
    }

    if (partialWidths && partialWidths.length > 0) {
        uiLinesRef.current = [];
        removeLayoutLines(sceneRef, 1);

        // Create the main horizontal line
        const startPoint1 = new THREE.Vector3(mainStartPoint, yPos, zPos);
        const endPoint1 = new THREE.Vector3(mainEndPoint, yPos, zPos);

        const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);
        const material = new THREE.LineBasicMaterial({ color: themeColor ? themeColor : '#44C8F5' });
        const line1 = new THREE.Line(geometry1, material);

        // Create perpendicular line and head circle
        const createPerpendicularLine = (xPosition) => {
            const startP = new THREE.Vector3(xPosition, 0, 0); // Relative to group
            const endP = new THREE.Vector3(xPosition, endTopP, 0); // Positioned above the main line

            const geometry = new THREE.BufferGeometry().setFromPoints([startP, endP]);
            const line = new THREE.Line(geometry, material);

            const circleRadius = 10 / 1000;
            const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);
            const circleMaterial = new THREE.MeshBasicMaterial({ color: themeColor ? themeColor : '#44C8F5', side: THREE.DoubleSide });
            const circle = new THREE.Mesh(circleGeometry, circleMaterial);
            circle.position.copy(endP);

            const group = new THREE.Group();
            group.add(line);
            group.add(circle);

            return group;
        };

        const partialWidthGroup = new THREE.Group();

        const startGroup = createPerpendicularLine(0);
        startGroup.position.set(mainStartPoint, yPos, zPos);
        partialWidthGroup.add(startGroup);

        let lastXPosition = mainStartPoint;

        partialWidths.forEach((item, index) => {
            if (item && index < partialWidths.length - 1) {
                const relativeX = lastXPosition + (bottomTotalWidth) * item;
                const perpendicularGroup = createPerpendicularLine(0);
                perpendicularGroup.position.set(relativeX, yPos, zPos);
                partialWidthGroup.add(perpendicularGroup);
                lastXPosition = relativeX;
            }
        });

        const endGroup = createPerpendicularLine(0);
        endGroup.position.set(mainEndPoint, yPos, zPos);
        partialWidthGroup.add(endGroup);

        const mainGroup = new THREE.Group();
        mainGroup.add(line1);
        mainGroup.add(partialWidthGroup);

        if (sillHeigth > 0) {
            mainGroup.position.y -= sillHeigth;
        }

        mainGroup.name = "uiLines uiLines_h";

        sceneRef?.current?.add(mainGroup);

        // Create connecting red lines
        const partialWidthRefLines = new THREE.Group();
        const materialV = new THREE.LineBasicMaterial({ color: 0xff0000 });

        if (partialWidthGroup && partialWidthGroup?.children?.length > 0) {
            for (let i = 0; i < partialWidthGroup.children.length - 1; i++) {
                const point1 = new THREE.Vector3();
                const point2 = new THREE.Vector3();

                partialWidthGroup.children[i].getWorldPosition(point1);
                partialWidthGroup.children[i + 1].getWorldPosition(point2);

                const geometry = new THREE.BufferGeometry().setFromPoints([point1, point2]);
                const lineVirtual = new THREE.Line(geometry, materialV);

                partialWidthRefLines.add(lineVirtual);

                // Calculate the center of the line
                const center = new THREE.Vector3(
                    (point1.x + point2.x) / 2,
                    (point1.y + point2.y) / 2,
                    (point1.z + point2.z) / 2
                );

                lineVirtual.position.set(
                    center.x, center.y - .05, center.z
                );

                uiLinesRef.current.push(lineVirtual)
            }
        }
    }
};

export const addPartialHeights = (frameValue, partialHeights, uiLinesRef, sceneRef, themeColor, framePosition, FrameBottom, allOffSetData) => {
    // const framePositionRef = new THREE.Box3().setFromObject(framePosition);
    // const framePositionCenter = new THREE.Vector3();
    // framePositionRef.getCenter(framePositionCenter);

    let sillHeigth = allOffSetData?.sillHeight;
    let wRoffset = allOffSetData?.wRoffset;
    let wTopOffset = allOffSetData?.wTopOffset;
    let wBOffset = allOffSetData?.wBOffset;
    let wHeightOffset = allOffSetData?.wHeightOffset;

    console.log(allOffSetData, "allOffSetData")

    if (partialHeights && partialHeights.length > 0) {
        uiLinesRef.current = [];
        removeLayoutLines(sceneRef, 2);

        const itemBoundingBox = new THREE.Box3().setFromObject(framePosition);
        const frameBottomBounding = new THREE.Box3().setFromObject(FrameBottom);
        const endTopP = 0.1
        let lineDistance = 0.08 + endTopP;

        const actualFrameLength = frameValue - wHeightOffset;
        let mainStartPoint = frameBottomBounding.min.y + frameValue - wTopOffset - sillHeigth
        let mainEndPoint = frameBottomBounding.min.y + wBOffset
        const xPos = itemBoundingBox.max.x + lineDistance;
        const zPos = frameBottomBounding.max.z;

        // Create the main vertical line starts from top
        const startPoint1 = new THREE.Vector3(xPos, mainStartPoint, zPos);
        const endPoint1 = new THREE.Vector3(xPos, mainEndPoint, zPos);

        const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);
        const material = new THREE.LineBasicMaterial({ color: themeColor ? themeColor : '#44C8F5' });
        const line1 = new THREE.Line(geometry1, material);

        // Create horizontal perpendicular line and head circle
        const createPerpendicularLine = (yPosition) => {
            const startP = new THREE.Vector3(0, yPosition, 0); // Relative to group
            const endP = new THREE.Vector3(-endTopP, yPosition, 0); // Positioned to the right of the main line

            const geometry = new THREE.BufferGeometry().setFromPoints([startP, endP]);
            const line = new THREE.Line(geometry, material);

            const circleRadius = 10 / 1000;
            const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);
            const circleMaterial = new THREE.MeshBasicMaterial({ color: themeColor ? themeColor : '#44C8F5', side: THREE.DoubleSide });
            const circle = new THREE.Mesh(circleGeometry, circleMaterial);
            circle.position.copy(endP);

            const group = new THREE.Group();
            group.add(line);
            group.add(circle);

            return group;
        };

        const partialHeightGroup = new THREE.Group();

        // Add the start perpendicular line from bottom
        const startGroup = createPerpendicularLine(0);
        startGroup.position.set(xPos, mainStartPoint, zPos);
        partialHeightGroup.add(startGroup);

        let lastYPosition = mainStartPoint; // added frameValue to start section from top

        // Add perpendicular lines at intermediate points based on `partialHeights`
        partialHeights.forEach((item, index) => {
            if (item && index < partialHeights.length - 1) {
                const relativeY = lastYPosition - (actualFrameLength) * item;
                const perpendicularGroup = createPerpendicularLine(0);
                perpendicularGroup.position.set(xPos, relativeY, zPos);
                partialHeightGroup.add(perpendicularGroup);
                lastYPosition = relativeY;
            }
        });

        // Add the end perpendicular line to top
        const endGroup = createPerpendicularLine(0);
        endGroup.position.set(xPos, mainEndPoint, zPos);
        partialHeightGroup.add(endGroup);

        const mainGroup = new THREE.Group();
        mainGroup.add(line1);
        mainGroup.add(partialHeightGroup);
        mainGroup.name = "uiLines uiLines_v";

        if (wRoffset > 0) {
            mainGroup.position.x += wRoffset;
        }

        sceneRef?.current?.add(mainGroup);

        // Create connecting red lines
        const partialHeightRefLines = new THREE.Group();
        const materialV = new THREE.LineBasicMaterial({ color: 0xff0000 });

        if (partialHeightGroup && partialHeightGroup.children.length > 0) {
            for (let i = 0; i < partialHeightGroup.children.length - 1; i++) {
                const point1 = new THREE.Vector3();
                const point2 = new THREE.Vector3();

                partialHeightGroup?.children[i]?.getWorldPosition(point1);
                partialHeightGroup?.children[i + 1]?.getWorldPosition(point2);

                const geometry = new THREE.BufferGeometry().setFromPoints([point1, point2]);
                const lineVirtual = new THREE.Line(geometry, materialV);

                partialHeightRefLines.add(lineVirtual);

                // Calculate the center of the line
                const center = new THREE.Vector3(
                    (point1.x + point2.x) / 2,
                    (point1.y + point2.y) / 2,
                    (point1.z + point2.z) / 2
                );

                lineVirtual.position.set(
                    center.x, center.y - 0.03, center.z
                );

                uiLinesRef.current.push(lineVirtual);
            }
        }
    }
};

export const addUiLinesFrameH = (frameValue, partialWidths, uiLinesRef, sceneRef, themeColor, framePosition, FrameBottom, allOffSetData) => {
    uiLinesRef.current = [];
    removeFrameLines(sceneRef, 1)

    const framePositionRef = new THREE.Box3().setFromObject(framePosition);
    const framePositionCenter = new THREE.Vector3();
    framePositionRef.getCenter(framePositionCenter);

    const frameBottomBounding = new THREE.Box3().setFromObject(FrameBottom);

    let wLoffset = allOffSetData?.wLoffset;
    let wRoffset = allOffSetData?.wRoffset;
    let wTopOffset = allOffSetData?.wTopOffset;

    let bottomTotalWidth = frameValue - (wLoffset + wRoffset)

    const itemBoundingBox = new THREE.Box3().setFromObject(framePosition);

    let mainStartPoint = framePositionCenter.x - (bottomTotalWidth / 2) - wLoffset
    let mainEndPoint = framePositionCenter.x + (bottomTotalWidth / 2) + wRoffset

    const endTopP = 0.1
    let lineDistance = 0.08 + endTopP + wTopOffset;

    // Create the main horizontal line
    const startPoint1 = new THREE.Vector3(mainStartPoint, itemBoundingBox.max.y + lineDistance, frameBottomBounding.max.z);
    const endPoint1 = new THREE.Vector3(mainEndPoint, itemBoundingBox.max.y + lineDistance, frameBottomBounding.max.z);

    const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);
    const material = new THREE.LineBasicMaterial({ color: themeColor ? themeColor : '#44C8F5' });
    const line1 = new THREE.Line(geometry1, material);

    // Create perpendicular line and head circle
    const createPerpendicularLine = (xPosition) => {
        const startP = new THREE.Vector3(xPosition, 0, 0); // Relative to group
        const endP = new THREE.Vector3(xPosition, -endTopP, 0); // Positioned above the main line

        const geometry = new THREE.BufferGeometry().setFromPoints([startP, endP]);
        const line = new THREE.Line(geometry, material);

        const circleRadius = 10 / 1000;
        const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);
        const circleMaterial = new THREE.MeshBasicMaterial({ color: themeColor ? themeColor : '#44C8F5', side: THREE.DoubleSide });
        const circle = new THREE.Mesh(circleGeometry, circleMaterial);
        circle.position.copy(endP);

        const group = new THREE.Group();
        group.add(line);
        group.add(circle);

        return group;
    };

    const partialWidthGroup = new THREE.Group();

    const startGroup = createPerpendicularLine(0);
    startGroup.position.set(mainStartPoint, itemBoundingBox.max.y + lineDistance, frameBottomBounding.max.z);
    partialWidthGroup.add(startGroup);

    const endGroup = createPerpendicularLine(0);
    endGroup.position.set(mainEndPoint, itemBoundingBox.max.y + lineDistance, frameBottomBounding.max.z);
    partialWidthGroup.add(endGroup);

    const mainGroup = new THREE.Group();
    mainGroup.add(line1);
    mainGroup.add(partialWidthGroup);
    mainGroup.name = "fullLines fullLines_h";

    // if (sillHeigth > 0) {
    //     mainGroup.position.y -= sillHeigth;
    // }

    sceneRef?.current?.add(mainGroup);

    // Create connecting red lines
    const partialWidthRefLines = new THREE.Group();
    const materialV = new THREE.LineBasicMaterial({ color: 0xff0000 });

    if (partialWidthGroup && partialWidthGroup?.children?.length > 0) {
        for (let i = 0; i < partialWidthGroup.children.length - 1; i++) {
            const point1 = new THREE.Vector3();
            const point2 = new THREE.Vector3();

            partialWidthGroup.children[i].getWorldPosition(point1);
            partialWidthGroup.children[i + 1].getWorldPosition(point2);

            const geometry = new THREE.BufferGeometry().setFromPoints([point1, point2]);
            const lineVirtual = new THREE.Line(geometry, materialV);

            partialWidthRefLines.add(lineVirtual);

            // Calculate the center of the line
            const center = new THREE.Vector3(
                (point1.x + point2.x) / 2,
                (point1.y + point2.y) / 2,
                (point1.z + point2.z) / 2
            );

            lineVirtual.position.set(
                center.x, center.y - .05, center.z
            );

            lineVirtual.name = "frameWidth"

            uiLinesRef.current.push(lineVirtual)
        }
    }
};

export const addUiLinesFrameV = (frameValue, partialHeights, uiLinesRef, sceneRef, themeColor, framePosition, allOffSetData) => {
    const framePositionRef = new THREE.Box3().setFromObject(framePosition);
    const framePositionCenter = new THREE.Vector3();
    framePositionRef.getCenter(framePositionCenter);

    let sillHeight = allOffSetData?.sillHeight;
    let wHeightOffset = allOffSetData?.wHeightOffset;
    let wLoffset = allOffSetData?.wLoffset;
    let wBOffset = allOffSetData?.wBOffset;

    // uiLinesRef.current = [];
    removeFrameLines(sceneRef, 2)

    const itemBoundingBox = new THREE.Box3().setFromObject(framePosition);

    const endTopP = 0.1
    let lineDistance = 0.08 + endTopP

    const actualFrameLength = frameValue - wHeightOffset;

    let mainStartPoint = itemBoundingBox.min.y - sillHeight // subtracted sill height because sill is below frame profile // so exted line
    let mainEndPoint = (itemBoundingBox.min.y + frameValue - sillHeight) // subtracted sill height because sill is below frame profile  // so reduced extended line
    const xPos = itemBoundingBox.min.x - lineDistance;
    const zPos = itemBoundingBox.max.z;

    // Create the main vertical line start from bottom
    const startPoint1 = new THREE.Vector3(xPos, mainStartPoint, zPos);
    const endPoint1 = new THREE.Vector3(xPos, mainEndPoint, zPos);

    const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);
    const material = new THREE.LineBasicMaterial({ color: themeColor ? themeColor : '#44C8F5' });
    const line1 = new THREE.Line(geometry1, material);

    // Create horizontal perpendicular line and head circle
    const createPerpendicularLine = (yPosition) => {
        const startP = new THREE.Vector3(0, yPosition, 0); // Relative to group
        const endP = new THREE.Vector3(endTopP, yPosition, 0); // Positioned to the right of the main line

        const geometry = new THREE.BufferGeometry().setFromPoints([startP, endP]);
        const line = new THREE.Line(geometry, material);

        const circleRadius = 10 / 1000;
        const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);
        const circleMaterial = new THREE.MeshBasicMaterial({ color: themeColor ? themeColor : '#44C8F5', side: THREE.DoubleSide });
        const circle = new THREE.Mesh(circleGeometry, circleMaterial);
        circle.position.copy(endP);

        const group = new THREE.Group();
        group.add(line);
        group.add(circle);

        return group;
    };

    const partialHeightGroup = new THREE.Group();

    // Add the start perpendicular line
    const startGroup = createPerpendicularLine(0);
    startGroup.position.set(xPos, mainEndPoint, zPos);
    partialHeightGroup.add(startGroup);

    // Add the end perpendicular line
    const endGroup = createPerpendicularLine(0);
    endGroup.position.set(xPos, mainStartPoint, zPos);
    partialHeightGroup.add(endGroup);

    const mainGroup = new THREE.Group();
    mainGroup.add(line1);
    mainGroup.add(partialHeightGroup);
    mainGroup.name = "fullLines fullLines_v";

    if (wBOffset == 0) {
        mainGroup.position.x -= wLoffset;
    }

    sceneRef?.current?.add(mainGroup);

    // Create connecting red lines
    const partialHeightRefLines = new THREE.Group();
    const materialV = new THREE.LineBasicMaterial({ color: 0xff0000 });

    if (partialHeightGroup && partialHeightGroup.children.length > 0) {
        for (let i = 0; i < partialHeightGroup.children.length - 1; i++) {
            const point1 = new THREE.Vector3();
            const point2 = new THREE.Vector3();

            partialHeightGroup.children[i].getWorldPosition(point1);
            partialHeightGroup.children[i + 1].getWorldPosition(point2);

            const geometry = new THREE.BufferGeometry().setFromPoints([point1, point2]);
            const lineVirtual = new THREE.Line(geometry, materialV);

            partialHeightRefLines.add(lineVirtual);

            // Calculate the center of the line
            const center = new THREE.Vector3(
                (point1.x + point2.x) / 2,
                (point1.y + point2.y) / 2,
                (point1.z + point2.z) / 2
            );

            lineVirtual.position.set(
                center.x, center.y - 0.04, center.z
            );

            lineVirtual.name = "frameHeight"

            uiLinesRef.current.push(lineVirtual);
        }
    }
};

export const addUiAddOnSillH = (frameAddSill, frameValue, addSillUiRef, sceneRef, themeColor, FrameTop, FrameBottom, allOffSetData) => {

    addSillUiRef.current = [];
    removeFrameSillAddLines(sceneRef, 1);

    let wRoffset = allOffSetData?.wRoffset;
    let wLoffset = allOffSetData?.wLoffset;

    let sillHeight = allOffSetData?.sillHeight;
    let wTopOffset = allOffSetData?.wTopOffset;
    let wBOffset = allOffSetData?.wBOffset;
    let wHeightOffset = allOffSetData?.wHeightOffset;

    if (frameAddSill && frameAddSill.length > 1) {
        const frameTopBounding = new THREE.Box3().setFromObject(FrameTop);
        const frameBottomBounding = new THREE.Box3().setFromObject(FrameBottom);
        const endTopP = 0.1
        let lineDistance = 0.27 + endTopP + wTopOffset;
        
        let mainStartPoint = frameTopBounding.min.x - wLoffset
        let mainEndPoint = frameTopBounding.max.x + wRoffset
        const yPos = frameTopBounding.max.y + lineDistance;
        const zPos = frameBottomBounding.max.z;

        console.log(frameTopBounding, "frameTopBounding")

        const startPoint1 = new THREE.Vector3(mainStartPoint, yPos, zPos);
        const endPoint1 = new THREE.Vector3(mainEndPoint, yPos, zPos);

        const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);
        const material = new THREE.LineBasicMaterial({ color: themeColor ? themeColor : '#44C8F5' });
        const line1 = new THREE.Line(geometry1, material);

        // Create perpendicular line and head circle
        const createPerpendicularLine = (xPosition) => {
            const startP = new THREE.Vector3(xPosition, 0, 0); // Relative to group
            const endP = new THREE.Vector3(xPosition, -endTopP, 0); // Positioned above the main line

            const geometry = new THREE.BufferGeometry().setFromPoints([startP, endP]);
            const line = new THREE.Line(geometry, material);

            const circleRadius = 10 / 1000;
            const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);
            const circleMaterial = new THREE.MeshBasicMaterial({ color: themeColor ? themeColor : '#44C8F5', side: THREE.DoubleSide });
            const circle = new THREE.Mesh(circleGeometry, circleMaterial);
            circle.position.copy(endP);

            const group = new THREE.Group();
            group.add(line);
            group.add(circle);

            return group;
        };

        const partialWidthGroup = new THREE.Group();

        const startGroup = createPerpendicularLine(0);
        startGroup.position.set(mainStartPoint, yPos, zPos);
        partialWidthGroup.add(startGroup);

        let lastXPosition = mainStartPoint;

        frameAddSill.forEach((item, index) => {
            if (item && index < frameAddSill.length - 1) {
                const relativeX = lastXPosition + item;
                const perpendicularGroup = createPerpendicularLine(0);
                perpendicularGroup.position.set(relativeX, yPos, zPos);
                partialWidthGroup.add(perpendicularGroup);
                lastXPosition = relativeX;
            }
        });

        const endGroup = createPerpendicularLine(0);
        endGroup.position.set(mainEndPoint, yPos, zPos);
        partialWidthGroup.add(endGroup);

        const mainGroup = new THREE.Group();
        mainGroup.add(line1);
        mainGroup.add(partialWidthGroup);
        mainGroup.name = "full_sa_Lines fullLines_saH";

        sceneRef?.current?.add(mainGroup);

        const partialHeightRefLines = new THREE.Group();
        const materialV = new THREE.LineBasicMaterial({ color: 0xff0000 });

        if (partialWidthGroup && partialWidthGroup.children.length > 0) {
            for (let i = 0; i < partialWidthGroup.children.length - 1; i++) {
                const point1 = new THREE.Vector3();
                const point2 = new THREE.Vector3();

                partialWidthGroup?.children[i]?.getWorldPosition(point1);
                partialWidthGroup?.children[i + 1]?.getWorldPosition(point2);

                const geometry = new THREE.BufferGeometry().setFromPoints([point1, point2]);
                const lineVirtual = new THREE.Line(geometry, materialV);

                partialHeightRefLines.add(lineVirtual);

                const center = new THREE.Vector3(
                    (point1.x + point2.x) / 2,
                    (point1.y + point2.y) / 2,
                    (point1.z + point2.z) / 2
                );

                lineVirtual.position.set(
                    center.x, center.y - 0.03, center.z
                );

                addSillUiRef.current.push(lineVirtual);
            }
        }
    }
};

export const addUiAddOnSillV = (frameAddSill, frameValue, addSillUiRef, sceneRef, themeColor, framePosition, FrameBottom, allOffSetData) => {

    let sillHeight = allOffSetData?.sillHeight;
    let wLoffset = allOffSetData?.wLoffset;
    let wBOffset = allOffSetData?.wBOffset;

    let wRoffset = allOffSetData?.wRoffset;
    let wTopOffset = allOffSetData?.wTopOffset;
    let wHeightOffset = allOffSetData?.wHeightOffset;

    addSillUiRef.current = [];
    removeFrameSillAddLines(sceneRef, 2);

    if (frameAddSill && frameAddSill.length > 1) {
        const frameBottomBounding = new THREE.Box3().setFromObject(FrameBottom);
        const endTopP = 0.1
        let lineDistance = 0.27 + endTopP

        const mainEndPoint = frameBottomBounding.min.y - sillHeight;
        const mainStartPoint = frameBottomBounding.min.y + frameValue - sillHeight;
        const xPos = frameBottomBounding.min.x - lineDistance;
        const zPos = frameBottomBounding.max.z;

        const startPoint1 = new THREE.Vector3(xPos, mainEndPoint, zPos);
        const endPoint1 = new THREE.Vector3(xPos, mainStartPoint, zPos);

        const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);
        const material = new THREE.LineBasicMaterial({ color: themeColor ? themeColor : '#44C8F5' });
        const line1 = new THREE.Line(geometry1, material);

        const createPerpendicularLine = (yPosition) => {
            const startP = new THREE.Vector3(0, yPosition, 0);
            const endP = new THREE.Vector3(endTopP, yPosition, 0);
            const geometry = new THREE.BufferGeometry().setFromPoints([startP, endP]);
            const line = new THREE.Line(geometry, material);

            const circleRadius = 10 / 1000;
            const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);
            const circleMaterial = new THREE.MeshBasicMaterial({ color: themeColor ? themeColor : '#44C8F5', side: THREE.DoubleSide });
            const circle = new THREE.Mesh(circleGeometry, circleMaterial);
            circle.position.copy(endP);

            const group = new THREE.Group();
            group.add(line);
            group.add(circle);
            return group;
        };

        const partialHeightGroup = new THREE.Group();

        const startGroup = createPerpendicularLine(0);
        startGroup.position.set(xPos, mainStartPoint, zPos);
        partialHeightGroup.add(startGroup);

        let lastYPosition = mainStartPoint;

        frameAddSill.forEach((item, index) => {
            if (item && index < frameAddSill.length - 1) {
                const relativeY = lastYPosition - item;
                const perpendicularGroup = createPerpendicularLine(0);
                perpendicularGroup.position.set(xPos, relativeY, zPos);
                partialHeightGroup.add(perpendicularGroup);
                lastYPosition = relativeY;
            }
        });

        const endGroup = createPerpendicularLine(0);
        endGroup.position.set(xPos, mainEndPoint, zPos);
        partialHeightGroup.add(endGroup);

        const mainGroup = new THREE.Group();
        mainGroup.add(line1);
        mainGroup.add(partialHeightGroup);
        mainGroup.name = "full_sa_Lines fullLines_saV";

        if (wBOffset == 0) {
            mainGroup.position.x -= wLoffset;
        }

        sceneRef?.current?.add(mainGroup);

        const partialHeightRefLines = new THREE.Group();
        const materialV = new THREE.LineBasicMaterial({ color: 0xff0000 });

        if (partialHeightGroup && partialHeightGroup.children.length > 0) {
            for (let i = 0; i < partialHeightGroup.children.length - 1; i++) {
                const point1 = new THREE.Vector3();
                const point2 = new THREE.Vector3();

                partialHeightGroup?.children[i]?.getWorldPosition(point1);
                partialHeightGroup?.children[i + 1]?.getWorldPosition(point2);

                const geometry = new THREE.BufferGeometry().setFromPoints([point1, point2]);
                const lineVirtual = new THREE.Line(geometry, materialV);

                partialHeightRefLines.add(lineVirtual);

                const center = new THREE.Vector3(
                    (point1.x + point2.x) / 2,
                    (point1.y + point2.y) / 2,
                    (point1.z + point2.z) / 2
                );

                lineVirtual.position.set(
                    center.x, center.y - 0.03, center.z
                );

                addSillUiRef.current.push(lineVirtual);
            }
        }
    }
};

export const removeLayoutLines = (sceneRef, type) => {
    // removing Profile Joints before adding
    if (sceneRef.current && sceneRef.current.children && sceneRef.current.children.length > 0) {
        let uiLines = ''

        sceneRef?.current?.traverse(function (object) {
            let lineType = (type == 1 ? 'uiLines_h' : type == 2 ? "uiLines_v" : "uiLines")
            if (object.name.includes(lineType)) {
                object.visible = false;
                uiLines = object
            }
        });

        if (uiLines) {
            sceneRef.current.remove(uiLines);
        }
    }
}

export const removeFrameLines = (sceneRef, type) => {
    if (sceneRef.current && sceneRef.current.children && sceneRef.current.children.length > 0) {
        let uiLines = ''

        sceneRef?.current?.traverse(function (object) {
            let lineType = (type == 1 ? 'fullLines_h' : type == 2 ? "fullLines_v" : "fullLines")
            if (object.name.includes(lineType)) {
                object.visible = false;
                uiLines = object
            }
        });

        if (uiLines) {
            sceneRef.current.remove(uiLines);
        }
    }
}

export const removeFrameSillAddLines = (sceneRef, type) => {
    if (sceneRef.current && sceneRef.current.children && sceneRef.current.children.length > 0) {
        let uiLines = ''

        sceneRef?.current?.traverse(function (object) {
            let lineType = (type == 1 ? 'fullLines_saH' : type == 2 ? "fullLines_saV" : "full_sa_Lines")
            if (object.name.includes(lineType)) {
                object.visible = false;
                uiLines = object
            }
        });

        if (uiLines) {
            sceneRef.current.remove(uiLines);
        }
    }
}

export const equalPartialWidths = (frameWidth, sectionCount) => {
    let widthRatios = [];

    // Calculate the ratio for each section
    let sectionRatio = 1 / sectionCount;

    // Populate the array with equal ratios
    for (let i = 0; i < sectionCount; i++) {
        widthRatios.push(sectionRatio);
    }

    return widthRatios;
};

export const recalculateWidthRatios = (
    totalDimention,
    widthRatios,
    updatedValueInMm,
    indexToUpdate,
    lockRefIndex,
    setCustomModelData,
    setResizeSashStyles,
    setApplyPartialTrigger,
) => {

    const newUpdatedRatios = [...widthRatios]; // Copy the current ratios
    const totalRatio = 1; // Total ratio sum must be 1
    const lockedIndexes = new Set(lockRefIndex); // Convert lockRefIndex to a set for efficient lookups

    // Convert the updated value to ratio
    const updatedRatio = updatedValueInMm / (totalDimention * 1000);

    // Ensure the updated ratio does not exceed available space
    if (updatedRatio > totalRatio || updatedRatio < 0) {
        console.log("Updated value exceeds valid range.");
        return
    }

    // Calculate the difference to adjust
    const ratioDifference = newUpdatedRatios[indexToUpdate] - updatedRatio;

    // Set the updated ratio at the specified index
    newUpdatedRatios[indexToUpdate] = updatedRatio;

    // Calculate the remaining ratio to distribute
    const lockedRatioSum = Array.from(lockedIndexes).reduce((sum, idx) => sum + newUpdatedRatios[idx], 0);
    const remainingRatio = totalRatio - updatedRatio - lockedRatioSum;

    if (remainingRatio < 0) {
        console.log("Not enough space to accommodate the updated ratio.");
        return
    }

    // Distribute the remaining difference proportionally among unlocked and non-updated indexes
    const remainingIndexes = newUpdatedRatios
        .map((_, idx) => idx)
        .filter(idx => idx !== indexToUpdate && !lockedIndexes.has(idx));

    const remainingRatiosSum = remainingIndexes.reduce((sum, idx) => sum + newUpdatedRatios[idx], 0);

    for (const idx of remainingIndexes) {
        const proportion = newUpdatedRatios[idx] / remainingRatiosSum;
        newUpdatedRatios[idx] += proportion * ratioDifference;
    }

    // Normalize to ensure all ratios sum to exactly 1 (handling floating-point inaccuracies)
    const normalizedRatiosSum = newUpdatedRatios.reduce((sum, ratio) => sum + ratio, 0);
    for (let i = 0; i < newUpdatedRatios.length; i++) {
        newUpdatedRatios[i] /= normalizedRatiosSum;
    }

    setCustomModelData(prevModelData => ({
        ...prevModelData,
        partialWidthRatios: newUpdatedRatios,
    }));

    // Set necessary states
    setResizeSashStyles(true);
    setApplyPartialTrigger(false);

    // return newUpdatedRatios;
};

export const recalculateHeightRatios = (
    totalDimention,
    heightRatios,
    updatedValueInMm,
    indexToUpdate,
    lockRefIndex,
    setCustomModelData,
    setResizeSashStyles,
    setApplyPartialTrigger,
) => {

    const newUpdatedRatios = [...heightRatios]; // Copy the current ratios
    const totalRatio = 1; // Total ratio sum must be 1
    const lockedIndexes = new Set(lockRefIndex); // Convert lockRefIndex to a set for efficient lookups

    // Convert the updated value to ratio
    const updatedRatio = updatedValueInMm / (totalDimention * 1000);

    // Ensure the updated ratio does not exceed available space
    if (updatedRatio > totalRatio || updatedRatio < 0) {
        console.log("Updated value exceeds valid range.");
        return
    }

    // Calculate the difference to adjust
    const ratioDifference = newUpdatedRatios[indexToUpdate] - updatedRatio;

    // Set the updated ratio at the specified index
    newUpdatedRatios[indexToUpdate] = updatedRatio;

    // Calculate the remaining ratio to distribute
    const lockedRatioSum = Array.from(lockedIndexes).reduce((sum, idx) => sum + newUpdatedRatios[idx], 0);
    const remainingRatio = totalRatio - updatedRatio - lockedRatioSum;

    if (remainingRatio < 0) {
        console.log("Not enough space to accommodate the updated ratio.");
        return
    }

    // Distribute the remaining difference proportionally among unlocked and non-updated indexes
    const remainingIndexes = newUpdatedRatios
        .map((_, idx) => idx)
        .filter(idx => idx !== indexToUpdate && !lockedIndexes.has(idx));

    const remainingRatiosSum = remainingIndexes.reduce((sum, idx) => sum + newUpdatedRatios[idx], 0);

    for (const idx of remainingIndexes) {
        const proportion = newUpdatedRatios[idx] / remainingRatiosSum;
        newUpdatedRatios[idx] += proportion * ratioDifference;
    }

    // Normalize to ensure all ratios sum to exactly 1 (handling floating-point inaccuracies)
    const normalizedRatiosSum = newUpdatedRatios.reduce((sum, ratio) => sum + ratio, 0);
    for (let i = 0; i < newUpdatedRatios.length; i++) {
        newUpdatedRatios[i] /= normalizedRatiosSum;
    }

    setCustomModelData(prevModelData => ({
        ...prevModelData,
        partialHeightRatios: newUpdatedRatios,
    }));

    // Set necessary states
    setResizeSashStyles(true);
    setApplyPartialTrigger(false);

    // return newUpdatedRatios;
};

// export const adjustWidthsForRounding = (frameWidth, newUpdatedRatios) => {
//     // Calculate widths as integers and track the difference
//     let totalCalculatedWidth = 0;

//     const widths = newUpdatedRatios.map(ratio => {
//         // const width = Math.floor(ratio * frameWidth); // Use Math.floor to ensure no decimal
//         const width = parseInt(ratio * frameWidth); // Use Math.floor to ensure no decimal
//         totalCalculatedWidth += width;
//         return width;
//     });

//     // Calculate the lost pixels
//     const roundingError = frameWidth - totalCalculatedWidth;

//     // Distribute the lost pixels among sections
//     for (let i = 0; i < roundingError; i++) {
//         widths[i % widths.length]++; // Add 1 mm to sections iteratively
//     }

//     return widths;
// };

export const adjustWidthsForRounding = (frameWidth, newUpdatedRatios) => {
    // Calculate widths as integers and track the difference
    let totalCalculatedWidth = 0;

    const widths = newUpdatedRatios.map(ratio => {
        const width = Math.floor(ratio * frameWidth); // Ensure integer values
        totalCalculatedWidth += width;
        return width;
    });

    // Calculate the lost pixels
    const roundingError = frameWidth - totalCalculatedWidth;

    // Distribute the lost pixels among sections with the largest ratios
    const ratioIndices = newUpdatedRatios
        .map((ratio, index) => ({ ratio, index }))
        .sort((a, b) => b.ratio - a.ratio); // Sort by ratio descending

    for (let i = 0; i < roundingError; i++) {
        widths[ratioIndices[i % ratioIndices.length].index]++;
    }

    return widths;
};


export const updateSashSize = (sashSize, oldWidth, width, sashCount, lockRefIndex) => {
    // Parse width and oldWidth once
    const parsedOldWidth = parseFloat(oldWidth);
    const parsedWidth = parseFloat(width);

    let extraVal = (parsedWidth - parsedOldWidth) / sashCount;
    let lockedLength = 0;

    // If there are locked indices, adjust the calculation for extraVal
    if (lockRefIndex?.length > 0) {
        extraVal = (parsedWidth - parsedOldWidth) / (sashCount - lockRefIndex.length);
        lockedLength = lockRefIndex.reduce((sum, index) => sum + sashSize[index], 0);
    }

    let resultedArray = [];
    let newTotalWidth = 0;

    // Main loop to calculate the new sash sizes
    sashSize.forEach((item, index) => {
        let newValue = lockRefIndex?.includes(index) ? parseFloat(item) : Math.round(parseFloat(item) + extraVal);
        resultedArray.push(newValue);

        // Calculate total width
        if (newValue > 0) {
            newTotalWidth += newValue;
        } else {
            newTotalWidth -= newValue;
        }
    });

    // If the total width exceeds the desired width, recalculate
    if (newTotalWidth > parsedWidth) {
        const partNewWidth = (parsedWidth - lockedLength) / sashCount;
        resultedArray = sashSize.map((item, index) => {
            return lockRefIndex?.includes(index) ? parseFloat(item) : Math.round(partNewWidth);
        });
    }

    // Return the final adjusted sash sizes
    return resultedArray;
}

export const getAllProfileRefScene = (allProfileRefSeq, sceneRef) => {

    allProfileRefSeq.current = []

    sceneRef.current.traverse((item) => {

        if ((item.name.includes("FrameRight") || item.name.includes("FrameLeft") || item.name.includes("FrameTop") || item.name.includes("FrameBottom")) && !item.name.includes("ProfileJoints")) {
            const index = allProfileRefSeq.current.findIndex((newItem) => newItem.name === item.name);
            if (index === -1) {
                allProfileRefSeq.current.push(item);
            }
        }

        setTimeout(() => {
            if (item?.name.includes("verticalBarRight") || item?.name.includes("verticalBarLeft") || item?.name.includes("horizontalBarTop") || item?.name.includes("horizontalBarBottom")) {
                allProfileRefSeq?.current?.push(item)
            }
        }, 100);

        setTimeout(() => {
            if (item?.name.includes("sill")) {
                allProfileRefSeq?.current?.push(item)
            }
        }, 200);
    })
}

export const getAllProfileRef = (allProfileRefSeq, glassRefSeq, frameRef, sashRef, sillRef, sashGroup) => {

    allProfileRefSeq.current = []
    glassRefSeq.current = []

    if (frameRef && frameRef.current && frameRef.current.length > 0) {
        frameRef.current.forEach((item) => {
            allProfileRefSeq.current.push(item);
        });
    }

    setTimeout(() => {
        if (sashRef && sashRef.current && sashRef.current.length > 0) {
            sashRef.current.forEach((item) => {
                allProfileRefSeq.current.push(item);
            });
        }
    }, 100);

    setTimeout(() => {
        if (sillRef && sillRef.current && sillRef.current.name === 'sill') {
            allProfileRefSeq.current.push(sillRef.current);
        }
    }, 200);

    if (sashGroup && sashGroup.current && sashGroup.current.length > 0) {
        sashGroup.current.forEach((item) => {
            glassRefSeq.current.push(item);
        });
    }

    // sceneRef.current.traverse((item) => {

    //     if ((item.name.includes("FrameRight") || item.name.includes("FrameLeft") || item.name.includes("FrameTop") || item.name.includes("FrameBottom")) && !item.name.includes("ProfileJoints")) {
    //         const index = allProfileRefSeq.current.findIndex((newItem) => newItem.name === item.name);
    //         if (index === -1) {
    //             allProfileRefSeq.current.push(item);
    //         }
    //     }

    //     setTimeout(() => {
    //         if (item?.name.includes("verticalBarRight") || item?.name.includes("verticalBarLeft") || item?.name.includes("horizontalBarTop") || item?.name.includes("horizontalBarBottom")) {
    //             allProfileRefSeq?.current?.push(item)
    //         }
    //     }, 100);

    //     setTimeout(() => {
    //         if (item?.name.includes("sill")) {
    //             allProfileRefSeq?.current?.push(item)
    //         }
    //     }, 200);
    // })
}

export function calculateFrameSillOffsets(allOffSetData, modelJson, jsonIndex, addSillCombine) {
    const frameAddSillV = [];
    const frameAddSillH = [];

    const sillHeight = modelJson[jsonIndex]?.sill?.height === 0 ? 0 : allOffSetData?.sillHeight;
    const wHeightOffset = allOffSetData?.wHeightOffset;
    const wTopOffset = allOffSetData?.wTopOffset;
    const wBOffset = allOffSetData?.wBOffset;

    const wWidthOffset = allOffSetData?.wWidthOffset;
    const wLoffset = allOffSetData?.wLoffset;
    const wRoffset = allOffSetData?.wRoffset;

    // For vertical lines
    const frameSizeV = modelJson[jsonIndex]?.dimensions?.height - wHeightOffset;

    if (wTopOffset > 0) frameAddSillV.push(wTopOffset);
    if (frameSizeV > 0) frameAddSillV.push(frameSizeV);
    if (wBOffset > 0) frameAddSillV.push(wBOffset);
    if (sillHeight > 0) frameAddSillV.push(sillHeight);

    if (wBOffset > 0 && sillHeight > 0 && wBOffset + sillHeight < 0.15) {
        addSillCombine.current = true;
    } else {
        addSillCombine.current = false;
    }

    // For horizontal lines
    const frameSizeH = modelJson[jsonIndex]?.dimensions?.width - wWidthOffset;

    if (wLoffset > 0) frameAddSillH.push(wLoffset);
    if (frameSizeH > 0) frameAddSillH.push(frameSizeH);
    if (wRoffset > 0) frameAddSillH.push(wRoffset);

    return { frameAddSillV, frameAddSillH };
}
