import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import NumberInput from "../../../../../../components/NumberInput";
import { useNavigate } from "react-router-dom";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import Editprices from "../../../../../../components/Editprices";
import DropZone from "../../../../../../components/DropZone";

const FrameStylesLeft = ({
  collectionId,
  selectItemDetails,
  updateItemDetailsServices,
  handleFormReset,
}) => {
  const {
    timestamp,
    selectedRightItem,
    selected,
    setSelected,
    handleEdit,
    handleFileUpdate,
  } = useRightContext();

  var acceptFileType = {
    "image/png": [".jpg", ".jpeg", ".png", ".svg", ".gif"],
  };

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [itemId, setItemId] = useState("");
  const [minWidth, setMinWidth] = useState("100");
  const [minHeight, setMinHeight] = useState("100");
  const [maxWidth, setMaxWidth] = useState("100");
  const [maxHeight, setMaxHeight] = useState("100");
  const [defaultStyle, setDefaultStyle] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [filePreview, setFilePreview] = useState("");
  // const [activeTab, setActiveTab] = useState("General");
  const [highState, setHighState] = useState(1);
  const [wideState, setWideState] = useState(1);

  useEffect(() => {
    setSelected(selectItemDetails?.name || "");
    setName(selectItemDetails?.name || "");
    setItemId(selectItemDetails?.id || "");

    setMinWidth(selectItemDetails?.minimumWidth || 100);
    setMinHeight(selectItemDetails?.minimumHeight || 100);
    setMaxWidth(selectItemDetails?.maximumWidth || 9999);
    setMaxHeight(selectItemDetails?.maximumHeight || 9999);

    setWideState(selectItemDetails?.wide || 1);
    setHighState(selectItemDetails?.high || 1);

    setDefaultStyle(selectItemDetails?.defaultStyle || false);
    setUploadedFile("");
    setFilePreview(selectItemDetails?.imagePath || "");

    setNameError(false);
  }, [selectItemDetails]);

  useEffect(() => {
    if (filePreview) {
      handleFileUpdate(filePreview);
    }
  }, [filePreview]);

  const handleNameChange = (e) => {
    const value = e.target.value;

    if (e.target.value?.length <= 50) {
      setName(value);
      handleEdit(value);
      setSelected(value);
    }

    if (value.length > 50) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  const handleDefaultChange = (e) => {
    const value = e.target.checked;

    setDefaultStyle(value);
  };

  const handleFormSubmit = () => {
    const formDataValues = {
      id: selectItemDetails?.id,
      name: name,
      minWidth: minWidth,
      minHeight: minHeight,
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      wide: wideState,
      high: highState,
      filePath: uploadedFile || "",
      imagePath: "",
      defaultStyle: defaultStyle || false,
      collectionId: collectionId,
    };

    updateItemDetailsServices(formDataValues);
  };

  // const navigateToDesigner = () =>{
  // 	navigate('')
  // }

  // const editNavData = { url: `/pricing/systems/styles/${collectionId}` }
  const editNavData = {
    url: `/pricing/systems/styles`,
    stateValue: collectionId,
  };

  // const handleNavigation = () => {
  //   navigate(`/product/frame/designer/${collectionId}/${timestamp}`, {
  //     state: { id: frameProductId },
  //   });
  // };

  const handleBlockNavigation = () => {
    navigate(`/product/frame/block-designer/${collectionId}/${timestamp}`);
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
        {selected && (
          <h5 className="mb-0 text-wrap text-break me-4 main_title">
            {selected}
          </h5>
        )}

        <Editprices data={editNavData} hasState={true} />
      </div>

      {/* <Nav tabs className="details_header border-0 nav-tabs-custom">
        <NavItem>
          <NavLink
            className={`${activeTab === "General" && "active"}`}
            onClick={() => {
              setActiveTab("General");
            }}
          >
            General
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === "Size tags" && "active"}`}
            onClick={() => {
              setActiveTab("Size tags");
            }}
          >
            Size tags
          </NavLink>
        </NavItem>
      </Nav> */}
      {/* <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
        <TabPane tabId="General" className="flex-grow-1 w-100"> */}
      <Form
        className={`${selectedRightItem?.isSelf ? "" : "not_allowed"
          } h-100 d-flex flex-column flex-grow-1 justify-content-between`}
      >
        {/* has_tab has_btns has_head -> add respective class of included compoenent in div of class inside_scroll for proper scroll */}

        <div className="inside_scroll has_btns has_head">
          <Row>
            <Col lg="6">
              <FormGroup className="form-group">
                <Label for="name">Name</Label>
                <Input
                  disabled={!selectedRightItem?.isSelf}
                  className="mb-1"
                  name="name"
                  value={name}
                  onChange={(e) => handleNameChange(e)}
                />

                {nameError && timestamp && itemId && (
                  <div className="mt-1 text-muted text-small">
                    Max 50 characters are allowed
                  </div>
                )}

                {!name && timestamp && itemId && (
                  <div className="mt-1 text-danger text-small">
                    Name is required
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup className="form-group">
                <Label for="minWidth">Minimum width</Label>

                <NumberInput
                  disabled={!selectedRightItem?.isSelf}
                  name="minWidth"
                  value={minWidth}
                  setValue={setMinWidth}
                />
                {parseInt(minWidth, 10) < 100 && (
                  <div className="mt-1 text-danger text-small">
                    Minimum allowed width is 100.
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup className="form-group">
                <Label for="minHeight">Minimum height</Label>

                <NumberInput
                  disabled={!selectedRightItem?.isSelf}
                  name="minHeight"
                  value={minHeight}
                  setValue={setMinHeight}
                />
                {parseInt(minHeight, 10) < 100 && (
                  <div className="mt-1 text-danger text-small">
                    Minimum allowed height is 100.
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup className="form-group">
                <Label for="maxWidth">Maximum width</Label>

                <NumberInput
                  disabled={!selectedRightItem?.isSelf}
                  name="maxWidth"
                  value={maxWidth}
                  setValue={setMaxWidth}
                />
                {parseInt(maxWidth, 10) < parseInt(minWidth, 10) && (
                  <div className="mt-1 text-danger text-small">
                    Maximum width cannot be less than minimum width.
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup className="form-group">
                <Label for="maxHeight">Maximum height</Label>

                <NumberInput
                  disabled={!selectedRightItem?.isSelf}
                  name="maxHeight"
                  value={maxHeight}
                  setValue={setMaxHeight}
                />
                {parseInt(maxHeight, 10) < parseInt(minHeight, 10) && (
                  <div className="mt-1 text-danger text-small">
                    Maximum height cannot be less than minimum height.
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup className="form-group">
                <Label for="defaultSwitch">Default frame style</Label>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="defaultSwitch"
                  className="form-switch-lg default_switch"
                  onChange={(e) => handleDefaultChange(e)}
                  label={defaultStyle ? "On" : "Off"}
                  disabled={
                    selectItemDetails?.defaultStyle ||
                    !selectedRightItem?.isSelf
                  }
                  checked={defaultStyle}
                />
              </FormGroup>
            </Col>

            {/* <Col lg="6">
              <FormGroup className="mb-1">
                <Label for="wide" className="text-dark">
                  Wide
                </Label>
                <NumberInput
                  name="wide"
                  id="wide"
                  value={wideState}
                  setValue={setWideState}
                />
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup className="mb-1">
                <Label for="high" className="text-dark">
                  High
                </Label>
                <NumberInput
                  name="high"
                  id="high"
                  value={highState}
                  setValue={setHighState}
                />
              </FormGroup>
            </Col> */}

            {selectedRightItem?.isSelf && (
              <>
                <Col lg="6">
                  <FormGroup className="form-group">
                    <Label for="uploadFile">Upload image</Label>

                    <DropZone
                      acceptFileType={acceptFileType}
                      multiple={false}
                      uploadedFile={uploadedFile}
                      setUploadedFile={setUploadedFile}
                      filePreview={filePreview}
                      setFilePreview={setFilePreview}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6"></Col>
              </>
            )}

            {selectedRightItem?.isSelf && (
              <Col lg="6" className="d-block d-lg-non">
                <Row className="gx-2 mb-5">
                  <Col xs="6" lg="12" xl="6">
                    <Button
                      color="primary w-100"
                      onClick={handleBlockNavigation}
                    >
                      Design
                    </Button>
                  </Col>

                  {/* <Col xl="6">
                    <StyledTooltip
                      title="This is temporay button for block approach demo"
                      placement="bottom"
                      arrow
                    >
                      <Button color="primary w-100" onClick={handleBlockNavigation}>
                        Design
                      </Button>
                    </StyledTooltip>
                  </Col> */}
                </Row>
              </Col>
            )}
          </Row>
        </div>

        {/* Start: space for buttons at the bottom */}
        {selectedRightItem?.isSelf && (
          <div className="d-flex gap-2 gap-xl-3 main_buttons justify-content-betwee">

            <Button
              color="primary"
              size="md"
              className="px-3 px-lg-4"
              onClick={() => handleFormSubmit()}
              disabled={
                !timestamp ||
                !name.trim() ||
                parseInt(minWidth, 10) > parseInt(maxWidth, 10) ||
                parseInt(minHeight, 10) > parseInt(maxHeight, 10) ||
                parseInt(minWidth, 10) < 100 ||
                isNaN(maxWidth) ||
                parseInt(minHeight, 10) < 100 ||
                isNaN(maxHeight) ||
                isNaN(minHeight) ||
                isNaN(minWidth)
              }
            >
              Save Changes
            </Button>

            <Button
              color="primary"
              size="md"
              outline
              className="px-4"
              disabled={!timestamp}
              onClick={() => handleFormReset()}
            >
              Reset
            </Button>
            {/* <div className="d-flex gap-2 gap-xl-3">
            </div> */}

            {/* {selectedRightItem?.isSelf && (
              <Button
                color="primary"
                className="px-3 px-lg-5 d-none d-lg-block"
                onClick={handleBlockNavigation}
              >
                Design
              </Button>
            )} */}
          </div>
        )}
        {/* End: space for buttons at the bottom */}
      </Form>
      {/* </TabPane> */}
      {/* <TabPane tabId="Size tags" className="flex-grow-1 w-100">
          <SizeTagsComp
            setLoading={false}
            frameStyleCollectionId={1}
            frameStyleId={timestamp}
            activeTab={activeTab}
          ></SizeTagsComp>
        </TabPane> */}
      {/* </TabContent> */}
    </div>
  );
};

export default FrameStylesLeft;
