export const applyGlazingSpec = (specs, id, index, jsonIndex = 0, setData) => {
  const { hexValue } = specs;

  return (prevModelJson) => {
    const updatedModelArray = [...prevModelJson];
    const updatedJson = { ...updatedModelArray[jsonIndex] };
    const sash = updatedJson?.sash ? updatedJson?.sash : {};
    const transom = updatedJson?.transom ? updatedJson?.transom : {};

    if (sash && !sash.sections?.length && transom && !transom?.splits?.length) {
      updatedJson.glass.color = hexValue;
      setData(specs, -1);
    } else if (sash && sash.sections?.length) {
      if (index > -1 && index !== undefined) {
        if(sash.sections[index]) {
          sash.sections[index].color = hexValue;
        }
      } else {
        sash?.sections?.forEach((section) => {
          section.color = hexValue;
        });
      }
      setData(specs, index);
    }

    updatedModelArray[jsonIndex] = updatedJson;
    return updatedModelArray;
  };
};

// export const applyGlazingSpec2 = (
//   specs,
//   id,
//   index,
//   jsonIndex = 0,
//   transomId,
//   setMethod
// ) => {
//   return (prevModelJson) => {
//     const updatedModelArray = [...prevModelJson];
//     const updatedJson = { ...updatedModelArray[jsonIndex] };
//     const sash = updatedJson?.sash || {};
//     const transom = updatedJson?.transom || {};
//     const { hexValue } = specs; // Extract hexValue
//     const updateTransomId = transomId?.split(" ")[0];
//     const updatedIndex = updateTransomId?.split("_").pop();

//     setMethod(specs, updatedIndex);

//     if (sash && sash.sections && sash.sections.length > 0) {
//       if (updatedIndex > -1 && updatedIndex !== undefined) {
//         // Update the specific section at the given index
//         sash.sections[Number(updatedIndex)].color = hexValue;
//       }
//     }

//     if (transom && transom.splits && transom.splits.length > 0) {
//       // Update the transom with the color
//       updatedJson.transom = addTextureToObject(transom, updateTransomId, {
//         color: hexValue,
//       });
//     }

//     updatedModelArray[jsonIndex] = updatedJson;
//     return updatedModelArray;
//   };
// };

export const applyTexture = (
  textureData,
  id,
  index,
  jsonIndex = 0,
  transomId,
  setMethod
) => {
  return (prevModelJson) => {
    const updatedModelArray = [...prevModelJson];
    const updatedJson = { ...updatedModelArray[jsonIndex] };
    const sash = updatedJson?.sash || {};
    const transom = updatedJson?.transom || {};
    let updateTransomId = transomId?.split(" ")[0];
    let updatedTextureData = {
      texturePath: textureData?.modelName[0],
    };

    let updatedIndex = updateTransomId?.split("_").pop();

    setMethod(textureData, updatedIndex,false,updateTransomId);

    // Ensure `textures` exists in the main object
    if (!updatedJson.textures) {
      updatedJson.textures = {};
    } else {
      updatedJson.textures.isTexture = false; // Add the isTexture key
    }
    if (updatedIndex === "" && updateTransomId === "") {
      updatedJson.textures = {
        ...updatedTextureData,
        isTexture: true,
      };
    }
    else if ((sash && !sash.sections?.length && transom && !transom?.splits?.length)) {
      // Set isTexture in the main textures
      updatedJson.textures = {
        ...updatedTextureData,
        isTexture: true,
      };
    } else if (transom && transom?.splits && transom?.splits?.length > 0) {
      updatedJson.transom = addTextureToObject(
        transom,
        updateTransomId,
        updatedTextureData
      );
    } else if (sash && sash.sections && sash.sections?.length > 0) {
      if (sash?.sections?.length > 0) {
        sash?.sections?.forEach((item) => {
          if (item?.splits && item?.splits?.length > 0) {
            item.splits = updateTransomStatus(
              item?.splits,
              updateTransomId,
              updatedTextureData
            );
          } else if (item?.splits?.length === 0 || !item?.splits) {
            if (updatedIndex > -1 && updatedIndex !== undefined) {
              sash.sections[Number(updatedIndex)].textures = {
                ...updatedTextureData,
                isTexture: true,
              };
            } else {
              sash.sections.forEach((section) => {
                if (section.textures) {
                  section.textures.isTexture = true;
                } else {
                  section.textures.isTexture = false;
                }
              });
            }
          }
        });
      }
    }

    updatedModelArray[jsonIndex] = updatedJson;
    return updatedModelArray;
  };
};

function addTextureToObject(obj, targetId, data) {
  // Check if the current object matches the target ID
  if (obj.id === targetId) {
    return {
      ...obj,
      textures: { ...data, isTexture: true }, // Add the textures key immutably
    };
  }

  // If the object has nested `sections`, process them immutably
  if (Array.isArray(obj.sections)) {
    return {
      ...obj,
      sections: obj.sections.map((section) =>
        addTextureToObject(section, targetId, data)
      ),
    };
  }

  // If the object has nested `splits`, process them immutably
  if (Array.isArray(obj.splits)) {
    return {
      ...obj,
      splits: obj.splits.map((split) =>
        addTextureToObject(split, targetId, data)
      ),
    };
  }

  // Return the object as-is if no match is found
  return obj;
}

function updateTransomStatus(arr, targetId, data) {
  // Map over the array and process each object
  return arr.map((obj) => addTextureObject(obj, targetId, data));
}

function addTextureObject(obj, targetId, data) {
  // Check if the current object matches the target ID
  if (obj.id === targetId) {
    return {
      ...obj,
      textures: { ...data, isTexture: true }, // Add the textures key immutably
    };
  }

  // If the object has nested `sections`, process them immutably
  if (Array.isArray(obj.sections)) {
    return {
      ...obj,
      sections: obj.sections.map((section) =>
        addTextureObject(section, targetId, data)
      ),
    };
  }

  // If the object has nested `splits`, process them immutably
  if (Array.isArray(obj.splits)) {
    return {
      ...obj,
      splits: obj.splits.map((split) =>
        addTextureObject(split, targetId, data)
      ),
    };
  }

  // Return the object as-is if no match is found
  return obj;
}


export const removeTexture = (
  id,
  index,
  jsonIndex = 0,
  glassIndex,
  updateData,
  transomId
) => {
  return (prevModelJson) => {
    const updatedModelArray = [...prevModelJson];
    const updatedJson = { ...updatedModelArray[jsonIndex] };
    const sash = updatedJson?.sash || {};
    const transom = updatedJson?.transom || {};
    let updatedTransomId = transomId.split(" ")[0]
    let updatedIndex = updatedTransomId.split("_").pop();

    // Ensure `textures` exists in the main object
    if (!updatedJson.textures) {
      updatedJson.textures = {};
    }
    else {
      updatedJson.textures.isTexture = false; // Add the isTexture key
    }

    if (updatedIndex === "" && transomId === "") {
      updatedJson.textures = {
        isTexture: false,
      };
    } else if (sash && !sash.sections?.length && transom && !transom?.splits?.length) {
      // updatedJson.textures.isTexture = false; // Set isTexture in the main textures
      updatedJson.textures = {
        isTexture: false,
      };
    } else if (transom && transom?.splits && transom?.splits?.length > 0) {
      updatedJson.transom = removeTextureStatus(transom, updatedTransomId);
    } else if (sash && sash.sections?.length) {
      if (sash?.sections?.length > 0) {
        sash.sections?.forEach((item) => {
          if (item?.splits && item?.splits?.length > 0) {
            item.splits = deleteTransomStatus(item?.splits, updatedTransomId);
          } else {
            if (updatedIndex > -1 && updatedIndex !== undefined) {
              // Ensure `textures` exists in the specific section
              if (!sash.sections[Number(updatedIndex)].textures) {
                sash.sections[Number(updatedIndex)].textures = {};
              }
              // sash.sections[Number(updatedIndex)].textures.isTexture = false; // Add isTexture key to the section textures
              sash.sections[Number(updatedIndex)].textures = {
                isTexture: false,
              };
            } else {
              sash.sections.forEach((section) => {
                // Ensure `textures` exists in each section
                if (!section.textures) {
                  section.textures = {};
                }
                section.textures.isTexture = false; // Add isTexture key to each section textures
              });
            }
          }
        });
      }
    }

    updateData(Number(updatedIndex) ? Number(updatedIndex) : 0, transomId);

    updatedModelArray[jsonIndex] = updatedJson;
    return updatedModelArray;
  };
};

function removeTextureStatus(obj, targetId) {
  // Check if the current object matches the target ID
  if (obj.id === targetId) {
    return {
      ...obj,
      textures: { isTexture: false }, // Add the textures key immutably
    };
  }

  // If the object has nested `sections`, process them immutably
  if (Array.isArray(obj.sections)) {
    return {
      ...obj,
      sections: obj.sections.map((section) =>
        removeTextureStatus(section, targetId)
      ),
    };
  }

  // If the object has nested `splits`, process them immutably
  if (Array.isArray(obj.splits)) {
    return {
      ...obj,
      splits: obj.splits.map((split) => removeTextureStatus(split, targetId)),
    };
  }

  // Return the object as-is if no match is found
  return obj;
}

function deleteTransomStatus(arr, targetId) {
  // Map over the array and process each object
  return arr.map((obj) => removeTextureObject(obj, targetId));
}

function removeTextureObject(obj, targetId) {
  // Check if the current object matches the target ID
  if (obj.id === targetId) {
    return {
      ...obj,
      textures: { isTexture: false }, // Add the textures key immutably
    };
  }

  // If the object has nested `sections`, process them immutably
  if (Array.isArray(obj.sections)) {
    return {
      ...obj,
      sections: obj.sections.map((section) =>
        removeTextureObject(section, targetId)
      ),
    };
  }

  // If the object has nested `splits`, process them immutably
  if (Array.isArray(obj.splits)) {
    return {
      ...obj,
      splits: obj.splits.map((split) => removeTextureObject(split, targetId)),
    };
  }

  // Return the object as-is if no match is found
  return obj;
}