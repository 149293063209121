import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import * as THREE from 'three';
import { getFrameType, getSashType, rgbStringToHex } from '../../../utility/helper';
import { getHexValue } from '../../../utility/designerHelper';
// import HingeModel from "../../../assets/models/EvolutionHinge5.glb"

let hardwares = [];
let hardwareList = [];


export const storeHandleRefPoints = (obj) => {
    hardwares.push(obj)
}

const singleSideHandlePosition = (modelClone ,profileBounding, data) => {

    const handleBoundingBox = new THREE.Box3().setFromObject(modelClone);
    const externalOffsetZ = profileBounding.min.z - handleBoundingBox.max.z;

    if (data.side === "Inside") {
        modelClone.scale.z *= -1;
        const newBoundingBox = new THREE.Box3().setFromObject(modelClone);
        const internalOffsetZ = profileBounding.max.z - newBoundingBox.min.z;
        modelClone.position.z -= internalOffsetZ
    } else if(data.side === "Outside"){
        modelClone.position.z -= externalOffsetZ;
    }else{
        modelClone.position.z -= externalOffsetZ;
    }

}

export const flipHandleDirection = (jsonIndex , handleDirection , index) => {
    return (prevModelJson) =>{
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };

        if(updateJson?.sash.sections && updateJson?.sash?.sections?.length){
            const section = updateJson?.sash?.sections[index];
    
            Object.keys(section.sashProfile).forEach((side) => {
                const sash = section.sashProfile[side];
            
                if (sash.doorhandle) {
                    sash.doorhandle.direction = handleDirection;
                }
            });
        }
        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray;
    }
}


export const getHandleRefs = () => {
    return hardwares;
}

export const addHinges = (model , type , index , side , data ,jsonIndex , saveHardwareData) => {

    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };

        const hingeTemplate =  {
            model: model,
            color: "#00000",
            offsetX: 0,
            offsetY: 0,
            center: data?.isProfileCenter,
            defaultPosition: data?.defaultPosition,
            modelHeight: updateJson?.dimensions?.height
        }
            const section = updateJson?.sash?.sections[index];
            const sashProfile = section?.sashProfile[side];

            if (!Array.isArray(sashProfile[type])) {
                sashProfile[type] = [];
            }

            sashProfile[type] = [
                ...sashProfile[type], // Preserve existing elements
                { ...hingeTemplate },
                { ...hingeTemplate },
                { ...hingeTemplate },
            ];

            saveHardwareData(null, null, index, "hinges", null, data, 1);

        // Saving data
        // setMethod(data, index, null, type);
        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray
    }
}

export const updateHardwareOnSash = (
    index,
    name,
    offsetX,
    offsetY,
    color,
    type,
    setMethod,
    hardwareType,
    hardwareIndex,
    jsonIndex = 0,
    side
) => {
    // This will update data
    setMethod(hardwareIndex, offsetX, offsetY, color, hardwareType,side);
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updatedJson = { ...updatedModelArray[jsonIndex] };
        const section = updatedJson?.sash?.sections[index];
        const hardware = section?.sashProfile[name][type];

        if (hardware) {
            hardware.offsetX = offsetX || 0;
            hardware.offsetY = offsetY || 0;
            hardware.color = getHexValue(color);
            hardware.side = side?.name
        }

        updatedModelArray[jsonIndex] = updatedJson;

        return updatedModelArray;
    };
};


export const storeMultipleHardwaresOnProfile = async (index, side, model, data, type, setMethod, jsonIndex) => {

    return (prevModelJson) => {

        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };

        if (!side.includes("Sash")) {
            const frameSide = updateJson.frame.sides[side];
            if (!Array.isArray(frameSide[type])) {
                frameSide[type] = [];
            }

            frameSide[type].push({
                model,
                color: "#000000",
                offsetX: 0,
                offsetY: 0,
            });
        } else {
            const section = updateJson?.sash?.sections[index];
            const sashProfile = section?.sashProfile[side];

            if (!Array.isArray(sashProfile[type])) {
                sashProfile[type] = [];
            }

            sashProfile[type].push({
                model,
                color: "#000000",
                offsetX: 0,
                offsetY: 0,
            });

        }

        // Saving data
        setMethod(data, index, null, type);
        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray;
    }
}


export const updateMultiplHardwareOnProfile = (index, side, color, type, newType, hardwareIndex, setMethod, jsonIndex = 0 , horizontalPos, verticalPos) => {
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...prevModelJson[jsonIndex] };

        if (!side.includes("Sash")) {
            const frameSide = updateJson.frame.sides[side];
            if (frameSide[type]) {
                frameSide[type].forEach((item , idx) => {
                    const prevColor = prevModelJson[jsonIndex].frame.sides[side][type][idx]?.color;
                    item.color = color?.hex || rgbStringToHex(color?.rgbValue) || prevColor;
                    item.offsetX  = horizontalPos;
                    item.offsetY = verticalPos;
                })
            }
        } else {
            const section = updateJson?.sash?.sections[index];
            const sashProfile = section?.sashProfile[side];

            if (sashProfile[type]) {
                sashProfile[type].forEach((item , idx) => {
                    const prevColor = prevModelJson[jsonIndex]?.sash?.sections[index]?.sashProfile[side]?.[type][idx]?.color;
                    item.color = color?.hex || rgbStringToHex(color?.rgbValue) || prevColor;
                    item.offsetX  = horizontalPos;
                    item.offsetY = verticalPos;
                })
            }

        }

        // Saving data
        // setMethod(data, index, null, newType);
        setMethod(hardwareIndex, null, null, color, newType)
        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray;
    }
}

export const storeHardwareOnFrame = async (side, model, type, index, data, setMethod, jsonIndex = 0) => {
    setMethod(data, index, null, type, null)

    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };
        const frameSide = updateJson?.frame?.sides[side];

        if (!frameSide) {
            console.error("Invalid section hangingIndex or sash data");
            return prevModelJson; // Return unchanged JSON if validation fails
        }

        if (!frameSide[type]) {
            frameSide[type] = {
                model,
                color: "#00000",
                offsetX: 0,
                offsetY: 0,
            }
        } else {
            frameSide[type] = {
                ...frameSide[type],
                model,
                color: "#00000",
                offsetX: 0,
                offsetY: 0,
            };
        }

        updatedModelArray[jsonIndex] = updateJson;
        return updatedModelArray;
    }
}


export const updateHardwareOnFrame = (index, side, offsetX, offsetY, color, type, hardwareIndex, setMethod, jsonIndex = 0) => {
    // setMethod(data,index,null,type,null)

    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };
        const frameSide = updateJson?.frame?.sides[side];

        if (!frameSide) {
            console.error("Invalid section hangingIndex or sash data");
            return prevModelJson; // Return unchanged JSON if validation fails
        }

        const hardware = frameSide[type];

        if (hardware) {
            hardware.offsetX = offsetX;
            hardware.offsetY = offsetY;
            hardware.color = color?.hex
        }
        setMethod(hardwareIndex, offsetX, offsetY, color, type)
        updatedModelArray[jsonIndex] = updateJson;
        return updatedModelArray;
    }
}


export const deleteHardwareOnFrame = (index, side, type, setMethod, jsonIndex = 0) => {
    // setMethod(data,index,null,type,null)
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson];
        const updateJson = { ...updatedModelArray[jsonIndex] };
        const frameSide = updateJson?.frame?.sides[side];

        if (!frameSide) {
            console.error("Invalid section hangingIndex or sash data");
            return prevModelJson; // Return unchanged JSON if validation fails
        }

        if (frameSide[type]) {
            delete frameSide[type];
        }

        setMethod(index, null, type)

        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray;
    }
}


export const deleteMultipleHardwareFromProfile = async (
    index,
    side,
    type,
    hardwareType,
    setMethod,
    jsonIndex = 0,
    clearAll = false
) => {

    if (clearAll) {
        setMethod(index, null, hardwareType, true,3);
    } else {
        setMethod(index, null, hardwareType);
    }

    return (prevModelJson) => {
        try {
            if (!prevModelJson || !side || !type) {
               console.error("Invalid input");
               return;
            }

            const updatedModelArray = [...prevModelJson]

            const updateJson = { ...updatedModelArray[jsonIndex] };

            if (!side.includes("Sash")) {
                const frameSide = updateJson?.frame?.sides[side];
                const hardware = frameSide[type];
                if (Array.isArray(hardware) && hardware.length > 0)
                    if(clearAll){
                        hardware.length = 0;
                    }else{
                        hardware.pop();
                    }
                else
                    console.warn(
                        `No hardware of type '${type}' in frame side '${side}'.`
                    );
            } else {
                const hardware =
                    updateJson?.sash?.sections?.[index]?.sashProfile?.[side][type];
                if (Array.isArray(hardware) && hardware.length > 0)
                    if(clearAll){
                        hardware.length = 0;
                    }else{
                        hardware.pop();
                    }  
                else
                    console.warn(`No hardware of type '${type}' in sash side '${side}'.`);
            }

            updatedModelArray[jsonIndex] = updateJson
            return updatedModelArray;
        } catch (error) {
            console.error("Error deleting hardware:", error.message);
            return prevModelJson;
        }
    };
};


export const addHardwareOnSash = async (element, data, index, hangingIndex, orientation, type, setMethod, cylinderData = null, jsonIndex) => {

    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };
        const sash = updateJson?.sash;
        const modelHeight = updateJson?.dimensions?.height;

        if (!sash?.sections?.length || !sash?.sections[hangingIndex]) {
            console.error("Invalid section hangingIndex or sash data");
            return prevModelJson; // Return unchanged JSON if validation fails
        }

        const section = sash?.sections[hangingIndex];
        const profile = section?.sashProfile[orientation];

        if (!profile) {
            console.error(`Invalid orientation '${orientation}' in sash profile`);
            return prevModelJson;
        }

        if (!profile[type]) {
            profile[type] = {
                model: element,
                color: "#00000",
                offsetX: 0,
                offsetY: 0,
                center: data?.isProfileCenter,
                defaultPosition: data?.defaultPosition,
                modelHeight,
                side : "Both",
                direction : "default"
            };
        } else {
            profile[type] = {
                ...profile[type],
                model: element,
                color: "#00000",
                offsetX: 0,
                offsetY: 0,
                center: data?.isProfileCenter,
                defaultPosition: data?.defaultPosition,
                modelHeight,
                side: "Both",
                direction : "default"
            };
        }

        // For saving data
        setMethod(data, index, hangingIndex, type, cylinderData);
        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray; // Return the updated JSON object
    };
};


export const deleteHardwareFromSash = async (index, hangingIndex, orientation, type, setMethod, hardwareType, jsonIndex = 0) => {
    setMethod(index, hangingIndex, hardwareType)

    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };
        const sash = updateJson?.sash;

        // Validate that sash, sections, and the specific section exist
        if (!sash?.sections?.length || !sash?.sections[hangingIndex]) {
            console.error("Invalid section hangingIndex or sash data");
            return prevModelJson; // Return unchanged JSON if validation fails
        }

        const section = sash?.sections[hangingIndex];
        const profile = section?.sashProfile[orientation];

        // Validate that the profile exists
        if (!profile) {
            console.error(`Invalid orientation '${orientation}' in sash profile`);
            return prevModelJson;
        }

        // Remove the hardware object from the profile
        if (profile[type]) {
            delete profile[type]; // Deletes the entire object
        } else {
            console.warn(`No hardware of type '${type}' found in profile`);
        }

        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray;
    };
}

export const calculateVerticalPosition = (center, defaultPosition, modelHeight, profileHeight, profileBottom, profileCenter) => {

    let verticalPosition;
    if ((center && defaultPosition === 0) || (center && defaultPosition)) {
        verticalPosition = profileCenter.y;
    } else if (!center && !defaultPosition) {

        if (modelHeight > 1.4) {
            const targetHeight = 1050 / 1000; // Desired position from the bottom
            const normalizedHeight = targetHeight / profileHeight; // Normalized height (0 to 1)
            verticalPosition = profileBottom + normalizedHeight * profileHeight;
        } else {
            verticalPosition = profileCenter.y;
        }
    } else {
        const targetHeight = defaultPosition / 1000; // Desired position from the bottom
        const normalizedHeight = targetHeight / profileHeight; // Normalized height (0 to 1)
        verticalPosition = profileBottom + normalizedHeight * profileHeight;
    }

    return verticalPosition;
}




// PW = parent width => Width of the profile
// hc = harwareCount
// shw = sum of hardware widths
// ss = (PW - shw) / PW
// position will be
// ss + hardwarewidth/2

// ss = space between the hardwares
export const addHardwareInSteps = async (profile, allTrickleVents, hardwareData) => {

    const model = allTrickleVents.current;

    if (!profile || !model) {
        console.error("Invalid profile, config, or model.");
        return;
    }

    const hardwareCount = model?.length;
    // Calculate profile width (PW) and bounding box dimensions
    const boundingBox = new THREE.Box3().setFromObject(profile);
    // const zCenter = boundingBox.max.z - boundingBox.min.z;
    const profileCenter = new THREE.Vector3();
    boundingBox.getCenter(profileCenter);
    const profileWidth = boundingBox.max.x - boundingBox.min.x;

    const profileOffset = -profileWidth / 2
    // const profileOffset = -1.15


    // const hardwareCount = 3;

    let shw = 0;
    model.forEach((child, index) => {
        const hardwareBox = new THREE.Box3().setFromObject(child);
        const hardwareWidth = hardwareBox.max.x - hardwareBox.min.x;
        shw += hardwareWidth;
    })




    let positionX;
    const ss = (profileWidth - shw) / (hardwareCount + 1);
    model.forEach((child, index) => {
        const hardwareBox = new THREE.Box3().setFromObject(child);
        const hardwareWidth = hardwareBox.max.x - hardwareBox.min.x;
        positionX = (ss * (index + 1) + (hardwareWidth / 2) + (hardwareWidth * index) + profileOffset);
        child.position.set(positionX, boundingBox.min.y, profileCenter.z);

        const externalSide = child.children.find((subChild) => subChild.name.includes("Ext"));
        const internalSide = child.children.find((subChild) => subChild.name.includes("Int"));

        if (externalSide && internalSide) {

            const { externalOffsetZ, internalOffsetZ } = calculateZoffset(child, boundingBox);

            internalSide.position.z -= internalOffsetZ;
            externalSide.position.z -= externalOffsetZ;
        }
        
        const config = hardwareData[index] ? hardwareData[index] : {};

        if (child.material) {
            child.material = child.material.clone(); // Clone the material to avoid shared references
            child.material.color.set(config.color);; // Set color
        } else if (child.children && child.children.length > 0) {
            child.children.forEach((subChild) => {
                if (subChild.material) {
                    subChild.material = subChild.material.clone(); // Clone the material to avoid shared references
                    subChild.material.color.set(config.color);
                    // subChild.material.color.set(new THREE.Color(config?.color)); // Set color to red
                }
            });
        }

    })

};


export const positionHardwareOnSash = async (config, profile, model, boundingBox, direction) => {

    if (!profile || !config || !model) {
        console.error('Invalid profile or element.');
        return;
    }

    const modelExternal = model?.children?.find(child => child.name.includes("Ext"));
    const modelInternal = model?.children?.find(child => child.name.includes("Int"));
    const center = config?.center;
    const defaultPosition = config?.defaultPosition;
    const modelHeight = config?.modelHeight;

    const profileCenter = new THREE.Vector3();
    boundingBox.getCenter(profileCenter);


    const profileBottom = boundingBox.min.y;
    const profileHeight = boundingBox.max.y - boundingBox.min.y;

    const Xoffset = Number(config.offsetX) / 1000 || 0;
    const Yoffset = Number(config.offsetY) / 1000 || 0;

    let zAxis = direction === "inside" ? boundingBox.min.z : direction === "outside" ? boundingBox.max.z : profileCenter.z;

    if (direction === "inside") {
        zAxis = boundingBox.min.z
    } else if (direction === "outside") {
        zAxis = boundingBox.max.z
    } else if (modelExternal && modelInternal) {
        zAxis = profileCenter.z
    } else {
        zAxis = profileCenter.z
    }

    let verticalPosition;

    if (profile.name.includes('SashLeft') || profile.name.includes('SashRight')) {

        verticalPosition = calculateVerticalPosition(center, defaultPosition, modelHeight, profileHeight, profileBottom, profileCenter);

        model.position.set(profileCenter.x + Xoffset, verticalPosition + Yoffset, zAxis);
    } else {
        model.position.set(profileCenter.x + Xoffset, profileCenter.y + Yoffset, zAxis);
    }

    if (modelExternal && modelInternal) {

        const { externalOffsetZ, internalOffsetZ } = calculateZoffset(model, boundingBox)

        if (config?.side === "Outside") {
            modelInternal.visible = false;
        } else if (config?.side === "Inside") {
            modelExternal.visible = false;
        }

        modelInternal.position.z -= internalOffsetZ;
        modelExternal.position.z -= externalOffsetZ;
    }else{

        if (profile.name.includes('SashLeft') || profile.name.includes('SashRight')) {
            model.rotation.set(0, 0, -Math.PI / 2);
            if (config.direction === "left") {
                model.rotation.set(0, 0, -Math.PI);
            } else if (config.direction === "right") {
                model.rotation.set(0, 0, Math.PI)
            }
        } else {
            if (config.direction === "left") {
                model.rotation.set(0, 0, -Math.PI);
            } else if (config.direction === "right") {
                model.rotation.set(0, 0, Math.PI)
            }
        }
        singleSideHandlePosition(model, boundingBox, config)
    }


    if (config.color) {
        if (model.isMesh && model.material) {
            model.material = model.material.clone();
            model.material.color.set(config.color);
        } else if (model.children && model.children.length > 0) {
            model.children.forEach(child => {
                if (child.material) {
                    child.material = child.material.clone();
                    child.material.color.set(config.color);
                }
            });
        }
    }


    return model;
}



export const positionHardwareOnFrame = async (config, profile, model, direction) => {

    const boundingBox = new THREE.Box3().setFromObject(profile);
    const profileCenter = new THREE.Vector3();
    boundingBox.getCenter(profileCenter);


    const profileBottom = boundingBox.min.y; // Bottom position of the profile
    const profileHeight = boundingBox.max.y - boundingBox.min.y;

    const Xoffset = Number(config.offsetX) / 1000 || 0;
    const Yoffset = Number(config.offsetY) / 1000 || 0;

    let positionZ = direction === "inside" ? boundingBox.min.z : direction === "outside" ? boundingBox.max.z : profileCenter.z

    let verticalPosition;
    if (profile.name === 'FrameLeft' || profile.name === 'FrameRight') {

        const targetHeight = 1050 / 1000; // Desired position from the bottom
        const normalizedHeight = targetHeight / profileHeight; // Normalized height (0 to 1)

        verticalPosition = profileBottom + normalizedHeight * profileHeight;
        // verticalPosition = profileBottom + (1050 / 1000) * profileHeight;
        model.position.set(profileCenter.x + Xoffset, verticalPosition + Yoffset, positionZ);
    } else {
        model.position.set(profileCenter.x + Xoffset, profileCenter.y + Yoffset, positionZ);
    }

    if (config.color) {
        if (model.isMesh && model.material) {
            model.material = model.material.clone(); // Clone the material to avoid shared references
            model.material.color.set(config.color);
        } else if (model.children && model.children.length > 0) {
            // Apply color to child materials (if model has multiple parts)
            model.children.forEach(child => {
                if (child.material) {
                    // child.material.color.set(config.color);
                    child.material = child.material.clone(); // Clone each child's material
                    child.material.color.set(config.color);

                }
            });
        }
    }
    return model;
}


export function getHandlePosition(partitionGroup, type) {
    const typeMapping = {
        SashLeft: "SashRight",
        SashRight: "SashLeft",
        SashTop: "SashBottom",
        SashBottom: "SashTop",
    };

    const oppositeType = typeMapping[type];

    if (!oppositeType || !partitionGroup[oppositeType]) {
        console.log(`Invalid or missing sash position for type: ${type}`);
        return null; // Return null if the type or partition is invalid
    }

    const { x, y, z } = partitionGroup[oppositeType];
    return new THREE.Vector3(x, y, z);
}



export const loadHardware = (() => {
  // Cache to store loaded handles
  const handleCache = new Map();
  return (element) => {
    if (!element || element.trim() === "" || element.includes("undefined")) {
      console.error("Invalid URL provided for loading hardware.");
      return;
    }
    // Check if the handle is already loaded
    if (handleCache.has(element)) {
      // Return the cached model
      return Promise.resolve(handleCache.get(element));
    }
    const handleLoader = new GLTFLoader();
    return new Promise((resolve, reject) => {
      handleLoader.load(
        element,
        (gltf) => {
          const hardware = gltf.scene;
          handleCache.set(element, hardware);
          resolve(hardware);
        },
        undefined,
        (error) => {
          console.log(error, "632");

          console.error(`Error loading GLB for hardware: ${error.message}`);
          reject(error);
        }
      );
    });
  };
})();


export const calculateZoffset = (handle, profile) => {
    try {

        if (!handle || !handle.children || handle.children.length === 0) {
            console.error("Invalid handle object or handle has no children.");
            return;
        }


        const handleExternal = handle.children.find(child => child.name.includes("Ext"));
        const handleInternal = handle.children.find(child => child.name.includes("Int"));

        const handleBoundingExternal = new THREE.Box3().setFromObject(handleInternal);
        const handleBoundingInternal = new THREE.Box3().setFromObject(handleExternal);

        const internalOffsetZ = profile.max.z - handleBoundingInternal.min.z;
        const externalOffsetZ = profile.min.z - handleBoundingExternal.max.z;

        return { externalOffsetZ, internalOffsetZ }
    } catch (error) {
        console.error("Error in calculateZoffset:", error.message);
        return { externalOffsetZ: 0, internalOffsetZ: 0 };
    }
}




export const transonsFormedHandle = async (sashes, partitions, index, data, boundingBox) => {

    const type = sashes?.sections[index]?.type;

    const newType = getSashType(type)

    const partitionGroup = partitions[index];

    // Get handle position based on partition group and sash type
    const handlePosition = getHandlePosition(partitionGroup, newType).clone();

    const offsetX = data?.offsetX / 1000 || 0;
    const offsetY = data?.offsetY / 1000 || 0;
    
    // Add handle and create a clone
    const handle = await loadHardware(data?.model);
    const handleClone = handle.clone();
    const externalHandle = handleClone.children.find((child) => child.name.includes("Ext"));
    const internalHandle = handleClone.children.find((child) => child.name.includes("Int"));



    if(externalHandle && internalHandle){
        handleClone.position.set(handlePosition.x + offsetX , handlePosition.y + offsetY, handlePosition.z);
    

        const { externalOffsetZ, internalOffsetZ } = calculateZoffset(handleClone, boundingBox);
    
     
        if(data.side === "Outside"){
            internalHandle.visible = false;
        }
    
        if(data.side === "Inside"){
            externalHandle.visible = false;
        }
    
    
        if (externalHandle) {
            externalHandle.position.z -= externalOffsetZ; // Internal offset adjustment
        }
        if (internalHandle) {
            internalHandle.position.z -= internalOffsetZ; // External offset adjustment
        }
    }else{

        if (newType === 'SashLeft' || newType === 'SashRight') {

            let defaultPos;
            if (data.direction === "left") {
                handleClone.rotation.set(0, 0, -Math.PI);
                defaultPos = Math.PI;
            } 
             if (data.direction === "right") {
                handleClone.rotation.set(0, 0, Math.PI);
                defaultPos = Math.PI;
            }
            if(data.direction === "default"){
                handleClone.rotation.set(0, 0, -Math.PI / 2);
                defaultPos = -Math.PI / 2;
            }

        } else {
            if (data.direction === "left") {
                handleClone.rotation.set(0, 0, -Math.PI);
            } else if (data.direction === "right") {
                handleClone.rotation.set(0, 0, Math.PI)
            }
        }

        handleClone.position.set(handlePosition.x + offsetX, handlePosition.y + offsetY, handlePosition.z);
        singleSideHandlePosition(handleClone , boundingBox , data)

    }


    if (data?.color) {
        if (handleClone.isMesh && handleClone.material) {
            handleClone.material = handleClone.material.clone();
            handleClone.material.color.set(data.color);
        } else if (handleClone.children && handleClone.children.length > 0) {
           
            handleClone.children.forEach(child => {
                if (child.material) {
                    child.material = child.material.clone(); // Clone each child's material
                    child.material.color.set(data.color);

                }
            });
        }
    }

    // Return the adjusted handle clone
    return handleClone;
};

export const dualHandles = async (sashes, partitions, index, data, boundingBox) => {

    try {

        const sashCenter = new THREE.Vector3()
        boundingBox.getCenter(sashCenter)

        const profileBottom = boundingBox.min.y; // Bottom position of the profile
        const profileHeight = boundingBox.max.y - boundingBox.min.y;

        if (!sashes || !partitions || !data?.model || !boundingBox) {
            console.error("Invalid arguments passed to dualHandles function.");
            return;
        }

        const type = sashes?.sections[index]?.type;
        // const model = sashes?.sections[index]?.model;
        if (!type) {
            console.error(`No type found for sash at index ${index}.`);
            return;
        }
    
        const newType = getSashType(type)
    
        const partitionGroup = partitions[index];

        if (!partitionGroup) {
            console.error(`No partition group found for index ${index}.`);
            return;
        }

        // Get handle position based on partition group and sash type
        const handlePosition = getHandlePosition(partitionGroup, newType).clone();
        if (!handlePosition) {
            console.error("Failed to retrieve handle position.");
            return;
        }
        
        // Add handle and create a clone
        const handle = await loadHardware(data?.model);

        if (!handle) {
            console.error("Failed to load hardware model.");
            return;
        }

        const handleClone = handle.clone();
        const handleBoundingBox  = new THREE.Box3().setFromObject(handleClone);
    
        let xOffset;
        let verticalPosition
    
        if (index === 0) {
            handleClone.scale.x *= -1;
            const handleBoundingBox2  = new THREE.Box3().setFromObject(handleClone);
            xOffset = handleBoundingBox2.max.x - boundingBox.max.x;
            verticalPosition = calculateVerticalPosition(data?.center, data?.defaultPosition, data?.modelHeight, profileHeight, profileBottom, sashCenter);
            handleClone.position.x -= xOffset;
            handleClone.position.y = verticalPosition;
        } else {
            handleBoundingBox.setFromObject(handleClone); // Update bounding box after scale
            verticalPosition = calculateVerticalPosition(data?.center, data?.defaultPosition, data?.modelHeight, profileHeight, profileBottom, sashCenter);
            xOffset = handleBoundingBox.min.x - boundingBox.min.x;
            handleClone.position.x -= xOffset;
            handleClone.position.y = verticalPosition
        }
    
        // Set the position of the handle clone
        // handleClone.position.y = handlePosition.y;
        // handleClone.position.z = handlePosition.z;
        
        // Calculate Z offsets for internal and external parts
        const { externalOffsetZ, internalOffsetZ } = calculateZoffset(handleClone, boundingBox);
    
        // Adjust positions of the external and internal handle parts
        const externalHandle = handleClone.children.find((child) => child.name.includes("Ext"));
        const internalHandle = handleClone.children.find((child) => child.name.includes("Int"));

        if (externalHandle) {
            externalHandle.position.z -= externalOffsetZ; // Internal offset adjustment
        }
        if (internalHandle) {
            internalHandle.position.z -= internalOffsetZ; // External offset adjustment
        }


        if (index === 0) {
            if (data?.color) {
                if (handleClone.isMesh && handleClone.material) {
                    handleClone.material = handleClone.material.clone(); // Clone the material to avoid shared references
                    handleClone.material.color.set(data.color);
                } else if (handleClone.children && handleClone.children.length > 0) {
                    // Apply color to child materials (if model has multiple parts)
                    handleClone.children.forEach(child => {
                        if (child.material) {
                            // child.material.color.set(config.color);
                            child.material = child.material.clone(); // Clone each child's material
                            child.material.color.set(data.color);

                        }
                    });
                }
            }
        } else if (index = 1) {
            if (data?.color) {
                if (handleClone.isMesh && handleClone.material) {
                    handleClone.material = handleClone.material.clone(); // Clone the material to avoid shared references
                    handleClone.material.color.set(data.color);
                } else if (handleClone.children && handleClone.children.length > 0) {
                    // Apply color to child materials (if model has multiple parts)
                    handleClone.children.forEach(child => {
                        if (child.material) {
                            // child.material.color.set(config.color);
                            child.material = child.material.clone(); // Clone each child's material
                            child.material.color.set(data.color);

                        }
                    });
                }
            }
        }
    
        // Return the adjusted handle clone
        return handleClone;
    } catch (error) {
        console.error(error);
    }
}


export const applyHinges = async (data, boundingBox , group , sashPosition) => {


    try {

        if (!Array.isArray(data) || data.length === 0) {
            console.error("Not an array");
            return;
        }
        
        const hinge = await loadHardware(data[0]?.model);

        if(!hinge){
            console.error("No hinge");
            return;
        }

        const verticalLength = boundingBox.max.y - boundingBox.min.y;
        const numHinges = data?.length;
        const distanceBetweenHinges = verticalLength / (numHinges + 0.5);
    
        for (let i = 0; i < numHinges; i++) {
            console.log(sashPosition , "903");
            
            const hingePosition = boundingBox.min.y + (i + 1) * distanceBetweenHinges;
            const verticalOffset = data[i]?.offsetY / 1000 || 0;
            const horizontalOffset = data[i]?.offsetX / 1000 || 0;
            let hingeInstance = hinge.clone();

            const xPosition = sashPosition === "SashLeft"
                    ? boundingBox.min.x + horizontalOffset // Left sash
                    : boundingBox.max.x + horizontalOffset;
        

            hingeInstance.position.set(xPosition, hingePosition + verticalOffset, boundingBox.min.z);


            if (data[i]?.color) {
                hingeInstance.traverse((child) => {
                    if (child.isMesh) {
                        child.material = child.material.clone(); // Clone material to avoid affecting other objects
                        child.material.color.set(data[i].color); // Set color
                        child.material.needsUpdate = true; // Ensure material updates
                    }
                });
            }

            if(sashPosition === "SashLeft"){
                group.add(hingeInstance.clone());
            }else{
                group.add(hingeInstance.clone());
            }

            // if (data[i]?.color) {
            //     if (hingeInstance.isMesh && hingeInstance.material) {
            //         hingeInstance.material = hingeInstance.material.clone();
            //         hingeInstance.material.color.set(data[i].color);
            //     } else if (hingeInstance.children && hingeInstance.children.length > 0) {
            //         hingeInstance.children.forEach(child => {
            //             if (child.material) {
            //                 child.material = child.material.clone();
            //                 child.material.color.set(data[i].color);
            //             }
            //         });
            //     }
            // }
        }        
    } catch (error) {
        console.error(error);
        
    }

}

