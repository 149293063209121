import * as THREE from 'three';
import { createExternalMesh } from './utils/threeUtils'
import { getModelFileUrl, updateTransomCount } from './utils/generalUtils';
import { createSashFromTransom, getSashHangingRefs, storeHangingRefs } from './sashes';
import { createProfileMaterial } from './utils/materials.js';
import { getSashType } from '../../../utility/helper.js';


let partitionArr = [];
let transomsArr = [];
let glassThickness = 0.01;

const verticalPartitionMap = new Map();

export const storeTransomPartitionRefs = (obj, callBack) => {
  partitionArr.push(obj);
  if (typeof callBack === 'function') {
    callBack();
  }
}

const storeVerticalPartion = (sectionId, mesh) => {
  let parts = sectionId?.split("_");
  const hangingIndex = parts && parts?.length ? parts[parts?.length - 1] : null;

  if (!verticalPartitionMap.has(hangingIndex)) {
    verticalPartitionMap.set(hangingIndex, []);
  }

  const currentPartition = verticalPartitionMap.get(hangingIndex);

  // Check if the mesh is already present to avoid duplicates
  const isAlreadyStored = currentPartition.some((storedMesh) => storedMesh.name === mesh.name);
  if (!isAlreadyStored) {
    currentPartition.push(mesh);
  }

}

export const storeTransomRefs = (obj, callBack) => {
  transomsArr.push(obj);
  if (typeof callBack === "function") {
    callBack();
  }
}

export const getTransomPartitionRefs = () => {
  return partitionArr;
}

export const getTransomRefs = () => {
  return transomsArr;
}

export const emptyTransomPartitions = () => {
  partitionArr = [];
}

export const emptyTransomRefs = () => {
  transomsArr = [];
}

export const manipulateTransoms = (transomConfig, data, setMethod, jsonIndex = 0, updatePanelDataForTransoms, type = "transoms", numSections, side, defaultHandle) => {

  let {
    transomHeight,
    transomWidth,
    initialRatios,
    orientation,
    sectionId,
    material = "wood",
    selectedTransom,
    sashId,
    sashWidth,
    sashHeight,
    sashMullion
  } = transomConfig;

  const height = transomHeight / 1000;
  const depth = transomWidth / 1000;

  let transformedSectionId = sectionId ? sectionId.split(" ") : null;

  if (transformedSectionId && transformedSectionId?.length) {
    sectionId = transformedSectionId[0]
  }

  setMethod(data, 0);

  const typeMapping = {
    SashLeft: "SashRight",
    SashRight: "SashLeft",
    SashTop: "SashBottom",
    SashBottom: "SashTop",
  };

  return (prevModelJson) => {

    const updateModelArray = [...prevModelJson]
    const updatedModelJson = { ...updateModelArray[jsonIndex] };

    let parts = sectionId?.split("_") || selectedTransom?.split("_");
    const hangingIndex = parts && parts?.length ? parts[parts?.length - 1] : null;

    // Default transom data structure
    let mullionData = {
      width: sashMullion?.width / 1000,
      depth: sashMullion?.height / 1000
    }
    let handleModel = getModelFileUrl(defaultHandle?.modelFilePath);

    let doorHandle = {
      model: handleModel,
      color: "#00000",
      offsetX: 0,
      offsetY: 0,
      center: defaultHandle?.isProfileCenter,
      defaultPosition: defaultHandle?.defaultPosition,
      modelHeight: updatedModelJson?.dimensions?.height,
      side: "Both",
      direction: "default"
    };

    let glassColor = updatedModelJson?.glass?.color

    const createTransomData = (ratios, orientation, parentId, type, numSections) => {

      const sections = Array.from({ length: numSections }, (_, index) => ({
        id: `section_${index}_${parentId}`,
        material: "glass",
        opacity: 0.6,
        reflectivity: 0.5,
        color: glassColor ? glassColor : "#B0E0E6",
        type: side,
      }));

      const sideSash = getSashType(side);
      const oppositeSide = typeMapping[sideSash];


      if (type === "sash") {
        sections.forEach((section) => {
          section.sashProfile = {
            SashTop: { width: sashWidth / 1000, height: sashHeight / 1000, id: sashId, isVisible: true },
            SashRight: { width: sashWidth / 1000, height: sashHeight / 1000, id: sashId, isVisible: true },
            SashBottom: { width: sashWidth / 1000, height: sashHeight / 1000, id: sashId, isVisible: true },
            SashLeft: { width: sashWidth / 1000, height: sashHeight / 1000, id: sashId, isVisible: true },
          };

          if (section.type && (section.type === "left" || section.type === "right")) {
            section.sashProfile[oppositeSide].doorhandle = doorHandle;
          }
        });
      }

      return {
        id: `split_${parentId}`,
        type: type,
        orientation,
        ratios,
        height,
        depth,
        transomId: data?.id,
        hangings: numSections,
        material: "glass",
        sections,
        masterSlave: {
          mullion: (side === "master/slave" || side === "slave/master") ? mullionData : false
        },
        partialHeightRatios: [1]
      }
    };

    const findAndAddSplit = (node, sectionId, orientation) => {
      if (!node.splits) {
        // Create initial split with two sections
        node.splits = [
          createTransomData(initialRatios, orientation, node.id, type, numSections),
        ];
        return;
      }

      // Traverse splits to find matching sectionId
      for (const split of node.splits) {

        if (selectedTransom) {

          let selectedId = selectedTransom?.split(" ");
          selectedId = selectedId[1];
          // selectedId = selectedId?.split("_");
          // selectedId = selectedId[0];

          if (split.id === selectedId) {
            split.height = height;
            split.depth = depth;
            split.transomId = data?.id;
          }
        }

        for (const section of split.sections) {
          if (section.id === sectionId) {
            if (!section.splits) {
              section.splits = [
                createTransomData(
                  initialRatios,
                  orientation, // Alternate orientation
                  section.id,
                  type,
                  numSections
                ),
              ];
            } else {
              findAndAddSplit(section, sectionId, orientation);
            }
            return;
          } else if (section.splits) {
            findAndAddSplit(section, sectionId, orientation);
          }
        }
      }
    };

    if (updatedModelJson?.sash?.sections?.length) {

      let section = hangingIndex !== null ? updatedModelJson.sash.sections[hangingIndex] : {};

      if (orientation === "vertical") {
        section.transomCount += 1;
      }


      if (!section?.splits) {
        // Use the common logic to create a new transom split
        section.splits = [
          createTransomData(
            initialRatios || [0.5, 0.5],
            orientation,
            sectionId,
            type,
            numSections
          ),
        ];
      } else {

        if (selectedTransom) {

          let selectedId = selectedTransom?.split(" ");
          selectedId = selectedId[1];

          for (const split of section.splits) {
            if (split.id === selectedId) {
              split.height = height;
              split.depth = depth;
            }
          }
        }


        findAndAddSplit(section, sectionId, orientation);
      }
    } else {
      // General logic for transoms
      const stack = [{ node: updatedModelJson }];

      while (stack.length > 0) {
        const { node } = stack.pop();

        // If node has a transom, manipulate its splits
        if (node.transom) {
          if (!node.transom.splits) {
            // Add a default split if none exist
            node.transom = {
              type: "transom",
              transomId: data?.id,
              material,
              height,
              depth,
              splits: [
                createTransomData(initialRatios, orientation, "transom_split_0", type, numSections),
              ],
            };
          } else {
            // Add further splits to the specific sectionId in existing splits
            findAndAddSplit(node.transom, sectionId, orientation);
          }
        }
      }
    }

    updatePanelDataForTransoms(sectionId, false);

    updateModelArray[jsonIndex] = updatedModelJson;

    return updateModelArray;
  };
};



export const deleteTransomSplit = (deleteConfig, jsonIndex = 0, updateData) => {
  let sectionId = deleteConfig?.data?.name;
  sectionId = sectionId.split(' ');
  sectionId = sectionId[0]
  let index = deleteConfig?.index;
  let deletePanelData = false;
  if (index == 0) {
    deletePanelData = true;
  }

  return (prevModelJson) => {

    const updateModelArray = [...prevModelJson]
    const updatedModelJson = { ...updateModelArray[jsonIndex] };

    let parts = sectionId?.split("_");
    const hangingIndex = parts && parts?.length ? parts[parts?.length - 1] : null;


    const deleteSplitArray = (node, sectionId) => {
      if (!node.splits || node.splits.length === 0) return false;

      const lastSplit = node.splits[node.splits.length - 1];

      // Check if the last split contains the matching sectionId
      if (lastSplit.sections.some(section => section.id === sectionId)) {
        delete node.splits; // Remove the entire splits array
        return true;
      }

      // Recursively check nested splits
      for (const section of lastSplit.sections) {
        if (section.splits && deleteSplitArray(section, sectionId)) {
          return true;
        }
      }

      return false;
    };


    if (updatedModelJson?.sash?.sections?.length) {
      let selectedSection = updatedModelJson?.sash?.sections[hangingIndex];
      selectedSection.transomCount -= 1;
      if (selectedSection && selectedSection?.splits?.length) {
        deleteSplitArray(selectedSection, sectionId);
      }
    }

    if (updatedModelJson?.transom && updatedModelJson?.transom?.splits?.length > 0) {
      const stack = [{ node: updatedModelJson }];
      while (stack.length > 0) {
        const { node } = stack.pop();
        if (node.transom) {
          deleteSplitArray(node.transom, sectionId);
        }
      }
    }

    updateModelArray[jsonIndex] = updatedModelJson;

    updateData(sectionId, deletePanelData)

    return updateModelArray;
  };
};



export const processSplit = (split, parentGroup, startPosX, startPosY, parentWidth, parentHeight, transomFrameHeight, transomFrameDepth, materials, colorVal, modelName, framePathCollection, verticalNested = 0, isFirstHorizontal = true) => {

  const orientation = split?.orientation;
  transomFrameHeight = split?.height;
  transomFrameDepth = split?.depth;

  const splitRatios = split?.ratios && split.ratios.length > 1 ? split.ratios : [0.5, 0.5];

  if (splitRatios && splitRatios.length < 2) {
    console.error("Error: Invalid split ratios. Expected an array with at least two values.");
    return;
  }

  let lockingPlate = modelName === "Heritage Door" ? "LockingPlate" : ""


  const startPosX2 = startPosX + (orientation === "vertical" ? splitRatios[0] * parentWidth : 0);
  const startPosY2 = startPosY - (orientation === "horizontal" ? splitRatios[0] * parentHeight : 0);
  const section = split?.sections[0];
  const section2 = split?.sections[1];


  if (!section || !section.id || !section2 || !section2.id) {
    console.error("Error: Invalid sections in split. Sections must have an id.");
    return;
  }

  let newCount = verticalNested;
  if (orientation === "horizontal") {
    const transomGeometry = new THREE.BoxGeometry(
      parentWidth,
      transomFrameHeight,
      transomFrameDepth
    );
    const transomMesh = new THREE.Mesh(transomGeometry, materials.frameMaterial);
    transomMesh.position.y = startPosY2;
    transomMesh.position.x = startPosX2 + (parentWidth / 2);

    transomMesh.name = `${section?.id} ${split?.id}`;



    const transomBounding = new THREE.Box3().setFromObject(transomMesh);
    const center = new THREE.Vector3()
    transomBounding.getCenter(center)

    const transomPos = {
      position: { x: center?.x, y: center?.y, z: center?.z },
      name: transomMesh.name,
      type : "horizontal"
    }
    storeTransomRefs(transomPos, getTransomRefs);
    parentGroup.add(transomMesh);
    transomMesh.transType = "horizontal";
    transomMesh.transHeightCenter = transomFrameHeight / 2;
    let externalColor = true;
    if (externalColor) {
      createExternalMesh(transomMesh, materials.frameMaterial_Ext, transomGeometry, parentGroup)
    }
  } else {
    const transomGeometry = new THREE.BoxGeometry(
      transomFrameHeight,
      parentHeight,
      transomFrameDepth
    );
    const transomMesh = new THREE.Mesh(transomGeometry, materials.frameMaterial);
    transomMesh.position.y = startPosY2 - parentHeight / 2;
    // transomMesh.position.x = startPosX2;
    transomMesh.position.x = startPosX2;
    transomMesh.name = `${section2?.id} ${split?.id}`;

    const transomBounding2 = new THREE.Box3().setFromObject(transomMesh);
    const center = new THREE.Vector3()
    transomBounding2.getCenter(center)

    const transomPos = {
      position: { x: center?.x, y: center?.y, z: center?.z },
      name: transomMesh.name,
      type : "vertical"
    }
    storeTransomRefs(transomPos, getTransomRefs)
    parentGroup.add(transomMesh);
    transomMesh.transType = "vertical";
    transomMesh.transHeightCenter = transomFrameHeight / 2;
    let externalColor = true;
    if (externalColor) {
      createExternalMesh(transomMesh, materials.frameMaterial_Ext, transomGeometry, parentGroup)
    }
    // verticalNested = false;
    newCount = verticalNested + 1;
  }
  let verticalOffset = verticalNested == 0 ? transomFrameHeight / 2 : verticalNested == 1 ? 0 : 0;
  let verticalOffset2 = verticalNested == 0 ? 0 : verticalNested == 1 ? transomFrameHeight / 2 : 0;
  const splitHeight = split?.orientation === 'horizontal'
    ? splitRatios[0] * parentHeight // Constrain height to parent's height
    : parentHeight;
  const splitWidth = split?.orientation === 'horizontal'
    ? parentWidth
    : splitRatios[0] * parentWidth;
  const splitHeight2 = split?.orientation === 'horizontal'
    ? splitRatios[1] * parentHeight // Constrain height to parent's height
    : parentHeight;
  const splitWidth2 = split?.orientation === 'horizontal'
    ? parentWidth
    : splitRatios[1] * parentWidth;

  if (section.splits) {

    if (section.splits[0].type == "sash") {

      let sashHangingValue = split?.hangings;
      let generalData = {
        color: colorVal,
        colorExt: colorVal,
        dimensions: {
          width: splitWidth,
          height: splitHeight
        },
        startPosX: startPosX,
        startPosY: startPosY,
        materials: materials
      }
      let partialWidth = [1];
      // let masterSlave = {
      //     mullion: {
      //         width: 0.07,
      //         depth: 0.03
      //     }
      // }


      let masterSlave = section?.masterSlave?.mullion ? { ...section?.masterSlave } : false

      //  partialWidth = [0.5, 0.5];
      createSashFromTransom(section.splits[0], parentGroup, null, splitWidth - verticalOffset - (verticalNested == 1 ? 0 : -transomFrameHeight / 2),
        splitHeight, sashHangingValue || 2, { current: [] }, partialWidth,
        null, masterSlave, generalData, 0, 0, 0, framePathCollection, "GS20 Sliding Door",
        []);
      // createSash(
      //   section.splits[0], parentGroup, frame?.sides, windowData?.dimensions?.width - wWidthOffset, 
      //   windowData?.dimensions?.height - wHeightOffset, sashHangingValue || 2, sashGroup, partialWidthRatios, 
      //   allTrickleVents, masterSlave, windowData, wLoffset, wTopOffset, wBOffset, framePathCollection, modelName, 
      //   splitArray);
    } else {
      // let newCount = orientation == "vertical" ? verticalNested + 1 : verticalNested;
      newCount = section.splits[0].orientation == "vertical" ? verticalNested + 1 : verticalNested;
      // if(section.splits[0].orientation == "vertical") verticalNested++;
      processSplit(
        section.splits[0],
        parentGroup,
        startPosX,
        startPosY,
        splitWidth,
        splitHeight, transomFrameHeight, transomFrameDepth, materials, colorVal, "", framePathCollection, newCount, true
      );
    }
  } else {
    const glassGeometry = new THREE.BoxGeometry(splitWidth, splitHeight, glassThickness);
    const glassMesh = new THREE.Mesh(glassGeometry, materials.glassMaterial);

    glassMesh.position.set(
      startPosX + splitWidth / 2,
      startPosY - splitHeight / 2,
      -glassThickness / 2
    );

    glassMesh.name = `${section?.id} GlassPanel ${lockingPlate}`;
    glassMesh.renderOrder = -10;

    if (split?.orientation === "horizontal") {
      storeVerticalPartion(section?.id, glassMesh)
    }

    const glassBounding = new THREE.Box3().setFromObject(glassMesh);
    const center = new THREE.Vector3()
    glassBounding.getCenter(center)
    const glassPos = {
      position: { x: center?.x, y: center?.y, z: center?.z },
      name: glassMesh.name
    }

    if(split?.type === "transoms"){
      storeHangingRefs(glassPos , getSashHangingRefs)
    }


    storeTransomPartitionRefs(glassPos, getTransomPartitionRefs)
    let isPanelEnabled = section?.panels?.isPanel || false; //panels *****

    if (!isPanelEnabled) {
      parentGroup.add(glassMesh);
    } else {

      const frameMaterial_Ext = createProfileMaterial(colorVal)

      const panelMesh = new THREE.Mesh(glassGeometry.clone(), frameMaterial_Ext);
      panelMesh.name = `${section?.id}`;
      panelMesh.scale.set(0.999, 0.999, 1);


      panelMesh.position.set(
        startPosX + splitWidth / 2,
        startPosY - splitHeight / 2,
        (transomFrameDepth * 0.5) - (glassThickness * 0.5) - 0.011
      );

      // panelMesh.position.z = ;
      parentGroup.add(panelMesh);

      let externalColor = true;
      if (externalColor) {
        createExternalMesh(panelMesh, materials.panelMaterial, glassGeometry, parentGroup)
      }
    }
    // parentGroup.add(glassMesh);
  }
  if (section2.splits) {

    if (section2.splits[0].type == "sash") {
      let sashHangingValue = split?.hangings;
      let generalData = {
        color: colorVal,
        colorExt: colorVal,
        dimensions: {
          width: splitWidth2 - transomFrameHeight / 2,
          height: splitHeight2 - transomFrameHeight / 2
        },
        startPosX: startPosX2,
        startPosY: startPosY2,
        materials: materials
      }
      //below should be dynamic, *****
      let partialWidth = [1];

      let masterSlave = section2?.masterSlave?.mullion ? { ...section2?.masterSlave } : false;
      //  partialWidth = [0.5, 0.5];
      createSashFromTransom(section2.splits[0], parentGroup, null, splitWidth2 - 0 - (verticalNested == 1 ? -transomFrameHeight / 2 : 0),
        splitHeight2, sashHangingValue || 2, { current: [] }, partialWidth,
        null, masterSlave, generalData, 0, 0, 0, framePathCollection, "GS20 Sliding Door",
        []);
      // createSash(
      //   section2.splits[0], parentGroup, frame?.sides, windowData?.dimensions?.width - wWidthOffset, 
      //   windowData?.dimensions?.height - wHeightOffset, sashHangingValue || 2, sashGroup, partialWidthRatios, 
      //   allTrickleVents, masterSlave, windowData, wLoffset, wTopOffset, wBOffset, framePathCollection, modelName, 
      //   splitArray);
    } else {
      // if (section2.splits) {
      newCount = section2.splits[0].orientation == "vertical" ? verticalNested + 1 : verticalNested;
      processSplit(
        section2.splits[0],
        parentGroup,
        startPosX2,
        startPosY2,
        splitWidth2,
        splitHeight2, transomFrameHeight, transomFrameDepth, materials, colorVal, "", framePathCollection, newCount, true
      );
    }
  } else {
    const glassGeometry2 = new THREE.BoxGeometry(splitWidth2, splitHeight2, glassThickness);
    const glassMesh2 = new THREE.Mesh(glassGeometry2, materials.glassMaterial);
    glassMesh2.position.set(
      startPosX2 + splitWidth2 / 2,
      startPosY2 - splitHeight2 / 2,
      -glassThickness / 2
    );
    glassMesh2.name = `${section2?.id} GlassPanel`;
    glassMesh2.renderOrder = -10;
    const glassBounding2 = new THREE.Box3().setFromObject(glassMesh2);
    const center = new THREE.Vector3()
    glassBounding2.getCenter(center)
    const glassPos2 = {
      position: { x: center?.x, y: center?.y, z: center?.z },
      name: glassMesh2.name
    }

    if (split?.orientation === "horizontal") {
      storeVerticalPartion(section2?.id, glassMesh2)
    }

    if(split?.type === "transoms"){
      storeTransomPartitionRefs(glassPos2, getTransomPartitionRefs)
      storeHangingRefs(glassPos2 , getSashHangingRefs)
    }


    let isPanelEnabled = section2?.panels?.isPanel || false; //panels *****

    if (!isPanelEnabled) {
      parentGroup.add(glassMesh2);
    } else {
      const frameMaterial_Ext = createProfileMaterial(colorVal)

      const panelMesh = new THREE.Mesh(glassGeometry2.clone(), frameMaterial_Ext);
      panelMesh.name = `${section?.id}`;
      panelMesh.scale.set(0.999, 0.999, 1);
      panelMesh.position.set(
        startPosX2 + splitWidth2 / 2,
        startPosY2 - splitHeight2 / 2,
        (transomFrameDepth * 0.5) - (glassThickness * 0.5) - 0.011
      );
      // panelMesh.position.set(
      //   startPosX + splitWidth / 2,
      //   startPosY - splitHeight / 2,
      //   (transomFrameDepth * 0.5) - (glassThickness * 0.5) - 0.011
      // );
      // panelMesh.position.z = ;
      parentGroup.add(panelMesh);

      let externalColor = true;
      if (externalColor) {
        createExternalMesh(panelMesh, materials.panelMaterial, glassGeometry2, parentGroup)
      }
    }
    // parentGroup.add(glassMesh2);
  }

}

function addTempSashData(transomData, tempSash) {
  console.log("transomData 11 :::: ", transomData);
  if (!transomData.splits) {
    transomData.splits = [];
    transomData.splits[0] = tempSash;
  }
  console.log("transomData 111 :::: ", transomData);
}
function removeTempSashData(transomData, tempSash) {
  console.log("transomData 22 :::: ", transomData);
  transomData.splits = null;
}
export function createTransomGlassPath(windowWidth, windowHeight, sideThickness, transomData, materials, colorVal, parentGroup, isTranomTriggered, partialWidthRatios, partialHeightRatios, frameWidth, frameHeight, framePathCollection) {
  emptyTransomPartitions();
  emptyTransomRefs();

  // let tempTarget = transomData.splits[0].sections[0].splits[0].sections[0];
  // let tempTarget = transomData.splits[0].sections[0].splits[0].sections[1];
  // let tempTarget = transomData.splits[0].sections[0];
  // let tempTarget = transomData.splits[0].sections[1];
  // addTempSashData(transomData.splits[0].sections[0], tempSash)
  // removeTempSashData(tempTarget, tempSash)
  // addTempSashData(tempTarget, tempSash)

  const transomFrameHeight = transomData.height || 0.01;
  const transomFrameDepth = transomData.depth || 0.05;

  const group = new THREE.Group();

  // Create transom splits
  // transomData?.splits?.forEach((split) => {
  //   const splitRatios = split.ratios;
  //   let startPosition = split.orientation === 'horizontal' ?  
  //   (windowHeight / 2) - windowFrameHeight : 
  //   -(windowWidth / 2) + windowFrameHeight;

  //   let partionIndex = 1;
  //   splitRatios.forEach((ratio) => {
  //     const splitHeight = split.orientation === 'horizontal' ? 
  //     ratio * ((windowHeight - (2 * windowFrameHeight)) - (transomFrameHeight)) :
  //     windowHeight - 2 * windowFrameHeight;

  //     const splitWidth = split.orientation === 'horizontal' ? 
  //     windowWidth - (2 * windowFrameHeight) : 
  //     ratio * ((windowWidth - (2 * windowFrameHeight)) - transomFrameHeight);

  //     const glassGeometry = new THREE.BoxGeometry(
  //       splitWidth,
  //       splitHeight,
  //       glassThickness
  //     );
  //     const glassMesh = new THREE.Mesh(glassGeometry, materials.glassMaterial);
  //     let transomGeometry = glassGeometry.clone();
  //     const transomMesh = new THREE.Mesh(transomGeometry, materials.frameMaterial);
  //     let transXoffset = 0, transYoffset = 0;
  //     if (split.orientation === 'horizontal') {          
  //       glassMesh.position.set(
  //         0,
  //       startPosition - splitHeight/2,
  //         -glassThickness / 2
  //       );
  //       startPosition -= transomFrameHeight + splitHeight;
  //       if(partionIndex == 1){
  //           transomGeometry = new THREE.BoxGeometry(
  //               splitWidth,
  //               transomFrameHeight,
  //               transomFrameDepth
  //           );
  //           transYoffset = startPosition + transomFrameHeight/2;
  //       }

  //     } else {
  //       glassMesh.position.set(
  //         startPosition + splitWidth / 2,
  //         0,
  //         -glassThickness / 2
  //       );
  //       startPosition += transomFrameHeight + splitWidth//(splitWidth + 2 * transomFrameHeight);
  //       if(partionIndex == 1){
  //           transomGeometry = new THREE.BoxGeometry(
  //               transomFrameHeight,
  //               splitHeight,
  //               transomFrameDepth
  //           );
  //           transXoffset = startPosition - transomFrameHeight/2;
  //       }

  //     }

  //     split.sections.forEach((section) => {
  //       glassMesh.name = section?.id
  //     })

  //     group.add(glassMesh);
  //     const boundingBox = new THREE.Box3().setFromObject(glassMesh);
  //     const center = new THREE.Vector3()
  //     boundingBox.getCenter(center)  
  //     const glassPos = {
  //       position: { x: center?.x, y: center?.y, z: center?.z },
  //       name: glassMesh?.name
  //     }
  //     storeTransomPartitionRefs(glassPos , getTransomPartitionRefs)

  //     if(partionIndex == 1){
  //       transomMesh.geometry = transomGeometry;
  //       transomMesh.position.y = transYoffset;
  //       transomMesh.position.x = transXoffset;
  //       group.add(transomMesh);
  //       partionIndex++;
  //     }

  //   });
  // });
  // console.log("transomData :::: ", transomData);
  // transomData ={
  //     "type": "transom",
  //     "material": "wood",
  //     "height": 0.03,
  //     "depth": 0.1,
  //     "splits": [
  //         {
  //             "id": "transom_split_0",
  //             "type": "transom",
  //             "orientation": "horizontal",//vertical, horizontal
  //             "ratios": [
  //                 0.5,
  //                 0.5
  //             ],
  //             "material": "glass",
  //             "sections": [
  //                 {
  //                     "id": "transom_section_1",
  //                     "material": "glass",
  //                     "opacity": 0.6,
  //                     "reflectivity": 0.5,
  //                     "color": "#B0E0E6",
  //                     "splits": [
  //                         {
  //                             "id": "transom_0_1_split",
  //                             "type": "transom",
  //                             "orientation": "vertical",//vertical, horizontal
  //                             "ratios": [
  //                                 0.5,
  //                                 0.5
  //                             ],
  //                             "material": "glass",
  //                             "sections": [
  //                                 {
  //                                     "id": "transom_section_2_nested_section_0",
  //                                     "material": "glass",
  //                                     "opacity": 0.6,
  //                                     "reflectivity": 0.5,
  //                                     "color": "#B0E0E6"
  //                                 },
  //                                 {
  //                                     "id": "transom_section_2_nested_section_1",
  //                                     "material": "glass",
  //                                     "opacity": 0.6,
  //                                     "reflectivity": 0.5,
  //                                     "color": "#B0E0E6"
  //                                 }
  //                             ]
  //                         }
  //                     ]
  //                 },
  //                 {
  //                     "id": "transom_section_2",
  //                     "material": "glass",
  //                     "opacity": 0.6,
  //                     "reflectivity": 0.5,
  //                     "color": "#B0E0E6",
  //                     "splits": [
  //                         {
  //                             "id": "transom_0_1_split",
  //                             "type": "transom",
  //                             "orientation": "vertical",//vertical, horizontal
  //                             "ratios": [
  //                                 0.5,
  //                                 0.5
  //                             ],
  //                             "material": "glass",
  //                             "sections": [
  //                                 {
  //                                     "id": "transom_section_2_nested_section_0",
  //                                     "material": "glass",
  //                                     "opacity": 0.6,
  //                                     "reflectivity": 0.5,
  //                                     "color": "#B0E0E6"
  //                                 },
  //                                 {
  //                                     "id": "transom_section_2_nested_section_1",
  //                                     "material": "glass",
  //                                     "opacity": 0.6,
  //                                     "reflectivity": 0.5,
  //                                     "color": "#B0E0E6"
  //                                 }
  //                             ]
  //                         }
  //                     ]
  //                 }
  //             ]
  //         }
  //     ]
  // };








  // setTimeout(() => {    
  // const updatedTransomData = updateTransomData(transomData, partialHeightRatios, partialWidthRatios);
  // transomData = updatedTransomData;
  // }, 100);


  // const { height: heightSplit2, width: widthSplit2 } = getSplitsFromTransomData(transomData.splits[0]);
  // console.log("Aadi transom Height Split:", heightSplit2 , transomData);
  // console.log("Aadi transom Width Split:", widthSplit2);


  // its static values
  const heightSplit = [0.3, 0.5, 0.2];
  const widthSplit = [0.2, 0.8];
  // partialHeightRatios, partialWidthRatios
  function getNewRatio(partialWidth, frameWidth, offsetL, offsetR) {
    let newWidth = frameWidth - offsetL - offsetR;
    const partialWidthArray = partialWidth.map(ratio => ratio * frameWidth);
    partialWidthArray[0] -= offsetL;
    partialWidthArray[partialWidthArray.length - 1] -= offsetR;
    const newPartialWidths = partialWidthArray.map(ratio => ratio / newWidth);
    return newPartialWidths;
  }
  if (!isTranomTriggered) {
    const newRatios = getNewRatio(partialWidthRatios, frameWidth, sideThickness.left, sideThickness.right);
    const newHeightRatios = getNewRatio(partialHeightRatios, frameHeight, sideThickness.top, sideThickness.bottom);
    // const newRatioss = getNewRatio(partialWidthRatios, frameWidth, 0, 0);
    // const newHeightRatios = getNewRatio(partialHeightRatios, frameHeight, sideThickness.top, sideThickness.bottom);
    // updateTransomCount(transomData.splits[0], newRatioss, "vertical");
    // updateTransomCount(transomData.splits[0], newHeightRatios, "horizontal");
    let tarRatio = transomData.splits[0].orientation == "vertical" ? newRatios : newHeightRatios;
    // updateTransomCount(transomData.splits[0], tarRatio, transomData.splits[0].orientation);
    updateTransomCount(transomData.splits[0], newRatios, newHeightRatios, transomData.splits[0].orientation);
    // updateTransomCount(transomData.splits[0], newRatios, "vertical");
    // updateTransomCount(transomData.splits[0], newHeightRatios, "horizontal");
    updateTransomData(transomData, newHeightRatios, newRatios);
  }
  // transomData = updatedTransomData;

  transomData?.splits?.forEach(async (split, index) => {//its working fine *********    

    if (!split || !split.sections || split.sections.length < 2 || !split.orientation || !split.ratios || split.ratios.length < 2) {
      console.error(`Error: Invalid split at index ${index}. Missing required properties.`);
      return; // Skip processing this split if it's invalid
    }

    let frameTop = parentGroup.getObjectByName("FrameTop");
    const topBoundingBox = new THREE.Box3().setFromObject(frameTop);
    let frameBottom = parentGroup.getObjectByName("FrameBottom");
    const bottomBoundingBox = new THREE.Box3().setFromObject(frameBottom);
    let frameLeft = parentGroup.getObjectByName("FrameLeft");
    const leftBoundingBox = new THREE.Box3().setFromObject(frameLeft);
    let frameRight = parentGroup.getObjectByName("FrameRight");
    const rightBoundingBox = new THREE.Box3().setFromObject(frameRight);


    processSplit(
      split,
      group,
      leftBoundingBox.max.x,
      topBoundingBox.min.y,
      windowWidth - (sideThickness.left + sideThickness.right), // Pass total width
      windowHeight - (sideThickness.top + sideThickness.bottom),
      transomFrameHeight,
      transomFrameDepth,
      materials,
      colorVal, "", framePathCollection, 0, true
    );
    // const { height: heightSplit2, width: widthSplit2 } = getSplitsFromTransomData(split);
    // console.log("Aadi transom Height Split:", heightSplit2);
    // console.log("Aadi transom Width Split:", widthSplit2);



    // console.clear();


    // Usage example
    // let hSplit= [], vSplit = [];
    // const splitValues = await getSplitValue(group , parentGroup);
    // console.log(splitValues , "827");
    // // storeTransomPartialDimensions(splitValues)


    // console.log("Height Splits :::: ", splitValues.heightSplit);
    // console.log("Width Splits: :::: ", splitValues.widthSplit);

  })


  return group;
}
// its working for without nested
// export function updateTransomData__(transomData, heightSplit, widthSplit) {
//   function updateSplits(splits, orientation, newRatios) {
//     splits.ratios[0] = newRatios[0];
//     splits.ratios[1] = 1 - newRatios[0];
//     newRatios = alterRatios(newRatios);
//     let widthSplit1, heightSplit1;
//     if (orientation === 'horizontal') heightSplit1 = newRatios;
//     if (orientation === 'vertical') widthSplit1 = newRatios;
//     const split1 = splits.sections[0].splits;
//     const split2 = splits.sections[1].splits;
//     if (split1) {
//       if (split1[0].orientation == "horizontal") updateSplits(split1[0], "horizontal", heightSplit1 || heightSplit);
//       if (split1[0].orientation == "vertical") updateSplits(split1[0], "vertical", widthSplit1 || widthSplit);
//     }
//     if (split2) {
//       if (split2[0].orientation == "horizontal") updateSplits(split2[0], "horizontal", heightSplit1 || heightSplit1);
//       if (split2[0].orientation == "vertical") updateSplits(split2[0], "vertical", widthSplit1 || widthSplit);
//     }
//   }
//   if (transomData.splits[0].orientation == "horizontal") updateSplits(transomData.splits[0], "horizontal", heightSplit);
//   if (transomData.splits[0].orientation == "vertical") updateSplits(transomData.splits[0], "vertical", widthSplit);
//   return transomData;
// }

//****** its working for vertical only */
export function updateTransomData_(transomData, heightSplit, widthSplit) {

  let newWidthSplit = widthSplit;
  let newChildSplit = widthSplit;
  function updateSplits(splits, orientation, newRatios) {
    splits.ratios[0] = newRatios[0];
    if (splits.verticalCount) {
      let n = splits.verticalCount[0];
      let totalSum = newWidthSplit?.reduce((acc, num) => acc + num, 0);
      let firstVal = newRatios.slice(0, n)?.reduce((acc, num) => acc + num, 0);
      splits.ratios[0] = firstVal / totalSum;
      newWidthSplit = widthSplit.slice(n);
    } else {
      let totalSum = newChildSplit[0] + newChildSplit[1];
      splits.ratios[0] = newChildSplit[0] / totalSum;
      splits.ratios[1] = newChildSplit[1] / totalSum;
      newChildSplit = newChildSplit.slice(2);
    }

    splits.ratios[1] = 1 - splits.ratios[0];
    newRatios = alterRatios(newRatios, splits.ratios[0]);
    let widthSplit1, heightSplit1;
    if (orientation === 'horizontal') heightSplit1 = newRatios;
    if (orientation === 'vertical') widthSplit1 = newRatios;
    const split1 = splits.sections[0].splits;
    const split2 = splits.sections[1].splits;
    if (split1) {
      if (split1[0].orientation == "horizontal") updateSplits(split1[0], "horizontal", heightSplit1 || heightSplit);
      if (split1[0].orientation == "vertical") updateSplits(split1[0], "vertical", widthSplit1 || widthSplit);
    }
    if (split2) {
      if (split2[0].orientation == "horizontal") updateSplits(split2[0], "horizontal", heightSplit1 || heightSplit1);
      if (split2[0].orientation == "vertical") updateSplits(split2[0], "vertical", widthSplit1 || widthSplit);
    }
  }
  if (transomData.splits[0].orientation == "horizontal") updateSplits(transomData.splits[0], "horizontal", heightSplit);
  if (transomData.splits[0].orientation == "vertical") updateSplits(transomData.splits[0], "vertical", widthSplit);
  return transomData;
}

export function updateTransomData__(transomData, heightSplit, widthSplit) {

  let newWidthSplit = widthSplit;
  let newChildSplit = widthSplit;
  let newHeightSplit = heightSplit;
  let newHeightChildSplit = heightSplit;
  function updateSplits(splits, orientation, newRatios) {
    // console.log("splits :::: ", splits);
    splits.ratios[0] = newRatios[0] || 0.5;
    if (orientation == "vertical") {
      if (splits.verticalCount) {
        let n = splits.verticalCount[0];
        let totalSum = newWidthSplit?.reduce((acc, num) => acc + num, 0);
        let firstVal = newRatios.slice(0, n)?.reduce((acc, num) => acc + num, 0);
        splits.ratios[0] = firstVal / totalSum;
        // newWidthSplit = widthSplit.slice(n);
      } else {
        let totalSum = newChildSplit[0] + newChildSplit[1];
        splits.ratios[0] = newChildSplit[0] / totalSum || 0.5;
        splits.ratios[1] = newChildSplit[1] / totalSum || 0.5;
        newChildSplit = newChildSplit.slice(2);
      }
    } else {
      if (splits.horizontalCount) {
        let n = splits.horizontalCount[0];
        let totalSum = newHeightSplit?.reduce((acc, num) => acc + num, 0);
        let firstVal = newRatios.slice(0, n)?.reduce((acc, num) => acc + num, 0);
        splits.ratios[0] = firstVal / totalSum;
        newHeightSplit = heightSplit.slice(n);
      } else {
        let totalSum = newHeightChildSplit[0] + newHeightChildSplit[1];
        splits.ratios[0] = newHeightChildSplit[0] / totalSum;
        splits.ratios[1] = newHeightChildSplit[1] / totalSum;
        newHeightChildSplit = newHeightChildSplit.slice(2);
      }
    }

    // console.log("splits-- :::: ", splits);
    splits.ratios[1] = 1 - splits.ratios[0];
    newRatios = alterRatios(newRatios, splits.ratios[0]);
    let widthSplit1, heightSplit1;
    if (orientation === 'horizontal') heightSplit1 = newRatios;
    if (orientation === 'vertical') widthSplit1 = newRatios;
    const split1 = splits.sections[0].splits;
    const split2 = splits.sections[1].splits;
    if (split1) {
      if (split1[0].orientation == "horizontal") updateSplits(split1[0], "horizontal", heightSplit1 || heightSplit);
      if (split1[0].orientation == "vertical") updateSplits(split1[0], "vertical", widthSplit);
    }
    if (split2) {
      if (split2[0].orientation == "horizontal") updateSplits(split2[0], "horizontal", heightSplit1 || heightSplit1);
      if (split2[0].orientation == "vertical") updateSplits(split2[0], "vertical", widthSplit);
    }
  }
  if (transomData.splits[0].orientation == "horizontal") updateSplits(transomData.splits[0], "horizontal", heightSplit);
  if (transomData.splits[0].orientation == "vertical") updateSplits(transomData.splits[0], "vertical", widthSplit);


  return transomData;
}
export function updateTransomData___(transomData, heightSplit, widthSplit) {

  // Clone splits for dynamic tracking
  let remainingWidthSplit = [...widthSplit];
  let remainingHeightSplit = [...heightSplit];

  function updateSplits(splits, orientation, splitRatios) {
    if (!splits || splitRatios.length === 0) return;

    // Update current split ratios
    splits.ratios[0] = splitRatios[0] || 0.5;
    splits.ratios[1] = 1 - splits.ratios[0];

    let localWidthSplit = [...remainingWidthSplit];
    let localHeightSplit = [...remainingHeightSplit];

    // Handle vertical or horizontal split propagation
    if (orientation === "vertical") {
      if (splits.verticalCount) {
        const n = splits.verticalCount[0];
        const total = localWidthSplit.slice(0, n)?.reduce((a, b) => a + b, 0);
        splits.ratios[0] = total;
        splits.ratios[1] = 1 - total;
        remainingWidthSplit = remainingWidthSplit.slice(n);
      }
    } else if (orientation === "horizontal") {
      if (splits.horizontalCount) {
        const n = splits.horizontalCount[0];
        const total = localHeightSplit.slice(0, n)?.reduce((a, b) => a + b, 0);
        splits.ratios[0] = total;
        splits.ratios[1] = 1 - total;
        remainingHeightSplit = remainingHeightSplit.slice(n);
      }
    }

    // Recursively update nested splits
    splits.sections.forEach((section) => {
      if (section.splits) {
        section.splits.forEach((childSplit) => {
          const childOrientation = childSplit.orientation;
          if (childOrientation === "vertical") {
            updateSplits(childSplit, "vertical", remainingWidthSplit);
          } else if (childOrientation === "horizontal") {
            updateSplits(childSplit, "horizontal", remainingHeightSplit);
          }
        });
      }
    });
  }

  // Start processing at the root level
  if (transomData.orientation === "vertical") {
    updateSplits(transomData, "vertical", widthSplit);
  } else if (transomData.orientation === "horizontal") {
    updateSplits(transomData, "horizontal", heightSplit);
  }

  return transomData;
}
export function updateTransomData____(transomData, widthSplit, heightSplit) {


  let remainingWidthSplit = [...widthSplit];
  let remainingHeightSplit = [...heightSplit];

  // Helper function to update splits recursively
  function updateSplits(splits, orientation) {
    if (!splits) return;


    // Update the current split's ratios based on orientation
    if (orientation === "vertical") {
      if (splits.verticalCount) {
        const n = splits.verticalCount[0]; // Number of vertical splits to consume
        const total = remainingWidthSplit.slice(0, n)?.reduce((a, b) => a + b, 0);

        // Update ratios
        splits.ratios[0] = total;
        splits.ratios[1] = 1 - total;

        // Consume the split ratios
        remainingWidthSplit = remainingWidthSplit.slice(n);
      }
    } else if (orientation === "horizontal") {
      if (splits.horizontalCount) {
        const n = splits.horizontalCount[0]; // Number of horizontal splits to consume
        const total = remainingHeightSplit.slice(0, n)?.reduce((a, b) => a + b, 0);

        // Update ratios
        splits.ratios[0] = total;
        splits.ratios[1] = 1 - total;

        // Consume the split ratios
        remainingHeightSplit = remainingHeightSplit.slice(n);
      }
    }


    // Process child sections recursively
    if (splits.sections && splits.sections.length > 0) {
      splits.sections.forEach((section) => {
        if (section.splits && section.splits.length > 0) {
          section.splits.forEach((childSplit) => {
            updateSplits(childSplit, childSplit.orientation);
          });
        }
      });
    }
  }

  // Process the top-level splits
  if (transomData.splits && transomData.splits.length > 0) {
    transomData.splits.forEach((split) => {
      // updateSplits(split, split.orientation);
    });
  }

  return transomData;
}
export function updateTransomData(transomData, heightSplit, widthSplit) {
  // let newWidthSplit = widthSplit;
  // let newChildSplit = widthSplit;
  // let newHeightSplit = heightSplit;
  // let newHeightChildSplit = heightSplit;
  function updateSplits(splits, orientation, newRatios) {
    // console.log("splits :::: ", splits);
    // let firstSum = splits?.transomArray[0]?.reduce((acc, num) => acc + num, 0);
    // let secondSum = splits?.transomArray[1]?.reduce((acc, num) => acc + num, 0);
    let firstSum = Array.isArray(splits.transomArray[0]) ? splits.transomArray[0].reduce((acc, num) => acc + num, 0) : 0;
    let secondSum = Array.isArray(splits?.transomArray?.[1]) ? splits.transomArray[1].reduce((acc, num) => acc + num, 0) : 0;

    let totalSum = firstSum + secondSum;
    splits.ratios[0] = firstSum / totalSum && firstSum / totalSum != 1 ? firstSum / totalSum : 0.5;
    // if(orientation == "vertical"){
    //   if(splits.verticalCount){
    //     let n = splits.verticalCount[0];
    //     let totalSum = newWidthSplit.reduce((acc, num) => acc + num, 0);
    //     let firstVal = newRatios.slice(0, n).reduce((acc, num) => acc + num, 0);
    //     splits.ratios[0] = firstVal / totalSum;
    //     // newWidthSplit = widthSplit.slice(n);
    //   }else{
    //     let totalSum = newChildSplit[0] + newChildSplit[1];
    //     splits.ratios[0] = newChildSplit[0] / totalSum || 0.5;
    //     splits.ratios[1] = newChildSplit[1] / totalSum || 0.5;
    //     newChildSplit = newChildSplit.slice(2);
    //   }
    // }else{
    //   if(splits.horizontalCount){
    //     let n = splits.horizontalCount[0];
    //     let totalSum = newHeightSplit.reduce((acc, num) => acc + num, 0);
    //     let firstVal = newRatios.slice(0, n).reduce((acc, num) => acc + num, 0);
    //     splits.ratios[0] = firstVal / totalSum;
    //     newHeightSplit = heightSplit.slice(n);
    //   }else{
    //     let totalSum = newHeightChildSplit[0] + newHeightChildSplit[1];
    //     splits.ratios[0] = newHeightChildSplit[0] / totalSum;
    //     splits.ratios[1] = newHeightChildSplit[1] / totalSum;
    //     newHeightChildSplit = newHeightChildSplit.slice(2);
    //   }
    // }

    // console.log("splits-- :::: ", splits);
    splits.ratios[1] = 1 - splits.ratios[0];
    // newRatios = alterRatios(newRatios, splits.ratios[0]);
    // let widthSplit1, heightSplit1;
    // if (orientation === 'horizontal') heightSplit1 = newRatios;
    // if (orientation === 'vertical') widthSplit1 = newRatios;
    const split1 = splits.sections[0].splits;
    const split2 = splits?.sections[1]?.splits;
    if (split1) {
      if (split1[0].orientation == "horizontal") updateSplits(split1[0], "horizontal", heightSplit);
      if (split1[0].orientation == "vertical") updateSplits(split1[0], "vertical", widthSplit);
    }
    if (split2) {
      if (split2[0].orientation == "horizontal") updateSplits(split2[0], "horizontal", heightSplit);
      if (split2[0].orientation == "vertical") updateSplits(split2[0], "vertical", widthSplit);
    }
  }
  if (transomData.splits[0].orientation == "horizontal") updateSplits(transomData.splits[0], "horizontal", heightSplit);
  if (transomData.splits[0].orientation == "vertical") updateSplits(transomData.splits[0], "vertical", widthSplit);


  return transomData;
}
// Utility function to adjust ratios dynamically (optional, if needed)
function alterRatios(ratios, baseRatio) {
  return ratios.map((ratio) => ratio * baseRatio);
}

function alterRatios_(newRatios, firstNumber) {
  // const firstNumber = newRatios[0];
  const alteredRatios = newRatios.slice(1).map(currentNumber => {
    return currentNumber / (1 - firstNumber);
  });
  return alteredRatios;
}