import React, { useEffect, useState } from "react";
import { Form, Image } from "react-bootstrap";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import { MdOutlineKeyboardArrowDown, MdOutlineSell } from "react-icons/md";
import { MenuProps, noSelectOptions } from "../../../../../../utility/data";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import NumberInput from "../../../../../../components/NumberInput";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import DropZone from "../../../../../../components/DropZone";
import { getHardwareDefaultSideService } from "../../../../../../services/commonServices";
import { getRightMenuList } from "../../../../../../services/hardwareStylesService";
import Editprices from "../../../../../../components/Editprices";
import UsedInProducts from "../../../../../../components/UsedInProducts";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../../../../../utility/commonHelper";
import { checkValidProductCodeService } from "../../../../../../services/productProfileServiceV";
import useDebounce from "../../../../../../components/customHooks/customHook";
const StylesLeft = ({
  styleMasterId,
  accessToken,
  selectItemDetails,
  updateItemDetailsServices,
  handleFormReset,
  modelOptions,
  colourCollectionOptions,
  usedProducts,
  removehardwareInUsedService,
  hardwareListing,
  selectedHardwareType,
  setSelectedHardwareType,
  model,
  setModel,
}) => {
  var acceptFileType = {
    "image/png": [".jpg", ".jpeg", ".png", ".svg", ".gif"],
  };

  const priceTypeOptions = [
    {
      id: 1,
      name: "Per Meter",
    },
    {
      id: 2,
      name: "Quantity",
    },
  ];

  const {
    timestamp,
    selected,
    setSelected,
    handleEdit,
    handleDescEdit,
    setDescUpdate,
    handleFileUpdate,
    selectedRightItem,
  } = useRightContext();

  const [defaultSideOptions, setDefaultSideOptions] = useState([]);
  const [escutcheonStyleOptions, setEscutcheonStyleOptions] = useState([]);
  const [internalStyleOptions, setInternalStyleOptions] = useState([]);

  const [escutcheonStyle, setEscutcheonStyle] = useState("");

  const [name, setName] = useState("");
  const [itemId, setItemId] = useState("");
  // const [description, setDescription] = useState("");
  const [length, setLength] = useState("");

  const [colorCollectionId, setColorCollectionId] = useState("");

  const [defaultSideId, setDefaultSideId] = useState("");
  const [internalStyleId, setInternalStyleId] = useState("");

  const [productCode, setProductCode] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [filePreview, setFilePreview] = useState("");

  const [nameError, setNameError] = useState(false);
  // const [descriptionError, setDescriptionError] = useState(false);
  const [selectedPriceType, setSelectedPriceType] = useState("");
  const [isValidProductCode, setIsValidProductCode] = useState(true);

  const navigate = useNavigate();

  const debouncedProductCode = useDebounce(productCode, 500)

  useEffect(() => {
    if (selectItemDetails?.styleMasterId) {
      getDefaultSideOptions(selectItemDetails?.styleMasterId);
    }
  }, [selectItemDetails?.styleMasterId]);

  useEffect(() => {
    if (selectItemDetails?.styleMasterId == 4) {
      getIiternalStyleOptions();
    }

    if (
      selectItemDetails?.styleMasterId == 1 ||
      selectItemDetails?.styleMasterId == 2 ||
      selectItemDetails?.styleMasterId == 5
    ) {
      getEscutcheonstyleOptions();
    }

    setName(selectItemDetails?.name || "");
    setItemId(selectItemDetails?.id || "");
    setSelected(selectItemDetails?.name || "");
    // setDescription(selectItemDetails?.description || "");

    setModel(selectItemDetails?.model || modelOptions[0]?.id);

    setColorCollectionId(
      selectItemDetails?.colourCollection || colourCollectionOptions[0]?.id
    );
    setLength(selectItemDetails?.length || 0);

    setEscutcheonStyle(selectItemDetails?.escutcheonStyle || "");
    setDefaultSideId(selectItemDetails?.defaultSide || "");
    setInternalStyleId(selectItemDetails?.internalStyle || "");

    setProductCode(selectItemDetails?.productCode || "");
    setUploadedFile("");
    setFilePreview(selectItemDetails?.imagePath || "");
    setSelectedPriceType(selectItemDetails?.priceType || 0);
    setSelectedHardwareType(selectItemDetails?.styleMasterId || "");

    setNameError(false);
    // setDescriptionError(false);
  }, [selectItemDetails]);

  // get default side options from master API
  const getDefaultSideOptions = async (fetchMasterId) => {
    const res = await getHardwareDefaultSideService(accessToken, fetchMasterId);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            setDefaultSideOptions(res?.data?.entity);
          }
        }
      }
    }
  };

  // get Escutcheon style options from master API
  const getEscutcheonstyleOptions = async () => {
    const res = await getRightMenuList(accessToken, 6);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            setEscutcheonStyleOptions(res?.data?.entity);
          } else {
            setEscutcheonStyleOptions(noSelectOptions);
          }
        }
      }
    } else {
      handleError(res, false, navigate);
    }
  };

  // get internal style options from master API
  const getIiternalStyleOptions = async () => {
    const res = await getRightMenuList(accessToken, 4);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            setInternalStyleOptions(res?.data?.entity);
          } else {
            setInternalStyleOptions(noSelectOptions);
          }
        }
      }
    } else {
      handleError(res, false, navigate);
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;

    if (e.target.value?.length <= 50) {
      setName(value);
      handleEdit(value);
      setSelected(value);
    }

    if (value.length > 50) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  // const handleDescriptionChange = (e) => {
  //   const value = e.target.value;

  //   if (e.target.value?.length <= 80) {
  //     setDescription(value);
  //     handleDescEdit(value);
  //     setDescUpdate(value);
  //   }

  //   if (value.length > 80) {
  //     setDescriptionError(true);
  //   } else {
  //     setDescriptionError(false);
  //   }
  // };

  const handleModelChange = (e) => {
    const value = e.target.value;
    setModel(value);
  };

  const handleEscutcheonStyleChange = (e) => {
    const value = e.target.value;
    setEscutcheonStyle(value);
  };

  const handleColorCollection = (e) => {
    const value = e.target.value;
    setColorCollectionId(value);

    // const getcolorCollectionNames = [value].map((item) => {
    //     const proj = colourCollectionOptions?.find((p) => p.id == item)
    //     return proj ? proj.text : null
    // })?.filter(id => id !== null);

    // setColorCollection(getcolorCollectionNames[0])
  };

  const handleDefaultSide = (e) => {
    const value = e.target.value;

    setDefaultSideId(value);
  };

  const handleInternalStyle = (e) => {
    const value = e.target.value;

    setInternalStyleId(value);
  };

  useEffect(() => {
    if (filePreview) {
      handleFileUpdate(filePreview);
    }
  }, [filePreview]);

  const handleFormSubmit = () => {
    var formDataValues = {
      id: selectItemDetails?.id || "",
      model: model || 1,
      name: name || "",
      description: productCode || "",
      colourCollection: colorCollectionId || 1,
      defaultSide: defaultSideId || "",
      escutcheonStyle: escutcheonStyle || "",
      internalStyle: internalStyleId || "",
      length: length || 0,

      productCode: productCode || "",
      filePath: uploadedFile || "",
      styleMasterId: selectedHardwareType,
      priceType: selectedPriceType || 2,
    };

    updateItemDetailsServices(formDataValues);
  };

  const handleHardwareChange = (e) => {
    const value = e.target.value;
    setSelectedHardwareType(value);
  };

  useEffect(() => {
    if (debouncedProductCode && timestamp) {
      (async () => {
        const res = await checkValidProductCodeService(accessToken, debouncedProductCode, timestamp, 2);
        if (res && res?.status === 200) {
          setIsValidProductCode(res?.data?.isValidProductCode);
        }
      })();
    }
  }, [debouncedProductCode]);

  const editNavData = {
    url: `/pricing/hardware`,
    stateValue:
      selectItemDetails?.styleMasterId == -1
        ? 1
        : selectItemDetails?.styleMasterId,
  };

  // console.log("Style", selectItemDetails?.styleMasterId);

  return (
    <div className="d-flex flex-column h-100">
      {timestamp ? (
        <>
          <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
            {selected && (
              <h5 className="mb-0 text-wrap text-break me-4 main_title">
                {`${selected} (${productCode})`}
              </h5>
            )}

            <Editprices data={editNavData} hasState={true} />
          </div>

          <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
            <div className="inside_scroll has_btns has_head">
              <Row>

                <Col lg="6">
                  <FormGroup className="form-group">
                    <Label for="selectedHardwareType">Hardware Type</Label>

                    <Select
                      labelId="selectedHardwareType"
                      id="selectedHardwareType"
                      value={selectedHardwareType}
                      label="selectedHardwareType"
                      onChange={(e) => {
                        handleHardwareChange(e);
                      }}
                      input={
                        <OutlinedInput
                          className="w-100"
                          name="selectedHardwareType"
                        />
                      }
                      MenuProps={MenuProps}
                      IconComponent={() => (
                        <MdOutlineKeyboardArrowDown
                          size={20}
                          className="me-1 MuiSvgIcon-root MuiSelect-icon"
                        />
                      )}
                    >
                      {hardwareListing &&
                        hardwareListing.map((o, index) => (
                          <MenuItem key={index} value={o?.id}>
                            {o?.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormGroup>
                </Col>

                {selectItemDetails?.styleMasterId !== 17 && (
                  <Col lg="6">
                    <FormGroup className="form-group modal_select">
                      <Label for="modalSelect">Model</Label>

                      <Select
                        labelId="modalSelect"
                        id="modalSelect"
                        value={model || selectItemDetails?.model}
                        disabled={!selectedRightItem?.isSelf}
                        label="modalSelect"
                        onChange={(e) => {
                          handleModelChange(e);
                        }}
                        input={
                          <OutlinedInput className="w-100" name="modalSelect" />
                        }
                        MenuProps={MenuProps}
                        IconComponent={() => (
                          <MdOutlineKeyboardArrowDown
                            size={20}
                            className="me-1 MuiSvgIcon-root MuiSelect-icon"
                          />
                        )}
                      >
                        {modelOptions &&
                          modelOptions?.map((o, index) => (
                            <MenuItem key={index} value={o?.id}>
                              <div className="d-flex align-items-center dropdown_with_icon">
                                <span className="me-2 small_svg_set modal_select_hide image_icon">
                                  {o.imageUrl && <Image src={o.imageUrl} />}
                                </span>

                                <div className="d-flex flex-column w-100">
                                  <span className="text-small lh-1 modal_select_hide">
                                    {o.subTitle}
                                  </span>
                                  <span className="lh-1">{o?.name}</span>
                                </div>
                              </div>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormGroup>
                  </Col>
                )}

                <Col lg="6">
                  <FormGroup className="form-group">
                    <Label for="name">Name</Label>
                    <Input
                      disabled={!selectedRightItem?.isSelf}
                      className="mb-1"
                      name="name"
                      value={name}
                      onChange={(e) => handleNameChange(e)}
                    />

                    {nameError && timestamp && itemId && (
                      <div className="mt-1 text-muted text-small">
                        Max 50 characters are allowed
                      </div>
                    )}

                    {!name && timestamp && itemId && (
                      <div className="mt-1 text-danger text-small">
                        Name is required
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-group">
                    <Label for="productCode">Product code</Label>
                    <Input
                      disabled={!selectedRightItem?.isSelf}
                      className="mb-1"
                      name="productCode"
                      value={productCode}
                      onChange={(e) => {
                        const value = e.target.value;

                        // Allow only numbers and restrict to 6 digits
                        if (/^\d{0,6}$/.test(value)) {
                          setProductCode(value);
                          handleDescEdit(value);
                        }
                      }}
                    />

                    {!productCode && timestamp && itemId && (
                      <div className="mt-1 text-danger text-small">
                        Product code is required
                      </div>
                    )}
                    {productCode?.length > 6 && timestamp && itemId && (
                      <div className="mt-1 text-danger text-small">
                        Product Code can be of max 6 digits.
                      </div>
                    )}
                    
                    {productCode && !isValidProductCode && (
                      <div className="mt-1 text-danger text-small">
                        Product code is not valid.
                      </div>
                    )}
                  </FormGroup>
                </Col>

                {/* <Col lg="6">
                  <FormGroup className="form-group">
                    <Label for="description">Description</Label>
                    <Input
                      disabled={!selectedRightItem?.isSelf}
                      className="mb-1"
                      name="description"
                      value={description}
                      onChange={(e) => handleDescriptionChange(e)}
                    />

                    {descriptionError && timestamp && itemId && (
                      <div className="mt-1 text-muted text-small">
                        Max 80 characters are allowed
                      </div>
                    )}
                  </FormGroup>
                </Col> */}

                {/* <Col lg="6">
                  <FormGroup className="form-group">
                    <Label for="productCode">Product code</Label>
                    <Input
                      disabled={!selectedRightItem?.isSelf}
                      className="mb-1"
                      name="productCode"
                      value={productCode}
                      onChange={(e) => setProductCode(e.target.value)}
                    />

                    {!productCode && timestamp && itemId && (
                      <div className="mt-1 text-danger text-small">
                        Product code is required
                      </div>
                    )}
                  </FormGroup>
                </Col> */}

                {selectItemDetails?.styleMasterId === 17 && (
                  <Col lg="6">
                    <FormGroup className="form-group">
                      <Label for="priceType">Price Type</Label>

                      <Select
                        labelId="priceType"
                        id="priceType"
                        value={selectedPriceType}
                        disabled={!selectedRightItem?.isSelf}
                        label="priceType"
                        onChange={(e) => {
                          setSelectedPriceType(e.target.value);
                        }}
                        input={
                          <OutlinedInput className="w-100" name="priceType" />
                        }
                        MenuProps={MenuProps}
                        IconComponent={() => (
                          <MdOutlineKeyboardArrowDown
                            size={20}
                            className="me-1 MuiSvgIcon-root MuiSelect-icon"
                          />
                        )}
                      >
                        {priceTypeOptions &&
                          priceTypeOptions.map((item, index) => (
                            <MenuItem key={index} value={item?.id}>
                              {item?.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {!selectedPriceType && (
                        <div className="mt-1 text-danger text-small">
                          Price type is required
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                )}

                {selectItemDetails?.styleMasterId == 12 && (
                  <Col lg="6">
                    <FormGroup className="form-group">
                      <Label for="length">Length</Label>

                      <NumberInput
                        name="length"
                        disabled={!selectedRightItem?.isSelf}
                        value={length}
                        setValue={setLength}
                      />
                    </FormGroup>
                  </Col>
                )}

                {selectItemDetails?.styleMasterId !== 17 && (
                  <Col lg="6">
                    <FormGroup className="form-group">
                      <Label for="colorCollection">Colour collection</Label>

                      <Select
                        labelId="colorCollection"
                        id="colorCollection"
                        value={colorCollectionId}
                        disabled={!selectedRightItem?.isSelf}
                        label="colorCollection"
                        onChange={(e) => {
                          handleColorCollection(e);
                        }}
                        input={
                          <OutlinedInput
                            className="w-100"
                            name="colorCollection"
                          />
                        }
                        MenuProps={MenuProps}
                        IconComponent={() => (
                          <MdOutlineKeyboardArrowDown
                            size={20}
                            className="me-1 MuiSvgIcon-root MuiSelect-icon"
                          />
                        )}
                      >
                        {colourCollectionOptions &&
                          colourCollectionOptions.map((o, index) => (
                            <MenuItem key={index} value={o?.id}>
                              {o?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormGroup>
                  </Col>
                )}

                {(selectItemDetails?.styleMasterId == 1 ||
                  selectItemDetails?.styleMasterId == 2) && (
                    <Col lg="6">
                      {defaultSideOptions && defaultSideOptions.length > 0 && (
                        <FormGroup className="form-group">
                          <Label for="defaultSide">Default side</Label>

                          <Select
                            labelId="defaultSide"
                            id="defaultSide"
                            value={defaultSideId}
                            disabled={!selectedRightItem?.isSelf}
                            label="defaultSide"
                            onChange={(e) => {
                              handleDefaultSide(e);
                            }}
                            input={
                              <OutlinedInput
                                className="w-100"
                                name="defaultSide"
                              />
                            }
                            MenuProps={MenuProps}
                            IconComponent={() => (
                              <MdOutlineKeyboardArrowDown
                                size={20}
                                className="me-1 MuiSvgIcon-root MuiSelect-icon"
                              />
                            )}
                          >
                            {defaultSideOptions &&
                              defaultSideOptions.map((o, index) => (
                                <MenuItem key={index} value={o?.id}>
                                  {o?.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormGroup>
                      )}
                    </Col>
                  )}

                {(selectItemDetails?.styleMasterId == 1 ||
                  selectItemDetails?.styleMasterId == 2 ||
                  selectItemDetails?.styleMasterId == 5) && (
                    <Col lg="6">
                      <FormGroup className="form-group modal_select">
                        <Label for="escutcheonStyle">Escutcheon style</Label>

                        <Select
                          labelId="escutcheonStyle"
                          id="escutcheonStyle"
                          value={escutcheonStyle}
                          disabled={!selectedRightItem?.isSelf}
                          label="escutcheonStyle"
                          onChange={(e) => {
                            handleEscutcheonStyleChange(e);
                          }}
                          input={
                            <OutlinedInput
                              className="w-100"
                              name="escutcheonStyle"
                            />
                          }
                          MenuProps={MenuProps}
                          IconComponent={() => (
                            <MdOutlineKeyboardArrowDown
                              size={20}
                              className="me-1 MuiSvgIcon-root MuiSelect-icon"
                            />
                          )}
                        >
                          {escutcheonStyleOptions &&
                            escutcheonStyleOptions?.map((o, index) => (
                              <MenuItem key={index} value={o?.id}>
                                <div className="d-flex align-items-center dropdown_with_icon">
                                  <span className="me-2 small_svg_set modal_select_hide image_icon">
                                    {o.imageUrl && <Image src={o.imageUrl} />}
                                  </span>

                                  <div className="d-flex flex-column w-100">
                                    {/* <span className="text-small lh-1 modal_select_hide">
                                                            {o.sub}
                                                        </span> */}
                                    <span className="lh-1">{o?.name}</span>
                                  </div>
                                </div>
                              </MenuItem>
                            ))}
                        </Select>
                      </FormGroup>
                    </Col>
                  )}

                {selectItemDetails?.styleMasterId == 4 && (
                  <Col lg="6">
                    <FormGroup className="form-group">
                      <Label for="internalStyle">Internal style</Label>

                      <Select
                        labelId="internalStyle"
                        id="internalStyle"
                        value={internalStyleId}
                        disabled={!selectedRightItem?.isSelf}
                        label="internalStyle"
                        onChange={(e) => {
                          handleInternalStyle(e);
                        }}
                        input={
                          <OutlinedInput
                            className="w-100"
                            name="internalStyle"
                          />
                        }
                        MenuProps={MenuProps}
                        IconComponent={() => (
                          <MdOutlineKeyboardArrowDown
                            size={20}
                            className="me-1 MuiSvgIcon-root MuiSelect-icon"
                          />
                        )}
                      >
                        {internalStyleOptions.map((o, index) => (
                          <MenuItem key={index} value={o?.id}>
                            {o?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormGroup>
                  </Col>
                )}

                <Col lg="6">
                  <FormGroup
                    className={`form-group ${!selectedRightItem?.isSelf ? "not_allowed" : ""
                      }`}
                  >
                    <Label for="uploadFile">Upload image</Label>

                    <DropZone
                      acceptFileType={acceptFileType}
                      multiple={false}
                      uploadedFile={uploadedFile}
                      setUploadedFile={setUploadedFile}
                      filePreview={filePreview}
                      setFilePreview={setFilePreview}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {selectItemDetails?.styleMasterId !== 17 && (
                <div
                  className={`${!selectedRightItem?.isSelf ? "not_allowed" : ""
                    }`}
                >
                  <h4 className="main_title sub_title">Used in products</h4>
                  <UsedInProducts
                    data={usedProducts}
                    handleRemove={removehardwareInUsedService}
                  />
                </div>
              )}
            </div>

            {/* Start: space for buttons at the bottom */}
            {selectedRightItem?.isSelf && (
              <div className="d-flex gap-2 gap-xl-3 main_buttons">
                <Button
                  color="primary"
                  size="md"
                  className="px-4"
                  onClick={() => handleFormSubmit()}
                  disabled={
                    !timestamp ||
                    !name.trim() ||
                    !productCode ||
                    nameError ||
                    productCode?.length > 6 ||
                    !isValidProductCode
                  }
                >
                  Save Changes
                </Button>

                <Button
                  color="primary"
                  size="md"
                  outline
                  className="px-4"
                  disabled={!timestamp}
                  onClick={() => handleFormReset()}
                >
                  Reset
                </Button>
              </div>
            )}
            {/* End: space for buttons at the bottom */}
          </Form>
        </>
      ) : (
        <h5 className="my-4 text-center text-muted w-100">
          No data found
          {/* <br /> Please Change the Filters */}
        </h5>
      )}
    </div>
  );
};

export default StylesLeft;
