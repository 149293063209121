import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, FormGroup, Input, Label, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { useDesigner } from '../../context/designerContext';
import { BiLockOpenAlt, BiSolidCheckSquare, BiX } from 'react-icons/bi';
import { MdInfoOutline } from 'react-icons/md';
import { adjustWidthsForRounding } from '../../utility/threeJsHelpers/uiLinesHelper';
import { getAllOffSet } from './BlockWindow/createWindowFrame';
import { handleMultiSelect } from '../../utility/threeJsHelpers/general';
import { checkValidationForPositiveNumber } from '../../utility/helper';

function EditPartialWidths({ uiLinesRef, savedData, manufacturingToggle }) {
  const {
    lockRefPoints,
    setLockRefPoints,
    lockRefIndex,
    setLockRefIndex,
    setApplyPartialObj,
    setApplyPartialTrigger,
    setPartialIndex,
    setCustomModelData,
  } = useDesigner();

  const [popoverIndex, setPopoverIndex] = useState(null);
  const [partialDimension, setPartialDimension] = useState(0);
  const [validLength, setValidLength] = useState(0);
  const [partialWidths, setPartialWidths] = useState([]);
  const [minPartialDimention, setMinPartialDimention] = useState(1);
  const dimensionRef = useRef(null);

  const partialWidthRatios = savedData?.sash?.partialWidthRatios || [1];
  const allOffSetData = getAllOffSet();
  const frameWidth = (savedData?.dimensions?.width || 0) * 1000;

  useEffect(() => {
    let adjustedFrameWidth = frameWidth;
    if (allOffSetData) {
      adjustedFrameWidth -= allOffSetData?.wWidthOffset * 1000;
    }

    const newPartialWidths = adjustWidthsForRounding(adjustedFrameWidth, partialWidthRatios);
    setPartialWidths(newPartialWidths);
  }, [allOffSetData, frameWidth, partialWidthRatios]);

  useEffect(() => {
    if (partialWidths) {
      setCustomModelData((prevModelData) => ({
        ...prevModelData,
        sashSize: partialWidths,
      }));
    }
  }, [partialWidths])

  useEffect(() => {
    if (savedData && popoverIndex != null) {
      let minPartialValue = 0
      if (popoverIndex == 0) {
        minPartialValue = (savedData?.frame?.sides?.left?.dimensions?.height) * 1000
      } else if (popoverIndex == (partialWidths.length - 1)) {
        minPartialValue = (savedData?.frame?.sides?.right?.dimensions?.height) * 1000
      } else {
        minPartialValue = 1
      }

      setMinPartialDimention(minPartialValue)
    }
  }, [savedData, popoverIndex])

  useEffect(() => {
    if (popoverIndex != null) {
      if (dimensionRef?.current) {
        dimensionRef?.current?.focus()
        dimensionRef?.current?.select()
      }
    } else {
      if (dimensionRef.current) {
        dimensionRef.current = null
      }
    }
  }, [popoverIndex])

  const handleApply = () => {
    if (partialDimension > 0) {
      setPopoverIndex(null);
      setApplyPartialTrigger(true);
    }
  };

  const handleInputW = (value) => {
    setPartialDimension(value);

    if (value < minPartialDimention) return;

    setApplyPartialObj({
      index: popoverIndex,
      value,
      type: 'W',
    });
  };

  const handleLockIndex = (index) => {
    setLockRefIndex((prev) => {
      if (prev.includes(index)) return prev.filter((item) => item !== index);
      return [...prev, index];
    });
  };

  const handleRefClick = (item, index) => {
    if (partialWidthRatios.length > 0) {
      const lockedLength = lockRefIndex.reduce((sum, idx) => sum + partialWidths[idx], 0);
      const remainingLength =
        frameWidth - lockedLength - (partialWidthRatios.length - lockRefIndex.length - 1) * 50;

      setValidLength(remainingLength);

      setPartialDimension(parseInt(partialWidths[index]))
    }
  };

  const handleApplyOnEnter = (e) => {
    if (e.key === 'Enter' && partialDimension >= 1 && partialDimension <= validLength) {
      handleApply();
    }
  };

  return (
    <>
      {uiLinesRef?.current?.map((item, index) => {
        const isLocked = lockRefPoints.some((obj) => obj.index === index);

        return (
          <div id={`overlayLines${index}`} key={index} className="ref_btn_wrap ui_ref">
            <div
              className="btn_parent is_bottom"
              style={{
                position: 'absolute',
                left: item.overlayX,
                top: item.overlayY,
              }}
            >
              <div className="position-relative">
                {uiLinesRef?.current?.length > 2 && !item.name.includes('FrameLeft') && (
                  <Button
                    type="button"
                    color={isLocked ? 'outline-danger' : 'light'}
                    className="rounded position-absolute ref_btn lock_btn"
                    onClick={() => { handleMultiSelect(index, item, setLockRefPoints); handleLockIndex(index) }}
                  >
                    <BiLockOpenAlt size={12} />
                  </Button>
                )}

                <Button
                  type="button"
                  color="light"
                  className="rounded ref_btn position-relative type_2"
                  id={`linesButtons_h${index}`}
                  onClick={() => {
                    if (!manufacturingToggle) {
                      setApplyPartialTrigger(false);
                      handleRefClick(item, index);
                    }
                    if (manufacturingToggle) {
                      setPartialIndex(`P${index + 1}`);
                    }
                  }}
                >
                  <span>{partialWidths[index] || 0}</span>
                </Button>

                <UncontrolledPopover
                  placement="bottom"
                  target={`linesButtons_h${index}`}
                  trigger="legacy"
                  isOpen={popoverIndex === index}
                  toggle={() => {
                    !manufacturingToggle ?
                      setPopoverIndex(popoverIndex === index ? null : index) : setPopoverIndex(null)
                  }}
                  className="custom_shadow"
                >
                  <PopoverBody className="p-0 position-relative">
                    {(!lockRefIndex.includes(index) && (lockRefIndex?.length !== (uiLinesRef?.current?.length - 1))) ? (
                      <div className="p-2">
                        <Button
                          color="primary"
                          className="circle_cross box_right_top rounded"
                          onClick={() => setPopoverIndex(null)}
                        >
                          <BiX size={20} />
                        </Button>

                        <FormGroup className="mb-0">
                          <Label for="width" className="text-dark fs-6 fw-bold">
                            Width
                          </Label>

                          <div className="d-flex align-items-center gap-2">
                            <Input
                              id="partialDimension"
                              innerRef={dimensionRef}
                              type="number"
                              value={partialDimension}
                              onChange={(e) => handleInputW(e.target.value)}
                              onKeyDown={handleApplyOnEnter}
                              onInput={(e) => checkValidationForPositiveNumber(e)}
                              className={partialDimension ? 'border-primary' : 'border-danger'}
                            />
                            <Button
                              outline
                              color="primary"
                              onClick={handleApply}
                              disabled={(partialDimension > validLength) || partialDimension < 1 || (partialDimension < minPartialDimention)}
                            >
                              <BiSolidCheckSquare size={18} className="me-2" />
                              Apply
                            </Button>
                          </div>

                          {partialDimension > validLength && (
                            <p className="text-danger mb-0">
                              Max value should be {validLength}
                            </p>
                          )}

                          {(partialDimension < minPartialDimention) && (
                            <p className="text-danger mb-0">Min value should be {minPartialDimention}</p>
                          )}
                        </FormGroup>
                      </div>
                    ) : (
                      <div className='p-2'>
                        <h4 className='text-bark mb-2'>Oops!</h4>

                        <Alert
                          color={`${lockRefIndex.includes(index) ? 'warning' : 'warning'}`}
                          className="p-2 m-0"
                        >
                          <div className='d-flex gap-2'>
                            <span>
                              <MdInfoOutline size={18} />
                            </span>

                            {lockRefIndex.includes(index) ? (
                              <span>
                                This glazing is locked, you can't change dimension of locked items
                              </span>
                            ) : (
                              <span>
                                You'll need at least 2 unlocked glazing to change dimensions
                              </span>
                            )}
                          </div>
                        </Alert>
                      </div>
                    )}
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default EditPartialWidths;