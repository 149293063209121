import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavLink,
} from "reactstrap";
import { useRightContext } from "../../../../../context/rightMenuContext";
import { useMessage } from "../../../../../context/messageContext";
import {
  deleteRightItem,
  updateOrder,
} from "../../../../../services/productProfileServiceV";
import { Image } from "react-bootstrap";
import {
  copyItem,
  itemVisibility,
} from "../../../../../services/commonServices";
import { GoCircleSlash } from "react-icons/go";
import { BiPlus, BiTrashAlt } from "react-icons/bi";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdClose,
  MdContentCopy,
  MdOutlineSearch,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from "react-icons/md";
import { handleError } from "../../../../../utility/commonHelper";

const ProfileRight = ({
  profileId,
  accessToken,
  pageName,
  pageType,
  allRightListItems,
  setLoading,
  getAllProfileListData,
  addNewRightItemServices,
  selectItemDetails,
  usedProducts,
  selectedSystemCollection,
  searchProfile,
  setSearchProfile,
  searchedIndex,
  setSearchedIndex,
}) => {
  const [previouseSelected, setPreviouseSelected] = useState({
    prevId: 1,
    prevIndex: 1,
  });
  const [currentSelected, setCurrentSelected] = useState({
    currId: 1,
    currIndex: 1,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [unchangableList, setUnchangableList] = useState([]);
  const [tempActiveIndex, setTempActiveIndex] = useState(-1);

  const navigate = useNavigate();

  const {
    timestamp,
    selectedRightItem,
    fetchSelected,
    newItemList,
    setNewItemList,
    handleActiveIndexUp,
    handleActiveIndexDown,
    activeIndex,
    setActiveIndex,
    userDataContext,
  } = useRightContext();

  const { setMessage, setMessageType } = useMessage();

  useEffect(() => {
    // console.log(allRightListItems, "right menu list")

    const assignItemValue = allRightListItems?.map((item) => {
      let newImagePath = item.imagePath;
      if (item?.imagePath && item?.imagePath?.includes("svg")) {
        const svgDataUri = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
          item?.imagePath
        )}`;
        newImagePath = svgDataUri;
      }
      return {
        name: item.name,
        description: item.description,
        imageUrl: newImagePath,
        width: item.width,
        height: item.height,
        id: item.id,
        isSelf: item.isSelf,
        isEnabled: item.isEnabled,
        productCode: item?.productCode,
      };
    });

    setNewItemList(assignItemValue);
    setUnchangableList(assignItemValue);
  }, [allRightListItems]);

  useEffect(() => {
    if (activeIndex !== -1) {
      setTempActiveIndex(activeIndex);
    }
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndex(0);
  }, []);

  const handleItemClick = (currentIndex, id) => {
    // if (pageType === -1 && selectedSystemCollection?.id === -1) {
    //   getAllProfileListData("", "");
    // } else if (pageType !== -1 && selectedSystemCollection?.id === -1) {
    //   getAllProfileListData(pageType, "");
    // } else if (pageType === -1 && selectedSystemCollection?.id !== -1) {
    //   getAllProfileListData("", selectedSystemCollection?.id);
    // } else {
    //   getAllProfileListData(pageType, selectedSystemCollection?.id);
    // }
    if (searchProfile?.length !== 0) {
      setSearchedIndex(unchangableList?.findIndex((item) => item?.id == id));
    } else {
      setSearchedIndex(-1);
    }
    setCurrentSelected({ currId: id, currIndex: currentIndex + 1 });

    if (currentSelected) {
      setPreviouseSelected({
        prevId: currentSelected.currId,
        prevIndex: currentSelected.currIndex,
      });
    }

    fetchSelected(currentIndex, id);
  };

  // api to delete right side items
  const deleteRightItemServices = async () => {
    setIsDeleting(true);
    setLoading(true);

    const res = await deleteRightItem(accessToken, selectItemDetails?.id);

    if (res && res && res.status === 200) {
      setShowDeleteModal(false);

      fetchSelected(0, allRightListItems[0]?.id);

      if (res?.data?.statusCode === 200) {
        setSearchProfile("");
        setMessage(
          res?.data?.message
            ? res.data.message
            : "Record removed successfully !"
        );
        setMessageType("success");
        if (pageType === -1 && selectedSystemCollection?.id === -1) {
          getAllProfileListData("", "");
        } else if (pageType !== -1 && selectedSystemCollection?.id === -1) {
          getAllProfileListData(pageType, "");
        } else if (pageType === -1 && selectedSystemCollection?.id !== -1) {
          getAllProfileListData("", selectedSystemCollection?.id);
        } else {
          getAllProfileListData(pageType, selectedSystemCollection?.id);
        }
        setIsDeleting(false);
      } else {
        setIsDeleting(false);
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setIsDeleting(false);
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      setShowDeleteModal(false);
      if (pageType === -1) {
        getAllProfileListData("");
      } else {
        getAllProfileListData(pageType);
      }
      handleError(res, false, navigate);
    }
  };

  // api to update right side item
  const updateOrderServices = async (type, direction) => {
    setLoading(true);

    const res = await updateOrder(accessToken, timestamp, type, direction);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (pageType === -1 && selectedSystemCollection?.id === -1) {
          getAllProfileListData("", "");
        } else if (pageType !== -1 && selectedSystemCollection?.id === -1) {
          getAllProfileListData(pageType, "");
        } else if (pageType === -1 && selectedSystemCollection?.id !== -1) {
          getAllProfileListData("", selectedSystemCollection?.id);
        } else {
          getAllProfileListData(pageType, selectedSystemCollection?.id);
        }
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  const handleItemUp = () => {
    setSearchedIndex(-1);
    handleActiveIndexUp();
    updateOrderServices(selectItemDetails?.type, "UP");
  };

  const handleItemDown = () => {
    setSearchedIndex(-1);
    handleActiveIndexDown();
    updateOrderServices(selectItemDetails?.type, "DOWN");
  };

  const copyPaletteServices = async (id) => {
    setLoading(true);
    const res = await copyItem(accessToken, id, 7);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(res?.data?.message ? res.data.message : "Item copied");
        if (pageType === -1 && selectedSystemCollection?.id === -1) {
          getAllProfileListData("", "");
        } else if (pageType !== -1 && selectedSystemCollection?.id === -1) {
          getAllProfileListData(pageType, "");
        } else if (pageType === -1 && selectedSystemCollection?.id !== -1) {
          getAllProfileListData("", selectedSystemCollection?.id);
        } else {
          getAllProfileListData(pageType, selectedSystemCollection?.id);
        }
        setLoading(false);
        setSearchProfile("");
      } else {
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
        if (pageType === -1) {
          getAllProfileListData("");
        } else {
          getAllProfileListData(pageType);
        }
        setLoading(false);
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      if (pageType === -1) {
        getAllProfileListData("");
      } else {
        getAllProfileListData(pageType);
      }
      handleError(res, false, navigate);
    }
  };

  const handleVisibility = async (e, item) => {
    if (usedProducts && usedProducts?.length > 0) {
      setShowVisibilityModal(true);
    } else {
      setLoading(true);
      const res = await itemVisibility(
        accessToken,
        item?.id,
        7,
        !item?.isEnabled
      );

      if (res && res.status === 200) {
        if (res?.data?.statusCode === 200) {
          setMessageType("success");
          setMessage(
            res?.data?.message ? res.data.message : "Action Successful"
          );
          if (pageType === -1) {
            getAllProfileListData("");
          } else {
            getAllProfileListData(pageType);
          }
          setLoading(false);
        } else {
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
          if (pageType === -1) {
            getAllProfileListData("");
          } else {
            getAllProfileListData(pageType);
          }
          setLoading(false);
        }
      } else {
        setLoading(false);
        setMessage(res?.message ? res.message : "Something went wrong");
        setMessageType("error");
        if (pageType === -1) {
          getAllProfileListData("");
        } else {
          getAllProfileListData(pageType);
        }
        handleError(res, false, navigate);
      }
    }
  };

  const searchProfileFun = (setList, list, profile, setProfile) => {
    setProfile(profile); // Update the profile state
    const searchText = profile.toLowerCase();
    if (searchText.length > 0) {
      setActiveIndex(-1);
      const new_list = unchangableList.filter((obj) => {
        const nameMatch = obj.name.toLowerCase().includes(searchText);
        const codeMatch =
          typeof obj.productCode === "number" &&
          String(obj.productCode).includes(searchText);
        return nameMatch || codeMatch;
      });
      setList(new_list); // Update the filtered list
    } else {
      fetchSelected(0, unchangableList[0]?.id);
      setActiveIndex(0);
      setList(unchangableList); // Reset to the full list when input is empty
    }
  };

  return (
    <>
      <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="pb-3 px-3">
          <InputGroup className="border rounded">
            <Input
              type="text"
              placeholder="Search Profile"
              className="search-input border-0"
              value={searchProfile}
              onChange={(e) =>
                searchProfileFun(
                  setNewItemList,
                  newItemList,
                  e.target.value,
                  setSearchProfile
                )
              }
            />
            <div className="input-group-append">
              <span className="input-group-text bg-transparent border-0 px-2">
                <MdOutlineSearch size={16} />
              </span>
            </div>
          </InputGroup>
        </div>
        <div className="inside_scroll has_btns has_search">
          <div className="right_list">
            {newItemList && newItemList?.length > 0 ? (
              <>
                {newItemList?.map((item, index) => (
                  <div
                    key={index}
                    className={`position-relative d-flex align-items-center right_item_wrap has_delete has_copy ${
                      userDataContext?.role === "Supplier" && "count_3"
                    }`}
                  >
                    <Link
                      to="#"
                      className={`right_item w-100 ${
                        activeIndex === index && "active"
                      }`}
                      onClick={() => handleItemClick(index, item.id)}
                    >
                      <span className="me-2 image_icon">
                        {item.imageUrl && item?.height!==0 && item?.width!==0 ? (
                          <Image
                            src={item.imageUrl}
                            style={{ width: "55px", height: "55px" }}
                          />
                        ) : (
                          <GoCircleSlash size={55} color="#989898" />
                        )}
                      </span>
                      <div className="right_title">
                        <span className="text-truncate">{item.name}</span>
                        <span className="text-truncate d-block text-small">
                          {item.description}
                        </span>
                      </div>
                    </Link>
                    <span className="position-absolute action_btns">
                      {userDataContext &&
                        userDataContext?.role === "Supplier" && (
                          <Link
                            to="#"
                            onClick={(e) => {
                              fetchSelected(index, item.id);
                              handleVisibility(e, item);
                            }}
                          >
                            {item?.isEnabled ? (
                              <MdOutlineVisibilityOff
                                className="text-muted visibility_off"
                                size={20}
                              />
                            ) : (
                              <MdOutlineVisibility
                                className="text-muted visibility_on"
                                size={20}
                              />
                            )}
                          </Link>
                        )}

                      {item.isSelf && (
                        <>
                          <Link
                            to="#"
                            onClick={() => {
                              setShowDeleteModal(true);
                              fetchSelected(index, item.id);
                            }}
                          >
                            {" "}
                            <BiTrashAlt className="text-muted" size={20} />{" "}
                          </Link>
                          {timestamp === "" ||
                          selectedSystemCollection?.id !==
                            -1 ? //   selectedSystemCollection?.id !== -1) || // (pageType !== -1 && //   selectedSystemCollection?.id === -1) || // (pageType === -1 && //  ||
                          // (pageType === -1 &&
                          //   selectedSystemCollection?.id !== -1)
                          null : (
                            <Link
                              to="#"
                              onClick={() => copyPaletteServices(item?.id)}
                            >
                              {" "}
                              <MdContentCopy
                                className="text-muted"
                                size={18}
                              />{" "}
                            </Link>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                ))}
              </>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center p-5"
                style={{ height: "100%" }}
              >
                <span>
                  No data found for selected filter
                  {/* <br />
                  Please Change the filter */}
                </span>
              </div>
            )}
          </div>
        </div>

        {/* Start: space for buttons at the bottom */}
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            disabled={selectedSystemCollection?.id !== -1}
            //   (pageType === -1 && selectedSystemCollection?.id === -1) ||
            //   (pageType !== -1 && selectedSystemCollection?.id !== -1) ||
            //   (pageType === -1 && selectedSystemCollection?.id !== -1)
            // }
            onClick={() =>
              addNewRightItemServices(
                `Profile ${
                  unchangableList && unchangableList.length
                    ? unchangableList.length + 1
                    : 1
                }`,
                pageType
              )
            }
          >
            <BiPlus size={20} className="me-1" />
            New
          </Button>
          {selectedRightItem?.isSelf && (
            <>
              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => handleItemUp()}
                disabled={
                  timestamp === "" ||
                  activeIndex === 0 ||
                  searchProfile.length != 0 ||
                  selectedSystemCollection?.id !== -1
                  // ||
                  // (pageType === -1 && selectedSystemCollection?.id === -1) ||
                  // (pageType !== -1 && selectedSystemCollection?.id !== -1)
                }
              >
                <MdArrowUpward size={22} className="me-1" />
                Up
              </Button>
              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => handleItemDown()}
                disabled={
                  timestamp === "" ||
                  allRightListItems?.length - 1 === activeIndex ||
                  selectedRightItem?.isLast ||
                  searchProfile.length != 0 ||
                  selectedSystemCollection?.id !== -1
                  // ||
                  // (pageType === -1 && selectedSystemCollection?.id === -1) ||
                  // (pageType !== -1 && selectedSystemCollection?.id !== -1)
                }
              >
                <MdArrowDownward size={22} className="me-1" />
                Down
              </Button>
            </>
          )}
        </div>
        {/* End: space for buttons at the bottom */}
      </div>

      <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
        {usedProducts?.length > 0 ? (
          <h2 className="px-3 pt-3">Oops!</h2>
        ) : (
          <ModalHeader>Delete Selected Item</ModalHeader>
        )}
        {usedProducts?.length > 0 ? (
          <ModalBody>
            <p className="bt-0">
              This profile is being used by the following products:
            </p>

            {usedProducts.map((item, index) => (
              <span key={index}>
                {index == 0 ? null : <br />} <b>{item.productType}</b> -{" "}
                {item.usedIn}
              </span>
            ))}

            <p className="mt-4">
              Remove the profile from these products first.
            </p>
          </ModalBody>
        ) : (
          <ModalBody>
            Are you sure you want delete{" "}
            <strong>{selectItemDetails?.name}</strong> ?
          </ModalBody>
        )}

        <ModalFooter>
          {usedProducts?.length == 0 ? (
            <Button
              color="outline-danger"
              size="md"
              disabled={isDeleting}
              onClick={() => deleteRightItemServices()}
            >
              Delete
            </Button>
          ) : null}

          <Button
            onClick={() => setShowDeleteModal(false)}
            color="primary"
            size="md"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showVisibilityModal}
        toggle={() => setShowVisibilityModal(false)}
      >
        <div className="d-flex justify-content-between align-items-center p-3">
          <h2 className="mb-0 lh-1">Oops!</h2>

          <NavLink onClick={() => setShowVisibilityModal(false)}>
            <MdClose size={20} />
          </NavLink>
        </div>

        <ModalBody>
          <p className="bt-0">
            This profile is being used by the following products:
          </p>

          {usedProducts.map((item, index) => (
            <span key={index}>
              {index == 0 ? null : <br />} <b>{item.productType}</b> -{" "}
              {item.usedIn}
            </span>
          ))}

          <p className="mt-4">Remove the profile from these products first.</p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProfileRight;
