import React, { useEffect, useState } from 'react'
import { BiPlus, BiTrashAlt } from 'react-icons/bi';
import { MdArrowDownward, MdArrowUpward, MdContentCopy, MdFolderOpen, MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useRightContext } from '../../../../../../context/rightMenuContext';
import { useMessage } from '../../../../../../context/messageContext';
import { deleteRightItem, updateOrder } from '../../../../../../services/frameCollectionsService';
import { copyItem, itemVisibility } from '../../../../../../services/commonServices';
import { handleError } from '../../../../../../utility/commonHelper';

const FrameCollectionsRight = ({ accessToken, pageName, pageType, allRightListItems, setLoading, getRightMenuListData, addNewRightItemServices, selectItemDetails }) => {

	const [previouseSelected, setPreviouseSelected] = useState({ prevId: 1, prevIndex: 1 })
	const [currentSelected, setCurrentSelected] = useState({ currId: 1, currIndex: 1 })
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [isDeleting, setIsDeleting] = useState(false)

	const navigate = useNavigate()
	const { timestamp, selectedRightItem, fetchSelected, toDelete, fetchDeleteData, newItemList, setNewItemList, handleActiveIndexUp, handleActiveIndexDown, activeIndex, setActiveIndex, userDataContext } = useRightContext();
	const { setMessage, setMessageType } = useMessage()

	useEffect(() => {
		const assignItemValue = allRightListItems?.map((item) => {
			return {
				name: item.name,
				description: item.description,
				id: item.id,
				isSelf: item.isSelf,
				isEnabled: item.isEnabled,
			}
		})

		setNewItemList(assignItemValue)

	}, [allRightListItems])

	useEffect(() => {
		setActiveIndex(0)
	}, [])

	const handleItemClick = (currentIndex, id) => {
		setCurrentSelected({ currId: id, currIndex: currentIndex + 1 });

		if (currentSelected) {
			setPreviouseSelected({
				prevId: currentSelected.currId,
				prevIndex: currentSelected.currIndex,
			});
		}
		fetchSelected(currentIndex, id);
	};

	// api to delete right side items
	const deleteRightItemServices = async () => {
		setIsDeleting(true)
		setLoading(true)

		const res = await deleteRightItem(accessToken, toDelete?.id)

		if (res && res && res.status === 200) {

			setShowDeleteModal(false)


			if (res?.data?.statusCode === 200) {
				setMessage(res?.data?.message ? res.data.message : 'Record removed successfully !');
				setMessageType('success')

				fetchSelected(0, allRightListItems[0]?.id);
				getRightMenuListData()
				setIsDeleting(false)
			} else {
				setIsDeleting(false)
				setLoading(false)
				setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
				setMessageType('error')
			}
		} else {
			setIsDeleting(false)
			setLoading(false)
			setMessage(res?.message ? res.message : 'Something went wrong');
			setMessageType('error')

			setShowDeleteModal(false)
			handleError(res, false, navigate)
		}
	}

	// api to update right side item
	const updateOrderServices = async (direction) => {
		setLoading(true)

		const res = await updateOrder(accessToken, timestamp, direction)

		if (res && res && res.status === 200) {

			if (res?.data?.statusCode === 200) {
				getRightMenuListData()
			} else {
				setLoading(false)
				setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
				setMessageType('error')
			}
		} else {
			setLoading(false)
			setMessage(res?.message ? res.message : 'Something went wrong');
			setMessageType('error')
			handleError(res, false, navigate)
		}
	}

	const handleItemUp = () => {
		handleActiveIndexUp()
		updateOrderServices("UP")
	}

	const handleItemDown = () => {
		handleActiveIndexDown()
		updateOrderServices("DOWN")
	}

	const copyPaletteServices = async (id) => {
		setLoading(true)
		const res = await copyItem(accessToken, id, 19)
		if (res && res.status === 200) {
			if (res?.data?.statusCode === 200) {
				setMessageType('success')
				setMessage(res?.data?.message ? res.data.message : 'Item copied')
				getRightMenuListData()
				setLoading(false)
			} else {
				setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
				setMessageType('error')
				getRightMenuListData()
				setLoading(false)
			}
		} else {
			setLoading(false)
			setMessage(res?.message ? res.message : 'Something went wrong');
			setMessageType('error')
			getRightMenuListData()
			handleError(res, false, navigate)
		}
	}

	const handleVisibility = async (e, item) => {
		setLoading(true)
		const res = await itemVisibility(accessToken, item?.id, 19, !item?.isEnabled)

		if (res && res.status === 200) {
			if (res?.data?.statusCode === 200) {
				setMessageType('success')
				setMessage(res?.data?.message ? res.data.message : 'Action Successful')
				getRightMenuListData()
				setLoading(false)
			} else {
				setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
				setMessageType('error')
				getRightMenuListData()
				setLoading(false)
			}
		} else {
			setLoading(false)
			setMessage(res?.message ? res.message : 'Something went wrong');
			setMessageType('error')
			getRightMenuListData()
			handleError(res, false, navigate)
		}
	}

	return (
		<>
			<div className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
				<div className='inside_scroll has_btns'>
					<div className='right_list'>
						{newItemList?.map((item, index) =>
							// add has_delete and has_copy class in below div based on availability of icons in action_btns div
							<div key={index} className={`position-relative d-flex align-items-center right_item_wrap has_delete has_copy ${userDataContext?.role === "Supplier" && 'count_3'}`}>
								<Link to="#" className={`right_item w-100 ${activeIndex === index && "active"}`} onClick={() => handleItemClick(index, item.id)}>
									<span className='me-2 image_icon' >
										<MdFolderOpen size={22} />
									</span>
									<div className="right_title">
										<span className="text-truncate">{item.name}</span>
										<span className="text-truncate d-block text-small">{item.description}</span>
									</div>
								</Link>

								<span className='position-absolute action_btns'>
									{userDataContext && userDataContext?.role === "Supplier" && (
										<Link to="#" onClick={(e) => { fetchSelected(index, item.id); handleVisibility(e, item) }}>
											{item?.isEnabled ? (
												<MdOutlineVisibilityOff className='text-muted visibility_off' size={20} />
											) : (
												<MdOutlineVisibility className='text-muted visibility_on' size={20} />
											)}
										</Link>
									)}
									
									{item.isSelf && (
										<>
											{newItemList?.length > 1 && (
												<Link to="#" onClick={() => { setShowDeleteModal(true); fetchDeleteData(index, item.id); }}> <BiTrashAlt className='text-muted' size={20} /> </Link>
											)}

											<Link to="#" onClick={() => copyPaletteServices(item?.id)}> <MdContentCopy className='text-muted' size={18} /> </Link>
										</>
									)}
								</span>
							</div>
						)}
					</div>
				</div>

				{/* Start: space for buttons at the bottom */}
				<div className='d-flex gap-2 gap-xl-3 main_buttons'>
					<Button
						color="primary"
						size="md"
						className='px-4'
						onClick={() => addNewRightItemServices(`Collection ${newItemList && newItemList.length ? (newItemList.length + 1) : 1}`, pageType)}
					>
						<BiPlus size={20} className='me-1' />
						New
					</Button>
					{selectedRightItem?.isSelf && (
						<>
							<Button
								color=""
								size="md"
								className='border-0 text-primary px-0'
								onClick={() => handleItemUp()}
								disabled={timestamp === '' || activeIndex === 0}
							>
								<MdArrowUpward size={22} className='me-1' />
								Up
							</Button>
							<Button
								color=""
								size="md"
								className='border-0 text-primary px-0'
								onClick={() => handleItemDown()}
								disabled={timestamp === '' || (allRightListItems?.length - 1 === activeIndex) || (selectedRightItem?.isLast)}
							>
								<MdArrowDownward size={22} className='me-1' />
								Down
							</Button>
						</>
					)}
				</div>
				{/* End: space for buttons at the bottom */}
			</div>

			<Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
				<ModalHeader >
					Delete Selected Item
				</ModalHeader>
				<ModalBody>
					Are you sure you want delete <strong>{toDelete?.name}</strong> ?
				</ModalBody>
				<ModalFooter>
					<Button color="outline-danger" size="md" disabled={isDeleting} onClick={() => deleteRightItemServices()}>Delete</Button>
					<Button onClick={() => setShowDeleteModal(false)} color="primary" size="md">Cancel</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default FrameCollectionsRight