export const updateColor = (color, jsonIndex = 0) => {
    return prevModelJson => {
        const updatedModelArray = [...prevModelJson]
        const updatedJson = {...updatedModelArray[jsonIndex]};
        updatedJson.color = color
        updatedModelArray[jsonIndex] = updatedJson
        return updatedModelArray
    }
}

export const updateColorExt = (color, jsonIndex = 0) => {
    return prevModelJson => {
        const updatedModelArray = [...prevModelJson]
        const updatedJson = {...updatedModelArray[jsonIndex]};
        updatedJson.colorExt = color
        updatedModelArray[jsonIndex] = updatedJson
        return updatedModelArray
    }
}