import axios from "axios";
import { baseServicePath, servicePath } from "../utility/data";

export const getOrbitUserDetails = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    return axios
        .get(`${servicePath}/Account/GetLoggedInUserInformation`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const copyItem = (accessToken, id, typeId) => {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("TypeId", typeId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Master/CopyToNew`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
}

export const itemVisibility = (accessToken, id, typeId, IsEnabled) => {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("TypeId", typeId);
    formData.append("IsEnabled", IsEnabled);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Master/EnableDisableItems`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
}

export const logOutOrbit = async (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${baseServicePath}/Logout`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const getFontSizeMaster = async (accessToken, typeID) => {

    const formData = new FormData();
    formData.append("TypeId", typeID);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/GetFontSizeMaster`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const getProfileTypeMaster = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetProfileProductTypeMaster`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const getHardwareModelService = (accessToken, fetchMasterId) => {

    const formData = new FormData();
    formData.append("StyleMasterId", fetchMasterId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Master/GethardwareModelMaster`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

export const getHardwareDefaultSideService = async (accessToken, fetchMasterId) => {

    const formData = new FormData();
    formData.append("StyleMasterId", fetchMasterId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/GethardwareDefaultSideMaster`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const getHardwareInternalStyleService = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GethardwareInternalStyleMaster`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const GetMarginMasterService = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetMarginMaster`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const GetMarkUpMasterService = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetMarkUpPriceList`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const GetSizeMasterService = async (accessToken, TypeName) => {
    const formData = new FormData();
    formData.append("TypeName", TypeName);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/GetSizeMaster`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const GetProfileJointFrameMaster = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetProfileJointFrameMaster`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const GetProfileJointBeadMaster = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetProfileJointBeadMaster`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const GetProfileJointTransomMaster = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetProfileJointTransomMaster`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const getSashMaster = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetSashMaster`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const GetProfileMastersForSystem = async (accessToken, typeId) => {

    // console.log(typeId, "type id")

    const formData = new FormData();
    formData.append("TypeId", typeId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/GetProfileMastersForSystem`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const getProfileInUsed = async (accessToken, typeId, itemId) => {

    const formData = new FormData();
    formData.append("ProfileMasterId", typeId);
    formData.append("SubProductId", itemId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/ProfileInUsed`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}
export const removeProfileInUsed = async (accessToken, itemId, productType) => {

    const formData = new FormData();
    formData.append("Id", itemId);
    formData.append("ProductType", productType);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/RemoveProfileInUsed`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}
export const getHardwareInUsed = async (accessToken, typeId, itemId) => {

    const formData = new FormData();
    formData.append("HandwareMasterId", typeId);
    formData.append("HardwareId", itemId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/HardwareInUsed`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}
export const removehardwareInUsed = async (accessToken, itemId, productType) => {

    const formData = new FormData();
    formData.append("Id", itemId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/RemoveHardwareInUsed`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}
export const getHardwareCollectionInUsed = async (accessToken, itemId) => {

    const formData = new FormData();
    formData.append("HandwareMasterId", itemId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/HardwareCollectionInUsed`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const getSysFrameSashInUsed = async (accessToken, collectionId, sashId) => {

    const formData = new FormData();
    formData.append("SashProductMasterId", collectionId);
    formData.append("SubProductId", sashId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/SubProductInUsed`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}
export const removePanelProdInUsed = async (accessToken, itemId, productType) => {

    const formData = new FormData();
    formData.append("Id", itemId);
    formData.append("ProductType", productType);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/RemoveSubProductInUsedV1`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const getGlazingspecInUsed = async (accessToken, CollectionId) => {

    const formData = new FormData();
    formData.append("CollectionId", CollectionId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/SpecificationInUsed`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}
export const getGlazingpaletteInUsed = async (accessToken, paletteId) => {

    const formData = new FormData();
    formData.append("PlatteId", paletteId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/SpecificationPalettesInUsed`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}
export const removeGlazingpaletteInUsed = async (accessToken, itemId, productType) => {

    const formData = new FormData();
    formData.append("Id", itemId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/RemoveSpecificationPalettesInUsed`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}
export const GetDiscountableItems = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetDiscountableItems`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const GetProjectInfoMaster = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetProjectInfoMaster`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const GetPropertiesMaster = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetPropertiesMaster`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const srcIconCondition = (userData, smallLogo) => {
    if (!(userData?.role === "Super Admin")) {
        return userData?.company_icon ? userData?.company_icon : smallLogo;
    } else {
        return smallLogo;
    }
}

export const srcLogoCondition = (userData, logo) => {
    if (!(userData?.role === "Super Admin")) {
        return userData?.company_logo ? userData?.company_logo : logo;
    } else {
        return logo;
    }
}

export const srcLoaderCondition = (userData, mainLogo) => {
    if (!(userData?.role === "")) {
        return userData?.company_logo ? userData?.company_logo : mainLogo;
    } else {
        return mainLogo;
    }
}

export const updateFrameStyleAppenture = async (accessToken, frameStyleId, wide = 1, high = 1) => {

    const formData = new FormData();
    formData.append("FrameStyleId", frameStyleId);
    // formData.append("Appenture", appenture);
    formData.append("Wide", wide);
    formData.append("High", high);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/FrameStyle/UpdateFrameStyleAppenture`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

// export const GetHangingMaster = async (accessToken) => {

//     const headers = {
//         'Authorization': `Bearer ${accessToken}`
//     }

//     try {
//         const res = await axios
//             .get(`${servicePath}/Master/GetHangingMaster`, { headers });
//         return res;
//     } catch (error) {
//         return error;
//     }
// }

export const GetHangingMaster = async (accessToken, sashId) => {

    const formData = new FormData();
    formData.append("SashProductMasterTypeId", sashId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/GetHangingMaster`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const GetTaxMaster = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetTaxMaster`, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

export const updateFrameDetails = async (accessToken, data) => {

    const formData = new FormData();

    formData.append("Id", data.id); // frame id for update
    formData.append("FrameCollectionId", data?.frameCollectionId);
    formData.append("FrameProductId", data?.frameProductId);
    formData.append("FrameStyleId", data?.frameStyleId);
    formData.append("FrameStyleCollectionId", data?.frameStyleCollectionId)

    formData.append("Quantity", data?.quantity);
    formData.append("Name", data?.name);
    formData.append("Reference", data?.reference);
    formData.append("Comment", data?.comment);
    formData.append("QuotationId", data?.quoteId);

    if (data?.modelImages && data?.modelImages.externalImage) {
        formData.append("ModelImages", JSON.stringify(data?.modelImages));
    }

    if (data.profilePath) {
        formData.append("SvgIn64Base", data?.profilePath);
    }

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Quotation/AddUpdateNewFrame`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
}

// export const GetTagsMasters = async (accessToken) => {

//     const headers = {
//         'Authorization': `Bearer ${accessToken}`
//     }

//     try {
//         const res = await axios
//             .get(`${servicePath}/Master/GetTagsMasters`, { headers });
//         return res;
//     } catch (error) {
//         return error;
//     }
// }